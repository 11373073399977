import React, { Component } from 'react';
import BillList from './BillList';
import Customers from './Customers';

class BillPage extends Component {
    renderPage = () => {
        let { match } = this.props;
        switch(match.params.page){ 
            case 'bills':{
                return <BillList match={match} {...this.props} />}
            case 'devis':{
                return <><h3>Devis</h3></>}
            case 'avoirs':{
                return <><h3>Avoirs</h3></>}
            case 'proforma':{
                return <><h3>Proforma</h3></>}
            case 'clients':{
                return <Customers match={match} {...this.props} />}
            default:{
                return <BillList match={match} {...this.props} />}
        }
    }
    render() {
        return(
            <div className="flex-part pb-50">
                {this.renderPage()}
            </div>
        )
    }
}

export default BillPage;