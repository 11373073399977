import React, { Component } from 'react';
import Countries from 'country-data';
import { connect } from 'react-redux';
import IconPlus from '../Icons/IconPlus';
import IconPerson from '../Icons/IconPerson';
import IconMan from '../Icons/IconMan';
import IconManMaleSign from '../Icons/IconManMaleSign';
import IconDate from '../Icons/DateIcon';
import IconDateExp from '../Icons/DateExpirationIcon';
import IconUnion from '../Icons/UnionIcon';
import IconMap from '../Icons/MapIcon';
import IconNumber from '../Icons/NumberIcon';
import IconNumSecuIcon from '../Icons/NumSecuIcon';
import IconFemale from '../Icons/IconFemale';
import IconWoman from '../Icons/IconWoman';
import EditableInput from '../EditableInput';
import CardPreview from '../CardPreview';
import { updateStaff, selectStaff } from '../../redux/action/StaffAction';
import { _updateStaff } from '../../services/Staff';
import BottomToTop from '../Transition/BottomToTop';
import ModalChild from './ModalChild';
import moment from 'moment';
import Logger from '../../helpers/Logger';
import { validationNotification } from "../../helpers/errorHandle";

class CivilInfoPreview extends Component {

    civilityManOptions= [{value: 'Monsieur'}];
    civilityWomanOptions= [{value: 'Madame'}, {value: 'Mademoiselle'}];
    constructor(props){
        super(props);
        
        this.state = {
            name: this.props.selectedStaff.name,
            surname: this.props.selectedStaff.surname,
            birthdate: this.props.selectedStaff.birthdate,
            birthdayPlace: this.props.selectedStaff.birthdayPlace,
            numberCni: this.props.selectedStaff.numberCni,
            dateExpirationCni: this.props.selectedStaff.dateExpirationCni,
            numberCnps: this.props.selectedStaff.numberCnps,
            familySituation: this.props.selectedStaff.familySituation,
            nationality: '',
            addChild: false,
            modalChild: false,
            indexChild: -1,
            childName: '',
            childSurname: '',
            childBirthday: null,
            childs : this.props.selectedStaff.childs,
            situation: [{value: 'Célibataire'}, {value: 'Marié(e)'}],
            civilityOptions: [...this.civilityManOptions, ...this.civilityWomanOptions],
            genreOptions: [{value: 'Homme'}, {value: 'Femme'}],
            valid: {
                name: this.props.valid.name,
                surname: this.props.valid.surname,
                birthday: true,
                nationality: true,
                numCni: true,
                numCnps: true,
                formValid: false
            },
            errors: {
                name: '',
                surname: '',
                birthday: '',
            }
        }
    }

    onChangeChild = (event) => {
        let { childs } = this.state
        this.setState({
            modalChild: true,
            indexChild: event.staffChild.index,
            childName: childs[event.staffChild.index].name,
            childSurname: childs[event.staffChild.index].surname,
            childBirthday: childs[event.staffChild.index].birthday
        });
    }
    
    renderChildren = () => {
        if(this.props.selectedStaff.childs && this.props.selectedStaff.childs.length){
            return (
                this.props.selectedStaff.childs.map((item, index) => {
                    return(
                        <div className="row ml-3 mr-1 mt-2 mb-2" key={index}>
                            <div className="col-md-12 ml-0">
                                <CardPreview name={item.name} surname={item.surname} birthDate={item.birthday} onClick={this.onChangeChild} index={index} />
                            </div>
                        </div>
                    )
                })
            )
        }else{
            return(
                <div className="row ml-3 mr-1 mt-2 mb-2">
                    <div className="col-md-12 ml-0 d-flex align-items-center justify-content-center bg-light">
                        AUCUN ENFANT
                    </div>
                </div>
            )
        }
    }

    renderFlag = () => {
        const country = Countries.countries.all.find(country => country.name === this.props.selectedStaff.nationality)
        if(country)
            return country.emoji
        else
            return ''
    }

    onChangeName(e) {
        this.props.onChangeName(e);
    }
    
    onChangeSurname = (e) => {
        this.props.onChangeSurname(e);
    }

    onValidate = async () => {
        let newChild = this.props.selectedStaff.childs || [];
        let childData = {
            name: this.state.childName,
            surname: this.state.childSurname,
            birthday: this.state.childBirthday
        }
        newChild.push(childData)
        await this.updateStaff(this.props.selectedStaff.id, {childs: newChild})
        this.setState({
            childs: newChild,
            modalChild: false,
            addChild: false,
            childName: '',
            childSurname: '',
            childBirthday: ''
        })
    }

    onEdit = async () => {
        let { indexChild } = this.state
        let newChild = this.props.selectedStaff.childs
        if(this.state.indexChild >= 0) {
            newChild[indexChild].name = this.state.childName
            newChild[indexChild].surname = this.state.childSurname
            newChild[indexChild].birthday = this.state.childBirthday
            await this.updateStaff(this.props.selectedStaff.id, {childs: newChild})
            this.setState({
                childs: newChild,
                modalChild: false,
                addChild: false
            })
        }
    }

    updateStaff = async (id, staffData) => {
        if (staffData.genre){
            staffData.civility = this.setDefaultCivility(staffData.genre)
        }
        let defaultOrganization = JSON.parse(sessionStorage.getItem('defaultOrg'));
        try {
            const staff = (await _updateStaff(defaultOrganization.id, id, staffData)).data;
            if(staff) {
                this.props.updateStaff(staff)
                this.props.selectStaff(staff)
            }
        } catch (err) {
            const error = { ...err };
            Logger.error(`Staff", "Could not update staff ${staffData}`, error);
            validationNotification(error?.response?.status, error?.response?.data?.errors);
        }
    }

    setDefaultCivility(genre) {
        switch (genre) {
            case this.state.genreOptions[0].value:
                return this.civilityManOptions[0].value;
            case this.state.genreOptions[1].value:
                return this.civilityWomanOptions[1].value;
            default:
                return '';
        }
    }
    onChangeGender(gender){
        switch (gender) {
            case this.state.genreOptions[0].value:{
                this.setState({civilityOptions: [...this.civilityManOptions]})
                break;
            }
            case this.state.genreOptions[1].value:{
                this.setState({civilityOptions: [...this.civilityWomanOptions]})
                break;
            }
            default: break;
        }
    }

    renderCivilPreview = () =>{
        const selectedStaff = this.props.selectedStaff;
        return(
            <div className='px-0'>
                <div className="header-preview">
                    <div className="d-flex align-items-center">
                        <span className="ico-content pr-1"><IconPerson color={"previewIco"} width={18} height={18} active /></span>
                        <EditableInput type='text' name='surname' value={selectedStaff.surname} className="header-preview-title pl-30-10 "
                            onBlur={event => this.updateStaff(selectedStaff.id, {surname: event.target.value}, 'prénom')} 
                            state={'preview'} editable onChange={this.onChangeSurname}
                        />
                        <EditableInput type='text' name='name' value={selectedStaff.name} className="header-preview-title pl-30-10 "
                            onBlur={event => this.updateStaff(selectedStaff.id, {name: event.target.value}, 'nom')} 
                            state={'preview'} editable onChange={(e) => this.onChangeName(e)}
                        />
                    </div>
                    <div className="d-flex align-items-center">
                        <EditableInput withClass='w-50 mr-2' type="select" name="nationality" selectedValue={selectedStaff.nationality} options={this.props.nationalities} optionIcon='emoji'
                            optionValue='name' className="pl-30-10" onBlur={event => this.updateStaff(selectedStaff.id, {nationality: event.target.value})} 
                            state={'preview'} editable withIcon={this.renderFlag()} />
                        <div className="label-input">{selectedStaff.matricule} </div>
                    </div>
                    <div className="d-flex align-items-center mt-1">
                        <span className="ico-content pr-1"></span>
                        <EditableInput withClass='w-50 mr-2' type="select" name="genre" selectedValue={selectedStaff.genre} options={this.state.genreOptions}
                        optionValue='value' className="pl-30-10" onChange={event => {this.onChangeGender(event.target.value)}} 
                        onBlur={event => this.updateStaff(selectedStaff.id, {genre: event.target.value})} 
                        state={'preview'} editable withIcon={selectedStaff.civility === this.state.civilityOptions[0].value ? <IconMan active /> : <IconWoman active/>} />

                        <EditableInput withClass='w-50 mr-2' type="select" name="civility" selectedValue={selectedStaff.civility} options={this.state.civilityOptions}
                        optionValue='value' className="pl-30-10" onBlur={event => this.updateStaff(selectedStaff.id, {civility: event.target.value})} 
                        state={'preview'} editable withIcon={selectedStaff.civility === this.state.civilityOptions[0].value ? <IconManMaleSign active /> : <IconFemale active/>} />
                    </div>
                </div>
                <div className="content-preview">
                    <div className="row pt-1 pl-3">
                        <div className="col-md-6">
                            <div className="label-input">Né le</div>
                            <EditableInput type="date" name="birthdate" id={this.props.selectedStaff.id} value={this.props.selectedStaff.birthdate ? moment(this.props.selectedStaff.birthdate).format('YYYY/MM/DD') : ''} 
                                className="pl-30-10" onBlur={event => event.target.value && this.updateStaff(this.props.selectedStaff.id, {birthdate: event.target.value})} 
                                state={'preview'} editable withIcon={<IconDate active={true}  />} />
                        </div>
                        <div className="col-md-6">
                            <div className="label-input">À</div>
                            <EditableInput type="text" name="birthlocation" value={selectedStaff.birthdayPlace} 
                                onBlur={event => this.updateStaff(selectedStaff.id, {birthdayPlace: event.target.value})} 
                                state={'preview'} editable withIcon={<IconMap active={true}  />} />
                        </div>
                    </div>
                    <hr className="mt-1 mb-1"/>
                    <div className="row pl-3">
                        <div className="col-md-6">
                            <div className="label-input">Numero de CNI</div>
                            <EditableInput type="text" name="cni" value={selectedStaff.numberCni} 
                                className="pl-30-10" onBlur={event => this.updateStaff(selectedStaff.id, {numberCni: event.target.value})} 
                                state={'preview'} editable withIcon={<IconNumber active={true}  />} />
                        </div>
                        <div className="col-md-6">
                            <div className="label-input">Date d'expiration de CNI</div>
                            <EditableInput type="date" name="cniexp" value={selectedStaff.dateExpirationCni ? moment(selectedStaff.dateExpirationCni).format('YYYY/MM/DD') : ''} 
                                className="pl-30-10" onBlur={event => this.updateStaff(selectedStaff.id, {dateExpirationCni: event.target.value})} 
                                state={'preview'} editable withIcon={<IconDateExp  active={true}  />} />
                        </div>
                    </div>
                    <hr className="mt-1 mb-1"/>
                    <div className="row pl-3">
                        <div className="col-md-6">
                            <div className="label-input">Numéro de sécurité sociale</div>
                            <EditableInput type="text" name="cnps" value={selectedStaff.numberCnps} 
                                className="pl-30-10" onBlur={event => this.updateStaff(selectedStaff.id, {numberCnps: event.target.value})} 
                                state={'preview'} editable withIcon={<IconNumSecuIcon active={true}  />} />
                        </div>
                        <div className="col-md-6">
                            <div className="label-input">Numéro de contribuable</div>
                            <EditableInput type="text" name="cnps" value={selectedStaff.niu} 
                                className="pl-30-10" onBlur={event => this.updateStaff(selectedStaff.id, {niu: event.target.value})} 
                                state={'preview'} editable withIcon={<IconNumSecuIcon active={true}  />} />
                        </div>
                    </div>
                    <hr className="mt-1 mb-1"/>
                    <div className="row pl-3">
                        <div className="col-md-12">
                            <div className="label-input">Situation familiale</div>
                            <EditableInput type="select" name="maritalstatus" value={selectedStaff.familySituation} options={this.state.situation}
                                optionValue='value' className="pl-30-10" onBlur={event => this.updateStaff(selectedStaff.id, {familySituation: event.target.value})} 
                                state={'preview'} editable withIcon={<IconUnion active={true}  />} />
                        </div>
                    </div>
                    <div className="label-input ml-3 justify-content-between align-items-center">
                        <div>Nombre d'enfant <span className="puce">{`${selectedStaff.childs ? selectedStaff.childs.length : 0}`}</span></div>
                        <button className='btn-plus mt-2' onClick={this.displayModalChild}>
                            <IconPlus />
                        </button>
                    </div>
                    
                    { this.renderChildren() }
                    
                </div>
            </div>
        )
    }

    displayModalChild = (event) => {
        this.setState({
            modalChild: true,
            addChild: true
        })
    }

    renderModalChild = () => {
        let { addChild } = this.state
        return <ModalChild name={this.state.childName} surname={this.state.childSurname} birthday={this.state.childBirthday}
                onChangeChildName={(e) => {this.setState({childName: e.target.value})}} 
                onChangeChildSurname={(e) => {this.setState({childSurname: e.target.value})}} onChangeChildBirthday={(e) => this.setState({childBirthday: e.target.value})} 
                onCancel={(e) => this.setState({modalChild: false})} onValidate={addChild ? () => this.onValidate() : () => this.onEdit()} 
            />
    }

    render(){
        
        return(
            <div>
                <BottomToTop in={!this.state.modalChild}>{this.renderCivilPreview()}</BottomToTop>
                <BottomToTop in={this.state.modalChild}>{this.renderModalChild()}</BottomToTop>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { staffs } = state
    return {
        selectedStaff: staffs.selectedStaff
    }
}

export default connect(mapStateToProps, {updateStaff, selectStaff})(CivilInfoPreview);