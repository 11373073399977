import React from "react";

const IconTeasuryDefaultComponent = props => (
  <svg width={props.width ? props.width : 29.099} height={props.height ? props.height : 24.591} viewBox="0 0 29.099 24.591" 
    className={`${props.className ? props.className : ''}`} 
    style={{"--theme-color": props.activecolor}}
  >
    <defs>
      <clipPath id="teasuryIco">
        <path
          fill={props.color ? props.color : "#ffffff"}
          d="M24.528,8.456H5.573A3.845,3.845,0,0,0,1.731,12.3V23.056A3.845,3.845,0,0,0,5.573,26.9H24.528a3.845,3.845,0,0,0,3.842-3.842V12.3A3.845,3.845,0,0,0,24.528,8.456ZM20.615,2.372,5.573,5.311C4.42,5.568,2.755,6.727,2.755,8.135A4.035,4.035,0,0,1,5.893,6.919H24.272V5.606a3.677,3.677,0,0,0-.89-2.408A3.029,3.029,0,0,0,20.615,2.372Z"
          transform="translate(34.859 22.176)"
        />
      </clipPath>
    </defs>
    <g transform="translate(-36.589 -24.484)">
      <g className="mask-ico">
        <path
          fill={props.color ? props.color : "#ffffff"}
          d="M63.229,37.241S54.492,34.1,53.118,43.233c-.057,0,.083,4.854,3.923,5.974.034-.037-17.157,0-17.157,0s-3.58-.136-3.58-5.317c.02.018,0-19.624,0-19.624H63.229Z"
        />
      </g>
      <g transform="translate(53.707 36.963)">
        <path
          fill={props.color ? props.color : "#ffffff"}
          d="M-2216.406-629.08a5.719,5.719,0,0,1-5.711-5.713,5.717,5.717,0,0,1,5.711-5.711,5.716,5.716,0,0,1,5.709,5.711A5.718,5.718,0,0,1-2216.406-629.08Zm-1.58-4.638v.426a1.711,1.711,0,0,0,.286,1.032,1.308,1.308,0,0,0,.861.5v.453h.865v-.453a1.325,1.325,0,0,0,.855-.5,1.715,1.715,0,0,0,.289-1.032,1.725,1.725,0,0,0-.154-.76,2.194,2.194,0,0,0-.383-.554,4.216,4.216,0,0,0-.5-.434c-.153-.108-.307-.228-.5-.39a2.173,2.173,0,0,1-.383-.433.986.986,0,0,1-.154-.551.647.647,0,0,1,.14-.474.538.538,0,0,1,.391-.136.529.529,0,0,1,.387.136.646.646,0,0,1,.139.474v.259h1v-.2a1.717,1.717,0,0,0-.281-1.026,1.327,1.327,0,0,0-.852-.495v-.461h-.865v.461a1.3,1.3,0,0,0-.837.5,1.7,1.7,0,0,0-.279,1.019,1.676,1.676,0,0,0,.154.76,2.077,2.077,0,0,0,.383.555,3.585,3.585,0,0,0,.5.432c.171.124.333.251.495.391a2.12,2.12,0,0,1,.384.433.954.954,0,0,1,.157.551.626.626,0,0,1-.15.472.6.6,0,0,1-.4.131.591.591,0,0,1-.4-.131.62.62,0,0,1-.15-.472v-.489Z"
          transform="translate(2222.679 641.066)"
        />
      </g>
    </g>
  </svg>
);

export default IconTeasuryDefaultComponent;
