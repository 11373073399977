import React, { Component } from 'react'
import IconPlus from '../Icons/IconPlus'

import './Adder.css'

class Adder extends Component {

    constructor(props){
        super(props)

        this.state = {
            source: this.props.source,
            color: this.props.color,
            items: this.props.items,
            openStep: this.props.openStep
        }
    }

    addItem = (elem) => {
        if(this.props.onAdd){
            this.props.onAdd(elem, this.props.openStep);
        }
    }

    onClick = (e) => {
        e.stopPropagation();
        this.addItem(this.props.source);
    }

    render(){
        return(
            <div className="adder">
                <button className="btn-add" onClick={this.onClick} ><IconPlus width={15} height={15} color={this.props.color} /></button>
                {this.props.items.map((item, index) => (
                    <span className="value" style={{ backgroundColor: item.bgColor}} key={index} > {item.value || 0} </span>
                ))}
                
            </div>
        )
    }
}

export default Adder
                                                                                          