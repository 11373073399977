/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const IoniconsSvgIosCubeComponent = (props) => (
  <svg width={props.width ? props.width : 15.162} height={props.height ? props.height : 15.166} viewBox="0 0 15.162 15.166">
    <defs>
      <style>{`.category{fill:${props.active ? '#177bd1;' : '#b5b5b5;'}}`}</style>
    </defs>
    <path
      className="category"
      d="M62.32,50.971l-6.529-2.927a.507.507,0,0,0-.412,0l-6.533,2.927a.306.306,0,0,0-.015.54l6.515,3.431a.5.5,0,0,0,.474,0l6.515-3.431A.306.306,0,0,0,62.32,50.971ZM56.465,63.126l6.566-3.474a.294.294,0,0,0,.131-.248V52.663a.271.271,0,0,0-.39-.259l-6.566,3.383a.288.288,0,0,0-.153.259v6.828A.275.275,0,0,0,56.465,63.126ZM48,52.663V59.4a.294.294,0,0,0,.131.248L54.7,63.122a.269.269,0,0,0,.408-.248V56.046a.288.288,0,0,0-.153-.259L48.39,52.4A.273.273,0,0,0,48,52.663Z"
      transform="translate(-48 -48)"
    />
  </svg>
);

export default IoniconsSvgIosCubeComponent;
