import React, {Component} from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import IconAccount from '../../Icons/IconAccountUser';
import IconBacklogUser from '../../Icons/IconBacklogUser';
import IconBank from '../../Icons/IconBankDefaultComponent';
import IconFees from '../../Icons/IconFeeDefaultComponent';
import IconBilling from '../../Icons/IconBilling';
import IconTreasury from '../../Icons/IconTeasuryDefaultComponent';
import IconTaxes from '../../Icons/IconTaxesComponent';
import './NavItem.css';

class NavItem extends Component {
    constructor(props){
        super(props)
        this.state = {
            id: this.props.data.id,
            image: this.props.data.image,
            label: this.props.data.label,
            route: this.props.data.route,
            activClass: this.props.data.activClass,
            classe: this.props.data.class,
            active: this.props.data.active,
            mouseOver: this.props.data.mouseOver,
            icon: this.props.data.icon,
            index: this.props.index,
            notification: this.props.data.notification
        }

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick = () => {
        const { index } = this.state;
        const color = this.renderColorTheme(this.props.data.id);
        this.props.onClick(index, { color });
    }

    setInactive = () => {
        
    }

    renderColorTheme = (id) => {
        switch (id) {
            case 1: return '#B5B5B5';
            case 2: return '#E29300';
            case 3: return '#FD543E';
            case 4: return '#0C9F23';
            case 5: return '#177BD1';
            case 6: return '#AF4E07';
            case 7: return '#09B6CC';
            default : return '#B5B5B5';
        }
    }

    renderIcon = (id) => {
        const DEFAULT_COLOR = "#FFFFFF";
        const themeColor = this.renderColorTheme(id);
        switch (id) {
            case 1:
                return <IconBacklogUser color={this.props.data.active ? themeColor : DEFAULT_COLOR } className="dashboard-icon" activecolor={themeColor}  />
            case 2:
                return <IconBank color={this.props.data.active ? themeColor : DEFAULT_COLOR } className="bank-icon" activecolor={themeColor}  color2={"transparent"} />
            case 3:
                return <IconFees className={`fees-icon ${this.props.data.active ? 'active' :  ''}`} activecolor={themeColor}  />
            case 4:
                return <IconBilling color={this.props.data.active ? themeColor : DEFAULT_COLOR } className="bill-icon" activecolor={themeColor}  />
            case 5:
                return <IconAccount color={this.props.data.active ? themeColor : DEFAULT_COLOR } className="social-icon" activecolor={themeColor} />
            case 6: 
                return <IconTreasury color={this.props.data.active ? themeColor : DEFAULT_COLOR } className="teasury-icon" activecolor={themeColor}  />
            case 7: 
                return <IconTaxes color={this.props.data.active ? themeColor : DEFAULT_COLOR } className="taxe-icon" activecolor={themeColor}  />
            default:
                return null
        }
            
    }
    render(){
        const { id, label, route, classe, notification} = this.props.data;
        const themeColor = this.renderColorTheme(id);
        return (
            <NavLink to={route} className={'menu-link'} onClick={this.props.onClick}>
                <button 
                    className={'NavItem ' + (classe ? classe : "")}
                    onClick={this.props.onClick}
                    style={{"--active-color": themeColor }}
                >
                    <div className="content-btn-item">
                        <div className='menu-item'>
                            {
                                this.renderIcon(id)
                            }
                        </div>
                        {
                            notification > 0 ? <span className='menu-notification' style={{"--default-noti-color": themeColor }}>{notification}</span> : null
                        }
                        <span className={'label'} style={{"--active-color": themeColor }}>{label}</span>
                        <span className={'h-bar'} ></span>
                    </div>
                </button>
            </NavLink>
        )
    }
}

NavItem.propTypes = {
    onClick: PropTypes.func.isRequired
}

export default NavItem