import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import moment from "moment";
import {Modal, Button} from 'react-bootstrap'
import Search from '../../components/Search';
import IconPlus from '../../components/Icons/IconPlus';
import { getPayPeriodByOrgId, createPayPeriod, countPayPeriodByOrgId, getPayPeriodById } from '../../services/PayPeriod';
import { setStaffs } from '../../redux/action/StaffAction';
import { setPayPeriod, addPayPeriod, addPayPeriodMultiple, selectPayPeriod } from '../../redux/action/PayPeriodAction';
import DetailContainer from "../../components/DetailContainer";
import CustomBtn from "../../components/Button";
import DoubleArrow from '../../components/Icons/DoubleArrow';
import DetailIcon from "../../components/Icons/DetailsIconComponent";
import DownloadIcon from "../../components/Icons/IconCloudDownloadComponent";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import EditableInput from "../../components/EditableInput";
import RowGroup from "../../components/RowGroup";
import Loader from '../../components/Loader';
import { numberFormat } from "../../helpers/Number";
import "./Payslip.css";
import Logger from '../../helpers/Logger';
import {toastr} from 'react-redux-toastr';
import { errorNotifcation } from "../../helpers/errorHandle";
import {PAGINATION} from "../../constants/constants";
const GenericTable = lazy(() => import("../../components/Table/GenericTable") );
moment.locale("fr");
class PayslipsViewList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            payPeriod: [],
            payPeriods: [],
            show: false,
            showDetail: false,
            loadData: false,
            loadPeriod: false,
            selectedItem: null,
            pageOfItems: [],
            DetailList: false,
            detailTitle: "",
            step: 0,
            pageSize: PAGINATION.DEFAULT_PAGE_SIZE,
            startPage: PAGINATION.DEFAULT_PAGE,
            currentPage: PAGINATION.DEFAULT_PAGE,
            endIndex: null,
            endPage: null,
            pages: [],
            startIndex: null,
            totalItems: null,
            totalPages: null,
            count: null,
            hasMore: true,
            scrollLoading: false,
            tableContainerWidth: "100%",
        }
    }

    onChangePage(pageOfItems) {
        this.setState({ pageOfItems });
    }

    onSelectItem = async (item) => {
        this.setState({
            loadPeriod: true,
        });
        const period = await this.getPeriodById(item.id);
        
        if(period){
            this.props.selectPayPeriod(period);
            if(!this.state.showDetail) {
                this.setState({
                    showDetail: true,
                    tableContainerWidth: "calc(100% - 450px)"
                });
            }
        } else {
            Logger.error("Error getting pay period");
            toastr.error("Période de paie inexistante, veuillez rafraichir la page.");
        }
        this.setState({
            loadPeriod: false,
        });
    }

    closeTab = () => {
        this.props.selectPayPeriod(null)
        this.setState({
            showDetail: false,
        });
        this.refGenericTable && this.refGenericTable.unSelectAll();
    }

    setRefGenericTable = (ref) => {
        if(ref){
            this.refGenericTable = ref;
        }
    }

    onGeneratePayPeriodAutomaticAction = async () => {
        this.onCreatePayPeriod(false);
    }

    onGeneratePayPeriodManuallyAction = async () => {
        this.onCreatePayPeriod(true);
    }

    generatePayPeriodAction = async () => {
        try{
            let defaultOrganization = await JSON.parse(sessionStorage.getItem('defaultOrg'));
            const countPayPeriod = (await countPayPeriodByOrgId(defaultOrganization.id)).data;
            if(countPayPeriod === 0){
                this.setState({show: !this.state.show});
            } else {
                this.onCreatePayPeriod();
            }
        } catch(error){
            Logger.error("countPayPeriodByOrgId", "Unable to count pay period", error);
            errorNotifcation(error?.response?.status, "Impossible de générer la période de paie, veuillez reessayer plus tard.");
        }

        
    }

    onCreatePayPeriod = async (manual = false) => {
        try{
            let defaultOrganization = await JSON.parse(sessionStorage.getItem('defaultOrg'));
            this.setState({
                loadData: true,
                show: false
            })

            const payPeriod = {
                organizationId: defaultOrganization.id,
            }
            await createPayPeriod(defaultOrganization.id, payPeriod, manual);
            
            const payperiods = await this.getPayPeriod(10, 1);

            if(manual && payperiods){
                const manualPayperiod = payperiods.find(p => p.manual === true);
                if (manualPayperiod) {
                    manualPayperiod.init = true;
                    this.onSelectItem(manualPayperiod);
                }
            }
        } catch(error) {
                this.setState({
                    loadData: false,
                    show: false
                },() => { 
                    Logger.error("CreatePayPeriod", "Unable to create period", error);
                    errorNotifcation(error?.response?.status, "Impossible de générer la période de paie, veuillez reessayer plus tard.");
                });
        }    
    }

    getPayPeriod = async (pageSize, page) => {
        try{
            let defaultOrganization = JSON.parse(sessionStorage.getItem('defaultOrg'));
            this.setState({ loadData: true });
            const payPeriod = await getPayPeriodByOrgId(defaultOrganization.id, pageSize, page);
            if(payPeriod?.status === 200){
                this.setState({ 
                    loadData: false,
                    currentPage: payPeriod.data.pager.currentPage,
                    endIndex: payPeriod.data.pager.endIndex,
                    endPage: payPeriod.data.pager.endPage,
                    pageSize: payPeriod.data.pager.pageSize,
                    pages: payPeriod.data.pager.pages,
                    startIndex: payPeriod.data.pager.startIndex,
                    startPage: payPeriod.data.pager.startPage,
                    totalItems: payPeriod.data.pager.totalItems,
                    totalPages: payPeriod.data.pager.totalPages,
                    count: payPeriod.data.count,
                });
                this.props.setPayPeriod(payPeriod.data.rows);
            }else{
                console.error("Erreur lors de la recuperation de donnees");
            }
            return payPeriod.data.rows;
        } catch(error) {
            Logger.error("getPayPeriod","unable to get pay period",error);
            errorNotifcation(error?.response?.status, "Erreur à la récupération des périodes de paies, veuillez rafraichir la page.");
            return null;
        }
    }

    getMoreData = async () => {
        try{
            let defaultOrganization = JSON.parse(sessionStorage.getItem('defaultOrg'));
            this.setState({ scrollLoading: true });
            const res = await getPayPeriodByOrgId(defaultOrganization.id, this.state.pageSize, this.state.currentPage + 1);
            if(res?.status === 200){
                this.setState({ 
                    scrollLoading: false,
                    currentPage: res.data.pager.currentPage,
                    endIndex: res.data.pager.endIndex,
                    endPage: res.data.pager.endPage,
                    pageSize: res.data.pager.pageSize,
                    pages: res.data.pager.pages,
                    startIndex: res.data.pager.startIndex,
                    startPage: res.data.pager.startPage,
                    totalItems: res.data.pager.totalItems,
                    totalPages: res.data.pager.totalPages,
                    count: res.data.count,
                });
                this.props.addPayPeriodMultiple(res.data.rows);
            }else{
                console.error("Erreur lors de la recuperation de donnees");
            }
        }catch(e){
            console.error("error: ", e);
        }
    }


    getPeriodById = async (id) => {
        try{
            let defaultOrganization = JSON.parse(sessionStorage.getItem('defaultOrg'));
            const period = (await getPayPeriodById(defaultOrganization.id, +id)).data;
            if(period){ 
                this.props.selectPayPeriod(period);
                return period;
            }
        } catch(error) {
            Logger.error("SelectPayPreriod", "Unable to select pay period", error);
            errorNotifcation(error?.response?.status, "Impossible d'afficher les détails de la période de paie, veuillez rafraichir la page.");
            return null;
        }
        
    }

    componentDidMount(){
       this.getPayPeriod(this.state.pageSize, 1);
    }

    goToStep = (event, step) => {
        if(step <= 1 && step >= 0){
            this.setState({
                step
            })
        }
    }
    
    downloadDipe = async () => {
        if(this.props.selectedPeriod.dipeAttachement){
            let { selectedPeriod } = this.props;
            const url =  selectedPeriod.dipeAttachement;
            setTimeout(() => {
                window.open(url);
            }, 100);
        }
    }

    renderModal(){
       return(
            <Modal show={this.state.show} onHide={() => this.setState({show: !this.state.show})}>
                <Modal.Header closeButton>
                    <Modal.Title>Génération de fiche de paie</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Merci d'utiliser le système de paie DIZEN.</p>
                    <p>Pour ce premier mois, vous avez le choix entre</p>
                    <ul>
                        <li>Générer les fiches de paie depuis janvier</li>
                        <li>Indiquer uniquement les cumuls pour chaque employé.</li>
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.onGeneratePayPeriodAutomaticAction}>
                        Génération depuis janvier
                    </Button>
                    <Button variant="primary" onClick={this.onGeneratePayPeriodManuallyAction}>
                        Saisie des cumuls
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderImpots = () => {
        return(
            <div className="impots-content">
                <div className="title-content-section"> Impôts salariaux </div>
                <RowGroup className="mt-1 ml-2 mr-2">
                    <div className="row-detail-item txt-grey"> 
                        <span className="txt-thin txt-small pl-10 mr-20">Total IRPP</span>
                        <span className="txt-bold txt-medium pr-10">{`XAF ${numberFormat(+this.props.selectedPeriod?.irppTotal, 2, ",", " ")}`}</span>
                    </div>
                    <div className="row-detail-item txt-grey"> 
                        <span className="txt-thin txt-small pl-10 mr-20">Total IRPP CAC</span>
                        <span className="txt-bold txt-medium pr-10">{`XAF ${numberFormat(+this.props.selectedPeriod?.irppCacTotal, 2, ",", " ")}`}</span>
                    </div>
                    <div className="row-detail-item txt-grey"> 
                        <span className="txt-thin txt-small pl-10 mr-20">Total CFC Salarial</span>
                        <span className="txt-bold txt-medium pr-10">{`XAF ${numberFormat(+this.props.selectedPeriod?.cfcSalarialTotal, 2, ",", " ")}`}</span>
                    </div>
                    <div className="row-detail-item txt-grey"> 
                        <span className="txt-thin txt-small pl-10 mr-20">Total Redevance Audiovisuelle</span>
                        <span className="txt-bold txt-medium pr-10">{`XAF ${numberFormat(+this.props.selectedPeriod?.audioVisualTotal, 2, ",", " ")}`}</span>
                    </div>
                    <div className="row-detail-item txt-grey"> 
                        <span className="txt-thin txt-small pl-10 mr-20">Total Taxe Communale</span>
                        <span className="txt-bold txt-medium pr-10">{`XAF ${numberFormat(+this.props.selectedPeriod?.municipalTaxesTotal, 2, ",", " ")}`}</span>
                    </div>
                </RowGroup>

                <div className="title-content-section"> Impôts patronaux </div>
                <RowGroup className="mt-1 ml-2 mr-2">
                    <div className="row-detail-item txt-grey"> 
                        <span className="txt-thin txt-small pl-10 mr-20">Total CFC Patronal</span>
                        <span className="txt-bold txt-medium pr-10">{`XAF ${numberFormat(+this.props.selectedPeriod?.cfcPatronalTotal, 2, ",", " ")}`}</span>
                    </div>
                    <div className="row-detail-item txt-grey"> 
                        <span className="txt-thin txt-small pl-10 mr-20">Total FNE</span>
                        <span className="txt-bold txt-medium pr-10">{`XAF ${numberFormat(+this.props.selectedPeriod?.fneTotal, 2, ",", " ")}`}</span>
                    </div>
                </RowGroup>

                <div className="title-content-section"> Totaux impôts </div>
                <RowGroup className="mt-1 ml-2 mr-2 mb-2">
                    <div className="row-detail-item txt-grey"> 
                        <span className="txt-thin txt-small pl-10 mr-20">Total Retenues à la source principales</span>
                        <span className="txt-bold txt-medium pr-10">{`XAF ${numberFormat(+this.props.selectedPeriod?.revenueTotalToMainSource, 2, ",", " ")}`}</span>
                    </div>
                    <div className="row-detail-item txt-grey"> 
                        <span className="txt-thin txt-small pl-10 mr-20">Total Retenue CAC</span>
                        <span className="txt-bold txt-medium pr-10">{`XAF ${numberFormat(+this.props.selectedPeriod?.cacDeductionsTotal, 2, ",", " ")}`}</span>
                    </div>
                    <div className="row-detail-item txt-grey"> 
                        <span className="txt-thin txt-small pl-10 mr-20">Total retenues à la source</span>
                        <span className="txt-bold txt-medium pr-10">{`XAF ${numberFormat(+this.props.selectedPeriod?.revenueTotalSource, 2, ",", " ")}`}</span>
                    </div>
                </RowGroup>
            </div>
        )
    }

    renderCnps = () => {
        return(
            <div className="cnps-content">
                <div className="title-content-section"> CNPS salariaux </div>
                <RowGroup className="mt-1 ml-2 mr-2">
                    <div className="row-detail-item txt-grey"> 
                        <span className="txt-thin txt-small pl-10 mr-20">Total Pensions Vieillesse Salariales</span>
                        <span className="txt-bold txt-medium pr-10">{`XAF ${numberFormat(+this.props.selectedPeriod?.OldAgePensionTotal, 2, ",", " ")}`}</span>
                    </div>
                </RowGroup>

                <div className="title-content-section"> CNPS patronaux </div>
                <RowGroup className="mt-1 ml-2 mr-2">
                    <div className="row-detail-item txt-grey"> 
                        <span className="txt-thin txt-small pl-10 mr-20">Total Pensions Vieillesse Patronalles</span>
                        <span className="txt-bold txt-medium pr-10">{`XAF ${numberFormat(+this.props.selectedPeriod?.OldAgePensionPatronsTotal, 2, ",", " ")}`}</span>
                    </div>
                    <div className="row-detail-item txt-grey"> 
                        <span className="txt-thin txt-small pl-10 mr-20">Total Allocations Familiales</span>
                        <span className="txt-bold txt-medium pr-10">{`XAF ${numberFormat(+this.props.selectedPeriod?.AllocationFamilialeTotal, 2, ",", " ")}`}</span>
                    </div>
                    <div className="row-detail-item txt-grey"> 
                        <span className="txt-thin txt-small pl-10 mr-20">Total Accident du Travail</span>
                        <span className="txt-bold txt-medium pr-10">{`XAF ${numberFormat(+this.props.selectedPeriod?.workAccidentTotal, 2, ",", " ")}`}</span>
                    </div>
                </RowGroup>

                <div className="title-content-section"> Totaux CNPS </div>
                <RowGroup className="mt-1 ml-2 mr-2 mb-2">
                    <div className="row-detail-item txt-grey"> 
                        <span className="txt-thin txt-small pl-10 mr-20">Total CNPS part Patronale</span>
                        <span className="txt-bold txt-medium pr-10">{`XAF ${numberFormat(+this.props.selectedPeriod?.cnpsPatronsPartTotal, 2, ",", " ")}`}</span>
                    </div>
                    <div className="row-detail-item txt-grey"> 
                        <span className="txt-thin txt-small pl-10 mr-20">Total CNPS part Salariale</span>
                        <span className="txt-bold txt-medium pr-10">{`XAF ${numberFormat(+this.props.selectedPeriod?.cnpsSalaryPartTotal, 2, ",", " ")}`}</span>
                    </div>
                    <div className="row-detail-item txt-grey"> 
                        <span className="txt-thin txt-small pl-10 mr-20">Total CNPS</span>
                        <span className="txt-bold txt-medium pr-10">{`XAF ${numberFormat(+this.props.selectedPeriod?.cnpsTotal, 2, ",", " ")}`}</span>
                    </div>
                </RowGroup>
            </div>
        )
    }

    gotoPayslips = () => {
        if(this.props.onSelectItem){
            this.props.onSelectItem(this.props.selectedPeriod);
            this.props.selectPayPeriod(null);
        }
    }

    renderTeledeclaration = (value) => {
        if(value){
            switch(value.toLowerCase()){
                case "déclaration en attente" : {
                    return <span className='item-status gray'>{value}</span>
                }
                case "déclaration reçus" : {
                    return <span className='item-status yellow'>{value}</span>
                }
                case "paiement effectué" : {
                    return <span className='item-status blue'>{value}</span>
                }
                case "reçus" : {
                    return <span className='item-status green'>{value}</span>
                }
                default : return "";
            }
        }
        return "";
    }

    headerData = [
        { title: <span> Période  <DoubleArrow /> </span> , align: "left", className: 'pl-15 payslip-period-width', name: "period"}, 
        { title: <span> Masse salariale  <DoubleArrow /> </span> , align: "left", className: 'pl-15 payslip-payroll-width', name: "salarymass"}, 
        { title: <span> Télédéclaration  <DoubleArrow /> </span> , align: "left", className: ' payslip-declaration-width hide-xs-responsive sm-hidden pl-15', name: "statusDecleration"}, 
        { title: <span> Impôts  <DoubleArrow /> </span> , align: "left", className: 'payslip-tax-width hide-xs-responsive sm-hidden pl-15', name: "taxes"}, 
    ];

    formatBodyData = (data) => {
        return data.map((item) => {
            let resData = {
                originalData: item,
                period: <><span className="txt-medium mr-1">{`${moment(item.dateDebut).format("MMMM")}`} </span><span className="txt-medium" > {`${moment(item.dateDebut).format("YYYY")}`}</span></>,
                salarymass: <><span className="txt-bold txt-medium" >{ numberFormat(+item?.salaryMass, 2, ",", " ") + ` ${ sessionStorage.getItem('defaultCurrency') ? JSON.parse(sessionStorage.getItem('defaultCurrency')) : '' }`}</span></>,
                statusDecleration: <>{`${this.renderTeledeclaration(item.statusDeclaration)}`}</>,
                taxes: <>{`${this.renderTeledeclaration(item.statusImpots)}`}</>,
            }
            return resData;
        })
    }

    render(){
        const statusOptions = [
            {value: "Declaration en attente"},
            {value: "Declaration reçus"},
            {value: "Paiement effectué"},
            {value: "Reçus"}
        ];
        const styles = {
            tabs: {
                background: '#fff',
                fontWeight: "bold",
            },
            indicator: {
                backgroundColor: '#177BD1',
            }
        };
        const {step, loadPeriod} = this.state;
        return(
            <div className='pay-period  col-display'>
                <div className="d-flex flex-column flex-1-auto pl-3" style={{width: this.state.tableContainerWidth}}>
                    <div className="options-control-header pt-20 mr-3">
                        <div className="head-option-left">
                            <button className='btn-plus' onClick={() => this.generatePayPeriodAction()}>
                                <IconPlus width={9} height={9} />
                            </button>
                            <Loader loadData={loadPeriod} className="ml-3" style={{width: 28, height: 28}} />
                        </div>
                        <Search handleSearch={() => {}} iconClass='search-social' />
                    </div>
                    <div className='mt-4 mr-3'>
                        <Suspense fallback={<div className="text-center">Chargement... </div>} >
                            <GenericTable
                                data={this.props.payPeriods}
                                onRef={this.setRefGenericTable}
                                header={this.headerData}
                                body={this.formatBodyData(this.props.payPeriods)}
                                className=''
                                isLoading={this.state.loadData}
                                asLoader={<Loader enable style={{width: "25px", height: "25px"}} />}
                                onSelect={(item) => this.onSelectItem(item.originalData)}
                                infiniteScroll
                                getData={this.getMoreData}
                                hasMore={(this.state.currentPage < this.state.endPage)}
                                scrollLoading={this.state.scrollLoading}
                            />
                        </Suspense>
                    </div>

                    <div className={ this.props.modalCreate ? ' social-detail' : 'd-none'}>
                            <div style={{ width: 300, backgroundColor: "#FFFFFF"}}></div>
                    </div>
                    {
                        this.renderModal()
                    }
                </div>
                <div className={`social-detail ${(this.state.showDetail ? "w-450" : "d-none")}`}>
                    <DetailContainer title={`Detail de period de paie ${moment(this.props.selectedPeriod?.dateDebut).format("MMMM YYYY")}`} onClose={this.closeTab}>
                        <div className="d-flex align-items-center txt-grey">
                            <span className="txt-thin txt-small pl-10 mr-20">Masse salariale:</span>
                            <span className="txt-bold txt-medium">{`XAF ${numberFormat(+this.props.selectedPeriod?.salaryMass, 2, ",", " ")}`}</span>
                        </div>
                        <div className="d-flex align-items-center txt-grey pl-10 mt-10">
                            <CustomBtn className="btn bg-dark-grey round-3 btn-center round-3 w-140 h-30" onClick={this.gotoPayslips}>
                                <DetailIcon />
                                <span className="txt-bold txt-smaller text-white text-nowrap ml-2">Visualiser</span> 
                            </CustomBtn>
                            <CustomBtn className={`btn bg-dark-grey round-3 btn-center round-3 w-140 h-30 ml-2 ${(!this.props.selectedPeriod?.dipeAttachement) ? 'btn-disabled' : ''}`}>
                                <DownloadIcon style={{transform: "scale(2)"}} />
                                <span className="txt-bold txt-smaller text-white text-nowrap ml-2" onClick={this.downloadDipe}>Télécharger le DIPE</span> 
                            </CustomBtn>
                        </div>
                        <div className="d-flex align-items-center txt-grey pl-10 mt-10">
                            <div className="d-flex align-items-center justify-content-between w-140 h-30 ">
                                <span className="txt-thin txt-small mr-20">Impôts</span>
                                <EditableInput type="select" options={statusOptions} optionValue={'value'} name={"impots"} className="txt-thin txt-small text-nowrap txt-grey" onChange={this.onChangeStatus} placeholder="Statut" />
                            </div>
                            <div className="d-flex align-items-center justify-content-between w-140 h-30 ml-2">
                                <span className="txt-thin txt-small mr-20">CNPS</span>
                                <EditableInput type="select" options={statusOptions} optionValue={'value'} name={"cnps"} className="txt-thin txt-small text-nowrap txt-grey" onChange={this.onChangeStatus} placeholder="Statut" />
                            </div>
                        </div>
                        <div className="modal-detail-preview mt-2">
                            <Tabs value={step} onChange={this.goToStep} TabIndicatorProps={{style: {backgroundColor: '#177BD1', borderRadius: '15px'}}} style={styles.tabs}>
                                <Tab label="Impôts" />
                                <Tab label="CNPS" />
                            </Tabs>
                            <SwipeableViews index={step} onChangeIndex={this.goToStep}>
                                <div className="">{this.renderImpots()}</div>
                                <div className="">{this.renderCnps()}</div>
                            </SwipeableViews>
                        </div>

                    </DetailContainer>
                </div>
            </div>
            
        )
    }
}

const mapStateToProps = (state) => {
    const { staffs, organizations, payPeriod } = state
    return {
        staffMembers: staffs.staffMembers,
        defaultOrganization: organizations.defaultOrganization,
        payPeriods: payPeriod.period,
        selectedPeriod: payPeriod.selectedPeriod
    }
}

export default connect(mapStateToProps, { setStaffs, setPayPeriod, addPayPeriod, addPayPeriodMultiple, selectPayPeriod })(PayslipsViewList);