import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateOrganizationTaxes } from "../../redux/action/OrganisationAction";
import { 
    selectTaxe, handleChange, onChangeTable8, onChangeTable9,
    onChangeTable10, onChangeTable11, onChangeTable12, onChangeTable15,
} from "../../redux/action/TaxesAction";
// import { numberFormat } from '../../helpers/Number';



class StepThreeForm extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        this.init();
        if(this.props.onRef){
            this.props.onRef(this);
        }
    }

    init = () => {
        if(!this.props.selectedPeriod){
            this.props.history.push('/taxes');
        };
    }

    getPayload = () => {
        return {
            prepaymentsToBeDeducted: { 
                advanceOnCAWithheldAtSource: {base: this.props.selectedTaxes.l45Base, principal: this.props.selectedTaxes.l45Principal, cac: this.props.selectedTaxes.l45Cac , penalities: this.props.selectedTaxes.l45Penalties , total: this.props.selectedTaxes.l45Total },
                prepaymentsOnWithheldPurchases: {base: this.props.selectedTaxes.l46Base, principal: this.props.selectedTaxes.l46Principal, penalities: this.props.selectedTaxes.l46Penalties , rate: this.props.selectedTaxes.l46Rate , total: this.props.selectedTaxes.l46Total },
                prepaymentsOf15OnRents: {base: this.props.selectedTaxes.l47Base, principal: this.props.selectedTaxes.l47Principal, penalities: this.props.selectedTaxes.l47Penalties , total: this.props.selectedTaxes.l47Total },
                withholdingTaxOnRemuneration: {base: this.props.selectedTaxes.l48Base, principal: this.props.selectedTaxes.l48Principal, cac: this.props.selectedTaxes.l48Cac , penalities: this.props.selectedTaxes.l48Penalties , total: this.props.selectedTaxes.l48Total },
                total: {base: this.props.selectedTaxes.l49Base, principal: this.props.selectedTaxes.l49Principal, cac: this.props.selectedTaxes.l49Cac , penalities: this.props.selectedTaxes.l49Penalties , total: this.props.selectedTaxes.l49Total },
            },

            liquidationAdvancePayments: {
                advancePaymentOnDeclareCA: {base: this.props.selectedTaxes.l50Base, principal: this.props.selectedTaxes.l50Principal, cac: this.props.selectedTaxes.l50Cac, penalities: this.props.selectedTaxes.l50Penalties, rate: this.props.selectedTaxes.l50Rate, total: this.props.selectedTaxes.l50Total },
                downPaymentOf15OnRents: {base: this.props.selectedTaxes.l51Base, principal: this.props.selectedTaxes.l51Principal, cac: this.props.selectedTaxes.l51Cac, penalities: this.props.selectedTaxes.l51Penalties, total: this.props.selectedTaxes.l51Total },
                deductionsToBeMade: {base: this.props.selectedTaxes.l52Base, principal: this.props.selectedTaxes.l52Principal, cac: this.props.selectedTaxes.l52Cac, penalities: this.props.selectedTaxes.l52Penalties, total: this.props.selectedTaxes.l52Total },
                previousCredit: {base: this.props.selectedTaxes.l53Base, principal: this.props.selectedTaxes.l53Principal, cac: this.props.selectedTaxes.l53Cac, penalities: this.props.selectedTaxes.l53Penalties , total: this.props.selectedTaxes.l53Total },
                depositToBePaid: {base: this.props.selectedTaxes.l54Base, principal: this.props.selectedTaxes.l54Principal, cac: this.props.selectedTaxes.l54Cac, penalities: this.props.selectedTaxes.l54Penalties , total: this.props.selectedTaxes.l54Total },
                taxCreditToBeDeferred: {base: this.props.selectedTaxes.l55Base, principal: this.props.selectedTaxes.l55Principal, cac: this.props.selectedTaxes.l55Cac, penalities: this.props.selectedTaxes.l55Penalties , total: this.props.selectedTaxes.l55Total },
            },

            withholdingsOnIncome: {
                incomeFromShares: {base: this.props.selectedTaxes.l56Base, paymentDate: this.props.selectedTaxes.l56PaymentDate, principal: this.props.selectedTaxes.l56Principal, cac: this.props.selectedTaxes.l56Cac, penalities: this.props.selectedTaxes.l56Penalties , total: this.props.selectedTaxes.l56Total },
                dividendsPaidOutside: {base: this.props.selectedTaxes.l57Base, paymentDate: this.props.selectedTaxes.l57PaymentDate, principal: this.props.selectedTaxes.l57Principal, cac: this.props.selectedTaxes.l57Cac, penalities: this.props.selectedTaxes.l57Penalties, total: this.props.selectedTaxes.l57Total },
                remunerationOfDirectors: {base: this.props.selectedTaxes.l58Base, paymentDate: this.props.selectedTaxes.l58PaymentDate, principal: this.props.selectedTaxes.l58Principal, cac: this.props.selectedTaxes.l58Cac, penalities: this.props.selectedTaxes.l58Penalties, total: this.props.selectedTaxes.l58Total },
                bondIncome: {base: this.props.selectedTaxes.l59Base, paymentDate: this.props.selectedTaxes.l59PaymentDate, principal: this.props.selectedTaxes.l59Principal, cac: this.props.selectedTaxes.l59Cac, penalities: this.props.selectedTaxes.l59Penalties , total: this.props.selectedTaxes.l59Total },
                incomeFromReceivables: {base: this.props.selectedTaxes.l60Base, paymentDate: this.props.selectedTaxes.l60PaymentDate, principal: this.props.selectedTaxes.l60Principal, cac: this.props.selectedTaxes.l60Cac, penalities: this.props.selectedTaxes.l60Penalties , total: this.props.selectedTaxes.l60Total },
                gainsOnDisposalOfShares: {base: this.props.selectedTaxes.l61Base, paymentDate: this.props.selectedTaxes.l61PaymentDate, principal: this.props.selectedTaxes.l61Principal, cac: this.props.selectedTaxes.l61Cac, penalities: this.props.selectedTaxes.l61Penalties , total: this.props.selectedTaxes.l61Total },
                total: {base: this.props.selectedTaxes.l62Base, paymentDate: this.props.selectedTaxes.l62PaymentDate, principal: this.props.selectedTaxes.l62Principal, cac: this.props.selectedTaxes.l62Cac, penalities: this.props.selectedTaxes.l62Penalties , total: this.props.selectedTaxes.l62Total },
            },

            taxesOnNonCommercialIncome: {
                remunerationForBoardSessions: {base: this.props.selectedTaxes.l63Base, rate: this.props.selectedTaxes.l63Rate, principal: this.props.selectedTaxes.l63Principal, cac: this.props.selectedTaxes.l63Cac, penalities: this.props.selectedTaxes.l63Penalties , total: this.props.selectedTaxes.l63Total },
                bonusesGratuitiesAndOthers: {base: this.props.selectedTaxes.l64Base, rate: this.props.selectedTaxes.l64Rate, principal: this.props.selectedTaxes.l64Principal, cac: this.props.selectedTaxes.l64Cac, penalities: this.props.selectedTaxes.l64Penalties , total: this.props.selectedTaxes.l64Total },
                remunerationOfArtists: {base: this.props.selectedTaxes.l65Base, rate: this.props.selectedTaxes.l65Rate, principal: this.props.selectedTaxes.l65Principal, cac: this.props.selectedTaxes.l65Cac, penalities: this.props.selectedTaxes.l65Penalties , total: this.props.selectedTaxes.l65Total },
                total: {base: this.props.selectedTaxes.l66Base, rate: this.props.selectedTaxes.l66Rate, principal: this.props.selectedTaxes.l66Principal, cac: this.props.selectedTaxes.l66Cac, penalities: this.props.selectedTaxes.l66Penalties , total: this.props.selectedTaxes.l66Total },
            },

            withholdingTaxesOnSalaries: {
                irpp: {base: this.props.selectedTaxes.l67Base, principal: this.props.selectedTaxes.l67Principal, cac: this.props.selectedTaxes.l67Cac, penalities: this.props.selectedTaxes.l67Penalties, total: this.props.selectedTaxes.l67Total },
                salarialCfc: {base: this.props.selectedTaxes.l68Base, principal: this.props.selectedTaxes.l68Principal, penalities: this.props.selectedTaxes.l68Penalties, total: this.props.selectedTaxes.l68Total },
                patronalCfc: {base: this.props.selectedTaxes.l69Base, principal: this.props.selectedTaxes.l69Principal, penalities: this.props.selectedTaxes.l69Penalties, total: this.props.selectedTaxes.l69Total },
                fne: {base: this.props.selectedTaxes.l70Base, principal: this.props.selectedTaxes.l70Principal, penalities: this.props.selectedTaxes.l70Penalties , total: this.props.selectedTaxes.l70Total },
                rav: {base: this.props.selectedTaxes.l71Base, principal: this.props.selectedTaxes.l71Principal, penalities: this.props.selectedTaxes.l71Penalties , total: this.props.selectedTaxes.l71Total },
                localDvlptTax: {base: this.props.selectedTaxes.l72Base, principal: this.props.selectedTaxes.l72Principal, penalities: this.props.selectedTaxes.l72Penalties , total: this.props.selectedTaxes.l72Total },
                total: {base: this.props.selectedTaxes.l73Base, principal: this.props.selectedTaxes.l73Principal, cac: this.props.selectedTaxes.l73Cac, penalities: this.props.selectedTaxes.l73Penalties , total: this.props.selectedTaxes.l73Total },
            },
        };
    }

    handleChange = (e, callbacks = null) => {
        this.props.handleChange(e);
        if (callbacks) {
            callbacks();
        }
    }

    render() {

        return <div className="step-three">
            {/* TABLEAU 8 */}
            <div className="d-flex align-items-center justify-content-between mt-2 pl-2 pr-2">
                <table className="table-taxes">
                    <thead>
                        <tr align="center">
                            <td width="40%" align="left"><span className="sm-txt bold pl-2">8 - ACOMPTES ET PRÉCOMPTES À DEDUIRE</span></td>
                          <td colSpan="4" width="15%"><span className="sm-txt bold">BASE</span></td>
                            <td width="10%"><span className="sm-txt bold">PRINCIPAL</span></td>
                            <td width="10%"><span className="sm-txt bold">CAC</span></td>
                            <td width="10%"><span className="sm-txt bold">PÉNALITÉS </span></td>
                            <td width="15%"><span className="sm-txt bold">TOTAL</span></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span className="sm-txt pl-2">Acompte sur CA  retenu à la source</span></td>
                            <td align="center" className="bordered-bottom"> <span className="sm-txt  bold line-indicator bold">L45</span> </td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l45Base" value={this.props.selectedTaxes.l45Base} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable8(); this.props.onChangeTable9()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l45Principal" value={this.props.selectedTaxes.l45Principal} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable8(); this.props.onChangeTable9()})} className="input-text text-right pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l45Cac" value={this.props.selectedTaxes.l45Cac} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable8(); this.props.onChangeTable9()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l45Penalties" value={this.props.selectedTaxes.l45Penalties} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable8(); this.props.onChangeTable9()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l45Total" value={this.props.selectedTaxes.l45Total || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable8(); this.props.onChangeTable9()})} className="input-text text-right-h pr-2 bold" disabled /></td>
                        </tr>
                        <tr>
                            <td>
                                <span className="sm-txt pl-2 align-items-center" style={{ float: "left" }}>Précomptes sur achats</span>
                                <div className="d-flex align-items-center ml-5" style={{ float: "left" }}>
                                    <label className="d-flex align-items-center checkbox ml-2 mr-2 mb-0"><span className="mr-2 sm-txt">0.5%</span> <input type="checkbox" name="l46Rate" id="l46CheckBox0p5" value={0.5} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable8(); this.props.onChangeTable9()})} className="table-checkbox"  checked={+this.props.selectedTaxes.l46Rate === 0.5 || this.props.selectedTaxes.l46CheckBox0p5} /></label>
                                    <label className="d-flex align-items-center checkbox ml-2 mr-2 mb-0"><span className="mr-2 sm-txt">1%</span> <input type="checkbox" name="l46Rate" id="l46CheckBox1" value={1} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable8(); this.props.onChangeTable9()})} className="table-checkbox"  checked={+this.props.selectedTaxes.l46Rate === 1 || this.props.selectedTaxes.l46CheckBox1} /></label>
                                    <label className="d-flex align-items-center checkbox ml-2 mr-2 mb-0"><span className="mr-2 sm-txt">3%</span> <input type="checkbox" name="l46Rate" id="l46CheckBox3" value={3} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable8(); this.props.onChangeTable9()})} className="table-checkbox"  checked={+this.props.selectedTaxes.l46Rate === 3 || this.props.selectedTaxes.l46CheckBox3} /></label>
                                    <label className="d-flex align-items-center checkbox ml-2 mr-2 mb-0"><span className="mr-2 sm-txt">5%</span> <input type="checkbox" name="l46Rate" id="l46CheckBox5" value={5} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable8(); this.props.onChangeTable9()})} className="table-checkbox" checked={+this.props.selectedTaxes.l46Rate === 5 || this.props.selectedTaxes.l46CheckBox5} /> </label>
                                </div>
                            </td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L46</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l46Base" value={this.props.selectedTaxes.l46Base} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable8(); this.props.onChangeTable9()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l46Principal" value={this.props.selectedTaxes.l46Principal} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable8(); this.props.onChangeTable9()})} className="input-text text-right pr-2" /></td>
                            <td align="right" className="border-dashed" bgcolor="#EFEFEF"><input type="text" name="l46Cac" value={this.props.selectedTaxes.l46Cac} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable8(); this.props.onChangeTable9()})} className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l46Penalties" value={this.props.selectedTaxes.l46Penalties} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable8(); this.props.onChangeTable9()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l46Total" value={+this.props.selectedTaxes.l46Total || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable8(); this.props.onChangeTable9()})} className="input-text text-right-h pr-2 bold" disabled /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Précomptes 15% sur loyers (déductible sur IR)</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L47</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l47Base" value={this.props.selectedTaxes.l47Base} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable8(); this.props.onChangeTable9()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l47Principal" value={this.props.selectedTaxes.l47Principal} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable8(); this.props.onChangeTable9()})} className="input-text text-right pr-2" /></td>
                            <td align="right" className="border-dashed" bgcolor="#EFEFEF"><input type="text" name="l47Cac" value={this.props.selectedTaxes.l47Cac} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable8(); this.props.onChangeTable9()})} className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l47Penalties" value={this.props.selectedTaxes.l47Penalties} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable8(); this.props.onChangeTable9()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l47Total" value={+this.props.selectedTaxes.l47Total || ''} placeholder="" disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Précomptes sur rémunérations et honoraires (5%)</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L48</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l48Base" value={this.props.selectedTaxes.l48Base} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable8(); this.props.onChangeTable9()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l48Principal" value={this.props.selectedTaxes.l48Principal} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable8(); this.props.onChangeTable9()})} className="input-text text-right pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l48Cac" value={this.props.selectedTaxes.l48Cac} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable8(); this.props.onChangeTable9()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l48Penalties" value={this.props.selectedTaxes.l48Penalties} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable8(); this.props.onChangeTable9()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l48Total" value={+this.props.selectedTaxes.l48Total || ''} placeholder="" disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">TOTAL= (Somme L 45 à  L48)</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt bold line-indicator bold">L49</span></td>
                            <td align="right" colSpan="3" className="bordered"><input type="text" name="l49Base" value={+this.props.selectedTaxes.l49Base || ''} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l49Principal" value={+this.props.selectedTaxes.l49Principal || ''} placeholder="" className="input-text bold text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l49Cac" value={+this.props.selectedTaxes.l49Cac || ''} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l49Penalties" value={+this.props.selectedTaxes.l49Penalties || ''} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l49Total"value={+this.props.selectedTaxes.l49Total || ''} placeholder="" className="input-text text-right-h pr-2 bold" disabled /></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* TABLEAU 9 */}
            <div className="d-flex align-items-center justify-content-between mt-4 pl-2 pr-2">
                <table className="table-taxes">
                    <thead>
                        <tr align="center">
                            <td width="40%" align="left"><span className="sm-txt bold pl-2">9 - LIQUIDATION ACOMPTES IMPOTS SUR LE REVENU</span></td>
                            <td colSpan="4" width="15%"><span className="sm-txt bold">BASE</span></td>
                            <td width="10%"><span className="sm-txt bold">PRINCIPAL</span></td>
                            <td width="10%"><span className="sm-txt bold">CAC</span></td>
                            <td width="10%"><span className="sm-txt bold">PÉNALITÉS </span></td>
                            <td width="15%"><span className="sm-txt bold">TOTAL</span></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <span className="sm-txt pl-2 align-items-center" style={{ float: "left" }}>Acompte sur CA déclaré</span>
                                <div className="d-flex align-items-center ml-5" style={{ float: "left" }}>
                                    <label className="d-flex align-items-center checkbox ml-2 mr-2 mb-0"><span className="mr-2 sm-txt">0.5%</span> <input type="checkbox" name="l50Rate" id="l50CheckBox0p5" value={0.5}  onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable9(); this.props.onChangeTable15()})} checked={+this.props.selectedTaxes.l50Rate === 0.5 || this.props.selectedTaxes.l50CheckBox0p5} className="table-checkbox"  /></label>
                                    <label className="d-flex align-items-center checkbox ml-2 mr-2 mb-0"><span className="mr-2 sm-txt">2%</span> <input type="checkbox" name="l50Rate" id="l50CheckBox2" value={2}  onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable9(); this.props.onChangeTable15()})} checked={+this.props.selectedTaxes.l50Rate === 2 || this.props.selectedTaxes.l50CheckBox2} className="table-checkbox"  /></label>
                                    <label className="d-flex align-items-center checkbox ml-2 mr-2 mb-0"><span className="mr-2 sm-txt">3%</span> <input type="checkbox" name="l50Rate" id="l50CheckBox3" value={3}  onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable9(); this.props.onChangeTable15()})} checked={+this.props.selectedTaxes.l50Rate === 3 || this.props.selectedTaxes.l50CheckBox3} className="table-checkbox"  /></label>
                                    <label className="d-flex align-items-center checkbox ml-2 mr-2 mb-0"><span className="mr-2 sm-txt">5%</span> <input type="checkbox" name="l50Rate" id="l50CheckBox5" value={5}  onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable9(); this.props.onChangeTable15()})} checked={+this.props.selectedTaxes.l50Rate === 5 || this.props.selectedTaxes.l50CheckBox5} className="table-checkbox"  /></label>

                                </div>
                            </td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L50</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l50Base" value={this.props.selectedTaxes.l50Base || ''}  onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable9(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="center" className="border-dashed"><input type="text" name="l50Principal" value={this.props.selectedTaxes.l50Principal || ''}  onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable9(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l50Cac" value={this.props.selectedTaxes.l50Cac || ''}  onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable9(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l50Penalties" value={this.props.selectedTaxes.l50Penalties || ''}  onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable9(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l50Total" value={this.props.selectedTaxes.l50Total || ''}  onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable9(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2 bold" disabled /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Acompte de 15% sur loyers perçus</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L51</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l51Base" value={this.props.selectedTaxes.l51Base || ''}  onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable9(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="center" className="border-dashed"><input type="text" name="l51Principal" value={this.props.selectedTaxes.l51Principal || ''}  onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable9(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l51Cac" value={this.props.selectedTaxes.l51Cac || ''}  onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable9(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l51Penalties" value={this.props.selectedTaxes.l51Penalties || ''}  onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable9(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l51Total" value={this.props.selectedTaxes.l51Total || ''}  onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable9(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2 bold" disabled /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Déductions à opérer = L49</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L52</span></td>
                            <td align="right" colSpan="3" className="bordered"><input type="text" name="l52Base" value={this.props.selectedTaxes.l52Base || ''}  onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable9(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" disabled /></td>
                            <td align="center" className="bordered"><input type="text" name="l52Principal" value={this.props.selectedTaxes.l52Principal || ''}  onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable9(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l52Cac" value={this.props.selectedTaxes.l52Cac || ''}  onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable9(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l52Penalties" value={this.props.selectedTaxes.l52Penalties || ''}  onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable9(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l52Total" value={this.props.selectedTaxes.l52Total || ''}  onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable9(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2 bold" disabled /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Crédit antérieur =( L 55 déclaration précédente)</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt bold line-indicator bold">L53</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l53Base" value={this.props.selectedTaxes.l53Base || ''}  onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable9(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="center" className="border-dashed"><input type="text" name="l53Principal" value={this.props.selectedTaxes.l53Principal || ''}  onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable9(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l53Cac" value={this.props.selectedTaxes.l53Cac || ''}  onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable9(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l53Penalties" value={this.props.selectedTaxes.l53Penalties || ''}  onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable9(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l53Total" value={this.props.selectedTaxes.l53Total || ''}  onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable9(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2 bold" disabled /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Acompte à payer = Solde [(L50 + L51) - (L52 + L53)]</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt bold line-indicator bold">L54</span></td>
                            <td align="right" colSpan="3" className="bordered"><input type="text" name="l54Base" value={this.props.selectedTaxes.l54Base || ''} className="input-text text-right-h pr-2" disabled /></td>
                            <td align="center" className="bordered"><input type="text" name="l54Principal" value={this.props.selectedTaxes.l54Principal || ''} className="input-text bold text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l54Cac" value={this.props.selectedTaxes.l54Cac || ''} className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l54Penalties" value={this.props.selectedTaxes.l54Penalties || ''} className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l54Total" value={this.props.selectedTaxes.l54Total || ''} className="input-text text-right-h pr-2 bold" disabled /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Crédit d’impôt à reporter = (Solde négatif L 54)</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt bold line-indicator bold">L55</span></td>
                            <td align="right" colSpan="3" className="bordered"><input type="text" name="l55Base" value={this.props.selectedTaxes.l55Base || ''} className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l55Principal" value={this.props.selectedTaxes.l55Principal || ''} className="input-text bold text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l55Cac" value={this.props.selectedTaxes.l55Cac || ''} className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l55Penalties" value={this.props.selectedTaxes.l55Penalties || ''} className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l55Total" value={this.props.selectedTaxes.l55Total || ''} className="input-text text-right-h pr-2 bold" disabled /></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* TABLEAU 10 */}
            <div className="d-flex align-items-center justify-content-between mt-4 pl-2 pr-2">
                <table className="table-taxes">
                    <thead>
                        <tr align="center">
                            <td width="40%" align="left"><span className="sm-txt bold pl-2">10 - RETENUES SUR REVENUS DE CAPITAUX MOBILIERS</span></td>
                            <td colSpan="4" width="15%"><span className="sm-txt bold">BASE</span></td>
                            <td width="10%"><span className="sm-txt bold">DATE  PAIEMENT</span></td>
                            <td width="10%"><span className="sm-txt bold">PRINCIPAL (15%)</span></td>
                            <td width="10%"><span className="sm-txt bold">CAC (10%)</span></td>
                            <td width="10%"><span className="sm-txt bold">PÉNALITÉS </span></td>
                            <td width="15%"><span className="sm-txt bold">TOTAL</span></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <span className="sm-txt pl-2 align-items-center">Revenus des Actions, parts de capital et  assimilés</span>
                            </td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L56</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l56Base" value={this.props.selectedTaxes.l56Base || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable10(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="center" className="border-dashed"><input type="text" name="l56PaymentDate" value={this.props.selectedTaxes.l56PaymentDate || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable10(); this.props.onChangeTable15()})} className="input-text bold text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed" ><input type="text" name="l56Principal" value={this.props.selectedTaxes.l56Principal || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable10(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed" ><input type="text" name="l56Cac" value={this.props.selectedTaxes.l56Cac || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable10(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l56Penalties" value={this.props.selectedTaxes.l56Penalties || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable10(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l56Total" value={this.props.selectedTaxes.l56Total || ''} disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Dividendes payés hors du Cameroun</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L57</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l57Base" value={this.props.selectedTaxes.l57Base || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable10(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="center" className="border-dashed"><input type="text" name="l57PaymentDate" value={this.props.selectedTaxes.l57PaymentDate || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable10(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l57Principal" value={this.props.selectedTaxes.l57Principal || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable10(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l57Cac" value={this.props.selectedTaxes.l57Cac || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable10(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l57Penalties" value={this.props.selectedTaxes.l57Penalties || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable10(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l57Total" value={this.props.selectedTaxes.l57Total || '' } disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Remunération des dirigeants et jetons de présence</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L58</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l58Base" value={this.props.selectedTaxes.l58Base || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable10(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="center" className="border-dashed"><input type="text" name="l58PaymentDate" value={this.props.selectedTaxes.l58PaymentDate || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable10(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l58Principal" value={this.props.selectedTaxes.l58Principal || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable10(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l58Cac" value={this.props.selectedTaxes.l58Cac || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable10(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l58Penalties" value={this.props.selectedTaxes.l58Penalties || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable10(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l58Total" value={this.props.selectedTaxes.l58Total || ''} disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Revenus des obligations</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt bold line-indicator bold">L59</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l59Base" value={this.props.selectedTaxes.l59Base || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable10(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="center" className="border-dashed"><input type="text" name="l59PaymentDate" value={this.props.selectedTaxes.l59PaymentDate || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable10(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l59Principal" value={this.props.selectedTaxes.l59Principal || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable10(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l59Cac" value={this.props.selectedTaxes.l59Cac || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable10(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l59Penalties" value={this.props.selectedTaxes.l59Penalties || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable10(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l59Total" value={this.props.selectedTaxes.l59Total || ''} disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Revenus créances, dépôts, cautionnements</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt bold line-indicator bold">L60</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l60Base" value={this.props.selectedTaxes.l60Base || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable10(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="center" className="border-dashed"><input type="text" name="l60PaymentDate" value={this.props.selectedTaxes.l60PaymentDate || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable10(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l60Principal" value={this.props.selectedTaxes.l60Principal || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable10(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l60Cac" value={this.props.selectedTaxes.l60Cac || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable10(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l60Penalties" value={this.props.selectedTaxes.l60Penalties || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable10(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l60Total" value={this.props.selectedTaxes.l60Total || ''} disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Gains sur cession d’actions, d’obligations</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt bold line-indicator bold">L61</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l61Base" value={this.props.selectedTaxes.l61Base || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable10(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="center" className="border-dashed"><input type="text" name="l61PaymentDate" value={this.props.selectedTaxes.l61PaymentDate || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable10(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l61Principal" value={this.props.selectedTaxes.l61Principal || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable10(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l61Cac" value={this.props.selectedTaxes.l61Cac || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable10(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l61Penalties" value={this.props.selectedTaxes.l61Penalties || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable10(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l61Total" value={this.props.selectedTaxes.l61Total || ''} disable className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2 text-center center d-flex justify-content-center">TOTAL= (Somme L 56 à  L61)</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt bold line-indicator bold">L62</span></td>
                            <td align="right" colSpan="3" className="bordered"><input type="text" name="l62Base" value={this.props.selectedTaxes.l62Base || '' } className="input-text text-right-h pr-2" disabled /></td>
                            <td align="center" className="bordered"><input type="text" name="l62PaymentDate" value={this.props.selectedTaxes.l62PaymentDate || '' } className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l62Principal" value={this.props.selectedTaxes.l62Principal || '' } className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l62Cac" value={this.props.selectedTaxes.l62Cac || '' } className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l62Penalties" value={this.props.selectedTaxes.l62Penalties || '' } className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l62Total" value={this.props.selectedTaxes.l62Total || ''} className="input-text text-right-h pr-2 bold" disabled /></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* TABLEAU 11 */}
            <div className="d-flex align-items-center justify-content-between mt-4 pl-2 pr-2">
                <table className="table-taxes">
                    <thead>
                        <tr align="center">
                            <td width="40%" align="left"><span className="sm-txt bold pl-2">11 - IMPÔTS SUR LES REVENUS NON COMMERCIAUXRETENUS À LA SOURCE <sup>*</sup></span></td>
                            <td colSpan="4" width="15%"><span className="sm-txt bold">BASE</span></td>
                            <td width="5%"><span className="sm-txt bold">TAUX</span></td>
                            <td width="10%"><span className="sm-txt bold">PRINCIPAL</span></td>
                            <td width="10%"><span className="sm-txt bold">CAC (10%)</span></td>
                            <td width="10%"><span className="sm-txt bold">PÉNALITÉS </span></td>
                            <td width="10%"><span className="sm-txt bold">TOTAL</span></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span className="sm-txt pl-2 align-items-center">Rémunérations des sessions des conseils d’administration</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L63</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l63Base" value={this.props.selectedTaxes.l63Base || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable11(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="center" className="border"><input type="text" name="l63Rate" value="15%" className="input-text input-align-center pr-2" disabled /></td>
                            <td align="right" className="border-dashed" ><input type="text" name="l63Principal" value={this.props.selectedTaxes.l63Principal || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable11(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed" ><input type="text" name="l63Cac" value={this.props.selectedTaxes.l63Cac || '' } onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable11(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l63Penalties" value={this.props.selectedTaxes.l63Penalties || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable11(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l63Total" value={this.props.selectedTaxes.l63Total || ''} disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Primes, gratifications, indemnités et perdiem descommissions et comités ad-hoc ou permanents</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L64</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l64Base" value={this.props.selectedTaxes.l64Base || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable11(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="center" className="bordered"><input type="text" name="l64Rate" value="15%" className="input-text input-align-center pr-2" disabled /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l64Principal" value={this.props.selectedTaxes.l64Principal || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable11(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l64Cac" value={this.props.selectedTaxes.l64Cac || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable11(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l64Penalties" value={this.props.selectedTaxes.l64Penalties || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable11(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l64Total" value={this.props.selectedTaxes.l64Total || ''} disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Rémunérations des artistes et sportifs</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L65</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l65Base" value={this.props.selectedTaxes.l65Base || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable11(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="center" className="bordered"><input type="text" name="l65Rate" value="15%" className="input-text input-align-center pr-2" disabled /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l65Principal" value={this.props.selectedTaxes.l65Principal || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable11(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l65Cac" value={this.props.selectedTaxes.l65Cac || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable11(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l65Penalties" value={this.props.selectedTaxes.l65Penalties || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable11(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l65Total" value={this.props.selectedTaxes.l65Total || ''} disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2 text-center center d-flex justify-content-center"> Total = (Somme L 63 à  L65)</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt bold line-indicator bold">L66</span></td>
                            <td align="right" colSpan="3" className="bordered"><input disabled type="text" name="l66Base" value={this.props.selectedTaxes.l66Base || ''} className="input-text text-right-h pr-2" /></td>
                            <td align="center" className="bordered"><input disabled type="text" name="l66Rate" value={this.props.selectedTaxes.l66Rate || ''} className="input-text input-align-center pr-2" /></td>
                            <td align="right" className="bordered"><input disabled type="text" name="l66Principal" value={this.props.selectedTaxes.l66Principal || ''} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input disabled type="text" name="l66Cac" value={this.props.selectedTaxes.l66Cac || ''} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input disabled type="text" name="l66Penalties" value={this.props.selectedTaxes.l66Penalties || ''} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input disabled type="text" name="l66Total" value={this.props.selectedTaxes.l66Total || ''} className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                    </tbody>
                    <p><span className="ml-3 sm-txt"><sup>*</sup> Joindre l’état nominatif.</span></p>
                </table>
            </div>

            {/* TABLEAU 12 */}
            <div className="d-flex align-items-center justify-content-between mt-4 pl-2 pr-2">
                <table className="table-taxes">
                    <thead>
                        <tr align="center">
                            <td width="40%" align="left"><span className="sm-txt bold pl-2">12 - IMPÔTS RETENUS SUR SALAIRES </span></td>
                            <td colSpan="4" width="12%"><span className="sm-txt bold">BASE</span></td>
                            <td width="12%"><span className="sm-txt bold">PRINCIPAL</span></td>
                            <td width="12%"><span className="sm-txt bold">CAC</span></td>
                            <td width="12%"><span className="sm-txt bold">PÉNALITÉS </span></td>
                            <td width="12%"><span className="sm-txt bold">TOTAL</span></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span className="sm-txt pl-2 align-items-center">IRPP sur traitements et salaires</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L67</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l67Base" value={this.props.selectedTaxes.l67Base || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable12(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed" ><input type="text" name="l67Principal" value={this.props.selectedTaxes.l67Principal || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable12(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2"  /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l67Cac" value={this.props.selectedTaxes.l67Cac || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable12(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l67Penalties" value={this.props.selectedTaxes.l67Penalties || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable12(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l67Total" value={this.props.selectedTaxes.l67Total || ''} disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr> 
                        <tr>
                            <td><span className="sm-txt pl-2">Crédit Foncier du Cameroun (part salariale)</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L68</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l68Base" value={this.props.selectedTaxes.l68Base || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable12(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2"  /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l68Principal" value={this.props.selectedTaxes.l68Principal || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable12(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2"  /></td>
                            <td align="right" className="" bgcolor="#EFEFEF"><input type="text" name="l68Cac" value={this.props.selectedTaxes.l68Cac || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable12(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l68Penalties" value={this.props.selectedTaxes.l68Penalties || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable12(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l68Total" value={this.props.selectedTaxes.l68Total || ''} disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Crédit Foncier du Cameroun(part patronale)</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L69</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l69Base" value={this.props.selectedTaxes.l69Base || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable12(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l69Principal" value={this.props.selectedTaxes.l69Principal || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable12(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="" bgcolor="#EFEFEF"><input type="text" name="l69Cac" value={this.props.selectedTaxes.l69Cac || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable12(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" disabled/></td>
                            <td align="right" className="border-dashed"><input type="text" name="l69Penalties" value={this.props.selectedTaxes.l69Penalties || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable12(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l69Total" value={this.props.selectedTaxes.l69Total || ''} disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Fonds National de l’Emploi</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L70</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l70Base" value={this.props.selectedTaxes.l70Base || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable12(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l70Principal" value={this.props.selectedTaxes.l70Principal || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable12(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="" bgcolor="#EFEFEF"><input type="text" name="l70Cac" value={this.props.selectedTaxes.l70Cac || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable12(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l70Penalties" value={this.props.selectedTaxes.l70Penalties || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable12(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l70Total" value={this.props.selectedTaxes.l70Total || ''} disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Redevance audiovisuelle</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L71</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l71Base" value={this.props.selectedTaxes.l71Base || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable12(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l71Principal" value={this.props.selectedTaxes.l71Principal || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable12(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="" bgcolor="#EFEFEF"><input type="text" name="l71PrincipaCac" value={this.props.selectedTaxes.l71Cac || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable12(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l71Penalties" value={this.props.selectedTaxes.l71Penalties || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable12(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l71Total" value={this.props.selectedTaxes.l71Total || ''} disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Taxe de Développement Local (taxe communale)</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L72</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l72Base" value={this.props.selectedTaxes.l72Base || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable12(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l72Principal" value={this.props.selectedTaxes.l72Principal || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable12(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="" bgcolor="#EFEFEF"><input type="text" name="l72PrincipaCac" value={this.props.selectedTaxes.l72Cac || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable12(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l72Penalties" value={this.props.selectedTaxes.l72Penalties || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable12(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l72Total" value={this.props.selectedTaxes.l72Total || ''} disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2"> TOTAL = (Somme L 67 à  L72)</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt bold line-indicator bold">L73</span></td>
                            <td align="right" colSpan="3" className="bordered"><input type="text" name="l73Base" value={this.props.selectedTaxes.l73Base || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable12(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l73Principal" value={this.props.selectedTaxes.l73Principal || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable12(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l73Cac" value={this.props.selectedTaxes.l73Cac || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable12(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l73Penalties" value={this.props.selectedTaxes.l73Penalties || ''} onChange={(e) => this.handleChange(e, () => {this.props.onChangeTable12(); this.props.onChangeTable15()})} className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l73Total" value={this.props.selectedTaxes.l73Total || ''} disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        selectedPeriod: state.organizations.selectedOrgTaxes,
        selectedTaxes: state.taxes.selectedTaxe,
    }
}

export default connect(mapStateToProps, { updateOrganizationTaxes, selectTaxe, handleChange, onChangeTable8, onChangeTable9, onChangeTable10, onChangeTable11, onChangeTable12, onChangeTable15 })(StepThreeForm);
