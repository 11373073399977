import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    selectTaxe, handleChange, handleChangeTab, handleChangeRate, handleCheckbox, 
    getSum, getSumL1L2, getSumL3L4, getSubstactL5L6, getSumL5L7, switchL5OrL7, actionFormOne, onChangeTable15,
} from "../../redux/action/TaxesAction";
import { numberFormat } from '../../helpers/Number';

class StepFormOne extends Component {
    constructor(props){
        super(props);
        this.state = {
            cdicsicime: '',
        }
    }

    componentDidMount(){
        this.init();
        if(this.props.onRef){
            this.props.onRef(this);
        }
    }

    componentWillUnmount = () => {
        this.props.onRef(undefined);
    }

    init = () => {
        if(!this.props.selectedPeriod){
            this.props.history.push('/taxes');
        };
    }

    getPayload = () => {
        return {
            specialIncomeTax: {
                retainedTRC: { base: this.props.selectedTaxes.tsrbase, rate: this.props.selectedTaxes.tsrrate, principal: this.props.selectedTaxes.tsrprincipal, penalities: this.props.selectedTaxes.tsrpenality, total: this.props.selectedTaxes.tsrtotal },
                total: { base: this.props.selectedTaxes.tsrbasetotal, rate: this.props.selectedTaxes.tsrrate, principal: this.props.selectedTaxes.tsrprincipaltotal, penalities: this.props.selectedTaxes.tsrpenalitytotal, total: this.props.selectedTaxes.tsrtotals }
            },
            exicesDuties: {
                generalRateTaxableCA: { base: this.props.selectedTaxes.cagbase, rate: this.props.selectedTaxes.cagrate, principal: this.props.selectedTaxes.cagprincipal, penalities: this.props.selectedTaxes.cagpenality, total: this.props.selectedTaxes.cagtotal },
                reducedRateTaxabeCA: { base: this.props.selectedTaxes.carbase, rate: this.props.selectedTaxes.carrate, principal: this.props.selectedTaxes.carprincipal, penalities: this.props.selectedTaxes.carpenality, total: this.props.selectedTaxes.cartotal },
                adValoremExiceDuty: { base: this.props.selectedTaxes.daabase, rate: this.props.selectedTaxes.daarate, principal: this.props.selectedTaxes.daaprincipal, penalities: this.props.selectedTaxes.daapenality, total: this.props.selectedTaxes.daatotal },
                specificExiceDuty: { base: this.props.selectedTaxes.dasbase, rate: this.props.selectedTaxes.dasrate, principal: this.props.selectedTaxes.dasprincipal, penalities: this.props.selectedTaxes.daspenality, total: this.props.selectedTaxes.dastotal },
                exiceDutyToReserve: { base: this.props.selectedTaxes.darbase, rate: this.props.selectedTaxes.darrate, principal: this.props.selectedTaxes.darprincipal, penalities: this.props.selectedTaxes.darpenality, total: this.props.selectedTaxes.dartotal },
                exiceDutypaidOnImportation: { principal: this.props.selectedTaxes.dapiprincipal, total: this.props.selectedTaxes.dapitotal },
                exciseDutySupplementPayable: { base: this.props.selectedTaxes.cdapbase, rate: this.props.selectedTaxes.cdaprate, principal: this.props.selectedTaxes.cdapprincipale, penalities: this.props.selectedTaxes.cdappenality, total: this.props.selectedTaxes.cdaptotal },
                l5Orl7: { base: this.props.selectedTaxes.l5orl7base, rate: this.props.selectedTaxes.l5orl7rate, principal: this.props.selectedTaxes.l5orl7principal, penalities: this.props.selectedTaxes.l5orl7penality, total: this.props.selectedTaxes.l5orl7total },
                l5PlusL7: { base: this.props.selectedTaxes.l5l7base, rate: this.props.selectedTaxes.l5l7rate, principal: this.props.selectedTaxes.l5l7principal, penalities: this.props.selectedTaxes.l5l7penality, total: this.props.selectedTaxes.l5l7total },
            },
            acheivedCA: {
                normalRateTaxableTransactions: { ca: this.props.selectedTaxes.ottnca, rate: this.props.selectedTaxes.ottnrate, amountOfTax: this.props.selectedTaxes.ottntotal },
                amountOfExiceDutyL8OrL9: { ca: this.props.selectedTaxes.mdaca, rate: this.props.selectedTaxes.mdarate, amountOfTax: this.props.selectedTaxes.mdatotal },
                otherTaxablesTransactions: { ca: this.props.selectedTaxes.aotca, rate: this.props.selectedTaxes.aotrate, amountOfTax: this.props.selectedTaxes.aottotal },
                taxedAtZeroRateExports: { ca: this.props.selectedTaxes.tvaetca, rate: this.props.selectedTaxes.tvaetrate, amountOfTax: this.props.selectedTaxes.tvaettotal },
                taxExemptCA: { ca: this.props.selectedTaxes.caeca, rate: null, amountOfTax: null },
                totalCAExcludingTax: { ca: this.props.selectedTaxes.caghtca, amountOfTax: this.props.selectedTaxes.caghttotal },
            }
        };
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    handleChangeTab = (e, tabCol = [], total = "", taux = null, callback = null) => {
        this.props.handleChangeTab(e, tabCol, total, taux);
        if(callback){ callback(); }
    }

    handleChangeRate = (e, tabCol = [], total = "", callback = null) => {
        this.props.handleChangeRate(e, tabCol, total);
        if(callback){ callback(); }
    }

    getSum = (target, tab = [], taux = null, add = true) => {
        this.props.getSum(target, tab, taux);
    }

    getSumL1L2 = (callback = null) => {
        this.props.getSumL1L2();
        if(callback){callback()};
    }

    getSumL3L4 = (callback = null) => {
        this.props.getSumL3L4();
        if(callback){ callback(); }
    }

    getSubstactL5L6 = (callback = null) => {
        this.props.getSubstactL5L6();
        if(callback){ callback(); }
    }

    getSumL5L7 = (callback = null) => {
        this.props.getSumL5L7();
        if(callback){ callback(); }
    }

    switchL5OrL7 = (value, callback = null) => {
        this.props.getSumL5L7(value);
        if(callback){ callback(); }
    }

    handleCheckbox = (e, target, tab, callback = null) => {
        this.props.handleCheckbox(e, target, tab);
        if(callback){ callback(); }
    }

    render() {
        return (
            <div className="taxe-step step-one">
                <div className="taxe-header-form pt-3">
                    <div class="head-static-center">
                        <div class="normal-txt">REPUBLIQUE DU CAMEROUN</div>
                        <div class="sm-txt">Paix - Travail - Patrie</div>
                        <div class="block-separator">----------</div>
                        <div class="normal-txt">MINISTERE DES FINANCES</div>
                        <div class="block-separator">----------</div>
                        <div class="normal-txt">DIRECTION GÉNÉRALE DES IMPOTS</div>
                        <div class="block-separator">----------</div>
                    </div>
                    <div></div>
                    <div class="head-static-center">
                        <div class="normal-txt-uppercase">REPUBLIC OF CAMEROON</div>
                        <div class="sm-txt">Peace - Work -  Fatherland</div>
                        <div class="block-separator">----------</div>
                        <div class="normal-txt-uppercase">MINISTRY OF FINANCE</div>
                        <div class="block-separator">----------</div>
                        <div class="normal-txt-uppercase">DIRECTORATE GENERAL OF TAXATION</div>
                        <div class="block-separator">----------</div>
                        <div class="sm-txt">SITE WEB:  www.impots.cm</div>
                        <div class="sm-txt bold">N° vert : 8200</div>
                    </div>
                </div>
                <div class="normal-txt-uppercase bold pl-2">
                    <span>D.G.E/C.R.I:</span>
                    <span class="d-inline-block bottom-bordered mw-150 h-20">
                        <input type="text" name="dgecri" value={this.props.selectedTaxes.dgecri} placeholder=""  className="input-text bold" disabled />
                    </span>
                </div>
                <div class="normal-txt-uppercase bold mt-1 pl-2">
                    <span>CDI/CSI/CIME:</span>
                    <span class="d-inline-block bottom-bordered mw-150 h-20">
                        <input type="text" name="cdicsicime" value={this.props.selectedTaxes.cdicsicime} placeholder="" className="input-text bold" disabled />
                    </span>
                </div>
                <div className="d-flex align-items-center">
                    <div class="main-title mt-2"> DÉCLARATION DES TAXES SUR LE CHIFFRE D’AFFAIRES ET DES IMPOTS SUR LE REVENU </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-3 pl-2 pr-2">
                    <div className="d-flex align-items-center">
                        <span class=" normal-txt-uppercase bold">REGIME D’IMPOSITION:</span> 
                        <span class="d-flex align-items-center sm-txt ml-2 h-20">
                            <label for="rireel" class="d-flex align-items-center checkbox ml-2 mr-2 mb-0"><span class="mr-2">Réel</span> <input type="checkbox" name="rireel" id="rireel" value="reel" className="table-checkbox" onChange={(e) => this.handleCheckbox(e,'isReal', ['isReal','isSimplify'])} checked={this.props.selectedTaxes.isReal} /></label>
                            <label for="risimplifie" class="d-flex align-items-center checkbox ml-2 mr-2 mb-0"><span class="mr-2">Simplifié</span> <input type="checkbox" name="risimplifie" id="risimplifie" value="simplifie" className="table-checkbox" onChange={(e) => this.handleCheckbox(e,'isSimplify', ['isReal','isSimplify'])} checked={this.props.selectedTaxes.isSimplify} /></label>
                        </span>
                    </div>
                    <div className="d-flex align-items-center mt-3">
                        <div class="d-flex align-items-center normal-txt-uppercase bold">
                            <span>EXERCICE FISCAL</span>
                            <span class="d-flex align-items-center bottom-bordered mw-100 h-20 ml-2">
                                <input type="text" name="fiscalExercice" value={this.props.selectedTaxes.fiscalExercice} placeholder="" className="input-text bold" disabled />
                            </span>
                        </div>
                        <div class="d-flex align-items-center normal-txt-uppercase bold ml-2">
                            <span>MOIS DE </span>
                            <span class="d-flex align-items-center bottom-bordered mw-100 h-20 ml-2">
                                <input type="text" name="period" value={this.props.selectedTaxes.period?.toUpperCase()} placeholder="" className="input-text bold" disabled />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-1 pl-2 pr-2 normal-txt bold">
                    <div className="d-flex align-items-center flex-3">
                        <div class="d-flex flex-1-auto align-items-center">
                            <div>Nom ou Raison sociale: </div>
                            <div class="d-flex align-items-center bottom-bordered  h-20 pl-1 flex-1-auto">
                                <input type="text" name="socialReason" value={this.props.selectedTaxes.socialReason} placeholder="" className="input-text bold" disabled />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center flex-1 ml-2">
                        <div>Sigle: </div>
                            <div class="d-flex align-items-center bottom-bordered  h-20 pl-1 flex-1-auto">
                                <input type="text" name="sigle" value={this.props.selectedTaxes.sigle} placeholder="" className="input-text bold" disabled />
                            </div>
                        </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2 pl-2 pr-2 normal-txt bold">
                    <div className="d-flex align-items-center flex-1">
                        <div class="d-flex flex-1-auto align-items-center">
                            <div>Ville: </div>
                            <div class="d-flex align-items-center bottom-bordered  h-20 pl-1 flex-1-auto">
                                <input type="text" name="town" value={this.props.selectedTaxes.town} placeholder="" className="input-text bold" disabled />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center flex-1 ml-2">
                        <div>Commune: </div>
                        <div class="d-flex align-items-center bottom-bordered  h-20 pl-1 flex-1-auto">
                            <input type="text" name="community" value={this.props.selectedTaxes.community} placeholder=""   className="input-text bold" disabled />
                        </div>
                    </div>
                    <div className="d-flex align-items-center flex-1 ml-2">
                        <div>Quratier: </div>
                        <div class="d-flex align-items-center bottom-bordered  h-20 pl-1 flex-1-auto">
                            <input type="text" name="quarter" value={this.props.selectedTaxes.quarter} placeholder=""   className="input-text bold" disabled />
                        </div>
                    </div>
                    <div className="d-flex align-items-center flex-1 ml-2">
                        <div>Lieu dit: </div>
                        <div class="d-flex align-items-center bottom-bordered  h-20 pl-1 flex-1-auto">
                            <input type="text" name="area" value={this.props.selectedTaxes.area} placeholder=""   className="input-text bold" disabled />
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2 pl-2 pr-2 normal-txt bold">
                    <div className="d-flex align-items-center flex-1">
                        <div class="d-flex flex-1-auto align-items-center">
                            <div>BP: </div>
                            <div class="d-flex align-items-center bottom-bordered  h-20 pl-1 flex-1-auto">
                                <input type="text" name="pobox" value={this.props.selectedTaxes.pobox} placeholder=""   className="input-text bold" disabled />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center flex-1 ml-2">
                        <div>Tel. fixe: </div>
                        <div class="d-flex align-items-center bottom-bordered  h-20 pl-1 flex-1-auto">
                            <input type="text" name="celphone" value={this.props.selectedTaxes.celphone} placeholder="" disabled  className="input-text bold" />
                        </div>
                    </div>
                    <div className="d-flex align-items-center flex-1 ml-2">
                        <div>Tel mobile: </div>
                        <div class="d-flex align-items-center bottom-bordered  h-20 pl-1 flex-1-auto">
                            <input type="text" name="mobilephone" value={this.props.selectedTaxes.mobilephone} placeholder="" className="input-text bold" disabled />
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2 pl-2 pr-2 normal-txt bold">
                    <div className="d-flex align-items-center flex-3">
                        <div class="d-flex flex-1-auto align-items-center">
                            <div>Adresse email: </div>
                            <div class="d-flex align-items-center bottom-bordered  h-20 pl-1 flex-1-auto">
                                <input type="text" name="email" value={this.props.selectedTaxes.email} placeholder=""  disabled className="input-text bold" />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center flex-1 ml-2">
                        <div>Fax: </div>
                        <div class="d-flex align-items-center bottom-bordered  h-20 pl-1 flex-1-auto">
                            <input type="text" name="fax" value={this.props.selectedTaxes.fax} placeholder="" className="input-text bold" disabled />
                        </div>
                    </div>
                </div>
                {/* TABLEAU 1 */}
                <div className="d-flex align-items-center justify-content-between mt-4 pl-2 pr-2">
                    <h3 class="title-table normal-txt-uppercase bold"> TAXE SPECIALE SUR LE REVENU (TSR) </h3>
                </div>

                <div className="d-flex align-items-center justify-content-between mt-3 pl-2 pr-2">
                    <table class="table-taxes">
                        <thead>
                            <tr align="center">
                                <td width="40%" align="left"><span class="sm-txt bold pl-2">0 - RÉMUNÉRATIONS VERSÉES À L’ÉTRANGER</span></td>
                                <td colSpan={4} width="15%"><span class="sm-txt bold">BASE</span></td>
                                <td width="15%"><span class="sm-txt bold">PRINCIPAL</span></td>
                                <td width="15%"><span class="sm-txt bold">PÉNALITÉS </span></td>
                                <td width="15%"><span class="sm-txt bold">TOTAL</span></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="v-align-middle border-right-dashed">
                                    <div class="d-flex align-items-center sm-txt ml-2">
                                        TSR retenue au taux de :
                                        <div class="d-flex align-items-center h-20"> 
                                            <label for="tsr15" class="d-flex align-items-center checkbox ml-2 mr- mb-0"><span class="mr-2">15%</span> <input type="checkbox" name="tsrrate" id="tsr15" value={15} className="table-checkbox" onChange={(e) => this.handleCheckbox(e,'is15', ['is15','is10','is7p5'], () => {this.props.actionFormOne(); this.props.onChangeTable15()})} checked={+this.props.selectedTaxes.tsrrate === 15 || this.props.selectedTaxes.is15} /></label>
                                            <label for="tsr10" class="d-flex align-items-center checkbox ml-2 mr-2 mb-0"><span class="mr-2">10%</span> <input type="checkbox" name="tsrrate" id="tsr10" value={10} className="table-checkbox" onChange={(e) => this.handleCheckbox(e,'is10', ['is15','is10','is7p5'], () => {this.props.actionFormOne(); this.props.onChangeTable15()})} checked={+this.props.selectedTaxes.tsrrate === 10 || this.props.selectedTaxes.is10}  /></label>
                                            <label for="tsr7-5" class="d-flex align-items-center checkbox ml-2 mr-2 mb-0"><span class="mr-2">7.5%</span> <input type="checkbox" name="tsrrate" id="tsr7-5" value={7.5} className="table-checkbox" onChange={(e) => this.handleCheckbox(e,'is7p5', ['is15','is10','is7p5'], () => {this.props.actionFormOne(); this.props.onChangeTable15()})} checked={+this.props.selectedTaxes.tsrrate === 7.5 || this.props.selectedTaxes.is7p5} /></label>
                                        </div>
                                    </div>
                                </td>
                                <td className="bordered-bottom"> <span class="sm-txt bold line-indicator">&nbsp;</span> </td>
                                <td colspan="3" className="border-dashed" align="right"><input type="text" name="tsrbase" value={this.props.selectedTaxes.tsrbase} onChange={(e) => this.handleChangeTab(e, ['tsrprincipal','tsrpenality'], 'tsrtotal', null, () => {this.props.actionFormOne(); this.props.onChangeTable15()})} placeholder="" className="input-text text-right-h pr-2" /></td>
                                <td align="right" className="border-dashed"><input type="text" name="tsrprincipal" value={this.props.selectedTaxes.tsrprincipal} onChange={(e) => this.handleChangeTab(e, ['tsrbase','tsrpenality'], 'tsrtotal', null, () => {this.props.actionFormOne(); this.props.onChangeTable15()})} placeholder="" className="input-text text-right-h pr-2" /></td>
                                <td align="right" className="border-dashed"><input type="text" name="tsrpenality" value={this.props.selectedTaxes.tsrpenality} onChange={(e) => this.handleChangeTab(e, ['tsrbase','tsrprincipal'], 'tsrtotal', null, () => {this.props.actionFormOne(); this.props.onChangeTable15()})} placeholder="" className="input-text text-right-h pr-2" /></td>
                                <td align="right" className="bordered"><input type="text" name="tsrtotal" value={this.props.selectedTaxes.tsrtotal ? numberFormat(this.props.selectedTaxes.tsrtotal) : 0.00} disabled placeholder="" className="input-text text-right-h pr-2 bold" /></td>
                            </tr>
                            <tr>
                                <td><span class="sm-txt bold pl-2">TOTAL</span></td>
                                <td align="center"  className="bordered-bottom"><span class="sm-txt bold line-indicator">L0</span></td>
                                <td align="right" className="border-dashed" colspan="3"><input type="text" name="tsrbasetotal" value={this.props.selectedTaxes.tsrbasetotal} placeholder="" className="input-text text-right-h pr-2 bold" disabled /></td>
                                <td align="right" className="border-dashed"><input type="text" name="tsrprincipaltotal" value={this.props.selectedTaxes.tsrprincipaltotal} placeholder="" className="input-text text-right-h pr-2 bold" disabled /></td>
                                <td align="right" className="border-dashed"><input type="text" name="tsrpenalitytotal" value={this.props.selectedTaxes.tsrpenalitytotal} placeholder="" className="input-text text-right-h pr-2 bold" disabled /></td>
                                <td align="right" className="bordered"><input type="text" name="tsrtotals" value={this.props.selectedTaxes.tsrtotals ? numberFormat(this.props.selectedTaxes.tsrtotals) : 0.00} placeholder="" disabled className="input-text text-right-h pr-2 bold" /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/* TABLEAU 2 */}
                <div className="d-flex align-items-center justify-content-between mt-2 pl-2 pr-2">
                    <h3 class="title-table normal-txt-uppercase bold"> DROITS D’ACCISES (DA) </h3>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2 pl-2 pr-2">
                    <table class="table-taxes">
                        <thead>
                            <tr align="center">
                                <td width="40%"  align="left"><span class="sm-txt bold pl-2">1a - CHIFFRE D’AFFAIRES RÉALISÉ</span></td>
                                <td colspan="4" width="15%"><span class="sm-txt bold">BASE</span></td>
                                <td width="5%"><span class="sm-txt bold">TAUX/TARIFS(%)</span></td>
                                <td width="10%"><span class="sm-txt bold">PRINCIPAL</span></td>
                                <td width="10%"><span class="sm-txt bold">PÉNALITÉS </span></td>
                                <td width="15%"><span class="sm-txt bold">TOTAL</span></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><span class="sm-txt pl-4">CA taxable au taux général</span></td>
                                <td align="center" className="bordered-bottom"> <span class="sm-txt  bold line-indicator bold">L1</span> </td>
                                <td align="right" colspan="3" className="border-dashed"><input type="text" name="cagbase" value={this.props.selectedTaxes.cagbase} onChange={(e) => {this.handleChangeTab(e, ['cagprincipal','cagpenality'], 'cagtotal', this.props.selectedTaxes.cagrate, () => {this.props.actionFormOne(); this.props.onChangeTable15()})}} placeholder="" className="input-text text-right-h pr-2" /></td>
                                <td align="center" className="border-dashed"><input type="text" name="cagrate" value={this.props.selectedTaxes.cagrate || 25} placeholder="" className="input-text bold input-align-center pr-2" disabled /></td>
                                <td align="right" className="border-dashed"><input type="text" name="cagprincipal" value={this.props.selectedTaxes.cagprincipal} placeholder="" onChange={(e) => this.handleChangeTab(e, ['cagbase','cagpenality'], 'cagtotal', this.props.selectedTaxes.cagrate, () => {this.props.actionFormOne(); this.props.onChangeTable15(); })} className="input-text text-right-h pr-2" /></td>
                                <td align="right" className="border-dashed"><input type="text" name="cagpenality" value={this.props.selectedTaxes.cagpenality} placeholder="" onChange={(e) => this.handleChangeTab(e, ['cagbase','cagprincipal'], 'cagtotal', this.props.selectedTaxes.cagrate, () => {this.props.actionFormOne(); this.props.onChangeTable15(); })} className="input-text text-right-h pr-2" /></td>
                                <td align="right" className="bordered"><input type="text" name="cagtotal" value={this.props.selectedTaxes.cagtotal ? numberFormat(this.props.selectedTaxes.cagtotal) : 0} placeholder="" disabled className="input-text text-right-h pr-2 bold" /></td>
                            </tr>
                            <tr>
                                <td><span class="sm-txt pl-4">CA taxable au taux réduit</span></td>
                                <td align="center" className="bordered-bottom"><span class="sm-txt  bold line-indicator bold">L2</span></td>
                                <td align="right" colspan="3" className="border-dashed"><input type="text" name="carbase" value={this.props.selectedTaxes.carbase} onChange={(e) => this.handleChangeTab(e, ['carprincipal','carpenality'], 'cartotal', this.props.selectedTaxes.carrate, () => {this.props.actionFormOne(); this.props.onChangeTable15(); })} placeholder=""className="input-text text-right-h pr-2" /></td>
                                <td align="center" className="border-dashed"><input type="text" name="carrate" value={this.props.selectedTaxes.carrate || 12.5} placeholder="" className="input-text bold input-align-center pr-2" disabled /></td>
                                <td align="right" className="border-dashed"><input type="text" name="carprincipal" value={this.props.selectedTaxes.carprincipal} onChange={(e) => this.handleChangeTab(e, ['carbase','carpenality'],'cartotal', this.props.selectedTaxes.carrate, () => {this.props.actionFormOne(); this.props.onChangeTable15(); })} placeholder="" className="input-text text-right-h pr-2" /></td>
                                <td align="right" className="border-dashed"><input type="text" name="carpenality" value={this.props.selectedTaxes.carpenality} onChange={(e) => this.handleChangeTab(e, ['carbase','carprincipal'],'cartotal', this.props.selectedTaxes.carrate, () => {this.props.actionFormOne(); this.props.onChangeTable15(); })} placeholder="" className="input-text text-right-h pr-2" /></td>
                                <td align="right" className="bordered"><input type="text" name="cartotal" value={this.props.selectedTaxes.cartotal ? numberFormat(this.props.selectedTaxes.cartotal) : 0} disabled placeholder="" className="input-text text-right-h pr-2 bold" /></td>
                            </tr>
                            <tr>
                                <td><span class="sm-txt bold  pl-2">1b - DROIT D’ACCISES AD VALOREM = L1 + L2</span></td>
                                <td align="center" className="bordered-bottom"><span class="sm-txt  bold line-indicator bold">L3</span></td>
                                <td align="right" colspan="3" className="border"><input type="text" name="daabase" value={this.props.selectedTaxes.daabase} placeholder="" onChange={(e) => this.handleChangeTab(e, ['daaprincipal','daapenality'], 'daatotal', this.props.selectedTaxes.daarate)} className="input-text text-right-h pr-2" disabled /></td>
                                <td align="center" className="border"><input type="text" name="daarate" value={this.props.selectedTaxes.daarate} placeholder="" onChange={(e) => this.handleChangeRate(e, ['daabase','daaprincipal','daapenality'], 'daatotal')} className="input-text input-align-center pr-2" disabled /></td>
                                <td align="right" className="border"><input type="text" name="daaprincipal" value={this.props.selectedTaxes.daaprincipal} placeholder="" onChange={(e) => this.handleChangeTab(e, ['daabase','daapenality'], 'daatotal', this.props.selectedTaxes.daarate)} className="input-text text-right-h pr-2" disabled /></td>
                                <td align="right" className="border"><input type="text" name="daapenality" value={this.props.selectedTaxes.daapenality} placeholder="" onChange={(e) => this.handleChangeTab(e, ['daabase','daaprincipal'], 'daatotal', this.props.selectedTaxes.daarate)} className="input-text text-right-h pr-2" disabled /></td>
                                <td align="right" className="bordered"><input type="text" name="daatotal" value={this.props.selectedTaxes.daatotal ? numberFormat(this.props.selectedTaxes.daatotal) : 0.00} placeholder="" disabled className="input-text text-right-h pr-2 bold" /></td>
                            </tr>
                            <tr>
                                <td><span class="sm-txt bold  pl-2">1c - DROIT D’ACCISES SPECIFIQUE(détails annexés)</span></td>
                                <td align="center" className="bordered-bottom"><span class="sm-txt  bold line-indicator bold">L4</span></td>
                                <td align="right" colspan="3"  className="border-dashed"><input type="text" name="dasbase" value={this.props.selectedTaxes.dasbase} onChange={(e) => this.handleChangeTab(e, ['dasprincipal','daspenality'], 'dastotal', this.props.selectedTaxes.dasrate, () => { this.props.actionFormOne(); this.props.onChangeTable15() })} placeholder="" className="input-text text-right-h pr-2" /></td>
                                <td align="center" className="border-dashed"><input type="text" name="dasrate" value={this.props.selectedTaxes.dasrate} onChange={(e) => this.handleChangeRate(e, ['dasprincipal','daspenality','dasbase'], 'daatotal', () => { this.props.actionFormOne(); this.props.onChangeTable15() })} placeholder="" className="input-text input-align-center pr-2" /></td>
                                <td align="right" className="border-dashed"><input type="text" name="dasprincipal" value={this.props.selectedTaxes.dasprincipal} onChange={(e) => this.handleChangeTab(e, ['dasbase','daspenality'], 'dastotal', this.props.selectedTaxes.dasrate, () => { this.props.actionFormOne(); this.props.onChangeTable15() })} placeholder="" className="input-text text-right-h pr-2" /></td>
                                <td align="right" className="border-dashed"><input type="text" name="daspenality" value={this.props.selectedTaxes.daspenality} onChange={(e) => this.handleChangeTab(e, ['dasbase','dasprincipal'], 'dastotal', this.props.selectedTaxes.dasrate, () => { this.props.actionFormOne(); this.props.onChangeTable15() })} placeholder="" className="input-text text-right-h pr-2" /></td>
                                <td align="right" className="bordered"><input type="text" name="dastotal" value={this.props.selectedTaxes.dastotal ? numberFormat(this.props.selectedTaxes.dastotal) : 0.00} disabled placeholder="" className="input-text text-right-h pr-2 bold" /></td>
                            </tr>
                            <tr>
                                <td><span class="sm-txt bold  pl-2">1d- DROITS D’ACCISES À REVERSER = L3 + L4</span></td>
                                <td align="center" className="bordered-bottom"><span class="sm-txt bold line-indicator bold">L5</span></td>
                                <td align="right" colspan="3" className="border"><input type="text" name="darbase" value={this.props.selectedTaxes.darbase} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                                <td align="center" className="border"><input type="text" name="darrate" value={this.props.selectedTaxes.darrate}  placeholder="" className="input-text input-align-center pr-2" disabled /></td>
                                <td align="right" className="border"><input type="text" name="darprincipal" value={this.props.selectedTaxes.darprincipal}placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                                <td align="right" className="border"><input type="text" name="darpenality" value={this.props.selectedTaxes.darpenality}placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                                <td align="right" className="bordered"><input type="text" name="dartotal" value={this.props.selectedTaxes.dartotal ? numberFormat(this.props.selectedTaxes.dartotal) : 0.00} disabled placeholder="" className="input-text text-right-h pr-2 bold" /></td>
                            </tr>
                            <tr>
                                <td><span class="sm-txt bold  pl-2">1e - DROITS D’ACCISES COMPLÉMENTAIRES</span></td>
                                <td className="bordered-bottom">&nbsp;</td>
                                <td bgcolor="#EFEFEF" align="right" colspan="3"><input type="text" name="none" value={""} placeholder=""   className="input-text text-right-h pr-2" disabled /></td>
                                <td bgcolor="#EFEFEF"><input type="text" name="none" value={""} placeholder=""className="input-text text-right-h pr-2" disabled /></td>
                                <td bgcolor="#EFEFEF" align="right"><input type="text" name="none" value={""} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                                <td bgcolor="#EFEFEF" align="right"><input type="text" name="none" value={""} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                                <td bgcolor="#EFEFEF" align="right" className="bordered-right"><input type="text" name="none" value={""} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                            </tr>
                            <tr>
                                <td><span class="sm-txt pl-4">Droits d’Accises payés à l’importation</span></td>
                                <td align="center" className="bordered-bottom"><span class="sm-txt  bold line-indicator bold">L6</span></td>
                                <td bgcolor="#EFEFEF" align="right" colspan="3"><input type="text" name="none" value={""} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                                <td bgcolor="#EFEFEF"><input type="text" name="none" value={""} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                                <td align="right" className="border-dashed"><input type="text" name="dapiprincipal" value={this.props.selectedTaxes.dapiprincipal} onChange={(e) => this.handleChangeTab(e, [], 'dapitotal', null, () => {this.props.actionFormOne(); this.props.onChangeTable15()} )} placeholder="" className="input-text text-right-h pr-2" /></td>
                                <td bgcolor="#EFEFEF" align="right" ><input type="text" name="none" value={""} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                                <td  align="right" className="bordered"><input type="text" name="dapitotal" value={this.props.selectedTaxes.dapitotal ? numberFormat(this.props.selectedTaxes.dapitotal) : 0.00} disabled placeholder="" className="input-text text-right-h pr-2 bold" /></td>
                            </tr>
                            <tr>
                                <td><span class="sm-txt pl-4">Complément Droits d’Accises à payer = L5 - L6</span></td>
                                <td align="center" className="bordered-bottom"><span class="sm-txt  bold line-indicator bold">L7</span></td>
                                <td align="right" colspan="3" className="border"><input type="text" name="cdapbase" value={this.props.selectedTaxes.cdapbase} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                                <td align="center" className="border"><input type="text" name="cdaprate" value={this.props.selectedTaxes.cdaprate} placeholder="" className="input-text input-align-center pr-2" disabled /></td>
                                <td align="right" className="border"><input type="text" name="cdapprincipale" value={this.props.selectedTaxes.cdapprincipale} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                                <td align="right" className="border"><input type="text" name="cdappenality" value={this.props.selectedTaxes.cdappenality} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                                <td align="right" className="bordered"><input type="text" name="cdaptotal" disabled value={this.props.selectedTaxes.cdaptotal ? numberFormat(this.props.selectedTaxes.cdaptotal) : 0.00} placeholder="" className="input-text text-right-h pr-2 bold" /></td>
                            </tr>
                            <tr> 
                                <td><span class="sm-txt pl-2 bold">TOTAL DROITS D’ACCISES À PAYER</span></td>
                                <td className="bordered-bottom"><span class="sm-txt bold">&nbsp;</span></td>
                                <td bgcolor="#EFEFEF" align="right" colspan="3"><input type="text" name="none" value={""} placeholder="" disabled className="input-text text-right-h pr-2" /></td>
                                <td bgcolor="#EFEFEF"><input type="text" name="none" value={""} placeholder="" disabled   className="input-text text-right-h pr-2" /></td>
                                <td bgcolor="#EFEFEF" align="right"><input type="text" name="none" value={""} placeholder="" disabled className="input-text text-right-h pr-2" /></td>
                                <td bgcolor="#EFEFEF" align="right"><input type="text" name="none" value={""} placeholder="" disabled className="input-text text-right-h pr-2" /></td>
                                <td bgcolor="#EFEFEF" align="right" className="bordered-right"><input type="text" name="none" value={""} placeholder="" disabled className="input-text text-right-h pr-2 bold" /></td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="d-flex align-item-center sm-txt h-20 pl-2">
                                        <label for="op-l5" class="d-flex align-items-center checkbox mr-2 mb-0"><span class="mr-2">L5</span> <input type="checkbox" name="l5" id="op-l5" value="l5" onChange={(e) => this.handleCheckbox(e, 'isL5', ['isL5','isL7'], () => {this.props.switchL5OrL7('l5'); this.props.actionFormOne(); this.props.onChangeTable15();})} checked={this.props.selectedTaxes.isL5} className="table-checkbox" /></label>
                                        <span className="ml-2 mr-2">OU</span> 
                                        <label for="op-l7" class="d-flex align-items-center checkbox ml-2 mr-2 mb-0"><span class="mr-2">L7</span> <input type="checkbox" name="l7" id="op-l7" value="l7" onChange={(e) => this.handleCheckbox(e, 'isL7', ['isL5','isL7'], () => {this.props.switchL5OrL7('l7'); this.props.actionFormOne(); this.props.onChangeTable15();})} checked={this.props.selectedTaxes.isL7} className="table-checkbox" /></label>
                                    </span>
                                </td>
                                <td align="center" className="bordered-bottom"><span class="sm-txt  bold line-indicator bold">L8</span></td>
                                <td align="right" colspan="3" className="border"><input type="text" name="l5orl7base" value={this.props.selectedTaxes.l5orl7base} onChange={this.handleChange} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                                <td align="center" className="border"><input type="text" name="l5orl7rate" value={this.props.selectedTaxes.l5orl7rate} onChange={this.handleChange} placeholder="" className="input-text input-align-center pr-2" disabled /></td>
                                <td align="right" className="border"><input type="text" name="l5orl7principal" value={this.props.selectedTaxes.l5orl7principal} onChange={this.handleChange} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                                <td align="right" className="border"><input type="text" name="l5orl7penality" value={this.props.selectedTaxes.l5orl7penality} onChange={this.handleChange} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                                <td align="right" className="bordered"><input type="text" name="l5orl7total" value={this.props.selectedTaxes.l5orl7total ? numberFormat(this.props.selectedTaxes.l5orl7total) : 0.00} disabled placeholder="" className="input-text text-right-h pr-2 bold" /></td>
                            </tr>
                            <tr>
                                <td><span class="sm-txt bold pl-2">(L5 + L7)</span></td>
                                <td align="center" className="bordered-bottom"><span class="sm-txt bold line-indicator bold">L9</span></td>
                                <td align="right" colspan="3" className="bordered"><input type="text" name="l5l7base" value={this.props.selectedTaxes.l5l7base} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                                <td align="center" className="bordered"><input type="text" name="l5l7rate" value={this.props.selectedTaxes.l5l7rate} placeholder="" className="input-text input-align-center pr-2" disabled /></td>
                                <td align="right" className="bordered"><input type="text" name="l5l7principal" value={this.props.selectedTaxes.l5l7principal} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                                <td align="right" className="bordered"><input type="text" name="l5l7penality" value={this.props.selectedTaxes.l5l7penality} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                                <td align="right" className="bordered"><input type="text" name="l5l7total" value={this.props.selectedTaxes.l5l7total ? numberFormat(this.props.selectedTaxes.l5l7total) : 0.00} placeholder="" disabled className="input-text text-right-h pr-2 bold" /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* TABLEAU 3 */}
                <div className="d-flex align-items-center justify-content-between mt-2 pl-2 pr-2">
                    <h3 class="title-table normal-txt-uppercase bold"> TAXE SUR LA VALEUR AJOUTÉE (TVA) </h3>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2 pl-2 pr-2">
                    <table class="table-taxes">
                        <thead>
                            <tr align="center">
                                <td width="40%" align="left"><span class="sm-txt bold pl-2">2 - CHIFFRE D’AFFAIRES RÉALISÉ</span></td>
                                <td colspan="4" width="20%"><span class="sm-txt bold">CA</span></td>
                                <td width="20%"><span class="sm-txt bold">TAUX (%)</span></td>
                                <td width="20%"><span class="sm-txt bold">MONTANT DE LA TAXE</span></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><span class="sm-txt pl-2">Opérations taxables au taux normal<sup>1</sup></span></td>
                                <td align="center" className="bordered-bottom"> <span class="sm-txt bold">L10</span> </td>
                                <td align="right" colspan="3" className="border-dashed"><input type="text" name="ottnca" value={this.props.selectedTaxes.ottnca} onChange={(e) => this.handleChangeTab(e, [], 'ottntotal', this.props.selectedTaxes.ottnrate, () => { this.props.actionFormOne(); this.props.onChangeTable15()})} placeholder="" className="input-text text-right-h pr-2" /></td>
                                <td align="center" className="border-dashed"><input type="text" name="ottnrate" value={this.props.selectedTaxes.ottnrate || 19.25} placeholder="" className="input-text bold input-align-center pr-2" disabled /></td>
                                <td align="right" className="bordered-right"><input type="text" name="ottntotal" value={this.props.selectedTaxes.ottntotal ? numberFormat(this.props.selectedTaxes.ottntotal) : 0.00} disabled placeholder=""   className="input-text text-right-h pr-2" /></td>
                            </tr>
                            <tr>
                                <td><span class="sm-txt pl-2">Montant du Droit d’Accises L8 ou L9</span></td>
                                <td align="center" className="bordered-bottom"><span class="sm-txt bold">L11</span></td>
                                <td align="right" colspan="3" className="border-dashed"><input type="text" name="mdaca" value={this.props.selectedTaxes.mdaca} onChange={(e) => this.handleChangeTab(e, [], 'mdatotal', this.props.selectedTaxes.mdarate, () => { this.props.actionFormOne(); this.props.onChangeTable15()})} placeholder="" className="input-text text-right-h pr-2" /></td>
                                <td align="center" className="border-dashed"><input type="text" name="mdarate" value={this.props.selectedTaxes.mdarate} onChange={(e) => this.handleChangeRate(e, ['mdaca'], 'mdatotal', () => this.getSum("caghttotal", ['ottntotal','mdatotal','aottotal','tvaettotal']))} placeholder="" className="input-text input-align-center pr-2" /></td>
                                <td align="right" className="border"><input type="text" name="mdatotal" value={this.props.selectedTaxes.mdatotal ? numberFormat(this.props.selectedTaxes.mdatotal) : 0.00} disabled placeholder="" className="input-text text-right-h pr-2" /></td>
                            </tr>
                            <tr>
                                <td><span class="sm-txt pl-2">Autres opérations taxables<sup>2</sup></span></td>
                                <td align="center" className="bordered-bottom"><span class="sm-txt bold">L12</span></td>
                                <td align="right" colspan="3" className="border-dashed"><input type="text" name="aotca" onChange={(e) => this.handleChangeTab(e, [], 'aottotal', this.props.selectedTaxes.aotrate, () => { this.props.actionFormOne(); this.props.onChangeTable15();})} value={this.props.selectedTaxes.aotca} placeholder="" className="input-text text-right-h pr-2" /></td>
                                <td className="border-dashed"><input type="text" name="aotrate" value={this.props.selectedTaxes.aotrate} onChange={(e) => this.handleChangeRate(e, ['aotca'], 'aottotal', () => this.getSum("caghttotal", ['ottntotal','mdatotal','aottotal','tvaettotal']))} placeholder="" className="input-text input-align-center pr-2" /></td>
                                <td align="right" className="border"><input type="text" name="aottotal" value={this.props.selectedTaxes.aottotal ? numberFormat(this.props.selectedTaxes.aottotal) : 0.00} disabled placeholder="" className="input-text text-right-h pr-2" /></td>
                            </tr>
                            <tr>
                                <td><span class="sm-txt pl-2">Exportations (taxables au taux zéro)</span></td>
                                <td align="center" className="bordered-bottom"><span class="sm-txt bold">L13</span></td>
                                <td align="right" colspan="3" className="border-dashed"><input type="text" name="tvaetca" value={this.props.selectedTaxes.tvaetca} onChange={(e) => this.handleChangeTab(e, [], 'tvaettotal', this.props.selectedTaxes.tvaetrate, () => { this.props.actionFormOne(); this.props.onChangeTable15()})} placeholder=""   className="input-text text-right-h pr-2" /></td>
                                <td align="center" className="border-dashed"><input type="text" name="tvaetrate" value={this.props.selectedTaxes.tvaetrate || 0} placeholder="" className="input-text bold input-align-center pr-2" disabled /></td>
                                <td align="right" className="border"><input type="text" name="tvaettotal" value={this.props.selectedTaxes.tvaettotal ? numberFormat(this.props.selectedTaxes.tvaettotal) : 0.00} disabled placeholder=""   className="input-text text-right-h pr-2" /></td>
                            </tr>
                            <tr>
                                <td><span class="sm-txt pl-2">Chiffre d’affaires exonéré</span></td>
                                <td align="center" className="bordered-bottom"><span class="sm-txt bold">L14</span></td>
                                <td align="right" colspan="3" className="border-dashed"><input type="text" name="caeca" value={this.props.selectedTaxes.caeca} onChange={(e) => this.handleChangeTab(e, [], 'none', 0.00, () => { this.props.actionFormOne(); this.props.onChangeTable15();})} placeholder="" className="input-text text-right-h pr-2" /></td>
                                <td bgcolor="#EFEFEF"><input type="text" name="none" value={""} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                                <td bgcolor="#EFEFEF" align="right" className="bordered-right"><input type="text" name="none" value={""} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                            </tr>
                            <tr>
                                <td><span class="sm-txt pl-2">CA global hors taxes = L10 + L11 + L12 + L13</span></td>
                                <td align="center" className="bordered-bottom"><span class="sm-txt bold">L15</span></td>
                                <td align="right" colspan="3" className="border"><input type="text" name="caghtca" value={this.props.selectedTaxes.caghtca} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                                <td align="center" className="border"><span class="sm-txt">L10 + L11 + L12 + L13</span></td>
                                <td align="right" className="border"><input type="text" name="caghttotal" value={this.props.selectedTaxes.caghttotal ? numberFormat(this.props.selectedTaxes.caghttotal) : 0.00} disabled placeholder="" className="input-text text-right-h pr-2 bold" /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selectedPeriod: state.organizations.selectedOrgTaxes,
        selectedTaxes: state.taxes.selectedTaxe,
    }
}

export default connect(mapStateToProps, { selectTaxe, handleChange, handleChangeTab, handleChangeRate, handleCheckbox, getSum, getSumL1L2, getSumL3L4, getSubstactL5L6, getSumL5L7, switchL5OrL7, actionFormOne, onChangeTable15 })(StepFormOne);
