import React from "react";

const IconCloudDownloadComponent = props => (
  <svg width={`${props.width ? props.width : 10.972}`} height={`${props.height ? props.height : 9.6}`} viewBox="0 0 10.972 9.6" {...props}>
    <defs>
      <style>{".a{fill:#fff;}"}</style>
    </defs>
    <g transform="translate(0 -32)">
      <path
        fill={`${props.fill ? props.fill : '#fff'}`}
        stroke={`${props.stroke ? props.stroke : 'transparent'}`}
        strokeWidth={`${props.strokeWidth ? props.strokeWidth : 0}`}
        d="M10.13,35.375a2.922,2.922,0,0,0-1.03-.384.343.343,0,0,1-.27-.263,3.54,3.54,0,0,0-1.053-1.855A3.377,3.377,0,0,0,5.486,32a3.174,3.174,0,0,0-2.921,1.818.344.344,0,0,1-.25.192,3.319,3.319,0,0,0-1.466.615A2.185,2.185,0,0,0,0,36.406c0,1.444,1.2,2.451,2.914,2.451H5.143V36.124a.35.35,0,0,1,.326-.352.343.343,0,0,1,.359.342v2.743H8.486c1.557,0,2.486-.734,2.486-1.963A1.728,1.728,0,0,0,10.13,35.375Z"
        transform="translate(0)"
      />
      <path
        fill={`${props.fill ? props.fill : '#fff'}`}
        stroke={`${props.stroke ? props.stroke : 'transparent'}`}
        strokeWidth={`${props.strokeWidth ? props.strokeWidth : 0}`}
        d="M177.371,353.573l-.786-.785a.343.343,0,0,0-.484.485l1.372,1.369a.343.343,0,0,0,.484,0l1.371-1.369a.343.343,0,1,0-.484-.485l-.786.785V352h-.686Z"
        transform="translate(-172.228 -313.143)"
      />
    </g>
  </svg>
);

export default IconCloudDownloadComponent;
