import axios from '../config/axios';

export const createPayPeriod = async (orgId, data, manual) => {
    return await axios.post(`/organizations/${orgId}/pay-period?manual=${manual}`, data);
}

export const getPayPeriodByOrgId = async (orgId, pageSize, page) => {
    return await axios.get(`/organizations/${orgId}/pay-period?pageSize=${pageSize}&page=${page}`);
}

export const getPayPeriodById = async (orgId, id) => {
    return await axios.get(`/organizations/${orgId}/pay-period/${id}`);
}

export const countPayPeriodByOrgId = async (orgId) => {
    return await axios.get(`/organizations/${orgId}/pay-period/count`);
}

export const getPayslipsPerPayPeriodId = async (orgId, payPeriodId, pageSize = 15, page = 1) => {
    return await axios.get(`/organizations/${orgId}/pay-period/${payPeriodId}/payslips?pageSize=${pageSize}&page=${page}`);
}

export const _updatePayPeriod = async (orgId, payPeriodId, data) => {
    const payload = {
        connectionId: sessionStorage.getItem("connectionId"),
        ...data,
    }
    return await axios.put(`/organizations/${orgId}/pay-period/${payPeriodId}`, payload);
}