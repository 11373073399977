import React, { Component } from 'react'
import { Form, Button } from 'react-bootstrap'
import Loader from '../../components/Loader'
import './NewPassword.css'
// import { _updatePassword } from '../../services/Keycloak-Auth';

class NewPassword extends Component {
    constructor(props){
        super(props)
        this.state = {
            password: '',
            passwordConfirm: '',
            error: null
        }
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleSubmit = async(event) => {
        event.preventDefault()
        this.setState({
            error: null
        })
        const { password, passwordConfirm } = this.state
        if ( password !== passwordConfirm ) {
            this.setState({
                error: 'Les mots de passe ne sont pas identiques.'
            })
        } else {
            this.refs.loader.toggleLoad();
        }
    }

    loadError = () => {
        let { error } = this.state
        return error != null ? (
            <Form.Text className="text-muted">
                <span style={{color: "red"}} > {error} </span>
            </Form.Text>
        ) : <div></div>
    }

    render(){
        return (
            <div className="auth">
                <div className="box-auth">
                <Form onSubmit={this.handleSubmit}>
                    <Form.Group>
                        <h3>Dizen</h3>
                        <Form.Text className="text-muted">
                            Veuillez spécifier un nouveau mot de passe pour votre compte
                        </Form.Text>
                        <Form.Text className="text-muted">
                            Indications: Votre mot de passe doit contenir
                            <ul>
                                <li>Au moins 8 caractères.</li>
                                <li>Au moins un caractère MAJUSCULE.</li>
                                <li>Au moins un caractère NUMERIQUE.</li>
                                <li>Au moins un symbole.</li>
                            </ul> 
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Mot de passe</Form.Label>
                        <Form.Control type="password" name="password" onChange={this.handleChange} placeholder="Votre mot de passe" />
                    </Form.Group>
                    <Form.Group controlId="formBasicPasswordConfirm">
                        <Form.Label>Cofirmation de mot de passe</Form.Label>
                        <Form.Control type="password" name="passwordConfirm" onChange={this.handleChange} placeholder="Confirmez votre mot de passe" />
                    </Form.Group>
                    <Form.Group>
                        {
                            this.loadError()
                        }
                    </Form.Group>
                    <div className="bottom d-flex align-items-center justify-content-between">
                        <Button variant="primary" type="submit" className="h-40">
                            Valider
                        </Button>
                        <Loader ref="loader" />
                    </div>
                </Form>
                </div>
            </div>
        )
    }
}

export default NewPassword