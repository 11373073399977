import { SET_USER, ADD_USER, UPDATE_USER ,DELETE_USER, SELECT_USER } from '../action-type/userType';

export const setUsers = (users) => ({
    type: SET_USER,
    payload: users,
});

export const addUser = (user) => ({
    type: ADD_USER,
    payload: user,
});

export const updateUser = (user) => ({
    type: UPDATE_USER,
    payload: user,
});

export const deleteUser = (user) => ({
    type: DELETE_USER,
    payload: user,
});

export const selectUser = (user) => ({
    type: SELECT_USER,
    payload: user,
});
