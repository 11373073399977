import React from "react";

const IconManMaleSign = props => (
  <svg {...props} width={props.width ? props.width : 18.445} height={ props.height ? props.height : 18.449} viewBox="0 0 18.445 18.449">
    <defs>
      <style>{".a{fill:#177bd1;}"}</style>
    </defs>
    <path
    fill={`${props.active ? '#177BD1' : '#b5b5b5;'}`}
      d="M65.846,48h-4.83a.6.6,0,1,0,0,1.206H64.36l-4,4.027a7.544,7.544,0,1,0,.851.856l4.027-4v3.344a.6.6,0,1,0,1.206,0V48.6A.6.6,0,0,0,65.846,48ZM61.792,58.95A6.3,6.3,0,1,1,59.947,54.5,6.25,6.25,0,0,1,61.792,58.95Z"
      transform="translate(-48 -48)"
    />
  </svg>
);

export default IconManMaleSign;
