import React, { Component } from 'react';
import { connect } from "react-redux";
import EditableInput from '../EditableInput';
import IconJob from '../Icons/JobComponent'
import IconContrat from '../Icons/AgreementComponent'
import IconDate from '../Icons/DateIcon'
import IconDepart from '../Icons/DepartIcon'
import IconService from '../Icons/ServiceIcon'
import moment from 'moment'
import 'moment-duration-format'
import {Toggle} from "react-toggle-component";
import { CONTRACT_TYPES } from '../../constants/ContractTypes';
import "simple-react-validator/dist/locale/fr";
import SimpleReactValidator from "simple-react-validator";
import { setContractFormValid } from '../../redux/action/ValidationAction';

class Contrat extends Component {
    constructor(props){
        super(props)
        this.state = {
            focusedPoste: false,
            focusedContrat: false,
            focusedBeginDate: false,
            focusedEndDate: false,
            focusedDeparture: false,
            focusedService: false,
            contratOptions: [{value: 'CDI'}, {value: 'CDD'}, {value: 'STAGE'}],
            departureOptions:[{value: ''}, {value:'Abandon de poste'}, {value: 'Démission avec préavis'}, {value: 'Démission sans préavis'} ],
            duration: '',
        }

        this.toggleRef = React.createRef();
        this.validator = new SimpleReactValidator({autoForceUpdate: this, locale: 'fr'});
    }

    focusPoste = () => {
        this.setState({
            focusedPoste  : !this.state.focusedPoste
        })
    }
    focusContrat = () => {
        this.setState({
            focusedContrat  : !this.state.focusedContrat            
        })
    }
    focusBeginDate = () => {
        this.setState({
            focusedBeginDate  : !this.state.focusedBeginDate            
        })
    }
    blurBeginDate = () => {
        const currentDate = new Date(moment().format("YYYY-MM")+"-"+moment().daysInMonth())
        let endDate = new Date(moment(this.props.endDate).format("YYYY-MM")+"-"+moment(this.props.endDate).daysInMonth());
        const endOfContractDate = (endDate && endDate <= currentDate) ? endDate : currentDate;
        const date = new Date(moment(this.props.beginDate).format("YYYY-MM"));
        const diff = endOfContractDate.valueOf() - date.valueOf();
        let duration = 0
        if(diff > 0) {
            duration = moment.duration(diff, 'milliseconds').format('Y [an] M')
        }
        this.setState({
            duration: duration + ' mois',
            focusedBeginDate  : !this.state.focusedBeginDate 
        })
        this.props.onBlurBeginDate(duration)
    }
    focusEndDate = () => {
        this.setState({
            focusedEndDate  : !this.state.focusedEndDate            
        })
    }
    focusDeparture = () => {
        this.setState({
            focusedDeparture  : !this.state.focusedDeparture            
        })
    }
    focusService = () => {
        this.setState({
            focusedService  : !this.state.focusedService            
        })
    }

    onChangePoste(e) {
        this.props.onChangePoste(e)
    }

    onChangeBeginDate(e) {
        this.props.onChangeBeginDate(e)
    }

    onChangeService(e) {
        this.props.onChangeService(e)
    }

    renderDuration = () => {
        let result = "";
        switch (this.props.seniorityInYears){
            case 0 : result += ""; break;
            case 1 : result += "1 an"; break;
            default : result += this.props.seniorityInYears+" ans"; break;
        }

        switch (this.props.seniorityInMonth){
            case 0 : result += ""; break;
            default : result += this.props.seniorityInYears+" ans"; break;
        }
        return result;
    }

    validContractForm = (noShowMessage) => {
        if (this.validator.allValid()) {
            this.props.setContractFormValid(true);
            this.validator.purgeFields();
            return true;
        } else {
            this.props.setContractFormValid(false);
            if(!noShowMessage) this.validator.showMessages();
            return false;
        }
    }
    resetContractForm = () => {
        this.validator.purgeFields();
        this.validator.hideMessages();
        this.props.setContractFormValid(false);
    }

    componentDidMount = () => {
        this.props.onRef(this);
        this.resetContractForm();
    }
    componentWillUnmount = () => {
        this.props.onRef(undefined);
    }

    onChangeNonContributingManager = (e) => {
        this.props.onChangeNonContributingManager(e);
        if(this.toggleRef && this.toggleRef.current) this.toggleRef.current.checked = e.target.checked;
    }
    validateContract = (event) => {
       if(event.target.value === CONTRACT_TYPES.CDI) {
            this.props.clearEndDate();
       }
    }

    errorMsg = (error) => {
        return this.props.formCoordValid ? "" : error;
    }

    renderNewContract = () => {
        return(
            <div className='px-0'>
                <div className="header-preview">
                    <div className="d-flex align-items-center">
                        <span className="ico-content pr-1"><IconJob width={20} height={20} active /></span>
                            <EditableInput type='text' name='poste' value={this.props.poste ? this.props.poste : ""} className="header-preview-title pl-30-10 "
                                onBlur={e => {}} placeholder="Definir un poste"
                                onChange={this.props.onChangePoste}
                                state={'preview'} editable required
                                error={{msg: this.errorMsg(this.validator.message('poste', this.props.poste, 'required|string')), placement: "top"}}
                            /> 
                    </div>
                    <div className="d-flex align-items-center mt-1">
                        <span className="ico-content pr-1"></span>
                        <div className="d-flex align-items-center label-input">
                            <span className="ico-content"> <IconContrat active /> </span>
                            <EditableInput withClass='mr-2' type="select" name="contratType" value={this.props.typeContrat ? this.props.typeContrat : ""} options={this.state.contratOptions}
                                optionValue='value' className="pl-30-10" onBlur={event => this.validateContract(event)}  placeholder="Selectionner un type de contrat"
                                onChange={this.props.onChangeContrat} selectedValue={this.props.typeContrat ? this.props.typeContrat : "STAGE"}
                                state={'preview'} editable />
                            { (this.props.seniorityInYears || this.props.seniorityInMonth || this.props.duration) ? <span className="labeled ml-2">{`Depuis ${ this.props.duration }`}</span> : <span></span> }
                        </div>
                    </div>
                </div>
                <div className="content-preview">
                    <div className="row pt-1 pl-3">
                        <div className="col-md-6 col-sm-12">
                            <div className="label-input">du </div>
                            <EditableInput type="customDate" name="StartDate" value={this.props.beginDate ? this.props.beginDate : null } className="pl-30-10" 
                                onBlur={this.props.onBlurBeginDate(this.props.duration)} placeholder='Date de debut'
                                onChange={this.props.onChangeBeginDate} required
                                state={'preview'} editable withIcon={<IconDate width={15} height={10.38} active />}
                                error={ {msg: this.errorMsg(this.validator.message('date de début', this.props.beginDate, 'required')), placement: "bottom"} }
                            />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="label-input">au </div>
                            <EditableInput type="customDate" name="departureDate" value={this.props.endDate ? this.props.endDate : null } className="pl-30-10" 
                                placeholder='Date de fin' 
                                placement="left-start"
                                onChange={this.props.onChangeEndDate}
                                state={'preview'} editable={this.props.typeContrat === CONTRACT_TYPES.CDI ? false : true} withIcon={<IconDate width={15} height={10.38} active />} />
                        </div>
                    </div>
                    <div className="row pt-1 pl-3 item-separator">
                        <div className="col-md-6 col-sm-12">
                            <div className="label-input">Service </div>
                            <EditableInput type="text" name="service" value={this.props.service ? this.props.service : ""} className="pl-30-10" 
                                onBlur={event => {}} placeholder="Service"
                                onChange={this.props.onChangeService}
                                state={'preview'} editable withIcon={<IconService width={15} height={15} active />} />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="label-input">Motif de depart </div>
                            <EditableInput withClass='mr-2' type="select" name="reasonOfDeparture" value={this.props.departure ? this.props.departure : ""} options={this.state.departureOptions}
                                optionValue='value' className="pl-30-10" onBlur={event => {}} placeholder="Entrez le motif de depart"
                                onChange={this.props.onChangeDepartue}
                                state={'preview'} editable withIcon={<IconDepart width={20} height={20} active />} />
                            
                        </div>
                    </div>
                    <div className="row pt-1 pl-3 item-separator mb-1">
                        <div className="col-md-6 col-sm-12 d-flex align-items-center">
                            <div className="label-input">Dirigeant Non Affilié Volontaire CNPS</div>
                        </div>
                        <div>
                        <div className="col-md-6 col-sm-12 d-flex align-items-center">
                            <Toggle ref={this.toggleRef} className="m-0" name="toggle-contributing" radius="3px" radiusBackground="2px" knobRadius="2px"
                                borderWidth="1px" checked={this.state.onChangeNonContributingManager ? this.state.nonContributingManager : false} value={this.state.nonContributingManager ? this.state.nonContributingManager : false}
                                onToggle={this.onChangeNonContributingManager} />
                            </div>
                        </div>
                    </div>
                    <div className="item-separator"></div>
                </div>
            </div>
        );
    }

    render() {
        
        return(
            this.renderNewContract()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        defaultOrganization: state.organizations.defaultOrganization,
        formContractValid: state.validation.formContractValid,
    }
}

export default connect(mapStateToProps, { setContractFormValid })(Contrat)