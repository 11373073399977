import React from "react";

const SvgArrowDown = props => (
  <svg width={props.width} height={props.height} viewBox="0 0 9.434 5.394">
    <path d="M4.719 3.768L8.286.198a.671.671 0 01.952 0 .68.68 0 010 .955L5.2 5.198a.673.673 0 01-.93.02L.2 1.156a.674.674 0 01.952-.955z" 
      fill={props.colors ? props.colors : "#707070"}/>
  </svg>
);

export default SvgArrowDown;
