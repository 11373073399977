import React, { Component } from 'react'
import './OrgsEmpty.css'

class OrgsEmpty extends Component {
    render(){
        return(
            <div className="orgsEmpty-wrapper">
                <div className="orgsEmpty-logo"/>
                <div className="orgsEmpty-content">
                    <span className="orgsEmpty-content-link" onClick={this.props.onCreateOrg}>Enregistrer une entreprise</span>
                </div>
            </div>
        )
    }
}

export default OrgsEmpty