import React, { Component } from 'react'
import PayslipsViewList from '../../../views/PayslipsView/PayslipsViewList'
import PayslipViewDetailList from '../../../views/PayslipsView/PayslipsViewDetailList'

class Payslip extends Component {

    constructor(props) {
        super(props)
        this.state = {
            DetailList: false,
            payPeriod: null
        }
    }
    
    onSelectItem = (item) => {
        this.setState({
         DetailList: true,
         payPeriod: {...item}  
        })
    }

    renderPage = () => {
        let { routes } = this.props
        const {DetailList} = this.state
        switch(true){
            case (routes.params.page ===  'payslip' && DetailList === false):
                return <PayslipsViewList onSelectItem={this.onSelectItem} />
            case (routes.params.page ===  'payslip' && DetailList === true):
                return <PayslipViewDetailList goBack={() => this.setState({ DetailList: false })} period={this.state.payPeriod} />
            default: return null
        }
    }

    render() {
        return ( this.renderPage() )
    }
}

export default Payslip