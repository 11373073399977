import React, { Component } from 'react';
import './InputArea.css';
import IconClose from '../Icons/IconClose';
import CheckIcon from '../Icons/CheckIcon';


const useFocus = () => {
    const ref = React.createRef()
    const setFocus = () => {ref.current &&  ref.current.focus()}

    return {setFocus, ref} 
}

export default class InputArea extends Component {
    constructor(props) {
        super(props);
        this.inputFocus = useFocus();
        this.state = {
            editMode: false
        }
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') this.props.onSave()
        if (event.keyCode === 27) this.onBlur()
    }

    handleChange = (event) => {
        this.props.onInputChange(event.target.value.trim() === ''? event.target.value.trim() : event.target.value)
    }

    onBlur = () => {
        setTimeout(() => {
            this.setState({
                editMode: false
            })
        }, 100)
    }

    onClick = () => {
        this.setState({
            editMode: true
        })
        setTimeout(() => {
            this.inputFocus.setFocus()
        }, 10);
    }

    onSave = () => {
        this.props.onSave()
    }

    onCancel = () => {
        this.props.onInputCancel()
        this.onBlur()
    }

    getDisplayModeClassName = () => {
        return this.state.editMode===true ? 'hidden' : 'font-size-10 mb-1 inputarea-wrapper inputarea';
    }

    getEditModeClassName = () => {
        return this.state.editMode===true ? 'font-size-10 mb-1 inputarea-wrapper edit-mode inputarea-edit' : ' hidden';
    }
    
    render() {
        let { editMode } = this.state;
        let { tag } = this.props;
        if(tag) {
            editMode = true;
            setTimeout(() => {
                this.inputFocus.setFocus()
            }, 10);
        }
        return (
            <div>
                <div onClick={this.onClick} className={this.getDisplayModeClassName()}>{tag || 'Saisir une observation...'}</div>
                <input type="text" onChange={this.handleChange} ref={this.inputFocus.ref} 
                    onBlur={this.onBlur} rows="7" 
                    className={this.getEditModeClassName()} 
                    value={tag || ''} onKeyPress={this.handleKeyPress}/>
                <div className={editMode===true ? 'inputarea-actions-wrapper' : 'hidden'}>
                    <div className="inputarea-action" onClick={()=>{this.onSave()}}>
                        <CheckIcon color="#707070"/>
                    </div>
                    <div className="inputarea-action ml-1" onClick={this.onCancel}>
                        <IconClose color="#707070"/>
                    </div>
                </div>
            </div>
        )
    }
}