/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const IoniconsSvgIosArrowBackComponent = (props) => (
  <svg width={props.width ? props.width : 11.002} height={props.height ? props.height : 7.068} viewBox="0 0 11.002 7.068">
    <defs>
      <style>{".drop-up-icon{fill:#707070;}"}</style>
    </defs>
    <path
      fill={props.color ? props.color : '#707070'}
      className="drop-up-icon"
      d="M161.837,93.053l4.016-3.756a.675.675,0,0,0,0-1,.8.8,0,0,0-1.074,0l-4.55,4.256a.676.676,0,0,0-.022.979l4.569,4.285a.8.8,0,0,0,1.074,0,.675.675,0,0,0,0-1Z"
      transform="translate(98.521 -159.508) rotate(90)"
    />
  </svg>
);

export default IoniconsSvgIosArrowBackComponent;
