/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const RedFlagComponent = (props) => (
  <svg width={props.width ? props.width : 23.358} height={props.height ? props.height : 6.212} viewBox="0 0 23.358 16.212">
    <defs>
      <style>
        {
          `.mapa{fill:${props.active ? '#177BD1;' : '#e4e4e3;'}}.mapb{fill:${props.active ? '#177BD1;' : '#c7c7c7;' }}.mapc{fill:#908e8d;}.mapd{fill:${props.active ? '#177BD1;': '#a5a5a5;'}}.mape{fill:#696967;}.mapf{fill:#a3a3a3;}`
        }
      </style>
    </defs>
    <g transform="translate(-0.881 -7.617)">
      <g transform="translate(0.881 7.617)">
        <path
          className="mapa"
          d="M6.823,28.028,1.483,29.193A.5.5,0,0,1,.9,28.562c2.816-8.642,2.622-8.3,3.01-8.4l4.224-.728Z"
          transform="translate(-0.881 -13.175)"
        />
        <path
          className="mapb"
          d="M12.1,28.071,17.83,29.43l-.049-9.176-4.321-.728Z"
          transform="translate(-6.158 -13.218)"
        />
        <path
          className="mapb"
          d="M32.4,28.071l5.341,1.165a.486.486,0,0,0,.583-.631c-2.962-8.642-2.719-8.3-3.156-8.351l-4.273-.728Z"
          transform="translate(-14.998 -13.218)"
        />
        <path
          className="mapa"
          d="M28.6,28.071,22.874,29.43l-.049-9.176,4.273-.728Z"
          transform="translate(-11.203 -13.218)"
        />
        <path
          className="mapa"
          d="M8.226,19.526c-.291,1.942-1.165,7.186-1.262,7.817l-.1.728-.388.1-4.9,1.068A.5.5,0,0,1,.992,28.6L2.5,23.992l.194-.631h0C3.613,20.788,3.468,20.3,4,20.254Zm10.1,8.545L12.6,29.43c0-.049-.049-4.467-.049-4.224L12.5,20.254l3.933-.68.388-.049.049.388Z"
          transform="translate(-0.924 -13.218)"
        />
        <path
          className="mapb"
          d="M26.88,19.526h0L22.9,26.032c-.049-.049-.583-.388-.534-.388,5.778-9.128,3.01-4.758,3.787-5.972l.34-.049Z"
          transform="translate(-10.985 -13.218)"
        />
        <path
          className="mapb"
          d="M10.031,30.782v.049l-.777.194L7.7,25.684l.583-.291Z"
          transform="translate(-4.089 -15.978)"
        />
        <path
          className="mapc"
          d="M9.4,22.605a.572.572,0,0,1-.874-.631c.049-.194,0-.34-.243-.291-.049,0-1.359.777-1.7.194-.1-.146-.049-.291-.049-.486a.528.528,0,0,0-.486-.34.485.485,0,0,1,.194-.243.675.675,0,0,1,.534.68c0,.194.049.291.34.291.777-.049,1.311-.728,1.651-.243.146.194.049.388.049.583a.292.292,0,0,0,.486.194Zm5.875,5.438a4.2,4.2,0,0,1-1.02.34.891.891,0,0,1-1.068-.728c-.194-1.117-1.845,0-2.185-1.117-.243-.68.874-1.748,1.505-2.136l.146.194c-1.02.583-2.379,2.282-.437,2.233,2.282-.049.146,2.136,3.01.971,1.991-.825,1.359,1.7,4.127-.243,1.457-1.02.1-1.408-1.408-2.282l.1-.194c1.359.777,2.088,1.02,2.185,1.651.1.777-1.408,1.554-1.894,1.748C16.489,29.063,16.78,27.412,15.275,28.043Zm8.448-1.6-.194-.194a.951.951,0,0,0,.049-1.214c-.194-.243-1.845-1.117-.486-1.8,1.068-.534.291-1.651,0-1.359-.1.1,0,.583-.486.922-.777.486-.971-.437-1.8-.1a1.458,1.458,0,0,0-.777,1.359l-.243.049a1.5,1.5,0,0,1,.922-1.6c.922-.388,1.117.486,1.748.1.34-.194.291-.486.34-.534.1-.631.631-.534.874-.243a1.089,1.089,0,0,1-.243,1.505c-.291.194-1.165.437-.049,1.214a2.668,2.668,0,0,1,.437.34A1.187,1.187,0,0,1,23.723,26.441Z"
          transform="translate(-3.313 -13.822)"
        />
        <path
          className="mapc"
          d="M25.17,29.138c-.728-.437,2.476,1.457-6.312-3.69l-5.049,2.33c-.534-.486,1.845,1.7-4.709-4.37L3.758,25.545l.194-.631h0c1.068-.437-2.767,1.068,5.292-2.088L13.857,27c.583-.243-1.894.874,4.855-2.233l6.069,3.5Z"
          transform="translate(-2.235 -14.771)"
        />
        <path
          className="mapd"
          d="M28.345,8.341v2.573a.554.554,0,0,1-.728.534c-1.8-.486-3.544,1.7-5.341.34V8.049C24.169,9.506,26.111,6.836,28,7.855A.585.585,0,0,1,28.345,8.341Z"
          transform="translate(-10.944 -7.617)"
        />
        <path
          className="mape"
          d="M22.858,8.175v8.011a.291.291,0,0,1-.583,0V8.175a.291.291,0,1,1,.583,0Z"
          transform="translate(-10.944 -7.742)"
        />
        <path
          className="mapf"
          d="M22.858,8.725v3.787a1.818,1.818,0,0,1-.583-.34V8.434A1.641,1.641,0,0,0,22.858,8.725Z"
          transform="translate(-10.944 -8.001)"
        />
      </g>
    </g>
  </svg>
);

export default RedFlagComponent;
