import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from "moment";
import DatePicker from "react-datepicker";
import { updateOrganizationTaxes } from "../../redux/action/OrganisationAction";
import {
    selectTaxe, handleChange, onChangeTable8, onChangeTable9,
    onChangeTable10, onChangeTable11, onChangeTable12, onChangeTable13, onChangeTable14, onChangeTable15, onChangeFooter,
} from "../../redux/action/TaxesAction";
// import { numberFormat } from '../../helpers/Number';



class StepFourForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        this.init();
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }

    init = () => {
        if (!this.props.selectedPeriod) {
            this.props.history.push('/taxes');
        };
    }


    getPayload = () => {

        let varIsCash = false;
        let varIsCheque = false;
        let varIsTransfer = false;
        switch (this.props.selectedTaxes.paymentType) {
            case ("isCash"): {
                varIsCash = true;
                break;
            }
            case ("isTransfer"): {
                varIsTransfer = true;
                break;
            }
            case ("isCheque"): {
                varIsCheque = true;
                break;
            }
            default: {
                varIsCash = true;
            }
        }

        return {
            capitalGainsTax: {
                disposalOfFixedAssets: { transferPrice: this.props.selectedTaxes.l74TransferPrice, netBookValue: this.props.selectedTaxes.l74NetBookValue, capitalGainsOrLosses: this.props.selectedTaxes.l74CapitalGainsOrLosses, rate: this.props.selectedTaxes.l74Rate, paymentTaxes: this.props.selectedTaxes.l74PaymentTaxes },
                disposalOfMarketableSecurities: { transferPrice: this.props.selectedTaxes.l75TransferPrice, netBookValue: this.props.selectedTaxes.l75NetBookValue, capitalGainsOrLosses: this.props.selectedTaxes.l75CapitalGainsOrLosses, rate: this.props.selectedTaxes.l75Rate, paymentTaxes: this.props.selectedTaxes.l75PaymentTaxes },
                propertyCapitalGains: { transferPrice: this.props.selectedTaxes.l76TransferPrice, deductibleExpense: this.props.selectedTaxes.l76DeductibleExpense, capitalGainsOrLosses: this.props.selectedTaxes.l76CapitalGainsOrLosses, rate: this.props.selectedTaxes.l76Rate, paymentTaxes: this.props.selectedTaxes.l76PaymentTaxes },
                total: { transferPrice: this.props.selectedTaxes.l77TransferPrice, deductibleExpense: this.props.selectedTaxes.l77DeductibleExpense, capitalGainsOrLosses: this.props.selectedTaxes.l77CapitalGainsOrLosses, rate: this.props.selectedTaxes.l77Rate, paymentTaxes: this.props.selectedTaxes.l77PaymentTaxes },
            },
            airportStampDuty: {
                internationalFlights: { numberOfPassengers: this.props.selectedTaxes.l78NumberOfPassengers, rate: this.props.selectedTaxes.l78Rate, principal: this.props.selectedTaxes.l78Principal, penalities: this.props.selectedTaxes.l78Penalties, total: this.props.selectedTaxes.l78Total },
                nationalFlights: { numberOfPassengers: this.props.selectedTaxes.l79NumberOfPassengers, rate: this.props.selectedTaxes.l79Rate, principal: this.props.selectedTaxes.l79Principal, penalities: this.props.selectedTaxes.l79Penalties, total: this.props.selectedTaxes.l79Total },
                total: { numberOfPassengers: this.props.selectedTaxes.l80NumberOfPassengers, rate: this.props.selectedTaxes.l80Rate, principal: this.props.selectedTaxes.l80Principal, penalities: this.props.selectedTaxes.l80Penalties, total: this.props.selectedTaxes.l80Total },
            },
            recapitulativeTotal: {
                tsr: { principal: this.props.selectedTaxes.l81Principal, cac: this.props.selectedTaxes.l81Cac, penalities: this.props.selectedTaxes.l81Penalties, total: this.props.selectedTaxes.l81Total },
                exicesDuties: { principal: this.props.selectedTaxes.l82Principal, cac: this.props.selectedTaxes.l82Cac, penalities: this.props.selectedTaxes.l82Penalties, total: this.props.selectedTaxes.l82Total, rate: this.props.selectedTaxes.l82CheckBox },
                vat: { principal: this.props.selectedTaxes.l83Principal, cac: this.props.selectedTaxes.l83Cac, penalities: this.props.selectedTaxes.l83Penalties, total: this.props.selectedTaxes.l83Total },
                acOrIr: { principal: this.props.selectedTaxes.l84Principal, cac: this.props.selectedTaxes.l84Cac, penalities: this.props.selectedTaxes.l84Penalties, total: this.props.selectedTaxes.l84Total },
                depositToBePaid: { principal: this.props.selectedTaxes.l85Principal, cac: this.props.selectedTaxes.l85Cac, penalities: this.props.selectedTaxes.l85Penalties, total: this.props.selectedTaxes.l85Total },
                ircm: { principal: this.props.selectedTaxes.l86Principal, cac: this.props.selectedTaxes.l86Cac, penalities: this.props.selectedTaxes.l86Penalties, total: this.props.selectedTaxes.l86Total },
                iROnNoneCommercial: { principal: this.props.selectedTaxes.l87Principal, cac: this.props.selectedTaxes.l87Cac, penalities: this.props.selectedTaxes.l87Penalties, total: this.props.selectedTaxes.l87Total },
                rts: { principal: this.props.selectedTaxes.l88Principal, cac: this.props.selectedTaxes.l88Cac, penalities: this.props.selectedTaxes.l88Penalties, total: this.props.selectedTaxes.l88Total },
                taxesOnCapitalGains: { principal: this.props.selectedTaxes.l89Principal, cac: this.props.selectedTaxes.l89Cac, penalities: this.props.selectedTaxes.l89Penalties, total: this.props.selectedTaxes.l89Total },
                airportStampDuty: { principal: this.props.selectedTaxes.l90Principal, cac: this.props.selectedTaxes.l90Cac, penalities: this.props.selectedTaxes.l90Penalties, total: this.props.selectedTaxes.l90Total },
                totalToPay: { principal: this.props.selectedTaxes.l91Principal, cac: this.props.selectedTaxes.l91Cac, penalities: this.props.selectedTaxes.l91Penalties, total: this.props.selectedTaxes.l91Total },
            },
            location: this.props.selectedTaxes.location,
            declarationDate: this.props.selectedTaxes.declarationDate,
            amount: +this.props.selectedTaxes.amount,
            isCash: varIsCash,
            isCheque: varIsCheque,
            isTransfer: varIsTransfer,
            cheque: {
                chequeNumber: this.props.selectedTaxes.chequeNumber,
                bank: this.props.selectedTaxes.bank,
                agency: this.props.selectedTaxes.agency,
            },

        }
    }

    handleChange = (e, callbacks = null) => {
        console.log("log event: ", e);
        this.props.handleChange(e);
        if (callbacks) {
            callbacks();
        }
    }

    render() {
        return <div className="step-four">
            {/* TABLEAU 13 */}
            <div className="d-flex align-items-center justify-content-between mt-2 pl-2 pr-2">
                <table className="table-taxes">
                    <thead>
                        <tr align="center">
                            <td width="40%" align="left"><span className="sm-txt bold pl-2">13 - IMPOTS SUR LES PLUS-VALUES</span></td>
                            <td colSpan="4" width="15%"><span className="sm-txt bold">PRIX DE CESSION</span></td>
                            <td width="10%"><span className="sm-txt bold">VALEUR NET COMPTABLE</span></td>
                            <td width="10%"><span className="sm-txt bold">+ /- VALUES</span></td>
                            <td width="10%"><span className="sm-txt bold">TAUX</span></td>
                            <td width="15%"><span className="sm-txt bold">IMPOTS A PAYER</span></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span className="sm-txt pl-2">Cession d’immobilisations</span></td>
                            <td align="center" className="bordered-bottom"> <span className="sm-txt  bold line-indicator bold">L74</span> </td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l74TransferPrice" value={this.props.selectedTaxes.l74TransferPrice} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable13(); this.props.onChangeTable15(); })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l74NetBookValue" value={this.props.selectedTaxes.l74NetBookValue} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable13(); this.props.onChangeTable15(); })} className="input-text text-right pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l74CapitalGainsOrLosses" value={this.props.selectedTaxes.l74CapitalGainsOrLosses} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable13(); this.props.onChangeTable15(); })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l74Rate" value={this.props.selectedTaxes.l74Rate} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable13(); this.props.onChangeTable15(); })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l74PaymentTaxes" value={this.props.selectedTaxes.l74PaymentTaxes || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable13(); this.props.onChangeTable15(); })} className="input-text text-right-h pr-2 bold" disabled /></td>
                        </tr>
                        <tr>
                            <td>
                                <span className="sm-txt pl-2 align-items-center" style={{ float: "left" }}>Cession de titres négociables</span>
                            </td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L75</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l75TransferPrice" value={this.props.selectedTaxes.l75TransferPrice} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable13(); this.props.onChangeTable15(); })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l75NetBookValue" value={this.props.selectedTaxes.l75NetBookValue} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable13(); this.props.onChangeTable15(); })} className="input-text text-right pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l75CapitalGainsOrLosses" value={this.props.selectedTaxes.l75CapitalGainsOrLosses} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable13(); this.props.onChangeTable15(); })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l75Rate" value={this.props.selectedTaxes.l75Rate} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable13(); this.props.onChangeTable15(); })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l75PaymentTaxes" value={+this.props.selectedTaxes.l75PaymentTaxes || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable13(); this.props.onChangeTable15(); })} className="input-text text-right-h pr-2 bold" disabled /></td>
                        </tr>
                        <tr align="center">
                            <td bgcolor="#EFEFEF">
                                <span className="sm-txt pl-2 align-items-center" style={{ float: "left" }}></span>
                            </td>
                            <td colSpan="4" width="15%" className="bordered"><span className="sm-txt bold">PRIX DE CESSION</span></td>
                            <td width="10%" className="bordered"><span className="sm-txt bold">CHARGES DEDUCTIBLES</span></td>
                            <td width="10%" className="bordered"><span className="sm-txt bold">+ /- VALUES</span></td>
                            <td width="10%" className="bordered"><span className="sm-txt bold">TAUX</span></td>
                            <td width="15%" className="bordered"><span className="sm-txt bold">IMPOTS A PAYER</span></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Plus-value immobilière par les particuliers</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L76</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l76TransferPrice" value={this.props.selectedTaxes.l76TransferPrice} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable13(); this.props.onChangeTable15(); })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l76DeductibleExpense" value={this.props.selectedTaxes.l76DeductibleExpense} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable13(); this.props.onChangeTable15(); })} className="input-text text-right pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l76CapitalGainsOrLosses" value={this.props.selectedTaxes.l76CapitalGainsOrLosses} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable13(); this.props.onChangeTable15(); })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l76Rate" value={this.props.selectedTaxes.l76Rate} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable13(); this.props.onChangeTable15(); })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l76PaymentTaxes" value={+this.props.selectedTaxes.l76PaymentTaxes || ''} placeholder="" disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td align="center"><span className="sm-txt pl-2">TOTAL</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt bold line-indicator bold">L77</span></td>
                            <td align="right" colSpan="3" className="bordered"><input type="text" name="l77TransferPrice" value={+this.props.selectedTaxes.l77TransferPrice || ''} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l77DeductibleExpense" value={+this.props.selectedTaxes.l77DeductibleExpense || ''} placeholder="" className="input-text bold text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l77CapitalGainsOrLosses" value={+this.props.selectedTaxes.l77CapitalGainsOrLosses || ''} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l77Rate" value={+this.props.selectedTaxes.l77Rate || ''} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l77PaymentTaxes" value={+this.props.selectedTaxes.l77PaymentTaxes || ''} placeholder="" className="input-text text-right-h pr-2 bold" disabled /></td>
                        </tr>
                    </tbody>
                    <p><span className="ml-3 sm-txt float-left"><sup>1</sup>Charges réelles déductibles = valeur d’acquisition + frais réels de construction et/ou de transformation dûment justifiés NB: En cas de moins values, pas d’imposition</span></p>
                </table>
            </div>

            {/* TABLEAU 14 */}
            <div className="d-flex align-items-center justify-content-between mt-4 pl-2 pr-2">
                <h3 class="title-table normal-txt-uppercase bold">DROITS DE TIMBRE D’AÉROPORT</h3>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-2 pl-2 pr-2">
                <table className="table-taxes">
                    <thead>
                        <tr align="center">
                            <td width="40%" align="left"><span className="sm-txt bold pl-2">14- DROIT DE TIMBRE D’AÉROPORT</span></td>
                            <td colSpan="4" width="15%"><span className="sm-txt bold">NOMBRE DE PASSAGERS</span></td>
                            <td width="10%"><span className="sm-txt bold">TAUX</span></td>
                            <td width="10%"><span className="sm-txt bold">PRINCIPAL</span></td>
                            <td width="10%"><span className="sm-txt bold">PÉNALITES</span></td>
                            <td width="15%"><span className="sm-txt bold">TOTAL</span></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span className="sm-txt pl-2">Vols internationaux</span></td>
                            <td align="center" className="bordered-bottom"> <span className="sm-txt  bold line-indicator bold">L78</span> </td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l78NumberOfPassengers" value={this.props.selectedTaxes.l78NumberOfPassengers} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable14();  this.props.onChangeTable15(); })} className="input-text text-right-h pr-2" /></td>
                            <td align="center" className="bordered"><input type="text" name="l78Rate" value={this.props.selectedTaxes.l78Rate} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable14();  this.props.onChangeTable15(); })} className="input-text input-align-center pr-2" disabled /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l78Principal" value={this.props.selectedTaxes.l78Principal} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable14();  this.props.onChangeTable15(); })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l78Penalties" value={this.props.selectedTaxes.l78Penalties} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable14();  this.props.onChangeTable15(); })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l78Total" value={this.props.selectedTaxes.l78Total || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable14();  this.props.onChangeTable15(); })} className="input-text text-right-h pr-2 bold" disabled /></td>
                        </tr>
                        <tr>
                            <td>
                                <span className="sm-txt pl-2 align-items-center" style={{ float: "left" }}>Vols nationaux</span>
                            </td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L79</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l79NumberOfPassengers" value={this.props.selectedTaxes.l79NumberOfPassengers} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable14();  this.props.onChangeTable15(); })} className="input-text text-right-h pr-2" /></td>
                            <td align="center" className="bordered"><input type="text" name="l79Rate" value={this.props.selectedTaxes.l79Rate} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable14();  this.props.onChangeTable15(); })} className="input-text input-align-center pr-2" disabled /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l79Principal" value={this.props.selectedTaxes.l79Principal} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable14();  this.props.onChangeTable15(); })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l79Penalties" value={this.props.selectedTaxes.l79Penalties} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable14();  this.props.onChangeTable15(); })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l79Total" value={+this.props.selectedTaxes.l79Total || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable14();  this.props.onChangeTable15(); })} className="input-text text-right-h pr-2 bold" disabled /></td>
                        </tr>
                        <tr>
                            <td align="center"><span className="sm-txt pl-2">TOTAL</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt bold line-indicator bold">L80</span></td>
                            <td align="right" colSpan="3" className="bordered"><input type="text" name="l80NumberOfPassengers" value={+this.props.selectedTaxes.l80NumberOfPassengers || ''} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                            <td align="center" className="bordered"><input type="text" name="l80Rate" value={+this.props.selectedTaxes.l80Rate || ''} placeholder="" className="input-text bold input-align-center pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l80Principal" value={+this.props.selectedTaxes.l80Principal || ''} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l80Penalties" value={+this.props.selectedTaxes.l80Penalties || ''} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l80Total" value={+this.props.selectedTaxes.l80Total || ''} placeholder="" className="input-text text-right-h pr-2 bold" disabled /></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* TABLEAU 15 */}
            <div className="d-flex align-items-center justify-content-between mt-4 pl-2 pr-2">
                <table className="table-taxes">
                    <thead>
                        <tr align="center">
                            <td width="40%" align="left"><span className="sm-txt bold pl-2">TOTAL RECAPITULATIF</span></td>
                            <td width="15%"><span className="sm-txt bold">PRINCIPAL</span></td>
                            <td width="15%"><span className="sm-txt bold">CAC</span></td>
                            <td width="15%"><span className="sm-txt bold">PÉNALITÉS </span></td>
                            <td width="15%"><span className="sm-txt bold">TOTAL</span></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span className="sm-txt pl-2 align-items-center">TSR ( = L 0 )</span></td>
                            <td align="right" className="border-dashed" ><input type="text" name="l81Principal" value={this.props.selectedTaxes.l81Principal || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l81Cac" value={this.props.selectedTaxes.l81Cac || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l81Penalties" value={this.props.selectedTaxes.l81Penalties || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l81Total" value={this.props.selectedTaxes.l81Total || ''} disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2 d-flex align-item-center">Droit d’Accises =
                                    <label className="d-flex align-items-center checkbox mr-1 mb-0 ml-1"><span class="mr-2">(L8)</span><input type="checkbox" name="l82CheckBox" value={8} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} checked={+this.props.selectedTaxes.l82CheckBox === 8} className="table-checkbox" /></label>
                                <span className="ml-1 mr-1">ou</span>
                                <label className="d-flex align-items-center checkbox ml-1 mr-0 mb-0"><span class="mr-2">(L9)</span><input type="checkbox" name="l82CheckBox" value={9} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} checked={+this.props.selectedTaxes.l82CheckBox === 9} className="table-checkbox" /></label>
                            </span>
                            </td>
                            <td align="right" className="border-dashed"><input type="text" name="l82Principal" value={this.props.selectedTaxes.l82Principal || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l82Cac" value={this.props.selectedTaxes.l82Cac || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l82Penalties" value={this.props.selectedTaxes.l82Penalties || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l82Total" value={this.props.selectedTaxes.l82Total || ''} disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">TVA ( = L 39)</span></td>
                            <td align="right" className="border-dashed"><input type="text" name="l83Principal" value={this.props.selectedTaxes.l83Principal || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l83Cac" value={this.props.selectedTaxes.l83Cac || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l83Penalties" value={this.props.selectedTaxes.l83Penalties || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l83Total" value={this.props.selectedTaxes.l83Total || ''} disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">AC/IR + Précomptes ( = L 44)</span></td>
                            <td align="right" className="border-dashed"><input type="text" name="l84Principal" value={this.props.selectedTaxes.l84Principal || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l84Cac" value={this.props.selectedTaxes.l84Cac || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l84Penalties" value={this.props.selectedTaxes.l84Penalties || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l84Total" value={this.props.selectedTaxes.l84Total || ''} disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Acompte à payer ( = L 54 )</span></td>
                            <td align="right" className="border-dashed"><input type="text" name="l85Principal" value={this.props.selectedTaxes.l85Principal || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l85Cac" value={this.props.selectedTaxes.l85Cac || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l85Penalties" value={this.props.selectedTaxes.l85Penalties || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l85Total" value={this.props.selectedTaxes.l85Total || ''} disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">IRCM ( = L 62)</span></td>
                            <td align="right" className="border-dashed"><input type="text" name="l86Principal" value={this.props.selectedTaxes.l86Principal || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l86Cac" value={this.props.selectedTaxes.l86Cac || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l86Penalties" value={this.props.selectedTaxes.l86Penalties || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l86Total" value={this.props.selectedTaxes.l86Total || ''} disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Impôts sur revenus non commerciaux (= L 66)</span></td>
                            <td align="right" className="border-dashed"><input type="text" name="l87Principal" value={this.props.selectedTaxes.l87Principal || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l87Cac" value={this.props.selectedTaxes.l87Cac || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l87Penalties" value={this.props.selectedTaxes.l87Penalties || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l87Total" value={this.props.selectedTaxes.l87Total || ''} disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">RTS / salaires (= L 73)</span></td>
                            <td align="right" className="border-dashed"><input type="text" name="l88Principal" value={this.props.selectedTaxes.l88Principal || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l88Cac" value={this.props.selectedTaxes.l88Cac || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l88Penalties" value={this.props.selectedTaxes.l88Penalties || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l88Total" value={this.props.selectedTaxes.l88Total || ''} disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Impots sur la plus-value ( = L 77 )</span></td>
                            <td align="right" className="border-dashed"><input type="text" name="l89Principal" value={this.props.selectedTaxes.l89Principal || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l89Cac" value={this.props.selectedTaxes.l89Cac || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l89Penalties" value={this.props.selectedTaxes.l89Penalties || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l89Total" value={this.props.selectedTaxes.l89Total || ''} disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Droit de timbre aéroport ( = L 80 )</span></td>
                            <td align="right" className="border-dashed"><input type="text" name="l90Principal" value={this.props.selectedTaxes.l90Principal || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l90Cac" value={this.props.selectedTaxes.l90Cac || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l90Penalties" value={this.props.selectedTaxes.l90Penalties || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l90Total" value={this.props.selectedTaxes.l90Total || ''} disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td align="center"><span className="sm-txt pl-2">TOTAL A PAYER</span></td>
                            <td align="right" className="bordered"><input type="text" name="l91Principal" value={this.props.selectedTaxes.l91Principal || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l91Cac" value={this.props.selectedTaxes.l91Cac || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l91Penalties" value={this.props.selectedTaxes.l91Penalties || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeTable15() })} className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l91Total" value={this.props.selectedTaxes.l91Total || ''} disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* TABLEAU FOOTER */}
            <div className="d-flex flex-row align-items-center container-fluid justify-content-between mt-5 pl-2 pr-2" id="tax-footer">
                <div className="d-flex flex-column col-6 p-3 justify-items-center" style={{ height: "500px", border: "1px solid lightgrey" }}>
                    <p align="center" class="center normal-txt-uppercase bold">TIMBRE À DATE DE L’ADMINISTRATION</p>
                    <hr style={{ width: "100%", textAlign: "left", marginLeft: "0", marginTop: "100px" }}></hr>
                    <p align="center" class="center normal-txt-uppercase bold mb-4">CADRE RÉSERVÉ À L’ADMINISTRATION</p>
                    <div>
                        <label>N° de quittance :</label>
                        <input className="bottom-bordered-input" style={{ width: "100%", border: "none", borderBottom: "1px solid lightgrey" }} ></input>
                    </div>
                    <div className="mt-4 p-0">
                        <label>Délivrée le :</label>
                        <input style={{ width: "100%", border: "none", borderBottom: "1px solid lightgrey" }} ></input>
                    </div>
                    <div className="mt-4 p-0">
                        <label>Pénalités :</label>
                        <input style={{ width: "100%", border: "none", borderBottom: "1px solid lightgrey" }} ></input>
                    </div>
                    <p align="left" className="bold mt-4">Le Receveur des Impôts</p>
                </div>
                <div className="d-flex flex-column col-6 p-3 justify-items-center" style={{ height: "500px", border: "1px solid lightgrey" }}>
                    <div className="d-flex flex-column">
                        <p align="center" class="center normal-txt-uppercase bold">LE DÉCLARANT</p>
                        <div className="d-flex flex-row">
                            <label className="mr-3" >A</label> <input name="location" value={this.props.selectedTaxes.location || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeFooter(); })} style={{ width: "50%", border: "none", borderBottom: "1px dashed lightgrey" }} ></input>
                            <label className="mr-3" >Le</label>
                            <DatePicker
                                selected={this.props.selectedTaxes.declarationDate ? new Date(this.props.selectedTaxes.declarationDate) : new Date()}
                                name="declarationDate"
                                value={this.props.selectedTaxes.declarationDate ? moment(this.props.selectedTaxes.declarationDate).format("DD/MM/YYYY") : ''}
                                onChange={(e) => this.props.onChangeFooter(e) }
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                dateFormat="dd/MM/yyyy"
                                todayButton="Aujourd'hui"
                                placeholderText={`${moment().format("DD/MM/YYYY")}`}
                                popperPlacement={"bottom-end"}
                                popperClassName="zi-9999"
                                disabled={this.props.preview}
                                style={{ width: "50%", border: "1px dashed lightgrey" }}
                            />
                        </div>
                    </div>
                    <p className="mt-3">Cachet et signature</p>
                    <hr style={{ width: "100%", textAlign: "left", marginLeft: "0", marginTop: "10px" }}></hr>
                    <p align="center" class="center normal-txt-uppercase bold mb-4">MODE DE PAIEMENT</p>
                    <div className="d-flex flex-row ml-0">
                        <label className="mr-2 col-4 pl-0" style={{ width: "200px" }} >Montant <i>(en chiffres)</i> :</label>
                        <div className="col-8 pl-0"><input className="align-self-stretch" name="amount" value={this.props.selectedTaxes.amount || ''} onChange={(e) => this.handleChange(e, () => { this.props.onChangeFooter(); })} style={{ width: "100%", height: "20px", border: "none", borderBottom: "1px dashed lightgrey" }} ></input></div>
                    </div>
                    <div className="d-flex flex-column">
                        <label className="mr-2 col-12 pl-0" align="left">Règlement effectué par <i>(cocher la case correspondante)</i>:</label>
                        <div className="col-12 d-flex flex-row pl-0">
                            <label className="d-flex align-items-center checkbox ml-2 mr-2 mb-0"><input type="checkbox" name="paymentType" value="isCash" onChange={(e) => this.handleChange(e, () => { this.props.onChangeFooter(); })} className="table-checkbox" checked={this.props.selectedTaxes.paymentType === "isCash"} /><span className="ml-2 sm-txt">Espèces</span> </label>
                            <label className="d-flex align-items-center checkbox ml-2 mr-2 mb-0"><input type="checkbox" name="paymentType" value="isTransfer" onChange={(e) => this.handleChange(e, () => { this.props.onChangeFooter(); })} className="table-checkbox" checked={this.props.selectedTaxes.paymentType === "isTransfer"} /><span className="ml-2 sm-txt">Ordre de virement</span> </label>
                            <label className="d-flex align-items-center checkbox ml-2 mr-2 mb-0"><input type="checkbox" name="paymentType" value="isCheque" onChange={(e) => this.handleChange(e, () => { this.props.onChangeFooter(); })} className="table-checkbox" checked={this.props.selectedTaxes.paymentType === "isCheque"} /><span className="ml-2 sm-txt">Chèque</span> </label>
                        </div>
                    </div>
                    <div className="d-flex flex-column">
                        <div className="mt-2 p-0">
                            <label>N<sup>o</sup> :</label>
                            <input name="chequeNumber" value={this.props.selectedTaxes.chequeNumber} onChange={(e) => this.handleChange(e, () => { this.props.onChangeFooter(); })} style={{ width: "100%", border: "none", borderBottom: "1px dashed lightgrey" }} ></input>
                        </div>
                        <div className="mt-2 p-0">
                            <label>Banque :</label>
                            <input name="bank" value={this.props.selectedTaxes.bank} onChange={(e) => this.handleChange(e, () => { this.props.onChangeFooter(); })} style={{ width: "100%", border: "none", borderBottom: "1px dashed lightgrey" }} ></input>
                        </div>
                        <div className="mt-2 p-0">
                            <label>Agence :</label>
                            <input name="agency" value={this.props.selectedTaxes.agency} onChange={(e) => this.handleChange(e, () => { this.props.onChangeFooter(); })} style={{ width: "100%", border: "none", borderBottom: "1px dashed lightgrey" }} ></input>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        selectedPeriod: state.organizations.selectedOrgTaxes,
        selectedTaxes: state.taxes.selectedTaxe,
    }
}

export default connect(mapStateToProps, { updateOrganizationTaxes, selectTaxe, handleChange, onChangeTable8, onChangeTable9, onChangeTable10, onChangeTable11, onChangeTable12, onChangeTable13, onChangeTable14, onChangeTable15, onChangeFooter })(StepFourForm);


