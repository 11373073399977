import React from "react";

const IoniconsSvgIosKeyComponent = (props) => (
  <svg width={props.width ? props.width : 16.86} height={props.height ? props.height : 16.858} viewBox="0 0 16.86 16.858">
    <defs>
      <style>{`.key{fill:${props.active ? '#177BD1;': '#b5b5b5;'}}`}</style>
    </defs>
    <path
      className="key"
      d="M60,54.893a4.577,4.577,0,0,0-1.516.166c-2.051-1.747-7.469-6.358-7.882-6.788A.817.817,0,0,0,49.992,48a1.352,1.352,0,0,0-.863.389c-.28.267-1.337,1.41-1.135,1.621.608.624.77.75,1.021,1.005.377.385,1.147-.041,1.459.093a.6.6,0,0,1,.421.507,7.574,7.574,0,0,1-.122.96,1.014,1.014,0,0,0,.373.77.853.853,0,0,0,.632.353c.365.008.847-.519,1.232-.126s-.251.96-.2,1.378a1.53,1.53,0,0,0,.875.977c.247.053.883-.474,1.244-.393a.846.846,0,0,1,.446.462c.041.186-.28,1.013-.239,1.2a2.849,2.849,0,0,0,.421.705,4.564,4.564,0,0,0-.381,1.933,4.83,4.83,0,1,0,9.653,0A4.857,4.857,0,0,0,60,54.893Zm.94,7.371a1.3,1.3,0,1,1,1.3-1.3A1.3,1.3,0,0,1,60.937,62.265Z"
      transform="translate(-47.968 -48)"
    />
  </svg>
);

export default IoniconsSvgIosKeyComponent;
