/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const FontAwesomeSolidUnlinkComponent = (props) => (
  <svg width={props.width ? props.width : 21} height={props.height ? props.height : 21} viewBox="0 0 18.247 18.247">
    <defs>
      <style>{".icon-unlink{fill:#707070;opacity:0.85;}"}</style>
    </defs>
    <path
      fill={props.color ? props.color : '#707070'}
      className="icon-unlink"
      d="M7.7,10.183a.3.3,0,0,1,0,.426L6.565,11.73a3.876,3.876,0,0,1-5.441,0,3.794,3.794,0,0,1,0-5.393L2.255,5.216a.306.306,0,0,1,.43,0l1,.993a.3.3,0,0,1,0,.426L2.556,7.756a1.8,1.8,0,0,0,0,2.555,1.836,1.836,0,0,0,2.577,0L6.264,9.19a.306.306,0,0,1,.43,0ZM6.264,3.655a.306.306,0,0,0,.43,0L7.824,2.534a1.836,1.836,0,0,1,2.577,0,1.8,1.8,0,0,1,0,2.555L9.271,6.209a.3.3,0,0,0,0,.426l1,.993a.306.306,0,0,0,.43,0l1.131-1.121a3.794,3.794,0,0,0,0-5.393,3.876,3.876,0,0,0-5.441,0L5.262,2.235a.3.3,0,0,0,0,.426l1,.993ZM9.18,9.641l.573-.568a.6.6,0,0,0,0-.852L4.637,3.2a.611.611,0,0,0-.859,0L3.2,3.771a.6.6,0,0,0,0,.852L8.321,9.641A.611.611,0,0,0,9.18,9.641Z"
      transform="translate(9.083 0.001) rotate(45)"
    />
  </svg>
);

export default FontAwesomeSolidUnlinkComponent;
