import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import moment from "moment";
import { setOrganizationTaxes, addMultipleOrganizationTaxes, selectedDefaultOrgTaxes } from "../../redux/action/OrganisationAction";
import { selectTaxe } from "../../redux/action/TaxesAction";
import {_getOrganizationTaxes} from "../../services/Organization";
import { SESSION_STORAGE_KEYS, PAGINATION } from '../../constants/constants';
import Logger from '../../helpers/Logger';
import IconPlus from '../../components/Icons/IconPlus';
import reverseOrderIcon from '../../assets/svg/icon-reverseOrder.svg';
import Loader from '../../components/Loader';
import Search from '../../components/Search';
import LabelStatus from "../../components/LabelStatus";
import { numberFormat } from '../../helpers/Number';
import FilePdfDownloadPdf from '../../components/Icons/FilePdfDownloadPdf';
import {_createTaxPeriod} from "../../services/Taxes";
import { errorNotifcation } from '../../helpers/errorHandle';
import { toastr } from 'react-redux-toastr';

import "./taxes.css";

const GenericTable = lazy(() => import("../../components/Table/GenericTable") );

class TaxeList extends Component {
    constructor(props){
        super(props);
        this.state = {
            loadTaxe: false,
            isEditView: false,
            loading: false,
            filter: '',
            pageSize: PAGINATION.DEFAULT_PAGE_SIZE,
            startPage: PAGINATION.DEFAULT_PAGE,
            currentPage: PAGINATION.DEFAULT_PAGE,endIndex: null,
            endPage: null,
            pages: [],
            startIndex: null,
            totalItems: null,
            totalPages: null,
            count: null,
            hasMore: true,
            scrollLoading: false,
            isDetailLoading: false,
            isAttachmentVisible: false,
            loadPreview: false,
        }
    }

    componentDidMount(){
        this.getData();
    }

    initFormOne = (payload) => {
        return {
            dgecri: null,
            cdicsicime: null,
            fiscalExercice: payload?.year,
            period: payload?.month,
            socialReason: payload?.organization?.name,
            sigle: payload?.organization?.acronym,
            town: payload?.organization?.town,
            community: payload?.organization?.community,
            quarter: payload?.organization?.district,
            area: payload?.organization?.placeSaid,
            pobox: payload?.organization?.codePostal,
            celphone: payload?.organization?.phone1,
            mobilephone: payload?.organization?.phone2,
            email: payload?.organization?.email,
            fax: payload?.organization?.fax,
            isReal: false,
            isSimplify: false,
            // Table 0: TSR
            tsrbase: +payload?.specialIncomeTax?.retainedTRC?.base  || null,
            tsrprincipal: +payload?.specialIncomeTax?.retainedTRC?.principal  || null,
            tsrpenality: +payload?.specialIncomeTax?.retainedTRC?.penalities  || null,
            tsrtotal: +payload?.specialIncomeTax?.retainedTRC?.total  || null,
            tsrrate: +payload?.specialIncomeTax?.retainedTRC?.rate  || null,
            is15: false,
            is10: false,
            is7p5: false,
            // --> L0
            tsrbasetotal: +payload?.specialIncomeTax?.total?.base  || null,
            tsrprincipaltotal: +payload?.specialIncomeTax?.total?.principal  || null,
            tsrpenalitytotal: +payload?.specialIncomeTax?.total?.penalities  || null,
            tsrtotals: +payload?.specialIncomeTax?.total?.total  || null,
            // Table 1: DA
            // --> L1
            cagbase: +payload?.exicesDuties?.generalRateTaxableCA?.base  || null,
            cagrate: +payload?.exicesDuties?.generalRateTaxableCA?.rate  || null,
            cagprincipal: +payload?.exicesDuties?.generalRateTaxableCA?.principal  || null,
            cagpenality: +payload?.exicesDuties?.generalRateTaxableCA?.penalities  || null,
            cagtotal: +payload?.exicesDuties?.generalRateTaxableCA?.total  || null,
            // --> L2
            carbase: +payload?.exicesDuties?.reducedRateTaxabeCA?.base  || null,
            carrate: +payload?.exicesDuties?.reducedRateTaxabeCA?.rate  || null,
            carprincipal: +payload?.exicesDuties?.reducedRateTaxabeCA?.principal  || null,
            carpenality: +payload?.exicesDuties?.reducedRateTaxabeCA?.penalities  || null,
            cartotal: +payload?.exicesDuties?.reducedRateTaxabeCA?.total  || null,
            // --> L3
            daabase: +payload?.exicesDuties?.adValoremExiceDuty?.base  || null,
            daarate: +payload?.exicesDuties?.adValoremExiceDuty?.rate  || null,
            daaprincipal: +payload?.exicesDuties?.adValoremExiceDuty?.principal  || null,
            daapenality: +payload?.exicesDuties?.adValoremExiceDuty?.penalities  || null,
            daatotal: +payload?.exicesDuties?.adValoremExiceDuty?.total  || null,
            // --> L4
            dasbase: +payload?.exicesDuties?.specificExiceDuty?.base  || null,
            dasrate: +payload?.exicesDuties?.specificExiceDuty?.rate  || null,
            dasprincipal: +payload?.exicesDuties?.specificExiceDuty?.principal  || null,
            daspenality: +payload?.exicesDuties?.specificExiceDuty?.penalities  || null,
            dastotal: +payload?.exicesDuties?.specificExiceDuty?.total  || null,
            // --> L5
            darbase: +payload?.exicesDuties?.exiceDutyToReserve?.base  || null,
            darrate: +payload?.exicesDuties?.exiceDutyToReserve?.rate  || null,
            darprincipal: +payload?.exicesDuties?.exiceDutyToReserve?.principal  || null,
            darpenality: +payload?.exicesDuties?.exiceDutyToReserve?.penalities  || null,
            dartotal: +payload?.exicesDuties?.exiceDutyToReserve?.total  || null,
            // --> L6
            dapiprincipal: +payload?.exicesDuties?.exiceDutypaidOnImportation?.principal  || null,
            dapitotal: +payload?.exicesDuties?.exiceDutypaidOnImportation?.total  || null,
            // --> L7
            cdapbase: +payload?.exicesDuties?.exciseDutySupplementPayable?.base  || null,
            cdaprate: +payload?.exicesDuties?.exciseDutySupplementPayable?.rate  || null,
            cdapprincipale: +payload?.exicesDuties?.exciseDutySupplementPayable?.principal  || null,
            cdappenality: +payload?.exicesDuties?.exciseDutySupplementPayable?.penalities  || null,
            cdaptotal: +payload?.exicesDuties?.exciseDutySupplementPayable?.total  || null,
            // --> L8
            l5orl7base: +payload?.exicesDuties?.l5Orl7?.base  || null,
            l5orl7rate: +payload?.exicesDuties?.l5Orl7?.rate  || null,
            l5orl7principal: +payload?.exicesDuties?.l5Orl7?.principal  || null,
            l5orl7penality: +payload?.exicesDuties?.l5Orl7?.penalities  || null,
            l5orl7total: +payload?.exicesDuties?.l5Orl7?.total  || null,
            isL5: false,
            isL7: false,
            // --> L9
            l5l7base: +payload?.exicesDuties?.l5PlusL7?.base  || null,
            l5l7rate: +payload?.exicesDuties?.l5PlusL7?.rate  || null,
            l5l7principal: +payload?.exicesDuties?.l5PlusL7?.principal  || null,
            l5l7penality: +payload?.exicesDuties?.l5PlusL7?.penalities  || null,
            l5l7total: +payload?.exicesDuties?.l5PlusL7?.total  || null,
            // Table 2: DA
            // --> L10
            ottnca: +payload?.acheivedCA?.normalRateTaxableTransactions?.ca  || null,
            ottnrate: +payload?.acheivedCA?.normalRateTaxableTransactions?.rate  || null,
            ottntotal: +payload?.acheivedCA?.normalRateTaxableTransactions?.amountOfTax  || null,
            // --> L11
            mdaca: +payload?.acheivedCA?.amountOfExiceDutyL8OrL9?.ca  || null,
            mdarate: +payload?.acheivedCA?.amountOfExiceDutyL8OrL9?.rate  || null,
            mdatotal: +payload?.acheivedCA?.amountOfExiceDutyL8OrL9?.amountOfTax  || null,
            // --> L12
            aotca: +payload?.acheivedCA?.otherTaxablesTransactions?.ca  || null,
            aotrate: +payload?.acheivedCA?.otherTaxablesTransactions?.rate  || null,
            aottotal: +payload?.acheivedCA?.otherTaxablesTransactions?.amountOfTax  || null,
            // --> L13
            tvaetca: +payload?.acheivedCA?.taxedAtZeroRateExports?.ca  || null,
            tvaetrate: +payload?.acheivedCA?.taxedAtZeroRateExports?.rate  || null,
            tvaettotal: +payload?.acheivedCA?.taxedAtZeroRateExports?.amountOfTax  || null,
            // --> L14
            caeca: +payload?.acheivedCA?.taxExemptCA?.ca  || null,
            // --> L15
            caghtca: +payload?.acheivedCA?.totalCAExcludingTax?.ca  || null,
            caghttotal: +payload?.acheivedCA?.totalCAExcludingTax?.amountOfTax  || null,
        }
    }

    initFormTwo = (payload) => {
        return {
            // Table 3: TVA DÉDUCTIBLE
            l16: +payload?.deductibleVat?.forecastProrata || null,
            l17: +payload?.deductibleVat?.reportOfPreviousCredit || null,
            l18: +payload?.deductibleVat?.deductibleVatOnLocalPurchases || null,
            l19: +payload?.deductibleVat?.deductibleVatOnLocalServices || null,
            l20: +payload?.deductibleVat?.deductibleVatOnPurchasesAbroad || null,
            l21: +payload?.deductibleVat?.deductibleVatOnServicesAndOthers || null,
            l22: +payload?.deductibleVat?.totalDeductibleVat || null,
            l23: +payload?.deductibleVat?.deductibleVatOnprovisionalProrata || null,
            // Table 4: RÉGULARISATIONS EXCEPTIONNELLES
            l24: +payload?.exceptionalAdjustments?.adjustmentOfDeductibleVat || null,
            l25: +payload?.exceptionalAdjustments?.regularisationOfVatPaidByState || null,
            l26: +payload?.exceptionalAdjustments?.adjustmentOnDisposalOfItems || null,
            l27: +payload?.exceptionalAdjustments?.adjustmentOfVatToBeRepaid || null,
            // Table 5: TVA ÀPAYER OU CRÉDIT DE TVA
            l28: +payload?.vatPayableOrVatCredit?.vatCollected || null,
            l29: +payload?.vatPayableOrVatCredit?.deductibleVatL22OrL23 || null,
            isl22: false,
            isl23: false,
            l30: +payload?.vatPayableOrVatCredit?.vatRegularisationToDeducted || null,
            l31: +payload?.vatPayableOrVatCredit?.vatRegularisationToRepaid || null,
            l32: +payload?.vatPayableOrVatCredit?.vatToBePaid || null,
            l33: +payload?.vatPayableOrVatCredit?.vatCredit || null,
            l34: +payload?.vatPayableOrVatCredit?.reimbursementRequested || null,
            l35: +payload?.vatPayableOrVatCredit?.adjustmentOfDeductibleVat || null,
            // Table 6: TOTAL DE TVA À PAYER
            // line ->
            l36principal: +payload?.totalVatToBePaid?.vatToBePaid?.principal || null,
            l36cac: +payload?.totalVatToBePaid?.vatToBePaid?.cac || null,
            l36Penalties: +payload?.totalVatToBePaid?.vatToBePaid?.penalities || null,
            l36total: +payload?.totalVatToBePaid?.vatToBePaid?.total || null,
            // line ->
            l37principal: +payload?.totalVatToBePaid?.vatWithheldAtSource?.principal || null,
            l37cac: +payload?.totalVatToBePaid?.vatWithheldAtSource?.cac || null,
            l37Penalties: +payload?.totalVatToBePaid?.vatWithheldAtSource?.penalities || null,
            l37total: +payload?.totalVatToBePaid?.vatWithheldAtSource?.total || null,
            // line ->
            l38principal: +payload?.totalVatToBePaid?.vatWithheldOnRemunerationPaidAbroad?.principal || null,
            l38cac: +payload?.totalVatToBePaid?.vatWithheldOnRemunerationPaidAbroad?.cac || null,
            l38Penalties: +payload?.totalVatToBePaid?.vatWithheldOnRemunerationPaidAbroad?.penalities || null,
            l38total: +payload?.totalVatToBePaid?.vatWithheldOnRemunerationPaidAbroad?.total || null,
            // line ->
            l39principal: +payload?.totalVatToBePaid?.amountToBePaid?.principal || null,
            l39cac: +payload?.totalVatToBePaid?.amountToBePaid?.cac || null,
            l39Penalties: +payload?.totalVatToBePaid?.amountToBePaid?.penalities || null,
            l39total: +payload?.totalVatToBePaid?.amountToBePaid?.total || null,
            // line ->
            // table 7: ACOMPTES ET PRÉCOMPTES À REVERSER
            l40base: +payload?.prepaymentsToBeReserved?.advanceOnCAWithheldAtSource?.base || null,
            l40principal: +payload?.prepaymentsToBeReserved?.advanceOnCAWithheldAtSource?.principal || null,
            l40cac: +payload?.prepaymentsToBeReserved?.advanceOnCAWithheldAtSource?.cac || null,
            l40penalties: +payload?.prepaymentsToBeReserved?.advanceOnCAWithheldAtSource?.penalities || null,
            l40total: +payload?.prepaymentsToBeReserved?.advanceOnCAWithheldAtSource?.total || null,
            // line ->
            l41base: +payload?.prepaymentsToBeReserved?.prepaymentsOnWithheldPurchases?.base || null,
            l41principal: +payload?.prepaymentsToBeReserved?.prepaymentsOnWithheldPurchases?.principal || null,
            l41cac: +payload?.prepaymentsToBeReserved?.prepaymentsOnWithheldPurchases?.cac || null,
            l41penalties: +payload?.prepaymentsToBeReserved?.prepaymentsOnWithheldPurchases?.penalities || null,
            l41total: +payload?.prepaymentsToBeReserved?.prepaymentsOnWithheldPurchases?.total || null,
            l41rate: +payload?.prepaymentsToBeReserved?.prepaymentsOnWithheldPurchases?.rate || null,
            // line ->
            l42base: +payload?.prepaymentsToBeReserved?.prepaymentsOf15OnRents?.base || null,
            l42principal: +payload?.prepaymentsToBeReserved?.prepaymentsOf15OnRents?.principal || null,
            l42cac: +payload?.prepaymentsToBeReserved?.prepaymentsOf15OnRents?.cac || null,
            l42penalties: +payload?.prepaymentsToBeReserved?.prepaymentsOf15OnRents?.penalities || null,
            l42total: +payload?.prepaymentsToBeReserved?.prepaymentsOf15OnRents?.total || null,
            // line ->
            l43base: +payload?.prepaymentsToBeReserved?.withholdingTaxOnRemuneration?.base || null,
            l43principal: +payload?.prepaymentsToBeReserved?.withholdingTaxOnRemuneration?.principal || null,
            l43cac: +payload?.prepaymentsToBeReserved?.withholdingTaxOnRemuneration?.cac || null,
            l43penalties: +payload?.prepaymentsToBeReserved?.withholdingTaxOnRemuneration?.penalities || null,
            l43total: +payload?.prepaymentsToBeReserved?.withholdingTaxOnRemuneration?.total || null,
            // line ->
            l44base: +payload?.prepaymentsToBeReserved?.total?.base || null,
            l44principal: +payload?.prepaymentsToBeReserved?.total?.principal || null,
            l44cac: +payload?.prepaymentsToBeReserved?.total?.cac || null,
            l44penalties: +payload?.prepaymentsToBeReserved?.total?.penalities || null,
            l44total: +payload?.prepaymentsToBeReserved?.total?.total || null,
        }
    }

    initFormThree = (payload) => {
        return {
            // table 8
            // l45
            l45Base: +payload?.prepaymentsToBeDeducted?.advanceOnCAWithheldAtSource?.base || null, 
            l45Principal: +payload?.prepaymentsToBeDeducted?.advanceOnCAWithheldAtSource?.principal || null, 
            l45Cac: +payload?.prepaymentsToBeDeducted?.advanceOnCAWithheldAtSource?.cac || null, 
            l45Penalties: +payload?.prepaymentsToBeDeducted?.advanceOnCAWithheldAtSource?.penalities || null, 
            l45Total: +payload?.prepaymentsToBeDeducted?.advanceOnCAWithheldAtSource?.total || null,
            
            // 46
            l46Base: +payload?.prepaymentsToBeDeducted?.prepaymentsOnWithheldPurchases?.base || null, 
            l46Principal: +payload?.prepaymentsToBeDeducted?.prepaymentsOnWithheldPurchases?.principal || null, 
            l46Penalties: +payload?.prepaymentsToBeDeducted?.prepaymentsOnWithheldPurchases?.penalities || null, 
            l46Total: +payload?.prepaymentsToBeDeducted?.prepaymentsOnWithheldPurchases?.total || null,

            l46Rate: +payload?.prepaymentsToBeDeducted?.prepaymentsOnWithheldPurchases?.rate || 0.5,
            l46CheckBox0p5: false, l46CheckBox1: false, l46CheckBox3: false, l46CheckBox5: false,
            
            // l47
            l47Base: +payload?.prepaymentsToBeDeducted?.prepaymentsOf15OnRents?.base || null, 
            l47Principal: +payload?.prepaymentsToBeDeducted?.prepaymentsOf15OnRents?.principal || null, 
            l47Penalties: +payload?.prepaymentsToBeDeducted?.prepaymentsOf15OnRents?.penalities || null, 
            l47Total: +payload?.prepaymentsToBeDeducted?.prepaymentsOf15OnRents?.total || null,

            // 48
            l48Base: +payload?.prepaymentsToBeDeducted?.withholdingTaxOnRemuneration?.base || null, 
            l48Principal: +payload?.prepaymentsToBeDeducted?.withholdingTaxOnRemuneration?.principal || null, 
            l48Cac: +payload?.prepaymentsToBeDeducted?.withholdingTaxOnRemuneration?.cac || null, 
            l48Penalties: +payload?.prepaymentsToBeDeducted?.withholdingTaxOnRemuneration?.penalities || null, 
            l48Total: +payload?.prepaymentsToBeDeducted?.withholdingTaxOnRemuneration?.total || null,

            // l49
            l49Base: +payload?.prepaymentsToBeDeducted?.total?.base || null, 
            l49Principal: +payload?.prepaymentsToBeDeducted?.total?.principal || null, 
            l49Cac: +payload?.prepaymentsToBeDeducted?.total?.cac || null, 
            l49Penalties: +payload?.prepaymentsToBeDeducted?.total?.penalities || null, 
            l49Total: +payload?.prepaymentsToBeDeducted?.total?.total || null,

            // table 9
            // l50
            l50Base: +payload?.liquidationAdvancePayments?.advancePaymentOnDeclareCA?.base || null, 
            l50Principal: +payload?.liquidationAdvancePayments?.advancePaymentOnDeclareCA?.principal || null, 
            l50Cac: +payload?.liquidationAdvancePayments?.advancePaymentOnDeclareCA?.cac || null, 
            l50Penalties: +payload?.liquidationAdvancePayments?.advancePaymentOnDeclareCA?.penalities || null, 
            l50Total: +payload?.liquidationAdvancePayments?.advancePaymentOnDeclareCA?.total || null,

            l50Rate: +payload?.liquidationAdvancePayments?.advancePaymentOnDeclareCA?.rate || 0.5,
            l50CheckBox0p5: false, l50CheckBox2: false, l50CheckBox3: false, l50CheckBox5: false,

            // l51
            l51Base: +payload?.liquidationAdvancePayments?.downPaymentOf15OnRents?.base || null, 
            l51Principal: +payload?.liquidationAdvancePayments?.downPaymentOf15OnRents?.principal || null, 
            l51Cac: +payload?.liquidationAdvancePayments?.downPaymentOf15OnRents?.cac || null, 
            l51Penalties: +payload?.liquidationAdvancePayments?.downPaymentOf15OnRents?.penalities || null, 
            l51Total: +payload?.liquidationAdvancePayments?.downPaymentOf15OnRents?.total || null,

            // l52
            l52Base: +payload?.liquidationAdvancePayments?.deductionsToBeMade?.base || null, 
            l52Principal: +payload?.liquidationAdvancePayments?.deductionsToBeMade?.principal || null, 
            l52Cac: +payload?.liquidationAdvancePayments?.deductionsToBeMade?.cac || null, 
            l52Penalties: +payload?.liquidationAdvancePayments?.deductionsToBeMade?.penalities || null, 
            l52Total: +payload?.liquidationAdvancePayments?.deductionsToBeMade?.total || null,

            // l53
            l53Base: +payload?.liquidationAdvancePayments?.previousCredit?.base || null, 
            l53Principal: +payload?.liquidationAdvancePayments?.previousCredit?.principal || null, 
            l53Cac: +payload?.liquidationAdvancePayments?.previousCredit?.cac || null, 
            l53Penalties: +payload?.liquidationAdvancePayments?.previousCredit?.penalities || null, 
            l53Total: +payload?.liquidationAdvancePayments?.previousCredit?.total || null,

            // l54
            l54Base: +payload?.liquidationAdvancePayments?.depositToBePaid?.base || null, 
            l54Principal: +payload?.liquidationAdvancePayments?.depositToBePaid?.principal || null, 
            l54Cac: +payload?.liquidationAdvancePayments?.depositToBePaid?.cac || null, 
            l54Penalties: +payload?.liquidationAdvancePayments?.depositToBePaid?.penalities || null, 
            l54Total: +payload?.liquidationAdvancePayments?.depositToBePaid?.total || null,

            // l55
            l55Base: +payload?.liquidationAdvancePayments?.taxCreditToBeDeferred?.base || null, 
            l55Principal: +payload?.liquidationAdvancePayments?.taxCreditToBeDeferred?.principal || null, 
            l55Cac: +payload?.liquidationAdvancePayments?.taxCreditToBeDeferred?.cac || null, 
            l55Penalties: +payload?.liquidationAdvancePayments?.taxCreditToBeDeferred?.penalities || null, 
            l55Total: +payload?.liquidationAdvancePayments?.taxCreditToBeDeferred?.total || null,

            // table 10
            // l56
            l56Base: +payload?.withholdingsOnIncome?.incomeFromShares?.base || null, 
            l56PaymentDate: payload?.withholdingsOnIncome?.incomeFromShares?.paymentDate || null, 
            l56Principal: +payload?.withholdingsOnIncome?.incomeFromShares?.principal || null, 
            l56Cac: +payload?.withholdingsOnIncome?.incomeFromShares?.cac || null, 
            l56Penalties: +payload?.withholdingsOnIncome?.incomeFromShares?.penalities || null, 
            l56Total: +payload?.withholdingsOnIncome?.incomeFromShares?.total || null,

            // l57
            l57Base: +payload?.withholdingsOnIncome?.dividendsPaidOutside?.base || null, 
            l57PaymentDate: payload?.withholdingsOnIncome?.dividendsPaidOutside?.paymentDate || null, 
            l57Principal: +payload?.withholdingsOnIncome?.dividendsPaidOutside?.principal || null, 
            l57Cac: +payload?.withholdingsOnIncome?.dividendsPaidOutside?.cac || null, 
            l57Penalties: +payload?.withholdingsOnIncome?.dividendsPaidOutside?.penalities || null, 
            l57Total: +payload?.withholdingsOnIncome?.dividendsPaidOutside?.total || null,

            // l58
            l58Base: +payload?.withholdingsOnIncome?.remunerationOfDirectors?.base || null, 
            l58PaymentDate: payload?.withholdingsOnIncome?.remunerationOfDirectors?.paymentDate || null, 
            l58Principal: +payload?.withholdingsOnIncome?.remunerationOfDirectors?.principal || null, 
            l58Cac: +payload?.withholdingsOnIncome?.remunerationOfDirectors?.cac || null, 
            l58Penalties: +payload?.withholdingsOnIncome?.remunerationOfDirectors?.penalities || null, 
            l58Total: +payload?.withholdingsOnIncome?.remunerationOfDirectors?.total || null,

            // l59
            l59Base: +payload?.withholdingsOnIncome?.bondIncome?.base || null, 
            l59PaymentDate: payload?.withholdingsOnIncome?.bondIncome?.paymentDate || null, 
            l59Principal: +payload?.withholdingsOnIncome?.bondIncome?.principal || null, 
            l59Cac: +payload?.withholdingsOnIncome?.bondIncome?.cac || null, 
            l59Penalties: +payload?.withholdingsOnIncome?.bondIncome?.penalities || null, 
            l59Total: +payload?.withholdingsOnIncome?.bondIncome?.total || null,

            // l60
            l60Base: +payload?.withholdingsOnIncome?.incomeFromReceivables?.base || null, 
            l60PaymentDate: payload?.withholdingsOnIncome?.incomeFromReceivables?.paymentDate || null, 
            l60Principal: +payload?.withholdingsOnIncome?.incomeFromReceivables?.principal || null, 
            l60Cac: +payload?.withholdingsOnIncome?.incomeFromReceivables?.cac || null, 
            l60Penalties: +payload?.withholdingsOnIncome?.incomeFromReceivables?.penalities || null, 
            l60Total: +payload?.withholdingsOnIncome?.incomeFromReceivables?.total || null,

            // l61
            l61Base: +payload?.withholdingsOnIncome?.gainsOnDisposalOfShares?.base || null, 
            l61PaymentDate: payload?.withholdingsOnIncome?.gainsOnDisposalOfShares?.paymentDate || null, 
            l61Principal: +payload?.withholdingsOnIncome?.gainsOnDisposalOfShares?.principal || null, 
            l61Cac: +payload?.withholdingsOnIncome?.gainsOnDisposalOfShares?.cac || null, 
            l61Penalties: +payload?.withholdingsOnIncome?.gainsOnDisposalOfShares?.penalities || null, 
            l61Total: +payload?.withholdingsOnIncome?.gainsOnDisposalOfShares?.total || null,

            // l62
            l62Base: +payload?.withholdingsOnIncome?.total?.base || null, 
            l62PaymentDate: payload?.withholdingsOnIncome?.total?.paymentDate || null, 
            l62Principal: +payload?.withholdingsOnIncome?.total?.principal || null, 
            l62Cac: +payload?.withholdingsOnIncome?.total?.cac || null, 
            l62Penalties: +payload?.withholdingsOnIncome?.total?.penalities || null, 
            l62Total: +payload?.withholdingsOnIncome?.total?.total || null,

            // table 11
            // l63
            l63Base: +payload?.taxesOnNonCommercialIncome?.remunerationForBoardSessions?.base || null, 
            l63Rate: +payload?.taxesOnNonCommercialIncome?.remunerationForBoardSessions?.rate || 15, 
            l63Principal: +payload?.taxesOnNonCommercialIncome?.remunerationForBoardSessions?.principal || null, 
            l63Cac: +payload?.taxesOnNonCommercialIncome?.remunerationForBoardSessions?.cac || null, 
            l63Penalties: +payload?.taxesOnNonCommercialIncome?.remunerationForBoardSessions?.penalities || null, 
            l63Total: +payload?.taxesOnNonCommercialIncome?.remunerationForBoardSessions?.total || null,

            // l64
            l64Base: +payload?.taxesOnNonCommercialIncome?.bonusesGratuitiesAndOthers?.base || null, 
            l64Rate: +payload?.taxesOnNonCommercialIncome?.bonusesGratuitiesAndOthers?.rate || 15, 
            l64Principal: +payload?.taxesOnNonCommercialIncome?.bonusesGratuitiesAndOthers?.principal || null, 
            l64Cac: +payload?.taxesOnNonCommercialIncome?.bonusesGratuitiesAndOthers?.cac || null, 
            l64Penalties: +payload?.taxesOnNonCommercialIncome?.bonusesGratuitiesAndOthers?.penalities || null, 
            l64Total: +payload?.taxesOnNonCommercialIncome?.bonusesGratuitiesAndOthers?.total || null,

            // l65
            l65Base: +payload?.taxesOnNonCommercialIncome?.remunerationOfArtists?.base || null, 
            l65Rate: +payload?.taxesOnNonCommercialIncome?.remunerationOfArtists?.rate || 15, 
            l65Principal: +payload?.taxesOnNonCommercialIncome?.remunerationOfArtists?.principal || null, 
            l65Cac: +payload?.taxesOnNonCommercialIncome?.remunerationOfArtists?.cac || null, 
            l65Penalties: +payload?.taxesOnNonCommercialIncome?.remunerationOfArtists?.penalities || null, 
            l65Total: +payload?.taxesOnNonCommercialIncome?.remunerationOfArtists?.total || null,

            // l66
            l66Base: +payload?.taxesOnNonCommercialIncome?.total?.base || null, 
            l66Rate: payload?.taxesOnNonCommercialIncome?.total?.rate || null, 
            l66Principal: +payload?.taxesOnNonCommercialIncome?.total?.principal || null,
            l66Cac: +payload?.taxesOnNonCommercialIncome?.total?.cac || null, 
            l66Penalties: +payload?.taxesOnNonCommercialIncome?.total?.penalities || null, 
            l66Total: +payload?.taxesOnNonCommercialIncome?.total?.total || null,

            // table 12
            // 67
            l67Base: +payload?.withholdingTaxesOnSalaries?.irpp?.base || null, 
            l67Principal: +payload?.withholdingTaxesOnSalaries?.irpp?.principal || null,
            l67Cac: +payload?.withholdingTaxesOnSalaries?.irpp?.cac || null, 
            l67Penalties: +payload?.withholdingTaxesOnSalaries?.irpp?.penalities || null, 
            l67Total: +payload?.withholdingTaxesOnSalaries?.irpp?.total || null,

            // l68
            l68Base: +payload?.withholdingTaxesOnSalaries?.salarialCfc?.base || null, 
            l68Principal: +payload?.withholdingTaxesOnSalaries?.salarialCfc?.principal || null,
            l68Penalties: +payload?.withholdingTaxesOnSalaries?.salarialCfc?.penalities || null, 
            l68Total: +payload?.withholdingTaxesOnSalaries?.salarialCfc?.total || null,

            // l69
            l69Base: +payload?.withholdingTaxesOnSalaries?.patronalCfc?.base || null, 
            l69Principal: +payload?.withholdingTaxesOnSalaries?.patronalCfc?.principal || null,
            l69Penalties: +payload?.withholdingTaxesOnSalaries?.patronalCfc?.penalities || null, 
            l69Total: +payload?.withholdingTaxesOnSalaries?.patronalCfc?.total || null,

            // l70
            l70Base: +payload?.withholdingTaxesOnSalaries?.fne?.base || null, 
            l70Principal: +payload?.withholdingTaxesOnSalaries?.fne?.principal || null,
            l70Penalties: +payload?.withholdingTaxesOnSalaries?.fne?.penalities || null, 
            l70Total: +payload?.withholdingTaxesOnSalaries?.fne?.total || null,

            // l71
            l71Base: +payload?.withholdingTaxesOnSalaries?.rav?.base || null, 
            l71Principal: +payload?.withholdingTaxesOnSalaries?.rav?.principal || null,
            l71Penalties: +payload?.withholdingTaxesOnSalaries?.rav?.penalities || null, 
            l71Total: +payload?.withholdingTaxesOnSalaries?.rav?.total || null,

            // l72
            l72Base: +payload?.withholdingTaxesOnSalaries?.localDvlptTax?.base || null, 
            l72Principal: +payload?.withholdingTaxesOnSalaries?.localDvlptTax?.principal || null,
            l72Penalties: +payload?.withholdingTaxesOnSalaries?.localDvlptTax?.penalities || null, 
            l72Total: +payload?.withholdingTaxesOnSalaries?.localDvlptTax?.total || null,

            // l73
            l73Base: +payload?.withholdingTaxesOnSalaries?.total?.base || null, 
            l73Principal: +payload?.withholdingTaxesOnSalaries?.total?.principal || null,
            l73Cac: +payload?.withholdingTaxesOnSalaries?.irpp?.cac || null, 
            l73Penalties: +payload?.withholdingTaxesOnSalaries?.total?.penalities || null, 
            l73Total: +payload?.withholdingTaxesOnSalaries?.total?.total || null,
        }
    }

    initFormFour = (payload) => {
        // l81 = l0
        let varl81Principal = +payload?.specialIncomeTax?.retainedTRC?.principal || 0;
        let varl81Cac = +payload?.specialIncomeTax?.retainedTRC?.cac || 0;
        let varl81Penalties = +payload?.specialIncomeTax?.retainedTRC?.penalities || 0;
        let varl81Total = +varl81Principal + +varl81Penalties;

        // l82 = l8 or l9
        let varl82Principal = 0;
        let varl82Cac = payload?.recapitulativeTotal?.exicesDuties?.cac || 0;
        let varl82Penalties = 0;
        let varl82Total = 0;


        if (+payload?.recapitulativeTotal?.vat?.rate === 9) {
            varl82Principal = +payload?.exicesDuties?.l5PlusL7?.principal;
            varl82Penalties = +payload?.exicesDuties?.l5PlusL7?.penalities;
            varl82Total = +varl82Principal + +varl82Cac + +varl82Penalties;
        } else {
            varl82Principal = +payload?.exicesDuties?.l5Orl7?.principal;
            varl82Penalties = +payload?.exicesDuties?.l5Orl7?.penalities;
            varl82Total = +varl82Principal + +varl82Cac + +varl82Penalties;
        }

        // l83 = l39
        let varl83Principal = +payload?.totalVatToBePaid?.amountToBePaid?.principal || 0;
        let varl83Cac = +payload?.totalVatToBePaid?.amountToBePaid?.cac || 0;
        let varl83Penalties = +payload?.totalVatToBePaid?.amountToBePaid?.penalities || 0;
        let varl83Total = varl83Principal + varl83Cac + varl83Penalties;

        // l84 = l44
        let varl84Principal = +payload?.prepaymentsToBeReserved?.total?.principal || 0 ;
        let varl84Cac = +payload?.prepaymentsToBeReserved?.total?.cac || 0;
        let varl84Penalties = +payload?.prepaymentsToBeReserved?.total?.penalities || 0;
        let varl84Total = +varl84Principal + +varl84Cac + +varl84Penalties;

        // l85 = l54
        let varl85Principal = +payload?.liquidationAdvancePayments?.depositToBePaid?.principal || 0; 
        let varl85Cac = +payload?.liquidationAdvancePayments?.depositToBePaid?.cac || 0;
        let varl85Penalties = +payload?.liquidationAdvancePayments?.depositToBePaid?.penalities || 0;
        let varl85Total = +varl85Principal + +varl85Cac + +varl85Penalties;

        // l86 = l62
        let varl86Principal = +payload?.withholdingsOnIncome?.total?.principal || 0; 
        let varl86Cac = +payload?.withholdingsOnIncome?.total?.cac || 0;
        let varl86Penalties = +payload?.withholdingsOnIncome?.total?.penalities || 0;
        let varl86Total = +varl86Principal + +varl86Cac + +varl86Penalties;

        // l87 = l66
        let varl87Principal = +payload?.taxesOnNonCommercialIncome?.total?.principal || 0; 
        let varl87Cac = +payload?.taxesOnNonCommercialIncome?.total?.cac || 0;
        let varl87Penalties = +payload?.taxesOnNonCommercialIncome?.total?.penalities || 0;
        let varl87Total = +varl87Principal + +varl87Cac + +varl87Penalties;

        // l88 = l73
        let varl88Principal = +payload?.withholdingTaxesOnSalaries?.total?.principal || 0; 
        let varl88Cac = +payload?.withholdingTaxesOnSalaries?.total?.cac || 0;
        let varl88Penalties = +payload?.withholdingTaxesOnSalaries?.total?.penalities || 0;
        let varl88Total = +varl88Principal + +varl88Cac + +varl88Penalties;

        // l89 = l77 // TODO
        let varl89Principal = +payload?.capitalGainsTax?.total?.transferPrice || 0; 
        let varl89Cac = +payload?.capitalGainsTax?.total?.deductibleExpense || 0;
        let varl89Penalties = +payload?.capitalGainsTax?.total?.capitalGainsOrLosses || 0;
        let varl89Total = +varl89Principal + +varl89Cac + +varl89Penalties;

        // l90 = l80
        let varl90Principal = +payload?.airportStampDuty?.internationalFlights?.principal || 0; 
        let varl90Cac =  +payload?.airportStampDuty?.internationalFlights?.cac || 0;
        let varl90Penalties = +payload?.airportStampDuty?.internationalFlights?.penalities || 0;
        let varl90Total = +varl90Principal + +varl90Cac + +varl90Penalties;

        let varl91Principal = varl81Principal + varl82Principal + varl83Principal + varl84Principal + varl85Principal + varl86Principal + varl87Principal + varl88Principal + varl89Principal + varl90Principal;
        let varl91Cac =  varl81Cac + varl82Cac + varl83Cac + varl84Cac + varl85Cac + varl86Cac + varl87Cac + varl88Cac + varl89Cac + varl90Cac;
        let varl91Penalties =  varl81Penalties + varl82Penalties + varl83Penalties + varl84Penalties + varl85Penalties + varl86Penalties + varl87Penalties + varl88Penalties + varl89Penalties + varl90Penalties;
        let varl91Total = varl81Total + varl82Total + varl83Total + varl84Total + varl85Total + varl86Total + varl87Total + varl88Total + varl89Total + varl90Total;

        let varPaymentType = (payload.isCheque ? "isCheque": (payload.isTransfer ? "isTransfer" : "isCash"));
        return {
            // TABLE 13
            // l74
            l74TransferPrice: +payload?.capitalGainsTax?.disposalOfFixedAssets?.transferPrice || null,
            l74NetBookValue: +payload?.capitalGainsTax?.disposalOfFixedAssets?.netBookValue || null,
            l74CapitalGainsOrLosses: +payload?.capitalGainsTax?.disposalOfFixedAssets?.capitalGainsOrLosses || null,
            l74Rate: +payload?.capitalGainsTax?.disposalOfFixedAssets?.rate || null,
            l74PaymentTaxes: +payload?.capitalGainsTax?.disposalOfFixedAssets?.paymentTaxes || null,

            // l75
            l75TransferPrice: +payload?.capitalGainsTax?.disposalOfMarketableSecurities?.transferPrice || null,
            l75NetBookValue: +payload?.capitalGainsTax?.disposalOfMarketableSecurities?.netBookValue || null,
            l75CapitalGainsOrLosses: +payload?.capitalGainsTax?.disposalOfMarketableSecurities?.capitalGainsOrLosses || null,
            l75Rate: +payload?.capitalGainsTax?.disposalOfMarketableSecurities?.rate || null,
            l75PaymentTaxes: +payload?.capitalGainsTax?.disposalOfMarketableSecurities?.paymentTaxes || null,

            // l76
            l76TransferPrice: +payload?.capitalGainsTax?.propertyCapitalGains?.transferPrice || null,
            l76DeductibleExpense: +payload?.capitalGainsTax?.propertyCapitalGains?.deductibleExpense || null,
            l76CapitalGainsOrLosses: +payload?.capitalGainsTax?.propertyCapitalGains?.capitalGainsOrLosses || null,
            l76Rate: +payload?.capitalGainsTax?.propertyCapitalGains?.rate || null,
            l76PaymentTaxes: +payload?.capitalGainsTax?.propertyCapitalGains?.paymentTaxes || null,

            // l77
            l77TransferPrice: +payload?.capitalGainsTax?.total?.transferPrice || null,
            l77DeductibleExpense: +payload?.capitalGainsTax?.total?.deductibleExpense || null,
            l77CapitalGainsOrLosses: +payload?.capitalGainsTax?.total?.capitalGainsOrLosses || null,
            l77Rate: +payload?.capitalGainsTax?.total?.rate || null,
            l77PaymentTaxes: +payload?.capitalGainsTax?.total?.paymentTaxes || null,

            // TABLE 14
            // l78
            l78NumberOfPassengers: +payload?.airportStampDuty?.internationalFlights?.numberOfPassengers || null,
            l78Rate: +payload?.airportStampDuty?.internationalFlights?.rate || 10000,
            l78Principal: +payload?.airportStampDuty?.internationalFlights?.principal || null,
            l78Penalties: +payload?.airportStampDuty?.internationalFlights?.penalities || null,
            l78Total: +payload?.airportStampDuty?.internationalFlights?.total || null,

            // l79
            l79NumberOfPassengers: +payload?.airportStampDuty?.internationalFlights?.numberOfPassengers || null,
            l79Rate: +payload?.airportStampDuty?.internationalFlights?.rate || 1000,
            l79Principal: +payload?.airportStampDuty?.internationalFlights?.principal || null,
            l79Penalties: +payload?.airportStampDuty?.internationalFlights?.penalities || null,
            l79Total: +payload?.airportStampDuty?.internationalFlights?.total || null,

            // l80
            l80NumberOfPassengers: +payload?.airportStampDuty?.internationalFlights?.numberOfPassengers || null,
            l80Rate: +payload?.airportStampDuty?.internationalFlights?.rate || null,
            l80Principal: +payload?.airportStampDuty?.internationalFlights?.principal || null,
            l80Penalties: +payload?.airportStampDuty?.internationalFlights?.penalities || null,
            l80Total: +payload?.airportStampDuty?.internationalFlights?.total || null,

            // TABLE 15
            // l81
            l81Principal: +payload?.recapitulativeTotal?.tsr?.principal || +varl81Principal || null,
            l81Cac: +payload?.recapitulativeTotal?.tsr?.cac || +varl81Cac || null,
            l81Penalties: +payload?.recapitulativeTotal?.tsr?.penalities || +varl81Penalties || null,
            l81Total: +payload?.recapitulativeTotal?.tsr?.total || varl81Total || null,

            // l82
            // check what checkbox is set first
            l82Principal: +payload?.recapitulativeTotal?.exicesDuties?.principal || varl82Principal || null,
            l82CheckBox: +payload?.recapitulativeTotal?.vat?.rate || 8,
            l82Cac: +payload?.recapitulativeTotal?.exicesDuties?.cac || null,
            l82Penalties: +payload?.recapitulativeTotal?.exicesDuties?.penalities || varl82Penalties || null,
            l82Total: +payload?.recapitulativeTotal?.exicesDuties?.total || varl82Total || null,

            // l83
            l83Principal: +payload?.recapitulativeTotal?.vat?.principal || varl83Principal || null,
            l83Cac: +payload?.recapitulativeTotal?.vat?.cac || varl83Cac || null,
            l83Penalties: +payload?.recapitulativeTotal?.vat?.penalities || varl83Penalties || null,
            l83Total: +payload?.recapitulativeTotal?.vat?.total || varl83Total || null,

            // l84
            l84Principal: +payload?.recapitulativeTotal?.acOrIr?.principal || +varl84Principal || null,
            l84Cac: +payload?.recapitulativeTotal?.acOrIr?.cac || +varl84Cac || null,
            l84Penalties: +payload?.recapitulativeTotal?.acOrIr?.penalities || +varl84Penalties || null,
            l84Total: +payload?.recapitulativeTotal?.acOrIr?.total || +varl84Total || null,

            // l85
            l85Principal: +payload?.recapitulativeTotal?.depositToBePaid?.principal || +varl85Principal || null,
            l85Cac: +payload?.recapitulativeTotal?.depositToBePaid?.cac || +varl85Cac || null,
            l85Penalties: +payload?.recapitulativeTotal?.depositToBePaid?.penalities || +varl85Penalties || null,
            l85Total: +payload?.recapitulativeTotal?.depositToBePaid?.total || varl85Total || null,

            // l86
            l86Principal: +payload?.recapitulativeTotal?.ircm?.principal || +varl86Principal || null,
            l86Cac: +payload?.recapitulativeTotal?.ircm?.cac || +varl86Cac || null,
            l86Penalties: +payload?.recapitulativeTotal?.ircm?.penalities || +varl86Penalties || null,
            l86Total: +payload?.recapitulativeTotal?.ircm?.total || +varl86Total || null,

            // l87
            l87Principal: +payload?.recapitulativeTotal?.iROnNoneCommercial?.principal || +varl87Principal || null,
            l87Cac: +payload?.recapitulativeTotal?.iROnNoneCommercial?.cac || +varl87Cac || null,
            l87Penalties: +payload?.recapitulativeTotal?.iROnNoneCommercial?.penalities || +varl87Penalties || null,
            l87Total: +payload?.recapitulativeTotal?.iROnNoneCommercial?.total || +varl87Total || null,

            // l88
            l88Principal: +payload?.recapitulativeTotal?.rts?.principal || +varl88Principal || null,
            l88Cac: +payload?.recapitulativeTotal?.rts?.cac || +varl88Cac || null,
            l88Penalties: +payload?.recapitulativeTotal?.rts?.penalities || +varl88Penalties || null,
            l88Total: +payload?.recapitulativeTotal?.rts?.total || +varl88Total || null,

            // l89
            l89Principal: +payload?.recapitulativeTotal?.taxesOnCapitalGains?.principal || +varl89Principal || null,
            l89Cac: +payload?.recapitulativeTotal?.taxesOnCapitalGains?.cac || +varl89Cac || null,
            l89Penalties: +payload?.recapitulativeTotal?.taxesOnCapitalGains?.penalities || +varl89Penalties || null,
            l89Total: +payload?.recapitulativeTotal?.taxesOnCapitalGains?.total || +varl89Total || null,

            // l90
            l90Principal: +payload?.recapitulativeTotal?.airportStampDuty?.principal || +varl90Principal || null,
            l90Cac: +payload?.recapitulativeTotal?.airportStampDuty?.cac || +varl90Cac || null,
            l90Penalties: +payload?.recapitulativeTotal?.airportStampDuty?.penalities || +varl90Penalties || null,
            l90Total: +payload?.recapitulativeTotal?.airportStampDuty?.total || +varl90Total || null,

            // l91
            l91Principal: +payload?.recapitulativeTotal?.totalToPay?.principal || varl91Principal || null,
            l91Cac: +payload?.recapitulativeTotal?.totalToPay?.cac || varl91Cac || null,
            l91Penalties: +payload?.recapitulativeTotal?.totalToPay?.penalities || varl91Penalties || null,
            l91Total: +payload?.recapitulativeTotal?.totalToPay?.total || varl91Total || null,

            // FOOTER
            paymentType: varPaymentType,
            location: payload.location || null,
            declarationDate: payload.declarationDate || null,
            amount: +payload.amount || null,
            chequeNumber: payload?.cheque?.chequeNumber || null,
            bank: payload?.cheque?.bank || null,
            agency: payload?.cheque?.agency || null,
        }
    }

    getData = async () => {
        const defaultOrg = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
        if(defaultOrg.id){
            this.setState({loadTaxe: true});
            try{
                const res = await _getOrganizationTaxes(defaultOrg.id, this.state.pageSize, this.state.startPage, this.state.filter);
                this.props.setOrganizationTaxes(res.data.rows);
                this.setState({ 
                    currentPage: res.data.pager.currentPage,
                    endIndex: res.data.pager.endIndex,
                    endPage: res.data.pager.endPage,
                    pageSize: res.data.pager.pageSize,
                    pages: res.data.pager.pages,
                    startIndex: res.data.pager.startIndex,
                    startPage: res.data.pager.startPage,
                    totalItems: res.data.pager.totalItems,
                    totalPages: res.data.pager.totalPages,
                    count: res.data.count,
                });
            }catch(e){
                Logger.log("Get Organization's Taxes","Can't get organization's taxes!" , e);
            }finally{
                this.setState({loadTaxe: false});
            }
        }
    }

    getMoreData = async () => {
        const defaultOrg = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
        try{
            this.setState({ scrollLoading: true });
            const res = await _getOrganizationTaxes(defaultOrg.id,this.state.pageSize, this.state.currentPage + 1, this.state.filter);
            this.setState({ 
                scrollLoading: false,
                currentPage: res.data.pager.currentPage,
                endIndex: res.data.pager.endIndex,
                endPage: res.data.pager.endPage,
                pageSize: res.data.pager.pageSize,
                pages: res.data.pager.pages,
                startIndex: res.data.pager.startIndex,
                startPage: res.data.pager.startPage,
                totalItems: res.data.pager.totalItems,
                totalPages: res.data.pager.totalPages,
                count: res.data.count,
            });
            this.props.addMultipleOrganizationTaxes(res.data.rows);
        }catch(e){
            Logger.log("Error","Can't get more organization's bills!" , e);
        }finally{
            this.setState({scrollLoading: false});
        }
    }

    renderStatus = (status) => {
        switch(status.toLowerCase()){
            case "déclaré" : {
                return "success"
            }
            case "en attente" : {
                return "default"
            }
            default: {
                return "default"
            }
        }
    }

    headerData = [
        { title: <span> Période <img src={reverseOrderIcon} alt="Période" width={10} height={10} /></span> , align: "center", className: 'pl-1 w-50-percent', name: "date"}, 
        { title: <span> Montant <img src={reverseOrderIcon} alt="Montant" width={10} height={10} /></span> , align: "left", className: 'w-25-percent pl-15', name: "amount"}, 
        { title: <span> Déclaration <img src={reverseOrderIcon} alt="Déclaration" width={10} height={10} /></span> , align: "center", className: 'text-align-center justify-content-center w-15-percent pr-2', name: "report"}, 
        { title: <span> Statut <img src={reverseOrderIcon} alt="status" width={10} height={10} /></span> , align: "right", className: 'text-align-center justify-content-center sm-hidden w-10-percent', name: "status"}
    ];

    formatBodyData = (data) => {
        return data.map((item, i) => {
            let resData = {
                originalData: item,
                date: <span style={{textTransform: 'capitalize'}}>{moment.utc(item.date).format('MMMM YYYY')}</span>,
                amount: (item.amount) ? (<span><span className="devise"> {`${item.currency} `} </span> {`${numberFormat(item.amount)}`} </span>) : '',
                report: <a href={item.attachment} onClick={() => {}} target='_blank' rel="noopener noreferrer" download={`Fiche-imposition-${moment(item.date).format('MMMM YYYY')}`} alt={`fiche d'imposition ${moment(item.date).format('MMMM YYYY')}`} title={`fiche d'imposition' ${moment(item.date).format('MMMM YYYY')} `} > <FilePdfDownloadPdf color="#09B6CC" /> </a>,
                status: (item.status) ? (<LabelStatus variant={this.renderStatus(item.status)} statement={`${item.status}`} />) : '',
            }
            return resData;
        });
    }

    createTaxPeriod = async () => {
        const defaultOrg = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
        try {
            this.setState({loadTaxe: true});
            await _createTaxPeriod(defaultOrg.id);
            toastr.success("", "Déclaration d'impôts créée avec succès !");
            await this.getData();
        } catch(err) {
            const error = { ...err };
            Logger.error("Create taxation period failed", error);
            if(error?.response?.status === 400 || error?.response?.status === 405){ 
                toastr.info("", error?.response?.data?.title?.trim()); 
            } else {
                errorNotifcation(error?.response?.status, "Une erreur est survenu lors du traitement de votre requete.");
            }
        } finally {
            this.setState({loadTaxe: false});
        }
    }

    gotoPreview = (payload) => {
        this.props.selectedDefaultOrgTaxes({...payload.originalData});
        const dataFormOne =  this.initFormOne(payload.originalData);
        const dataFormTwo = this.initFormTwo(payload.originalData);
        const dataFormThree = this.initFormThree(payload.originalData);
        const dataFormFour = this.initFormFour(payload.originalData);
        this.props.selectTaxe({ id: payload.originalData.id, ...dataFormOne, ...dataFormTwo, ...dataFormThree, ...dataFormFour });
        this.props.history.push(this.props.match.url + '/declaration/edit?page=1', {...payload.originalData});
    }

    render() {
        return (
            <div className='bill-list col-display'>
                <div className="d-flex flex-column flex-1-auto pl-3">
                    <div className='flex-1-auto mt-4 mr-3'>
                        <div className="options-control-header mb-3">
                            <div className="head-option-left">
                                <button className='btn-plus taxe' onClick={() => this.createTaxPeriod()}>
                                    <IconPlus width={9} height={9} />
                                </button>
                                <Loader loadData={this.state.loading} className="ml-3" style={{width: 28, height: 28}} />
                            </div>
                            <Search iconClass='search-taxe' />
                        </div>

                        <div className='mt-4'>
                            <Suspense fallback={<div className="text-center">Chargement... </div>} >
                                <GenericTable
                                    data={this.props.taxes}
                                    onRef={this.setRefGenericTable}
                                    header={this.headerData}
                                    body={this.formatBodyData(this.props.taxes)}
                                    className=''
                                    isLoading={this.state.loadTaxe}
                                    asLoader={<Loader style={{width: "25px", height: "25px"}} enable />}
                                    onSelect={this.gotoPreview}
                                    infiniteScroll
                                    getData={this.getMoreData}
                                    hasMore={(this.state.currentPage < this.state.endPage)}
                                    scrollLoading={this.state.scrollLoading}
                                />
                            </Suspense>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth.authData,
        organizations: state.organizations.organizations,
        taxes: state.organizations.organizationTaxes,
        selectedTaxes: state.taxes.selectedTaxe,
    }
}

export default connect(mapStateToProps, { setOrganizationTaxes, addMultipleOrganizationTaxes, selectedDefaultOrgTaxes, selectTaxe })(TaxeList);
