import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import App from '../App';
import SwitchOrgs from '../pages/SwitchOrgs';
import CreateOrg from '../pages/CreateOrg';
import Dashboard from '../pages/Dashboard/Dashboard';
import Bank from '../pages/Bank/Bank';
import Fees from '../pages/Fees/Fees';
import Bills from '../pages/Bills/Bills';
import Social from '../pages/Social/Social';
import Treasury from '../pages/Treasury/Treasury';
import Taxes from '../pages/Taxes';
import UsersPage from "../pages/Users";
import UserInvitationPage from "../pages/UserInvitation";
import { _extractPayloadFromToken } from '../helpers/jwt';
import { setAuthToken, setSocketService } from '../redux/action/AuthAction';
import RegisterAccount from '../pages/RegisterAccount';
import ForgotPassword from '../pages/ForgotPassword';
import { addOrganizationBankRecords } from '../redux/action/OrganisationAction';
import { setRecords, setStatements } from '../redux/action/AccountAction';
import { updatePayslip, setPayslipsSentByMail } from '../redux/action/PayslipAction'
import { setBankStatements, setLoaderImportBankStatement } from "../redux/action/bankAction";
import { selectCnpsDeclaration } from "../redux/action/TaxesAction";
import { setClosePayPeriodLoader } from "../redux/action/PayPeriodAction";
import SocketService from '../services/SocketService';
import { FUNCTIONALITIES_CODE } from '../constants/AuthConstants';
import { SESSION_STORAGE_KEYS } from '../constants/constants';
import SwitchOrgsLoader from '../components/loaders/SwitchOrgsLoader';
import AuthorizationRoute from './authRoute';
import Logger from '../helpers/Logger';

class Routes extends Component {

    habilitationsLoaded() {
        return sessionStorage.getItem(SESSION_STORAGE_KEYS.HABILITATIONS_LOADED)
    }
    UNSAFE_componentWillMount = () => {
        const jsontoken = sessionStorage.getItem(SESSION_STORAGE_KEYS.TOKEN);
        const token = JSON.parse(jsontoken);
        if (token) {
            const { access_token, refresh_token } = token;
            SocketService.initWebsocket(this.props);
            if (!access_token || !refresh_token) {
            } else {
                try {

                    _extractPayloadFromToken(access_token); // decode the token
                } catch (e) {
                    Logger.error('error while decoding token', e)
                }
            }
        }
    }

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path='/' component={App} />
                    <Route path='/register' component={RegisterAccount} />
                    <Route path='/forgot-password' component={ForgotPassword} />
                    <Route path='/user-invitation/validate/:token' component={UserInvitationPage}/>
                    <AuthorizationRoute exact path='/organizations' component={SwitchOrgs} />
                    <AuthorizationRoute path='/organization' component={CreateOrg} />
                    <AuthorizationRoute path='/dashboard' component={Dashboard} />
                    {
                        this.habilitationsLoaded() ?
                        <>
                            <AuthorizationRoute path='/accounts'  component={Bank} functionalitycode={FUNCTIONALITIES_CODE.MENU_BANK} />
                            <AuthorizationRoute path='/fees' component={Fees} functionalitycode={FUNCTIONALITIES_CODE.MENU_FEES} />
                            <AuthorizationRoute path='/billing' component={Bills} functionalitycode={FUNCTIONALITIES_CODE.MENU_BILLS} />
                            <AuthorizationRoute path='/social' component={Social} functionalitycode={FUNCTIONALITIES_CODE.MENU_SOCIAL}/>
                            <AuthorizationRoute path='/treasury' component={Treasury} functionalitycode={FUNCTIONALITIES_CODE.MENU_TREASURY} />
                            <AuthorizationRoute path='/taxes' component={Taxes} functionalitycode={FUNCTIONALITIES_CODE.MENU_TAXES} />
                            <AuthorizationRoute path='/users' component={UsersPage} functionalitycode={FUNCTIONALITIES_CODE.MENU_USERS} />
                        </> :
                        <SwitchOrgsLoader />
                    }
                </Switch>
            </BrowserRouter>
        )
    }

}

const mapStateToProps = (state) => {
    const { auth, organizations } = state
    return {
        authToken: auth.authToken,
        habilitationsLoaded: organizations.habilitationsLoaded
    }
}

export default connect(mapStateToProps, { setAuthToken,setSocketService,addOrganizationBankRecords, updatePayslip, setPayslipsSentByMail, setBankStatements, setLoaderImportBankStatement, setClosePayPeriodLoader, setRecords, setStatements, selectCnpsDeclaration })(Routes)
