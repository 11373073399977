import { SET_DEFAULT_ITEM } from "../action-type/navType";
import {FUNCTIONALITIES_CODE} from "../../constants/AuthConstants"

const INITIAL_STATE = {
    items: [
        {
            id: 1,
            label: 'Tableau de bord',
            route: '/dashboard',
            activClass: 'dashboard-active',
            class: 'dashboard-link',
            activeLabel: 'dashboard-label',
            active: true,
            mouseOver: false,
            notification: 0,
            code: FUNCTIONALITIES_CODE.DASHBOARD,
        },
        {
            id: 2,
            label: 'Comptes',
            route: '/accounts',
            activClass: 'bank-active',
            activeLabel: 'bank-label',
            class: 'bank-link',
            active: false,
            mouseOver: false,
            notification: 0,
            code: FUNCTIONALITIES_CODE.MENU_BANK
        },
        {
            id: 3,
            label: 'Frais',
            route: '/fees',
            activClass: 'fees-active',
            activeLabel: 'fess-label',
            class: 'fees-link',
            active: false,
            mouseOver: false,
            notification: 0,
            code: FUNCTIONALITIES_CODE.MENU_FEES
        },
        {
            id: 4,
            label: 'Facturation',
            route: '/billing',
            activClass: 'bill-active',
            activeLabel: 'bill-label',
            class: 'bill-link',
            active: false,
            mouseOver: false,
            notification: 0,
            code: FUNCTIONALITIES_CODE.MENU_BILLS
        },
        {
            id: 5,
            label: 'Social',
            route: '/social',
            activClass: 'social-active',
            activeLabel: 'social-label',
            class: 'social-link',
            active: false,
            mouseOver: false,
            notification: 3,
            code: FUNCTIONALITIES_CODE.MENU_SOCIAL
        },
        {
            id: 6,
            label: 'Trésorerie',
            route: '/treasury',
            activClass: 'treasury-active',
            activeLabel: 'treasury-label',
            class: 'treasury-link',
            active: false,
            mouseOver: false,
            notification: 0,
            code : FUNCTIONALITIES_CODE.MENU_TREASURY
        },
        {
            id: 7,
            label: 'Impôts & CNPS',
            route: '/taxes',
            activClass: 'taxe-active',
            activeLabel: 'taxe-label',
            class: 'taxe-link',
            active: false,
            mouseOver: false,
            notification: 0,
            code : FUNCTIONALITIES_CODE.MENU_TAXES
        },
        {
            id: 8,
            label: '',
            route: '/users',
            activClass: 'user-active',
            activeLabel: 'user-label',
            class: 'user-link',
            active: false,
            mouseOver: false,
            notification: 0
        },
    ]
};

const NavReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_DEFAULT_ITEM : 
            let {items} = state;
            const i = items.findIndex(elm => elm.id === 8); // This is for remove the "user" Navlink...
            if(i >= 0) items.splice(i, 1); // that create the overflow in the Navlink bar.
            items.map((element) => (
                element.active = false
            ))
            if(action.payload >= 0 && action.payload !== 8) items[action.payload].active = true;
            return state; 
        default: 
            return state;
    };
};

export default NavReducer;