import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateOrganizationTaxes } from "../../redux/action/OrganisationAction";
import { 
    selectTaxe, handleChange, handleChangeTab, handleChangeRate, handleCheckbox, 
    getSum, getSumL1L2, getSumL3L4, getSubstactL5L6, getSumL5L7, switchL22OrL23, getL16XL22,
    actionTabFive, 
} from "../../redux/action/TaxesAction";
import { numberFormat } from '../../helpers/Number';



class StepTwoForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.table8Cols = ['Base', 'Principal', 'Cac', 'Penalties'];
        this.table9Cols = ['Base', 'Principal', 'Cac', 'Penalties']

    }
    
    componentDidMount(){
        if(this.props.onRef){
            this.props.onRef(this);
        }
        this.init();
        this.sumL10ToL12();
    }
    
    init = () => {
        if(!this.props.selectedPeriod){
            this.props.history.push('/taxes');
        };
    }

    sumL10ToL12 = () => {
        const l10 = +this.props.selectedTaxes.ottntotal;
        const l11 = +this.props.selectedTaxes.mdatotal;
        const l12 = +this.props.selectedTaxes.aottotal;
        const e = {target: { name: "l28", value: (l10+l11+l12) }};
        this.props.handleChange(e);
    }

    handleChange = (e, callback = null) => {
        this.props.handleChange(e);
        if (callback) {
            callback();
        }
    }

    l16Xl22 = () => {
        const l16 = +this.props.selectedTaxes?.l16;
        const l22 = +this.props.selectedTaxes?.l22;
        const e = {target: { name: "l23", value: (l16*l22) }};
        this.props.handleChange(e);
    }

    handleChangeTab = (e, tabCol = [], total = "", taux = null, callback = null) => {
        this.props.handleChangeTab(e, tabCol, total, taux);
        if(callback){ callback(); }
    }

    handleChangeRate = (e, tabCol = [], total = "", callback = null) => {
        this.props.handleChangeRate(e, tabCol, total);
        if(callback){ callback(); }
    }

    getSum = (target, tab = [], taux = null) => {
        this.props.getSum(target, tab, taux);
    }

    getSumL1L2 = (callback = null) => {
        this.props.getSumL1L2();
        setTimeout(() => {if(callback)callback();}, 100);
    }

    getSumL3L4 = (callback = null) => {
        this.props.getSumL3L4();
        if(callback){ callback(); }
    }

    getSubstactL5L6 = (callback = null) => {
        this.props.getSubstactL5L6();
        if(callback){ callback(); }
    }

    getSumL5L7 = (callback = null) => {
        this.props.getSumL5L7();
        if(callback){ callback(); }
    }

    switchL22OrL23 = (value, callback = null) => {
        this.props.switchL22OrL23(value);
        if(callback){ callback(); }
    }

    handleCheckbox = (e, target, tab, callback = null) => {
        this.props.handleCheckbox(e, target, tab);
        if(callback){ callback(); }
    }

    sumTabs = (num, tabs = [], desTab = null) => {
        let arr = [];
        tabs.forEach((elm, idx) => {
            arr[idx] = num + elm;
        });

        this.props.sumTabs(arr, desTab);
    }

    getPayload = () => {
        return {
            deductibleVat: {
                forecastProrata: this.props.selectedTaxes.l16,
                reportOfPreviousCredit: this.props.selectedTaxes.l17,
                deductibleVatOnLocalPurchases: this.props.selectedTaxes.l18,
                deductibleVatOnLocalServices: this.props.selectedTaxes.l19,
                deductibleVatOnPurchasesAbroad: this.props.selectedTaxes.l20,
                deductibleVatOnServicesAndOthers: this.props.selectedTaxes.l21,
                totalDeductibleVat: this.props.selectedTaxes.l22,
                deductibleVatOnprovisionalProrata: this.props.selectedTaxes.l23
            },
            exceptionalAdjustments: {
                adjustmentOfDeductibleVat: this.props.selectedTaxes.l24,
                regularisationOfVatPaidByState: this.props.selectedTaxes.l25,
                adjustmentOnDisposalOfItems: this.props.selectedTaxes.l26,
                adjustmentOfVatToBeRepaid: this.props.selectedTaxes.l27
            },
            vatPayableOrVatCredit: {
                vatCollected: this.props.selectedTaxes.l28,
                deductibleVatL22OrL23: this.props.selectedTaxes.l29,
                vatRegularisationToDeducted: this.props.selectedTaxes.l30,
                vatRegularisationToRepaid: this.props.selectedTaxes.l31,
                vatToBePaid: this.props.selectedTaxes.l32,
                vatCredit: this.props.selectedTaxes.l33,
                reimbursementRequested: this.props.selectedTaxes.l34,
                adjustmentOfDeductibleVat: this.props.selectedTaxes.l45
            },
            totalVatToBePaid: {
                vatToBePaid: { principal: this.props.selectedTaxes.l36principal, cac: this.props.selectedTaxes.l36cac, penalities: this.props.selectedTaxes.l36Penalties, total: this.props.selectedTaxes.l36total },
                vatWithheldAtSource: { principal: this.props.selectedTaxes.l37principal, cac: this.props.selectedTaxes.l37cac, penalities: this.props.selectedTaxes.l37Penalties, total: this.props.selectedTaxes.l37total },
                vatWithheldOnRemunerationPaidAbroad: { principal: this.props.selectedTaxes.l38principal, cac: this.props.selectedTaxes.l38cac, penalities: this.props.selectedTaxes.l38Penalties, total: this.props.selectedTaxes.l38total },
                amountToBePaid: { principal: this.props.selectedTaxes.l39principal, cac: this.props.selectedTaxes.l39cac, penalities: this.props.selectedTaxes.l39Penalties, total: this.props.selectedTaxes.l39total },
            },
            prepaymentsToBeReserved: {
                advanceOnCAWithheldAtSource: { base: this.props.selectedTaxes.l40base, principal: this.props.selectedTaxes.l40principal, cac: this.props.selectedTaxes.l40cac, penalities: this.props.selectedTaxes.l40penalties, total: this.props.selectedTaxes.l40total },
                prepaymentsOnWithheldPurchases: { base: this.props.selectedTaxes.l41base, principal: this.props.selectedTaxes.l41principal, cac: this.props.selectedTaxes.l41cac, penalities: this.props.selectedTaxes.l41penalties, total: this.props.selectedTaxes.l41total, rate: this.props.selectedTaxes.l41total },
                prepaymentsOf15OnRents: { base: this.props.selectedTaxes.l42base, principal: this.props.selectedTaxes.l42principal, cac: this.props.selectedTaxes.l42cac, penalities: this.props.selectedTaxes.l42penalties, total: this.props.selectedTaxes.l42total },
                withholdingTaxOnRemuneration: { base: this.props.selectedTaxes.l43base, principal: this.props.selectedTaxes.l43principal, cac: this.props.selectedTaxes.l43cac, penalities: this.props.selectedTaxes.l43penalties, total: this.props.selectedTaxes.l43total },
                total: { base: this.props.selectedTaxes.l44base, principal: this.props.selectedTaxes.l44principal, cac: this.props.selectedTaxes.l44cac, penalities: this.props.selectedTaxes.l44penalties, total: this.props.selectedTaxes.l44total },
            }
        };
    }

    render() {
        return <div className="step-two">
            {/* TABLEAU 3 */}
            <div className="d-flex align-items-center justify-content-between mt-2 pl-2 pr-2">
                <table className="table-taxes">
                    <thead>
                        <tr align="center">
                            <td width="70%" align="left"><span className="sm-txt bold pl-2">3 -TVA DÉDUCTIBLE </span></td>
                            <td colSpan="4" width="30%"><span className="sm-txt bold">&nbsp;</span></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span className="sm-txt pl-2">Prorata prévisionnel (Assujettis partiels) : Cf. DSF exercice n-1</span></td>
                            <td align="center" className="bordered-bottom"> <span className="sm-txt  bold line-indicator bold">L16</span> </td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l16" value={this.props.selectedTaxes?.l16} onChange={(e) => this.handleChange(e, () => { this.props.actionTabFive() } )} placeholder="" className="input-text text-right-h pr-2" /></td>
                        </tr>
                        <tr>
                            <td>
                                <span className="sm-txt pl-2 align-items-left">Report du crédit antérieur :(L33 de la déclaration précédente )</span>
                            </td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L17</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l17" value={this.props.selectedTaxes?.l17} onChange={(e) => { this.handleChangeTab(e, ['l18','l19','l20', 'l21'], "l22", null, () => { this.props.actionTabFive() } ) }} placeholder="" className="input-text text-right-h pr-2" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">TVA déductible sur achats locaux:</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L18</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l18" value={this.props.selectedTaxes?.l18} onChange={(e) => this.handleChangeTab(e, ['l17','l19','l20', 'l21'], "l22", null, () => { this.props.actionTabFive() } )} placeholder="" className="input-text text-right-h pr-2" /></td>
                            </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">TVA déductible sur  services locaux: </span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L19</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l19" value={this.props.selectedTaxes?.l19} onChange={(e) => this.handleChangeTab(e, ['l17','l18','l20', 'l21'], "l22", null, () => { this.props.actionTabFive() } )} placeholder="" className="input-text text-right-h pr-2" /></td>
                            </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">TVA déductible sur achats à l’étranger</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt bold line-indicator bold">L20</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l20" value={this.props.selectedTaxes?.l20} onChange={(e) => this.handleChangeTab(e, ['l17','l18','l19', 'l21'], "l22", null, () => { this.props.actionTabFive() } )} placeholder="" className="input-text text-right-h pr-2" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">TVA déductible sur services et autres rémunérations versés à l’étranger</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt bold line-indicator bold">L21</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l21" value={this.props.selectedTaxes?.l21} onChange={(e) => this.handleChangeTab(e, ['l17','l18','l19', 'l20'], "l22", null, () => { this.props.actionTabFive() } )} placeholder="" className="input-text text-right-h pr-2" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Total TVA déductible :L17+L18+L19+L20+ L21</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt bold line-indicator bold">L22</span></td>
                            <td align="right" colSpan="3" className="bordered"><input type="text" name="l22" value={this.props.selectedTaxes.l22 ? numberFormat(this.props.selectedTaxes?.l22) : 0.00} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">TVA déductible au prorata previsionnel (assujettis partiels): L16 X L22</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt bold line-indicator bold">L23</span></td>
                            <td align="right" colSpan="3" className="bordered"><input type="text" name="l23" value={this.props.selectedTaxes.l23 ? numberFormat(this.props.selectedTaxes?.l23) : 0.00} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* TABLEAU 4 */}
            <div className="d-flex align-items-center justify-content-between mt-4 pl-2 pr-2">
                <table className="table-taxes">
                    <thead>
                        <tr align="center">
                            <td width="70%" align="left"><span className="sm-txt bold pl-2">4 - RÉGULARISATIONS EXCEPTIONNELLES</span></td>
                            <td colSpan="4" width="30%"><span className="sm-txt bold">{null}</span></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span className="sm-txt pl-2">Régularisation de TVA déductible ou TVA déjà retenue à la source  (-) </span></td>
                            <td align="center" className="bordered-bottom"> <span className="sm-txt  bold line-indicator bold">L24</span> </td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l24" value={this.props.selectedTaxes?.l24} onChange={(e) => this.handleChange(e, () => {this.props.actionTabFive()})} placeholder="" className="input-text text-right-h pr-2" /></td>
                        </tr>
                        <tr>
                            <td>
                                <span className="sm-txt pl-2 align-items-left"   >Régularisation de TVA  prise en charge  par l’Etat (-) </span>
                            </td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L25</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l25" value={this.props.selectedTaxes?.l25} onChange={(e) => this.handleChange(e, () => {this.props.actionTabFive()})} placeholder="" className="input-text text-right-h pr-2" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Régularisation sur cession d’éléments de l’actif immobilisé à reverser (+)</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L26</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l26" value={this.props.selectedTaxes?.l26} onChange={(e) => this.handleChange(e, () => {this.props.actionTabFive()})} placeholder="" className="input-text text-right-h pr-2" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Régularisation de TVA à reverser et autres (+)</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L27</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l27" value={this.props.selectedTaxes?.l27} onChange={(e) => this.handleChange(e, () => {this.props.actionTabFive()})} placeholder="" className="input-text text-right-h pr-2" /></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* TABLEAU 5 */}
            <div className="d-flex align-items-center justify-content-between mt-4 pl-2 pr-2">
                <table className="table-taxes">
                    <thead>
                        <tr align="center">
                            <td width="70%" align="left"><span className="sm-txt bold pl-2"> 5 - TVA ÀPAYER OU CRÉDIT DE TVA </span></td>
                        <td colSpan="4" width="30%"><span className="sm-txt bold">{null}</span></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span className="sm-txt pl-2">TVA collectée(L10 + L11 + L12)</span></td>
                            <td align="center" className="bordered-bottom"> <span className="sm-txt  bold line-indicator bold">28</span> </td>
                            <td align="right" colSpan="3" className="bordered"><input type="text" name="l28" value={numberFormat(this.props.selectedTaxes.l28)} disabled className="input-text text-right-h pr-2" /></td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex align-items-center sm-txt ml-2">
                                    TVA déductible:
                                    <div className="d-flex align-items-center h-20">
                                        <label htmlFor="isl22" className="d-flex align-items-center checkbox ml-2 mb-0"><span className="mr-2 sm-txt">(L22)</span> <input type="checkbox" name="isl22" id="isl22" value={true} className="table-checkbox" onChange={(e) => this.handleCheckbox(e,'isl22', ['isl22','isl23'], () => { this.switchL22OrL23('l22'); this.props.actionTabFive(); })} checked={this.props.selectedTaxes.isl22} /></label>
                                        <label htmlFor="or" className="d-flex align-items-center checkbox ml-2 mb-0"><span className="mr-2 sm-txt">ou</span></label>
                                        <label htmlFor="isl23" className="d-flex align-items-center checkbox ml-2 mb-0"><span className="mr-2 sm-txt">(L23)</span> <input type="checkbox" name="isl23" id="isl23" value={true} className="table-checkbox" onChange={(e) => this.handleCheckbox(e,'isl23', ['isl22','isl23'], () => { this.switchL22OrL23('l23'); this.props.actionTabFive(); })} checked={this.props.selectedTaxes.isl23} /></label>
                                    </div>
                                </div>
                            </td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L29</span></td>
                            <td align="right" colSpan="3" className="bordered"><input type="text" name="l29" value={numberFormat(this.props.selectedTaxes.l29)} disabled placeholder="" className="input-text text-right-h pr-2" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2"> Régularisation TVA à déduire : (L24 +L25)</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L30</span></td>
                            <td align="right" colSpan="3" className="bordered"><input type="text" name="l30" value={numberFormat(this.props.selectedTaxes.l30)} disabled placeholder="" className="input-text text-right-h pr-2" /></td>
                            </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Régularisation TVA à reverser : (L26 + L27) </span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L31</span></td>
                            <td align="right" colSpan="3" className="bordered"><input type="text" name="l31" value={numberFormat(this.props.selectedTaxes.l31)} placeholder="" disabled className="input-text text-right-h pr-2" /></td>
                            </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">TVA à payer : (L28 - L29 - L30 + L31)</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt bold line-indicator bold">L32</span></td>
                            <td align="right" colSpan="3" className="bordered"><input type="text" name="l32" value={numberFormat(this.props.selectedTaxes.l32)}  placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Crédit de TVA:</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt bold line-indicator bold">L33</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l33" value={this.props.selectedTaxes.l33} onChange={(e) => this.handleChange(e, () => {this.props.actionTabFive()})}  placeholder="" className="input-text text-right-h pr-2" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Remboursement demandé : (Préciser le montant) </span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt bold line-indicator bold">L34</span></td>
                            <td align="right" colSpan="3" className="bordered"><input type="text" name="l34" value={this.props.selectedTaxes.l34} onChange={(e) => this.handleChange(e, () => {this.props.actionTabFive()})} placeholder="" className="input-text text-right-h pr-2" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Crédit à reporter :(L33 - L34)</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt bold line-indicator bold">L35</span></td>
                            <td align="right" colSpan="3" className="bordered"><input type="text" name="l35" value={numberFormat(this.props.selectedTaxes.l35)} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* TABLEAU 6 */}
            <div className="d-flex align-items-center justify-content-between mt-4 pl-2 pr-2">
                <table className="table-taxes">
                    <thead>
                        <tr align="center">
                            <td width="40%" align="left"><span className="sm-txt bold pl-2">6 - TOTALDE TVA À PAYER</span></td>
                            <td colSpan="4" width="12%"><span className="sm-txt bold">PRINCIPAL</span></td>
                            <td width="12%"><span className="sm-txt bold">CAC</span></td>
                            <td width="12%"><span className="sm-txt bold">PÉNALITÉS </span></td>
                            <td width="12%"><span className="sm-txt bold">TOTAL</span></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span className="sm-txt pl-2 align-items-center">TVA à payer :(L32)</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L36</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l36principal" value={this.props.selectedTaxes.l36principal} onChange={(e) => this.handleChangeTab(e, ['l36cac', 'l36Penalties'], 'l36total', null, () => { this.props.actionTabFive()})} placeholder="" className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l36cac" value={this.props.selectedTaxes.l36cac} onChange={(e) => this.handleChangeTab(e, ['l36principal','l36Penalties'], 'l36total', null, () => { this.props.actionTabFive()})} placeholder="" className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l36Penalties" value={this.props.selectedTaxes.l36Penalties} onChange={(e) => this.handleChangeTab(e, ['l36principal', 'l36cac'], 'l36total', null, () => { this.props.actionTabFive()})} placeholder="" className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l36total" value={this.props.selectedTaxes.l36total ? numberFormat(this.props.selectedTaxes.l36total) : null} disabled placeholder="" className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">TVA retenue à la source  (Entreprise habilitée):</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L37</span></td>
                            <td align="right" colSpan="3" className="border"><input type="text" name="l37principal" value={this.props.selectedTaxes.l37principal} placeholder="" onChange={(e) => this.handleChangeTab(e, ['l37cac', 'l37Penalties'], 'l37total', null, () => { this.props.actionTabFive()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l37cac" value={this.props.selectedTaxes.l37cac} placeholder="" onChange={(e) => this.handleChangeTab(e, ['l37principal','l37Penalties'],'l37total', null, () => { this.props.actionTabFive()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border"><input type="text" name="l37Penalties" value={this.props.selectedTaxes.l37Penalties} placeholder="" onChange={(e) => this.handleChangeTab(e, ['l37principal','l37cac'],'l37total', null, () => { this.props.actionTabFive()})} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l37total" value={this.props.selectedTaxes.l37total ? numberFormat(this.props.selectedTaxes.l37total) : null} placeholder="" disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">TVA retenue sur remunération  versée à l’étranger :</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L38</span></td>
                            <td align="right" colSpan="3" className="bordered"><input type="text" name="l38principal" value={this.props.selectedTaxes.l38principal} onChange={(e) => this.handleChangeTab(e, ['l38cac', 'l38Penalties'],'l38total', null, () => { this.props.actionTabFive()})} placeholder="" className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l38cac" value={this.props.selectedTaxes.l38cac} onChange={(e) => this.handleChangeTab(e, ['l38principal','l38Penalties'],'l38total', null, () => { this.props.actionTabFive()})} placeholder="" className="input-text text-right-h pr-2"/></td>
                            <td align="right" className="bordered"><input type="text" name="l38Penalties" value={this.props.selectedTaxes.l38Penalties} onChange={(e) => this.handleChangeTab(e, ['l38principal','l38cac'],'l38total', null, () => { this.props.actionTabFive()})} placeholder="" className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l38total" value={this.props.selectedTaxes.l38total ? numberFormat(this.props.selectedTaxes.l38total) : null} disabled placeholder="" className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Montant à payer :( L36 + L37 + L38 )</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L39</span></td>
                            <td align="right" colSpan="3" className="border"><input type="text" name="l39principal" value={numberFormat(this.props.selectedTaxes.l39principal)} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l39cac" value={numberFormat(this.props.selectedTaxes.l39cac)} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l39Penalties" value={numberFormat(this.props.selectedTaxes.l39Penalties)} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l39total" value={this.props.selectedTaxes.l39total ? numberFormat(this.props.selectedTaxes.l39total) : null} disabled placeholder="" className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* TABLEAU 7 */}
            <div className="d-flex align-items-center justify-content-between mt-5 pl-2 pr-2">
                    <h3 className="title-table normal-txt-uppercase bold">IMPÔTS SUR LES REVENUS (IR)</h3>
                </div>
            <div className="d-flex align-items-center justify-content-between mt-2 pl-2 pr-2">
                <table className="table-taxes">
                    <thead>
                        <tr align="center">
                            <td width="40%" align="left"><span className="sm-txt bold pl-2">7 - ACOMPTES ET PRÉCOMPTES  À  REVERSER</span></td>
                            <td colSpan="4" width="12%"><span className="sm-txt bold">BASE</span></td>
                            <td width="12%"><span className="sm-txt bold">PRINCIPAL</span></td>
                            <td width="12%"><span className="sm-txt bold">CAC</span></td>
                            <td width="12%"><span className="sm-txt bold">PÉNALITÉS </span></td>
                            <td width="12%"><span className="sm-txt bold">TOTAL</span></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span className="sm-txt pl-2 align-items-center">Acompte sur CA  retenu à la source</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">40</span></td>
                            <td align="right" colSpan="3" className="border-dashed"><input type="text" name="l40base" value={this.props.selectedTaxes.l40base} onChange={(e) => this.handleChangeTab(e, ['l40principal', 'l40cac', 'l40penalties'], 'l40total', null, () => { this.props.actionTabFive() })} placeholder="" className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered" ><input type="text" name="l40principal" value={this.props.selectedTaxes.l40principal} onChange={(e) => this.handleChangeTab(e, ['l40base', 'l40cac', 'l40penalties'], 'l40total', null, () => { this.props.actionTabFive() })} placeholder="" className="input-text text-right-h pr-2"  /></td>
                            <td align="right" className="bordered"><input type="text" name="l40cac" value={this.props.selectedTaxes.l40cac} onChange={(e) => this.handleChangeTab(e, ['l40base', 'l40principal', 'l40penalties'], 'l40total', null, () => { this.props.actionTabFive() })} placeholder="" className="input-text text-right-h pr-2"  /></td>
                            <td align="right" className="border-dashed"><input type="text" name="l40penalties" value={this.props.selectedTaxes.l40penalties} onChange={(e) => this.handleChangeTab(e, ['l40base', 'l40principal', 'l40cac'], 'l40total', null, () => { this.props.actionTabFive() })} placeholder="" className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l40total" value={this.props.selectedTaxes.l40total ? numberFormat(this.props.selectedTaxes.l40total) : null} disabled placeholder="" className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td>
                                <div className="d-flex flex-column sm-txt ml-2">
                                    Précomptes sur achats retenus
                                    <div className="d-flex align-items-center h-20"> 
                                        <label for="psar05" className="d-flex align-items-center checkbox mr-2 mb-0"><span className="mr-2">0.5%</span> <input type="checkbox" name="l41rate" id="psar05" value={0.5} className="table-checkbox" onChange={(e) => this.handleCheckbox(e,'is05', ['is05','is1','is3','is5','is10'], () => {this.props.actionTabFive()} )} checked={+this.props.selectedTaxes.l41rate === 0.5} /></label>
                                        <label for="psar1" className="d-flex align-items-center checkbox ml-2 mr-2 mb-0"><span className="mr-2">1%</span> <input type="checkbox" name="l41rate" id="psar1" value={1} className="table-checkbox" onChange={(e) => this.handleCheckbox(e,'is1', ['is05','is1','is3','is5','is10'], () => {this.props.actionTabFive()} )} checked={+this.props.selectedTaxes.l41rate === 1}  /></label>
                                        <label for="psar3" className="d-flex align-items-center checkbox ml-2 mr-2 mb-0"><span className="mr-2">3%</span> <input type="checkbox" name="l41rate" id="psar3" value={3} className="table-checkbox" onChange={(e) => this.handleCheckbox(e,'is3', ['is05','is1','is3','is5','is10'], () => {this.props.actionTabFive()} )} checked={+this.props.selectedTaxes.l41rate === 3} /></label>
                                        <label for="psar5" className="d-flex align-items-center checkbox ml-2 mr-2 mb-0"><span className="mr-2">5%</span> <input type="checkbox" name="l41rate" id="psar5" value={5} className="table-checkbox" onChange={(e) => this.handleCheckbox(e,'is5', ['is05','is1','is3','is5','is10'], () => {this.props.actionTabFive()} )} checked={+this.props.selectedTaxes.l41rate === 5} /></label>
                                        <label for="psar10" className="d-flex align-items-center checkbox ml-2 mr-2 mb-0"><span className="mr-2">10%</span> <input type="checkbox" name="l41rate" id="psar10" value={10} className="table-checkbox" onChange={(e) => this.handleCheckbox(e,'is10', ['is05','is1','is3','is5','is10'], () => {this.props.actionTabFive()})} checked={+this.props.selectedTaxes.l41rate === 10} /></label>
                                    </div>
                                </div>
                            </td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L41</span></td>
                            <td align="right" colSpan="3" className="border"><input type="text" name="l41base" value={this.props.selectedTaxes.l41base} placeholder="" onChange={(e) => this.handleChangeTab(e, ['l41principal', 'l41cac', 'l41penalties'], 'l41total', this.props.selectedTaxes?.l41rate)} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border"><input type="text" name="l41principal" value={this.props.selectedTaxes.l41principal} placeholder="" onChange={(e) => this.handleChangeTab(e, ['l41base', 'l41cac', 'l41penalties'], 'l41total', this.props.selectedTaxes?.l41rate)} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l41cac" value={this.props.selectedTaxes.l41cac} placeholder="" onChange={(e) => this.handleChangeTab(e, ['l41base', 'l41principal', 'l41penalties'], 'l41total', this.props.selectedTaxes?.l41rate)} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="border"><input type="text" name="l41penalties" value={this.props.selectedTaxes.l41penalties} placeholder="" onChange={(e) => this.handleChangeTab(e, ['l41base', 'l41principal', 'l41cac'], 'l41total', this.props.selectedTaxes?.l41rate)} className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l41total" value={this.props.selectedTaxes.l41total ? numberFormat(this.props.selectedTaxes.l41total) : null} placeholder="" disabled className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Précomptes sur loyers (15%)</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L42</span></td>
                            <td align="right" colSpan="3" className="bordered"><input type="text" name="l42base" value={this.props.selectedTaxes.l42base} onChange={(e) => this.handleChangeTab(e, ['l42principal', 'l42cac', 'l42penalties'], 'l42total', 15, () => {this.props.actionTabFive()})} placeholder="" className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l42principal" value={this.props.selectedTaxes.l42principal} onChange={(e) => this.handleChangeTab(e, ['l42base', 'l42cac', 'l42penalties'], 'l42total', 15, () => {this.props.actionTabFive()})} placeholder="" className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l42cac" value={this.props.selectedTaxes.l42cac} onChange={(e) => this.handleChangeTab(e, ['l42base', 'l42principal', 'l42penalties'], 'l42total', 15, () => {this.props.actionTabFive()})} placeholder="" className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l42penalties" value={this.props.selectedTaxes.l42penalties} onChange={(e) => this.handleChangeTab(e, ['l42base', 'l42principal', 'l42cac'], 'l42total', 15, () => {this.props.actionTabFive()})} placeholder="" className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l42total" value={this.props.selectedTaxes.l42total ? numberFormat(this.props.selectedTaxes.l42total) : null} disabled placeholder="" className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">Précomptes sur rémunérations et honoraires(5%)</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L43</span></td>
                            <td align="right" colSpan="3" className="border"><input type="text" name="l43base" value={this.props.selectedTaxes.l43base} onChange={(e) => this.handleChangeTab(e, ['l43principal', 'l43cac', 'l43penalties'], 'l43total', 5, () => {this.props.actionTabFive()})} placeholder="" className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l43principal" value={this.props.selectedTaxes.l43principal} onChange={(e) => this.handleChangeTab(e, ['l43base', 'l43cac', 'l43penalties'], 'l43total', 5, () => {this.props.actionTabFive()})} placeholder="" className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l43cac" value={this.props.selectedTaxes.l43cac} onChange={(e) => this.handleChangeTab(e, ['l43base', 'l43principal', 'l43penalties'], 'l43total', 5, () => {this.props.actionTabFive()})} placeholder="" className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l43penalties" value={this.props.selectedTaxes.l43penalties} onChange={(e) => this.handleChangeTab(e, ['l43base', 'l43principal', 'l43cac'], 'l43total', 5, () => {this.props.actionTabFive()})} placeholder="" className="input-text text-right-h pr-2" /></td>
                            <td align="right" className="bordered"><input type="text" name="l43total" value={this.props.selectedTaxes.l43total ? numberFormat(this.props.selectedTaxes.l43total) : null} disabled placeholder="" className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                        <tr>
                            <td><span className="sm-txt pl-2">TOTAL = (Somme L 40 à  L43)</span></td>
                            <td align="center" className="bordered-bottom"><span className="sm-txt  bold line-indicator bold">L44</span></td>
                            <td align="right" colSpan="3" className="border"><input type="text" name="l44base" value={numberFormat(this.props.selectedTaxes.l44base)} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l44principal" value={numberFormat(this.props.selectedTaxes.l44principal)} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l44cac" value={numberFormat(this.props.selectedTaxes.l44cac)} className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l44penalties" value={numberFormat(this.props.selectedTaxes.l44penalties)} placeholder="" className="input-text text-right-h pr-2" disabled /></td>
                            <td align="right" className="bordered"><input type="text" name="l44total" value={this.props.selectedTaxes.l44total ? numberFormat(this.props.selectedTaxes.l44total) : null} disabled placeholder="" className="input-text text-right-h pr-2 bold" /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    }

}

const mapStateToProps = (state) => {
    return {
        selectedPeriod: state.organizations.selectedOrgTaxes,
        selectedTaxes: state.taxes.selectedTaxe,
    }
}

export default connect(mapStateToProps, { updateOrganizationTaxes, selectTaxe, handleChange, handleChangeTab, handleChangeRate, handleCheckbox, getSum, getSumL1L2, getSumL3L4, getSubstactL5L6, getSumL5L7, switchL22OrL23, getL16XL22, actionTabFive })(StepTwoForm);
