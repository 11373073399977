/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const BankComponent = (props) => (
  <svg width={props.width ? props.width : 15.618} height={props.height ? props.height : 15.853} viewBox="0 0 15.618 15.853">
    <defs>
      <style>{".a{fill:#b5b5b5;}"}</style>
    </defs>
    <g transform="translate(-1.568 -0.917)">
      <g transform="translate(1.568 0.917)">
        <rect
          className="a"
          width={15.529}
          height={0.794}
          transform="translate(0.089 15.058)"
        />
        <rect
          className="a"
          width={14.371}
          height={0.794}
          transform="translate(0.669 13.934)"
        />
        <rect
          className="a"
          width={0.33}
          height={0.643}
          transform="translate(4.51 1.228) rotate(58.962)"
        />
        <rect
          className="a"
          width={0.33}
          height={3.038}
          transform="translate(3.552 1.804) rotate(58.962)"
        />
        <rect
          className="a"
          width={2.603}
          height={0.33}
          transform="matrix(0.857, 0.515, -0.515, 0.857, 11.802, 1.649)"
        />
        <rect
          className="a"
          width={0.687}
          height={0.33}
          transform="matrix(0.857, 0.515, -0.515, 0.857, 10.79, 1.04)"
        />
        <path
          className="a"
          d="M39.294,16.915h.493l-.247-.654Z"
          transform="translate(-32.498 -13.497)"
        />
        <path
          className="a"
          d="M31.954,16.667h-.4v.383h.4a.184.184,0,0,0,.208-.191A.189.189,0,0,0,31.954,16.667Zm.181-.389a.174.174,0,0,0-.191-.177h-.387v.354h.387A.172.172,0,0,0,32.135,16.278Z"
          transform="translate(-26.155 -13.366)"
        />
        <path
          className="a"
          d="M9.375.917,1.568,5.611H17.181ZM7.422,4.812H6.729V3.44H7.4a.35.35,0,0,1,.391.349.31.31,0,0,1-.238.315.335.335,0,0,1,.265.335A.36.36,0,0,1,7.422,4.812Zm1.6,0-.1-.265H8.3l-.1.265H7.921L8.46,3.44h.3L9.3,4.812H9.025Zm1.61,0H10.4l-.716-.981v.981H9.447V3.44h.247l.7.95V3.44h.241Zm.654-.58-.121.142v.438h-.241V3.44h.241v.652L11.7,3.44H12l-.557.646.6.726h-.3Z"
          transform="translate(-1.568 -0.917)"
        />
        <path
          className="a"
          d="M10.22,42.621V35.958h.466v-.766H7.847v.766h.464v6.663Z"
          transform="translate(-6.716 -29.018)"
        />
        <path
          className="a"
          d="M49.475,42.621V35.958h.466v-.766H47.1v.766h.464v6.663Z"
          transform="translate(-38.898 -29.018)"
        />
        <path
          className="a"
          d="M69.11,42.621V35.958h.464v-.766H66.736v.766H67.2v6.663Z"
          transform="translate(-54.997 -29.018)"
        />
        <path
          className="a"
          d="M29.848,42.621V35.958h.466v-.766h-2.84v.766h.464v6.663Z"
          transform="translate(-22.807 -29.018)"
        />
        <rect
          className="a"
          width={14.454}
          height={0.819}
          transform="translate(0.591 5.025)"
        />
      </g>
    </g>
  </svg>
);

export default BankComponent;
