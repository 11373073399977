import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import IconPlus from "../../components/Icons/IconPlus";
import Loader from '../../components/Loader';
import Search from '../../components/Search';
import DisplayContainer from '../../components/DisplayContainer';
import InvitationForm from './InvitationForm';
import {_getOrganizationUsers} from "../../services/Organization";
import UserAuth from "../../helpers/auth"
import Logger from '../../helpers/Logger';
import "./users.css";
import { setSelectedOrgUser, setOrgUsers } from '../../redux/action/OrganisationAction';
import LabelStatus from '../../components/LabelStatus';
import reverseOrderIcon from '../../assets/svg/icon-reverseOrder.svg';
import { mapStatusToVariantLabel } from '../../constants/constants';
import { errorNotifcation } from "../../helpers/errorHandle";
const GenericTable = lazy(() => import("../../components/Table/GenericTable") );

class UserInvitation extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            displayModal: false,
            selectedItem: null,
            defaultModalTitle: "Inviter une personne",
            users: [],
            isPreview: true,
            loadingData: true,
        };
    }

    componentDidMount(){
        this.getOrganizationUsers();
    }

    onCloseModal = () => {
        this.setState({
            displayModal: false,
            isPreview: true
        })
        this.GenericTable && this.GenericTable.unSelect();
    }

    openModal = () => {
        this.setState({
            displayModal: true,
            isPreview: false,
        })
    }

    getOrganizationUsers = async () => {
        try {
            this.setState({
                loadingData: true,
            })
            this.onCloseModal();
            const orgId = UserAuth._getCurrentOrganization.id;
            const users = (await _getOrganizationUsers(orgId)).data.rows;
            this.props.setOrgUsers(users);
            this.setState({
                users
            }).then(()=> {
                this.setState({
                    loadingData: false,
                })
            })
        } catch (error) {
            Logger.error("GetOrganizationUsers", "Error during get organizations", error);
            errorNotifcation(error?.response?.status, "Impossible de récupérer les organisations, veuillez reessayer plus tard");
            this.setState({
                loadingData: false,
            })
        }
    }

    setLoading = (loading) => {
        this.DisplayContainer && this.DisplayContainer.setLoading(loading);
    }

    displayContainerRef = (ref) => {
        this.DisplayContainer = ref;
    }

    onSelectedUser = (item) => {
        this.props.setSelectedOrgUser(item.originalData);
        this.setState({
            selectedItem: item.originalData,
            displayModal: true,
            isPreview: true,
        })
    }

    setGenericTable = (ref) => {
        this.GenericTable = ref;
    }

    headerData = [
        { title: <> Nom <img src={reverseOrderIcon} alt="nom" width={10} height={10} /></> , align: "left", className: 'pl-15 user-name-width', name: "nom"}, 
        { title: <> Prénom <img src={reverseOrderIcon} alt="prenom" width={10} height={10} /></> , align: "left", className: 'text-align-right user-surname-width', name: "prenom"}, 
        { title: <> Email <img src={reverseOrderIcon} alt="email" width={10} height={10} /></> , align: "left", className: 'text-align-right user-email-width', name: "email"}, 
        { title: <> Role <img src={reverseOrderIcon} alt="role" width={10} height={10} /></> , align: "left", className: 'text-align-right user-role-width', name: "role"}, 
        { title: <> Status <img src={reverseOrderIcon} alt="status" width={10} height={10} /></> , align: "left", className: 'pl-15 user-status-width sm-hidden', name: "status"}, 
    ];

    formatBodyData = (data) => {
                return data?.map((item, i) => {
            return {
                originalData: item,
                nom: <span className="bold"> {item.user?.lastName || ""} </span>,
                prenom: <span className="bold"> {item.user?.firstName?.charAt(0).toUpperCase() + item.user?.firstName?.slice(1) || ""} </span>,
                email: <span className="email-text"> {item?.email || item.user?.email || ""} </span>,
                role: item.role?.rolename || "",
                status: <LabelStatus statement={item?.status || 'Actif'} variant={mapStatusToVariantLabel(item?.status)} />,
            }
        });
    }

    render() {
        return (
            <div className='user-views col-display'>
                <div className="d-flex flex-column flex-1-auto pl-3">
                    <div className="options-control-header pt-20 mr-3">
                        <div className="head-option-left">
                            <button className='btn-plus' onClick={this.openModal}>
                                <IconPlus width={9} height={9} />
                            </button>
                            <Loader loadData={this.state.loading} className="ml-3" style={{width: 28, height: 28}} />
                        </div>
                        <Search handleSearch={() => {}} iconClass='search-social' />
                    </div>
                    <div className='mt-4 mr-3'>
                        <div className="table-data-content">
                            <Suspense fallback={<div className="text-center">Chargement... </div>} >
                                <GenericTable
                                    data={this.props.users}
                                    onRef={this.setGenericTable}
                                    header={this.headerData}
                                    body={this.formatBodyData(this.props.users)}
                                    className='mt-20'
                                    hoverColor="rgba(255, 219, 219, 0.2)"
                                    isLoading={this.state.loadingData}
                                    asLoader={<Loader enable />}
                                    onSelect={this.onSelectedUser}
                                />
                            </Suspense>
                        
                        </div>
                    </div>
                </div>
                {   this.state.displayModal ?
                    <div className={'user-detail'}>
                        <DisplayContainer headerTile={this.state.selectedItem ? this.state.selectedItem.firstName : this.state.defaultModalTitle} onRef={this.displayContainerRef}
                        customHeader={!this.state.isPreview ? "Ajouter un utilisateur" : (this.state.selectedItem?.firstName || "UTILISATEUR")} onClose={this.onCloseModal} loading={false} >
                            <InvitationForm onSubmit={this.submitInvitationForm} user={this.state.isPreview ? {...this.state.selectedItem} : null} isPreview={this.state.isPreview}
                            refreshUsers={this.getOrganizationUsers} loadingSent={this.setLoading}/>
                        </DisplayContainer>
                    </div> : null
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { organizations } = state;
    return {
        selectedUser: organizations?.selectedOrgUser,
        users: organizations?.organizationUsers,
    }
}

export default connect(mapStateToProps, { setSelectedOrgUser, setOrgUsers })(UserInvitation);
