import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Search from '../Search'
import Loader from '../Loader';
import './TriSearch.css'

class Trisearch extends Component {
    constructor(props){
        super(props)
        this.state = {}
    }

    handleClick = (key) => {
        let {handleTri} = this.props
        if (key === 0 ) {
            handleTri(key)
        }if (key === 1) {
            handleTri(key)
        }if (key === 2) {
            handleTri(key)
        }
        
    }

    handleSearch = (term) => {
        this.props.handleSearch(term)
    }

    render(){
        const { className } = this.props;
        return (
            <div className={'tri-search ' + className + ' mt-20'}>
                <div className="d-flex">
                    <div className="export-file sm-hidden mr-2">
                        <span className="export-label ml-2">Exporter</span>
                    </div>
                    { this.props.loading ? 
                        <Loader enable style={{width: "30px", height: "30px"}} /> : <></>
                    }
                </div>
                
                <Search handleSearch={this.handleSearch} />
            </div>
        )
    }
}

Trisearch.propTypes = {
    Trisearch: PropTypes.func
}

export default Trisearch