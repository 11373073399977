import React, { Component } from 'react';
import CnpsList from './CnpsList';
import CnpsEdit from './CnpsEdit';

class CnpsPageViews extends Component {
    renderPage = () => {
        let { match } = this.props;
        switch(match.params.view){ 
            case 'edit':{
                return <CnpsEdit match={match} {...this.props} />}
            default:{
                return <CnpsList match={match} {...this.props} />}
        }
    }
    render() {
        return(
            <div className="flex-part content-taxe-pages mb-1">
                {this.renderPage()}
            </div>
        )
    }
}

export default CnpsPageViews;