import React from "react";

const MapComponent = (props) => (
  <svg width={ props.width ? props.width : 11.883 } height={ props.height ? props.height : 15.844 } viewBox="0 0 11.883 15.844">
    <defs>
      <style></style>
    </defs>
    <g transform="translate(-0.917 0)">
      <g transform="translate(0.917)">
        <path
          fill={`${props.active ? '#177BD1;' :'#b5b5b5;'}`}
          d="M6.858,0A5.934,5.934,0,0,0,.917,5.942c0,3.961,5.942,9.9,5.942,9.9S12.8,9.9,12.8,5.942A5.934,5.934,0,0,0,6.858,0Zm0,1.981A3.961,3.961,0,1,1,2.9,5.942,3.961,3.961,0,0,1,6.858,1.981Z"
          transform="translate(-0.917)"
        />
      </g>
    </g>
  </svg>
);

export default MapComponent;
