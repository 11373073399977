import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { ContentTypes, PAGINATION, ACCOUNT_TYPE } from '../../../constants/constants';
import {
    setOrganizationBankRecords, addMultipleOrganizationBankRecords, updateUnreconciledInvoices,
    setReconciledInvoices, setSelectedInvoice, addOrganizationBankRecords, setReconciledBankRecordItem,
} from '../../../redux/action/OrganisationAction';
import {
    setAccounts, setRecords, addRecordsMultiple, selectRecord, selectAccount,
} from '../../../redux/action/AccountAction';
import SearchBankRecord from '../../../components/SearchBankRecord';
import ListBankAccounts from "../../../components/ListBankAccounts";
import PeriodDropdown from "../../../components/PeriodDropdown";
import '../Bank.css';
import DisplayContainer from '../../../components/DisplayContainer';
import Reconciliation from "../../../views/Bank/Reconciliation";

import CustomImageZoom from "../../../components/CustomImageZoom";

import { _getInvoicesByParams } from '../../../services/Invoice';
import { _getRecordById } from "../../../services/BankRecord";

import { _getOrganizationAccounts, _getOrganizationRecords } from '../../../services/Organization';
import Logger from "../../../helpers/Logger";
import { numberFormat } from '../../../helpers/Number';
import reverseOrderIcon from '../../../assets/svg/icon-reverseOrder.svg';
import DateItemList from '../../../components/DateItemList';
import ArrowUpCircle from '../../../components/ArrowCircled/ArrowUpCircle';
import ArrowDownCircle from '../../../components/ArrowCircled/ArrowDownCircle';
import IconClose from '../../../components/Icons/IconClose';
import Loader from '../../../components/Loader';
import moment from "moment";
import { errorNotifcation } from "../../../helpers/errorHandle";
import { isSameStringIgnoreCase } from "../../../helpers/stringHelper";
import { SESSION_STORAGE_KEYS } from "../../../constants/constants";
const GenericTable = lazy(() => import("../../../components/Table/GenericTable"));

class Record extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            filter: '',
            pageSize: PAGINATION.DEFAULT_PAGE_SIZE,
            startPage: PAGINATION.DEFAULT_PAGE,
            currentPage: PAGINATION.DEFAULT_PAGE,
            endIndex: null,
            endPage: null,
            pages: [],
            startIndex: null,
            totalItems: null,
            totalPages: null,
            count: null,
            hasMore: true,
            scrollLoading: false,
            bankRecords: [],
            pager: null,
            selectedItem: {},
            displayModal: false,
            loadModalReconcile: false,
            isRecordLoading: false,
            isMoreRecordLoading: false,
            loadingUnreconciledInvoices: false,
            loadingReconciledInvoices: false,
            scanAttachment: '',
            showScanPreview: false,
            showFilePreviewResponsive: false,
            unselectedInvoice: false,
            getReconciled: false,
            getUnReconciled: false,
            displayReconciliation: false,

            periods: [
                { id: 1, value: "12 mois", startDate: moment().subtract(1, 'years').toDate(), endDate: new Date()},
                { id: 2, value: "6 mois", startDate: moment().subtract(6, 'months').toDate(), endDate: new Date()},
                { id: 4, value: "2 mois", startDate: moment().subtract(2, 'months').toDate(), endDate: new Date()},
                { id: 3, value: "3 mois", startDate: moment().subtract(3, 'months').toDate(), endDate: new Date()},
                { id: 5, value: "1 mois", startDate: moment().subtract(1, 'months').toDate(), endDate: new Date()},
            ],
            selectedPeriod: {},
            isReconsileFilter: false,
            isChartVisible: false,
        };
    }

    componentDidMount() {
        this.getAccounts();
    }

    setRefSearchBank = (ref) => {
        this.searchBankRef = ref;
    }

    setRefGenericTable = (ref) => {
        this.genericTable = ref;
    }

    setModalReconcile = (value) => {
        this.setState({
            loadModalReconcile: value,
        });
    }

    handleSearch = (filter) => {

    }

    selectPeriod = (value) => {
        this.setState({
            selectedPeriod: value,
        }, () => {
            this.getRecords( this.props.selectedAccount?.id );
        });
    }

    refreshPeriod = () => {
        this.setState({
            selectedPeriod: null,
        }, () => { this.getRecords( this.props.selectedAccount?.id ); });
    }

    constructParam = (page, accountId) => {
        return {
            pageSize: this.state.pageSize,
            page: page ? page : 1,
            accountId,
            startDate : this.state.selectedPeriod?.startDate ? this.state.selectedPeriod?.startDate : '',
            endDate : this.state.selectedPeriod?.endDate ? this.state.selectedPeriod?.endDate : '',
            isReconciled: this.state.getReconciled ? true : this.state.getUnReconciled ? false : null,
            filter: this.state.filter,
        }
    }

    getAccounts() {
        this.setState({
            isLoading: true,
            isRecordLoading: true,
        });
        const defaultOrg = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
        if (defaultOrg.id) {
            _getOrganizationAccounts(defaultOrg.id).then(res => {
                this.props.setAccounts(res.data); // selectRecord, selectAccount,
                this.getRecords();
            }).catch(error => {
                Logger.error("GetBankAccount", `Error occured during get Bank Account for org ${defaultOrg.id}`, error);
                errorNotifcation(error?.response?.status, "Impossible de récupérer la liste des comptes, veuillez reessayer plus tard");
            }).finally(() => {
                this.setState({
                    isLoading: false,
                });
            })
        }
    }

    getRecords = async (accountId = null, reload = false) => {
        this.setState({ isRecordLoading: !reload });
        const defaultOrg = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
        if (defaultOrg.id) {
            try {
                const page = reload ? this.state.currentPage : 1;
                const res = await _getOrganizationRecords(defaultOrg.id, this.constructParam(page, accountId));
                this.props.setRecords(res.data?.rows);
                this.setPaginationData(res.data);
            } catch (err) {
                Logger.error("GetRecords", `Error occured during get Account records for org ${defaultOrg.id}`, err);
                errorNotifcation(err, "Impossible de récupérer la liste des opérations bancaires, veuillez reessayer plus tard");
            } finally {
                this.setState({ isRecordLoading: false });
            }
        }
    }

    getMoreRecords = async () => {
        try {
            const defaultOrg = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
            this.setState({ isMoreRecordLoading: true });
            const param = this.constructParam(this.state.currentPage + 1, this.props.selectedAccount?.id);
            const res = await _getOrganizationRecords(defaultOrg.id, param);
            this.props.addRecordsMultiple(res.data.rows);
            this.setPaginationData(res.data);
        } catch (e) {
            Logger.error("Get Records", `Error occured during get records`, e);
            errorNotifcation(e, "Impossible de récupérer la liste des enregistrements, veuillez reessayer plus tard");
        } finally {
            this.setState({ isMoreRecordLoading: false });
        }
    }

    setPaginationData(data) {
        this.setState({
            currentPage: data.pager.currentPage,
            endIndex: data.pager.endIndex,
            endPage: data.pager.endPage,
            pageSize: data.pager.pageSize,
            pages: data.pager.pages,
            startIndex: data.pager.startIndex,
            startPage: data.pager.startPage,
            totalItems: data.pager.totalItems,
            totalPages: data.pager.totalPages,
            count: data.count,
        });
    }

    handleSelectRecord = (data, reloadInvoices = true) => {

        var displayReconciliation = data.originalData?.account?.type === ACCOUNT_TYPE.BANQUE;

        this.setState({
            displayReconciliation,
            selectedItem: data.originalData,
            scrollLoading: true,
        });

        try {
            this.props.selectRecord(data.originalData);

            if (displayReconciliation && reloadInvoices) {

                if (this.refReconciliation) {
                    this.refReconciliation.resetFilterFields();
                    this.refReconciliation.hideInvoiceDetail();
                }
                this.props.setReconciledInvoices([]);
                this.getReconciledInvoices(data.originalData.id, true);
                this.getUnreconciledInvoices(true, {}, data.originalData.id);
                this.props.setSelectedInvoice({});
            }

        } catch (error) {
            Logger.error("GetStatement", `Error occured during get records`, error);
            errorNotifcation(error, "Impossible de récupérer la liste des factures, veuillez reessayer plus tard");
        } finally {
            // ...
        }

    }

    renderSense = (item) => {
        if (item.debit) {
            return <ArrowUpCircle size={25} />
        } else {
            return <ArrowDownCircle size={25} />
        }
    }

    headerData = [
        { title: <> Date <img src={reverseOrderIcon} alt="date" width={10} height={10} className="ml-1" /></>, align: "left", className: "pl-15 w-10-percent", name: "date" },
        { title: <> Compte <img src={reverseOrderIcon} alt="compte" width={10} className="ml-1" height={10} /></>, align: "left", className: "pl-15 w-55-percent", name: "accountNumber" },
        { title: <> Description <img src={reverseOrderIcon} alt="description" width={10} className="ml-1" height={10} /></>, align: "left", className: "pl-15 w-55-percent", name: "description" },
        { title: <> Debit <img src={reverseOrderIcon} alt="debit" width={10} height={10} className="ml-1" /></>, align: "left", className: "pl-15 w-25-percent text-align-left sm-hidden", name: "debit" },
        { title: <> Credit <img src={reverseOrderIcon} alt="credit" width={10} height={10} className="ml-1" /></>, align: "left", className: "pl-15 w-25-percent text-align-left sm-hidden", name: "credit" },
    ];

    formatBodyData = (data) => {
        if (data?.length) {
            return data.map((item, i) => {
                let resData = {
                    originalData: item,
                    date: <DateItemList date={item.operationDate ? moment(item.operationDate).format('YYYY/MM/DD') : moment(item.createdAt).format('YYYY/MM/DD')} active={item?.invoices.length} type={'bank'} />,
                    accountNumber: <span>{(item?.account?.bank?.logoSrc ?
                        <img width={17} height={17} alt=" " src={item.account.bank.logoSrc} />
                        :
                        "") + " " + (item?.account.accountNumber || item?.account.accountName)}</span>,
                    description: <span>{item.description}</span>,
                    debit: (item.debit) ? (<span><span className="devise"> {`${item.account.currency} `} </span> {`${numberFormat(item.debit)}`} </span>) : '',
                    credit: (item.credit) ? (<span><span className="devise"> {`${item.account.currency} `} </span> {`${numberFormat(item.credit)}`} </span>) : '',
                }
                return resData;
            });
        }
        return null;
    }

    getReconciledBankRecords = () => {
        this.onCloseReconciliation();
        this.setState({
            getReconciled: !this.state.getReconciled,
            getUnReconciled: false,
        }, () => {
            this.getRecords(this.props.selectedAccount?.id);
        });
    }

    getUnreconciledBankRecords = () => {
        this.onCloseReconciliation();
        this.setState({
            getUnReconciled: !this.state.getUnReconciled,
            getReconciled: false,
        }, () => {
            this.getRecords(this.props.selectedAccount?.id);
        })
    }

    handleSelectBankAccount = (data) => {
        this.props.selectAccount(data);
        const isBankAccount = isSameStringIgnoreCase(data?.type, ACCOUNT_TYPE.BANQUE);
        this.setState({
            getUnReconciled: isBankAccount ? this.state.getUnReconciled : false,
            getReconciled: isBankAccount ? this.state.getReconciled : false,
        }, () => {
            this.getRecords(data?.id);
        });
    }

    toggleChart = () => {
        this.setState({ isChartVisible: !this.state.isChartVisible });
    }
    renderBtnChartToggler = () => {
        if (this.state.isChartVisible) {
            return "Hide chart";
        }
        return "Show chart"
    }

    setRefReconciliation = (ref) => {
        this.refReconciliation = ref;
    }

    onCloseReconciliation = () => {
        if (this.genericTable) this.genericTable.unSelectAll();
        this.setState({
            displayReconciliation: false,
            selectedItem: null,
            loadModalReconcile: false,
            showScanPreview: false,
            scanAttachment: '',
            unreconciledInvoices: {},
            loadingUnreconciledInvoices: false,
        })
    }

    reloadRecords = async () => {
        await this.getRecords(null, true);
        this.reloadSelectedRecord(false);
    }

    reloadSelectedRecord = (isReconciledBankRecord = true) => {
        const organization = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
        const selectedRecord = this.props.selectedRecord || this.state.selectedItem;
        if (selectedRecord) {
            _getRecordById(organization.id, selectedRecord.id).then((res) => {
                this.props.selectRecord(res.data);
                if (isReconciledBankRecord) this.props.setReconciledBankRecordItem(res.data);
                if (this.genericTable && !isReconciledBankRecord) this.genericTable.selectByValue(res.data);
            }).catch((err) => {
                Logger.error(`Can't get a bank record by id - ${selectedRecord.id}`, err);
            });
        }

    }

    getUnreconciledInvoices = (loading, param, bankRecordId) => {
        let organization = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
        const params = {
            ...param,
            getUnreconciled: true,
            bankFilter: true,
            bankRecordId,
        }
        if (organization) {
            this.setState({
                loadingUnreconciledInvoices: !!loading,
            }, () => {
                _getInvoicesByParams(organization.id, params).then((res) => {
                    this.props.updateUnreconciledInvoices(res.data.rows);
                    this.setState({
                        loadingUnreconciledInvoices: false,
                    });
                }).catch((err) => {
                    Logger.error(`Can't get invoices unlinked to a bank record, id - ${bankRecordId}`, err);
                });
            })
        }
    }

    getReconciledInvoices = (bankRecordId, loading) => {
        let organization = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
        if (organization) {
            this.setState({
                loadingReconciledInvoices: !!loading,
            }, () => {
                _getInvoicesByParams(organization.id, { bankRecordId, bankFilter: true }).then((res) => {
                    this.props.setReconciledInvoices(res.data.rows);
                    this.setState({
                        loadingReconciledInvoices: false,
                    });
                }).catch((err) => {
                    Logger.error(`Can't get invoices linked to a bank record, id - ${bankRecordId}`, err);
                });
            })
        }
    }

    reloadAllInvoices = (bankRecordId, param) => {
        this.setState({
            loadModalReconcile: true,
            showScanPreview: false,
            scanAttachment: '',
        }, () => {
            this.getReconciledInvoices(bankRecordId);
            this.getUnreconciledInvoices(false, param, bankRecordId);
            this.reloadSelectedRecord();
            this.setState({
                loadModalReconcile: false,
            });
        });
    }

    togglePreviewScan = (attachment, unselectedInvoice, contentType) => {
        this.setState(() => ({
            scanAttachment: attachment,
            showScanPreview: true,
            unselectedInvoice: !!unselectedInvoice,
            contentType,
        }));
    }

    closePreviewScan = () => {
        if (!this.state.unselectedInvoice) this.props.setSelectedInvoice({});
        this.setState({
            showScanPreview: false,
            scanAttachment: '',
        })
    }

    renderPreviewScan = () => {
        let { showScanPreview, scanAttachment, contentType } = this.state;
        if (scanAttachment) {
            return (
                <div className={`file-prefiew ${showScanPreview ? "show fade-in" : "d-none"}`}>
                    {
                        contentType === ContentTypes.pdf ?
                            <div className="preview-file-container">
                                <button className='btn-close' onClick={this.closePreviewScan} type="button"><IconClose color="#FFFFFF" /></button>
                                <object data={scanAttachment} 
                                    width='100%'
                                    height='100%'
                                    type="application/pdf"
                                >
                                </object>
                            </div> 
                        :
                        <div className="preview-file-container">
                            <CustomImageZoom image={scanAttachment} alt='Scan image' allowCloser onClose={this.closePreviewScan} />
                        </div>
                    }   
                </div>
            )
        }
    }

    render() {
        return (
            <div className="bank">
                <div className='fluid-content-data'>
                    <div className="d-flex align-items-center justify-content-between pl-3 pr-3 mt-3">
                        <div className="d-flex align-items-center justify-content-between">
                            {!this.state.isLoading ? <ListBankAccounts data={this.props.accounts} onClick={this.handleSelectBankAccount} className="min-width-medium" /> : <></>}
                            {(!this.state.isLoading && this.props.selectedAccount?.id) ? <span className="txt-small bold ml-2 mr-2 txt-grey"> Solde - {numberFormat(this.props.selectedAccount.currentBalance)} {this.props.selectedAccount.currency}</span> : <></>}
                            {this.state.isLoading ? <Loader style={{ width: "25px", height: "25px" }} enable /> : <></>}
                        </div>
                        <div className="d-flex align-items-center">
                            <button className="btn btn-default btn-toggle-chart mr-2" onClick={this.toggleChart}>{this.renderBtnChartToggler()}</button>
                            <div className="period-box">
                                <PeriodDropdown
                                    onRe={this.setPeriodDropdownRef} 
                                    options={this.state.periods} 
                                    optionKey={'value'} 
                                    defaultValue={this.state.selectedPeriod?.value}
                                    placeholder="Période" 
                                    onSelect={this.selectPeriod}
                                    onCancel={this.refreshPeriod}
                                />
                            </div>
                        </div>

                    </div>
                    {this.state.isChartVisible ?
                        <div className="chart-box pl-3 pr-3 mt-3">
                            <div className="chart-content"></div>
                        </div> :
                        <></>
                    }
                    <div className="d-flex align-items-center justify-content-between pl-3 pr-3 mt-3">
                        <SearchBankRecord
                            onRef={this.setRefSearchBank}
                            handleSearch={this.handleSearch}
                            getReconciled={this.getReconciledBankRecords}
                            getUnreconciled={this.getUnreconciledBankRecords}
                            reconcilitaionFilter={!isSameStringIgnoreCase(this.props.selectedAccount?.type, ACCOUNT_TYPE.CAISSE)}
                            isGetUnreconciled={this.state.getUnReconciled}
                            isGetReconciled={this.state.getReconciled}
                        />
                    </div>
                    <div className='records mt-2 pl-lg-3 pr-lg-3'>
                        <Suspense fallback={<div className="text-center">Chargement... </div>} >
                            <GenericTable
                                data={[...this.props.records]}
                                onRef={this.setRefGenericTable}
                                header={this.headerData}
                                body={this.formatBodyData(this.props.records)}
                                className='mlr-1'
                                isLoading={this.state.isRecordLoading}
                                asLoader={<Loader style={{ width: "25px", height: "25px" }} enable />}
                                onSelect={this.handleSelectRecord}
                                infiniteScroll
                                getData={this.getMoreRecords}
                                hasMore={(this.state.currentPage < this.state.endPage)}
                                scrollLoading={this.state.isMoreRecordLoading}
                            />
                        </Suspense>
                    </div>
                    {this.renderPreviewScan()}
                </div>
                { this.state.displayReconciliation ?
                    <div className={'bank-detail'}>
                        <DisplayContainer headerTile={this.state.selectedItem ? this.state.selectedItem.description : ''} onClose={this.onCloseReconciliation} loading={this.state.loadModalReconcile} >
                            <Reconciliation
                                onRef={this.setRefReconciliation}
                                item={this.state.selectedItem}
                                loadingUnreconciledInvoices={this.state.loadingUnreconciledInvoices}
                                loadUnreconciledInvoices={this.getUnreconciledInvoices}
                                loadingReconciledInvoices={this.state.loadingReconciledInvoices}
                                loadReconciledInvoices={this.getReconciledInvoices}
                                reloadAllInvoices={this.reloadAllInvoices}
                                onInvoiceItemClicked={this.togglePreviewScan}
                                attachmentDisplayed={this.state.showScanPreview}
                                closeAttachmentDisplay={this.closePreviewScan}
                                refreshAllRecords={this.reloadRecords}
                                setModalReconcile={this.setModalReconcile}
                            />
                        </DisplayContainer>
                    </div> : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth.authData,
        organizations: state.organizations.organizations,
        bankRecords: state.organizations.organizationBankRecords.data,
        defaultOrg: state.organizations.defaultOrganization,
        pager: state.organizations.organizationBankRecords.pager,
        accounts: state.accounts.accounts,
        records: state.accounts.records,
        selectedAccount: state.accounts.selectedAccount,
        selectedRecord: state.accounts.selectedRecord,
    }
}

export default connect(mapStateToProps, {
    setOrganizationBankRecords, addMultipleOrganizationBankRecords, updateUnreconciledInvoices, setReconciledInvoices,
    setSelectedInvoice, addOrganizationBankRecords, setReconciledBankRecordItem,
    setAccounts, setRecords, addRecordsMultiple, selectRecord, selectAccount,
})(Record)
