import React from "react";

const ArrowUpCircle = (props) => (
  <svg viewBox="0 0 26.604 26.604" width={props.size ? props.size : 26.604} height={props.size ? props.size : 26.604}>
    <defs>
      <style>
        {
          ".a,.b{fill:none;stroke:#af2216;stroke-width:1.5px;}.a{stroke-linecap:round;stroke-linejoin:round;}.b{stroke-miterlimit:10;}"
        }
      </style>
    </defs>
    <g transform="translate(-63.25 -63.25)">
      <path
        fill="none"
        stroke={props.color ? props.color : "#af2216"}
        strokeWidth={props.strokeWidth ? props.strokeWidth : 1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M176,175.189,181.23,170l5.23,5.189m-5.23-4.468v10.523"
        transform="translate(-104.678 -99.07)"
      />
      <path
        fill="none"
        stroke={props.color ? props.color : "#af2216"}
        strokeWidth={props.strokeWidth ? props.strokeWidth : 1.5}
        strokeMiterlimit={10}
        d="M89.1,76.552A12.552,12.552,0,1,0,76.552,89.1,12.555,12.555,0,0,0,89.1,76.552Z"
      />
    </g>
  </svg>
);

export default ArrowUpCircle;
