import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import EditableInput from '../EditableInput';
import Key from '../Icons/KeyIcon';
import IconBank from '../Icons/BankComponent';
import NumberSecu from '../Icons/NumSecuIcon';
import Numb from '../Icons/NumberIcon';
import './CardBankPreview.css';

class CardBankPreview extends Component {

    constructor(props){
        super(props);
        this.state = {};

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick = (event) => {
        const bankInfos = {
            index: this.props.index,
            name: this.props.name,
            bankCode: this.props.bankCode,
            compteNum: this.props.compteNum,
            guichetCode: this.props.guichetCode,
            ribKey: this.props.ribKey,
            active: this.props.active,
        }
        event = {
            ...event,
            bankInfos
        }
        if(this.props.onClick){
            this.props.onClick(event);
        }
    }

    render(){
        
        return(
            <div className={`row card-bank-preview ${ this.props.active ? 'active' : ''}`} style={{ backgroundColor:this.props.active ? 'rgba(23,123,209,.05)' : '#F8F8F8' }} onClick={this.handleClick}>
                <div className="col-md-12">

                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <EditableInput type="text" name="bankName" value={`${this.props.name}`} className="ml-2 small bold" onBlur={event => {}} state={'preview'} withIcon={<IconBank />} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="label-input">{`Code banque`}</div>
                            <EditableInput type="text" name="bankCode" value={`${this.props.bankCode}`} className="ml-2 small bold" onBlur={event => {}} state={'preview'} withIcon={<Numb active />} />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="label-input">{`Code guichet`}</div>
                            <EditableInput type="text" name="guichetCode" value={this.props.guichetCode} className="ml-2 small bold" onBlur={event => {}} state={'preview'} withIcon={<Numb active />} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="label-input">{`Numero de compte`}</div>
                            <EditableInput type="text" name="compteNum" value={`${this.props.compteNum}`} className="ml-2 small bold" onBlur={event => {}} state={'preview'} withIcon={<NumberSecu active />} />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="label-input">{`Clef RIB`}</div>
                            <EditableInput type="text" name="ribKey" value={`${this.props.ribKey}`} className="ml-2 small bold" onBlur={event => {}} state={'preview'} withIcon={<Key width={12} height={12} active />} />
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }
}

CardBankPreview.propTypes = {
    fullName: PropTypes.string,
    index: PropTypes.number,
    name: PropTypes.string,
    bankCode: PropTypes.number,
    compteNum: PropTypes.string,
    guichetCode: PropTypes.string,
    ribKey: PropTypes.number,
    active: PropTypes.bool
}

export default CardBankPreview;