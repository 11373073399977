import React, { Component } from "react"
import PropTypes from "prop-types"
import "./MainContent.css"

class MainContent extends Component {
    constructor(props){
        super(props)
        this.state = {
            className: this.props.className
        }
    }

    render(){
        const { children } = this.props
        const { className } = this.state
        return (
            <div className={"main-content " + className}>
               {children}
            </div>
        )
    }
}

MainContent.propTypes = {
    children: PropTypes.node
}

export default MainContent