import React from "react";

const IoniconsSvgIosContactComponent = (props) => (
  <svg width={props.size ? props.size : 23.717} height={props.size ? props.size : 23.717} viewBox="0 0 23.717 23.717">
    <defs>
    </defs>
    <path
      fill={`${props.color ? props.color : '#b5b5b5'}`}
      d="M59.859,48a11.853,11.853,0,0,0-11.79,10.61,11.428,11.428,0,0,0,0,2.5A11.857,11.857,0,1,0,59.859,48ZM67.6,66.592c-1.294-.49-3.392-1.209-4.7-1.6-.137-.04-.154-.051-.154-.61a3.244,3.244,0,0,1,.376-1.328,6.626,6.626,0,0,0,.525-1.8,4.006,4.006,0,0,0,.775-1.876,2.5,2.5,0,0,0-.023-1.574,1.011,1.011,0,0,1-.034-.1,10.252,10.252,0,0,1,.177-2.212,4.073,4.073,0,0,0-.849-2.925,4.358,4.358,0,0,0-3.335-1.6h-1a4.352,4.352,0,0,0-3.307,1.6A4.056,4.056,0,0,0,55.2,55.5a10.252,10.252,0,0,1,.177,2.212c-.011.04-.023.068-.034.1a2.46,2.46,0,0,0-.023,1.574,4.068,4.068,0,0,0,.775,1.876,6.9,6.9,0,0,0,.525,1.8,3.185,3.185,0,0,1,.217,1.346c0,.564-.023.57-.148.61-1.351.4-3.358,1.106-4.561,1.585A10.262,10.262,0,1,1,67.6,66.592Z"
      transform="translate(-48 -48)"
    />
  </svg>
);

export default IoniconsSvgIosContactComponent;
