import React from "react";

const IoniconsSvgIosArrowBackComponent = (props) => (
  <svg width={props.width ? props.width : 5.171} height={props.height ? props.height : 9.046} viewBox="0 0 5.171 9.046">
    <defs>
      {
        props.color ? <style>{`.goback{fill: ${props.color}}`}</style> : <style>{".goback{fill:#707070;}"}</style>
      }
      
    </defs>
    <path
      fill={` ${props.color ? props.color : "#FFFFFF"}`}
      d="M161.567,92.609l3.423-3.42a.646.646,0,1,0-.916-.913L160.2,92.151a.645.645,0,0,0-.019.891l3.894,3.9a.646.646,0,1,0,.916-.913Z"
      transform="translate(-160.008 -88.087)"
    />
  </svg>
);

export default IoniconsSvgIosArrowBackComponent;
