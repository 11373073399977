import React from "react";

const ComponentComponent = (props) => (
  <svg width={props.width ? props.width : 22} height={props.height ? props.height : 15} viewBox="0 0 22 15">
    <defs>
      <style>
        {
          `.ab,.cb{fill:none;}.bb{clip-path:url(#ab);}.${props.numClass},.${props.borderClass}{fill:${props.active ? '#177BD1;' :'#b5b5b5;'}}.eb,.fb{stroke:none;}`
        }
      </style>
      
        <rect width={22} height={15} fill="none" stroke={`${props.active ? props.active : '#177BD1;'}`} strokeWidth={0.5} />
      
    </defs>
    <g className="bb">
      <g className="cb">
        <path
          className="eb"
          d="M1,0H21a1,1,0,0,1,1,1V14a1,1,0,0,1-1,1H1a1,1,0,0,1-1-1V1A1,1,0,0,1,1,0Z"
        />
        <path
          className={props.borderClass}
          d="M 1 0.5 C 0.7243003845214844 0.5 0.5 0.7243003845214844 0.5 1 L 0.5 14 C 0.5 14.27569961547852 0.7243003845214844 14.5 1 14.5 L 21 14.5 C 21.27569961547852 14.5 21.5 14.27569961547852 21.5 14 L 21.5 1 C 21.5 0.7243003845214844 21.27569961547852 0.5 21 0.5 L 1 0.5 M 1 0 L 21 0 C 21.55228042602539 0 22 0.4477100372314453 22 1 L 22 14 C 22 14.55228042602539 21.55228042602539 15 21 15 L 1 15 C 0.4477195739746094 15 0 14.55228042602539 0 14 L 0 1 C 0 0.4477100372314453 0.4477195739746094 0 1 0 Z"
        />
      </g>
      <path
        className={props.numClass}
        d="M-236.4-1348.66v-1.17h1.741a1.3,1.3,0,0,0,1.094-.46,2.24,2.24,0,0,0,.355-1.381,3.379,3.379,0,0,1-.72.135q-.4.035-.95.035a2.137,2.137,0,0,1-1.51-.435,1.681,1.681,0,0,1-.45-1.265v-1.059a1.657,1.657,0,0,1,.485-1.32,1.991,1.991,0,0,1,1.345-.42h1.07a2.22,2.22,0,0,1,1.239.315,1.982,1.982,0,0,1,.731,1.115,7.77,7.77,0,0,1,.249,2.22,4.822,4.822,0,0,1-.7,2.97,2.523,2.523,0,0,1-2.1.88A9.449,9.449,0,0,1-236.4-1348.66Zm1.21-5.846a.739.739,0,0,0-.17.545v.48a.668.668,0,0,0,.205.571,1.213,1.213,0,0,0,.695.149h1.25a4.983,4.983,0,0,0-.105-1.165,1.013,1.013,0,0,0-.325-.581.962.962,0,0,0-.59-.165h-.45A.707.707,0,0,0-235.19-1354.505Zm-13.5,5.975v-6.05l-1.31.17v-.95l1.471-.64h1.319v7.47Zm7.65-2.24.28-1.37.73.19-.48,1.35Zm-4.32,0,.28-1.37.73.19-.48,1.35Zm5.3-.529.4-.64,1.16.879-.33.44Zm-4.32,0,.4-.64,1.16.879-.33.44Zm4.829-.97,1.17-.81.32.44-1.01.959Zm-4.32,0,1.17-.81.32.44-1.01.959Zm2.271-.07v-.55l1.4-.15.03.751Zm-4.32,0v-.55l1.4-.15.03.751Zm5.63-1.49.53-.17.62,1.249-.71.281Zm-4.32,0,.53-.17.62,1.249-.71.281Z"
        transform="translate(252 1360)"
      />
    </g>
  </svg>
);

export default ComponentComponent;
