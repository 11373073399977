import React, { Component } from 'react'
import Payslip from './SocialPage/Payslip'

class SocialPage extends Component {
    renderPage = () => {
        let { match } = this.props
        switch(match.params.page){
            case 'payslip':
                return <Payslip routes={match} />
            case 'social-security':
                return null
            case 'absence':
                return null
            default: return <Payslip />
        }
    }
    render() {
        return(
            <div className="flex-part">
                {this.renderPage()}
            </div>
        )
    }
}

export default SocialPage