import React, { Component } from 'react';
import { connect } from "react-redux";
import IconEuro from '../Icons/IconEuro'
import IconCategory from '../Icons/CategoryIcon'
import IconEchelon from '../Icons/EchelonIcon'
import IconPayment from '../Icons/EuroPaymentComponent'
import HousingAllowance from '../Icons/HousingAllowanceComponent';
import TransportAllowance from '../Icons/TransportAllowanceComponent';
import IconTime from '../Icons/TimeIcon'
import EditableInput from '../EditableInput';
import { numberFormat } from '../../helpers/Number';
import AllowancesManager from "../AllowancesManager";
import "simple-react-validator/dist/locale/fr";
import SimpleReactValidator from "simple-react-validator";
import { setPayFormValid } from '../../redux/action/ValidationAction';

class Pay extends Component {
    constructor(props){
        super(props)
        this.state = {
            focusedSalaire: false,
            focusedCategory: false,
            focusedEchelon: false,
            focusedTime: false,
        }

        this.validator = new SimpleReactValidator({autoForceUpdate: this, locale: 'fr'});
    }

    constructContractAllowances(allowance, proratization = false){
        return {
            amount: allowance.amount,
            proratization,
            allowanceId: allowance.id,
            allowance: allowance
        }
    }

    focusSalaire = () => {
        this.setState({
            focusedSalaire  : !this.state.focusedSalaire
        })
    }
    focusCategory = () => {
        this.setState({
            focusedCategory  : !this.state.focusedCategory          
        })
    }
    focusEchelon = () => {
        this.setState({
            focusedEchelon  : !this.state.focusedEchelon            
        })
    }
    focusTime = () => {
        this.setState({
            focusedTime  : !this.state.focusedTime            
        })
    }

    onBlurSalary = async (e) => {
        const data = {brutSalaryIndicator: +e.target.value, contractAllowances: this.props.allowances, updateDefault: true}
        await this.props.onBlurSalary(data);
    }

    onChangeSalary = event => {
        if (this.props.onChangeSalary) {
            this.props.onChangeSalary(event);
        }
    }

    onChangeTime(e) {
        this.props.onChangeTime(e)
    }

    validPayForm = (noShowMessage) => {
        if (this.validator.allValid()) {
            this.props.setPayFormValid(true);
            this.validator.purgeFields();
            return true;
        } else {
            this.props.setPayFormValid(false);
            if(!noShowMessage) this.validator.showMessages();
            return false;
        }
    }
    resetPayForm = () => {
        this.validator.purgeFields();
        this.validator.hideMessages();
        this.props.setPayFormValid(false);
    }

    componentDidMount = () => {
        this.props.onRef(this);
        this.resetPayForm();
    }
    componentWillUnmount = () => {
        this.props.onRef(undefined);
    }

    checkAllowance = (allowance, allowances) => {
        if(!allowance.allowanceId || !allowance.amount) return false;
        if(allowances.length > 0){
            const match = allowances.find((item) => { return item.allowanceId === allowance.allowanceId });
            return match ? false : true;
        }
        return true;
    }

    addPayslipAllowance = async (parentId, item, name, updateDefault) => {
        delete item.id;
        const allowance = {name};
        item = {...item, allowance};
        if(this.checkAllowance(item, this.props.allowances)){
            const newAllowances = [...this.props.allowances, item];
            return await this.computePayslipAllowance(newAllowances, updateDefault);
        }
    }

    updatePayslipAllowance = async (parentId, item, name, updateDefault) => {
        const allowance = {name};
        item = {...item, allowance};
        let { allowances } = this.props;
        const index = allowances.findIndex((allowance) => { return allowance.allowanceId === item.allowanceId });
        if(index >= 0) allowances[index] = item;
        return await this.computePayslipAllowance(allowances, updateDefault);
    }

    deletePayslipAllowance = async (id, parentId, updateDefault) => {
        let { allowances } = this.props;
        const index = allowances.findIndex((allowance) => { return allowance.allowanceId === id});
        allowances.splice(index, 1);
        return await this.computePayslipAllowance(allowances, updateDefault);
    }

    computePayslipAllowance = async (allowances, updateDefault) => {
        if(this.props.setAllowances){
            // this.props.setAllowances(allowances);
            const result = await this.props.onBlurSalary({brutSalaryIndicator: this.props.brutSalaryIndicator, contractAllowances: allowances, updateDefault});
            return result;
        } else {
            return false;
        }
    }

    errorMsg = (error) => {
        return this.props.formPayValid ? "" : error;
    }

    renderNewPay = () => {
        return(
            <div className='px-0'>
                
                <div className="header-preview">
                    <div className="d-flex align-items-center">
                        <div className="col-md-12 col-sm-12">
                            <div className="label-input">Salaire brut </div>
                            <div className='d-flex EditableInput align-items-center'>
                                <span className="ico-content mr-1"> <IconEuro  active /> </span>
                                <span className="valuePreview mr-2">{+this.props.brutSalary ? numberFormat(this.props.brutSalary) : ''}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-preview">
                    <div className="row pt-1 pl-3">
                        <div className="col-md-12 col-sm-12">
                            <p className='payTitle'>Salaire de base indicatif <span className="required ml-1">*</span></p>
                            <EditableInput type="number" name="brutSalary" value={this.props.brutSalaryIndicator ? this.props.brutSalaryIndicator : ""} globalClassName="min-width150"
                                onBlur={this.onBlurSalary} onChange={this.onChangeSalary}
                                placeholder="0"
                                error={{msg: this.errorMsg(this.validator.message('salaire de base indicatif', this.props.brutSalaryIndicator, 'required|numeric|min:1,num')), placement: "top"}}
                                className="header-preview-title pl-30-10" editable state={'preview'} 
                            />
                        </div>
                    </div>
                </div>
                <div className="header-preview">
                    <div className="d-flex align-items-center mt-1">
                        <span className="ico-content pr-1"></span>
                        <div className="d-flex label-input align-items-center">
                            <span className="ico-content mr-1"> <IconCategory width={18} height={18} active /> </span>
                            <span className="txt-primary bold mr-3">{this.props.category ? this.props.category : "1"}</span> 
                            <span className="ico-content mr-1"> <IconEchelon width={18} height={18} active /> </span>
                            <span className="txt-primary bold ">{this.props.echelon ? this.props.echelon : "A"}</span> 
                        </div>
                    </div>

                    <div className="row pt-1 pl-3">
                        <div className="col-md-5 col-sm-12">
                            <div className="label-input">Salaire de base </div>
                            <div className='d-flex EditableInput align-items-center'>
                                <span className="ico-content mr-1"> <IconEuro  active /> </span>
                                <span className="valuePreview mr-2">{+this.props.baseSalary ? numberFormat(+this.props.baseSalary) : 0}</span>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="label-input">Sur-salaire </div>
                            <div className='d-flex EditableInput align-items-center'>
                                <span className="ico-content mr-1"> <IconPayment  active /> </span>
                                <span className="valuePreview mr-2">{+this.props.surSalary ? numberFormat(+this.props.surSalary) : 0}</span> 
                            </div>
                            
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="label-input">Horaire hebdo. </div>
                            <EditableInput type="number" name="weeklyTime" value={this.props.time ? this.props.time : 40} className="pl-30-10" 
                                state={'preview'} editable withIcon={<IconTime  active />} 
                                error={{msg: this.errorMsg(this.validator.message('horaires', this.props.time, 'required|numeric|min:1,num')), placement: "top"}}
                                onChange={this.props.onChangeTime} required
                            />
                        </div>
                    </div>
                </div>
                <div className="content-preview">
                    <div className="row pt-1 pl-3">
                        <div className="col-md-6 col-sm-12">
                            <div className="label-input">Indemnité de transport</div>
                            <div className='d-flex EditableInput align-items-center'>
                                <EditableInput type="number" name="transportAllowance" value={this.props.transportAllowance ? +this.props.transportAllowance : ""}
                                placeholder="0" className="pl-30-10" state={'preview'}  globalClassName="min-width150"
                                withIcon={<TransportAllowance />}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                                <div className="label-input">Indemnité de logement</div>
                                <div className='d-flex EditableInput align-items-center'>
                                <EditableInput type="number" name="housingAllowance" value={this.props.housingAllowance ? +this.props.housingAllowance : ""}
                                    placeholder="0" className="pl-30-10" state={'preview'}  globalClassName="min-width150"
                                    withIcon={<HousingAllowance active />}
                                />
                            </div>
                        </div>
                        <div className="row pt-1 pr-3">
                            <div className="col-md-12 col-sm-12">
                                <AllowancesManager 
                                    allowances={this.props.allowances} 
                                    objectID={{contractId: 10000000000}} 
                                    saveAllowances={this.addPayslipAllowance}
                                    updateAllowances={this.updatePayslipAllowance}
                                    deleteAllowance={this.deletePayslipAllowance}
                                />
                            </div>
                        </div>
                        <hr/>
                </div>
            </div>
        </div>
        )
    }

    render() {
        
        return(
            this.renderNewPay()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        defaultOrganization: state.organizations.defaultOrganization,
        formPayValid: state.validation.formPayValid,
    }
}

export default connect(mapStateToProps, { setPayFormValid })(Pay)