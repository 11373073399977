import React from "react";

const GroupComponent = (props) => (
  <svg className={props.className ? props.className : ''} width={8.673} height={7.552} viewBox="0 0 8.673 7.552">
    <defs>
      <style></style>
    </defs>
    <g transform="translate(0 0)">
      <path
        fill="#b5b5b5"
        d="M164.9,114.836a.343.343,0,0,1-.483,0l-1.6-1.592v6.067a.341.341,0,0,1-.682,0v-6.067l-1.6,1.595a.345.345,0,0,1-.483,0,.34.34,0,0,1,0-.48l2.177-2.161a.383.383,0,0,1,.108-.071.326.326,0,0,1,.131-.026.342.342,0,0,1,.239.1l2.177,2.161A.334.334,0,0,1,164.9,114.836Z"
        transform="translate(-156.325 -112.1)"
      />
      <path
        fill="#b5b5b5"
        d="M4.94,2.736a.343.343,0,0,1-.483,0L2.86,1.146V7.213a.341.341,0,0,1-.682,0V1.146L.581,2.741a.345.345,0,0,1-.483,0,.34.34,0,0,1,0-.48L2.278.1A.383.383,0,0,1,2.385.026.326.326,0,0,1,2.516,0a.342.342,0,0,1,.239.1L4.932,2.258A.334.334,0,0,1,4.94,2.736Z"
        transform="translate(5.036 7.551) rotate(180)"
      />
    </g>
  </svg>
);

export default GroupComponent;
