import React, { Component } from 'react';
import DashboardLayout from '../Dashboard/DashboardLayout';
import BankContent from './BankContent';

export default class Bank extends Component {
    render() {
        return(
            <DashboardLayout title={'Comptes'}>
                <BankContent {...this.props} />
            </DashboardLayout>
        )
    }
}