import { SET_MAIN_COLOR } from '../action-type/themeType';

const INITIAL_STATE = {
    mainColor: "#B5B5B5",
};

const ThemeReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SET_MAIN_COLOR:
            return { ...state, mainColor: action.payload };
        default:
            return state;
    }
}

export default ThemeReducer;