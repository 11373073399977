import { VALID_FORM_CIVIL, VALID_FORM_CONTRACT, VALID_FORM_COORD, VALID_FORM_PAY } from '../action-type/validateType';

export const setCivilFormValid = (payload) => ({
    type: VALID_FORM_CIVIL,
    payload
});

export const setCoordFormValid = (payload) => ({
    type: VALID_FORM_COORD,
    payload
});

export const setContractFormValid = (payload) => ({
    type: VALID_FORM_CONTRACT,
    payload
});

export const setPayFormValid = (payload) => ({
    type: VALID_FORM_PAY,
    payload
});