import React, { Component } from 'react';
import FeesContent from "./FeesContent";
import DashboardLayout from '../Dashboard/DashboardLayout';


export default class Fees extends Component {
    render() {
        return(
            <DashboardLayout title={'Frais'}>
                <FeesContent {...this.props}/>
            </DashboardLayout>
        )
    }
}