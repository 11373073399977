import axios from "../config/axios";

export const _saveBill = async (orgId, data) => {
    return await axios.post(`/organizations/${orgId}/bills`, data);
};

export const _updateBill = async (orgId, data) => {
    return await axios.put(`/organizations/${orgId}/bills`, data);
};

export const _generateBillTitle = async (orgId) => {
    return await axios.get(`/organizations/${orgId}/bills/generate-title`);
};
