import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Auth from './pages/Auth';
import './App.css';
import './App-animations.css';
import NewPassword from './pages/NewPassword/NewPassword';

class App extends Component {
  constructor(props){
    super(props)
    this.state = {
      authState: 'SIGNIN',
      authResult: null
    }
  }

  changeAuthState = (authState, authResult) => {
    this.setState({
      authState,
      authResult
    });
  }

  render() {
    let { authState, authResult } = this.state;
    switch(authState) {
      case 'SIGNIN': return (
        <div className="App">
          <Auth changeAuthState={this.changeAuthState}/>
        </div>
      );

      case 'NEWPASSWORD': return (
        <div className="App">
          <NewPassword authResult={ authResult } changeAuthState={this.changeAuthState}/>
        </div>
      );

      case 'REDIRECT': return <Redirect to={"/organizations"} />;

      default: return (
        <div className="App">
          <span style={{textAlign: "center"}} >The page you are looking for does not exist</span>
        </div>
      );
    }
  }
  
}

export default App;
