/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const BankTransferComponent = (props) => (
  <svg width={props.width ? props.width : 19.198} height={props.height ? props.height : 19.198} viewBox="0 0 19.198 19.198">
    <defs>
      <style>
        {`.bta{fill:${props.active ? '#177BD1;': '#b5b5b5;'}}.btb{fill:${props.active ? '#177BD1;':'#ababab;'}}.btc{fill:#fff;}.btd{fill:${props.active ? '#177BD1;': '#e1e1e3;'}}`}
      </style>
    </defs>
    <g transform="translate(-6.875 -6.875)">
      <g transform="translate(6.875 6.875)">
        <path
          className="bta"
          d="M258.509,317.506h5.444v1.737l3.15-3.15-3.15-3.151v1.737h-5.444"
          transform="translate(-247.906 -300.045)"
        />
        <path
          className="btb"
          d="M387.709,312.942V314.1l1.992,1.992-1.992,1.992v1.159l3.15-3.15Z"
          transform="translate(-371.662 -300.045)"
        />
        <path
          className="bta"
          d="M15.509,11.439H10.026v1.737l-3.15-3.151,3.15-3.15V8.612h5.483"
          transform="translate(-6.875 -6.875)"
        />
        <circle
          className="btc"
          cx={5.987}
          cy={5.987}
          r={5.987}
          transform="translate(3.612 3.612)"
        />
        <path
          className="btd"
          d="M214.927,92.587a6.033,6.033,0,0,0-.868.063,5.988,5.988,0,0,1,0,11.849,5.987,5.987,0,1,0,.869-11.911Z"
          transform="translate(-205.328 -88.975)"
        />
        <path
          className="bta"
          d="M91.988,98.265a6.277,6.277,0,1,1,6.277-6.277A6.284,6.284,0,0,1,91.988,98.265Zm0-11.975a5.7,5.7,0,1,0,5.7,5.7A5.7,5.7,0,0,0,91.988,86.291Z"
          transform="translate(-82.39 -82.389)"
        />
        <path
          className="bta"
          d="M163.338,133.415l2.752-2.4a.386.386,0,0,1,.507,0l2.752,2.4Z"
          transform="translate(-156.745 -125.697)"
        />
        <path
          className="bta"
          d="M221.758,131.019a.386.386,0,0,0-.507,0l-.326.284,2.427,2.113h1.159Z"
          transform="translate(-211.905 -125.697)"
        />
        <path
          className="bta"
          d="M175.405,201.591a.29.29,0,0,1-.29-.29V197.38a.29.29,0,1,1,.579,0V201.3a.29.29,0,0,1-.29.29Zm2.318,0a.29.29,0,0,1-.29-.29V197.38a.29.29,0,0,1,.579,0V201.3a.29.29,0,0,1-.29.29Zm-4.635,0a.29.29,0,0,1-.29-.29V197.38a.29.29,0,0,1,.579,0V201.3a.29.29,0,0,1-.29.29Zm1.159,0a.29.29,0,0,1-.29-.29V197.38a.29.29,0,1,1,.579,0V201.3a.29.29,0,0,1-.29.29Zm2.318,0a.29.29,0,0,1-.29-.29V197.38a.29.29,0,0,1,.579,0V201.3a.29.29,0,0,1-.29.29Z"
          transform="translate(-165.806 -189.075)"
        />
        <path
          className="bta"
          d="M163.338,190.046h6.012v.773a.386.386,0,0,1-.386.386h-5.239a.386.386,0,0,1-.386-.386Z"
          transform="translate(-156.745 -182.327)"
        />
        <path
          className="bta"
          d="M269.731,190.046v.773a.386.386,0,0,1-.386.386H270.5a.386.386,0,0,0,.386-.386v-.773Z"
          transform="translate(-258.285 -182.327)"
        />
        <path
          className="bta"
          d="M169.35,285.366h-6.012l.3-.9a.386.386,0,0,1,.366-.263h4.679a.386.386,0,0,1,.366.263Z"
          transform="translate(-156.745 -272.521)"
        />
        <path
          className="bta"
          d="M264.215,284.47a.386.386,0,0,0-.366-.263H262.69a.386.386,0,0,1,.366.263l.3.9h1.159Z"
          transform="translate(-251.911 -272.521)"
        />
        <circle
          className="btc"
          cx={0.29}
          cy={0.29}
          r={0.29}
          transform="translate(9.309 6.374)"
        />
      </g>
    </g>
  </svg>
);

export default BankTransferComponent;
