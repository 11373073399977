import React from "react";

const SvgIconBacklogUser = props => (
  <svg width={ props.width ? props.width : "30.9" } height= { props.height ? props.height : "26.48" } viewBox="0 0 30.899 26.485" {...props}>
    <path
      d="M15.45 0A15.445 15.445 0 003.883 25.685c.076.083.145.166.221.241a1.737 1.737 0 002.559-.007 11.941 11.941 0 0117.574 0 1.737 1.737 0 002.559.007l.221-.241A15.445 15.445 0 0015.45 0zm-.966 3.166a.966.966 0 111.931 0v2.483a.966.966 0 01-1.931 0V3.166zM5.656 16.408H3.173a.966.966 0 110-1.931h2.483a.966.966 0 110 1.931zM9.208 9.2a.974.974 0 01-1.366 0L6.083 7.448a.966.966 0 011.366-1.366l1.759 1.759a.966.966 0 010 1.359zm11.077 2.38l-3.276 5.207a2.138 2.138 0 01-.483.483 2.077 2.077 0 11-2.414-3.38l5.207-3.276a.706.706 0 01.807 0 .694.694 0 01.158.966zm2.773-2.38a.966.966 0 01-1.366-1.366l1.759-1.759a.966.966 0 111.366 1.366zm4.669 7.207h-2.483a.966.966 0 110-1.931h2.483a.966.966 0 110 1.931z"
      fill={ props.color ? props.color : "#b5b5b5"}
      className={`${props.className ? props.className : ''}`} style={{"--theme-color": props.activecolor}}
    />
  </svg>
);

export default SvgIconBacklogUser;
