import React from "react";

const IconMan = (props) => (
  <svg {...props} width={props.width ? props.width : 8.759} height={ props.height ? props.height : 18.449} viewBox="0 0 8.759 18.449">
    <defs>
      <style>{".a{fill:#177bd1;}"}</style>
    </defs>
    <path
      fill={`${props.active ? '#177BD1' : '#b5b5b5;'}`}
      d="M146.37,19.482h-.008a1.741,1.741,0,1,1,1.749-1.741,1.745,1.745,0,0,1-1.741,1.741Zm-1.326,14.967a1.05,1.05,0,0,1-1.038-1.168l.038-10.67h-.384v4.036a.932.932,0,0,1-.246.7.817.817,0,0,1-1.168,0,.926.926,0,0,1-.246-.7V21.973a2.22,2.22,0,0,1,.53-1.476,1.963,1.963,0,0,1,1.5-.692h4.7a1.954,1.954,0,0,1,1.5.7,2.21,2.21,0,0,1,.53,1.472v4.674a.911.911,0,0,1-.254.7.864.864,0,0,1-1.191,0,.911.911,0,0,1-.254-.7V22.611h-.346V33.28a1.065,1.065,0,1,1-2.122.023V27.876h-.461v5.416A1.093,1.093,0,0,1,145.044,34.449Z"
      transform="translate(-142 -16)"
    />
  </svg>
);

export default IconMan;
