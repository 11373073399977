import React, {Component} from "react";
import {connect} from "react-redux";
import IconUserAccount from "../../components/Icons/IconUserAccount";
import IconArrowDown from "../../components/Icons/IconArrowDown";
import IconMenu from "../../components/Icons/IconMenuComponent";
import "./Header.css";
import PropTypes from "prop-types";
import UserAuth from "../../helpers/auth"

class Header extends Component {
    constructor(props){
        super(props)
        this.state = {
            name: "",
            email: ""
        }
    }

    UNSAFE_componentWillMount() {
        const user = UserAuth._getUser();
        ;
        if(user) {
            const familyName = user.family_name
            const givenName = user.given_name
            this.setState({
                name: (givenName ? givenName : "") + " " + (familyName ? familyName : ""),
                email: user.email
            })
        }
    }

    componentDidMount(){
        let userMenu = document.querySelector(".user-menu-btn");
        userMenu.addEventListener("click", this.handleMenuAccount, true);
    }

    handleMenu = (e) => {
        e.stopPropagation();
        if(this.props.onToggleMenu){
            this.props.onToggleMenu();
        }
    }

    handleMenuAccount = (e) => {
        e.stopImmediatePropagation();
        if(this.props.onToggleAccountMenu){
            this.props.onToggleAccountMenu(e);
        }
    }

    render(){
        let { name, email } = this.state;
        return (
            <div className="header">
                <div className="container-right">
                    <div onClick={this.handleMenu} className="lg-hidden" ><IconMenu className="btn-nav-menu" color={this.props.themeColor} /></div>
                    <div>
                        <h2 className="title">{this.props.title}</h2>
                    </div>
                    <div className="usr-block user-menu-btn">
                        <IconUserAccount />
                        <span className="label-user sm-hidden">{name ? name : email}</span>
                        <IconArrowDown className="sm-hidden" />
                    </div>
                </div>
            </div>
        )
    }
}

Header.propTypes = {
    onToggleAccountMenu: PropTypes.func,
    accountMenuActive: PropTypes.bool
}

const mapStateToProps = (state) => {
    const { theme } = state;
    return {
        themeColor: theme.mainColor,
    }
}
export default connect(mapStateToProps, {})(Header);
