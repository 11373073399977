import axios from "../config/axios";

export const _getAllowancesByName = async (name) => {
  if (!name.length)
    throw new Error('Cannot get allowance without a name');
  return await axios.get(`/allowances/name/${name}`);
};

export const _createPayslipAllowance = async (
  orgId,
  payPeriodId,
  payslipId,
  data
) => {
  return await axios.post(
    `/organizations/${orgId}/pay-period/${payPeriodId}/payslips/${payslipId}/allowances/`,
    data
  );
};

export const _updatePayslipAllowance = async (
  orgId,
  payPeriodId,
  payslipId,
  allowanceId,
  data
) => {
  return await axios.put(
    `/organizations/${orgId}/pay-period/${payPeriodId}/payslips/${payslipId}/allowances/${allowanceId}`,
    data
  );
};

export const _deletePayslipAllowance = async (
  orgId,
  payPeriodId,
  payslipId,
  allowanceId
) => {
  return await axios.delete(
    `/organizations/${orgId}/pay-period/${payPeriodId}/payslips/${payslipId}/allowances/${allowanceId}`
  );
};