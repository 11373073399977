import React from "react";

const TransportAllowanceComponent = (props) => (
  <svg width={props.width ? props.width : 22.361} height={props.height ? props.height : 11.394} className={props.className ? props.className : ""}  viewBox="0 0 22.361 11.394">
    <defs>
      <style>{".a{fill:#177bd1;}"}</style>
    </defs>
    <g transform="translate(-1055.33 -287.058)">
      <path
        fill={props.fill ? props.fill : "#177bd1"}
        d="M29.373,101.014c-.1-1.124-.265-1.343-.327-1.426a1.818,1.818,0,0,0-.615-.445h0a.1.1,0,0,1-.025-.158.443.443,0,0,0,.126-.349.453.453,0,0,0-.455-.411h-.434a.471.471,0,0,0-.056,0,.236.236,0,0,0-.039-.013h0a3.214,3.214,0,0,0-1.339-1.65A9.613,9.613,0,0,0,22.744,96a9.616,9.616,0,0,0-3.463.565,3.214,3.214,0,0,0-1.339,1.65l0,0a.181.181,0,0,0-.038.009.471.471,0,0,0-.056,0h-.435a.453.453,0,0,0-.455.411.443.443,0,0,0,.128.347.1.1,0,0,1-.025.158h0a1.83,1.83,0,0,0-.615.445c-.062.083-.227.3-.327,1.426a9.994,9.994,0,0,0-.02,1.709,7.469,7.469,0,0,0,.27,1.428.445.445,0,0,0,.367.3h0v.006a.445.445,0,0,0,.445.445h1.558a.445.445,0,0,0,.445-.445h0a2.124,2.124,0,0,0,.583-.088,4.418,4.418,0,0,1,.779-.137c.849-.081,1.693-.109,2.2-.109s1.378.028,2.228.109a4.428,4.428,0,0,1,.782.137,2.213,2.213,0,0,0,.552.088h0a.445.445,0,0,0,.445.445h1.558a.445.445,0,0,0,.445-.445v0h0a.445.445,0,0,0,.368-.3,7.468,7.468,0,0,0,.27-1.428A9.94,9.94,0,0,0,29.373,101.014ZM18.747,98.6a2.451,2.451,0,0,1,.93-1.234,8.392,8.392,0,0,1,3.067-.473,8.368,8.368,0,0,1,3.067.473,2.458,2.458,0,0,1,.93,1.234l.028.06a.223.223,0,0,1-.207.318c-.925-.025-2.872-.105-3.818-.105s-2.894.082-3.82.107a.223.223,0,0,1-.207-.318C18.727,98.638,18.738,98.617,18.747,98.6Zm.332,2.216a11.885,11.885,0,0,1-1.442.085c-.295,0-.6-.083-.656-.346a.822.822,0,0,1-.014-.376c.018-.085.045-.147.184-.168a2.376,2.376,0,0,1,1.156.189,2.689,2.689,0,0,1,.837.391A.132.132,0,0,1,19.079,100.812Zm6.159,2.281c-.366.042-1.1.026-2.486.026s-2.119.015-2.485-.026-.859-.4-.531-.718a2.778,2.778,0,0,1,1.41-.455,10.264,10.264,0,0,1,1.6-.134,8.682,8.682,0,0,1,1.6.134,2.827,2.827,0,0,1,1.41.455c.3.341-.147.673-.525.719Zm3.269-2.543c-.056.264-.362.346-.656.346a12.7,12.7,0,0,1-1.473-.085c-.082-.008-.122-.157-.035-.225a2.6,2.6,0,0,1,.837-.391,2.7,2.7,0,0,1,1.227-.186.16.16,0,0,1,.114.139,1.131,1.131,0,0,1-.014.4Z"
        transform="translate(1039.262 193.548)"
      />
      <path
        fill={props.fill ? props.fill : "#177bd1"}
        d="M261.714,142.953a4.134,4.134,0,1,0,4.134,4.134A4.134,4.134,0,0,0,261.714,142.953Zm.891,3.44a.293.293,0,0,1,0,.585h-2.4c0,.037,0,.073,0,.11a2.009,2.009,0,0,0,.028.329h2.155a.293.293,0,0,1,0,.585h-1.96a1.986,1.986,0,0,0,2.475.936.293.293,0,1,1,.21.546A2.571,2.571,0,0,1,259.786,148h-.545a.293.293,0,0,1,0-.585h.4a2.6,2.6,0,0,1-.021-.329c0-.037,0-.073,0-.11h-.38a.293.293,0,0,1,0-.585h.473a2.57,2.57,0,0,1,3.4-1.7.293.293,0,1,1-.21.546,1.986,1.986,0,0,0-2.572,1.156Z"
        transform="translate(811.842 144.105)"
      />
    </g>
  </svg>
);

export default TransportAllowanceComponent;
