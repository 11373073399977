import React, { Component } from 'react';
import { addClass, darggable, removeClass } from '../../helpers/DOM';
import IconClose from '../Icons/IconClose';
import './CustomImageZoom.css';

class CustomImageZoom extends Component {

    constructor(props){
        super(props);

        this.state = {
            pace: 0.5,
            loading: true
        };
    }

    UNSAFE_componentWillMount(){
        this.setState({
            image: this.props.image,
            alt: this.props.alt,
            controls: this.props.controls,
            zoom: this.props.zoom || 1,
            maxScale: 5,
            minScale: 0.25,
            rotation: 0,
            pace: 0.25,
        });
    }

    componentDidMount(){
        let container = document.querySelector(".custom-image-zoom");
        let image = document.querySelector(".custom-image-zoom > img.imagePreview");
        
        image.addEventListener("load", () => {
            this.autoSize(image);
            this.setState({ loading: false });
            removeClass(image, "opacity-0");
            addClass(image, "fade-in");
            this.zoomActions(image);
            darggable(image, container);
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.image !== prevProps.image){
            this.setState({ image: this.props.image })
        }
    }

    autoSize = (image) => {
        const imageSize = this._sizeOf(image);
        if(imageSize.width > imageSize.height){
            addClass(image, "width-auto");
        }else if(imageSize.width < imageSize.height){
            addClass(image, "height-auto");
        }else{
            addClass(image, "width-height-auto");
        }
    }

    zoomActions = (selector) => {

        let zoomInBtn = document.querySelector(".zoom-control-item.zoom-in");
        let zoomOutBtn = document.querySelector(".zoom-control-item.zoom-out");
        let zoomRotateBtn = document.querySelector(".zoom-control-item.zoom-rotate");
        
        // Zoom IN case
        zoomInBtn.addEventListener("click", (e) => {
            e.stopPropagation();
            let scale = this.zoomIn();
            const rotation = this.state.rotation;
            selector.style.transform = `scale(${scale}) rotate(${rotation}deg)`;
        });
        // Zoom OUT case
        zoomOutBtn.addEventListener("click", (e) => {
            e.stopPropagation();
            const scale = this.zoomOut();
            const rotation = this.state.rotation;
            selector.style.transform = `scale(${scale}) rotate(${rotation}deg)`;
        });
        // Rotate case
        zoomRotateBtn.addEventListener("click", (e) => {
            e.stopPropagation();
            const rotation = this.rotate();
            const scale = this.state.zoom;
            selector.style.transform = `scale(${scale}) rotate(${rotation}deg)`;
        });
    }

    zoomIn = () => {
        const {pace, zoom, maxScale} = this.state;
        let newZoom = null;
        if(zoom <= (maxScale - pace)){
            newZoom = zoom + pace;
            this.setState({
                zoom: newZoom
            });
        }
        return newZoom;
    }

    zoomOut = () => {
        const {pace, zoom, minScale} = this.state;
        let newZoom = null;

        if(zoom >= (minScale + pace)){
            newZoom = zoom - pace;
            this.setState({
                zoom: newZoom
            });
        }
        return newZoom;
    }

    rotate = () => {
        const {rotation} = this.state;
        let r = ((rotation + 90) >= 360) ? 0 : (rotation + 90);
        this.setState({
            rotation: r
        });
        return r;
    }

    _sizeOf(selector){
        const imageWidth = selector.offsetWidth;
        const imageHeight= selector.offsetHeight;

        return { "width" : imageWidth, "height" : imageHeight};
    }

    percentage = () => {
        const { zoom } = this.state;
        return `${zoom * 100} %`;
    }

    renderLoader = () => {
        const {loading} = this.state;
        if(loading){
            return (
                <div className="box-loader">
                    <div className="b-load"></div>
                </div> 
            )
        }
        return (<></>)
    }

    renderClose = () => {
        if (this.props.allowCloser) {
            return (
                <div className="close-zoom" onClick={this.props.onClose}><IconClose color={'#F0F0F0'} /></div>
            )
        }
    }

    imgClicked = (e) => {
        e.stopPropagation();
    }

    render(){

        return (
            <div className="custom-image-zoom" onClick={this.props.onClose}>
                { this.renderClose() }
                <div className="close-zoom-responsive lg-hidden" onClick={this.props.onClose}><IconClose color={'#F0F0F0'} /></div>
                {this.renderLoader()}
                <div className="custom-image-zoom-controls" >
                    <span className="zoom-control-item zoom-in"></span>
                    <span className="zoom-control-item zoom-out"></span>
                    <span className="zoom-control-item zoom-rotate"></span>
                </div>
                <div className="percentage-view">{this.percentage()}</div>
                <img src={this.state.image} alt={this.props.alt} className="imagePreview opacity-0" id="custom-image-zoom-item" onClick={this.imgClicked} />
            </div> 
        )
    }
}

export default CustomImageZoom;