import React from "react";

const HousingAllowanceComponent = (props) => (
  <svg width={props.width ? props.width : 18.425} height={props.height ? props.height : 11.273} className={props.className ? props.className : ""} viewBox="0 0 18.425 11.273">
    <defs>
      <style>{".a{fill:#177bd1;}"}</style>
    </defs>
    <g transform="translate(-1118.224 -285.058)">
      <g transform="translate(1102.224 255.075)">
        <path
          fill={props.fill ? props.fill : "#177bd1"}
          d="M67.984,99.107a.165.165,0,0,0-.229,0l-3.8,3.634a.165.165,0,0,0-.051.12v3.41a.661.661,0,0,0,.661.661h1.986a.331.331,0,0,0,.331-.331v-2.81a.165.165,0,0,1,.165-.165H68.7a.165.165,0,0,1,.165.165v2.81a.331.331,0,0,0,.331.331h1.985a.661.661,0,0,0,.661-.661v-3.41a.165.165,0,0,0-.051-.12Z"
          transform="translate(-46.911 -65.675)"
        />
        <path
          fill={props.fill ? props.fill : "#177bd1"}
          d="M25.812,36.383,24.267,34.9V32.661a.331.331,0,0,0-.331-.331h-.992a.331.331,0,0,0-.331.331v.661l-1.2-1.144A.643.643,0,0,0,20.958,32h0a.641.641,0,0,0-.457.178l-4.395,4.2a.337.337,0,0,0-.028.462.331.331,0,0,0,.479.022l4.287-4.1a.165.165,0,0,1,.229,0l4.288,4.1a.331.331,0,0,0,.467-.009A.339.339,0,0,0,25.812,36.383Z"
          transform="translate(0)"
        />
      </g>
      <path
        fill={props.fill ? props.fill : "#177bd1"}
        d="M261.714,142.953a4.134,4.134,0,1,0,4.134,4.134A4.134,4.134,0,0,0,261.714,142.953Zm.891,3.44a.293.293,0,0,1,0,.585h-2.4c0,.037,0,.073,0,.11a2.009,2.009,0,0,0,.028.329h2.155a.293.293,0,0,1,0,.585h-1.96a1.986,1.986,0,0,0,2.475.936.293.293,0,1,1,.21.546A2.571,2.571,0,0,1,259.786,148h-.545a.293.293,0,0,1,0-.585h.4a2.6,2.6,0,0,1-.021-.329c0-.037,0-.073,0-.11h-.38a.293.293,0,0,1,0-.585h.473a2.57,2.57,0,0,1,3.4-1.7.293.293,0,1,1-.21.546,1.986,1.986,0,0,0-2.572,1.156Z"
        transform="translate(870.8 142.105)"
      />
    </g>
  </svg>
);

export default HousingAllowanceComponent;
