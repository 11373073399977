import React, { Component } from 'react';
import EnvelopLineIcon from '../../assets/svg/envelop-line.svg';
import SmartPhoneIcon from '../../assets/svg/smart-phone.svg';
import GlobeIcon from '../../assets/svg/globe.svg';
import { DEVICES } from '../../constants/AuthConstants';
import moment from 'moment';

import './InvoiceMetaData.css'

class InvoiceMetaData extends Component {

    constructor(props) {
        super(props)
        this.state = {

        }
    }

    renderSource = (item) => {
        let srcIcon = "";

        if(item.senderEmail)
            srcIcon = EnvelopLineIcon;
        else if(item.source.toLowerCase() === DEVICES.mobile.toLowerCase())
            srcIcon = SmartPhoneIcon
        else
            srcIcon = GlobeIcon

        return(
            <span className={ (this.props.className ? this.props.className : '') }>
                <img src={srcIcon} alt='tag icon' width='15px' height='15px' /> {item.source}
            </span>
        ) 
    }

    renderDateLabel = (item) => {

        if(item.senderEmail)
            return(
                <span className={ (this.props.className ? this.props.className : '') }>Recu le</span>
            )
        
        if(item.source.toLowerCase() === DEVICES.mobile.toLowerCase())
            return(
                <span className={ (this.props.className ? this.props.className : '') }>Scannée le</span>
            )
        
        return(
            <span className={ (this.props.className ? this.props.className : '') }>Importée le</span>
        )      
    }

    render = () => {
        const { invoice } = this.props;
        return(
            (invoice && invoice.source) ? <React.Fragment>
                <div className="item-row mt-1">
                    <span className={ 'bold ' + (this.props.header ? this.props.header : '') }>Provenance</span>
                    {
                        this.renderSource(invoice)
                    }
                </div>
                <div className="bg-light-grey br-5 m-2">
                    {
                        invoice.senderEmail ?
                        <div className="item-row">
                            <span className={(this.props.className ? this.props.className : '')}>Expéditeur</span>
                            <span className={ 'bold text-elipsis' + (this.props.className ? this.props.className : '') }  style={{"--max-width": "150px"}}>{invoice.senderEmail}</span>
                        </div> : null
                    }
                    {
                        invoice.emailObject ?
                        <div className="item-row">
                            <span className={(this.props.className ? this.props.className : '')}>Objet du mail</span>
                            <span className={ 'bold text-elipsis' + (this.props.className ? this.props.className : '') } style={{"--max-width": "150px"}}>{invoice.emailObject}</span>
                        </div> : null
                    }
                    <div className="item-row">
                        {
                            this.renderDateLabel(invoice)
                        }
                        <div className="text-elipsis" style={{"--max-width": "150px"}}> 
                            <span className={ 'bold ' + (this.props.className ? this.props.className : '') }>  {moment(invoice.receptionDate ?? invoice.createdAt).format("DD MMM YYYY")} </span> à 
                            <span className={ 'bold ' + (this.props.className ? this.props.className : '') }>  {moment(invoice.receptionDate ?? invoice.createdAt).format("HH:mm")} </span>
                        </div>
                    </div>
                </div>
                
            </React.Fragment>: null
        )
    }
}

export default InvoiceMetaData;