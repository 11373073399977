/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const IoniconsSvgIosCalendarComponent = () => (
  <svg width="1em" height="1em" viewBox="0 0 13.096 12.088">
    <defs>
      <style>{".a{fill:#e29300;}"}</style>
    </defs>
    <path
      className="a"
      d="M59.836,65.007H58.577v.756a.253.253,0,0,1-.252.252h-.5a.253.253,0,0,1-.252-.252v-.756H51.526v.756a.253.253,0,0,1-.252.252h-.5a.253.253,0,0,1-.252-.252v-.756H49.259A1.263,1.263,0,0,0,48,66.267v8.563a1.263,1.263,0,0,0,1.259,1.259H59.836A1.263,1.263,0,0,0,61.1,74.829V66.267A1.263,1.263,0,0,0,59.836,65.007Zm.252,9.444a.631.631,0,0,1-.63.63H49.637a.631.631,0,0,1-.63-.63V68.785a.253.253,0,0,1,.252-.252H59.836a.253.253,0,0,1,.252.252Zm-8.562-10.2A.253.253,0,0,0,51.274,64h-.5a.253.253,0,0,0-.252.252v.756h1.007Zm7.051,0A.253.253,0,0,0,58.325,64h-.5a.253.253,0,0,0-.252.252v.756h1.007Z"
      transform="translate(-48 -64)"
    />
  </svg>
);

export default IoniconsSvgIosCalendarComponent;
