export const ROLES = {
    accountant: 'Comptable',
    customer: 'Entrepreneur'
}

export const DEVICES = {
    mobile: 'mobile'
}
export const ATUH_TAG = {
    forgot: 'forgot',
    confirm: 'confirm',
}

export const FUNCTIONALITIES_CODE = {

    MENU_FEES: "MENU_FEES",
    MENU_SOCIAL : "MENU_SOCIAL",
    MENU_TREASURY : "MENU_TREASURY",
    MENU_TAXES : "MENU_TAXES",
    MENU_BILLS : "MENU_BILLS",
    MENU_BANK: "MENU_BANK",
    MENU_ACCOUNT: "MENU_ACCOUNT",
    MENU_USERS: "MENU_USERS",
    DASHBOARD: 'DASHBOARD',

    STAFF_LIST: "STAFF_LIST",
    STAFF_READ: "STAFF_READ",
    STAFF_CREATE: "STAFF_CREATE",
    STAFF_UPDATE: "STAFF_UPDATE",
    STAFF_DELETE: "STAFF_DELETE",

    PAYSLIP_LIST: "PAYSLIP_LIST",
    PAYSLIP_GENERATE : "PAYSLIP_GENERATE",

    DIPE_GENERATE: "DIPE_GENERATE",

    ORG_READ : "ORG_READ",
    ORG_LIST : "ORG_LIST",
    ORG_CREATE : "ORG_CREATE",
    ORG_UPDATE : "ORG_UPDATE",
    ORG_DELETE : "ORG_DELETE",

    INVOICES_READ : "INVOICES_READ",
    INVOICES_CREATE : "INVOICES_CREATE",
    INVOICES_DELETE : "INVOICES_DELETE",
    INVOICES_UPDATE : "INVOICES_UPDATE",
    INVOICES_LIST : "INVOICES_LIST",

    BILL_READ : "BILL_READ",
    BILL_CREATE : "BILL_CREATE",
    BILL_DELETE : "BILL_DELETE",
    BILL_UPDATE : "BILL_UPDATE",
    BILL_LIST : "BILL_LIST",

    TAXES_READ : "TAXES_READ",
    TAXES_CREATE : "TAXES_CREATE",
    TAXES_DELETE : "TAXES_DELETE",
    TAXES_UPDATE : "TAXES_UPDATE",
    TAXES_LIST : "TAXES_LIST",
}