import React, {Component} from 'react';
import { connect } from "react-redux";
import { setLoaderImportBankStatement } from "../../redux/action/bankAction";
import Loader from "../Loader/Loader";
import 'react-circular-progressbar/dist/styles.css';
import './'
import './CustomInputFile.css';

class CustomInputFile extends Component {

    constructor(props){
        super(props);
        this.state = {
            label: "Importer",
            file: null,
            fileName: null,
            fileSize: null,
            fileType: null,
            controls: false,
            uploadState: false,
            disabled: false
        };
        this.fileInputRef = React.createRef();
        this.openFileDialog = this.openFileDialog.bind(this);
        this.resetValues = this.resetValues.bind(this);
    }

    componentDidMount(){
        this.props.onRef(this);
        var elem = document.querySelector('.CustomInputFile');
        elem.addEventListener('errorUpload',
            () => {this.resetValues();}
        );
    }

    componentWillUnmount = () => {
        this.props.onRef(undefined);
    }

    UNSAFE_componentWillReceiveProps = () => {
        if(this.props.loaderImportBankStatement) {
            this.resetValues();
        }
    }

    openFileDialog = () => {
        if (this.state.disabled) return;
        this.fileInputRef.current.click();
    }

    onFilesAdded = (e) => {
        if (this.props.disabled) return;
        const files = e.target.files[0];

        if(!files) return;
        this.setState({
            file: files,
            fileName: files.name,
            fileSize: files.size,
            fileType: files.type,
            controls: true
        });
    }

    cancelSelectedFile = () => {
        this.fileInputRef.current.value = "";
        this.setState({
            fileName: null,
            fileSize: null,
            fileType: null,
        }, () => { this.toggleControls(); });
    }
    
    processUpload = async () => {
        this.setState({
            disabled: true
        }, () => { this.toggleUploadState(); this.toggleControls(); });
        await this.sendRequest();
    }


    setLabel = (value) => {
        this.setState({
            label: value
        })
    }

    toggleControls = () => {
        this.setState({
            controls: !this.state.controls
        })
    }

    toggleUploadState = () => {
        this.setState({
            uploadState: !this.state.uploadState
        })
    }

    resetValues = () => {
        this.props.setLoaderImportBankStatement(false);
        this.fileInputRef.current.value = "";
        this.setState({
            label: "Importer",
            file: null,
            fileName: null,
            fileSize: null,
            fileType: null,
            controls: false,
            uploadState: false,
            disabled: false
        });
    }

    sendRequest = () => {
        const formData = new FormData();
        formData.append("reportFile", this.state.file, this.state.file.name);

        const options = {
            headers : {
                "Accept": "application/json",
                "Content-type" : "multipart/form-data"
            },
        }
        this.props.setLoaderImportBankStatement(true);
        this.props.afterUpload(formData, options);
    }

    render(){

        return (
            <div className={`CustomInputFile ${this.props.className ? this.props.className : ""}`}>
                <div className="import-file" style={ {maxWidth : this.props.maxWidth || "300px"} }>
                    <input
                        ref={this.fileInputRef}
                        className="hidden"
                        type="file"
                        onChange={this.onFilesAdded}
                        accept=".pdf"
                    />
                    <button className="link-button" onClick={this.openFileDialog}> <span className="import-label">{ (this.state.fileName) ? this.state.fileName : this.state.label }</span> </button>
                </div>
                <div className="CustomInputFile__controls">
                    <button className={`btn circular controls ${this.state.controls ? "" : "hidden"}`} onClick={ this.cancelSelectedFile }> <span className="btn__cancel"></span> </button>
                    <button  className={`btn circular controls ${this.state.controls ? "" : " hidden"}`} onClick={ this.processUpload } > <span className="btn__upload"></span> </button>
                </div>
                <div className={`d-flex justify-content-center  ${this.state.uploadState ? "" : " hidden"}`}>
                    <Loader style={{width: '30px', height: '30px'}} loadData={this.props.loaderImportBankStatement} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loaderImportBankStatement: state.bank.loaderImportBankStatement,
    }
}

export default connect(mapStateToProps, { setLoaderImportBankStatement })(CustomInputFile)