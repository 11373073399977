import React from "react";

const ChildParentFreeNgoAvatarMalePersonComponent = (props) => (
  <svg width={props.width ? props.width : 11.86} height={props.height ? props.height : 16.637} viewBox={`0 0 ${props.width ? props.width : 11.86} ${props.height ? props.height : 16.637}`}>
    <defs>
      <style>{`.${props.className}{fill: ${props.active ? '#177BD1;' : '#b5b5b5;'}}`}</style>
    </defs>
    <g transform="translate(-295.776 -150.084)">
      <g transform="translate(293.344 146.672)">
        <path
          className={props.className}
          d="M304.916,150.667l-3.006,3.006v8.483a1.153,1.153,0,0,1-2.306,0V158.2h-.659v3.953a1.153,1.153,0,0,1-2.306,0v-8.483l-3.006-3.006a.99.99,0,0,1,1.4-1.4l2.347,2.347h3.789l2.347-2.347a.99.99,0,0,1,1.4,1.4Zm-3.336-1.688a2.3,2.3,0,1,1-.674-1.632A2.223,2.223,0,0,1,301.58,148.978Z"
          transform="translate(-290.911 -143.26)"
        />
      </g>
    </g>
  </svg>
);

export default ChildParentFreeNgoAvatarMalePersonComponent;
