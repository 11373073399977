import axiosDefaultClient, {baseInstance} from '../config/axios';
import CONSTANTS from "../constants/constants";

export const _uploadBankStatement = async (orgId, formDataIn) => {
    try{
        const presignRes = (await _presignedRequest(orgId, formDataIn.get("reportFile").name)).data;
        await _uploadFileToS3(presignRes, formDataIn.get("reportFile"));
        return await _sendFileToExtractFromS3(orgId, formDataIn.get("reportFile").name, presignRes.fields.key)
    }catch(e){
        throw new Error(e);
    }
}
export const _presignedRequest = async(orgId, origFileName) => {
    return await axiosDefaultClient.post(`/organizations/${orgId}/presign-post?originalFileName=${origFileName}&context=${CONSTANTS.BANK_CONTEXT}`);
}
export const _uploadFileToS3 = async (presignedPostData, file) => {
    const formData = new FormData();
    Object.keys(presignedPostData.fields).forEach(key => {
        formData.append(key, presignedPostData.fields[key]);
    });

    formData.append("file", file);
    return await baseInstance.post(presignedPostData.url, formData);
}
export const _sendFileToExtractFromS3 = async (orgId, fileName, fileKey) => {
    const fileKeyEncoded = encodeURI(fileKey);
    const reqUrl = `/organizations/${orgId}/bank-statements?s3FileName=${fileKeyEncoded}`;
    
    const options = {
        headers: {
            "Content-Type": "application/json",
            "Connection-ID": sessionStorage.getItem("connectionId"),
        },
    };
    return await axiosDefaultClient.post(reqUrl, options);
}

// Get bank records by bank statement id
export const _getBankRecordsByStatementId = async (orgId, params) => {
    const param = {
        params: {
            ...params,
            orgId,
        }
    }
    return await axiosDefaultClient.get(`/organizations/${orgId}/records`, param);
}
