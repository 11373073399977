import React, { Component } from 'react';
import EditableInput from '../EditableInput';
import IconPlus from '../Icons/IconPlus';
import IconMap from '../Icons/MapIcon';
import IconCall from '../Icons/IconCall';
import IconMail from '../Icons/IconMail';
import IconAddress from '../Icons/AddressIcon';
import IconBankTransfert from '../Icons/BankTransferIcon';
import IconBank from '../Icons/BankComponent';
import CardBankPreview from '../CardBankPreview';
import Countries from 'country-data';
import { connect } from 'react-redux';
import { updateStaff, selectStaff } from '../../redux/action/StaffAction';
import { _updateStaff } from '../../services/Staff';
import ModalBank from './ModalBank';
import BottomToTop from '../Transition/BottomToTop';
import Logger from '../../helpers/Logger';
import { validationNotification } from '../../helpers/errorHandle';

class CoordonneePreview extends Component {

    constructor(props){
        super(props);

        this.state = {
            bankInfo: this.props.data.bankInfo,
            modalBank: false,
            bankPreview: false,
            bankIndex: -1,
            bankName: '',
            bankCode: '',
            guichetCode: '',
            compteNum: '',
            ribKey: '',
            active: false,
            modePay: [{value: 'Virement'}, {value: 'Espèce'}, {value: 'Chèque'}],
        };
    }

    handleClick = (index) => {
        let {bankInfo} = this.state
        this.setState({
            modalBank: true,
            bankPreview: true,
            bankIndex: index,
            bankName: bankInfo[index].name,
            bankCode: bankInfo[index].bankCode,
            guichetCode: bankInfo[index].guichetCode,
            compteNum: bankInfo[index].compteNum,
            ribKey: bankInfo[index].ribKey,
            active: !!bankInfo[index].active,
        })
        
    }
    renderBankInfos = () => {
        if(this.props.selectedStaff.bankInfo?.length){
            return (
                this.props.selectedStaff.bankInfo.map((item, index) => {
                    return(
                        <div className="row ml-3 mr-1 mt-2" key={index}>
                            <div className="col-md-12 ml-0">
                                <CardBankPreview { ...item } index={index} onClick={() => this.handleClick(index)} />
                            </div>
                        </div>
                    )
                })
            )
        }else{
            return(
                <div className="row ml-3 mr-1 mt-2">
                    <div className="col-md-12 ml-0 d-flex align-items-center justify-content-center bg-light" >
                        AUCUNE INFORMATIONS BANQUAIRES
                    </div>
                </div>
            )
        }
        
    }

    renderFlag = () => {
        const { data } = this.props
        const country = Countries.countries.all.find(country => country.name === data.country)
        if(country)
            return country.emoji
        else
            return ''
    }

    onChangeBankName = (e) => {
        this.setState({
            bankName: e.target.value
        })
    }
    onChangeBankCode = (e) => {
        this.setState({
            bankCode: e.target.value
        })
    }
    onChangeGuichetCode = (e) => {
        this.setState({
            guichetCode: e.target.value
        })
    }
    onChangeCompteNum = (e) => {
        this.setState({
            compteNum: e.target.value
        })
    }
    onChangeRibKey = (e) => {
        this.setState({
            ribKey: e.target.value
        })
    }
    onChangeActive = () => {
        this.setState({
            active: !this.state.active
        })
    }

    onEditBank = async () => {
        let {bankIndex} = this.state;
        let newBank = this.props.selectedStaff.bankInfo;
        newBank[bankIndex].name  = this.state.bankName;
        newBank[bankIndex].bankCode  = this.state.bankCode;
        newBank[bankIndex].compteNum  = this.state.compteNum;
        newBank[bankIndex].guichetCode  = this.state.guichetCode;
        newBank[bankIndex].ribKey  = this.state.ribKey;
        newBank[bankIndex].active  = this.state.active;
        let index = newBank.findIndex(bank => bank.active === true)
        if(index >= 0 && newBank[bankIndex].active) {
            newBank[index].active = false
        }
        await this.updateStaff(this.props.data.id, {bankInfo: newBank});
        this.setState({
            bankInfo: newBank,
            modalBank: false,
            bankPreview: false,
            bankIndex: -1,
            bankName: '',
            bankCode: '',
            guichetCode: '',
            compteNum: '',
            ribKey: '',
            active: false,
        });
    }

    onValidateModalBank = async () => {
        let newBank = this.props.selectedStaff.bankInfo || [];
        let bankData = {
            name: this.state.bankName,
            bankCode: this.state.bankCode,
            compteNum: this.state.compteNum,
            guichetCode: this.state.guichetCode,
            ribKey: this.state.ribKey,
            active: this.state.active
        }
        let index = -1;
        if(newBank?.length > 0) {
            index = newBank.findIndex(bank => bank.active === true);
            if(index >= 0 && bankData.active) {
                newBank[index].active = false;
            }
        }
        newBank.push(bankData)
        await this.updateStaff(this.props.data.id, {bankInfo: newBank})
        this.setState({
            bankInfo: newBank,
            bankName: '',
            bankCode: '',
            compteNum: '',
            guichetCode: '',
            ribKey: '',
            active: false,
            modalBank: false,
            bankPreview: false
        });
    }

    updateStaff = async (id, staffData) => {
        let defaultOrganization = JSON.parse(sessionStorage.getItem('defaultOrg'));
        try {
            const staff = (await _updateStaff(defaultOrganization.id, id, staffData)).data;
            if(staff) {
                this.props.updateStaff(staff)
                this.props.selectStaff(staff)
            }
        } catch (err) {
            const error = { ...err };
            Logger.error(`Staff", "Could not update staff ${staffData}`, error);
            validationNotification(error?.response?.status, error?.response?.data?.errors);
        }
    }

    displayModalBank = (event) => {
        this.setState({
            modalBank: true,
            bankPreview: false
        })
    }

    renderModalBank = () => {
        return <ModalBank onChangeBankName={(e) => this.onChangeBankName(e)} BankName={this.state.bankName} 
            onChangeBankCode={(e) => this.onChangeBankCode(e)} BankCode={this.state.bankCode}
            onChangeGuichetCode={(e) => this.onChangeGuichetCode(e)} GuichetCode={this.state.guichetCode}
            onChangeCompteNum={(e) => this.onChangeCompteNum(e)} CompteNum={this.state.compteNum}
            onChangeRibKey={(e) => this.onChangeRibKey(e)} RibKey={this.state.ribKey}
            onChangeActive={(e) => this.onChangeActive(e)} active={this.state.active}
            onCancel={() => this.setState({modalBank: false})} onValidate={ this.state.bankPreview ? () => this.onEditBank() : () => this.onValidateModalBank()} 
        />
    }

    renderCoordonnee = () => {
        const {data} = this.props
        return (
            <div className='px-0'>
                <div className="header-preview">
                    <div className="row pt-1 pl-3">
                        <div className="col-md-6">
                            <div className="label-input">Reside au </div>
                            <EditableInput type="select" value={data.country} name="livingCountry" selectedValue={data.country} options={this.props.nationalities} optionIcon='emoji'
                            optionValue='name' className="pl-30-10" onBlur={event => this.updateStaff(data.id, {country: event.target.value})} 
                            state={'preview'} editable withIcon={this.renderFlag()} />

                            {/* <EditableInput type="text" name="livingCountry" value={data.country} 
                                className="pl-30-10" onBlur={event => this.updateStaff(data.id, {country: event.target.value})} 
                                state={'preview'} editable withIcon={this.renderFlag()} /> */}
                        </div>
                        <div className="col-md-6">
                            <div className="label-input">Dans la ville de </div>
                            <EditableInput type="text" name="livingTown" value={data.city} 
                                onBlur={event => this.updateStaff(data.id, {city: event.target.value})} 
                                state={'preview'} editable withIcon={<IconMap active />} />
                        </div>
                    </div>
                    <div className="row pt-1 pl-3">
                        <div className="col-md-6">
                            <div className="label-input">Telephone </div>
                            <EditableInput type="text" name="phone1" value={data.phone1} 
                                className="pl-30-10" onBlur={event => this.updateStaff(data.id, {phone1: event.target.value || null})}
                                state={'preview'} editable withIcon={<IconCall width={15} height={15} active />} />
                            <EditableInput type="text" name="phone2" value={data.phone2} 
                                className="pl-30-10" onBlur={event => this.updateStaff(data.id, {phone2: event.target.value || null})} 
                                state={'preview'} editable withIcon={<IconCall width={15} height={15} active />} />
                        </div>
                        <div className="col-md-6">
                            <div className="label-input">Email </div>
                            <EditableInput type="email" name="email" value={data.email} 
                                className="pl-30-10" onBlur={event => this.updateStaff(data.id, {email: event.target.value})} 
                                state={'preview'} editable withIcon={<IconMail width={15} height={10.38} active />} />
                        </div>
                    </div>
                    <div className="row pt-1 pl-3">
                        <div className="col-md-12">
                            <div className="label-input">Adresse </div>
                            <EditableInput type="text" name="staffaddr" value={data.address ? data.address : ''} 
                                className="pl-30-10" onBlur={event => this.updateStaff(data.id, {address: event.target.value})} 
                                state={'preview'} editable withIcon={<IconAddress width={15} height={10.38} active />} />
                        </div>
                    </div>
                </div>
                <div className="content-preview">
                    <div className="row pt-1 pl-3">
                        <div className="col-md-12">
                            <div className="label-input">Mode de paiement </div>
                            <EditableInput type="select" name="paymentMode" value={data.payementMode} options={this.state.modePay} optionValue={'value'}
                                className="pl-30-10" onBlur={event => this.updateStaff(data.id, {payementMode: event.target.value})} 
                                state={'preview'} editable withIcon={<IconBankTransfert width={15} height={15} active />} />
                        </div>
                    </div>
                    {
                        data.payementMode === this.state.modePay[0].value ?
                        <div>
                            <div className="label-input ml-3 flex-column">
                                <div className='d-flex align-items-center'><span className='mr-3'><IconBank width={15} height={15} active /></span>Coordonnées bancaires</div>
                                <button className='btn-plus mt-2' onClick={this.displayModalBank}>
                                    <IconPlus />
                                </button>
                            </div>
                            <div className="row pt-1">
                                <div className="col-md-12">
                                    { this.renderBankInfos() }
                                </div>
                            </div>
                        </div> : <></>
                    }
                    
                </div>

            </div>
        )
    }
    
    render(){
        return(
            <div>
                <BottomToTop in={!this.state.modalBank}>{this.renderCoordonnee()}</BottomToTop>
                <BottomToTop in={this.state.modalBank}>{this.renderModalBank()}</BottomToTop>
            </div>
        )
    }
    
}

const mapStateToProps = (state) => {
    return {
        selectedStaff: state.staffs.selectedStaff
    }
}

export default connect(mapStateToProps, {updateStaff, selectStaff})(CoordonneePreview);