import axios from 'axios'

let baseURL = process.env.REACT_APP_API_BASE_URL; 

const instance = axios.create({
    baseURL
})

export default instance

export const baseInstance = axios.create();