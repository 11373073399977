import React, { Component } from 'react';
import { connect } from 'react-redux';
import {updateOrganizationInvoice, updateSelectedInvoice, addOrganizationInvoice} from '../../redux/action/OrganisationAction';
import moment from 'moment';
import { numberFormat } from '../../helpers/Number';
import { dateFormatPart } from '../../helpers/date';
import CategoryIcon from '../../assets/svg/category.svg';
import DateIcon from '../../assets/svg/icon-calendar.svg';
import UserAvatar from '../../assets/svg/user-avatar.svg';
import TagIcon from '../../assets/svg/tag.svg';
import './Scan.css';
import InputArea from '../InputArea';
import { FORM_ACTIONS } from '../../constants/FormActions';
import { __updateObservation , __createObservation , __deleteObservation} from '../../services/Observation.js';
import { _getInvoiceById } from '../../services/Invoice';
import TagContainer from '../TagContainer/TagContainer';
import InvoiceMetaData from '../InvoiceMetaData/InvoiceMetaData';
import Logger from '../../helpers/Logger';
import { _extractRole } from '../../services/Auth';
import { errorNotifcation } from "../../helpers/errorHandle";
import InvoiceAttachment from "../../components/InvoiceAttachment";

class ScanPreview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: this.props.selectedInvoice,
            selectedTag: {},
            loading: false,
            selectedScanState: null,
            selectedTagPosition: null,
            scanStates: [
                {name: 'En cours', value: 'En cours', color: '#007bff'},
                {name: 'En attente', value: 'En attente', color: 'grey'},
                {name: 'Traité', value: 'Traité', color: '#15AC20'},
            ],
            showFilePreview: false,
            showFilePreviewResponsive: false,
            action: FORM_ACTIONS.create,
        };
    }

    UNSAFE_componentWillMount(){
        this.processInvoice();
    }

    processInvoice = async () => {
        const {id} = this.props.selectedInvoice;
        this.setState({ loading: true});
        let defaultOrganization = JSON.parse(sessionStorage.getItem('defaultOrg'));
        try {
            const invoice = (await _getInvoiceById(defaultOrganization.id, id)).data;
            this.setState({ item: invoice, loading: false});
        } catch (error) {
            Logger.error("ProcessInvoice", "Error occured while getting invoice by Id", error);
            errorNotifcation(error?.response?.status, "Impossible de récupérer la facture, veuillez rafraichir la page.");  
        }
    }

    selectScanState = (item) => {
        this.setState({
            selectedScanState: item
        })
    }


    createObservation =  async (observation) => {
        this.props.toggleLoader();
        try {
            const createdObservation =  (await __createObservation(this.props.selectedInvoice.id, observation)).data;
            const newObservationList = [...this.props.selectedInvoice.observations, createdObservation ];
            this.updateObservationsInvoice(newObservationList);
            this.onInputCancel();
        } catch (error) {
            Logger.error("CreateObservation", "Could not create observation", error);
            errorNotifcation(error?.response?.status, "Erreur pendant la création de l'observation, veuillez rafraichir la page.");
        } finally {
            this.props.toggleLoader();
        }
    }

    updateObservation =  async (observation) => {
        observation.id = this.state.selectedTag.id;
        this.props.toggleLoader();
        try {
            const updatedObservation = (await __updateObservation(this.props.selectedInvoice.id, observation)).data;
            let newObservationList = [ ...this.props.selectedInvoice.observations ];
            let oldObservationIndex = newObservationList.findIndex(obs => {
                return updatedObservation.id === obs.id;
            });
            newObservationList[oldObservationIndex] = updatedObservation;
            this.updateObservationsInvoice(newObservationList);
            this.onInputCancel();
        } catch (error) {
            Logger.error("UpdateObservation", "Could not update observation", error);
            errorNotifcation(error?.response?.status, "Erreur pendant la mise à jour de l'observation, veuillez rafraichir la page.");
        } finally {
            this.props.toggleLoader();
        }
    }

    createOrUpdateObservation = async () => {
        if(this.state.selectedTag.value){
            const observation = { 
                type : this.getRole(),
                value : this.state.selectedTag.value,
                invoiceId : this.props.selectedInvoice.id
                
            };
            if(this.state.action === FORM_ACTIONS.update) this.updateObservation(observation);
            else if(this.state.action === FORM_ACTIONS.create) this.createObservation(observation);
        }
    }

    deleteObservation = async (observation) => {
        this.props.toggleLoader();
        try {
            await __deleteObservation(this.props.selectedInvoice.id, observation);
            const oldObservationIndex = this.props.selectedInvoice.observations.findIndex((obs) => {
                return observation.id === obs.id;
            });
            if(oldObservationIndex >= 0){
                let newObservationList = [ ...this.props.selectedInvoice.observations ];
                newObservationList.splice(oldObservationIndex, 1); 
                this.updateObservationsInvoice(newObservationList);
            }
        } catch (error) {
            Logger.error("DeleteObservation", "Failed to delete observation", error);
            errorNotifcation(error?.response?.status, "Erreur pendant la suppression de l'observation, veuillez rafraichir la page.");
        } finally {
            this.props.toggleLoader();
        }
    }

    updateObservationsInvoice = (newObservationList) => {
        const  newInvoice = {...this.props.selectedInvoice , observations: newObservationList};
        this.props.updateOrganizationInvoice(newInvoice);
        this.props.updateSelectedInvoice(newInvoice);
        this.setState({item : newInvoice , action : FORM_ACTIONS.create});
    }

    onTagClick = (tag) => {
        this.setState({action : "update"});
        this.setState({
            selectedTag: {...tag},
        })        
    }

    onInputChange = (value) => {
        let { selectedTag } = this.state;
        if (!selectedTag) selectedTag = {};
        selectedTag.value = value;
        this.setState({
            selectedTag
        });
    }

    onInputCancel = async () => {
        this.setState({action : FORM_ACTIONS.create, selectedTag: { value: ''}});
    }

    getTagsOverflow = () => {
        const {observations} = this.props.selectedInvoice;
            if(observations && observations.length > 15) return ' overflow-y';
        return '';
    }

    togglePreviewFile = (attachment, visibility) => {
        this.setState({
            showFilePreviewResponsive: !this.state.showFilePreviewResponsive
        });
        if(this.props.handleAttachment){
            this.props.handleAttachment(this.props.selectedInvoice, attachment, visibility);
        }
    }

    getRole = () => {
        try {
            const role = _extractRole();
            return role?.rolename;
        } catch (error) {
            Logger.error("Error during get role of user");
            errorNotifcation(error?.response?.status, "Erreur pendant la récupération du rôle, veuillez reessayer ultérieurement.");
        }
    }

    render() {
        let { selectedTag } = this.state;
        let item = this.props.selectedInvoice;
        let { value } = selectedTag;
        return(
            <div className="invoice-preview d-flex">
                <div className="data-preview">
                    <div className="item-row">
                        {
                            item.categoryId ? 
                            <div></div>
                            :<div className='d-flex justify-content-between align-items-center'> 
                                <img src={CategoryIcon} alt='Category icon' width='15px' height='15px'/> 
                                <span className='ml-2 category'>Non catégorisé</span>
                            </div>
                        }
                        <div className='d-flex justify-content-between align-items-center'>
                            <img src={DateIcon} alt='Date icon' width='15px' height='15px' />
                            <span className='ml-2'>{item.date ? dateFormatPart(moment(item.date).format('YYYY/MM/DD')) : dateFormatPart(moment(item.createdAt).format('YYYY/MM/DD'))}</span>
                        </div>
                    </div>
                    {
                        item.deliver && item.nui ?
                        <div className="item-row mt-1 mb-1">
                            <div  className='d-flex justify-content-between align-items-center'>
                                <img src={UserAvatar} alt='user avatar' width='15px' height='15px' />
                                <span className='ml-2'>{item.deliver}</span>
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <img src={TagIcon} alt='tag icon' width='15px' height='15px' />
                                <span className='ml-2'>{item.nui}</span>
                            </div>
                        </div> : null
                    }
                    {
                        item.totalAmount ? <div className="card-item-detail m-2 mb-3 mt-3">
                            
                            <div className="item-row mt-1">
                                <span className="bold">Fournisseur</span>
                                <span>{item.deliver ? item.deliver : ''}</span>
                            </div>
                            <div className="item-row mt-1">
                                <span className="bold">NUI</span>
                                <span>{item.nui ? item.nui : ''}</span>
                            </div>
                            <div className="item-row mt-1">
                                <span className="bold">Montant HT</span>
                                <span>{item.netAmount ? `${item.currency} ` + numberFormat(item.netAmount) : ''}</span>
                            </div>
                            <div className="item-row">
                                <span className="bold">TVA</span>
                                <span> {item.vat ? `${item.currency} `+ numberFormat(item.vat) : ''}</span>
                            </div>
                            <div className="item-row">
                                <span className="font-size-12 bold">Montant Total</span>
                                <span className="font-size-12 bold">{`${item.currency} `}  {numberFormat(item.totalAmount)}</span>
                            </div>
                        </div>: null
                    }
                    <div className="separator" />
                    <div className='p-2 mt-1 mb-1'>
                        <div className="font-size-11 bold mb-1">Observations</div>
                        <div className={'tags-wrapper' + this.getTagsOverflow()}>
                            <TagContainer onTagClick={this.onTagClick} 
                                accountRole={this.getRole()} 
                                tags={this.props.selectedInvoice.observations}
                                onRemove={this.deleteObservation}
                                editable={true}
                            />                        
                        </div>
                    </div>
                    <div className='p-2 mt-1'>
                        <InputArea onSave={this.createOrUpdateObservation} onInputChange={this.onInputChange} onInputCancel={this.onInputCancel} tag={value}/>
                    </div>
                    <hr/>
                    <InvoiceMetaData invoice={item} />
                    <InvoiceAttachment attachment={this.props.selectedInvoice.attachment} contentType={this.props.selectedInvoice.contentType} onHandleAttachment={this.togglePreviewFile} />
                </div>
            </div>
            
        )
    }
}

const mapStateToProps = (state) => {
    const {organizations } = state;
    return {
        invoices: organizations.organizationInvoices,
        selectedInvoice: organizations.selectedInvoice,
    }
}

export default connect(mapStateToProps, { updateOrganizationInvoice, updateSelectedInvoice, addOrganizationInvoice })(ScanPreview);
