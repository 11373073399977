import { isBasicError } from "./http.helper";
import { toastr } from "react-redux-toastr";

export const errorNotifcation = (errorStatusCode, message, messageHeader) => {
    if (errorStatusCode && !isBasicError(errorStatusCode)) toastr.error(messageHeader || "", message?.title || message);
}

export const infoNotification = (errorStatusCode, message, messageHeader) => {
    if (errorStatusCode && !isBasicError(errorStatusCode)) toastr.info(messageHeader || "", message);
}

export const validationNotification = (errorStatusCode, errors) => {
    if (errorStatusCode === 400 && errors?.length) {
        for (const error of errors) {
            const message = `${error.message.slice(0, -1)} for field ${error.parameter}`;
            toastr.error("", message);
        }
    }
}