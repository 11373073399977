import React from "react";

const SvgIconBilling = props => (
  <svg width={props.width ? props.width : "23.17"} height={props.height ? props.height : "27.11"} viewBox="0 0 30.9 36.164" {...props}>
    <g 
    fill={props.color? props.color :"#b5b5b5"}
    className={`${props.className ? props.className : ''}`} 
    style={{"--theme-color": props.activecolor}}
    >
      <path
        data-name="Trac\xE9 3"
        d="M27.929 0H2.969C1.335 0-.002 1.7-.002 3.767v28.625c0 2.072 1.337 3.767 2.971 3.767h24.96c1.634 0 2.971-1.7 2.971-3.767V3.767C30.9 1.692 29.563 0 27.929 0zm.891 16.575a1.381 1.381 0 01-1.188 1.507h-6.448a1.239 1.239 0 00-1.122 1c-.3 1.092-1.062 2.015-1.939 2.015h-5.347c-.884 0-1.642-.923-1.939-2.015a1.232 1.232 0 00-1.122-1H3.269a1.381 1.381 0 01-1.188-1.507V4.143a1.381 1.381 0 011.188-1.506h24.362a1.381 1.381 0 011.188 1.507z"
      />
      <path
        data-name="Trac\xE9 4"
        d="M26.224 8.472H4.676a1.083 1.083 0 01-1.16-.975 1.083 1.083 0 011.16-.975h21.548a1.083 1.083 0 011.16.975 1.083 1.083 0 01-1.16.975zm0 4.457H4.676a1.083 1.083 0 01-1.16-.975 1.083 1.083 0 011.16-.975h21.548a1.083 1.083 0 011.16.975 1.083 1.083 0 01-1.16.975z"
      />
    </g>
  </svg>
);

export default SvgIconBilling;
