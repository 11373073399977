import React from "react";

const IconTaxesComponent = (props) => (
  <svg width={props.size ? props.size : 28.162} height={props.size ? (props.size * 25.673) / 28.162 : 25.673} viewBox="0 0 28.162 25.673">
    <defs></defs>
    <g transform="translate(-36.589 -32.338)" className={`${props.className ? props.className : ''}`} style={{"--theme-color": props.activecolor}}>
      <path
        fill={`${props.color ? props.color : "#FFFFFF"}`}
        d="M-2169.41-8446.42h-21a.953.953,0,0,1-1-.9v-23.328a.953.953,0,0,1,1-.9h21a.953.953,0,0,1,1,.9v23.328A.953.953,0,0,1-2169.41-8446.42Zm-20-7.178v1.795h18v-1.795Zm0-4.486v1.795h18v-1.795Zm0-4.484v1.793h18v-1.793Zm0-4.487v1.8h10v-1.8Z"
        transform="translate(2228 8503.879)"
      />
      <g transform="translate(51.707 44.963)">
        <path
          fill={`${props.color ? props.color : "#FFFFFF"}`}
          d="M6.274,8334.637A6.274,6.274,0,1,1,0,8340.91,6.273,6.273,0,0,1,6.274,8334.637Z"
          transform="translate(0 -8334.637)"
        />
        <path
          fill={`${props.color ? props.color : "#FFFFFF"}`}
          fillRule="evenodd"
          stroke="#335284"
          strokeWidth={0.5}
          d="M1233.91,9055.31a1.687,1.687,0,0,1,.279-1.019,1.291,1.291,0,0,1,.837-.5v-.461h.865v.461a1.314,1.314,0,0,1,.851.5,1.7,1.7,0,0,1,.283,1.025v.2h-1v-.259a.65.65,0,0,0-.139-.475.532.532,0,0,0-.387-.136.541.541,0,0,0-.391.136.651.651,0,0,0-.139.475.981.981,0,0,0,.153.551,2.184,2.184,0,0,0,.384.433c.157.133.321.265.5.39a4.321,4.321,0,0,1,.5.434,2.226,2.226,0,0,1,.384.555,1.736,1.736,0,0,1,.153.76,1.706,1.706,0,0,1-.289,1.032,1.325,1.325,0,0,1-.854.5v.452h-.865v-.452a1.307,1.307,0,0,1-.862-.5,1.7,1.7,0,0,1-.286-1.032v-.426h1v.488a.614.614,0,0,0,.15.472.587.587,0,0,0,.4.132.6.6,0,0,0,.4-.132.629.629,0,0,0,.15-.472.953.953,0,0,0-.157-.551,2.12,2.12,0,0,0-.384-.433c-.153-.132-.318-.262-.5-.391a3.673,3.673,0,0,1-.5-.432,2.115,2.115,0,0,1-.384-.556A1.687,1.687,0,0,1,1233.91,9055.31Z"
          transform="translate(-1229.184 -9050.594)"
        />
      </g>
    </g>
  </svg>
);

export default IconTaxesComponent;
