import axios from '../config/axios';

export const _getCustomers = async (orgId, params) => {
    const param = {
        params: {
            ...params,
            orgId,
        }
    }
    return await axios.get(`/organizations/${orgId}/customers`, param);
}