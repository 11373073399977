import React, { Component } from 'react'
import Input from '../Inputs/inputs'
import IconChild from '../Icons/ChildIcon'
import IconDate from '../Icons/DateIcon'
import Validator from 'validator'

export default class ModalChild extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            surname: '',
            birthday: '',
            focusedName: false,
            focusedSurname: false,
            focusedBirthday: false,
            modalChild: false,
            valid: {
                name: true,
                birthday: true
            },
            errors: []
        }
    }

    focusName = (value) => {
        this.setState({
            focusedName: !value
        })
    }

    focusSurname = (value) => {
        this.setState({
            focusedSurname: !value
        })
    }

    focusBirthday = (value) => {
        this.setState({
            focusedBirthday: !value
        })
    }

    onChangeChildName = (e) => {
        this.props.onChangeChildName(e)
        let valid = !Validator.isEmpty(e.target.value)
        this.setState({
            valid: {
                ...this.state.valid,
                name: valid
            }
        })
    }

    onChangeSurname = (e) => {
        this.props.onChangeChildSurname(e)
    }

    onChangeChildBirthday = (e) => {
        this.props.onChangeChildBirthday(e)
        let valid = !Validator.isEmpty(e.target.value)
        this.setState({
            valid: {
                ...this.state.valid,
                birthday: valid
            }
        })
    }

    onValidate = () => {
        let { valid } = this.state
        if(this.props.name === undefined || this.props.name === ''){
            this.setState({
                valid: {
                    ...this.state.valid,
                    name: false
                }
            })
        } else if(this.props.birthday === undefined || this.props.birthday === ''){
            this.setState({
                valid: {
                    ...this.state.valid,
                    birthday: false
                }
            })
        }
        if((this.props.name && valid.name) && (this.props.birthday && valid.birthday)) {
            this.setState({
                valid: {
                    name: true,
                    birthday: true
                }
            })
            this.props.onValidate()
        }
    }

    render(){
        return(
            <div className='px-3 mt-2'>
                <h6 className='modal-child-title'>Ajouter un enfant</h6>
                <div className='d-flex'>
                    <div className='w-50'>
                        <Input name='childName' required valid={this.state.valid.name} active={this.state.focusedName} type='text' className={this.state.focusedName ? 'input-focused': ''} placeholder="Nom de l'enfant" title="Nom de l'enfant" 
                            onChange={(e) => this.onChangeChildName(e)} value={this.props.name}
                            onFocus={() => this.focusName(this.state.focusedName)} onBlur={() => this.focusName(this.state.focusedName)}> 
                            <IconChild className='ic2' active={this.state.focusedName} width={17} height={17} />
                        </Input>
                    </div>
                    <div className='w-50 ml-3'>
                        <Input name='childSurname' valid active={this.state.focusedSurname} type='text' className={this.state.focusedSurname ? 'input-focused': ''} placeholder="Prénom de l'enfant" title="Prénom de l'enfant" 
                            onChange={(e) => this.props.onChangeChildSurname(e)} value={this.props.surname}
                            onFocus={() => this.focusSurname(this.state.focusedSurname)} onBlur={() => this.focusSurname(this.state.focusedSurname)}> 
                            <IconChild className='ic3' active={this.state.focusedSurname} width={17} height={17} />
                        </Input>
                    </div>
                </div>
                <Input name='childBirthday' required valid={this.state.valid.birthday} active={this.state.focusedBirthday} type='custom-date' className={this.state.focusedBirthday ? 'input-focused': ''} placeholder='Date de naissance' title='Date de naissance' 
                    onChange={(e) => this.onChangeChildBirthday(e)} value={this.props.birthday}
                    onFocus={() => this.focusBirthday(this.state.focusedBirthday)} onBlur={() => this.focusBirthday(this.state.focusedBirthday)}> 
                    <IconDate className='date' active={this.state.focusedBirthday} width={17} height={17} />
                </Input>
                <div className='d-flex modal-child-btn'>
                    <div className='w-50'>
                        <button className='btn btn-annuler w-100' onClick={this.props.onCancel}>Annuler</button>
                    </div>
                    <div className='w-50 ml-3'>
                        <button className='btn btn-validate w-100' onClick={this.onValidate}>Valider</button>
                    </div>
                </div>
            </div>
        )
    }
}