import React from "react";

const IconCalendar = props => (
  <svg width={`${ props.size ? props.size : "1em"}`} height={`${ props.size ? props.size : "1em"}`} viewBox="0 0 31.72 29.28" className={`${ props.className ? props.className : ""}`}>
    <path
      d="M28.67 2.44h-3.05v1.83a.612.612 0 01-.61.61h-1.22a.612.612 0 01-.61-.61V2.44H8.54v1.83a.612.612 0 01-.61.61H6.71a.612.612 0 01-.61-.61V2.44H3.05A3.059 3.059 0 000 5.49v20.74a3.059 3.059 0 003.05 3.05h25.62a3.059 3.059 0 003.05-3.05V5.49a3.059 3.059 0 00-3.05-3.05zm.61 22.875a1.529 1.529 0 01-1.525 1.525H3.965a1.529 1.529 0 01-1.525-1.525V11.59a.612.612 0 01.61-.61h25.62a.612.612 0 01.61.61zM8.54.61A.612.612 0 007.93 0H6.71a.612.612 0 00-.61.61v1.83h2.44zm17.08 0a.612.612 0 00-.61-.61h-1.22a.612.612 0 00-.61.61v1.83h2.44z"
      fill={`${ props.fill ? props.fill : "#555555"}`}
    />
  </svg>
);

export default IconCalendar;
