import React, { Component } from 'react'
import './OrgLoader.css'

class OrgLoader extends Component {
    render(){
        return(
            <div className="item-loader-wrapper">
                <div className="modal-logo-org-loader"/>
                <div className="modal-descript-org-loader"/>
            </div>
        )
    }
}

export default OrgLoader