import React, {Component} from "react";
import PropTypes from "prop-types";
import Header from "../../views/Header";
import NavLink from "../../views/NavLink";
import NavLinkResponsive from "../../views/NavLinkResponsive";
import MainContent from "../../views/MainContent";
import "./Dashboard.css";
import Footer from "../../views/Footer/Footer";
import AccountMenu from "../../components/dropdowns/AccountMenu/AccountMenu";
import OrgsTopModal from "../../components/modals/OrgsTopModal";

class DashboardLayout extends Component {
    constructor(props){
        super(props);

        this.state = {
            accountMenuActive: false,
            orgsModalOpened: false
        };
        this.navLinkResponsive = React.createRef();
        this.accountMenu = React.createRef();
        this.orgModal = React.createRef();
    
    }

    onItemMenuClicked  = (menuItem, e = null) => {
        //if(e) e.stopPropagation();
        switch(menuItem.id){
            case 1 : break;
            case 98 : this.setState({ orgsModalOpened: true }); break;
            case 99 : break;
            default: break;
        }
        this.setState({
            accountMenuActive: false
        })
    }

    onToggleAccountMenu = (e) => {
        e.stopPropagation();
        this.accountMenu.current.toggleAccountMenu();
    };

    onCloseOrgsModal = () => {
        this.setState({
            orgsModalOpened: false
        })
    };

    toggleMenu = () => {
        this.navLinkResponsive.current.toggleMenu();
    };

    showOrgMenu = () => {
        let org = document.querySelector(".switch-org-modal");
        if(org){
            org.classList.remove("hidden");
        }
    };

    render(){
        const MyOrgsTopModals = React.forwardRef((props, ref) => <OrgsTopModal innerRef={ref} {...props} />);
        return (
            <div className="main">
                <NavLink onClick={this.handleClick} match={this.props} className="sm-hidden" />
                <NavLinkResponsive ref={ this.navLinkResponsive } onClick={this.handleClick} match={this.props} />
                <MainContent>
                    <Header title={this.props.title} onToggleAccountMenu={this.onToggleAccountMenu} accountMenuActive={this.state.accountMenuActive} onToggleMenu={this.toggleMenu} />
                    <AccountMenu ref={this.accountMenu} onToggleOrgs={() => this.showOrgMenu()} onClose={this.onToggleAccountMenu} />
                    <div className="explicit-content">
                        {this.props.children}
                    </div>
                    <Footer />
                </MainContent>
                <MyOrgsTopModals opened={(e) => {}} onClose={(e) => {}}/>
            </div>
        )
    }
}
DashboardLayout.propTypes = {
    children: PropTypes.node
}

export default DashboardLayout