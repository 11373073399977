/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const DiZenComponent = (props) => (
  <svg width={ props.width ? props.width : 26} height={ props.height ? props.height : 26} viewBox="0 0 26 26" className={props.className ? props.className : "" }>
    <g transform="translate(-125.494 -64.494)">
      <g fill="none" stroke={props.color ? props.color : "#ffffff"} transform="translate(125.494 64.494)">
        <circle stroke="none" cx={13} cy={13} r={13} />
        <circle fill="none" cx={13} cy={13} r={12.5} />
      </g>
      <path
        fill={props.color ? props.color : "#ffffff"}
        d="M-4.537-4.336,7.038-16.444V-17.5H-6.837v1.325H4.588L-7.012-4.067v1.056H7.338V-4.336Z"
        transform="translate(138.463 88)"
      />
      <g transform="translate(133.795 76.446)">
        <line fill="none" stroke={props.color ? props.color : "#ffffff"} strokeLinecap="square" x2={9.398} transform="translate(0 2.096)" />
        <line fill="none" stroke={props.color ? props.color : "#ffffff"} strokeLinecap="square" x2={9.398} />
      </g>
    </g>
  </svg>
);

export default DiZenComponent;
