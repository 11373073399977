import React, {Component, lazy, Suspense} from 'react';
import { connect } from 'react-redux';
import moment from "moment";
import TriSearch from '../../components/TriSearch';
import InputFee from '../../components/InputFee';
import { setOrganizationInvoices, setSelectedInvoice, addMultipleOrganizationInvoice} from '../../redux/action/OrganisationAction';
import './Fees.css';
import { _searchOrganizationInvoices } from '../../services/Organization';
import { _getInvoiceById, _createMultipleInvoices } from '../../services/Invoice';
import { SESSION_STORAGE_KEYS, PAGINATION, ContentTypes } from '../../constants/constants';
import Logger from '../../helpers/Logger';
import { errorNotifcation } from "../../helpers/errorHandle";
import { numberFormat } from '../../helpers/Number';
import DateItemList from '../../components/DateItemList';
import TagContainer from '../../components/TagContainer';
import Loader from '../../components/Loader';
import IconClose from '../../components/Icons/IconClose';
import ScanPreview from '../../components/Scan/ScanPreview'
import reverseOrderIcon from '../../assets/svg/icon-reverseOrder.svg';
import attachBlack from '../../assets/svg/icon-attack-black.svg';
import DisplayContainer from '../../components/DisplayContainer';
import CustomImageZoom from '../../components/CustomImageZoom';
import { toastr } from 'react-redux-toastr';
const GenericTable = lazy(() => import("../../components/Table/GenericTable") );

class FeesPage extends Component{
    constructor() {
        super();
        this.state = {
            loading: false,
            invoices: [],
            filteredFees: [],
            filteredDate: [],
            filteredDataPending: [],
            filteredDataTreated: {
                checked: false,
                datas: []
            },
            term: '',
            showPreview: false,
            itemSelected: null,
            pageSize: PAGINATION.DEFAULT_PAGE_SIZE,
            startPage: PAGINATION.DEFAULT_PAGE,
            currentPage: PAGINATION.DEFAULT_PAGE,
            endIndex: null,
            endPage: null,
            pages: [],
            startIndex: null,
            totalItems: null,
            totalPages: null,
            count: null,
            hasMore: true,
            scrollLoading: false,
            isDetailLoading: false,
            isAttachmentVisible: false,
            loadPreview: false,
        };
        this.handleTri = this.handleTri.bind(this)
        
    }

    componentDidMount() {
        this.setState({
            loading: true
        });
        let defaultOrg = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
        this.getData(defaultOrg)
        // UseInvoiceSearch(defaultOrg.id, "", 10, 1);
    }

    handleShow = () => {
        this.setState({ showPreview: true });
    }
    handleHide = () => {
        if (this.genericTable) this.genericTable.unSelectAll();
        this.setState({ showPreview: false, isAttachmentVisible: false, isDetailLoading: false });
    }

    setItemSelected = async (item) => {
        this.setState({isDetailLoading: true});
        try{
            const defaultOrg = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
            const res = await _getInvoiceById(defaultOrg.id, item.originalData.id);
            if(res.status === 200){
                this.props.setSelectedInvoice(res.data);
                this.setState({ itemSelected: res.data, showPreview: true });
            }
        }catch(e){
            Logger.log("Detail invoice",'Error while getting fee\'s detail data' , e);
        }finally{
            this.setState({isDetailLoading: false});
        }
    }

    getData(defaultOrg){
        if (defaultOrg.id){
            _searchOrganizationInvoices(defaultOrg.id, "", this.state.pageSize).then(res => {
                const invoices = res.data.rows;
                this.setState({ 
                    loading: false,
                    currentPage: res.data.pager.currentPage,
                    endIndex: res.data.pager.endIndex,
                    endPage: res.data.pager.endPage,
                    pageSize: res.data.pager.pageSize,
                    pages: res.data.pager.pages,
                    startIndex: res.data.pager.startIndex,
                    startPage: res.data.pager.startPage,
                    totalItems: res.data.pager.totalItems,
                    totalPages: res.data.pager.totalPages,
                    count: res.data.count,
                });
                this.setOrganizationInvoices(invoices);
            }).catch(error => {
                this.setState({ 
                    loading: false,
                });
                Logger.log("FeesContent",'Error while loading fees', error);
            })
        }
    }

    setOrganizationInvoices = (invoices) => {
        this.setState({
            invoices
        });
        if(invoices) {
            this.props.setOrganizationInvoices(invoices)
        }
    }

    handleSearch = async (term) => {
        let defaultOrg = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
        if (defaultOrg.id){
            this.setState({
                searchTerm: term
            })
            if (term) {
                try {
                    const invoices = (await _searchOrganizationInvoices(defaultOrg.id, term)).data.rows;
                    this.setOrganizationInvoices(invoices);
                } catch (error) {
                    Logger.log("SearchInvoices",'Error while search organization fees', error);
                }
            } else this.getData(defaultOrg)
        }
    }

    handleTri = (key) => {
        let {pageOfItems} = this.state
        switch (key){
            case 0 :{
                let filterResult = pageOfItems.reverse()
                this.setState({
                    pageOfItems: filterResult
                })
                break
            }
            case 1 :{
                let filterResult = pageOfItems
                const {filteredDataTreated} = this.state
                if(filteredDataTreated.checked === false){
                    this.setState({
                        filteredDataTreated: {
                            checked: true,
                            datas: [...pageOfItems]
                        }
                    })

                    let results = filterResult.filter(item => 
                    item.status === "archived" || item.status === "closed"
                    )
                    
                    this.setState({
                        pageOfItems: results
                    })

                }else{
                    let results = this.state.filteredDataTreated.datas

                    this.setState({
                        pageOfItems: results,
                        filteredDataTreated: {
                            checked: false,
                        }
                    },() => { })
                }

                
                break
            }
            case 2 :{
                let filterResult = pageOfItems
                let results = filterResult.filter(item => 
                    item.status === "pending"
                )
                
                this.setState({
                    pageOfItems: results
                })
                break
            }
            default:{
                break
            }
        }
    }

    setRefGenericTable = (ref) => {
        this.genericTable = ref;
    }

    setInputFeeRef = (ref) => {
        this.inputFeeRef = ref;
    }

    setDetailLoading = (value) => {
        this.setState({
            isDetailLoading: value,
        })
    }

    createMultipleInvoices = async (payload) => {
        let organization = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
        _createMultipleInvoices(organization.id, payload).then((res) => {
            this.setInfiniteScrollData(res.data);
            this.props.setOrganizationInvoices(res.data.rows);
            toastr.success("", "Facture(s) importée(s) avec succès !");
        }).catch((err) => {
            const error = { ...err };
            Logger.error("CreateFee", "Create Fee Failed", error);
            errorNotifcation(error?.response?.status, "Echec d'enregistrement de la / les facture(s), veuillez reessayer ultérieurement");
        }).finally(() => {
            if(this.inputFeeRef) this.inputFeeRef.resetValues();
            this.setState({
                isDetailLoading: false,
            });
        });
    }

    getMoreInvoices = async () => {
        try{
            let defaultOrg = JSON.parse(sessionStorage.getItem('defaultOrg'));
            this.setState({ scrollLoading: true });
            const res = await _searchOrganizationInvoices(defaultOrg.id, this.state.term, this.state.pageSize, this.state.currentPage + 1);
            this.setInfiniteScrollData(res.data);
            this.props.addMultipleOrganizationInvoice(res.data.rows);
        } catch(err) {
            const error = { ...err };
            Logger.error("Infinite scroll : can not get invoices", error);
            errorNotifcation(error?.response?.status, "Erreur de chargement des factures veuillez rafraichir la page !");
        } 
    }

    setInfiniteScrollData = (data) => {
        this.setState({ 
            scrollLoading: false,
            currentPage: data.pager.currentPage,
            endIndex: data.pager.endIndex,
            endPage: data.pager.endPage,
            pageSize: data.pager.pageSize,
            pages: data.pager.pages,
            startIndex: data.pager.startIndex,
            startPage: data.pager.startPage,
            totalItems: data.pager.totalItems,
            totalPages: data.pager.totalPages,
            count: data.count,
        });
    }

    headerData = [
        { title: <span> Date <img src={reverseOrderIcon} alt="date" width={10} height={10} /></span> , align: "center", className: 'pl-1 fees-date-width', name: "date"}, 
        { title: <span> Fournisseur <img src={reverseOrderIcon} alt="deliver" width={10} height={10} /></span> , align: "left", className: 'pl-15 fees-deliver-width', name: "deliver"}, 
        { title: <span> Montant <img src={reverseOrderIcon} alt="amount" width={10} height={10} /></span> , align: "right", className: 'text-align-right justify-content-end fees-amount-width pr-1', name: "totalAmount"}, 
        { title: <span> TVA <img src={reverseOrderIcon} alt="tva" width={10} height={10} /></span> , align: "right", className: 'text-align-right fees-vat-width justify-content-end sm-hidden', name: "vat"}, 
        { title: <span> Observation <img src={reverseOrderIcon} alt="observation" width={10} height={10} /></span> , align: "left", className: 'pl-15 fees-observation-width sm-hidden', name: "observations"}, 
        { title: <span> <img src={attachBlack} alt="Attach" width={7} height={'auto'} /></span> , align: "left", className: 'icon-attach-h fees-attachment-width justify-content-center img sm-hidden ', name: "attachment"}, 
    ];

    formatBodyData = (data) => {
        return data.map((item, i) => {
            let resData = {
                originalData: item,
                date: <DateItemList key={i} date={item.date ? moment(item.date).format('YYYY/MM/DD') : moment(item.createdAt).format('YYYY/MM/DD')} active={item.totalAmount} type={'fees'} />,
                deliver: <span>{item.deliver}</span>,
                totalAmount: (item.totalAmount) ? (<div className="total-tva"><span className="devise"> {`${item.currency} `} </span> {`${numberFormat(item.totalAmount)}`} </div>) : '',
                vat: (item.vat) ? (<div><span className="devise"> {`${item.currency} `} </span> {`${numberFormat(item.vat)}`} </div>) : '',
                observations: <TagContainer tags={item.observations} slice/>,
                attachment: <img src={attachBlack} alt="Attach" width={7} height={'auto'} />
            }
            return resData;
        });
    }

    openScanPreview = (item, attachment, visibility) => {
        this.setState({ isAttachmentVisible: visibility });
    }

    closeScanPreview = () => {
        this.setState({ isAttachmentVisible: false });
    }

    toggleLoaderPreview = () => {
        this.setState({ loadPreview: !this.state.loadPreview })
    }

    render(){
        return(
            <div className="fees-content" style={{ flex: '1' }}>
                <div className="fees">
                    <TriSearch handleSearch={this.handleSearch} handleTri={this.handleTri} loading={this.state.isDetailLoading} className="pl-3 pr-3" />
                    <InputFee onRef={this.setInputFeeRef} onSave={this.createMultipleInvoices} setLoading={this.setDetailLoading} className="sm-hidden ml-3 mr-3"/>
                    <div className="display-table mb-1 pl-lg-3 pr-lg-3">
                        <Suspense fallback={<div className="text-center">Chargement... </div>} >
                            <GenericTable
                                data={this.props.invoices}
                                onRef={this.setRefGenericTable}
                                header={this.headerData}
                                body={this.formatBodyData(this.props.invoices)}
                                className='mt-20 mb-1 mlr-1'
                                hoverColor="rgba(255, 219, 219, 0.2)"
                                isLoading={this.state.loading}
                                asLoader={<Loader style={{width: "25px", height: "25px"}} enable />}
                                onSelect={this.setItemSelected}
                                infiniteScroll
                                startPage={this.state.startPage + 1}
                                getData={this.getMoreInvoices}
                                hasMore={(this.state.currentPage < this.state.endPage)}
                                scrollLoading={this.state.scrollLoading}
                            />
                        </Suspense>
                    </div>
                    {   this.state.isAttachmentVisible ? 
                            <div className={`attachment-prefiew d-flex align-items-center justify-content-center ${ this.state.isAttachmentVisible ? "show fade-in" : ""}`}>
                            {
                                this.state.itemSelected.attachment ? (this.state.itemSelected.contentType === ContentTypes.pdf) ?
                                    <div className="preview-file-container">
                                        <button className='btn-close' onClick={this.closeScanPreview} type="button"><IconClose color="#FFFFFF" /></button>
                                        <object data={this.state.itemSelected.attachment} 
                                            width='100%'
                                            height='100%'
                                            type="application/pdf"
                                        >
                                        </object>
                                    </div>
                                    
                                :
                                <div className="preview-file-container">
                                    <CustomImageZoom image={this.state.itemSelected.attachment} alt='Scan image' allowCloser onClose={this.closeScanPreview} />
                                </div> : null
                            }   
                        </div> : <></>
                    }
                    
                </div>
                { this.state.showPreview ?
                    <div className={'fee-detail'}>
                        <DisplayContainer headerTile={'Detail facture'} onClose={this.handleHide} loading={this.state.loadPreview} >
                            <Suspense fallback={<span>...</span>} >
                                <ScanPreview item={this.state.itemSelected} handleAttachment={this.openScanPreview} toggleLoader={this.toggleLoaderPreview} />
                            </Suspense>
                        </DisplayContainer>
                    </div> : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { auth, organizations } = state
    return {
        authData: auth.authData,
        organizations: organizations.organizations,
        invoices: organizations.organizationInvoices,
        defaultOrg: organizations.defaultOrganization,
    }
}

export default connect(mapStateToProps, { setOrganizationInvoices, setSelectedInvoice, addMultipleOrganizationInvoice })(FeesPage)