import { _extractPayloadFromToken } from './jwt';
import { SESSION_STORAGE_KEYS } from '../constants/constants';

export default class UserAuth {

    static _getUser(){
       return  _extractPayloadFromToken(this._getTokens().id_token);
    }

    static _getTokens(){
        const tokenJson = sessionStorage.getItem(SESSION_STORAGE_KEYS.TOKEN);
        if(!tokenJson){
            throw new Error("Cannot read token");
        }
        return JSON.parse(tokenJson);
    }

    static get _getCurrentOrganization(){
        return JSON.parse(sessionStorage.getItem('defaultOrg'));
    }

    static _hasHabilitation(habilitation){
        const habilitations = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.HABILITATIONS));
        const res = habilitations.find(elm => elm.functionalityCode === habilitation);
        return res ? true : false;
    }
}
