import React, { Component } from 'react'
import IconClose from './IconClose';
import PricetagIcon from './PricetagIcon';
import { TAG_TYPES } from '../../constants/constants';
import './Tag.css';

export default class Tags extends Component {

    constructor(props){
        super(props);
        this.state = {
            id: this.props.id
        }
    }

    removeItem = () => {
        if(this.props.onRemove){
            this.props.onRemove(this.props.data);
        }
    }
    
    renderCloser = () => {
        if(this.props.editable){
            let { active } = this.props;
            return (
                <span className={`btn-renover  ${(active) ? "active" : ""}`} onClick={(e) => { e.stopPropagation(); this.removeItem() } }>
                    <IconClose width={6} height={6} color='#B5B5B5' />
                </span>
            )
        }
    }
    onTagClick = () => {
        if(this.props.onTagClick){
            this.props.onTagClick(this.props.data);
        }
    }

    renderOriginalTag = () => {
        const { title, active, slice } = this.props;
        return (
            <div className={`tag ${(active) ? "active" : ""} ${(this.props.editable) ? "pointer" : ""}`} onClick={() => this.onTagClick()}>
                <span className={`price-tag-box ${(active) ? "active" : ""}`}>
                    <PricetagIcon 
                        width={10} 
                        height={10} fill={`${(active) ? "#FFFFFF" : "#b5b5b5"}`} 
                        stroke={`${(active) ? "#FFFFFF" : "#b5b5b5"}`} 
                        strokeWidth={0.5}/>
                </span>
                <span className={`tag-title ${(active) ? "active" : ""}`} >{slice ? title > 10 ? title.slice(0,7) + '...' : title : title}</span>
                { this.renderCloser() }
            </div>
        )
    }

    renderTag = () => {
        return (
            <div className={`tag-row ${(this.props.active) ? "active" : ""} ${(this.props.editable) ? "pointer" : ""}`} onClick={() => this.onTagClick()}>
                <div className="content-tag">{this.props.title}</div>
                { this.renderCloser() }
            </div>
        )
    }

    renderSwitchTag = () => {
        switch (this.props.type) {
            case TAG_TYPES.ROW:
                return this.renderTag();
        
            default:
                return this.renderOriginalTag();
        }
    }

    render() {
        return (this.renderSwitchTag());
    }
}