import React, { Component } from "react";
import { connect } from 'react-redux';
import { setSelectedInvoice } from '../../redux/action/OrganisationAction';
import moment from "moment";
import DateItemList from "../DateItemList";
import IconLink from '../Icons/IconLink';
import IconUnlink from '../Icons/IconUnlink';
import { _getInvoiceById } from '../../services/Invoice';
import { numberFormat } from '../../helpers/Number';
import TruncateString from "../TruncateString";
import Logger from "../../helpers/Logger";
import { errorNotifcation } from "../../helpers/errorHandle";

import "./InvoiceRowItem.css";

class InvoiceRowItem extends Component {
    constructor(props){
        super(props);

        this.state = {
            item: {},
            displayLink: false,
            displayUnlink: false,
        }
        this._isMounted = false;
    }

    componentDidMount = () => {
        this._isMounted = true;
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    getInvoiceById = async () => {
        let defaultOrganization = JSON.parse(sessionStorage.getItem('defaultOrg'));
        try {
            const invoice = (await _getInvoiceById(defaultOrganization.id, this.props.data.id)).data;
            return invoice;
        } catch (error) {
            Logger.error(`INVOICE", "Could not get invoice with id ${this.props.data.id}`, error);
            errorNotifcation(error?.response?.status, "Impossible de récupérer la facture, veuillez reessayer ultérieurement.");
        }
    }

    handleAttachment = (e) => {
        e.stopPropagation();
        this.getInvoiceById().then(result => {
            this.props.setSelectedInvoice(result);
            if(this.props.onHandleAttachment){
                this.props.onHandleAttachment(result);
            }
        });
    }

    handleDetail = (e) => {
        e.stopPropagation();
        this.getInvoiceById().then(result => {
            this.props.setSelectedInvoice(result);
            if(this.props.handleDetail){
                this.props.handleDetail(result);
            }
        });
    }

    handleClick = (e) => {
        e.stopPropagation();
        if(!this.props.detail) this.props.onClick(this.props.data);
        else if(this.props.unreconcile) this.props.unreconcile(this.props.data.id);
    }

    renderAction = () => {
        return(
            <button className={ (this.props.detail) ? "invoice-action invoice-detail" : "invoice-action invoice-unreconciled" } onClick={this.handleClick}>
                <span className={(this.props.detail) ? 'ico-link fade-in' : 'ico-link opacity-0'}><IconLink /></span> 
                {   
                    (this.props.detail) ?
                        <span className={'ico-unlink opacity-0 fade-in'}><IconUnlink /></span>
                    : null
                }
            </button>
        )
    }

    render(){
        const date =  moment(new Date(this.props.data.date || this.props.data.createdAt)).format('YYYY/MM/DD');
        return(
            <div className={ this.props.activeItem ? 'invoice-row-item invoice-active' : 'invoice-row-item' } onClick={this.props.detail ? this.handleDetail : this.handleAttachment}>
                <span>
                    <DateItemList date={date} active={this.props.data.bankRecords && this.props.data.bankRecords.length} type={'fees'} />
                </span>
                <span className="invoice-deliver invoice-statement"> <TruncateString text={ this.props.data.deliver } maxWidth={130} /> </span>
                <span className="invoice-amount invoice-statement"> { numberFormat(this.props.data.totalAmount) } { this.props.data.currency } </span>
                {
                    this.renderAction()
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { organizations } = state;
    return {
        selectedInvoice: organizations.selectedInvoice,
    }
}

export default connect(mapStateToProps, { setSelectedInvoice })(InvoiceRowItem)