import React, { Component } from 'react';
import DashboardLayout from '../Dashboard/DashboardLayout';
import UsersContent from "./UsersContent";

class UsersPage extends Component {
    constructor(props){
        super(props);
        this.state={};
    }
    render() {
        return (
            <DashboardLayout title={'Utilisateurs'}>
                <UsersContent {...this.props}/>
            </DashboardLayout>
        );
    }
}

export default UsersPage;