import React, {Component} from 'react'
import { Redirect } from 'react-router-dom'
import './SwitchOrgs.css'
import { connect } from 'react-redux';
import SwitchOrgsLoader from '../../components/loaders/SwitchOrgsLoader';
import { _getOrganizationsByUserId } from '../../services/Organization';
import { setOrganizations, setDefaultOrganizationWithHabilitations } from '../../redux/action/OrganisationAction';
import { _extractPayloadFromToken } from '../../helpers/jwt';
import CreateOrg from '../CreateOrg';
import OrgsEmpty from '../../components/loaders/OrgsEmpty';
import { SESSION_STORAGE_KEYS } from '../../constants/constants';
import Logger from '../../helpers/Logger';
import { errorNotifcation } from "../../helpers/errorHandle";

class SwitchOrgs extends Component {

    constructor(props){
        super(props);
        this.state = { 
            redirect: false,
            loading: false,
            organizations: [],
            create: false
        }
        this._isMounted = false;
    }

    handleSelect = (org) => {
        this.props.setDefaultOrganizationWithHabilitations(org.id);
        this.setState({
            redirect: true,
        });
    }

    componentDidMount = async () => {
        this._isMounted = true;
        const authToken = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.TOKEN));
        let payload = _extractPayloadFromToken(authToken.access_token);
        let id = payload.sub
        if(id) {
            await this.getUserOrganizations(id);
        }
    }
    componentWillUnmount = () => {
        this._isMounted = false;
    }

    getUserOrganizations = async (userId) => {
        this.setLoading(true);
        try{
            const res = await _getOrganizationsByUserId(userId);
            this.props.setOrganizations(res.data);
            if (res?.data && res?.data.length === 1) this.handleSelect(res?.data[0]);
            return res?.data;
        }catch(error){
            errorNotifcation(error?.response?.status, "Erreur lors de la récupération des organisations, veuillez réessayer ultérieurement");
            Logger.error("getUserOrganizations","Error occured when getting organizations", error);
        }finally{
            this.setLoading(false);
        }
    }


    onCreateOrg = () => {
        this.setState({
            create: true
        });
    }

    setLoading = (loading) => {
        this.setState({
            loading
        });
    }

    loadOrgsContent = () => {
        if(this.state.loading) {
            return (
                <>
                    <SwitchOrgsLoader/>
                    <SwitchOrgsLoader/>
                    <SwitchOrgsLoader/>
                </>
            );
        } else {
            if (!this.props.organizations?.length) return <OrgsEmpty onCreateOrg={this.onCreateOrg}/>
            return (
                <React.Fragment>
                    {
                        this.props.organizations.map((org, index) => {
                            return (
                                <div className="item-list" onClick={() => {this.handleSelect(org)}} key={index}>
                                    <div className="item-org-logo-wrapper" style={{"--logo-url": "url(" + org.logo + ")"}}>
                                        {/*
                                            org.logo ? <img className="item-org-logo" src={org.logo} alt="Organization logo"/> : null
                                        */}
                                    </div>
                                        <div className="org-description">
                                            <div className="orgName">{org.name}</div>
                                            <div className="socialReason">{org.invoiceEmail}</div>
                                        </div>
                                </div>
                            )
                        })
                    }
                </React.Fragment>
            );
        }
    }


    render(){
        const { redirect, create } = this.state
        if(redirect) return <Redirect to={"/fees"} />
        if(create) return <CreateOrg/>
        return (
            <div className="switch-orgs">
                <div className="switch-content-list">
                    <h6 className="small-title mb-3"> veuillez sélectionner une société </h6>
                    <div className="switch-content">
                        { this.loadOrgsContent() }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { auth, organizations } = state
    return {
        authToken: auth.authToken,
        organizations: organizations.organizations
    }
}

export default connect(mapStateToProps, { setOrganizations, setDefaultOrganizationWithHabilitations })(SwitchOrgs)