import React, {PureComponent, createRef} from 'react';
import {PropTypes} from 'prop-types';
import moment from 'moment';
import './EditableInput.css';
import { numberFormat } from '../../helpers/Number';
import {Overlay, Popover} from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import IconArrowUpAndDown from "../Icons/IconArrowUpDown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class EditableInput extends PureComponent{
    constructor(props){
        super(props);

        this.state = {
            state: this.props.state || 'init',
            mode: this.props.mode || 'record',
            value: this.props.value,
            previousValue: this.props.value,
            name: this.props.name,
            boxSizing: this.props.boxSizing,
            startDate: new Date(),
            placeholder: this.props.placeholder,
            overlayOpend: false,
        };

        this.customDateRef = createRef();
        this.target =  createRef();
    }

    setStartDate = (date) => {
        this.setState({
            startDate: date,
        })
    }

    onpenCustomSelect = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    renderDropdownData = () => {
        let { name, options, optionKey, optionValue, prefixLabel, suffixLabel, optionIcon, placeholder, custom, customContent } = this.props;
        if(custom && customContent){
            return customContent;
        }else{
            return(
                <Dropdown.Menu className="input-drop-down-menu text-color-input" name={name ? name : ''}>
                    <Dropdown.Item className="text-color-input border-bottom-dashed" key={`item-default`} onClick={(e) => this.props.onSelect({})}>
                        { optionValue ? optionValue : placeholder ? placeholder : 'Select an item' }
                    </Dropdown.Item>
                    {
                        options.map((item, index) => (
                            <Dropdown.Item className="text-color-input" active={item[optionKey] === optionValue} key={`item-${index}`} onClick={(e) => this.props.onSelect(item)}>
                                {optionIcon ? optionIcon : <></>}
                                {`${prefixLabel ? prefixLabel : ''} ${item[optionKey]} ${suffixLabel ? suffixLabel : ''}`}
                            </Dropdown.Item>
                        ))
                    }
                    { custom ?
                        <Dropdown.Item className="text-color-input border-top-dashed mt-1" onClick={this.onpenCustomSelect}> Definir une plage </Dropdown.Item>
                        : <></>
                    }
                </Dropdown.Menu>
            )
        }
        
    }

    // Render input form
    renderInputForm = () => {
        let { name, options, optionValue, optionIcon, required, id, placeholder } = this.props;
        let icon = this.props.withIcon;
        const globalClassName = this.props.globalClassName;
        const dateValue = this.props.value ? this.props.value : "";
        return(
            <div className={`valueRecord ${this.renderBoxSizingClassName()}  ${icon ? 'withIcon' : ''} ${globalClassName ? globalClassName : ''} ${this.props.error ? 'ei-error' : ''}`}>
                <div className="icon-comp">
                    {
                        (this.props.type === 'select') && (this.state.state === 'init' || this.state === 'preview') ? <></> : icon ? icon   : <></>
                    }
                </div>
                {
                    this.props.type === 'select' ?
                    <select name={name} className={`input-txt ${this.props.className ? this.props.className : ''}`} id={id ? id : ''} required={ required ? true : false }
                        onChange={this.handleChange} value={(this.props.isProps ? this.props.value || '' : this.state.value) || this.props.selectedValue} onBlur={this.onBlur} autoFocus={true}>
                        {
                            placeholder ? <option value="">
                                {placeholder}
                            </option> : ''
                        }
                        {
                            options.map((option, index) => {
                                if(option){
                                    return <option key={index} value={option[optionValue]} className={`${this.props.optionClassName ? this.props.optionClassName : ''}`}>
                                        {optionIcon ? option[optionIcon] : null} {option[optionValue]}
                                    </option>
                                }
                                return null
                            })
                        }
                    </select> :
                    this.props.type === 'customSelect' ?
                    <Dropdown className="input-drop-down">
                        <Dropdown.Toggle className="input-drop-down-btn text-color-input" variant={this.props.variant}>
                            { this.props.withIcon ? this.props.withIcon : <></>}
                            <div className="d-flex align-items-center justify-content-between flex-1-auto ml-1">
                                <span className="input-nane">
                                    { this.props.optionValue ? this.props.optionValue : this.props.placeholder ? this.props.placeholder : 'Select an item' }
                                </span>
                                <span className="p-2 input-arrow"> <IconArrowUpAndDown /> </span>
                            </div>
                        </Dropdown.Toggle>
                        {
                            this.renderDropdownData()
                        }
                    </Dropdown> :
                    this.props.type === 'customDate' ?
                    <DatePicker
                        selected={ dateValue }
                        onChange={this.handleChangeDate}
                        onBlur={this.onBlurCustomDate}
                        onClick={this.onClick}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        dateFormat="dd/MM/yyyy"
                        todayButton="Aujourd'hui"
                        placeholderText={`${this.props.placeholder || ''} ${ required ? "*" : ""}`}
                        autoFocus
                        popperPlacement={this.props.placement ? this.props.placement : "bottom-start"}
                        popperClassName="zi-9999"
                    />
                    :
                    <input 
                        type={this.props.type} 
                        id={id ? id : ''}
                        name={this.props.name} 
                        value={this.props.isProps ? this.props.value : this.state.value}
                        className={`input-txt ${this.props.className ? this.props.className : ''}`} 
                        placeholder={`${this.props.placeholder || ''} ${ required ? "*" : ""}`}
                        required={this.props.required ? true : false}
                        disabled={this.props.disabled ? true : false}
                        multiple={this.props.multiple ? true : false}
                        onChange={this.handleChange}
                        onBlur={this.onBlur}
                        onFocus={this.onFocus}
                        autoFocus
                    />
                }
                
            </div>
            
        )
    }

    renderPreviewForm = () => {
        const {value, placeholder, required, id, name} = this.props;
        let icon = this.props.withIcon;
        let type = this.props.type;
        const className = this.props.className;
        const globalClassName = this.props.globalClassName;
        return (
            <div className={`valuePreview ${icon ? 'withIcon' : ''} ${globalClassName ? globalClassName : ''}`} id={id ? id : ''} name={name ? name : ''} onClick={this.onClick}>
                <div className="icon-comp">
                    {
                        icon ? icon   : <></>
                    }
                </div>
                {(type === "customDate") ?  
                <div className="custom-date-preview ml-1"> {this.state.value ? moment(this.props.value).format('DD/MM/YYYY') : this.props.placeholder ? this.props.placeholder : "Selectionnez une date"}{` ${ required ? "*" : ""}`} </div>
                :
                    (value || (value === 0)) ? 
                    <div className={`txt-preview  ${className ? className : ''}`}>{ type ==='number' ? numberFormat(value): type === 'date' ? moment(value).format('DD/MM/YYYY') : value} </div>
                :  <div className={`txt-preview  ${className ? className : ''}`}>{ placeholder || '' } { required ? <span className={'required ml-1'}>*</span> : "" } </div> }
            </div>
        )
    }

    onFocus = (event) => {
        if(this.props.onFocus){
            this.props.onFocus(event);
        }
    }

    onCloseCalendar = (date) => {
        let event = { target: {value : new Date(date) } }
        if(this.props.onBlur){
            this.props.onBlur(event);
        }
        if(this.props.state === 'preview'){
            this.setState({
                state: 'preview'
            })
        }
    }

    onBlur = (event) => {
        let updatedData = {
            id: this.props.id,
            [this.props.name]: event.target.value
        }
        event = { updatedData, ...event  }
        if(this.props.onBlur){
            this.props.onBlur(event);
        }
        if(this.props.state === 'preview'){
            this.setState({
                state: 'preview'
            })
        }
    }

    setPreview = () => {
        const { previousValue, value } = this.state;
        if(previousValue !== value){
            this.setState({
                state: 'preview'
            });
        }
    }

    handleChangeDate = (date) => {
        const d = moment(date).format("DD/MM/YYYY")
        let event = { target: {value : date } }
        this.setState({
            value: d,
            previousValue: d,
            // state: 'preview'
        }, () => {
            if(this.props.onChange){
                this.props.onChange(event);
            }
        });
    }

    onBlurCustomDate = (e) => {
        this.setState({
            state: 'preview'
        }, (e) => {
            if(this.props.onBlur){
                this.props.onBlur(e);
            }
        })
    }

    handleChange = (event) => {
        this.setState({
            value: event.target.value
        })
        if(this.props.onChange){
            this.props.onChange(event);
        }
    }

    onClick = () => {
        const { editable, value } = this.props;
        this.setState({value: value});

        if(editable){
            this.setState({
                state: 'init'
            });
        }
    }

    formatEntryDate = (date) => {
        // incomming format: DD/MM/YYYY
        if(date.indexOf("/")){
            const regex = /\//gi;
            let dateValue = date.replaceAll(regex, '-')
            const part = dateValue.split("-");
            return new Date(part[2] + "-" + part[1] + "-" + part[0]);
        }
        return new Date(moment(date, "DD/MM/YYYY").format("YYYY-MM-DD"));
    }

    renderElement = () => {
        const {state} = this.state;

        if(state === 'init'){
            return this.renderInputForm();
        }else{
            return this.renderPreviewForm();
        }
    }

    render(){
        let { withClass, type, error, style } = this.props;
        const errMsg = error?.msg ? error.msg : "Champ obligatoire";
        const stringMsg = error?.msg?.props?.children || '';
        let styles = style = {...style, '--error-msg': errMsg};
        return(
            <>
                <div ref={this.target} className={`EditableInput ${ type === 'select' && withClass && this.state.state === 'init'  ? withClass : ''} ${error?.msg ? 'ei-error' : ''}`} style={styles} content={stringMsg} >
                    { 
                        this.renderElement()
                    }
                </div>
                <Overlay target={this.target.current} show={error?.msg ? true: false} placement={error?.placement ? error?.placement: "top"}>
                    {
                        error?.msg ? <Popover id="popover-contained" bsPrefix="popover err">{error?.msg}</Popover> : <div></div>
                    }
                </Overlay>
            </>
        )
    }

    renderBoxSizingClassName = () => {
        switch (this.props.boxSizing) {
            case "inherit":
                return "boxSizing-inherit"
        
            default:
                return "";
        }
    }
}

EditableInput.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    agent: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
        PropTypes.instanceOf(Date)
    ]),
    type: PropTypes.oneOf(['checkbox','date','email','customDate','number','password','radio','search','select','tel','text','time','url']),
    state: PropTypes.oneOf(['init','preview']),
    mode: PropTypes.oneOf(['record','patch']),
    boxSizing: PropTypes.oneOf(['initial','inherit']),
    placeholder: PropTypes.string,
    multiple: PropTypes.bool,
    editable: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.object,
    className: PropTypes.string,
    optionClassName: PropTypes.string,
    globalClassName: PropTypes.string,
    withIcon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
}

export default EditableInput;