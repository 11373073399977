import React from "react";

const SampleFlagComponent = props => (
  <svg viewBox="0 0 18.001 13.185" width={props.width ? props.width : 17} height={props.height ? props.height : 17} >
    <defs>
      <style>{".a{fill:#177bd1;}"}</style>
    </defs>
    <path
      fill={`${props.color ? props.color : '#177bd1'}`}
      d="M-11764.48-1354.815a6.033,6.033,0,0,1-3.768-.978,5.627,5.627,0,0,0-3.518-.913,22.2,22.2,0,0,0-5.625.913v-11.078a7.779,7.779,0,0,1,4.422-1.129,9.98,9.98,0,0,1,4.721,1.129,7.913,7.913,0,0,0,3.79.812,18.536,18.536,0,0,0,5.068-.812v11.312l-.009,0A21.394,21.394,0,0,1-11764.48-1354.815Zm-3.191-6.116a.131.131,0,0,1,.077.025l.266.192a.122.122,0,0,0,.068.024h.009a.144.144,0,0,0,.105-.049.187.187,0,0,0,.048-.147l-.029-.366a.2.2,0,0,1,.056-.154l.242-.242a.2.2,0,0,0,.047-.191.154.154,0,0,0-.108-.113l-.308-.074a.161.161,0,0,1-.106-.106l-.115-.34a.153.153,0,0,0-.127-.109h-.007a.158.158,0,0,0-.135.089l-.163.322a.157.157,0,0,1-.118.089l-.316.032a.154.154,0,0,0-.124.1.2.2,0,0,0,.021.195l.207.275a.2.2,0,0,1,.033.16l-.08.361a.194.194,0,0,0,.033.158.137.137,0,0,0,.1.058.189.189,0,0,0,.071-.015l.292-.154A.133.133,0,0,1-11767.672-1360.931Z"
      transform="translate(11777.391 1368)"
    />
  </svg>
);

export default SampleFlagComponent;
