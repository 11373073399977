import React, { Component } from 'react';

export default class TreasuryContent extends Component {
    constructor(props){
        super(props);
        this.state = {};
    }

    randomNumber = (min = 0, max = 10) => {
        return Math.floor(Math.random() * (max - min) + min)
    }

    renderTBody = () => {
        const tabFirstnames = ["BOUMO","NGNENTIDEM","NKAMEN","MONTHE","MBAKOP","CHEPING","BOUOPDA","BAKAM","NIMPE","NUEKUMO","FAGDIO"];
        const tabLastnames = ["Marius","Michael","Boris","Happi","Bettina","Line","Murielle","Sandrine","Aurelien","Landry","Wilfried"];
        const tabEmails = ["marius@contact.com","michael@contact.com","Boris@contact.com","Happi@contact.com","Bettina@contact.com","Line@contact.com","Murielle@contact.com","Sandrine@contact.com","Aurelien@contact.com","Landry@contact.com","Wilfried@contact.com"];
        let rows = [];
        for(let i = 1 ; i <= 500 ; i++){
            rows.push(<tr>
                    <td>{i}</td>
                    <td>{tabFirstnames[this.randomNumber(0, (tabFirstnames.length - 1))]}</td>
                    <td>{tabLastnames[this.randomNumber(0, (tabLastnames.length - 1))]}</td>
                    <td>{this.randomNumber(600000001, 699999999)}</td>
                    <td>{tabEmails[this.randomNumber(0, (tabEmails.length - 1))]}</td>
                    <td><input type="radio" name="stand" value={1} /></td>
                </tr>
            );
        }
        return rows.map((elm, idx) => {
            return elm;
        });
    }

    render() {
        return(
            <div className="teasury-content d-flex flex-column flex-1-auto">
                <table className="tab table table-bordered table-hover table-responsive-sm table-striped" width="100%">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>NOM</th>
                            <th>PRENOM</th>
                            <th>TELEPHONE</th>
                            <th>EMAIL</th>
                            <th>ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderTBody()}
                    </tbody>
                    <tfoot>
                    <tr>
                        <th>ID</th>
                        <th>NOM</th>
                        <th>PRENOM</th>
                        <th>TELEPHONE</th>
                        <th>EMAIL</th>
                        <th>ACTION</th>
                    </tr>
                    </tfoot>
                </table>
            </div>
        )
    }
}