import moment from 'moment';
import 'moment/locale/fr';
const DEFAULT_DATE_FORMAT="YYYY/MM/DD";
/**
 * Format a date in the format 21 Oct 1960
 * @param {String} date 
 */
export const dateFormatPart = (date) => {
    // NOTE: Format date hs to be -> YYYY/MM/DD  eg: 2019/07/18
    const months = ['','Jan','Feb','Mar','Apr','May','Juin','Juil','Aug','Sep','Oct','Nov','Dec']
    let part = date.split('/')
    const year = part[0]
    const day = part[2]
    const month = months[part[1]*1]
    const res = day + " " + month + " " + year
    return res
}

export const formatDate = (date, format, dateFormat) => {
    moment.locale("fr");
    dateFormat = dateFormat || DEFAULT_DATE_FORMAT;
    return moment(date, dateFormat).format(format);
}

export const _seniority = (date) => {
    const currentDate = new Date();
    const diff = currentDate.valueOf() - new Date(date).valueOf();
    return moment.duration(diff, 'milliseconds').format('Y');
}