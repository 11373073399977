import React, { Component } from 'react';
// import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import "react-datepicker/dist/react-datepicker.css";
import './inputs.css';

export default class Inputs extends Component {

    constructor(props){
        super(props);

        this.state = {
            value: this.props.value,
            name: this.props.name
        }
    }

    getLabelClass = (active) => {
        let className = "m-0 label-input";
        if (active) {
            className += " active-label ";
        }
        return className;
    }
    beforeMaskedValueChange = (newState, oldState) => {
       
        function fillInMaskWithLetters(value) {
       
            if (!value) {
                return '';
            }
       
            const [year, month, day] = value.split('/');
            return [
                replaceMaskWithLetter(year, 'Y', 4),
                replaceMaskWithLetter(month, 'M', 2),
                replaceMaskWithLetter(day, 'D', 2)
            ].join('/');
        }
       
        function replaceMaskWithLetter(value, letter, length) {
       
            if (!value)
                value = '';
       
            value = value.replace(/[^0-9]/g, '');
            return value + _repeat(letter, length - value.length);
        }
       
        function _repeat(letter, length) {
            return letter.repeat(length);
        }
       
        function findFirstPlaceholderIndex(value) {
       
            const placeholderPositions = [
                value.indexOf('D'),
                value.indexOf('M'),
                value.indexOf('Y')
            ].filter(position => position >= 0);
       
            if (placeholderPositions.length === 0)
                return null;
            return Math.min(...placeholderPositions);
        }
       
       
        let value = fillInMaskWithLetters(newState.value);
        let selection = newState.selection;
        selection = newState.selection;
       
        let index = findFirstPlaceholderIndex(value);
       
       
        if (findFirstPlaceholderIndex(value) === null) {
            index = 0;
        }
       
        if (findFirstPlaceholderIndex(value) === null && (Math.max(0, value.length - 1) === 9)) {
            index = 9;
        }
       
        // if(allNumbers(value)){
        //     index=this.state.htmlRenderedElement.selectionStart;  
        // }
       
        selection = {
            start: index,
            end: index,
            length: 1
        };
       
        return { value, selection };
    }

    handleChangeDate = (date) => {
        let event = { target: {value : moment(date).format('YYYY/MM/DD') } }
        this.setState({
            value: moment(new Date(date)).format('YYYY/MM/DD')
        })
        if(this.props.onChange){
            this.props.onChange(event);
        }
    }

    onCloseCalendar = (date) => {
        let event = { target: {value : moment(new Date(date)).format('YYYY/MM/DD') } }
        if(this.props.onBlur){
            this.props.onBlur(event);
        }
    }

    render() {
            let { required, title, value, name, placeholder, valid, errorMsg, type, options, optionIcon, optionValue, onChange, onFocus, onBlur, active, extraLabel, children, className, readonly } = this.props
            return(
                <div className={`form-group input-box`}>
                    <label className={this.getLabelClass(active)} htmlFor={title}>{title} {required ? <span className={'required ml-1'}>*</span> : ''}
                    {errorMsg ? <span className='input-hasError'>{errorMsg}</span> : extraLabel ? <span className='extra-label'>{extraLabel}</span> : null}</label>
                    <div className= {`${valid ? '' :'input-error'} input-group align-items-center ${className} `}>
                        {children}
                        {
                            type === 'select' ? 
                            <select name={name ? name : ''} className={'form-control input'} onChange={onChange} onFocus={onFocus} value={value} onBlur={onBlur}>
                                {
                                    options.map((option, index) => {
                                        if(option){
                                            return <option key={index} value={option[optionValue]}>
                                                {optionIcon ? option[optionIcon] : null} {option[optionValue]}
                                            </option>
                                        }
                                        return null
                                    })
                                }
                            </select> : 
                            type === 'custom-date' ? 
                            <DatePicker
                                className={`form-control input-datepicker ${this.props.className ? this.props.className : ''}`} 
                                selected={this.state.value ? new Date(this.state.value) : ''}
                                onChange={this.handleChangeDate}
                                onBlur={this.props.onBlur}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                dateFormat="yyyy/MM/dd"
                                todayButton="Aujourd'hui"
                                placeholderText={this.props.placeholder ? this.props.placeholder : "Selectionner une date"}
                            /> :
                            <input required={required} type={type} name={name ? name : ''} style={readonly ? {fontWeight: 'bold', paddingRight: 0} : null } value={value ? value : ''} placeholder={placeholder} onChange={onChange} 
                            onFocus={onFocus} onBlur={onBlur} className={`form-control input`} />

                            
                        }
                        
                    </div>
                </div>
            )
    }
}