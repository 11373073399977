import axios from '../config/axios'
import { _uploadFileToS3 } from "./Invoice";
import { PAGINATION } from '../constants/constants';

export const _createOrganization = async (formData, orgUser) => {
    try {
        const orgLogo = formData.get("logo")?.name;
        let payload = { orgUser };
        formData.forEach((value, key) => { payload[key] = value });
        payload.logo = orgLogo ? orgLogo : null;
        const org = (await _saveOrganisation(payload)).data;
        if (org && org.logo) {
            await _uploadFileToS3(org.logo, formData.get("logo"));
            const logoData = { logo: org.logo.fields.key }
            return await _updateOrganisation(org.id, logoData);
        }
        return { data: org };
    } catch (error) {
        throw new Error(error);
    }
}

export const _saveOrganisation = async (formData) => {
    const reqUrl = "/organizations";
    return await axios.post(reqUrl, formData);
}

export const _updateOrganisation = async (id, data) => {
    const reqUrl = `/organizations/${id}`;
    return await axios.put(reqUrl, data);
}

export const _getOrganizationsByUserId = async (userId) => {
    return await axios.get('/users/' + userId + '/organizations');
}

export const _getOrganizationStaff = async (orgId, pageSize = 50, page = 1) => {
    return await axios.get(`/organizations/${orgId}/staff?pageSize=${pageSize}&page=${page}`);
}

export const _getOrganizationInvoices = async (orgId) => {
    return await axios.get('/organizations/' + orgId + '/invoices');
}

export const _searchOrganizationInvoices = async (orgId, filter = "", pageSize = 50, page = 1) => {
    const param = {
        params: { filter }
    }
    return await axios.get(`/organizations/${orgId}/invoices?pageSize=${pageSize}&page=${page}`, param);
}

export const _getOrganizationBankRecords = async (orgId, params) => {
    const param = {
        params,
    }
    return await axios.get(`/organizations/${orgId}/bank-records`, param);
}

export const _getOrganizationStatements = async (orgId, params) => {
    const param = {
        params,
    }
    return await axios.get(`/organizations/${orgId}/statements`, param);
}

export const _getOrganizationUsers = async (orgId) => {
    return await axios.get(`/organizations/${orgId}/users`);
}

export const _deleteOrganizationUser = async (orgId, invitationId) => {
    return await axios.delete(`/organizations/${orgId}/users/invitation/${invitationId}`);
}

export const _sendInvitationsUsers = async (orgId, invitations) => {
    return await axios.post(`/organizations/${orgId}/users/invitation`, { invitations });
}

export const _validateOrganizationUserInvitation = async (token) => {
    return await axios.get(`/users/user-invitation/validate/${token}`);
}

export const _checkIfOrganisationUsersExists = async (orgId, email) => {
    return await axios.get(`/organizations/${orgId}/user/${email}/exist/`);
}

export const _linkUserToOrganization = async (payload) => {
    let endpoint = `/users/user-invitation/validate/link`;
    return await axios.post(`${endpoint}`, payload);
}

export const _getOrganizationBills = async (orgId, pageSize = PAGINATION.DEFAULT_PAGE_SIZE, page = PAGINATION.DEFAULT_PAGE, filter = "") => {
    return await axios.get(`/organizations/${orgId}/bills?filter=${filter}&pageSize=${pageSize}&page=${page}`);
}

export const _getOrganizationTaxes = async (orgId, pageSize = PAGINATION.DEFAULT_PAGE_SIZE, page = PAGINATION.DEFAULT_PAGE, filter = "") => {
    return await axios.get(`/organizations/${orgId}/taxation-periods?filter=${filter}&pageSize=${pageSize}&page=${page}`);
}

export const _getOrganizationAccounts = async (orgId) => {
    return await axios.get(`/organizations/${orgId}/accounts`);
}

export const _getOrganizationRecords = async (orgId, params) => {
    return await axios.get(`/organizations/${orgId}/records`, { params: { ...params, orgId } });
}

export const _getOrganizationRecordsById = async (orgId, id) => {
    return await axios.get(`/organizations/${orgId}/records/${id}`);
}

export const _getOrganizationCnpsDeclarations = async (orgId, pageSize = PAGINATION.DEFAULT_PAGE_SIZE, page = PAGINATION.DEFAULT_PAGE, filter = "") => {
    return await axios.get(`/organizations/${orgId}/cnps-declarations?filter=${filter}&pageSize=${pageSize}&page=${page}`);
}

export const _createOrganizationCnpsDeclaration = async (orgId) => {
    return await axios.post(`/organizations/${orgId}/cnps-declarations`);
}

export const _updateOrganizationCnpsDeclaration = async (orgId, id, payload) => {
    return await axios.put(`/organizations/${orgId}/cnps-declarations/${id}`, payload);
}

export const _teledeclareOrganizationCnps = async (orgId, id) => {
    return await axios.post(`/organizations/${orgId}/cnps-declarations/${id}/teledeclare`, {
        id,
        connectionId: sessionStorage.getItem("connectionId"),
    });
}

export const _confirmCnpsDeclaration = async (orgId, id) => {
    return await axios.post(`/organizations/${orgId}/cnps-declarations/${id}/confirm`, {
        id,
        connectionId: sessionStorage.getItem("connectionId"),
    });
}
