import axios from 'axios';
import axiosDefaultClient from '../config/axios';
import keycloak from '../config/keycloak';
import qs from 'querystring';
import { _extractPayloadFromToken } from '../helpers/jwt';
import { SESSION_STORAGE_KEYS,PATHS } from '../constants/constants';
import UserAuth from '../helpers/auth';
import Logger from '../helpers/Logger';
const defaultInstance = axios.create({
    baseURL: keycloak.AUTH_SERVER_URL
})
export const _authenticate = async (credentials) => {
    let endpoint = `/auth/login`;
    const requestBody = {
        username: credentials.username,
        password: credentials.password,
    }
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Client-Name': 'dizen/customer',
        }
    }
    return await defaultInstance.post(endpoint, qs.stringify(requestBody), config);
};

export const _register = async (payload) => {
    let endpoint = `auth/register`;
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Client-Name': 'dizen/customer'
        }
    }
    return defaultInstance.post(endpoint, payload, config);
};


export const _sendVerificationEmail = async (email) => {
    let endpoint = `auth/reset-password-verification`;
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Client-Name': 'dizen/customer'
        }
    }
    return defaultInstance.post(endpoint, {email}, config);
};

export const _confirmPassword = async (email, code, password) => {
    const data = {
        email,
        code,
        password
    }
    let endpoint = `auth/reset-password-confirm`;
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Client-Name': 'dizen/customer'
        }
    }
    return defaultInstance.post(endpoint, data, config);
};


export const _logout = async () => {
    return await defaultInstance.post(`/auth/logout`, { token: UserAuth._getTokens().access_token});
};

export const _extractRole = () => {
    const role = sessionStorage.getItem(SESSION_STORAGE_KEYS.ROLE);
    if(role && role !== "undefined") return JSON.parse(role)
    return null;
}

export const _getRole = async (forceRefresh = false) => {
    const access_token = _extractPayloadFromToken(UserAuth._getTokens().access_token);
    let org = sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG);
    if(org && org !== "undefined") org = JSON.parse(org)
    if (!org) {
        throw new Error("Impossible de lire le rôle lorsque l'organisation n'est pas selectionné ")
    }
    if (access_token.sub) {
        let endpoint = `/users/${access_token.sub}/role/${org.id}`;
        const role = (await axiosDefaultClient.get(endpoint)).data;
        if(role && role.length > 0) sessionStorage.setItem(SESSION_STORAGE_KEYS.ROLE, JSON.stringify(role[0]));
        return {data: role};
    }
    return {data: null};
}

export const _initFunctionalities = async (forceRefresh = false) => {
    const org = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
    const access_token = _extractPayloadFromToken(UserAuth._getTokens().access_token);
    if (access_token.sub) {
        let endpoint = `/users/${access_token.sub}/functionalities/${org.id}`;
        let result = await axiosDefaultClient.get(endpoint);
        sessionStorage.setItem(SESSION_STORAGE_KEYS.HABILITATIONS, JSON.stringify(result.data));
        await _getRole();
    }
}

export  const _getFunctionalities = () => {
    const org = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
    const habilitations = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.HABILITATIONS));
    if (!org) {
       return null;
    }
    if (habilitations && habilitations.length > 0) {
        return habilitations;
    }
    return null;
}

export const _canAccess =  (fonctionality) => {
    const functionalities = _getFunctionalities();
    if (!functionalities) {
        return false;
    };

    const result = functionalities.filter((item) => {
        return item.functionalityCode === fonctionality
    });
    return result && result.length > 0;
}

export const _checkAccessOrRedirect = (functionality, failedRedirectUrl= PATHS.UNAUTHORIZED_PATH) => {
    
    //  const history = useHistory();
    if(!_canAccess(functionality)){
        return false;
    }
    return  true;
}

export const _checkAuth = () => {
    try {
        const tokens =  UserAuth._getTokens();
        if (!tokens) {
            return false;
        }
        const tokenPayload = _extractPayloadFromToken(tokens.access_token);
        const { exp } = tokenPayload; // get the expiration time of the token
        if (exp < (new Date().getTime() / 1000)) {
            return false;
        }
    } catch (err) {
        Logger.error("Authentication Error", `Failed to verify Token`);
        return false;
    }
    return true;
}

export const _getRoles = async () => {
    return await axiosDefaultClient.get("users/roles");
}

export const authAxios = defaultInstance;
