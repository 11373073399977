import React, { Component, lazy, Suspense } from "react";
import { connect } from 'react-redux';
import moment from "moment";
import { toastr } from 'react-redux-toastr';
import { SESSION_STORAGE_KEYS, PAGINATION } from '../../constants/constants';
import Loader from '../../components/Loader';
import Search from '../../components/Search';
import { numberFormat } from '../../helpers/Number';
import { errorNotifcation } from '../../helpers/errorHandle';
import Logger from '../../helpers/Logger';
import IconPlus from '../../components/Icons/IconPlus';
import reverseOrderIcon from '../../assets/svg/icon-reverseOrder.svg';
import imgPdfFile from '../../assets/svg/file-pdf-cog.svg';
import { 
    setCnpsDeclaration, addCnpsDeclaration, addMoreCnpsDeclaration, updateCnpsDeclaration, deleteCnpsDeclaration, selectCnpsDeclaration 
} from "../../redux/action/TaxesAction";
import { _getOrganizationCnpsDeclarations, _createOrganizationCnpsDeclaration } from "../../services/Organization";

import "./taxes.css";

const GenericTable = lazy(() => import("../../components/Table/GenericTable") );

class CnpsList extends Component {
    constructor(props){
        super(props);
        this.state = {
            loadTaxe: false,
            isEditView: false,
            loading: false,
            filter: '',
            pageSize: PAGINATION.DEFAULT_PAGE_SIZE,
            startPage: PAGINATION.DEFAULT_PAGE,
            currentPage: PAGINATION.DEFAULT_PAGE,endIndex: null,
            endPage: null,
            pages: [],
            startIndex: null,
            totalItems: null,
            totalPages: null,
            count: null,
            hasMore: true,
            scrollLoading: false,
            isDetailLoading: false,
            isAttachmentVisible: false,
            loadPreview: false,
        }
    }

    componentDidMount(){
        this.getData();
    }

    setRefGenericTable = (ref) => {
        this.genericTable = ref;
    }

    getData = async () => {
        const defaultOrg = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
        if(defaultOrg.id){
            try{
                this.setState({loadTaxe: true});
                const res = await _getOrganizationCnpsDeclarations(defaultOrg.id, this.state.pageSize, this.state.startPage, this.state.filter);
                this.props.setCnpsDeclaration(res.data.rows);
                this.setState({ 
                    currentPage: res.data.pager.currentPage,
                    endIndex: res.data.pager.endIndex,
                    endPage: res.data.pager.endPage,
                    pageSize: res.data.pager.pageSize,
                    pages: res.data.pager.pages,
                    startIndex: res.data.pager.startIndex,
                    startPage: res.data.pager.startPage,
                    totalItems: res.data.pager.totalItems,
                    totalPages: res.data.pager.totalPages,
                    count: res.data.count,
                });
            }catch(e){
                errorNotifcation(e?.response?.status, "Impossible de lister les déclarations CNPS!");
                Logger.log("Get Organization's CNPS Declaration","Can't get organization's CNPS Declaration!" , e);
            }finally{
                this.setState({loadTaxe: false});
            }
        }
    }

    getMoreData = async () => {
        const defaultOrg = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
        try{
            this.setState({ scrollLoading: true });
            const res = await _getOrganizationCnpsDeclarations(defaultOrg.id, this.state.pageSize, this.state.currentPage + 1, this.state.filter);
            this.setState({ 
                scrollLoading: false,
                currentPage: res.data.pager.currentPage,
                endIndex: res.data.pager.endIndex,
                endPage: res.data.pager.endPage,
                pageSize: res.data.pager.pageSize,
                pages: res.data.pager.pages,
                startIndex: res.data.pager.startIndex,
                startPage: res.data.pager.startPage,
                totalItems: res.data.pager.totalItems,
                totalPages: res.data.pager.totalPages,
                count: res.data.count,
            });
            this.props.addMoreCnpsDeclaration(res.data.rows);
        }catch(e){
            errorNotifcation(e?.response?.status, "Impossible de lister plus de déclarations CNPS.");
            Logger.log("Error","Can't get more organization's CNPS Declaration!" , e);
        }finally{
            this.setState({scrollLoading: false});
        }
    }
    
    stopProp = (e) => {
        e.stopPropagation();
    }

    createDeclaration = async (e) => {
        e.stopPropagation();
        const defaultOrg = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
        if(defaultOrg.id){
            try{
                this.setState({loadTaxe: true});
                const res = await _createOrganizationCnpsDeclaration(defaultOrg.id);
                this.props.addCnpsDeclaration(res.data);
                toastr.success("", "Déclaration CNPS créée avec succès !");
            }catch(e){
                if(e?.response?.status === 400){ toastr.info("", e?.response?.data?.title?.trim()); } else {
                    errorNotifcation(e?.response?.status, "Une erreur est survenu lors du traitement de votre requete.");
                }
                Logger.log("Create Organization's CNPS Declaration","Can't create organization's CNPS Declaration!", e);
            }finally{
                this.setState({loadTaxe: false});
            }
        }
    }

    headerData = [
        { title: <span> Période <img src={reverseOrderIcon} alt="Période" width={10} height={10} /></span> , align: "center", className: 'pl-1 w-20-percent', name: "period"},
        { title: <span> Masse salariale <img src={reverseOrderIcon} alt="salary mass" width={10} height={10} /></span> , align: "left", className: 'text-align-right justify-content-end w-20-percent pl-15', name: "totalBrutSalary"}, 
        { title: <span> Total salaire taxable <img src={reverseOrderIcon} alt="irppTotal" width={10} height={10} /></span> , align: "left", className: 'text-align-right justify-content-end w-20-percent pr-2', name: "totalTaxableSalary"},
        { title: <span> Total salaire cotisable <img src={reverseOrderIcon} alt="regime" width={10} height={10} /></span> , align: "left", className: 'text-align-right justify-content-end sm-hidden w-20-percent', name: "totalContributionSalary"},
        { title: <span> Effectif salariers <img src={reverseOrderIcon} alt="regime" width={10} height={10} /></span> , align: "left", className: 'text-align-right justify-content-end sm-hidden w-10-percent', name: "numberOfStaff"},
        { title: <span> Télécharger <img src={reverseOrderIcon} alt="staff" width={10} height={10} /></span> , align: "right", className: 'text-align-center justify-content-center sm-hidden w-10-percent pr-1', name: "download"}
    ];

    formatBodyData = (data) => {
        return data.map((item, i) => {
            let resData = {
                originalData: item,
                period: <span style={{textTransform: 'capitalize'}}>{moment.utc(item.periodDate).format('MMMM YYYY')}</span>,
                totalBrutSalary: <>{`${numberFormat(item.totalBrutSalary)}`}</>,
                totalTaxableSalary: <>{`${numberFormat(item.totalTaxableSalary)}`}</>,
                totalContributionSalary: <>{`${numberFormat(item.totalContributionSalary)}`}</>,
                numberOfStaff: <>{`${numberFormat(item.numberOfStaff, 0)}`}</>,
                download: <span className="label-status"> <a href={item.dipeAttachement} onClick={this.stopProp} target='_blank' rel="noopener noreferrer" download={`fiche de paie ${moment(item.periodDate).format("MM YYYY")}`} alt={`fiche de paie ${moment(item.periodDate).format("MM YYYY")}`} title={`Déclaration CNPS ${moment(item.periodDate).format("MM YYYY")} `} > <img src={imgPdfFile} width={15} height={'auto'} alt={'Payslip file'} /> </a> </span>,
            }
            return resData;
        });
    }

    createCnpsPeriod = async () => {
        // const defaultOrg = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
        try {
            this.setState({loadTaxe: true});
            
            await this.getData();
        } catch(err) {
            const error = { ...err };
            Logger.error("Create taxation period failed", error);
            errorNotifcation(error?.response?.status, "Echec de création de la période d'imposition, veuillez reessayer ultérieurement");
        } finally {
            this.setState({loadTaxe: false});   
        }
    }

    gotoPreview = (payload) => {
        this.props.history.push(this.props.match.url + '/edit?period='+payload.originalData.id, {...payload.originalData});
    }

    render() {
        return (
            <div className='bill-list col-display'>
                <div className="d-flex flex-column flex-1-auto pl-3">
                    <div className='d-flex flex-column flex-1-auto mt-4 mr-3'>

                        <div className="options-control-header mb-3">
                            <div className="head-option-left">
                                <button className='btn-plus taxe' onClick={(e) => this.createDeclaration(e)} disabled={this.state.loadTaxe}>
                                    <IconPlus width={9} height={9} />
                                </button>
                                <Loader loadData={this.state.loading} className="ml-3" style={{width: 28, height: 28}} />
                            </div>
                            <Search iconClass='search-taxe' />
                        </div>

                        <div className='mt-4'>
                            <Suspense fallback={<div className="text-center">Chargement... </div>} >
                                <GenericTable
                                    data={this.props.cnpsDeclarations}
                                    onRef={this.setRefGenericTable}
                                    header={this.headerData}
                                    body={this.formatBodyData(this.props.cnpsDeclarations)}
                                    className=''
                                    isLoading={this.state.loadTaxe}
                                    asLoader={<Loader style={{width: "25px", height: "25px"}} enable />}
                                    onSelect={this.gotoPreview}
                                    infiniteScroll
                                    getData={this.getMoreData}
                                    hasMore={(this.state.currentPage < this.state.endPage)}
                                    scrollLoading={this.state.scrollLoading}
                                />
                            </Suspense>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        authData: state.auth.authData,
        organizations: state.organizations.organizations,
        cnpsDeclarations: state.taxes.cnpsDeclarations,
        selectedCnpsDeclaration: state.taxes.selectedCnpsDeclaration,
    }
}

export default connect(mapStateToProps, { setCnpsDeclaration, addCnpsDeclaration, addMoreCnpsDeclaration, updateCnpsDeclaration, deleteCnpsDeclaration, selectCnpsDeclaration })(CnpsList);