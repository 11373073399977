import React, { Component } from 'react';
import moment from "moment";
import {numberFormat} from "../../helpers/Number";
import DateItemList from "../DateItemList";
import TruncateString from "../TruncateString";
import ArrorUpGreen from "../Icons/ArrowupredComponent";
import ArrorDownDred from "../Icons/ArrowdowngreenComponent";
import "./ListBankRecords.css";

class ItemBankRecord extends Component {
    constructor(props){
        super(props);
        this.state = {

        };
    }

    onClick = () => {
        if(this.props.onClick) this.props.onClick(this.props.data);
    }

    renderArrow = () => {
        if(this.props.data.debit){
            return <ArrorUpGreen className="mr-2" />;
        }else if(this.props.data.credit){
            return <ArrorDownDred className="mr-2" />;
        }
    }

    render() {
        const date =  moment(new Date(this.props.data.operationDate)).format('YYYY/MM/DD');
        const reconciled = this.props.data?.invoices && this.props.data?.invoices.length > 0;
        if(this.props.data) {
            return(
                <div className={ 'invoice-row-item' } onClick={this.onClick}>
                    <span>
                        <DateItemList date={date} />
                    </span>
                    <span className="invoice-deliver invoice-statement"> <TruncateString text={this.props.data.description} className={reconciled ? 'txt-bold' : ''} maxWidth={130} /> </span>
                    <span className={`invoice-amount invoice-statement txt-grey ${reconciled ? 'txt-bold' : ''}`}> { numberFormat(this.props.data.credit || this.props.data.debit) } { this.props.data?.account.currency } </span>
                    { this.renderArrow() }
                </div>
            );
        }
    }
}

export default ItemBankRecord;