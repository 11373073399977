import React, { Component } from 'react';
import { addClass, removeClass } from "../../../helpers/DOM";
import './AccountMenu.css';
import IconUserAccount from '../../Icons/IconUserAccount'
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { _extractPayloadFromToken } from '../../../helpers/jwt';
import { _logout } from "../../../services/Auth";
import DeconectionButtton from "../../DeconectionButtton";
import './AccountMenu.css';
import { SESSION_STORAGE_KEYS } from '../../../constants/constants';
import UserAuth from '../../../helpers/auth';
import Logger from '../../../helpers/Logger';
import { errorNotifcation } from "../../../helpers/errorHandle";

class AccountMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            redirect: null,
            redirectUser: null,
        };
        this.dcnx = React.createRef();
    }

    menuDisplayState = () => {
        let { active } = this.props;
        if (!active) {
            this.closeButton(true);
        } else {
            return 'block';
        }
    }

    UNSAFE_componentWillMount() {
        let authToken = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.TOKEN));
        let payload = _extractPayloadFromToken(authToken.access_token);
        if (payload) {
            const familyName = payload.family_name;
            const givenName = payload.given_name;
            this.setState({
                name: givenName + ' ' + familyName,
                email: payload.email
            })
        }
    }

    componentDidMount() {

        let dom = document.querySelector("body");
        dom.addEventListener("click", this.hideMenu(), true);
    }

    toggleAccountMenu = () => {
        let menuWrapper = document.querySelector(".account-menu-wrapper.fade-in");
        if (menuWrapper) {
            this.hideMenu();
        } else {
            this.showMenu();
        }
    }

    showMenu = () => {
        let menuWrapper = document.querySelector(".account-menu-wrapper");
        if (menuWrapper) {
            menuWrapper.style.display = "block";
            removeClass(menuWrapper, "fade-out");
            addClass(menuWrapper, "fade-in");
        }
    }

    hideMenu = () => {
        let menuWrapper = document.querySelector(".account-menu-wrapper");
        if (menuWrapper) {
            removeClass(menuWrapper, "fade-in");
            addClass(menuWrapper, "fade-out");
            setTimeout(() => {
                menuWrapper.style.display = "none";
                removeClass(menuWrapper, "fade-out");
            }, 300);
        }
    }

    onItemMenuClicked = (item) => {
        if (this.props.onItemMenuClicked) {
            this.props.onItemMenuClicked(item);
            this.hideMenu();
        }
    }

    switchOrg = (item) => {
        this.props.onToggleOrgs(item);
        this.hideMenu();
    }

    disconnect = (item) => {
        console.log('on disconnect')
        this.props.onDisconnection(item);
        this.hideMenu();
    }

    closeBox = (enable = false, delay = 500, callback = null) => {
        let box = document.querySelector(".account-menu-wrapper");
        if (enable) {
            return new Promise((resolve) => {
                box.classList.remove("hidden");
                if (box.classList.contains("fade-in")) {
                    box.classList.remove("fade-in");
                    box.classList.add("fade-out");
                }
                setTimeout(() => {
                    box.classList.add("hidden");
                    if (callback) callback();
                    resolve(box);
                }, delay);
            });
        }
    }

    openBox = (enable = false, delay = 500) => {
        if (enable) {
            let box = document.querySelector(".account-menu-wrapper");
            box.classList.remove("hidden");
            if (box.classList.contains("fade-in")) {
                box.classList.remove("fade-in");
                box.classList.add("fade-out");
            }
            setTimeout(() => {
                box.classList.add("hidden");
            }, delay);
        }
    }

    processDisconnection = async () => {
        const token = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.TOKEN));

        let payload = _extractPayloadFromToken(token.access_token);
        if (payload) {
            try {
                const res = (await _logout()).data;
                sessionStorage.clear();
                this.dcnx.current.init();
                this.setState({
                    redirect: "/",
                }, () => { sessionStorage.clear() });

                return res;

            } catch (error) {
                Logger.error("ProcessDisconnection", "Failed to Logout user", error);
                errorNotifcation(error?.response?.status, "Erreur pendant la déconnexion, veuillez reessayer ultérieurement.");
            }
        }

    }

    toggleOrg = () => {
        this.hideMenu();
        if (this.props.onToggleOrgs) {
            this.props.onToggleOrgs();
        }
    }

    deconnexion = () => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({ status: "Disconnected" });
            }, 3000);
        });
    }

    gotoPage = (route) => {
        this.setState({
            redirect: route,
        });
    }

    render() {
        if (this.state.redirect) { return (<Redirect to={this.state.redirect} />) }
        let { active } = this.props;

        const name = `${UserAuth._getUser().given_name} ${UserAuth._getUser().family_name}`;
        const email = UserAuth._getUser().email;
        return (
            <div className={`account-menu-wrapper  ${(active) ? "fade-in visible" : "fade-out"}`} onClick={this.props.onClose}>
                <div className="account-menu-content">
                    <div className="account-menu-header">
                        <div className="account-avatar">
                            <IconUserAccount size={50} />
                        </div>
                        <div className="account-infos">
                            {name ? <div className="account-name txt-grey">{name}</div> : null}
                            {email ? <div className="account-email">{email}</div> : null}
                        </div>
                    </div>
                    <div className="account-menu-items txt-grey">
                        <div className="account-menu-item txt-grey">Paramètres</div>
                        <div className="account-menu-item txt-grey" onClick={this.gotoPage.bind(this, "/users")}>Utilisateurs</div>
                        <div onClick={this.toggleOrg} className="account-menu-item txt-grey">Changer d'organisation</div>
                    </div>
                    <div className="account-menu-footer">
                        <div className="account-menu-footer-content">
                            <div className="account-menu-footer-item"> <DeconectionButtton ref={this.dcnx} label={'Deconnexion'} onClick={this.processDisconnection} className={'txt-grey'} /> </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

AccountMenu.propTypes = {
    active: PropTypes.bool,
    onItemMenuClicked: PropTypes.func,
    onBlur: PropTypes.func
}

export default AccountMenu