import React from "react";

const IconUploadCloud = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={ props.width || "19"} height={props.height || "15"} viewBox="0 0 18.356 14.419">
    <path id="_ionicons_svg_ios-cloud-upload" d="M15.671,36.306A5.2,5.2,0,0,0,10.326,32,5.566,5.566,0,0,0,5.9,34.082a2.8,2.8,0,0,0-.964-.171,2.523,2.523,0,0,0-2.56,2.362A3.054,3.054,0,0,0,3.259,42.3H8.712V37.732L6.995,39.251a.507.507,0,0,1-.66,0,.391.391,0,0,1,0-.592l2.51-2.221A.463.463,0,0,1,9,36.348a.489.489,0,0,1,.179-.032.5.5,0,0,1,.326.119l2.51,2.221a.388.388,0,0,1,0,.592.5.5,0,0,1-.66,0l-1.71-1.519V42.3H15.1a3.2,3.2,0,0,0,3.259-3.041A3.106,3.106,0,0,0,15.671,36.306ZM8.712,46a.469.469,0,0,0,.932,0V42.3H8.712Z" 
      transform="translate(0 -32)" 
      fill={ props.color || "#b5b5b5" }/>
  </svg>

);

export default IconUploadCloud;
