import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from "react-redux-toastr";
import { errorNotifcation } from '../../helpers/errorHandle';
import {_updateTaxPeriod} from "../../services/Taxes";
import { setOrganizationTaxes, addMultipleOrganizationTaxes } from "../../redux/action/OrganisationAction";
import { SESSION_STORAGE_KEYS} from '../../constants/constants';
import Logger from '../../helpers/Logger';
import Loader from '../../components/Loader';
import IconBack from '../../components/Icons/IconBack';
import IconSave from '../../components/Icons/SaveIcon';
import { getQueryString } from '../../helpers/dataFormat';
import StepsView from './StepsView';
import StepsButton from '../../components/StepsButton';
import {staticNode} from "../../helpers/DOM";

import "./taxes.css";


class TaxeEdit extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            payload: null,
        }
    }

    componentDidMount(){
        staticNode(".hoverlay-controls", 90, "offset");
    }

    getRefPageOne = (refPageOne) => {
        this.pageOne = refPageOne;
    }

    getRefPageTwo = (refPageTwo) => {
        this.pageTwo = refPageTwo;
    }

    getRefPageThree = (refPageThree) => {
        this.pageThree = refPageThree;
    }

    getRefPageFour = (refPageFour) => {
        this.pageFour = refPageFour;
    }

    updateTaxes = async () => {
        this.setState({isLoading: true});
        let payload1 = this.pageOne ? this.pageOne.getPayload() : {};
        let payload2 = this.pageTwo ? this.pageTwo.getPayload() : {};
        let payload3 = this.pageThree ? this.pageThree.getPayload() : {};
        let payload4 = this.pageFour ? this.pageFour.getPayload() : {};
        let payload = { ...payload1, ...payload2, ...payload3, ...payload4 };
        const defaultOrg = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
        try {
            const res = await _updateTaxPeriod(defaultOrg.id, this.props.selectedTaxes?.id, payload);
            toastr.success("", "Periode d'imposition cree avec succes !");
            return { status: 'success', data: res.data };
        } catch(err) {
            Logger.error("Update taxation period failed", err);
            errorNotifcation(err?.response?.status, "Echec lors de la modification de la période d'imposition, veuillez reessayer ultérieurement");
            return { status: 'error', data: err };
        } finally {
            this.setState({isLoading: false});   
        }
    }

    init = () => {
        this.setState({
            payload: this.props.history.location.state,
        });
    }

    queryParam = () => {
        const qs = getQueryString(this.props.history.location.search);
        return qs.get("page");
    }

    gotoView = (page) => {
        this.props.history.push(this.props.match.url+"?page="+page);
    }

    gotoPeriod = () => {
        this.props.history.push(this.props.history.push('/taxes'));
    }

    render() {
        const qs = getQueryString(this.props.history.location.search);
        return (
            <div className='bill-list col-display'>
                <div className="d-flex flex-column flex-1-auto pl-3">
                    <div className='d-flex flex-column flex-1-auto mt-4 mr-3'>
                        <div className="options-control-header mb-3">
                            <div className="head-option-left">
                                <button className='btn-plus-taxe mr-2' onClick={this.gotoPeriod}>
                                    <IconBack  color="#FFFFFF" />
                                </button>
                                <button onClick={this.updateTaxes} className="btn-save-taxe d-flex align-items-center mr-2 p2">
                                    <span className="mr-2">Mettre a jour</span>
                                    <IconSave color="#09B6CC" width={10} height={10} /> 
                                </button>
                                <Loader enable={this.state.isLoading} className="ml-3" style={{width: 28, height: 28}} />
                            </div>
                            <div className="block-step">
                                <StepsButton activeStep={qs.get("page")} onSelect={this.gotoView} />
                            </div>
                            
                        </div>

                        <div className="hoverlay-controls">
                            <div className="d-flex align-items-center justify-content-between m-2 flex-1-auto">
                                <div className="head-option-left">
                                    <button className='btn-plus-taxe mr-2' onClick={this.gotoPeriod}>
                                        <IconBack  color="#FFFFFF" />
                                    </button>
                                    <button onClick={this.updateTaxes} className="btn-save-taxe d-flex align-items-center mr-2 p2">
                                        <span className="mr-2">Mettre a jour</span>
                                        <IconSave color="#09B6CC" width={10} height={10} /> 
                                    </button>
                                    <Loader enable={this.state.isLoading} className="ml-3" style={{width: 28, height: 28}} />
                                </div>
                                <div className="block-step">
                                    <StepsButton activeStep={qs.get("page")} onSelect={this.gotoView} />
                                </div>
                                
                            </div>
                        </div>

                        <div className='d-flex flex-column flex-1-auto switcher-view-content'>
                            <StepsView currentStep={qs.get("page")} history={this.props.history} refPageOne={this.getRefPageOne} refPageTwo={this.getRefPageTwo} refPageThree={this.getRefPageThree} refPageFour={this.getRefPageFour} />
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth.authData,
        organizations: state.organizations.organizations,
        taxes: state.organizations.organizationTaxes,
        selectedPeriod: state.organizations.selectedOrgTaxes,
        selectedTaxes: state.taxes.selectedTaxe,
    }
}

export default connect(mapStateToProps, { setOrganizationTaxes, addMultipleOrganizationTaxes })(TaxeEdit);
