/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const JobComponent = (props) => (
  <svg width={props.width ? props.width : 18.534} height={props.height ? props.height : 19.681} viewBox="0 0 18.534 19.681">
    <defs>
      <style>{`.jba{fill:${props.active ? '#177BD1;': '#b5b5b5;'}}`}</style>
    </defs>
    <g transform="translate(-51.689 -39.4)">
      <path
        className="jba"
        d="M263.087,293.4a.648.648,0,0,1,.595-.67H265.3a.656.656,0,0,1,.6.67v.511h.726V293.4A1.381,1.381,0,0,0,265.3,292h-1.615a1.373,1.373,0,0,0-1.321,1.4v.511h.726Zm-2.5,3.416v2.738a.631.631,0,0,0,.578.666h6.78a.638.638,0,0,0,.586-.666v-2.738h-2.587v.514a.357.357,0,0,1-.37.348h-2.187a.338.338,0,0,1-.348-.348v-.514Zm2.451-1.209a.365.365,0,0,1,.348-.38h2.187a.384.384,0,0,1,.37.38v.483h3.132v-1.173a.6.6,0,0,0-.568-.643h-7.9a.631.631,0,0,0-.605.643v1.173h3.041Z"
        transform="translate(-198.856 -241.134)"
      />
      <rect
        className="jba"
        width={1.453}
        height={0.999}
        transform="translate(64.912 54.815)"
      />
      <path
        className="jba"
        d="M96.867,39.4h0Zm0,3.427A1.364,1.364,0,0,0,98.23,41.46v-.692a1.362,1.362,0,0,0-2.723,0v.692A1.364,1.364,0,0,0,96.868,42.827Zm1.09,16.236a.669.669,0,0,0,.676-.642l.273-8.192H94.96l.263,8.193a.664.664,0,1,0,1.328-.032V51.522a.363.363,0,0,1,.726,0v6.869A.681.681,0,0,0,97.959,59.063Z"
        transform="translate(-41.307)"
      />
      <path
        className="jba"
        d="M51.7,149.541v4.371a.61.61,0,0,0,.17.429.591.591,0,0,0,.415.184h.006a.661.661,0,0,0,.589-.64V151.3a.363.363,0,1,1,.726,0v1.2c0,.016,0,.039,0,.056l.016.566h4l.025-.395V151.3a.363.363,0,1,1,.726,0v1.194c0,.024.006.05.005.075l-.005.167v1.175a.59.59,0,0,0,1.18,0v-4.37c0-.012,0-.024,0-.037a1.356,1.356,0,0,0-1.431-1.561h-.768l-1.484,1.384s-.006,0-.009,0l-.013.011-.016.012-.014.01-.016.01-.015.008-.017.008-.015.006-.018.007-.015,0-.019,0-.016,0-.019,0-.016,0h-.07l-.017,0-.018,0-.017,0-.017,0-.016-.005-.017-.006-.016-.007-.015-.008-.016-.009-.014-.009-.016-.011-.013-.009-.015-.011s0,0-.007,0l-1.428-1.379h-.82a1.418,1.418,0,0,0-1.433,1.416,1.439,1.439,0,0,0,.009.145C51.7,149.516,51.7,149.528,51.7,149.541Z"
        transform="translate(0 -103.614)"
      />
    </g>
  </svg>
);

export default JobComponent;
