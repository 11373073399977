import React from "react";

const IoniconsSvgIosPersonComponent = (props) => (
  <svg width={props.width ? props.width : 17.104} height={ props.height ? props.height : 17.104} viewBox="0 0 17.104 17.104">
    <defs>
        <style>{`.${props.className}{fill:${props.active ? '#177BD1' : '#b5b5b5;'}}`}</style>
    </defs>
    <path
      
      fill={`${props.active ? '#177BD1' : '#b5b5b5;'}`}
      d="M81.1,80.672c-.321-1.416-2.151-2.107-2.784-2.33A20.757,20.757,0,0,0,76,77.9a2.358,2.358,0,0,1-1.073-.494,9.382,9.382,0,0,1-.071-2.2,6.607,6.607,0,0,0,.508-.967,12.467,12.467,0,0,0,.374-1.688s.365,0,.494-.641a6.311,6.311,0,0,0,.33-1.492c-.027-.512-.307-.5-.307-.5a7.809,7.809,0,0,0,.3-2.285A3.67,3.67,0,0,0,72.566,64c-2.632,0-4.036,1.8-4,3.635a8.146,8.146,0,0,0,.3,2.285s-.281-.013-.307.5a6.311,6.311,0,0,0,.33,1.492c.125.641.494.641.494.641a12.468,12.468,0,0,0,.374,1.688,6.607,6.607,0,0,0,.508.967,9.382,9.382,0,0,1-.071,2.2,2.358,2.358,0,0,1-1.073.494,20.756,20.756,0,0,0-2.321.445c-.633.223-2.463.913-2.784,2.33a.356.356,0,0,0,.352.432H80.757A.355.355,0,0,0,81.1,80.672Z"
      transform="translate(-64.009 -64)"
    />
  </svg>
);

export default IoniconsSvgIosPersonComponent;
