import { toastr } from "react-redux-toastr";

let PROPS = null;

const _subscribe = async (url, callback) => {
    let socket = new WebSocket(url);
    socket.addEventListener('open', function (event) {

        const data = {
            action: "onMessage",
            eventType: "init",
            message: "init",
        }
        socket.send( JSON.stringify( data ) );
    });

    socket.addEventListener("close", function (event) {
        initWebsocket(PROPS);
    })

    socket.addEventListener('message', function (event) {
       const data = JSON.parse(event.data); 
       switch(data.eventType) {
            case "init":{
                sessionStorage.setItem("connectionId", data.connectionId);
                break;
            }
            case "disconnection":{
                sessionStorage.removeItem("connectionId");
                break;
            }
            case "bankRecord": {
                callback( data.eventType, data);
                break;
            }
            case "paySlip": {
                callback(data.eventType, data);
                break;
            }
            case "payslipsByMail": {
                callback(data.eventType, data);
                break;
            }
            case "bankStatement": {
                callback(data.eventType, data);
                break;
            }
            case "extractionError": {
                callback(data.eventType, data);
                break;
            }
            case "bankIncoherentBalance": {
                callback(data.eventType, data);
                break;
            }
            case "closePayPeriod": {
                callback(data.eventType, data);
                break;
            }
            case "bankReconciliation": {
                callback(data.eventType, data);
                break;
            }
            case "cnpsDeclarationProof": {
                callback(data.eventType, data);
                break;
            }
            case "cnpsDeclartionConfirmed": {
                callback(data.eventType, data);
                break;
            }
           default:
               break;
       }
    });
    return socket;
}

const initWebsocket = async (props)=> {
    const sock = await _subscribe(process.env.REACT_APP_WS_API_BASE_URL, (eventType, data) => {     
        mapEventToAction(eventType, data, props);
    })
    PROPS = props;
    props.setSocketService(sock);
}

const addData = (data, props) => {
    if(data.message) toastr.success("", "Relevé bancaire importé avec succès!");
    props.setRecords(data.message);
    props.setLoaderImportBankStatement(false);
}

const genPaySlip = (data, props)=>{
    props.updatePayslip(data.message)
}

const payslipsSentByMail = (data, props) => {
    props.setPayslipsSentByMail(!data.message);
}

const reloadBankStatements = (data, props) => {
    props.setStatements(data.message);
}

const setBankExtractionError = (data, props) => {
    toastr.error("Echec de l'importation du relevé bancaire", "veuillez réessayer plus tard ou contacter votre administrateur !");
    props.setLoaderImportBankStatement(false);
}

const setBankIncoherentBalanceError = (data, props) => {
    toastr.error("Solde de compte incohérent", "Veuillez vérifier l'ordre d'importation ou les soldes initial et de clôture !");
    props.setLoaderImportBankStatement(false);
}

const setClosePayPeriodLoader = (data, props) => {
    props.setClosePayPeriodLoader(false);
}

const displayReconciliationNotification = (data, props) => {
    toastr.success("", "Rapprochement bancaire effectué avec succès !");
    props.setRecords(data.message);
}

const setCnpsDeclarationProofs = (data, props) => {
    toastr.success("", "Télédéclaration CNPS initiée avec succès !");
    props.selectCnpsDeclaration(data.message);
}

const cnpsDeclarationConfirmed = (data, props) => {
    toastr.success("", "Télédéclaration CNPS confirmée avec succès !");
    props.selectCnpsDeclaration(data.message);
}

const mapEventToAction = (eventType, data, props) => {
    switch(eventType) {
        case "bankRecord": {
            return addData(data, props);
        }
        case "paySlip": {
            return  genPaySlip(data, props);
        }
        case "payslipsByMail": {
            return payslipsSentByMail(data, props);
        }
        case "bankStatement": {
            return reloadBankStatements(data, props);
        }
        case "extractionError": {
            return setBankExtractionError(data, props);
        }
        case "bankIncoherentBalance": {
            return setBankIncoherentBalanceError(data, props);
        }
        case "closePayPeriod": {
            return setClosePayPeriodLoader(data, props);
        }
        case "bankReconciliation": {
            return displayReconciliationNotification(data, props);
        }
        case "cnpsDeclarationProof": {
            return setCnpsDeclarationProofs(data, props);
        }
        case "cnpsDeclartionConfirmed": {
            return cnpsDeclarationConfirmed(data, props);
        }
        default:
            break;
    }
}

export default {initWebsocket};
