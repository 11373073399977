/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const GroupComponent = () => (
  <svg width={14.424} height={9.62} viewBox="0 0 14.424 9.62">
    <defs>
      <style>{".back{fill:#177bd1;}"}</style>
    </defs>
    <g transform="translate(-52.741 -10.75)">
      <path
        className="back"
        d="M117.226,160.208a.655.655,0,0,1,.005.922l-3.046,3.051h11.593a.651.651,0,0,1,0,1.3H114.189l3.046,3.051a.659.659,0,0,1-.005.922.649.649,0,0,1-.917-.005l-4.128-4.158a.732.732,0,0,1-.135-.205.622.622,0,0,1-.05-.251.653.653,0,0,1,.185-.456l4.128-4.158A.638.638,0,0,1,117.226,160.208Z"
        transform="translate(-59.259 -149.274)"
      />
    </g>
  </svg>
);

export default GroupComponent;
