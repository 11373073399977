/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const IconNumbers = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={ props.width || "22"} height={props.height || "15"} viewBox="0 0 22 15">
  <g id="Group_319" data-name="Group 319" transform="translate(-3 -8)">
    <g id="Rectangle_112" data-name="Rectangle 112" transform="translate(3 8)" fill="none" stroke={ props.color || "#b5b5b5" } strokeLinejoin="round" strokeWidth="0.5">
      <rect width="22" height="15" rx="1" stroke="none"/>
      <rect x="0.25" y="0.25" width="21.5" height="14.5" rx="0.75" fill="none"/>
    </g>
    <path id="Union_1" data-name="Union 1" d="M-236.4-1348.66v-1.17h1.741a1.3,1.3,0,0,0,1.094-.46,2.24,2.24,0,0,0,.355-1.381,3.379,3.379,0,0,1-.72.135q-.4.035-.95.035a2.137,2.137,0,0,1-1.51-.435,1.681,1.681,0,0,1-.45-1.265v-1.059a1.657,1.657,0,0,1,.485-1.32,1.991,1.991,0,0,1,1.345-.42h1.07a2.22,2.22,0,0,1,1.239.315,1.982,1.982,0,0,1,.731,1.115,7.77,7.77,0,0,1,.249,2.22,4.822,4.822,0,0,1-.7,2.97,2.523,2.523,0,0,1-2.1.88A9.449,9.449,0,0,1-236.4-1348.66Zm1.21-5.846a.739.739,0,0,0-.17.545v.48a.668.668,0,0,0,.205.571,1.213,1.213,0,0,0,.695.149h1.25a4.983,4.983,0,0,0-.105-1.165,1.013,1.013,0,0,0-.325-.581.962.962,0,0,0-.59-.165h-.45A.707.707,0,0,0-235.19-1354.505Zm-13.5,5.975v-6.05l-1.31.17v-.95l1.471-.64h1.319v7.47Zm7.65-2.24.28-1.37.73.19-.48,1.35Zm-4.32,0,.28-1.37.73.19-.48,1.35Zm5.3-.529.4-.64,1.16.879-.33.44Zm-4.32,0,.4-.64,1.16.879-.33.44Zm4.829-.97,1.17-.81.32.44-1.01.959Zm-4.32,0,1.17-.81.32.44-1.01.959Zm2.271-.07v-.55l1.4-.15.03.751Zm-4.32,0v-.55l1.4-.15.03.751Zm5.63-1.49.53-.17.62,1.249-.71.281Zm-4.32,0,.53-.17.62,1.249-.71.281Z" 
      transform="translate(255 1368)" 
      fill={ props.color || "#b5b5b5" }/>
  </g>
</svg>
);

export default IconNumbers;
