import React, { Component, lazy, Suspense  } from 'react';
import { connect } from 'react-redux';
import { setOrganizationCustomers, addMultipleOrganizationCutomers } from "../../redux/action/OrganisationAction";
import { _getCustomers } from "../../services/Customer";
import Logger from '../../helpers/Logger';
import { SESSION_STORAGE_KEYS, PAGINATION } from '../../constants/constants';
import Loader from '../../components/Loader';
import Search from '../../components/Search';
import IconPlus from '../../components/Icons/IconPlus';
import reverseOrderIcon from '../../assets/svg/icon-reverseOrder.svg';

import "./Bills.css";

const GenericTable = lazy(() => import("../../components/Table/GenericTable") );

export class CustomerPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            filter: '',
            pageSize: PAGINATION.DEFAULT_PAGE_SIZE,
            startPage: PAGINATION.DEFAULT_PAGE,
            currentPage: PAGINATION.DEFAULT_PAGE,endIndex: null,
            endPage: null,
            pages: [],
            startIndex: null,
            totalItems: null,
            totalPages: null,
            count: null,
            hasMore: true,
            scrollLoading: false,
            isDetailLoading: false,
            isAttachmentVisible: false,
            loadPreview: false,
        };
    }

    componentDidMount = () => {
        this.getData();
    }

    constructParam = () => {
        return {
            pageSize: this.state.pageSize,
            page: this.state.startPage,
            filter: this.state.filter,
        }
    }

    getData = async () => {
        const defaultOrg = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
        if(defaultOrg.id){
            const param = this.constructParam();
            this.setState({loading: true});
            _getCustomers(defaultOrg.id, param).then((res) => {
                if(res.status === 200){
                    this.props.setOrganizationCustomers(res.data.rows);
                    this.setState({ 
                        loading: false,
                        currentPage: res.data.pager.currentPage,
                        endIndex: res.data.pager.endIndex,
                        endPage: res.data.pager.endPage,
                        pageSize: res.data.pager.pageSize,
                        pages: res.data.pager.pages,
                        startIndex: res.data.pager.startIndex,
                        startPage: res.data.pager.startPage,
                        totalItems: res.data.pager.totalItems,
                        totalPages: res.data.pager.totalPages,
                        count: res.data.count,
                    });
                }
            }).catch((e) => {
                Logger.log("Get Organization's Customers","Can't get organization's bills!" , e);
            }).finally(() => {
                if(this.state.loading) this.setState({loading: false});
            });
        }
    }

    getMoreData = async () => {
        const defaultOrg = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
        this.setState({ scrollLoading: true });
        const param = {
            ...this.constructParam(),
            page: this.state.currentPage + 1,
        };
        _getCustomers(defaultOrg.id, param).then((res) => {
            if(res?.status === 200){
                this.setState({ 
                    scrollLoading: false,
                    currentPage: res.data.pager.currentPage,
                    endIndex: res.data.pager.endIndex,
                    endPage: res.data.pager.endPage,
                    pageSize: res.data.pager.pageSize,
                    pages: res.data.pager.pages,
                    startIndex: res.data.pager.startIndex,
                    startPage: res.data.pager.startPage,
                    totalItems: res.data.pager.totalItems,
                    totalPages: res.data.pager.totalPages,
                    count: res.data.count,
                });
                this.props.addMultipleOrganizationCutomers(res.data.rows);
            }else{
                Logger.log("Get more Organization's Customers","Can't get more organization's bills!" , null);
            }
        }).catch((e) => {
            Logger.log("Error","Can't get more organization's bills!" , e);
        });
    }

    headerData = [
        { title: <span> Name <img src={reverseOrderIcon} alt="name" width={10} height={10} /></span> , align: "left", className: 'pl-15', width: "45%", name: "name"}, 
        { title: <span> Téléphone <img src={reverseOrderIcon} alt="phone1" width={10} height={10} /></span> , align: "left", className: 'hide-xs-responsive pl-15', width: "15%", name: "phone1"}, 
        { title: <span> Adresse <img src={reverseOrderIcon} alt="address" width={10} height={10} /></span> , align: "left", className: 'pl-15', width: "25%", name: "address"}, 
        { title: <span> Country <img src={reverseOrderIcon} alt="country" width={10} height={10} /></span> , align: "left", className: 'pl-15', width: "15%", name: "country"}
    ];

    formatBodyData = (data) => {
        return data.map((item, i) => {
            let resData = {
                originalData: item,
                name: <span>{item.name}</span>,
                phone1: <span>{item.phone1}</span>,
                address: <span>{ item.address ? item.address : `${item.postalCode} ${item.city}`}</span>,
                country: <span className="role">{item.country}</span>,
            }
            return resData;
        });
    }

    render() {
        return (
            <div className="d-flex flex-column flex-1-auto pl-3">
                <div className='flex-1-auto mt-4 mr-3'>
                    <div className="flext-part">
                        <div className="options-control-header pt-20 mb-3">
                            <div className="head-option-left">
                                <button className='btn-plus bill' onClick={this.gotoEditView}>
                                    <IconPlus width={9} height={9} />
                                </button>
                                <Loader loadData={this.state.loadCustomer} className="ml-3" style={{width: 28, height: 28}} />
                            </div>
                            <Search iconClass='search-bill' />
                        </div>
                        <Suspense fallback={<div className="text-center">Chargement... </div>} >
                            <GenericTable
                                data={this.props.customers}
                                onRef={this.setRefGenericTable}
                                header={this.headerData}
                                body={this.formatBodyData(this.props.customers)}
                                className='mt-20 mb-1 mlr-1'
                                hoverColor="rgba(255, 219, 219, 0.2)"
                                isLoading={this.state.loading}
                                asLoader={<Loader style={{width: "25px", height: "25px"}} enable />}
                                onSelect={this.setItemSelected}
                                infiniteScroll
                                startPage={this.state.startPage + 1}
                                getData={this.getMoreData}
                                hasMore={(this.state.currentPage < this.state.endPage)}
                                scrollLoading={this.state.scrollLoading}
                            />
                        </Suspense>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth.authData,
        organizations: state.organizations.organizations,
        customers: state.organizations.customers,
        defaultOrg: state.organizations.defaultOrganization,
    }
}

export default connect(mapStateToProps, { setOrganizationCustomers, addMultipleOrganizationCutomers })(CustomerPage);
