import React, { Component } from 'react'
import DashboardLayout from '../Dashboard/DashboardLayout'
import TaxesContent from './TaxeContent'

export default class Taxes extends Component {
    render() {
        return(
            <DashboardLayout title={'Impôts & CNPS'}>
                <TaxesContent {...this.props}/>
            </DashboardLayout>
        )
    }
}