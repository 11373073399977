export const numberWithCommas = (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
}
/**
 * To Format a number
 * @param {Number} numberValue the number's value to format
 * @param {Number} decimals the number of decimals after the coma [optional]
 * @param {String} dec_point the decimal point [optional]
 * @param {String} thousands_sep the thousands separator [optional]
 * @returns {String} Number formated
 */
export const numberFormat = (numberValue, decimals, dec_point, thousands_sep) => {
    numberValue =  numberValue * 1;
    decimals = typeof decimals !== 'undefined' ? decimals : 2;
    dec_point = typeof dec_point !== 'undefined' ? dec_point : ',';
    thousands_sep = typeof thousands_sep !== 'undefined' ? thousands_sep : ' ';

    var parts = Number.parseFloat(numberValue).toFixed(decimals).toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

    return parts.join(dec_point);
}