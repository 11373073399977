import React, { Component } from "react";
import NavLink from "../NavLink";
import "./NavLinkResponsive.css";

class NavLinkResponsive extends Component {
    constructor(props){
        super(props);
        this.state = {
            show: false,
        };
        this.navlink = React.createRef();
    }

    componentDidMount(){
        const nav = document.querySelector(".NavLinkResponsive");
        if(nav){
            document.querySelector(".navlink-hoverley").addEventListener("click", () => {
                this.toggleMenu(false);
            });
        }
    }

    handleHoverlay = (e) => {
        e.stopPropagation();
        this.setState({
            show: false,
        })
    }

    toggleMenu = (val = false) => {
        if(val){
            this.displayInMenu();
        }else{
            this.displayOutMenu();
        }
    }

    toggleClass = (className, element) => {
        if(element.classList.contains(className)){
            element.classList.remove(className)
        }else{
            element.classList.add(className)
        }
    }

    displayInMenu = () => {
        const nav = document.querySelector(".NavLinkResponsive");
        const hoverley = document.querySelector(".NavLinkResponsive .navlink-hoverley");
        const navlink = document.querySelector(".NavLinkResponsive .NavLink");

        this.toggleClass("show", nav);
        this.toggleClass("fade-in", hoverley);
        this.toggleClass("slide-in-left", navlink);
    }

    displayOutMenu = () => {
        
        const nav = document.querySelector(".NavLinkResponsive");
        if(nav){
            const hoverley = document.querySelector(".NavLinkResponsive .navlink-hoverley");
            const navlink = document.querySelector(".NavLinkResponsive .NavLink");

            this.toggleClass("fade-in", hoverley);
            this.toggleClass("fade-out", hoverley);
            this.toggleClass("slide-in-left", navlink);
            this.toggleClass("slide-out-left-2", navlink);

            setTimeout(() => { 
                this.toggleClass("show", nav);
                this.toggleClass("fade-out", hoverley);
                this.toggleClass("slide-out-left-2", navlink);
            }, 300);
        }
    }

    displayComponent = () => {
        if(this.state.show){
            return "show";
        }else{
            return "";
        }
    }

    hideMenu = (index, theme) => {
        this.toggleMenu(false);
        setTimeout(() => {
            this.setState({show: false});
        }, 300);
    }

    render(){
        const MyNavLink = React.forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);
        return(
            <div className={`NavLinkResponsive ${ this.displayComponent()} ${this.props.className}`}>
                <MyNavLink ref={ this.navlink } {...this.props} onItemClick={(index, theme) => this.hideMenu(index, theme)} />
                <div className="navlink-hoverley " onClick={() => this.toggleMenu(false)}></div>
            </div>
        )
    }
}

export default NavLinkResponsive;