import React from "react";

const IconBankDefaultComponent = props => (
  <svg width={props.width ? props.width : 30.033} height={props.height ? props.height :26.149} viewBox="0 0 30.033 26.149" {...props}>
    <g 
    transform="translate(-1.431)"
    className={`${props.className ? props.className : ''}`}
    style={{"--theme-color": props.activecolor}}
    >
      <path
        fill={props.color ? props.color : "#ffffff"}
        d="M1.823,4.3a.534.534,0,1,0,0,1.068h25.72a.534.534,0,1,0,0-1.068H1.823Z"
        transform="translate(1.764 18.474)"
      />
      <path
        fill={props.color ? props.color : "#ffffff"}
        d="M1.6,4.62a.534.534,0,1,0,0,1.068H30.566a.534.534,0,0,0,0-1.068H1.6Z"
        transform="translate(0.364 20.461)"
      />
      <path
        fill={props.color ? props.color : "#ffffff"}
        d="M2.326,2.585H5.4a.534.534,0,0,1,.534.534V13.425a.534.534,0,0,1-.534.534H2.326a.534.534,0,0,1-.534-.534V3.119A.534.534,0,0,1,2.326,2.585ZM4.863,3.653h-2v9.238h2Z"
        transform="translate(4.922 7.681)"
      />
      <path
        fill={props.color ? props.color : "#ffffff"}
        d="M3.4,2.585H6.469A.534.534,0,0,1,7,3.119V13.425a.534.534,0,0,1-.534.534H3.4a.534.534,0,0,1-.534-.534V3.119A.534.534,0,0,1,3.4,2.585ZM5.935,3.653h-2v9.238h2Z"
        transform="translate(11.656 7.681)"
      />
      <path
        fill={props.color ? props.color : "#ffffff"}
        d="M4.47,2.585H7.541a.534.534,0,0,1,.534.534V13.425a.534.534,0,0,1-.534.534H4.47a.534.534,0,0,1-.534-.534V3.119A.534.534,0,0,1,4.47,2.585ZM7.008,3.653H5v9.238h2Z"
        transform="translate(18.39 7.681)"
      />
      <path
        fill={props.color ? props.color : "#ffffff"}
        d="M14.463,1.285,26.638,9.326h0a.534.534,0,0,1-.3.98H1.933a.534.534,0,0,1-.263-1L13.844,1.265l.294.444-.294-.446a.534.534,0,0,1,.619.021ZM24.569,9.237,14.139,2.348,3.708,9.237H24.569Z"
        transform="translate(2.451 -1.175)"
      />
      <path
        fill={props.color ? props.color : "#ffffff"}
        d="M4.9,1.592a2.029,2.029,0,1,1-1.435.594A2.022,2.022,0,0,1,4.9,1.592Zm.68,1.349a.961.961,0,1,0,.281.68A.958.958,0,0,0,5.578,2.942Z"
        transform="translate(11.691 1.445)"
      />
      <path
        fill={props.color ? props.color : "#ffffff"}
        fillRule="evenodd"
        d="M10.118,5.23a2.029,2.029,0,1,1,.594,1.435A2.022,2.022,0,0,1,10.118,5.23Zm12.459,3L12.147,1.336,1.716,8.225H22.577Z"
        transform="translate(4.443 -0.164)"
      />
      <path
        fill={props.color2 ? props.color2 : "rgba(255,255,255,0)"}
        fillRule="evenodd"
        d="M3.3,2.02a.961.961,0,1,0,.68-.281A.958.958,0,0,0,3.3,2.02Z"
        transform="translate(12.612 2.366)"
        className="circle"
      />
      <path
        fill={props.color ? props.color : "#ffffff"}
        fillRule="evenodd"
        d="M5.014,2.732h-2v9.238h2Z"
        transform="translate(12.577 8.603)"
      />
      <path
        fill={props.color ? props.color : "#ffffff"}
        fillRule="evenodd"
        d="M4.083,2.732v9.238h2V2.732Z"
        transform="translate(19.311 8.603)"
      />
      <path
        fill={props.color ? props.color : "#ffffff"}
        fillRule="evenodd"
        d="M3.942,11.969V2.732h-2v9.238Z"
        transform="translate(5.844 8.603)"
      />
    </g>
  </svg>
);

export default IconBankDefaultComponent;
