/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const IoniconsSvgIosArrowRoundForwardComponent = () => (
  <svg width={14.424} height={9.62} viewBox="0 0 14.424 9.62">
    <defs>
      <style>{".next{fill:#fff;}"}</style>
    </defs>
    <path
      className="next"
      d="M121.2,160.208a.655.655,0,0,0-.005.922l3.046,3.051H112.646a.651.651,0,0,0,0,1.3h11.588l-3.046,3.051a.659.659,0,0,0,.005.922.649.649,0,0,0,.917-.005l4.128-4.158a.732.732,0,0,0,.135-.205.622.622,0,0,0,.05-.251.653.653,0,0,0-.185-.456l-4.128-4.158A.638.638,0,0,0,121.2,160.208Z"
      transform="translate(-112 -160.024)"
    />
  </svg>
);

export default IoniconsSvgIosArrowRoundForwardComponent;
