// elem = document.querySelector('css_selector')
export const containsClass = (elem, className) => {
    return elem.classList.contains(className)
}
export const addClass = (elem, className) => {
  return elem.classList.add(className)
}
export const removeClass = (elem, className) => {
  return elem.classList.remove(className)
}
export const toggleClass = (elem, className) => {
    return elem.classList.toggle(className)
}
export const darggable = (dragItem, container) => {

    var active = false;
    var currentX;
    var currentY;
    var initialX;
    var initialY;
    var xOffset = 0;
    var yOffset = 0;

    container.addEventListener("touchstart", dragStart, false);
    container.addEventListener("touchend", dragEnd, false);
    container.addEventListener("touchmove", drag, false);

    container.addEventListener("mousedown", dragStart, false);
    container.addEventListener("mouseup", dragEnd, false);
    container.addEventListener("mousemove", drag, false);

    function dragStart(e) {
      if (e.type === "touchstart") {
        initialX = e.touches[0].clientX - xOffset;
        initialY = e.touches[0].clientY - yOffset;
      } else {
        initialX = e.clientX - xOffset;
        initialY = e.clientY - yOffset;
      }

      if (e.target === dragItem) {
        active = true;
      }
    }

    function dragEnd(e) {
      initialX = currentX;
      initialY = currentY;

      active = false;
    }

    function drag(e) {
      if (active) {
      
        e.preventDefault();
      
        if (e.type === "touchmove") {
          currentX = e.touches[0].clientX - initialX;
          currentY = e.touches[0].clientY - initialY;
        } else {
          currentX = e.clientX - initialX;
          currentY = e.clientY - initialY;
        }

        xOffset = currentX;
        yOffset = currentY;

        setTranslate(currentX, currentY, dragItem);
      }
    }

    function setTranslate(xPos, yPos, el) {
      el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";
    }
};

export const staticNode = (selector, offsetValue = 100, classNameAgent = "offset" ) => {
  document.addEventListener('scroll', () => {
      const isTop = window.scrollY > +offsetValue;
      let selectorObj = document.querySelector(selector);
      if(selectorObj !== null){
          if (isTop) {
              if(!selectorObj.classList.contains(classNameAgent)){
                  selectorObj.classList.add(classNameAgent);
              }
          }else{
              if(selectorObj.classList.contains(classNameAgent)){
                  selectorObj.classList.remove(classNameAgent);
              }
          }
      }
  });
}