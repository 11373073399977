import { combineReducers } from 'redux';
import StaffReducer from './StaffReducer';
import OrganizationReducer from './organizationReducer';
import AuthReducer from './AuthReducer';
import NavReducer from './NavReducer';
import PayPeriodReducer from './PayPeriodReducer';
import PayslipReducer from './PayslipReducer';
import ValidationReducer from './ValidationReducer';
import ContractReducer from './ContractReducer';
import ThemeReducer from './ThemeReducer';
import {reducer as toastrReducer} from 'react-redux-toastr'
import BankReducer from './BankReducer';
import UserReducer from './UserReducer';
import TaxesReducer from './TaxesReducer';
import AccountReducer from './AccountReducer';

export default combineReducers({
    staffs: StaffReducer,
    organizations: OrganizationReducer,
    auth: AuthReducer,
    nav: NavReducer,
    payPeriod: PayPeriodReducer,
    payslips: PayslipReducer,
    validation: ValidationReducer,
    contracts: ContractReducer,
    bank: BankReducer,
    accounts: AccountReducer,
    theme: ThemeReducer,
    users: UserReducer,
    taxes: TaxesReducer,
    toastr: toastrReducer
});
