import React, { Component } from 'react';
import Btn from 'react-bootstrap/Button';
import './Button.css';

export default class Button extends Component {
    render() {
        let { variant, title, children, loading, onClick, className, block, disabled } = this.props
        return(
            <Btn variant={variant} disabled={loading || disabled} onClick={onClick} bsPrefix={className} block={block}>
                {!loading ? title || children : 'Loading...'}
            </Btn>
        );
    }
}