import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { PATHS, SESSION_STORAGE_KEYS } from '../constants/constants';
import * as authSvc from '../services/Auth';

let redirect_url = PATHS.AUTH_FAILED_URL;

const AuthorizationRoute = ({ component: Component, ...rest }) => {
    const functionalitycode = rest.functionalitycode;
    return (
        <Route
            {...rest}
            render={routeProps => (
                (checkAuth() && hasAuthorisation(functionalitycode)) ? (
                    <Component {...routeProps} functionalitycode={functionalitycode} />
                ) : (
                        <Redirect to={redirect_url} />
                    )
            )}
        />
    )
}

const checkAuth = () => {
    const auth = authSvc._checkAuth();
    if (!auth) {
        redirect_url = PATHS.AUTH_FAILED_URL;
    }
    return auth;
}

const hasAuthorisation = (functionCode, url = PATHS.UNAUTHORIZED_PATH) => {
    const tokenJson = sessionStorage.getItem(SESSION_STORAGE_KEYS.TOKEN);
    if(!tokenJson){
        return false;
    }

    if (!functionCode) {
        return true;
    }
    
    const result = authSvc._canAccess(functionCode);
    if (!result) {
        redirect_url = url;
    }
    return result;
}

export default AuthorizationRoute;

