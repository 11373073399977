import React, { Component } from 'react'
import DashboardLayout from '../Dashboard/DashboardLayout'
import SocialContent from './SocialContent'


export default class Social extends Component {

    render() {
        
        return(
            <DashboardLayout title={'Social'}>
                <SocialContent {...this.props}/>
            </DashboardLayout>
        )
    }
}