import React, { Component } from "react";
import PropTypes from "prop-types";
import "./RowGroup.css";

class RowGroup extends Component {
    constructor(props){
        super(props);
        this.state = {};
    }

    render(){
        return(
            <div className={`row-group ${this.props.className ? this.props.className : ''}`} id={`${this.props.id ? this.props.id : new Date()}`}>
                {this.props.children}
            </div>
        )
    }
}

RowGroup.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    id: PropTypes.string,
}

export default RowGroup;
