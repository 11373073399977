import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import './Social.css';
import { setStaffs, selectStaff, setAllowancesStaff } from '../../redux/action/StaffAction';
import Staff from './SocialPage/Staff';
import SocialPage from './SocialPage';
import SubNavLink from "../../views/SubNavLink";

class Social extends Component {
    constructor(props){
        super(props)
        this.state = {
            menu: [
                { label: 'Staff', route: '', active: true, notifications: 0 },
                { label: 'Fiche de paie', route: '/payslip', active: false, notifications: 0 },
                /*
                { label: 'Sécurité social', route: '/social-security', active: false, notifications: 0 },
                { label: 'Absence', route: '/absence', active: false, notifications: 0 }*/
            ],
            titles: [
                {title:'Nom & Prénom', className: ""}, 
                {title:'Téléphone', className: "hide-xs-responsive"}, 
                {title:'Email', className: ""}, 
                {title:'Rôle', className: ""}
            ],
            hideMenu: false,
            showMenu: true,
            modalType: 'create',
            modalCreate: false,
            modalDetail: false,
            selectedItem: null,
            pageOfItems: [],
            loadData: false,
            pager: {},
            colorTheme:  {
                bgColor: "#FFFFFF",
                color: "#B5B5B5",
                activeColor: "#177BD1",
                hoverColor: "transparent",
            }
        }
    }

    setDefaultActiveSubMenu = () => {
        const {pathname} = window.location
        const param = this.getCurrentSubRouteParam(pathname)
        let {menu} = this.state
        

        menu.map((item, index) => (
            item.active = false
        ))
        let activeItem = menu.find((item) => { return item.route ===  '/'+param }) 
        
        activeItem ? 
            activeItem.active = true :
            menu[0].active = true
    }

    UNSAFE_componentWillMount(){
        this.setDefaultActiveSubMenu()

    }

    componentDidMount() {
        // this.toggleSubMenu();
    }

    onDisplayCreate = () => {
        this.props.staffs.map((staff, index) => {
            this.props.staffs[index].selected = false
            return staff
        })
        this.setState({
            modalType: 'create',
            modalCreate: true,
        })
    }

    onClose = () => {
        this.props.staffs.map((staff, index) => {
            this.props.staffs[index].selected = false
            return staff
        })
        this.setState({
            modalCreate: false,
            contentWidth: this.state.hideMenu ? 'w-100' : 'w-100',
        })
    }

    onHideMenu = () => {}

    toggleMenu = () => {
        this.setState({
            showMenu: !this.state.showMenu
        })
    }

    onSelectItem = (item) => {
        this.setState({
            modalType: 'detail',
            modalCreate: true,
            selectedItem: item
        })
        this.props.selectStaff(item);
        this.props.setAllowancesStaff(item.contracts[0].contractAllowances);
    }


    setActive(key) {
        let newMenu = this.state.menu.map(item => {
            item.active = false
            return item
        })
        newMenu[key].active = true
        this.setState({
            menu: [...newMenu]
        })
    }

    getCurrentSubRouteParam = (pathname) => {
        const part = pathname.split('/')
        return part[ part.length - 1 ]
    }

    getMenuItemActive = (menuItem) => {
        let activItem = menuItem.find((elem) => { return elem.active === true });
        return [activItem.label, activItem.notifications]
    }
    
    render() {
        let { match } = this.props
        let { menu } = this.state;
        
        return(
            <div className='social-content'>
                <SubNavLink match={match} menu={menu} colorTheme={this.state.colorTheme} />
                <Route exact path={match.url} render={() => {
                    return <Staff titles={this.state.titles} modalCreate={this.state.modalCreate} 
                        displayCreate={() => this.onDisplayCreate()} onSelectItem={(item) => this.onSelectItem(item)}
                        modalType={this.state.modalType} selectedItem={this.state.selectedItem} onClose={() => this.onClose()} />
                }} />
                <Route path={match.url+'/:page'} component={SocialPage} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { organizations, staffs } = state
    return {
        staffs: staffs.staffMembers,
        selectedStaff: staffs.selectedStaff,
        selectedAllowancesStaff: staffs.allowancesStaffSelected,
        defaultOrganization: organizations.defaultOrganization
    }
}

export default connect(mapStateToProps, { setStaffs, selectStaff, setAllowancesStaff })(Social)