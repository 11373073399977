import React, {Component} from 'react'
import PropTypes from 'prop-types'
import './FormCardInput.css';
import {Overlay, Popover} from "react-bootstrap";

class FormCardInput extends Component {

    constructor (props) {
        super(props)
        this.fileInputRef = React.createRef();
        this.target =  React.createRef();
    }

    getClass = (error) => {
        return error ? 'formCard-select formCard-text-error' : 'formCard-select';
    }

    getFileName = (file) => {
        if (!file) return `Choisir un fichier  ${this.props.required ? '*' : ''}`;
        let { name } = file
        return name.length > 25 ? name.slice(0, 25) + "..." : name
    }

    render(){
        const {error, value, prependIcon, accept, onChange, placeholder, type, name, options, optionValue, optionIcon, required} = this.props;
        return(
            <>
                <div ref={this.target} className={'formCard-wrapper'}>
                {
                    type === 'select' ? 
                    <div className="formCard-row">
                        <div className="formCard-prepend-icon mr-20">
                            {prependIcon}
                        </div>
                        <select name={name ? name : ''} className={this.getClass(error)} onChange={onChange} onFocus={null} value={value} defaultValue={""} onBlur={null}>
                            <option value="" disabled >&nbsp;&nbsp;{`${placeholder} ${required ? '*' : ''}`}</option>
                            {
                                options.map((option, index) => {
                                    if(option){
                                        return <option key={index} value={option[optionValue]}>
                                            {optionIcon ? option[optionIcon] : ""}  {option[optionValue]}
                                        </option>
                                    }
                                    return ""
                                })
                            }
                        </select>
                    </div> :
                        type === 'file' ? 
                        <div className="formCard-row">
                        <input
                            ref={this.fileInputRef}
                            className="hidden"
                            type={type}
                            onChange={onChange}
                            accept={accept}
                            name={name}
                        />
                        <button className="formCard-upload formCard-row" onClick={() => {this.fileInputRef.current.click()}}> 
                            <div className="formCard-prepend-icon mr-20">
                                {prependIcon}
                            </div>
                            <span> { this.getFileName(value)} </span>
                        </button> 
                    </div> :
                        (type === 'button' || type === 'submit') ?
                        <button className="formCard-button" type={type} onClick={this.props.onClick}>
                            {this.props.value}
                        </button>
                    :
                    <div className="formCard-row">
                        <div className="formCard-prepend-icon mr-20">
                            {prependIcon}
                        </div>
                        <input className="formCard-input"  type={type || "text"} name={name} required={required} placeholder={`${placeholder}  ${required ? '*' : ''}`} value={value} onChange={onChange}/>
                    </div>

                }
                </div>
                <Overlay target={this.target.current} show={this.props.error?.msg ? true: false} placement={this.props.error?.placement ? this.props.error?.placement: "top"}>
                    {
                        this.props.error?.msg ? <Popover id="popover-contained" bsPrefix={`popover err ${this.props.error?.placement}`}>{this.props.error?.msg}</Popover> : <div>ok</div>
                    }
                </Overlay>
            </>
        )
    }

}

FormCardInput.propTypes = {
    name: PropTypes.string,
    type: PropTypes.string,
    accept: PropTypes.string,
    error: PropTypes.bool,
    value: PropTypes.any,
    required: PropTypes.bool,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    prependIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onChange: PropTypes.func
}

export default FormCardInput