import axios, {baseInstance} from "./axios";
import { toastr } from "react-redux-toastr";
import { getMessageError, isBasicError } from "../helpers/http.helper";
import printerAxios from "./printerAxios";
import { authAxios } from "../services/Auth";
import Logger from "../helpers/Logger";
import { SESSION_STORAGE_KEYS } from "../constants/constants";

export default {
  setupInterceptors: (store) => {
    axios.interceptors.request.use(
      function(config) {
        const authToken = JSON.parse(sessionStorage.getItem("token"));
        if (authToken !== null) {
          const { access_token } = authToken;
          if (access_token !== null) {
            config.headers.Authorization = `Bearer ${access_token}`;
          }
        }
        return config;
      },
      function(error) {
        Logger.error('NetworkServiceError','fail to setup interceptors', error);
        return Promise.reject(error);
      }
    );
    const axiosInstances = [axios, printerAxios, authAxios, baseInstance];
    axiosInstances.forEach((instance) => {
      instance.interceptors.response.use(
        (config) => {
          return config;
        },
        async (error) => {
          const originalRequest = error.config;
          const errorStatus = error?.response?.status;
          const errorMessage = getMessageError(errorStatus);
          let logStatusError = errorStatus ? errorStatus : "CONNECTION ERROR";
          logStatusError = "("+logStatusError+")";
          if(!errorStatus || errorStatus === 500)
            Logger.error("HTTP ERROR", "Http Error Occured " + logStatusError ,error);
            // token expiré on le rafraichi
          if(errorStatus === 498) {
            const newTokens = await refreshtoken();
            sessionStorage.setItem(SESSION_STORAGE_KEYS.TOKEN, JSON.stringify(newTokens.data));
            Logger.debug("Token REFRESH","Token automaically refreshed after expired");
            Logger.debug("Token REFRESH","Now sending originalRequest"+ JSON.stringify(originalRequest));
            return instance(originalRequest);
          }
          if(isBasicError(errorStatus)) toastr.error("Error", errorMessage);
          return Promise.reject(error);
        }
      );
    });
  },
};

const refreshtoken = () => {
  const jsonToken =  sessionStorage.getItem(SESSION_STORAGE_KEYS.TOKEN);
  const authToken = JSON.parse(jsonToken)
  
  const body = {
    access_token: authToken.access_token,
    refresh_token: authToken.refresh_token
  }
  return axios.post("/auth/refresh-token",body)
}