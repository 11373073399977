import React, { Component } from 'react'
import './Loader.css'

class Loader extends Component {
    constructor(props){
        super(props) 
        this.state = {
            enable: this.props.enable || false,
        }
        this.toggleLoad = this.toggleLoad.bind(this);
    }

    toggleLoad = () => {
        this.setState({
            enable: !this.state.enable
        })
    }

    disableLoad = () => {
        this.setState({
            enable: false
        })
    }

    render(){
        const { enable } = this.props;
        return(
            <div className={(this.props.loadData || enable) ? `${this.props.className} loader  visible` : `${this.props.className} loader`} style={(this.props.style) ? this.props.style : {} } ></div>
        )
    }
}

export default Loader