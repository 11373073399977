import React, { Component } from 'react'
import DashboardLayout from '../Dashboard/DashboardLayout'
import TreasuryContent from './TreasuryContent'

export default class Treasury extends Component {
    render() {
        return(
            <DashboardLayout title={'Trésorerie'}>
                <TreasuryContent {...this.props}/>
            </DashboardLayout>
        )
    }
}