import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import { Form, Button } from 'react-bootstrap'
import Loader from '../../components/Loader'
import './Auth.css'
import { _authenticate } from '../../services/Auth';
import { setAuthData, setCurrentAuthUser, setAuthToken, setSocketService} from '../../redux/action/AuthAction';
import { addOrganizationBankRecords } from '../../redux/action/OrganisationAction';
import { setRecords, setStatements } from '../../redux/action/AccountAction';
import { updatePayslip, setPayslipsSentByMail} from '../../redux/action/PayslipAction';
import { setBankStatements, setLoaderImportBankStatement } from "../../redux/action/bankAction";
import { selectCnpsDeclaration } from "../../redux/action/TaxesAction";
import { setClosePayPeriodLoader } from "../../redux/action/PayPeriodAction";
import SocketService from '../../services/SocketService';
import Logger from '../../helpers/Logger';
import { SESSION_STORAGE_KEYS } from '../../constants/constants';
import { errorNotifcation } from "../../helpers/errorHandle";

class Auth extends Component {
    constructor(props){
        super(props)
        this.state = {
            login: '',
            password: '',
            remember: false,
            error: null,
            redirect: false,
            pathName: '/register',
            disabled: false
        };
        this._isMounted = false;
    }

    componentDidMount = () => {
        this._isMounted = true;
    }
    componentWillUnmount = () => {
        this._isMounted = false;
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        }, () => { 
        })
    }
    handleSubmit = async(event) => {
        try {
            this.refs.loader.toggleLoad();
            event.preventDefault();
            sessionStorage.clear();
            this.setState({
                error: null,
                disabled: true
            });
            this.refs.loader.toggleLoad();
            const { login, password } = this.state;
            const result = (await _authenticate({username: login, password})).data;
            sessionStorage.setItem(SESSION_STORAGE_KEYS.TOKEN, JSON.stringify(result));
            this.props.setAuthToken(result);
            SocketService.initWebsocket(this.props);
            await this.props.changeAuthState('REDIRECT', null);
        } catch(err) {
            const response = err.request && err.request.response
            if(response) {
                let errorResponse = JSON.parse(response);
                Logger.error('Error response', errorResponse);
                if(errorResponse.exception && errorResponse.exception.includes('AccountSetupException')) {
                    this.props.changeAuthState('NEWPASSWORD', errorResponse.token);
                }
                if(errorResponse.message && errorResponse.message.includes('Invalid user credentials')) {
                    this.setState({
                        error: 'Nom d\'utilisateur ou mot de passe incorrect.'
                    })
                }
            }
            Logger.error("Authenticate Handle Submit", "Failed to authenticate user", err);
            errorNotifcation(err?.response?.status, err?.response?.data || "Failed to authenticate user", "Authentification");
        } finally {
            if(this.refs.loader)
                this.refs.loader.toggleLoad();
            this.setState({disabled: false});
        }
    }

    loadError = () => {
        let { error } = this.state
        return error != null ? (
            <Form.Text className="text-muted">
                <span style={{color: "red"}} > {error} </span>
            </Form.Text>
        ) : <div></div>
    }

    gotoRegister = () => {
        this.setState({
            redirect: true
        })
    }

    gotoForgotPassword = () => {
        this.setState({
            redirect: true,
            pathName: 'forgot-password'
        })
    }

    render(){
        if(this.state.redirect) return <Redirect to={this.state.pathName}  />
        return (
            <div className="auth">
                <div className="box-auth">
                <Form onSubmit={this.handleSubmit}>
                    <Form.Group>
                        <h3>Dizen</h3>
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Login</Form.Label>
                        <Form.Control type="text" name="login" onChange={this.handleChange} placeholder="Votre login" />
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Mot de passe</Form.Label>
                        <Form.Control type="password" name="password" onChange={this.handleChange} placeholder="Votre mot de passe" />
                    </Form.Group>
                    <Form.Group controlId="formBasicChecbox" className="d-flex align-items-center justify-content-between">
                        <div className="create-account" onClick={this.gotoForgotPassword}>Mot de passe oublié ?</div>
                        <div className="create-account" onClick={this.gotoRegister}>Créer un compte</div>
                    </Form.Group>
                    <Form.Group>
                        {
                            this.loadError()
                        }
                    </Form.Group>
                    <div className="bottom d-flex align-items-center justify-content-between">
                        <Button variant="primary" type="submit" className="h-40" disabled={this.state.disabled}>
                           <div style={{"position": "relative"}}>
                                Se connecter 
                                <Loader 
                                    ref="loader" 
                                    enable={this.state.disabled} 
                                    style={{
                                        width: '20px', 
                                        height: '20px', 
                                        position: "absolute", 
                                        top: 0, 
                                        left: "30%",
                                        borderColor: "rgba(0,0,0,0.5)",
                                        borderTop: "#FFFFFF",
                                    }} 
                                />
                            </div>
                        </Button>
                        
                        <Form.Check type="checkbox" label="Se souvenir de moi" />
                    </div>
                </Form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { auth } = state
    return {
        authData: auth.authData,
        authToken: auth.authToken,
        roles: auth.roles,
    }
}
export default connect(mapStateToProps, { setAuthData, setAuthToken, setCurrentAuthUser, setSocketService, addOrganizationBankRecords, updatePayslip, setPayslipsSentByMail, setBankStatements, setLoaderImportBankStatement, setClosePayPeriodLoader, setStatements, setRecords, selectCnpsDeclaration })(Auth)