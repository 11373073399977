import React, { Component } from 'react';
import Statement from './BankPage/Statement';
import Record from './BankPage/Record';

class BankPage extends Component {
    renderPage = () => {
        let { match } = this.props
        switch(match.params.page){
            case 'statements':{
                return <Statement match={match} {...this.props} />
            }
            case 'accounts':{
                return <Record match={match} {...this.props} />
            }
            default:
                return <Record match={match} {...this.props} />
        }
    }
    render() {
        return(
            <div className="flex-part">
                {this.renderPage()}
            </div>
        )
    }
}

export default BankPage