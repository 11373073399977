export const ADD_ORG = 'ADD_ORG'
export const SET_ORGS = 'SET_ORGS'
export const SET_DEFAULT_ORG = 'SET_DEFAULT_ORG'
export const SET_ORG_INVOICES = 'SET_ORG_INVOICES'
export const ADD_ORG_INVOICE = 'ADD_ORG_INVOICE'
export const ADD_ORG_INVOICE_MULTIPLE = 'ADD_ORG_INVOICE_MULTIPLE'
export const UPDATE_ORG_INVOICE = 'UPDATE_ORG_INVOICE' 
export const UPDATE_SELECTED_INVOICE = 'UPDATE_SELECTED_INVOICE' 
export const SET_ORG_BANK_REPORTS = 'SET_ORG_BANK_REPORTS'
export const ADD_ORG_BANK_RECORDS = 'ADD_ORG_BANK_RECORDS'
export const ADD_ORG_BANK_RECORDS_MULTIPLE = 'ADD_ORG_BANK_RECORDS_MULTIPLE'
export const UPDATE_UNRECONCILED_INVOICES = 'UPDATE_UNRECONCILED_INVOICES'
export const SET_RECONCILED_INVOICES = 'SET_RECONCILED_INVOICES'
export const SET_SELECTED_INVOICE = 'SET_SELECTED_INVOICE'
export const TOGGLE_ORG_ROLE_CHANGE = 'TOGGLE_ORG_ROLE_CHANGE'
export const SET_SELECTED_ORG_USER = 'SET_SELECTED_ORG_USER'
export const SET_ORG_USERS = 'SET_ORG_USERS'
export const SET_RECONCICLED_BANK_RECORD_ITEM = 'SET_RECONCICLED_BANK_RECORD_ITEM'
export const SET_ORG_BILLS = 'SET_ORG_BILLS'
export const ADD_ORG_BILL = 'ADD_ORG_BILL'
export const ADD_MULTIPLE_ORG_BILLS = 'ADD_MULTIPLE_ORG_BILLS'
export const UPDATE_ORG_BILL = 'UPDATE_ORG_BILL'
export const DELETE_ORG_BILL = 'DELETE_ORG_BILL'
export const SET_ORG_CUSTOMERS = 'SET_ORG_CUSTOMERS';
export const ADD_MULTIPLE_ORG_CUSTOMERS = 'ADD_MULTIPLE_ORG_CUSTOMERS';
export const SET_ORG_TAXES = 'SET_ORG_TAXES'
export const SELECTED_DEFAULT_ORG_TAXES = 'SELECTED_DEFAULT_ORG_TAXES'
export const ADD_ORG_TAXES = 'ADD_ORG_TAXES'
export const ADD_ORG_TAXES_MULTIPLE = 'ADD_ORG_TAXES_MULTIPLE'
export const UPDATE_ORG_TAXES = 'UPDATE_ORG_TAXES' 
export const DELETE_ORG_TAXES = 'DELETE_ORG_TAXES' 
