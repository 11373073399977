import Countries from "country-data";

let tab = [];
let Currencies = [];

/* Countries.countries.all.map((elm, i) => {
    return tab.push(elm.currencies[0]);
});

const setCurrencies = new Set(tab);
for (let item of setCurrencies)
{ if(item) Currencies.push({currency: item}); }; */

Countries.countries.all.forEach((item) => {
    if(item.currencies[0] && !tab[item.currencies[0]]){
        tab[item.currencies[0]] = true;
        Currencies.push({currency: item.currencies[0]});
    }
});

export default Currencies;