import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import SubNavLink from "../../views/SubNavLink";
import {PAGINATION} from "../../constants/constants";
import TaxePage from "./TaxePage";
import TaxeList from "./TaxeList";

import "./taxes.css";

class TaxesContent extends Component {
    constructor(props){
        super(props);
        this.state = {
            menu: [
                { label: 'Impôts', route: '', active: true, notifications: 0 },
                { label: 'CNPS', route: '/cnps', active: false, notifications: 0 },
            ],
            loading: false,
            activePage: PAGINATION.DEFAULT_PAGE,
            pageSize: PAGINATION.DEFAULT_PAGE_SIZE,
            bankRecords: [],
            pager: null,
            selectedItem: {},
            displayModal: false,
            loadModalBank: false,
            loadingUnreconciledInvoices: false,
            loadingReconciledInvoices: false,
            scanAttachment: '',
            showScanPreview: false,
            showFilePreviewResponsive: false,
            unselectedInvoice: false,
            colorTheme:  {
                bgColor: "#FFFFFF",
                color: "#B5B5B5",
                activeColor: "#09B6CC",
                hoverColor: "transparent",
            }
        };
    }

    setDefaultActiveSubMenu = () => {
        const {pathname} = window.location
        const param = this.getCurrentSubRouteParam(pathname)
        let {menu} = this.state
        

        menu.forEach((item) => (
            item.active = false
        ))
        let activeItem = menu.find((item) => { return item.route ===  '/'+param }) 
        
        activeItem ? activeItem.active = true : menu[0].active = true;
    }

    UNSAFE_componentWillMount(){
        this.setDefaultActiveSubMenu()
    }
    UNSAFE_componentWillUpdate = () => {
        this.setDefaultActiveSubMenu()
    }

    getCurrentSubRouteParam = (pathname) => {
        const part = pathname.split('/')
        return part[ part.length - 1 ]
    }

    render() {
        return(
            <div className='taxe-content'>
                <SubNavLink match={this.props.match} menu={this.state.menu} colorTheme={this.state.colorTheme} />
                <Route exact path={this.props.match.url} render={() => {
                    return <TaxeList {...this.props} />
                }} />
                <Route path={this.props.match.url+'/:page'} component={TaxePage} />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { auth, organizations } = state
    return {
        authData: auth.authData,
        organizations: organizations.organizations,
        defaultOrg: organizations.defaultOrganization,
        pager: organizations.organizationBankRecords.pager
    }
}

export default connect(mapStateToProps, { })(TaxesContent)
