
import React from "react";

const IconMenuComponent = props => (
  <svg width={25} height={25} viewBox="0 0 25 25" {...props}>
    <g transform="translate(-334 -12.772)">
      <line fill="none" strokeLinecap="round" strokeWidth={props.strokeWidth ? props.strokeWidth : "2px"} stroke={props.color ? props.color : "#B5B5B5"} x2={17.249} transform="translate(337.5 19.5)" />
      <line fill="none" strokeLinecap="round" strokeWidth={props.strokeWidth ? props.strokeWidth : "2px"} stroke={props.color ? props.color : "#B5B5B5"} x2={17.249} transform="translate(337.5 25.272)" />
      <line fill="none" strokeLinecap="round" strokeWidth={props.strokeWidth ? props.strokeWidth : "2px"} stroke={props.color ? props.color : "#B5B5B5"} x2={17.249} transform="translate(337.5 31.043)" />
      <rect
        fill="rgba(255,255,255,0)"
        width={25}
        height={25}
        transform="translate(334 12.772)"
      />
    </g>
  </svg>
);

export default IconMenuComponent;
