import axiosDefaultClient from '../config/axios';

// Bank records reconciliation
export const _reconcile = async (orgId, bankRecordId, data) => {
    return await axiosDefaultClient.post(
        `/organizations/${orgId}/bank-records/${bankRecordId}/reconcile`, data
    );
}
export const _unreconcile = async (orgId, bankRecordId, invoiceId) => {
    return await axiosDefaultClient.delete(
        `/organizations/${orgId}/bank-records/${bankRecordId}/unreconcile/${invoiceId}`
    );
}

// Get bank record
export const _getRecordById = async (orgId, id) => {
    return await axiosDefaultClient.get(`/organizations/${orgId}/records/${id}`);
}

// move bank record to cash account
export const _moveToCashAccount = async (orgId, bankRecordId) => {
    return await axiosDefaultClient.post(`/organizations/${orgId}/bank-records/${bankRecordId}/move-to-cash-account`);
}

// remove cash record from cash account
export const _removeFromCashAccount = async (orgId, bankRecordId) => {
    return await axiosDefaultClient.delete(`/organizations/${orgId}/bank-records/${bankRecordId}/remove-from-cash-account`);
}