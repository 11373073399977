import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import IconPlus from '../../components/Icons/IconPlus';
import IconSave from '../../components/Icons/SaveIcon';
import List from "./List";
import BillViews from "./BillViews";
import IconClose from "../../components/Icons/IconClose";

import "./Bills.css";

class BillList extends Component {
    constructor(props){
        super(props);
        this.state = {
            loadBill: false,
            isEditView: false,
        }
    }

    gotoEditView = () => {
        this.setState({ isEditView: true }, () => {
            this.props.history.push(this.props.match.url+"/bills/edit", {state: "edit"});
        });
    }

    canselEdit = () => {
        this.setState({ isEditView: false }, () => {
            this.props.history.push(this.props.match.url+"/");
        });
    }

    saveBill = () => {
        this.setState({ isEditView: false }, () => {
            this.props.history.push(this.props.match.url+"/bills");
        });
    }

    renderBtnView = () => {
        if(this.props.match.params?.view === "edit"){
            return(
                <div className="d-flex align-items-center">
                    <button onClick={this.saveBill} className="btn-save-bill d-flex align-items-center mr-2 p2">
                        <span className="mr-2">Enregistrer</span>
                        <IconSave color="#0C9F23" width={10} height={10} /> 
                    </button>
                    <button className="btn-cancel-bill" onClick={this.canselEdit}>
                        <IconClose width={8} height={8} />
                    </button>
                </div>
            )
        }else{
            return( 
                <button className='btn-plus bill' onClick={this.gotoEditView}>
                    <IconPlus width={9} height={9} />
                </button> 
            );
        }
    }

    render() {
        return (
            <div className='bill-list col-display'>
                <div className="d-flex flex-column flex-1-auto pl-3">
                    <div className='flex-1-auto mt-4 mr-3'>
                        <Route exact path={this.props.match.url} render={() => {
                            return <List {...this.props} />
                        }} />
                        <Route exact path={this.props.match.url+'/:view'} component={BillViews} />
                    </div>
                </div>
            </div>
        )
    }
}

export default BillList;
