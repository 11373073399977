import React, { Component } from "react";
import { connect } from "react-redux";
import { setSelectedBankAccount } from "../../redux/action/bankAction";
import { selectAccount } from '../../redux/action/AccountAction';
import IconAccountBalance from "../Icons/IconAccountBalance";
import IconArrowUpAndDown from "../Icons/IconArrowUpDown";
import { Dropdown } from "react-bootstrap";
import "./ListBankAccounts.css";

class ListBankAccounts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedAccount: null,
            defalutLabel: "Tous les comptes",
        }
        this.props.selectAccount(null);

    }

    onClick = (item) => {
        this.props.selectAccount(item);
        this.setState({
            selectedAccount: item,
        });
        this.props.onClick(item);
    }



    constructAccountItem = (item) => {
        return (
            <span className="d-flex align-items-center">
                <span className="p-1 xsmall-vw-hidden bank-item-wrapped"> {item?.type}  </span>
                {item?.bank ? <> <span className="xsmall-vw-hidden">--</span> <span className="p-1 xsmall-vw-hidden bank-item-wrapped"> {item.bank?.bankName}  </span> <span className="xsmall-vw-hidden">--</span></> : <></>}
                <span className="p-1 bank-item-wrapped"> {item.accountNumber} </span>
            </span>
        )
    }

    renderData = () => {
        return (
            <Dropdown.Menu className="bank-drop-down-menu text-color-bank">
                {
                    this.state.selectedAccount ?
                        <Dropdown.Item className="text-color-bank" active={false} key={`account-default`} onClick={(e) => this.onClick()}>
                            {this.state.defalutLabel}
                        </Dropdown.Item> : null
                }
                {
                    this.props.data.map((item, index) => (
                        (item.id !== this.state.selectedAccount?.id) ?
                            <Dropdown.Item className="text-color-bank" active={false} key={`account-${index}`} onClick={(e) => this.onClick(item)}>
                                {this.constructAccountItem(item)}
                            </Dropdown.Item> : null
                    ))
                }
            </Dropdown.Menu>
        )
    }

    render = () => {
        if (this.props.data?.length > 0) {
            return (
                <Dropdown className={`bank-drop-down ${this.props.className ? this.props.className : ''}`}>
                    <Dropdown.Toggle className="bank-drop-down-btn text-color-bank" variant="" id="dropdown-payslip">
                        <IconAccountBalance />
                        <div className="d-flex align-items-center justify-content-between flex-1-auto ml-1">
                            <span className="bank-nane">
                                {this.state.selectedAccount ? this.constructAccountItem(this.state.selectedAccount) : this.state.defalutLabel}
                            </span>
                            <span className="p-2 bank-arrow"> <IconArrowUpAndDown /> </span>
                        </div>
                    </Dropdown.Toggle>
                    {
                        this.renderData()
                    }
                </Dropdown>
            )
        }
        return (
            <> </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        selectedBankAccount: state.bank.selectedBankAccount,
        selectedAccount: state.accounts.selectedAccount,
    }
}

export default connect(mapStateToProps, { setSelectedBankAccount, selectAccount })(ListBankAccounts);