import React, {Component } from 'react'
import PropTypes from 'prop-types'

import './LabelStatus.css'

class LabelStatus extends Component {

    constructor (props) {
        super(props)

        this.state = {
        }
    }

    renderStatusColor = () => {
        switch(this.props.variant || "default"){
            case "success" : {
                return " success"
            }
            case "warning" : {
                return " warning"
            }
            case "danger" : {
                return " danger"
            }
            case "primary" : {
                return " primary"
            }
            default: {
                return " default"
            }
        }
    }

    render() {
        return(
            <span className={ 'label-status ' + this.renderStatusColor() }> {this.props.statement} </span>
        )
    }

}

LabelStatus.propTypes = {
    statement: PropTypes.string,
    variant: PropTypes.oneOf(['success', 'warning', 'danger', 'primary', 'default'])
}

export default LabelStatus