import React, {Component} from 'react'
import { Redirect } from 'react-router-dom'
import './CreateOrg.css'
import { connect } from 'react-redux';
import { _createOrganization } from '../../services/Organization';
import UserAuth from '../../helpers/auth';
import { setOrganizations, setDefaultOrganizationWithHabilitations } from '../../redux/action/OrganisationAction';
import IconNumbers from '../../components/Icons/IconNumbers'
import IconUploadCloud from '../../components/Icons/IconUploadCloud'
import FormCardInput from '../../components/FormCardInput'
import IconCommercial from '../../components/Icons/IconCommercial'
import IconFlag from '../../components/Icons/SampleFlagComponent'
import Countries from 'country-data';
import Loader from "../../components/Loader";
import Logger from "../../helpers/Logger";
import { toastr } from "react-redux-toastr";
import { errorNotifcation } from "../../helpers/errorHandle";

class CreateOrg extends Component {

    constructor(props){
        super(props);

        this.state = { 
            redirect: false,
            loading: false,
            name: undefined,
            country: undefined,
            emoji: null,
            numNiu: undefined,
            rcs: undefined,
            logo: undefined,
            selectedFile: null,
            countries: [],
            errors: {
                name: null,
                country: null,
                msg: null,
            }
        }
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    fileChangedHandler = (event) => {
        const file = event.target.files[0]
        this.setState({ 
            selectedFile: file
        })
    }

    validateForm = () => {
        let { name, country } = this.state;
        let errors = {
            name: !name ? true : false,
            country: !country ? true : false,
        }
        this.setState({errors});
        return !(errors.name || errors.country);
    }

    handleSubmit = async () => {
        try {
            if (!this.validateForm()) return;
            let { name, country, numNiu, rcs, selectedFile } = this.state
            this.setState({
                loading: true
            })
        
            let formData = new FormData()
            formData.append('name', name);
            formData.append('country', country);
            formData.append('numNiu', numNiu);
            formData.append('rcs', rcs);
            const orgUser = {
                'firstName': UserAuth._getUser().given_name,
                'lastName': UserAuth._getUser().family_name,
                'email': UserAuth._getUser().email,
            };
            if(selectedFile) formData.append('logo', selectedFile);
            const createdOrg = (await _createOrganization(formData, orgUser)).data;
            this.props.setOrganizations([createdOrg])
            this.props.setDefaultOrganizationWithHabilitations(createdOrg.id);
            this.setState({
                loading: false,
                redirect: true,
                errors: {
                    name: null,
                    country: null,
                    msg: null,
                }
            })
            toastr.success("", `Organisation '${createdOrg.name?.toUpperCase()}' créée avec succès!`);
        } catch (error) {
            this.setState({
                loading: false
            });
            Logger.error("CreateOrganization", `Error occured during create org` ,error);
            errorNotifcation(error?.response?.status, "Erreur à la création de l'organisation, veuillez reessayer plus tard.");
        }
    }
    
    componentDidMount = () => {
        let countries = Countries.countries.all.map(country => {
            if(country.emoji) {
                return country
            }
            return undefined
        });
        this.setState({
            countries: countries.sort((a, b) => a.name.localeCompare(b.name))
        });
    }

    onChangeCountry = (e) => {
        const { countries } = this.state;
        const emojiItem = countries.find((elem => { return elem.name === e.target.value }));
        this.setState({
            country: e.target.value,
            emoji: emojiItem.emoji,
        });
    }

    rendreFlag = () => {
        const { emoji, errors } = this.state;
        if(emoji){
            return emoji;
        }
        return (<IconFlag color={ errors.country ? 'red' : '#177BD1'}/>);
    }

    rendreSubmitButton = () => {
        const { loading } = this.state;
        if(loading){
            return (
                <Loader ref="loader" enable style={{width: "25px", height: "25px", borderColor: "rgba(255,255,255,0.8)", borderRightColor: "rgba(255,255,255,0.2)"}} />
            );
        }
        return "Créer une enterprise";
    }

    render(){
        let { redirect, name, country, countries, numNiu, rcs, errors, selectedFile } = this.state;
        if(redirect){
            return (
                <Redirect to={"/fees"} />
            )
        }
        return(
            <div className="createOrg">
                <div className="co-wrapper">
                    <div className="co-form-wrapper">
                        <span className="co-form-title mt-3">AJOUTER UNE ENTREPRISE</span>
                        <div className="co-fields-wrapper container">
                            <div className="row mb-2">
                                <FormCardInput type="text" onChange={this.handleChange} value={name || ''} 
                                    name="name" prependIcon={<IconCommercial color={ errors.name ? 'red' : '#177BD1'}/>} 
                                    onBlur={null} placeholder="Nom de votre entreprise" required
                                    error={errors.name}/>
                            </div>
                            <div className="row mb-2">
                                <FormCardInput type="select" name="country"
                                    options={countries}
                                    selectedValue='Cameroon'
                                    optionValue='name' required
                                    placeholder='Pays' title='Pays' 
                                    prependIcon={this.rendreFlag()} 
                                    onChange={this.onChangeCountry} value={country}
                                    onFocus={() => {}} onBlur={() => {}}
                                    error={errors.country}/>
                            </div>
                            <div className="row mb-2">
                                <FormCardInput type="text" onChange={this.handleChange} value={numNiu || ''} name="numNiu" prependIcon={<IconNumbers color="#177BD1"/>} onBlur={null} placeholder="Numero de contribuable" />
                            </div>
                            <div className="row mb-2">
                                <FormCardInput type="text" onChange={this.handleChange} value={rcs || ''} 
                                    name="rcs" prependIcon={<IconNumbers color={'#177BD1'}/>} 
                                    onBlur={null} placeholder="Registre de commerce"/>
                            </div>
                            <div className="row mb-2">
                                <FormCardInput type="file" accept=".png, .jpeg, .jpg" onChange={this.fileChangedHandler} value={selectedFile || null} name="logo" prependIcon={<IconUploadCloud color="#177BD1"/>} onBlur={null} placeholder="Logo de votre entreprise" />
                            </div>
                            <div className="co-note mb-2">
                                <i>(<b>*</b>) Champs obligatoires</i>
                                {errors.msg && <i style={{color:'red', fontSize: '12px'}}> {errors.msg}</i>}
                                </div>
                            
                            <div className="row mt-10 pl-2 pr-2 mb-4">
                                <button className="co-form-button" type="submit" onClick={this.handleSubmit}>
                                    { this.rendreSubmitButton() }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { auth, organizations } = state
    return {
        authToken: auth.authToken,
        organizations: organizations.organizations,
    }
}

export default connect(mapStateToProps, { setOrganizations, setDefaultOrganizationWithHabilitations })(CreateOrg)