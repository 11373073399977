import axios from "axios";

const NEW_RELIC_URL = "https://log-api.newrelic.com/log/v1";
const NEWRELIC_KEY = process.env.REACT_APP_NEWRELIC_KEY;

export default class Logger {
  static platform = `DIZEN CUSTOMER PORTAL ${(process.env.REACT_APP_ENV)?.toString().toUpperCase()}`;
  static _loggerPrefix = `[${this.platform}]`;

  static SEVERITY = {
    EMERGENCY: "EMERGENCY",
    ALERT: "ALERT",
    CRITICAL: "CRITICAL",
    NOTICE: "NOTICE",
    INFORMATIONAL: "INFO",
    WARNING: "WARNING",
    ERROR: "ERROR",
  };

  static _postLogToNewRelic(tag, severity_level, message, error) {
    error = error ? JSON.stringify(error).replace('"', '\\"') : "Unknow Error";
    message = this._loggerPrefix + message;

    let logBody = {
      message,
      service: "Dizen",
      environment: process.env.REACT_APP_ENV,
      level: severity_level?.toLowerCase(),
      attributes: {
        platform: this.platform,
        tag,
        severity_level,
        error
      },
    };

    let headers = {
        "X-License-Key": NEWRELIC_KEY,
        "Content-Type": "application/json",
      };

    axios.post(NEW_RELIC_URL, logBody, {headers})
    .then((response) => console.log(response))
    .catch((error) => console.log(error));
  }

  static info = (tag, message) => {
    this.log(tag, this.SEVERITY.INFORMATIONAL, message);
  };

  
  static debug = (tag, message) => {
    this.log(tag, this.SEVERITY.NOTICE, message);
  };

  static warning = (tag, message) => {
    this.log(tag, this.SEVERITY.WARNING, message);
  };

  static error = (tag, message, error) => {
    this.log(tag, this.SEVERITY.ERROR, message, error);
  };

  static log = (tag, severityLevel, message, error) => {
    this._postLogToNewRelic(tag, severityLevel, message, error);
  };
}
