import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Loader from '../../components/Loader';
import Logger from '../../helpers/Logger';
import { ORGANIZATION_USER_STATUS, SESSION_STORAGE_KEYS } from '../../constants/constants';
import { _validateOrganizationUserInvitation } from '../../services/Organization';
import { toastr } from 'react-redux-toastr';
import { errorNotifcation } from "../../helpers/errorHandle";

class UserInvitation extends Component {

    constructor(props){
        super(props);
        this.state = {
            redirect: ""
        }
    }

    componentDidMount(){
        this.validateUserInvitation();
    }

    validateUserInvitation = async () => {
        let redirect = "";
        try {
            const token = this.props.match.params.token;
            if(!!token){
                const orgUser = (await _validateOrganizationUserInvitation(token)).data;
                if(orgUser.status === ORGANIZATION_USER_STATUS.ACTIVE){
                    toastr.success("", "Invitation acceptée. Connectez-vous pour accéder à l'organization.");
                } else {
                    toastr.warning("Invitation", "Veuillez creer un compte pour accéder à l'organization.");
                    redirect = "register";
                    sessionStorage.setItem(SESSION_STORAGE_KEYS.INVITATION_USER, token);
                }
            }
        } catch (error) {
            Logger.error("Error during get roles", error);
            errorNotifcation(error?.response?.status, "Erreur lors de la validation de l'invitation, veuillez réessayer ultérieurement", "Invitation");
        } finally {
            this.setState({redirect: "/" + redirect});
        }
    }

    render(){
        const {redirect} = this.state;
        if(redirect) return <Redirect to={redirect} />
        else return (
            <div className="registerAcc d-flex align-items-center justify-content-center">
                <Loader style={{width: '70px', height: '70px', borderColor: "rgba(255, 255, 255, 0.1)", borderTopColor: "rgba(255, 255, 255, 0.7)"}}  loadData={true} />
            </div>
        );
    }
}

export default UserInvitation;