import React, {Component} from 'react'
import { Redirect } from 'react-router-dom'
import './forgotPassword.css'
import { _sendVerificationEmail, _confirmPassword } from '../../services/Auth';
import FormCardInput from '../../components/FormCardInput'
import IconEmail from '../../components/Icons/IconEmail';
import IconUser from '../../components/Icons/IconUser';
import IconLock from '../../components/Icons/IconLock';
import Loader from '../../components/Loader';
import Logger from '../../helpers/Logger';
import { ATUH_TAG } from '../../constants/AuthConstants';
import {toastr} from 'react-redux-toastr';
import SimpleReactValidator from "simple-react-validator";
import IconBack from '../../components/Icons/BackIcon';
import { maskEmail } from '../../helpers/http.helper';
import { errorNotifcation } from "../../helpers/errorHandle";

class ForgotPassword extends Component {
    regexPassword = "";
    constructor(props){
        super(props);

        this.state = { 
            redirect: false,
            title: "Vérification de l'email",
            buttonTitle: "Vérifier Email",
            step: ATUH_TAG.forgot,
            email: "",
            code: "",
            password: "",
            passwordConfirm: "",
            loading: false,
            isFormValid: true,
            errorMsg: "",
        }

        this.validator = new SimpleReactValidator({autoForceUpdate: this});
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    sendVerificationEmail = async () => {
        try {
            this.setState({loading: true, isFormValid: false});
            if(this.state.email) {
                const forgot = (await _sendVerificationEmail(this.state.email)).data;
                if(forgot) {
                    this.validator.purgeFields();
                    this.setState({step: ATUH_TAG.confirm, title: "Réinitialisation du mot de passe", buttonTitle: "Réinitialiser"})
                    toastr.success("", "Un code de vérification vous a été envoyé par mail");
                }
            }
        } catch (error) {
            const errorMsg = {...error}; 
            Logger.error("Error during email verifiration for reset password", errorMsg);
            errorNotifcation(error?.response?.status, errorMsg?.response?.data || "Erreur durant de la vérification de votre email", "Password");
        } finally{
            this.setState({loading: false});
        }
    }

    confirmPassword = async () => {
        try {
            if(this.isPasswordValid()){
                this.setState({loading: true, isFormValid: false});
                if(this.state.code && this.state.password) {
                    const confirm = (await _confirmPassword(this.state.email, this.state.code, this.state.password)).data;
                    if(confirm) {
                        toastr.success("", "Mot de passe réinitialisé avec succès!");
                        this.setState({redirect: true})
                    }
                }
            } else {
                this.setState({errorMsg: "Les mot de passe ne sont pas identiques"});
                this.validator.showMessageFor("passwordConfirm");
            }
        } catch (error) {
            Logger.error("Error during email verifiration for reset password", error);
            errorNotifcation(error?.response?.status,  error?.response?.data || "Erreur durant de la vérification de votre email", "Error");
        } finally{
            this.setState({loading: false});
        }
    }

    isPasswordValid = () => {
        return this.state.password && this.state.passwordConfirm && (this.state.password === this.state.passwordConfirm);
    }

    onSubmitForgotPassword = () => {
        this.setState({errorMsg: null});
        if (this.validator.allValid()) {
            switch (this.state.step) {
                case ATUH_TAG.forgot:
                    return this.sendVerificationEmail();
                case ATUH_TAG.confirm:
                    return this.confirmPassword();
                default:
                    break;
            }
        } else {
            this.setState({isFormValid: false});
            this.validator.showMessages();
        }
    }

    errorMsg = (error) => {
        return this.state.isFormValid ? "" : error;
    }

    renderForgotPasswordVerification = () => {
        return(
            <div className="row mb-2">
                <FormCardInput type="email" onChange={this.handleChange} value={this.state.email} 
                    name="email" prependIcon={<IconEmail color={ this.state.errors?.email ? 'red' : '#177BD1'}/>}
                    onBlur={null} placeholder="Email"
                    error={{ msg: this.errorMsg(this.validator.message('email', this.state.email, 'required|email')), placement: "left"}}/>
            </div>
        )
    }

    renderForgotPasswordConfirm = () => {
        return( 
            <>
                <div className="row mb-2">
                    <FormCardInput type="text" onChange={this.handleChange} value={this.state.code} 
                        name="code" prependIcon={<IconUser color={ this.state.errors?.code ? 'red' : '#177BD1'}/>}
                        onBlur={null} placeholder="Code"
                        error={{ msg: this.errorMsg(this.validator.message('code', this.state.code, 'required|string')), placement: "left"}} />
                </div>
                <div className="row mb-2">
                    <FormCardInput type="password" onChange={this.handleChange} value={this.state.password || ''} 
                        name="password" prependIcon={<IconLock color={ this.state.errors?.password ? 'red' : '#177BD1'}/>} 
                        onBlur={null} placeholder="Mot de passe" 
                        error={{ msg: this.errorMsg(this.validator.message('password', this.state.password, `required|string|min:6|regex:${this.regexPassword}`)), placement: "left"}}/>
                </div>
                <div className="row mb-4">
                    <FormCardInput type="password" onChange={this.handleChange} value={this.state.passwordConfirm || ''} 
                        name="passwordConfirm" prependIcon={<IconLock color={ this.state.errors?.passwordConfirm ? 'red' : '#177BD1'}/>} 
                        onBlur={null} placeholder="Confirmer le mot de passe" 
                        error={{ msg: this.state.errorMsg || this.errorMsg(this.validator.message('passwordConfirm', this.state.passwordConfirm, `required|string|min:6|regex:${this.regexPassword}`)), placement: "left"}}/>
                </div>
            </>
        )
    }

    renderComponent = () => {
        switch (this.state.step) {
            case ATUH_TAG.forgot:
                return this.renderForgotPasswordVerification();
                case ATUH_TAG.confirm:
                    return this.renderForgotPasswordConfirm();
            default:
                return (<></>);
        }
    }

    render(){
        if(this.state.redirect){
            return (
                <Redirect to={"/"} />
            )
        }
        return (
            <div className="registerAcc">
                <div className="ra-wrapper">
                    <div className="ra-form-wrapper">
                        <div className="pwd-ra-form-title mt-3">
                            <div className='pwd-btn-back' onClick={() => this.setState({redirect: true})}>
                                    <IconBack/>
                            </div>
                            <span className="pwd-title-text ">
                                {this.state.title}
                            </span>
                        </div>
                        {this.state.step === ATUH_TAG.confirm ? (<div className="pwd-title-email">
                            ({maskEmail(this.state.email)})
                            </div> ): <> </>
                        }
                        <div className="ra-fields-wrapper container">
                            {this.renderComponent()}
                            <div className="row mt-10 pl-2 pr-2 mb-4">
                            
                                <button className="ra-form-button" type="submit" style={{paddingTop: "5px"}} disabled={this.state.loading} onClick={this.onSubmitForgotPassword}>
                                {this.state.loading ? (<div className="d-flex">
                                <Loader loadData={this.state.loading} style={{width: '30px', height: '30px', marginRight: "5px"}} /> En cours...
                                </div>): this.state.buttonTitle}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default ForgotPassword;