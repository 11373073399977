import React, {Component} from "react";
import PropTypes from 'prop-types';
import IconClose from '../Icons/IconClose';
import Loader from '../Loader/Loader';
import TruncateString from '../TruncateString';

import "./DisplayContainer.css";

class DisplayContainer extends Component {

    constructor(props){
        super(props);

        this.state = {
            loading: this.props.loading,
        };
    }
    setLoading = (loading) => {
        this.setState({
            loading,
        })
    }
    componentDidMount() {
        this.props.onRef && this.props.onRef(this);
    }
    componentWillUnmount() {
        this.props.onRef && this.props.onRef(undefined);
    }

    renderHeader = () => {
        if(this.props.customHeader){
            return(
                <div className="headerDisplayContainer">
                    <div className="customHeaderContent">
                        <TruncateString className="bold" text={ this.props.customHeader } maxWidth={277} />
                    </div>
                    <button className='btn-close' onClick={ this.props.onClose } type="button">
                        { this.state.loading ?
                            <Loader className='loader-create-staff' style={{width: '30px', height: '30px'}} loadData={this.state.loading} />
                            : <IconClose />
                        }
                    </button>
                </div>
            )
        }
        return(
            <div className="headerDisplayContainer">
                <div className="titleStatement">
                    <TruncateString text={this.props.headerTile} maxWidth={277} />
                </div>
                <button className='btn-close' onClick={ this.props.onClose } type="button">
                { this.props.loading ?
                    <Loader className='loader-create-staff' style={{width: '30px', height: '30px'}} loadData={this.props.loading} />
                    : <IconClose />
                }
                </button>
            </div>
        )
    }

    render(){

        return(
            <div className="displayContainer">
                { this.renderHeader() }
                <div className="contentDisplayContainer">
                    { this.props.children }
                </div>
                
            </div>
        )
    }

}

DisplayContainer.propTypes = {
    headerTile: PropTypes.string,
    customHeader: PropTypes.node,
    children: PropTypes.node,
    onClose: PropTypes.func
}

export default DisplayContainer;