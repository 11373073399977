import React from "react";

const EditIconComponent = props => ( 
  <svg width={`${props.size ? props.size : 512}`} height={`${props.size ? props.size : 512}`} viewBox='0 0 512 512'>
    <g>
      <g>
        <polygon 
          fill="none"
          stroke={`${props.color ? props.color : '#000000'}`}
          strokeWidth={`${props.strockWidth ? props.strockWidth : 44}`}
          strokeLinecap="round"
          strokeLinejoin="round"
          points='358.62 129.28 86.49 402.08 70 442 109.92 425.51 382.72 153.38 358.62 129.28' 
        />
      </g>
      <g>
        <path
          fill="none"
          stroke={`${props.color ? props.color : '#000000'}`}
          strokeWidth={`${props.strockWidth ? props.strockWidth : 44}`}
          strokeLinecap="round"
          strokeLinejoin="round" 
          d='M413.07,74.84,401.28,86.62l24.1,24.1,11.79-11.79a16.51,16.51,0,0,0,0-23.34l-.75-.75A16.51,16.51,0,0,0,413.07,74.84Z' 
        />
      </g>
    </g>
  </svg>
);

export default EditIconComponent;