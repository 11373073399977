import React from "react";

const SvgIconAccountUser = props => (
  <svg width={props.width ? props.width : "30.04"} height={props.height ? props.height : "24.75"} viewBox="0 0 30.042 24.755" {...props}>
    <g fill={props.color? props.color : "#b5b5b5"} className={`${props.className ? props.className : ''}`} style={{"--theme-color": props.activecolor}}>
      <path
        data-name="Trac\xE9 5"
        d="M21.707 19.046c-.8-.339-2.117-.356-2.7-.619-.4-.187-1.041-.39-1.249-.687a10.725 10.725 0 01-.208-2.418 3.934 3.934 0 00.711-1.188 15.411 15.411 0 00.345-2.333s.474.238.66-.882a8.621 8.621 0 00.38-2.189c-.086-.713-.416-.543-.416-.543a7.345 7.345 0 00.416-3.173C19.646 2.486 18.031 0 15.009 0s-4.644 2.494-4.644 5.014a7.459 7.459 0 00.409 3.173s-.337-.17-.416.543a8.027 8.027 0 00.38 2.189c.187 1.128.66.882.66.882a15.56 15.56 0 00.345 2.333 3.934 3.934 0 00.711 1.188 10.725 10.725 0 01-.208 2.418 3.445 3.445 0 01-1.249.679c-.574.263-1.888.3-2.7.636a5.943 5.943 0 00-3.287 5.7h19.973a5.935 5.935 0 00-3.28-5.709z"
      />
      <path
        data-name="Trac\xE9 6"
        d="M7.26 15.078a3.669 3.669 0 002.235-.837c-1.037-2.116-.475-4.583-.689-6.888S7.621 3.679 5.68 3.679h-.026c-1.873 0-2.884 1.369-3.1 3.674s.381 5.042-.682 6.888c.636.774 2.241.747 2.241.747a6.105 6.105 0 01-.067 1.513 1.546 1.546 0 01-.8.5 12.567 12.567 0 00-1.733.648A4.023 4.023 0 000 20.994h5.373a5.826 5.826 0 012.141-2.683 3.525 3.525 0 011.653-.468s.254-.54-.582-.747a4.805 4.805 0 01-1.285-.6 5.449 5.449 0 01-.04-1.418zm15.523 0a3.669 3.669 0 01-2.235-.837c1.037-2.116.475-4.583.689-6.888s1.184-3.674 3.125-3.674h.027c1.873 0 2.884 1.369 3.1 3.674s-.381 5.042.682 6.888c-.636.774-2.241.747-2.241.747a6.106 6.106 0 00.067 1.513 1.546 1.546 0 00.8.5 12.567 12.567 0 011.733.648 4.023 4.023 0 011.512 3.349h-5.375a5.826 5.826 0 00-2.141-2.683 3.525 3.525 0 00-1.653-.468s-.254-.54.582-.747a4.805 4.805 0 001.285-.6 5.449 5.449 0 00.04-1.414z"
      />
    </g>
  </svg>
);

export default SvgIconAccountUser;
