import React, {Component} from "react";
import { connect } from 'react-redux';
import InvoiceRowItem from "../InvoiceRowItem";
import Loader from "../Loader/Loader"

import "./ListInvoices";

class ListInvoices extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedInvoice: null,
        }
    }

    onHandleAttachment = (selectedInvoice) => {
        this.setState(() => ({
            selectedInvoice,
        }));
        this.props.onHandleAttachment(selectedInvoice.attachment, null, selectedInvoice.contentType);
    }

    onHandleDetail = (selectedInvoice) => {
        this.setState(() => ({
            selectedInvoice,
        }));
        this.props.onHandleDetail(selectedInvoice);
    }

    onClick = (selectedInvoice) => {
        if (this.props.onClick) this.props.onClick(selectedInvoice);
    }

    unreconcile = (invoiceId) => {
        if (this.props.unreconcile) this.props.unreconcile(invoiceId);
    }

    render = () => {
        const { data, loading, selectedInvoice } = this.props;
        if (loading) {
            return(
                <div className={'d-flex justify-content-center'}>
                    <Loader className='loader-create-staff' style={{width: '30px', height: '30px'}}  loadData={loading} />
                </div>
            )
        }
        else {
            return(
                <div className="card">
                    {
                        (data && data.length > 0) ?
                            data.map((item, index) => {
                                return <InvoiceRowItem
                                            activeItem={(selectedInvoice && (selectedInvoice.id === item.id))}
                                            data={item}
                                            key={index} 
                                            onHandleAttachment={this.onHandleAttachment} 
                                            onClick={this.onClick}
                                            unreconcile={this.unreconcile}
                                            active={item.bankRecordId}
                                            detail={this.props.detail}
                                            handleDetail={this.onHandleDetail}
                                        />
                            })
                        :
                        <span className='text-header-bank'> <center> Aucune facture ne repond à cette recherche </center> </span>
                    }
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    const { organizations } = state;
    return {
        selectedInvoice: organizations.selectedInvoice,
    }
}

export default connect(mapStateToProps, {})(ListInvoices)