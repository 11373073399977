import React from "react";

const IconEmail = props => (
  <svg width={ props.width ? props.width : 18.366 } height={ props.height ? props.height : 12.717} viewBox="0 0 18.366 12.717" >
    <defs>
      {
        <style>{`.mail{fill:${props.color ? props.color : '#b5b5b5;'}}`}</style>
      }
    </defs>
    <g transform="translate(0 0)">
      <path
        className="mail"
        d="M66.216,146.712l-4.75,4.839a.085.085,0,0,0,0,.124l3.324,3.541a.573.573,0,0,1,0,.812.576.576,0,0,1-.812,0L60.666,152.5a.091.091,0,0,0-.128,0l-.808.821a3.554,3.554,0,0,1-2.534,1.069,3.626,3.626,0,0,1-2.587-1.1l-.777-.79a.091.091,0,0,0-.128,0l-3.311,3.528a.576.576,0,0,1-.812,0,.573.573,0,0,1,0-.812l3.324-3.541a.094.094,0,0,0,0-.124l-4.755-4.839a.087.087,0,0,0-.15.062v9.683a1.417,1.417,0,0,0,1.413,1.413H64.953a1.417,1.417,0,0,0,1.413-1.413v-9.683A.088.088,0,0,0,66.216,146.712Z"
        transform="translate(-48 -145.153)"
      />
      <path
        className="mail"
        d="M68.749,120.085a2.4,2.4,0,0,0,1.726-.724l6.927-7.052a1.387,1.387,0,0,0-.874-.309H60.974a1.378,1.378,0,0,0-.874.309l6.927,7.052A2.4,2.4,0,0,0,68.749,120.085Z"
        transform="translate(-59.566 -112)"
      />
    </g>
  </svg>
);

export default IconEmail;
