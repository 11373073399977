/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const PathComponent = (props) => (
  <svg  width={props.width ? props.width : 14.649} height={props.height ? props.height : 14.649} viewBox="0 0 14.649 14.649">
    <defs>
      <style>{".saveIcon{transform: scale(0.8)}"}</style>
    </defs>
    <path
      fill={props.color ? props.color : '#707070'}
      d="M78.111,66.522l-1.98-1.98a1.846,1.846,0,0,0-1.3-.542H73.762a.616.616,0,0,0-.61.618V67.67a.612.612,0,0,1-.61.61H67.662a.612.612,0,0,1-.61-.61V64.618a.619.619,0,0,0-.61-.618H65.221A1.224,1.224,0,0,0,64,65.221V77.429a1.224,1.224,0,0,0,1.221,1.221H77.429a1.224,1.224,0,0,0,1.221-1.221V67.815A1.831,1.831,0,0,0,78.111,66.522Zm-4.269,10.3a.649.649,0,0,1-.679.61H67.044a.649.649,0,0,1-.679-.61V73.16a.649.649,0,0,1,.679-.61h6.119a.649.649,0,0,1,.679.61Z"
      transform="translate(-64 -64)"
    />
  </svg>
);

export default PathComponent;
