import { SET_BANK_STATEMENTS, ADD_MULTIPLE_BANK_STATEMENTS, SET_BANK_ACCOUNT, SET_SELECTED_BANK_STATEMENT, SET_SELECTED_BANK_RECORD, SET_SELECTED_STATEMENT_RECORD, SET_LOAD_IMPORT_BANK_STATEMENT, SET_SELECTED_BANK_ACCOUNT } from '../action-type/bankType'

const INITIAL_STATE = {
    bankStatements: [],
    bankAccounts: [],
    selectedBankStatement: {},
    selectedBankRecord: {},
    selectedStatementRecord: {},
    loaderImportBankStatement: false,
    selectedBankAccount: {},
}

const BankReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_BANK_STATEMENTS: {
            return { ...state, bankStatements: action.payload }
        }
        case ADD_MULTIPLE_BANK_STATEMENTS: {
            const data = state.bankStatements.concat(action.payload);
            return { ...state, bankStatements: data }
        }
        case SET_BANK_ACCOUNT: {
            return { ...state, bankAccounts: action.payload }
        }
        case SET_SELECTED_BANK_STATEMENT : {
            return { ...state, selectedBankStatement: action.payload }
        }
        case SET_SELECTED_BANK_RECORD : {
            return { ...state, selectedBankRecord: action.payload }
        }
        case SET_SELECTED_STATEMENT_RECORD : {
            return { ...state, selectedStatementRecord: action.payload }
        }
        case SET_LOAD_IMPORT_BANK_STATEMENT : {
            return { ...state, loaderImportBankStatement: action.payload }
        }
        case SET_SELECTED_BANK_ACCOUNT : {
            return { ...state, selectedBankAccount: action.payload }
        }
        default: return state;
    }
}

export default BankReducer