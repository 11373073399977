import React, { Component } from 'react'
import Input from '../Inputs/inputs'
import { Form } from 'react-bootstrap'
import IconNumber from '../Icons/NumberIcon'
import IconNumSecuIcon from '../Icons/NumSecuIcon'
import IconKey from '../Icons/KeyIcon'
import Validator from 'validator'

export default class ModalBank extends Component {
    constructor(props) {
        super(props)
        this.state = {
            focusedBankName: false,
            focusedBankCode: false,
            focusedGuichetCode: false,
            focusedCompteNum: false,
            focusedRibKey: false,
            valid: {
                name: true,
                code: true,
                guichet: true,
                compte: true,
                rib: true
            },
            errors: {
                compte: ''
            }
        }
    }
    focusBankName = (value) => {
        this.setState({
           focusedBankName : !value
        })
    }
    focusBankCode = (value) => {
        this.setState({
           focusedBankCode : !value
        })
    }
    focusGuichetCode = (value) => {
        this.setState({
            focusedGuichetCode: !value
        })
    }
    focusCompteNum = (value) => {
        this.setState({
           focusedCompteNum : !value
        })
    }
    focusRibKey = (value) => {
        this.setState({
           focusedRibKey : !value
        })
    }

    onChangeBankName = (e) => {
        this.props.onChangeBankName(e)
        let valid = !Validator.isEmpty(e.target.value)
        this.setState({
            valid: {
                ...this.state.valid,
                name: valid
            }
        })
    }
    onChangeBankCode = (e) => {
        this.props.onChangeBankCode(e)
        let valid = !Validator.isEmpty(e.target.value)
        this.setState({
            valid: {
                ...this.state.valid,
                code: valid
            }
        })
    }
    onChangeGuichetCode = (e) => {
        this.props.onChangeGuichetCode(e)
        let valid = !Validator.isEmpty(e.target.value)
        this.setState({
            valid: {
                ...this.state.valid,
                guichet: valid
            }
        })
    }
    onChangeCompteNum = e => {
        this.props.onChangeCompteNum(e)
        let valid = !Validator.isEmpty(e.target.value)
        this.setState({
            valid: {
                ...this.state.valid,
                compte: valid
            }
        },() => {
            if(valid) {
                this.setState({
                    errors: {
                        ...this.state.errors,
                        compte: ''
                    }
                })
            }
        })
    }
    onChangeRibKey = e => {
        this.props.onChangeRibKey(e)
        let valid = !Validator.isEmpty(e.target.value)
        this.setState({
            valid: {
                ...this.state.valid,
                rib: valid
            }
        })
    }
    onValidate = () => {
        let { valid } = this.state
        if(!this.props.BankName){
            this.setState({
                valid: {
                    ...this.state.valid,
                    name: false
                }
            })
        } else if(!this.props.BankCode) {
            this.setState({
                valid: {
                    ...this.state.valid,
                    code: false
                }
            })
        } else if(!this.props.GuichetCode){
            this.setState({
                valid: {
                    ...this.state.valid,
                    guichet: false
                }
            })
        } else if(!this.props.CompteNum){
            this.setState({
                valid: {
                    ...this.state.valid,
                    compte: false
                }
            })
        } else if(!Validator.isCreditCard(this.props.CompteNum)){
            this.setState({
                valid: {
                    ...this.state.valid,
                    compte: false
                },
                errors: {
                    ...this.state.errors,
                    compte: 'Carte invalide'
                }
            })
        } else if(!this.props.RibKey){
            this.setState({
                valid: {
                    ...this.state.valid,
                    rib: false
                }
            })
        }
        if((this.props.BankName && valid.name) && (this.props.BankCode && valid.code) && (this.props.GuichetCode && valid.guichet)
            && (this.props.CompteNum && valid.compte) && (this.props.RibKey && valid.rib)) {
                this.props.onValidate()
        }
    }
    render() {
        return (
            <div className='px-3 mt-2'>
                <h6 className='modal-child-title'>Coordonnées bancaires</h6>
                    <Input name='bankName' required valid={this.state.valid.name} active={this.state.focusedBankName} type='text' className={this.state.focusedBankName ? 'input-focused': ''} placeholder="Nom de la banque" title="Nom de la banque" 
                        onChange={(e) => this.onChangeBankName(e)} value={this.props.BankName}
                        onFocus={() => this.focusBankName(this.state.focusedBankName)} onBlur={() => this.focusBankName(this.state.focusedBankName)}> 
                        
                    </Input>
                    <div className='d-flex'>
                        <div className="w-50">
                            <Input name='bankCode' required valid={this.state.valid.code} active={this.state.focusedBankCode} type='number' className={this.state.focusedBankCode ? 'input-focused' : ''} placeholder='Code de la banque' title='Code de la banque'
                                onChange={(e) => this.onChangeBankCode(e)} value={this.props.BankCode}
                                onFocus={() => this.focusBankCode(this.state.focusedBankCode)} onBlur={() => this.focusBankCode(this.state.focusedBankCode)}>
                                    <IconNumber borderClass='border1' numClass='num1' active={this.state.focusedBankCode} width={17} height={17} />
                            </Input>
                        </div>
                        <div className="w-50 ml-3">
                            <Input name='guichetCode' required valid={this.state.valid.guichet} active={this.state.focusedGuichetCode} type='number' className={this.state.focusedGuichetCode ? 'input-focused' : ''} placeholder='Code guichet' title='Code guichet'
                                onChange={(e) => this.onChangeGuichetCode(e)} value={this.props.GuichetCode}
                                onFocus={() => this.focusGuichetCode(this.state.focusedGuichetCode)} onBlur={() => this.focusGuichetCode(this.state.focusedGuichetCode)}>
                                    <IconNumber borderClass='border2' numClass='num2' active={this.state.focusedGuichetCode} width={17} height={17} />
                            </Input>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div className="w-50">
                            <Input name='compteNum' valid active={this.state.focusedCompteNum} type='number' required
                                className={this.state.focusedCompteNum ? 'input-focused' : ''} placeholder='Numéro de compte' title='Numéro de compte' 
                                onChange={(e) => this.onChangeCompteNum(e)} value={this.props.CompteNum}
                                onFocus={() => this.focusCompteNum(this.state.CompteNum)} onBlur={() => this.focusCompteNum(this.state.focusedCompteNum)}>
                                    <IconNumSecuIcon active={this.state.focusedCompteNum} width={17} height={17} />
                            </Input>
                        </div>
                        <div className="w-50 ml-3">
                            <Input name='ribKey' required valid={this.state.valid.rib} active={this.state.focusedRibKey} type='number' className={this.state.focusedRibKey ? 'input-focused' : ''} placeholder='Clé RIB' title='Clé RIB' 
                                onChange={(e) => this.onChangeRibKey(e)} value={this.props.RibKey}
                                onFocus={() => this.focusRibKey(this.state.RibKey)} onBlur={() => this.focusRibKey(this.state.focusedRibKey)}>
                                    <IconKey active={this.state.focusedRibKey} width={17} height={17} />
                            </Input>
                        </div>
                    </div>
                    <Form.Check type='checkbox'>
                        <Form.Check.Input onChange={this.props.onChangeActive} value={this.props.active} type='checkbox' checked />
                        <Form.Check.Label className={this.props.active ? 'active-label' : null}>Activer</Form.Check.Label>
                    </Form.Check>
                    <div className='d-flex modal-child-btn'>
                        <div className='w-50'>
                            <button className='btn btn-annuler w-100' onClick={this.props.onCancel}>Annuler</button>
                        </div>
                        <div className='w-50 ml-3'>
                            <button className='btn btn-validate w-100' onClick={this.onValidate}>Valider</button>
                        </div>
                </div>
            </div>
        )
    }
}