/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const IconAccountBalance = (props) => (
  <svg width={props.width || 13.546} height={props.height || 14.863} viewBox="0 0 13.546 14.863">
    <defs>
      <style>{".icon-account-balance{fill:#707070;}"}</style>
    </defs>
    <path
      className="icon-account-balance"
      d="M5.208,7.688H3.782v5.2H5.208Zm4.278,0H8.06v5.2H9.486Zm6.06,6.688H2v1.486H15.546ZM13.764,7.688H12.338v5.2h1.426ZM8.773,2.68l3.714,2.036H5.059L8.773,2.68m0-1.68L2,4.716V6.2H15.546V4.716Z"
      transform="translate(-2 -1)"
    />
  </svg>
);

export default IconAccountBalance;
