import React from "react";

const FilePdfDownloadPdf = (props) => (
  <svg viewBox="0 0 14.425 15.943" width={props.size ? props.size : 15} height={props.size ? props.size : 15}>
    <defs></defs>
    <g transform="translate(0 0)">
      <g transform="translate(2.366 4.935)">
        <path
          fill={props.color ? props.color : '#999999'}
          d="M37.883,42.56a.4.4,0,0,0-.116-.2,1.617,1.617,0,0,0-1.044-.247,8.625,8.625,0,0,0-1.255.092,3.128,3.128,0,0,1-.582-.4,4.251,4.251,0,0,1-1.051-1.631c.015-.059.028-.111.04-.164A10.61,10.61,0,0,0,34.06,38.1a.555.555,0,0,0-.033-.137l-.022-.056a.453.453,0,0,0-.412-.316l-.124,0h0a.466.466,0,0,0-.476.3,5.534,5.534,0,0,0,.29,2.491l-.073.177c-.2.5-.459,1-.685,1.441l-.029.057c-.238.464-.453.859-.649,1.193l-.2.106c-.015.008-.361.191-.442.239a2.47,2.47,0,0,0-1.219,1.246.306.306,0,0,0,.116.339l.195.1a.6.6,0,0,0,.266.063c.49,0,1.058-.61,1.841-1.976a18.314,18.314,0,0,1,2.836-.673,4.892,4.892,0,0,0,2.066.655.956.956,0,0,0,.243-.027.422.422,0,0,0,.242-.164A.876.876,0,0,0,37.883,42.56Zm-7.43,2.405a3.4,3.4,0,0,1,.965-1.155c.033-.027.114-.1.187-.173A3.49,3.49,0,0,1,30.453,44.965Zm3.095-7.128c.157,0,.247.4.254.768a1.579,1.579,0,0,1-.187.826,4.011,4.011,0,0,1-.133-1.031S33.476,37.838,33.548,37.838Zm-.924,5.079q.165-.294.34-.623a11.659,11.659,0,0,0,.6-1.3,4.311,4.311,0,0,0,.986,1.224l.154.124A12.518,12.518,0,0,0,32.625,42.917Zm4.989-.044a.729.729,0,0,1-.275.047,3.223,3.223,0,0,1-1.139-.344c.191-.014.367-.022.524-.022a2.011,2.011,0,0,1,.654.071C37.659,42.7,37.663,42.842,37.614,42.873Z"
          transform="translate(-29.973 -37.585)"
        />
      </g>
      <path
        fill={props.color ? props.color : '#999999'}
        d="M76.373,73.336a3.037,3.037,0,1,0,3.037,3.037A3.037,3.037,0,0,0,76.373,73.336Zm1.377,2.15-.014.022-1.042,1.447a.38.38,0,0,1-.642,0L75,75.486a.38.38,0,0,1,.33-.568h.541V74.1a.38.38,0,0,1,.38-.38H76.5a.38.38,0,0,1,.38.38v.822h.541a.38.38,0,0,1,.33.568Z"
        transform="translate(-64.985 -63.466)"
      />
      <path
        fill={props.color ? props.color : '#999999'}
        d="M76.963,115.458l-.006.006h.006Z"
        transform="translate(-68.106 -99.775)"
      />
      <path
        fill={props.color ? props.color : '#999999'}
        d="M104.464,70.546V70.54l-.006.006Z"
        transform="translate(-91.811 -61.056)"
      />
      <path
        fill={props.color ? props.color : '#999999'}
        d="M25.116,7.179,20.142,2.2a1.257,1.257,0,0,0-.895-.371H14.1A1.267,1.267,0,0,0,12.834,3.1V16.258A1.267,1.267,0,0,0,14.1,17.523h7.586l-.506-.506H14.1a.76.76,0,0,1-.759-.759V3.1A.76.76,0,0,1,14.1,2.34h5.148a.754.754,0,0,1,.537.222l4.975,4.975a.765.765,0,0,1,.222.537v2.738l.506.506V8.073A1.257,1.257,0,0,0,25.116,7.179Z"
        transform="translate(-12.834 -1.833)"
      />
      <path
        fill={props.color ? props.color : '#999999'}
        d="M101.337,67.386l.006-.006-.506-.506"
        transform="translate(-88.69 -57.896)"
      />
    </g>
  </svg>
);

export default FilePdfDownloadPdf;
