import React from 'react';
import { Row, Col, Button, Modal} from "react-bootstrap";
import moment from 'moment';
import PropTypes from 'prop-types';
import EditableInput from "../../components/EditableInput";
import Tag from "../../components/Tag/Tag";
import IconCalendar from '../../assets/svg/icon-calendar.svg';
import LabelStatus from '../../components/LabelStatus';
import {_getRoles} from '../../services/Auth';
import {_sendInvitationsUsers, _checkIfOrganisationUsersExists, _deleteOrganizationUser} from '../../services/Organization';
import Logger from '../../helpers/Logger';
import {toastr} from 'react-redux-toastr';
import IconClose from '../../components/Icons/IconClose';
import CheckIcon from '../../components/Icons/CheckIcon';
import { mapStatusToVariantLabel, ORGANIZATION_USER_STATUS, TAG_TYPES } from '../../constants/constants';
import SimpleReactValidator from "simple-react-validator";
import UserAuth from '../../helpers/auth';
import Loader from '../../components/Loader/Loader';
import { errorNotifcation } from "../../helpers/errorHandle";

const propTypes = {
    invitations: PropTypes.arrayOf(
        PropTypes.shape({
            lastName: PropTypes.string,
            firstName: PropTypes.string,
            email: PropTypes.string.isRequired,
            role: PropTypes.string.isRequired,
            status: PropTypes.oneOf(['pending', 'active' ]),
        })
    ),
    onSubmit: PropTypes.func,
};

class InvitationForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lastName: '',
            firstName: '',
            email: '',
            role: '',
            status: 'pending',
            invitations: [],
            roles: [],
            selectedInvitation: null,
            updateStatus: false,
            showModal: false,
            editMode: false,
            isFormValid: false,
            loadingDelete: false,
            loadingAdd: false,
            loadingSent: false,
        };
        this.deleteUser = this.deleteUser.bind(this);
        this.selectInvitation = this.selectInvitation.bind(this);
        this.deleteInvitation = this.deleteInvitation.bind(this);

        this.validator = new SimpleReactValidator({autoForceUpdate: this});
    }

    componentDidMount(){
        this.getRoles();
    }

    getRoles = async () => {
        try {
            const roles = (await _getRoles()).data;
            this.setState({
                roles
            })
        } catch (error) {
            Logger.error("Error during get roles", error);
            errorNotifcation(error?.response?.status, "Erreur lors de la recuperation des roles, veuillez réessayer ultérieurement", "Get Roles");
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        }, () => {
            this.checkIsEditMode();
        });
    }

    checkIsEditMode = () => {
        if(this.state.firstName || this.state.lastName || this.state.email){
            this.setState({
                editMode: true
            })
        } else {
            this.setState({
                editMode: false
            })
        }
    }

    addOrUpdateInvitation = async () => {
        if (this.validator.allValid()) {
            this.setState({loadingAdd: true});
            if(this.state.selectedInvitation){
                await this.updateInvitation();
            } else {
                await this.addInvitation();
            }
            this.setState({loadingAdd: false});
        } else {
            this.setState({isFormValid: false});
            this.validator.showMessages();
        }
    }

    updateInvitation = async () => {
        this.setState({isFormValid: false});
        if (!(await this.isUserAlreadyExists(this.state.email))) {
            const item = this.constructInvitation();
            if(item){
                const invitations = this.state.invitations;
                const index = invitations.findIndex(x => x.email === this.state.selectedInvitation.email);
                if(index > -1) {
                    invitations[index] = item;
                    this.setState({
                        invitations: invitations,
                    });
                    this.onClear();
                }
            }
        } else {
            toastr.error("", `L'utilisateur avec l'email ${this.state.email} a déja été ajouté.`);
        }
    }

    addInvitation = async () => {
        this.setState({isFormValid: false});
        if (!((await this.isUserAlreadyExists(this.state.email)) || this.isUserAlreadyAdded(this.state.email))) {
            const item = this.constructInvitation();
            if(item){
                this.setState({
                    invitations: [ ...this.state.invitations, item ],
                });
                this.onClear();
            }
        } else {
            toastr.error("", `L'utilisateur avec l'email ${this.state.email} a déja été ajouté.`);
        }
    }

    constructInvitation(){
        const { lastName, firstName, email } = this.state;
        const role = this.state.roles?.find(x => x.rolename === this.state.role);
        if(role){
            return { lastName, firstName, email, role, roleId: role?.id };
        } else {
            return null;
        }
    }

    isUserAlreadyAdded = (email) => {
        return this.state.invitations?.findIndex(x => x.email === email) > -1;
    }

    isUserAlreadyExists = async (email) => {
        const orgId = JSON.parse(sessionStorage.getItem('defaultOrg'))?.id;
        const existInBd = (await _checkIfOrganisationUsersExists(orgId, email)).data;
        return existInBd;
    }

    onClear = () => {
        this.setState({
            lastName: "",
            firstName: "",
            email: "",
            role: "Rôle",
            updateStatus: false,
            selectedInvitation: null,
            editMode: false,
            isFormValid: true,
            loadingAdd: false
        });
        this.validator.purgeFields();
    }

    onClearAll = () => {
        this.onClear();
        this.setState({
            invitations: []
        });
        this.loadingSent(false);
    }

    deleteInvitation(invitation){
        const index = this.state.invitations?.findIndex(x => x.email === invitation.email);
        if(index > -1){
            let invitations = this.state.invitations;
            invitations.splice(index, 1);
            this.setState({
                invitations
            });
        }
        this.onClear();
    }

    selectInvitation = (value) => {
        if(value){
            this.setState({
                ...value,
                role: value.role.rolename,
                selectedInvitation: value,
                updateStatus: true,
                editMode: true,
                isFormValid: false
            });
        }
    }

    loadingSent = (loading) => {
        if (this.props.loadingSent) this.props.loadingSent(loading);
        this.setState({
            loadingSent: loading
        });
    }

    createInvitations = async (e) => {
        try {
            if(this.state.invitations?.length > 0) {
                this.loadingSent(true);
                const orgId = JSON.parse(sessionStorage.getItem('defaultOrg'))?.id;
                await _sendInvitationsUsers(orgId, this.state.invitations)
                this.onClearAll();
                toastr.success("", "Envoie des invitations aux utilisateurs avec succès!");
                if(this.props.refreshUsers)
                    this.props.refreshUsers();
            }
        } catch (error) {
            Logger.error("Error during get roles", error);
            errorNotifcation(error?.response?.status, "Erreur lors de l'envoie des invitations aux utilisateurs");
        } finally{
            this.loadingSent(false);
        }
    }

    deleteUser = () => {
        this.setState({
            showModal: true
        })
    }

    onDeleteUser = async (e) => {
        try {
            this.setState({loadingDelete: true});
            const orgId = JSON.parse(sessionStorage.getItem('defaultOrg'))?.id;
            await _deleteOrganizationUser(orgId, this.props.user.id );
            toastr.success("", `Utilisateur supprimé  avec succès!`);
            this.onCancelDeleteUser();
            if(this.props.refreshUsers)
                this.props.refreshUsers();
        } catch (error) {
            Logger.error("Error during delete user", error);
            errorNotifcation(error?.response?.status, "Erreur lors de la suppresion de l'utilisateur. Veuillez réessayer plutard!");
        } finally{
            this.setState({loadingDelete: false});
        }
    }

    onCancelDeleteUser = () => {
        this.setState({
            showModal: false
        })
    }

    constructInvitationTitle = (invitation) => {
        return (
            <div className="d-flex align-items-center position-relative">
                <div className="tag-ellipsis flex-1-auto">
                {invitation.firstName} {invitation.lastName} ({invitation.email})
                </div>
                <div className="mr-1 bold"> - {invitation.rolename}</div>
            </div>
        )
    }

    renderInvitations = () => {
        return(
            this.state.invitations.map((item, index) => {
                return <Tag key={index} type={TAG_TYPES.ROW} title={this.constructInvitationTitle(item)} data={item} active onTagClick={this.selectInvitation} onRemove={this.deleteInvitation} editable slice />
            })
        )
    }

    render() {
        this.validator.purgeFields();
        return ( 
            <React.Fragment>
                {this.renderPreview()} 
                {this.renderInvitation()}
                {this.renderModal()}
            </React.Fragment>
        );
    }

    errorMsg = (error) => {
        return this.state.isFormValid ? "" : error;
    }

    renderInvitation() {
        return (
            !this.props.isPreview  ? 
            <div className="invitation-form">
                <div className="h-separator"></div>
                <Row className="mt-2 pl-2 pr-2">
                    <Col md={6} sm={12}>
                        <EditableInput type="email" name="email" value={this.state.email} onChange={this.handleChange} placeholder={'Email'} 
                        error={{ msg: this.errorMsg(this.validator.message('email', this.state.email, 'required|email'))}} isProps />
                    </Col>
                    <Col md={6} sm={12}><EditableInput type="select" name="role" value={this.state.role} selectedValue={this.state.role} optionValue="rolename" options={this.state.roles} onChange={this.handleChange} placeholder={'Rôle'}
                     error={{ msg: this.errorMsg(this.validator.message('Rôle', this.state.role, 'required|alpha'))}} isProps/></Col>
                </Row>  
                <div className="mt-2 pl-2 pr-2">
                    <div className={'inputarea-actions-wrapper'}>
                    { this.state.loadingAdd ?
                        <Loader className='loader-add-invitation' style={{width: '30px', height: '30px'}} loadData={true} />
                        : 
                        <>
                            <div className="inputarea-action" onClick={this.onClear}>
                                <IconClose color="#707070"/>
                            </div>
                            <div className={`inputarea-action ml-2 ${this.state.editMode ?  "btn-color-blue" : ""}`} onClick={this.addOrUpdateInvitation}>
                                <CheckIcon color={this.state.editMode ? "#FFFFFF" : "#707070"}/>
                            </div>
                        </>
                    }
                    </div>
                </div>
                <div className="tag-area m-2">
                    { this.renderInvitations() }
                </div>
                <Row className="mt-2 pl-2 pr-2">
                    <Col md={6} sm={12}><Button className="btn btn-cancel" onClick={this.onClearAll} disabled={this.state.loadingSent}>Annuler</Button></Col>
                    <Col md={6} sm={12}><Button className="btn btn-submit" onClick={this.createInvitations} disabled={this.state.loadingSent}>Envoyer les invitations</Button></Col>
                </Row> 
            </div> :
            <div></div>
        );
    }

    renderPreview() {
        return (
            this.props.isPreview && this.props.user ? <div className="invitation-form">
                <div className="h-separator"></div>
                <div className="item-row">
                    <span className="text-modal-bank">Invité le:  </span>
                    <span className="text-modal-bank">
                        <span className='pr-2'> <img src={IconCalendar} alt='Date icon' width='15px' height='15px' /> </span>
                        { moment(new Date(this.props.user.createdAt || new Date())).format('DD MMMM YYYY') }
                    </span>
                </div>
                <div className="card-item-detail m-2">
                    <div className="item-row">
                        <span className="text-modal-bank bold"> Nom: </span>
                        <span className="bold text-modal-bank bold"> { this.props.user.user?.lastName } </span>
                    </div>
                    <div className="item-row">
                        <span className="text-modal-bank bold"> Prénom: </span>
                        <span className="bold text-modal-bank">
                            <span className="bold text-modal-bank"> { this.props.user.user?.firstName } </span>
                        </span>
                    </div> 
                    <div className="item-row">
                        <span className="text-modal-bank bold"> Email: </span>
                        <span className="bold text-modal-bank">
                            <span className="bold text-modal-bank email-text"> { this.props.user.email || "" } </span>
                        </span>
                    </div>
                    <div className="item-row">
                        <span className="text-modal-bank bold"> Role: </span>
                        <span className="bold text-modal-bank"> { this.props.user.role?.rolename || ""} </span>
                    </div>
                    <div className="item-row">
                        <span className="text-modal-bank bold"> Status: </span>
                        <span className="bold text-modal-bank"> <LabelStatus statement={this.props.user.status || ORGANIZATION_USER_STATUS.ACTIVE} variant={mapStatusToVariantLabel(this.props.user.status)} /></span>
                    </div>
                </div>
                <Col md={8} sm={12} style={{paddingLeft: '8px', paddingTop: '0px'}}>
                    {UserAuth._getUser().email !== (this.props.user?.email || this.props.user?.user?.email) ?
                        <Button style={{margin: '7px 0 0 7px', backgroundColor: '#707070', borderColor: '#707070'}} onClick={this.deleteUser} >{this.props.user.status === ORGANIZATION_USER_STATUS.ACTIVE ? "Supprimer l'utilisateur" : "Annuler l'invitation" }</Button>
                        : <></>
                    }
                </Col>
                
            </div> : null
        );
    }

    renderModal(){
       return( 
            <Modal show={this.state.showModal} onHide={() => this.setState({showModal: !this.state.showModal})}>

                <Modal.Header closeButton>
                    <Modal.Title>Suppresion d'un utilisateur</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Etes vous sûr de vouloir {this.props.user?.status === ORGANIZATION_USER_STATUS.ACTIVE ? "supprimer " : "annuler l'invitation de " }
                    l'utilisateur {this.props.user?.user?.firstName === null ? this.props.user?.user?.email : this.props.user?.user?.firstName} de votre organisation ?</p>
                </Modal.Body>
                <Modal.Footer>
                    { this.state.loadingDelete ?
                        <Loader className='loader-send-invitation' style={{width: '30px', height: '30px'}}  loadData={true} />
                        : 
                        <>
                            <Button variant="danger" onClick={this.onCancelDeleteUser}>
                                Non
                            </Button>
                            <Button variant="primary" onClick={this.onDeleteUser}>
                                Oui
                            </Button>
                        </>
                    }
                </Modal.Footer>
            </Modal>
        )
    }
}

InvitationForm.propTypes = propTypes;

export default InvitationForm;