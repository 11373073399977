import { SET_PAYSLIPS, ADD_PAYSLIPS_MULTIPLE, UPDATE_PAYSLIP_ITEM, SET_PAYSLIPS_SENT_BY_MAIL, SELECTED_PAYSLIP } from '../action-type/payslipType';

const INITIAL_STATE = {
    payslips: [],
    payslipsSentByMail: false,
    selectedPayslip: null,
}

const PayslipReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_PAYSLIPS : {
            if(action.payload){
                return {
                    ...state,
                    payslips: action.payload
                }
            }
            return state;
        }
        case ADD_PAYSLIPS_MULTIPLE : {
            if(action.payload){
                const data = state.payslips.concat(action.payload);
                return {
                    ...state,
                    payslips: data
                }
            }
            return state;
        }
        case UPDATE_PAYSLIP_ITEM: {
            if(action.payload){
                const index = state.payslips.findIndex((element) => {
                    return element.id === action.payload.id;
                });
                if (index >= 0){
                    const payload = action.payload;
                    payload.loader = false;
                    payload.staff = state.payslips[index].staff;
                    state.payslips[index] = payload;
                    state.selectedPayslip = payload
                }
            }
            return { ...state }
        }
        case SET_PAYSLIPS_SENT_BY_MAIL : {
            return {
                ...state,
                payslipsSentByMail: action.payload
            }
        }
        case SELECTED_PAYSLIP : {
            return {
                ...state,
                selectedPayslip: action.payload
            }
        }
        default: 
            return state
    }
}

export default PayslipReducer;