import React, { Component, lazy, Suspense  } from 'react';
import { connect } from 'react-redux';
import moment from "moment";
import { setOrganizationBills, addMultipleOrganizationBills } from "../../redux/action/OrganisationAction";
import {_getOrganizationBills} from "../../services/Organization";
import Logger from '../../helpers/Logger';
import UserAuth from "../../helpers/auth";
import { FUNCTIONALITIES_CODE } from "../../constants/AuthConstants";
import { SESSION_STORAGE_KEYS, PAGINATION } from '../../constants/constants';
import LabelStatus from "../../components/LabelStatus";
import Loader from '../../components/Loader';
import Search from '../../components/Search';
import IconPlus from '../../components/Icons/IconPlus';
import reverseOrderIcon from '../../assets/svg/icon-reverseOrder.svg';
import DateItemList from '../../components/DateItemList';
import { numberFormat } from '../../helpers/Number';
import FilePdfDownloadPdf from '../../components/Icons/FilePdfDownloadPdf';

import "./Bills.css";

const GenericTable = lazy(() => import("../../components/Table/GenericTable") );

export class List extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            term: '',
            pageSize: PAGINATION.DEFAULT_PAGE_SIZE,
            startPage: PAGINATION.DEFAULT_PAGE,
            currentPage: PAGINATION.DEFAULT_PAGE,endIndex: null,
            endPage: null,
            pages: [],
            startIndex: null,
            totalItems: null,
            totalPages: null,
            count: null,
            hasMore: true,
            scrollLoading: false,
            isDetailLoading: false,
            isAttachmentVisible: false,
            loadPreview: false,
        };
    }

    componentDidMount(){
        this.getBills();
    }

    getBills = async () => {
        if(UserAuth._hasHabilitation(FUNCTIONALITIES_CODE.BILL_LIST)){
            this.setState({loading: true});
            const defaultOrg = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
            try{
                const res = await _getOrganizationBills(defaultOrg.id, this.state.pageSize, this.state.startPage, this.state.term);
                if(res.status === 200){
                    this.props.setOrganizationBills(res.data.rows);
                    this.setState({ 
                        loading: false,
                        currentPage: res.data.pager.currentPage,
                        endIndex: res.data.pager.endIndex,
                        endPage: res.data.pager.endPage,
                        pageSize: res.data.pager.pageSize,
                        pages: res.data.pager.pages,
                        startIndex: res.data.pager.startIndex,
                        startPage: res.data.pager.startPage,
                        totalItems: res.data.pager.totalItems,
                        totalPages: res.data.pager.totalPages,
                        count: res.data.count,
                    });
                }
            }catch(e){
                Logger.log("Get Organization's Bills","Can't get organization's bills!" , e);
            }finally{
                if(this.state.loading) this.setState({loading: false});
            }
        }
    }

    getMoreBills = async () => {
        if(UserAuth._hasHabilitation(FUNCTIONALITIES_CODE.BILL_LIST)){
            const defaultOrg = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
            try{
                this.setState({ scrollLoading: true });
                const res = await _getOrganizationBills(defaultOrg.id,this.state.pageSize, this.state.currentPage + 1, this.state.term);
                if(res?.status === 200){
                    this.setState({ 
                        currentPage: res.data.pager.currentPage,
                        endIndex: res.data.pager.endIndex,
                        endPage: res.data.pager.endPage,
                        pageSize: res.data.pager.pageSize,
                        pages: res.data.pager.pages,
                        startIndex: res.data.pager.startIndex,
                        startPage: res.data.pager.startPage,
                        totalItems: res.data.pager.totalItems,
                        totalPages: res.data.pager.totalPages,
                        count: res.data.count,
                    });
                    this.props.addMultipleOrganizationBills(res.data.rows);
                }else{
                    Logger.log("Get more Organization's Bills","Can't get more organization's bills!" , null);
                }
            }catch(e){
                Logger.log("Error","Can't get more organization's bills!" , e);
            }finally{
                if(this.state.scrollLoading) this.setState({scrollLoading: false});
            }
        }
    }

    renderStatus = (status) => {
        switch(status.toLowerCase()){
            case "validé" : {
                return "success"
            }
            case "en attente" : {
                return "default"
            }
            case "rejetté" : {
                return "danger"
            }
            default: {
                return "default"
            }
        }
    }

    headerData = [
        { title: <span> Date <img src={reverseOrderIcon} alt="date" width={10} height={10} /></span> , align: "center", className: 'pl-1 w-10-percent', name: "date"}, 
        { title: <span> Client <img src={reverseOrderIcon} alt="client" width={10} height={10} /></span> , align: "left", className: 'w-55-percent pl-15', name: "client"}, 
        { title: <span> Montant <img src={reverseOrderIcon} alt="amount" width={10} height={10} /></span> , align: "right", className: 'text-align-right justify-content-end w-25-percent pr-2', name: "amount"},
        { title: <span> Imprimer <img src={reverseOrderIcon} alt="download" width={10} height={10} /></span> , align: "center", className: 'stmt-attachment-width text-align-center sm-hidden justify-content-center', name: "attachment"},
        { title: <span> Statut <img src={reverseOrderIcon} alt="status" width={10} height={10} /></span> , align: "right", className: 'text-align-center justify-content-center sm-hidden w-10-percent', name: "status"}
    ];

    formatBodyData = (data) => {
        return data.map((item, i) => {
            return {
                originalData: item,
                date: <DateItemList key={i} date={item.date ? moment(item.date).format('YYYY/MM/DD') : moment(item.createdAt).format('YYYY/MM/DD')} active type={'bill'} />,
                client: <span>{item.customer.name}</span>,
                amount: (item.ttcTotal) ? (<span><span className="devise total-ttc"> {`${item.currency} `} </span> {`${numberFormat(item.ttcTotal)}`} </span>) : '',
                attachment: (item.attachment ?
                    <a href={item.attachment} onClick={(e) => {e.stopPropagation()}} target='_blank' rel="noopener noreferrer" download={`Facture  ${item?.customer?.name} ${moment(item.date).format('YYYYMMDD')}`} alt={`Facture ${item?.customer?.name} ${moment(item.date).format('YYYYMMDD')}`} title={`Facture ${item?.customer?.name} ${moment(item.date).format('YYYYMMDD')}`} >
                        <FilePdfDownloadPdf width={15} color="#0C9F23" />
                    </a>  
                    : <FilePdfDownloadPdf width={15} color="#0C9F23" />),
                status: (item.status) ? (<LabelStatus variant={this.renderStatus(item.status)} statement={`${item.status}`} />) : '',
            };
        });
    }

    gotoEditView = () => {
        if(UserAuth._hasHabilitation(FUNCTIONALITIES_CODE.BILL_CREATE)){
            this.props.history.push(this.props.match.url+"/bills/edit", {state: "edit"});
        }
    }

    onSelectBill = (payload) => {
        if(UserAuth._hasHabilitation(FUNCTIONALITIES_CODE.BILL_READ)){
            const organization = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
            this.props.history.push(this.props.match.url+"/bills/preview", {payload: {...payload?.originalData, organization}});
        }
    }

    render() {
        return (
            <div className="flext-part">
                <div className="options-control-header mb-3">
                    <div className="head-option-left">
                        <button className='btn-plus bill' onClick={this.gotoEditView} disabled={!UserAuth._hasHabilitation(FUNCTIONALITIES_CODE.BILL_CREATE)}>
                            <IconPlus width={9} height={9} />
                        </button>
                        <Loader loadData={this.state.loadBill} className="ml-3" style={{width: 28, height: 28}} />
                    </div>
                    <Search iconClass='search-bill' />
                </div>
                <Suspense fallback={<div className="text-center">Chargement... </div>} >
                    <GenericTable
                        data={this.props.bills}
                        onRef={this.setRefGenericTable}
                        header={this.headerData}
                        body={this.formatBodyData(this.props.bills)}
                        className='mt-20 mb-1 mlr-1'
                        hoverColor="rgba(255, 219, 219, 0.2)"
                        isLoading={this.state.loading}
                        asLoader={<Loader style={{width: "25px", height: "25px"}} enable />}
                        onSelect={this.onSelectBill}
                        infiniteScroll
                        startPage={this.state.startPage + 1}
                        getData={this.getMoreBills}
                        hasMore={(this.state.currentPage < this.state.endPage)}
                        scrollLoading={this.state.scrollLoading}
                    />
                </Suspense>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { auth, organizations } = state
    return {
        authData: auth.authData,
        organizations: organizations.organizations,
        bills: organizations.organizationBills,
        defaultOrg: organizations.defaultOrganization,
    }
}

export default connect(mapStateToProps, { setOrganizationBills, addMultipleOrganizationBills })(List);
