import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import {setOrganizationBankRecords, updateUnreconciledInvoices, setReconciledInvoices, setSelectedInvoice, addOrganizationBankRecords} from '../../redux/action/OrganisationAction';
import './Bank.css';
import Record from "../../pages/Bank/BankPage/Record";
import BankPage from "../../pages/Bank/BankPage";
import SubNavLink from "../../views/SubNavLink";
import {PAGINATION} from "../../constants/constants";
class BankContent extends Component {
    constructor() {
        super();
        this.state = {
            menu: [
                { label: 'Transactions', route: '', active: true, notifications: 0 },
                { label: 'Relevés', route: '/statements', active: false, notifications: 0 },
            ],
            loading: false,
            activePage: PAGINATION.DEFAULT_PAGE,
            pageSize: PAGINATION.DEFAULT_PAGE_SIZE,
            bankRecords: [],
            pager: null,
            selectedItem: {},
            displayModal: false,
            loadModalBank: false,
            loadingUnreconciledInvoices: false,
            loadingReconciledInvoices: false,
            scanAttachment: '',
            showScanPreview: false,
            showFilePreviewResponsive: false,
            unselectedInvoice: false,
            colorTheme:  {
                bgColor: "#FFFFFF",
                color: "#B5B5B5",
                activeColor: "#E29300",
                hoverColor: "transparent",
            }
        };
    }

    setDefaultActiveSubMenu = () => {
        const {pathname} = window.location
        const param = this.getCurrentSubRouteParam(pathname)
        let {menu} = this.state
        

        menu.forEach((item) => (
            item.active = false
        ))
        let activeItem = menu.find((item) => { return item.route ===  '/'+param }) 
        
        activeItem ? 
            activeItem.active = true :
            menu[0].active = true
    }

    UNSAFE_componentWillMount(){
        this.setDefaultActiveSubMenu()
    }
    UNSAFE_componentWillUpdate = () => {
        this.setDefaultActiveSubMenu()
    }

    setActive(key) {
        let newMenu = this.state.menu.map(item => {
            item.active = false
            return item
        })
        newMenu[key].active = true
        this.setState({
            menu: [...newMenu]
        })
    }

    getCurrentSubRouteParam = (pathname) => {
        const part = pathname.split('/')
        return part[ part.length - 1 ]
    }

    getMenuItemActive = (menuItem) => {
        let activItem = menuItem.find((elem) => { return elem.active === true });
        return [activItem.label, activItem.notifications]
    }

    render() {
        const { menu } = this.state;
        let { match } = this.props;
        return(
            <div className='bank-content'>
                <SubNavLink match={match} menu={menu} colorTheme={this.state.colorTheme} />
                <Route exact path={match.url} render={() => {
                    return <Record {...this.props} />
                }} />
                <Route path={match.url+'/:page'} component={BankPage} />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { auth, organizations } = state
    return {
        authData: auth.authData,
        organizations: organizations.organizations,
        bankRecords: organizations.organizationBankRecords.data,
        defaultOrg: organizations.defaultOrganization,
        pager: organizations.organizationBankRecords.pager
    }
}

export default connect(mapStateToProps, { setOrganizationBankRecords, updateUnreconciledInvoices, setReconciledInvoices, setSelectedInvoice, addOrganizationBankRecords})(BankContent)
