/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const TracComponent = (props) => (
  <svg width={props.width ? props.width : 16} height={props.height ? props.height : 10} viewBox="0 0 14 8">
    <defs>
      <style>{".link-icon{fill:#707070;}"}</style>
    </defs>
    <path
      fill={props.color ? props.color : '#707070'}
      className="link-icon"
      d="M3.33,11A2.343,2.343,0,0,1,5.5,8.52H8.3V7H5.5A3.777,3.777,0,0,0,2,11a3.777,3.777,0,0,0,3.5,4H8.3V13.48H5.5A2.343,2.343,0,0,1,3.33,11Zm2.87.8h5.6V10.2H6.2ZM12.5,7H9.7V8.52h2.8A2.343,2.343,0,0,1,14.67,11a2.343,2.343,0,0,1-2.17,2.48H9.7V15h2.8A3.777,3.777,0,0,0,16,11,3.777,3.777,0,0,0,12.5,7Z"
      transform="translate(-2 -7)"
    />
  </svg>
);

export default TracComponent;
