import { VALID_FORM_CIVIL, VALID_FORM_CONTRACT, VALID_FORM_COORD, VALID_FORM_PAY } from '../action-type/validateType';

const INITIAL_STATE = {
    formCivilValid: false,
    formCoordValid: false,
    formContractValid: false,
    formPayValid: false
};

const validationReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case VALID_FORM_CIVIL:
            return { ...state, formCivilValid: action.payload };
        case VALID_FORM_CONTRACT:
            return { ...state, formContractValid: action.payload };
        case VALID_FORM_COORD: 
            return { ...state, formCoordValid: action.payload };
        case VALID_FORM_PAY:
            return { ...state, formPayValid: action.payload };
        default:
            return state;
    }
}

export default validationReducer;