import axios from "../config/axios";

export const patchMonthlyInfoPayslip = async (orgId, payPeriodId, payslipId, data) => {
    return await axios.put(`/organizations/${orgId}/pay-period/${payPeriodId}/payslips/${payslipId}`, data);
}

export const generatePayslip = async (orgId, payPeriodId, printOrGenerate = "generate", staffId) => {
    const payload = {
        connectionId: sessionStorage.getItem("connectionId"),
        payPeriodId: payPeriodId,
        staffId: staffId
    }
   return await axios.post(`/organizations/${orgId}/pay-period/${payPeriodId}/payslips/${printOrGenerate}`, payload);
}

export const sendPayslipsByMail = async (orgId, payPeriodId, payslipId) => {
    const payload = {
        connectionId: sessionStorage.getItem("connectionId"),
        payPeriodId: payPeriodId,
        payslipId: payslipId,
    }
    return await axios.post(`/organizations/${orgId}/pay-period/${payPeriodId}/payslips/send`, payload);
}

export const _updatePayslip = async (orgId, payPeriodId, payslipId, data, manual) => {
    return await axios.put(`/organizations/${orgId}/pay-period/${payPeriodId}/payslips/${payslipId}?manual=${manual}`, data);
}
