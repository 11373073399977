import React, { PureComponent } from 'react';
import IconEuro from '../Icons/IconEuro';
import IconCategory from '../Icons/CategoryIcon';
import IconEchelon from '../Icons/EchelonIcon';
import IconPayment from '../Icons/EuroPaymentComponent';
import IconTime from '../Icons/TimeIcon';
import HousingAllowance from '../Icons/HousingAllowanceComponent';
import TransportAllowance from '../Icons/TransportAllowanceComponent';
import EditableInput from '../EditableInput';
import { connect } from 'react-redux';
import { updateStaff, selectStaff, setAllowancesStaff, deleteAllowancesStaff } from '../../redux/action/StaffAction';
import { _updateContrat, _getContractAllowances } from '../../services/Contrat';
import { numberFormat } from '../../helpers/Number';
import AllowancesManager from "../AllowancesManager";
import Logger from '../../helpers/Logger';
import { errorNotifcation } from "../../helpers/errorHandle";

class PayPreview extends PureComponent {
    constructor(props){
        super(props)
        this.state = {
            category: this.props.data.category,
            echelon: this.props.data.echelon,
            surSalary: this.props.data.surSalary,
            initBrutSalary: this.props.data.brutSalary,
            brutSalary: this.props.data.brutSalary,
            baseSalary: this.props.data.baseSalary,
            baseSalaryIndicator: (+this.props.data.baseSalary + (+this.props.data.surSalary)),
            transportAllowance: _getContractAllowances.transportAllowance(this.props.data.contractAllowances),
            housingAllowance: _getContractAllowances.housingAllowance(this.props.data.contractAllowances),
            weeklyTime: this.props.data.weeklyTime,
            allowances: this.props.selectedAllowancesStaff,
            selectedAllowancesStaff: this.props.selectedAllowancesStaff,
            selectedStaff: this.props.selectedStaff,
            contract: this.props.selectedStaff.contracts[0],
        };
    }

    handleChange = (brutSalary) => {
        if (!brutSalary || (brutSalary <= 0)) return;
        this.updateContract(brutSalary).then(grid => {
            if(grid) {
                let staff = this.props.selectedStaff;
                let { contracts } = staff;
                let contract = this.getContractReducer();
                const i = this.getIndexContractReducer();
                contract = {
                    ...contract,
                    baseSalary: grid.baseSalary,
                    brutSalary,
                    baseSalaryIndicator: (+grid.baseSalary + (+grid.surSalary)),
                    category: grid.category,
                    echelon: grid.echelon,
                    housingAllowance: 0,
                    surSalary: grid.surSalary,
                    transportAllowance: 0
                };
                contracts[i] = contract;
                staff = { ...staff, contracts: [ ...contracts ] };
                this.props.updateStaff(staff);
            }
        });
        
    }

    handleFocus = () => {
        const contract = this.getContractReducer();
        this.setState({
            initBrutSalary: +contract.brutSalary,
        }, () => { });
    }

    updateContract = async (id, contractData) => {
        if ( (contractData.brutSalary && (contractData.brutSalary > 0)) || ((contractData.weeklyTime && (contractData.weeklyTime > 0)))
        || ((contractData.transportAllowance && (contractData.transportAllowance > 0)))  || ((contractData.housingAllowance && (contractData.housingAllowance > 0))) ){
            let defaultOrganization = JSON.parse(sessionStorage.getItem('defaultOrg'));
            try {
                const contract = (await _updateContrat(defaultOrganization.id, this.props.data.staffId, id, contractData)).data;
                if(contract) {
                    let staff = this.props.selectedStaff;
                    let { contracts } = staff;
                    const i = this.getIndexContractReducer();
                    contracts[i] = contract;
                    staff = { ...staff, contracts: [ ...contracts ] };
                    this.props.updateStaff(staff);
                    this.props.selectStaff(staff);
                    this.props.updateAllowancesStaff(contract.contractAllowances);
                }
                this.setState({contract});
            } catch (error) {
                Logger.error("UpdateContract", "Error occured when updating contract", error);
                errorNotifcation(error?.response?.status, "Erreur pendant la mise à jour du contrat, veuillez reessayer ultérieurement.");
            }
        }
    }

    getContractReducer = () => {
        const { data } = this.props
        const { contracts } = this.props.selectedStaff;
        const contract = contracts.find((contract) => { return contract.id === data.id });
        contract.transportAllowance = _getContractAllowances.transportAllowance(contract.contractAllowances);
        contract.housingAllowance = _getContractAllowances.housingAllowance(contract.contractAllowances);
        contract.baseSalaryIndicator = (+contract.baseSalary) + (+contract.surSalary)
        return contract;
    }

    getIndexContractReducer = () => {
        const { data } = this.props
        const { contracts } = this.props.selectedStaff;
        return contracts.findIndex((contract) => { return contract.id === data.id });
    }

    deleteAllowances = async (id, contractId) => {
        if(this.props.onDeleteAllowance){
            const allowances = await this.props.onDeleteAllowance(id, contractId);
            this.setState({allowances});
            return true;
        }
    }

    updateAllowance = async (contractId, data, nameAllowance) => {
        if(this.props.onUpdateAllowance){
            const allowances = await this.props.onUpdateAllowance(contractId, data, nameAllowance);
            this.setState({allowances});
            return true;
        }
    }

    addAllowance = async (contractId, data, nameAllowance) => {
        if(this.props.onAddAllowance){
            const allowances = await this.props.onAddAllowance(contractId, data, nameAllowance);
            this.setState({allowances});
            return true;
        }
    }

    render() {
        const contract = this.getContractReducer();
        return(
            <div className='px-0'>
                <div className="header-preview">
                    <div className="d-flex align-items-center">
                        <div className="col-md-12 col-sm-12">
                            <div className="label-input">Salaire brut </div>
                            <div className='d-flex EditableInput align-items-center'>
                                <span className="ico-content mr-1"> <IconEuro  active /> </span>
                                <span className="valuePreview mr-2">{+contract.brutSalary ? numberFormat(contract.brutSalary) : ''}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-preview">
                    <div className="row pt-1 pl-3">
                        <div className="col-md-12 col-sm-12">
                            <p className='payTitle'>Salaire de base indicatif</p>
                            <EditableInput type="number" name="baseSalaryIndicator" value={contract.baseSalaryIndicator}
                                onBlur={(event) => this.updateContract(+contract.id, {brutSalary: +event.target.value})}
                                className="header-preview-title pl-30-10" editable state={'preview'} 
                            />
                        </div>
                    </div>
                </div>
                <div className="header-preview">
                    <div className="d-flex align-items-center mt-1">
                        <span className="ico-content pr-1"></span>
                        <div className="label-input d-flex align-items-center">
                            <span className="ico-content mr-1"> <IconCategory active /> </span>
                            <span className="txt-primary bold mr-3">{contract.category}</span> 
                            <span className="ico-content mr-1"> <IconEchelon width={20} height={20} active /> </span>
                            <span className="txt-primary bold ">{contract.echelon}</span> 
                        </div>
                    </div>
                    <div className="row pt-1 pl-3">
                        <div className="col-md-5 col-sm-12">
                            <div className="label-input">Salaire de base </div>
                            <div className='d-flex EditableInput align-items-center'>
                                <span className="ico-content mr-1"> <IconEuro  active /> </span>
                                <span className="valuePreview mr-2">{+contract.baseSalary ? numberFormat(contract.baseSalary) : ''}</span>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="label-input">Sur-salaire </div>
                            <div className='d-flex EditableInput align-items-center'>
                                <span className="ico-content mr-1"> <IconPayment  active /> </span>
                                <span className="valuePreview mr-2">{+contract.surSalary ? numberFormat(contract.surSalary) : ''}</span> 
                            </div>
                            
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="label-input">Horaire hebdo. </div>
                            <EditableInput type="number" name="weeklyTime" value={contract.weeklyTime} className="pl-30-10" 
                                onBlur={event => this.updateContract(+contract.id, {weeklyTime: +event.target.value})} state={'preview'} editable withIcon={<IconTime  active />} />
                        </div>
                    </div>
                </div>
                <div className="content-preview">
                    <div className="row pt-1 pl-3">
                        <div className="col-md-6 col-sm-12">
                            <div className="label-input">Indemnité de transport </div>
                            <div className='d-flex EditableInput align-items-center'>
                                <EditableInput type="number" name="transportAllowance" value={contract.transportAllowance ? +contract.transportAllowance : 0} 
                                    onBlur={(event) => this.updateContract(+contract.id, {transportAllowance: +event.target.value, housingAllowance: +contract.housingAllowance})}
                                    onFocus={this.handleFocus} placeholder="0"
                                    className="pl-30-10" state={'preview'}  globalClassName="min-width150"
                                    withIcon={<TransportAllowance />}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="label-input">Indemnité de logement </div>
                            <div className='d-flex EditableInput align-items-center'>
                                <EditableInput type="number" name="housingAllowance" value={contract.housingAllowance ? +contract.housingAllowance : 0} 
                                    onBlur={(event) => this.updateContract(+contract.id, {housingAllowance: +event.target.value, transportAllowance: +contract.transportAllowance})}
                                    onFocus={this.handleFocus} placeholder="0"
                                    className="pl-30-10" state={'preview'}  globalClassName="min-width150"
                                    withIcon={<HousingAllowance active />}
                                />
                            </div>
                        </div>
                        <hr/>
                    </div>
                </div>
                <div>
                    <AllowancesManager 
                        allowances={contract.contractAllowances} 
                        objectID={{contractId: contract.id}} 
                        saveAllowances={this.addAllowance}
                        updateAllowances={this.updateAllowance}
                        deleteAllowance={this.deleteAllowances}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { staffs } = state
    return {
        selectedStaff: staffs.selectedStaff,
        selectedAllowancesStaff: staffs.allowancesStaffSelected,
    }
}

export default connect(mapStateToProps, {updateStaff, selectStaff, setAllowancesStaff, deleteAllowancesStaff})(PayPreview)
