import React from "react";

const UnionComponent = (props) => (
  <svg width={props.width ? props.width : 20.63} height={props.height ? props.height : 13.694} viewBox={`0 0 ${props.width ? props.width : 20.63} ${props.height ? props.height : 13.694}`}>
    <defs>
      <style>{`.union{fill:${props.active ? '#177BD1;' :'#b5b5b5;'}}`}</style>
    </defs>
    <path
      className="union"
      d="M-248.711-1456.305a.285.285,0,0,1-.282-.347c.257-1.134,1.723-1.687,2.228-1.865a16.449,16.449,0,0,1,1.859-.356,1.9,1.9,0,0,0,.859-.4,7.537,7.537,0,0,0,.057-1.759,5.231,5.231,0,0,1-.406-.774,9.908,9.908,0,0,1-.3-1.351s-.3,0-.4-.514a5.036,5.036,0,0,1-.264-1.194c.021-.41.246-.4.246-.4a6.507,6.507,0,0,1-.238-1.83,2.925,2.925,0,0,1,3.2-2.91,2.938,2.938,0,0,1,3.2,2.91,6.237,6.237,0,0,1-.242,1.83s.224-.011.246.4a5.037,5.037,0,0,1-.264,1.194c-.1.514-.4.514-.4.514a9.908,9.908,0,0,1-.3,1.351,5.231,5.231,0,0,1-.406.774,7.537,7.537,0,0,0,.057,1.759,1.9,1.9,0,0,0,.859.4,16.4,16.4,0,0,1,1.858.356c.507.178,1.973.731,2.229,1.865a.285.285,0,0,1-.278.347Zm14.676-.127a5.075,5.075,0,0,0-2.258-2.1,4.743,4.743,0,0,0-1.742-.367s-.268-.423.614-.585a5.987,5.987,0,0,0,1.354-.473c.134-.155.042-1.107.042-1.107a4.8,4.8,0,0,1-2.357-.656c1.094-1.659.5-3.592.727-5.4A2.976,2.976,0,0,1-234.36-1470h.028a2.986,2.986,0,0,1,3.267,2.878c.226,1.8-.4,3.951.72,5.4a4.434,4.434,0,0,1-2.364.585,3.57,3.57,0,0,0,.071,1.185,1.777,1.777,0,0,0,.846.388,16.359,16.359,0,0,1,1.828.509,2.971,2.971,0,0,1,1.595,2.624Z"
      transform="translate(249 1470)"
    />
  </svg>
);

export default UnionComponent;
