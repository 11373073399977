import React from "react";

const PricetagIcon = (props) => (
  <svg viewBox="0 0 20.067 19.96" width={props.width} height={props.height}>
    <defs>
    </defs>
    <path
      stroke={props.stroke}
      fill={props.fill ? props.fill : '#707070'}
      strokeWidth={props.strokeWidth}
      d="M31.493,13.846h-5.6a.659.659,0,0,0-.465.191l-11.2,11.2a1.315,1.315,0,0,0,0,1.855l5.332,5.332a1.315,1.315,0,0,0,1.855,0L32.61,21.23a.659.659,0,0,0,.191-.465V15.159A1.306,1.306,0,0,0,31.493,13.846Zm-2.174,5.1a1.458,1.458,0,1,1,1.29-1.29A1.46,1.46,0,0,1,29.319,18.942Z"
      transform="translate(-13.235 -13.346)"
    />
  </svg>
);

export default PricetagIcon;
