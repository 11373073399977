/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const GroupComponent = (props) => (
  <svg width={props.width ? props.width : 18.891} height={props.height ? props.height : 5.834} viewBox="0 0 18.891 15.834">
    <defs>
      <style>{`.paiea{fill:${props.active ? '#177bd1;' : '#b5b5b5;'}}`}</style>
    </defs>
    <g transform="translate(-6.686 -24.736)">
      <path
        className="paiea"
        d="M56.212,49.7l-3.744-1.679a.291.291,0,0,0-.236,0L48.485,49.7a.176.176,0,0,0-.008.309l3.736,1.967a.285.285,0,0,0,.272,0l3.736-1.967A.176.176,0,0,0,56.212,49.7Zm-3.357,6.97,3.765-1.992a.169.169,0,0,0,.075-.142V50.674a.156.156,0,0,0-.224-.148l-3.765,1.94a.165.165,0,0,0-.088.148V56.53A.158.158,0,0,0,52.854,56.674Zm-4.854-6v3.863a.169.169,0,0,0,.075.142l3.765,1.992a.154.154,0,0,0,.234-.142V52.614a.165.165,0,0,0-.088-.148l-3.763-1.94A.156.156,0,0,0,48,50.674Z"
        transform="translate(-36.216 -23.264)"
      />
      <path
        className="paiea"
        d="M56.212,49.7l-3.744-1.679a.291.291,0,0,0-.236,0L48.485,49.7a.176.176,0,0,0-.008.309l3.736,1.967a.285.285,0,0,0,.272,0l3.736-1.967A.176.176,0,0,0,56.212,49.7Zm-3.357,6.97,3.765-1.992a.169.169,0,0,0,.075-.142V50.674a.156.156,0,0,0-.224-.148l-3.765,1.94a.165.165,0,0,0-.088.148V56.53A.158.158,0,0,0,52.854,56.674Zm-4.854-6v3.863a.169.169,0,0,0,.075.142l3.765,1.992a.154.154,0,0,0,.234-.142V52.614a.165.165,0,0,0-.088-.148l-3.763-1.94A.156.156,0,0,0,48,50.674Z"
        transform="translate(-41.314 -16.126)"
      />
      <path
        className="paiea"
        d="M56.212,49.7l-3.744-1.679a.291.291,0,0,0-.236,0L48.485,49.7a.176.176,0,0,0-.008.309l3.736,1.967a.285.285,0,0,0,.272,0l3.736-1.967A.176.176,0,0,0,56.212,49.7Zm-3.357,6.97,3.765-1.992a.169.169,0,0,0,.075-.142V50.674a.156.156,0,0,0-.224-.148l-3.765,1.94a.165.165,0,0,0-.088.148V56.53A.158.158,0,0,0,52.854,56.674Zm-4.854-6v3.863a.169.169,0,0,0,.075.142l3.765,1.992a.154.154,0,0,0,.234-.142V52.614a.165.165,0,0,0-.088-.148l-3.763-1.94A.156.156,0,0,0,48,50.674Z"
        transform="translate(-31.118 -16.126)"
      />
    </g>
  </svg>
);

export default GroupComponent;
