import React , {Component } from 'react';
import Tag from '../Tag';
import { ROLES } from '../../constants/AuthConstants';

const MEDIUM_WIDTH = 1024;
const LARGE_WIDTH = 1280;
const EXTRA_WIDTH = 1440;

class TagContainer extends Component {

    constructor(props){
        super(props);
        this.state = {
            accountType :  this.props.accountRole,
            observations : this.props.observations,
            screen: {height: 0, width: 0},
        };
    }

    componentDidMount = () => {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    
    getSliceByScreenWidth = (item) => {
        try {
            let observations = item;
            if (!observations) observations = [];
            let {screen} = this.state;
            if (screen.width <= MEDIUM_WIDTH) return this.sliceObservations(observations, 2);
            if (screen.width > MEDIUM_WIDTH && screen.width <= LARGE_WIDTH) return this.sliceObservations(observations, 4);
            if (screen.width > LARGE_WIDTH && screen.width <= EXTRA_WIDTH) return this.sliceObservations(observations, 4);
            if (screen.width > EXTRA_WIDTH) return observations;
        } catch(e){
            return [];
        }
    };

    sliceObservations = (observations, itemsNum) => {
        const size = observations.length
        observations = observations.slice(0, itemsNum)
        const remain = size - itemsNum
        if (remain > 0) {
            observations.push({
                type: ROLES.customer,
                value: remain + "+"
            })
        }
        
        return observations
    };

    updateWindowDimensions = () => {
        this.setState({ screen : {width: window.innerWidth, height: window.innerHeight }});
    };

    getAccountType = (tag) => {
        return tag.type === ROLES.accountant;
    };

    editable = (tag) => {
        if(this.props.editable){
            return tag.type === this.state.accountType;
        }
    };

    onRemove = (tag) => {
        if(this.props.onRemove){
            this.props.onRemove(tag);
        }
    };

    onUpdate = () => {
        if(this.props.onUpdate){
            this.props.onUpdate();
        }
    };

    onTagClick = (data) => {
        if(this.props.onTagClick && this.editable(data)){
            this.props.onTagClick(data);
        }
    };

    onClick = (elem) => {
        if(this.props.onClick){
            this.props.onClick(elem);
        }
    }

    renderTag = (elem, index) => {
        let { slice } = this.props;
        return (
            <Tag data={elem} 
                onTagClick={() => this.onTagClick(elem)} 
                key={elem.id + "" + index} 
                index={index}
                title={elem.value} 
                active={this.getAccountType(elem)}
                editable={this.editable(elem)}
                onUpdate={this.onUpdate} 
                onRemove={this.onRemove} 
                slice={slice}
            />
        )
    }

    renderSliceTag = () => {
        return this.props.slice ? this.getSliceByScreenWidth(this.props.tags) : this.props.tags;
    }

    render() {
        const observations = this.renderSliceTag();
        return (
            <div className="tag-container">
            {
                observations? observations.map((elem, index) => {
                    return (
                        this.renderTag(elem, index)
                    )
                }) : ""
            }
            </div>
        );
    }

}

export default TagContainer;