import moment from 'moment'
import { SET_PAYPERIOD, ADD_PAYPERIOD, ADD_PAYPERIOD_MULTIPLE, SELECTED_PAYPERIOD, SET_CLOSE_PAYPERIOD_LOADER } from '../action-type/payperiodType';

const INITIAL_STATE = {
    period: [],
    selectedPeriod: null,
    closePayPeriodLoader: false,
}

const PayPeriodReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_PAYPERIOD :{ 
            if(action.payload){
                action.payload.sort((a, b) => { return new Date(b.date) - new Date(a.date) });
                action.payload.map((item) => ( item.date = moment(item.date, 'MM/DD/YYYY').format('MMMM YYYY') ));
                state.period = action.payload;
            }
            return { ...state };
        }
        case ADD_PAYPERIOD :{ 
            if(action.payload) {
                return { ...state, period: [...action.payload, ...state.period]};
            }
            return { ...state }
        }
        case ADD_PAYPERIOD_MULTIPLE :{ 
            if(action.payload) {
                const data = state.period.concat(action.payload);
                return { ...state, period: data};
            }
            return { ...state }
        }
        case SELECTED_PAYPERIOD :{ 
            return { ...state, selectedPeriod: action.payload };
        }
        case SET_CLOSE_PAYPERIOD_LOADER: {
            return { ...state, closePayPeriodLoader: action.payload };
        }
        default: return state;
    }
}

export default PayPeriodReducer;