import React, {Component} from "react";
import "./Footer.css";

class Footer extends Component {
    constructor(props){
        super(props)
        this.state = {}
    }

    render(){
        return (
            <footer className="d-flex container-footer">
                <span className="d-flex align-items-center footer">Copyright © 2019. Tous droits réservés.</span>
            </footer>
        )
    }
}

export default Footer