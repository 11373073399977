import React from "react";

const CalendarComponent = (props) => (
  <svg width={ props.width ? props.width : 24.868 } height={ props.height ? props.height : 18.239 } viewBox={`0 0 ${ props.width ? props.width : 24.868 } ${ props.height ? props.height : 18.239 }`}>
    <defs>
      <style>
        {
          `.expa{fill:#fff;}.expb{fill:${props.active ? '#177BD1;' : '#b5b5b5;'}}.expc{clip-path:url(#a);}.expd,.expe,.expg{fill:none;}.expd,.expe{stroke:${props.active ? '#177BD1' :'#b5b5b5;'}}.expd{stroke-linejoin:round;stroke-width:0.4px;}.expe{stroke-linecap:round;stroke-width:0.7px;}.expf{stroke:none;}`
        }
      </style>
      <clipPath id="expa">
        <path
          className="expa"
          d="M19.236,4.532V-.23H-.405V18.008H19.236v-.926H14.473s-1.087-.795,0-1.241a2,2,0,0,1,.516,0,6,6,0,0,1,2.547-4.518c.039-.059.787-.4,0-1.063-.04-.063-2.291-.654-2.547-4.254a4.14,4.14,0,0,0-.516-.065s-.886-.451,0-1.409C14.545,4.542,19.236,4.532,19.236,4.532Z"
        />
      </clipPath>
    </defs>
    <g transform="translate(0.405 0.23)">
      <path
        className="expb"
        d="M91.686,61.169h-.148c-.189,0-.349-.026-.369-.215v0c-.425-3.953-2.914-4.028-2.914-4.959s2.493-1.006,2.914-4.959v0c.02-.189.18-.215.369-.215h.148a.417.417,0,0,0,.419-.387A.408.408,0,0,0,91.7,50H82.4a.417.417,0,0,0-.419.387.408.408,0,0,0,.407.428h.16c.189,0,.349.026.369.215v0c.425,3.953,2.914,4.028,2.914,4.959S83.341,57,82.919,60.951v0c-.02.189-.18.215-.369.215H82.4a.417.417,0,0,0-.419.387.408.408,0,0,0,.407.428H91.7a.406.406,0,0,0,.407-.428A.417.417,0,0,0,91.686,61.169Zm-7.339-.995c.835-2.321,2.315-2.039,2.315-2.955V55.6c0-.564-1.07-.957-1.739-1.873a.228.228,0,0,1,.189-.361h3.866a.227.227,0,0,1,.195.355c-.657.919-1.748,1.315-1.748,1.879v1.617c0,.907,1.422.66,2.321,2.955.081.207.02.465-.2.465h-5C84.324,60.64,84.272,60.384,84.347,60.174Z"
        transform="translate(-67.642 -45.147)"
      />
      <g className="expc">
        <path
          className="expb"
          d="M18.181,4.754H16.8a.2.2,0,1,0,0,.4h1.385a.453.453,0,0,1,.454.452V8.769H1.532a.2.2,0,1,0,0,.4h17.1V20.192a.453.453,0,0,1-.452.452H.851A.453.453,0,0,1,.4,20.192V5.607a.453.453,0,0,1,.454-.451H2.284a.2.2,0,1,0,0-.4H.851A.854.854,0,0,0,0,5.607V20.192a.854.854,0,0,0,.853.853H18.181a.854.854,0,0,0,.853-.853V5.607a.854.854,0,0,0-.853-.853Z"
          transform="translate(0 -3.381)"
        />
        <path
          className="expb"
          d="M9.45,3.355h7.989a.2.2,0,0,0,0-.4H9.45a.2.2,0,1,0,0,.4ZM7.808,4.816a.9.9,0,0,0,.9-.9V2.482a.9.9,0,1,0-1.806,0v1.43A.9.9,0,0,0,7.808,4.816Zm-.5-2.333a.5.5,0,0,1,1,0v1.43a.5.5,0,0,1-1,0ZM19.039,4.816a.9.9,0,0,0,.9-.9V2.482a.9.9,0,0,0-1.806,0v1.43A.9.9,0,0,0,19.039,4.816Zm-.5-2.333a.5.5,0,0,1,1,0v1.43a.5.5,0,1,1-1,0Z"
          transform="translate(-3.918 -1.579)"
        />
        <g transform="translate(-0.173 0.465)">
          <g className="expd" transform="translate(1.766 7)">
            <rect className="expf" width={14.345} height={8.069} rx={1} />
            <rect
              className="expg"
              x={0.2}
              y={0.2}
              width={13.945}
              height={7.669}
              rx={0.8}
            />
          </g>
          <path
            className="expb"
            d="M68.665,68.541c-.087-.386-.586-.574-.758-.634a5.652,5.652,0,0,0-.632-.121.642.642,0,0,1-.292-.135,2.554,2.554,0,0,1-.019-.6,1.8,1.8,0,0,0,.138-.263,3.4,3.4,0,0,0,.1-.46s.1,0,.135-.175a1.72,1.72,0,0,0,.09-.406c-.007-.14-.084-.136-.084-.136a2.127,2.127,0,0,0,.083-.622,1.092,1.092,0,0,0-2.175,0,2.219,2.219,0,0,0,.081.622s-.076,0-.084.136a1.718,1.718,0,0,0,.09.406c.034.175.135.175.135.175a3.393,3.393,0,0,0,.1.46,1.8,1.8,0,0,0,.138.263,2.556,2.556,0,0,1-.019.6.642.642,0,0,1-.292.135,5.651,5.651,0,0,0-.632.121c-.172.061-.671.249-.758.634a.1.1,0,0,0,.1.118h4.463A.1.1,0,0,0,68.665,68.541Z"
            transform="translate(-61.529 -55.968)"
          />
          <line className="expe" x2={4.483} transform="translate(10.283 8.345)" />
          <line className="expe" x2={6.276} transform="translate(8.49 10.138)" />
          <line className="expe" x2={6.276} transform="translate(8.49 11.931)" />
          <line className="expe" x2={11.655} transform="translate(3.111 13.724)" />
        </g>
      </g>
    </g>
  </svg>
);

export default CalendarComponent;
