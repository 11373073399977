import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { setUsers, selectUser } from '../../redux/action/UserAction';
import UserInvitation from "../../views/Users/UserInvitation";
import './Users.css';

class UsersContent extends Component {
    render() {
        let { match } = this.props;
        return (
            <div className="users-content">
                <Route exact path={match.url} render={() => {
                    return <UserInvitation {...this.props} />
                }} />
                <Route path={match.url+'/:page'} component={UserInvitation} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { auth, organizations, users } = state;
    return {
        authData: auth.authData,
        organizations: organizations.organizations,
        invoices: organizations.organizationInvoices,
        defaultOrg: organizations.defaultOrganization,
        users: users.users,
        selectedUser: users.selectedUser,
    }
}

export default connect(mapStateToProps, { setUsers, selectUser })(UsersContent)
