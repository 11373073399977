import { 
    SET_TAXES, SELECT_TAXE, SET_VALUE ,HANDLE_CHANGE_TAB, HANDLE_CHANGE_RATE, HANDLE_CHECKBOX, 
    GET_SUM, GET_SUM_L1_L2, GET_SUM_L3_L4, GET_SUBSTACT_L5_L6, GET_SUM_L5_L7, SWITCH_L5_OR_L7,
    SET_PAGE, GET_L16XL22, SWITCH_L22_OR_L23, ACTION_TAB_FIVE, ACTION_FORM_ONE,
    SUM_TABS, ONCHANGE_TABLE_8, ONCHANGE_TABLE_9, ONCHANGE_TABLE_10, ONCHANGE_TABLE_11, ONCHANGE_TABLE_12,
    ONCHANGE_TABLE_13, ONCHANGE_TABLE_14, ONCHANGE_TABLE_15, ONCHANGE_FOOTER,
    SET_CNPS_DECLARATION_PERIODS, ADD_CNPS_DECLARATION_PERIODS, ADD_MORE_CNPS_DECLARATION_PERIODS,
    UPDATE_CNPS_DECLARATION_PERIODS, UPDATE_CNPS_DECLARATION_PERIODS_DETAILS, DELETE_CNPS_DECLARATION_PERIODS, SELECT_CNPS_DECLARATION_PERIODS,
} from '../action-type/taxesType';
import {toNumber} from "../../helpers/dataFormat";

const INITIAL_STATE = {
    taxes: [],
    selectedTaxe: {},
    page: null,
    cnpsDeclarations: [],
    selectedCnpsDeclaration: {},
};

const TaxesReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SET_TAXES: {
            return { ...state, taxes: action.payload };
        }
        case SELECT_TAXE: {
            return { ...state, selectedTaxe: action.payload };
        }
        case SET_VALUE: {
            return { ...state, selectedTaxe: {...state.selectedTaxe, [action.payload.target.name]: action.payload.target.value} };
        }
        case SET_PAGE: {
            return { ...state, page: +action.payload };
        }
        case HANDLE_CHANGE_TAB: {
            let sum = null;
            action.payload.tabCol.forEach(elm => {
                if(elm){
                    sum += +state.selectedTaxe[elm];
                }
            });
            sum += +action.payload.event.target.value;
            if(action.payload.taux){ sum += (sum * +action.payload.taux / 100); }
            return { 
                ...state, 
                selectedTaxe: {
                    ...state.selectedTaxe, 
                    [action.payload.event.target.name]: +action.payload.event.target.value,
                    [action.payload.total]: toNumber(sum),
                },
            };
        }
        case HANDLE_CHANGE_RATE: {
            let sum = null;
            action.payload.tabCol.forEach(elm => {
                if(elm){
                    sum += +state.selectedTaxe[elm];
                }
            });
            sum += (sum * +action.payload.event.target.value / 100);
            return { 
                ...state, 
                selectedTaxe: {
                    ...state.selectedTaxe,
                    [action.payload.event.target.name]: +action.payload.event.target.value,
                    [action.payload.total]: toNumber(sum),
                },
            };
        }
        case HANDLE_CHECKBOX: {
            let obj = {};
            action.payload.tabCol.forEach(elm => {
                if(elm){
                    obj = {...obj, [elm]: false};
                }
            });
            obj[action.payload.target] = true;
            return { 
                ...state, 
                selectedTaxe: {
                    ...state.selectedTaxe,
                    ...obj,
                    [action.payload.event.target.name]: action.payload.event.target.value,
                },
            };
        }
        case GET_SUM: {
            let sum = 0;
            action.payload.tab.forEach(elm => {
                if(elm){
                    sum += +state.selectedTaxe[elm];
                }
            });
            if(action.payload.taux){ sum = sum + (sum * +action.payload.taux / 100); }
            return { 
                ...state, 
                selectedTaxe: {
                    ...state.selectedTaxe,
                    [action.payload.target]: toNumber(sum),
                },
            };
        }
        case GET_SUM_L1_L2: {
            return { 
                ...state, 
                selectedTaxe: {
                    ...state.selectedTaxe,
                    daabase: toNumber(+state.selectedTaxe.cagbase + +state.selectedTaxe.carbase),
                    daarate: toNumber(+state.selectedTaxe.cagrate + +state.selectedTaxe.carrate),
                    daaprincipal: toNumber(+state.selectedTaxe.cagprincipal + +state.selectedTaxe.carprincipal),
                    daapenality: toNumber(+state.selectedTaxe.cagpenality + +state.selectedTaxe.carpenality),
                    daatotal: toNumber(+state.selectedTaxe.cagtotal + +state.selectedTaxe.cartotal),
                },
            };
        }
        case GET_SUM_L3_L4: {
            return { 
                ...state, 
                selectedTaxe: {
                    ...state.selectedTaxe,
                    darbase: toNumber(+state.selectedTaxe.daabase + +state.selectedTaxe.dasbase),
                    darrate: toNumber(+state.selectedTaxe.daarate + +state.selectedTaxe.dasrate),
                    darprincipal: toNumber(+state.selectedTaxe.daaprincipal + +state.selectedTaxe.dasprincipal),
                    darpenality: toNumber(+state.selectedTaxe.daapenality + +state.selectedTaxe.daspenality),
                    dartotal: toNumber(+state.selectedTaxe.daatotal + +state.selectedTaxe.dastotal),
                },
            };
        }
        case GET_SUBSTACT_L5_L6: {
            return { 
                ...state, 
                selectedTaxe: {
                    ...state.selectedTaxe,
                    cdapbase: toNumber(+state.selectedTaxe.darbase),
                    cdaprate: toNumber(+state.selectedTaxe.darrate),
                    cdapprincipale: toNumber(+state.selectedTaxe.darprincipal - +state.selectedTaxe.dapiprincipal),
                    cdappenality: toNumber(+state.selectedTaxe.darpenality),
                    cdaptotal: toNumber(+state.selectedTaxe.dartotal - +state.selectedTaxe.cdaptotal),
                },
            };
        }
        case GET_SUM_L5_L7: {
            return { 
                ...state, 
                selectedTaxe: {
                    ...state.selectedTaxe,
                    l5l7base: toNumber(+state.selectedTaxe.darbase + +state.selectedTaxe.cdapbase),
                    l5l7rate: toNumber(+state.selectedTaxe.darrate + +state.selectedTaxe.cdaprate),
                    l5l7principal: toNumber(+state.selectedTaxe.darprincipal + +state.selectedTaxe.cdapprincipale),
                    l5l7penality: toNumber(+state.selectedTaxe.darpenality + +state.selectedTaxe.cdappenality),
                    l5l7total: toNumber(+state.selectedTaxe.dartotal + +state.selectedTaxe.cdaptotal),
                },
            };
        }
        case GET_L16XL22: {
            return {
                ...state, 
                selectedTaxe: {
                    ...state.selectedTaxe,
                    l23: toNumber(+state.selectedTaxe.l16 * +state.selectedTaxe.l22),
                }
            }
        }
        case SWITCH_L5_OR_L7: {
            switch(action.payload){
                case 'l5': {
                    return {
                        ...state, 
                        selectedTaxe: {
                            ...state.selectedTaxe,
                            l5orl7base: toNumber(+state.selectedTaxe.darbase),
                            l5orl7rate: toNumber(+state.selectedTaxe.darrate),
                            l5orl7principal: toNumber(+state.selectedTaxe.darprincipal),
                            l5orl7penality: toNumber(+state.selectedTaxe.darpenality),
                            l5orl7total: toNumber(+state.selectedTaxe.dartotal),
                        }
                    };
                }
                case 'l7': {
                    return {
                        ...state, 
                        selectedTaxe: {
                            ...state.selectedTaxe,
                            l5orl7base: toNumber(+state.selectedTaxe.cdapbase),
                            l5orl7rate: toNumber(+state.selectedTaxe.cdaprate),
                            l5orl7principal: toNumber(+state.selectedTaxe.cdapprincipale),
                            l5orl7penality: toNumber(+state.selectedTaxe.cdappenality),
                            l5orl7total: toNumber(+state.selectedTaxe.cdaptotal),
                        }
                    };
                }
                default: { return state; }
            }
        }
        case SWITCH_L22_OR_L23: {
            switch(action.payload){
                case 'l22': {
                    return {
                        ...state, 
                        selectedTaxe: {
                            ...state.selectedTaxe,
                            l29: toNumber(+state.selectedTaxe.l22),
                        }
                    };
                }
                case 'l23': {
                    return {
                        ...state, 
                        selectedTaxe: {
                            ...state.selectedTaxe,
                            l29: toNumber(+state.selectedTaxe.l23),
                        }
                    };
                }
                default: { return state; }
            }
        }
        case ACTION_TAB_FIVE: {
            return {
                ...state,
                selectedTaxe: {
                    ...state.selectedTaxe,
                    l23: toNumber(+state.selectedTaxe.l16 * +state.selectedTaxe.l22),
                    l28: toNumber(+state.selectedTaxe.ottntotal + +state.selectedTaxe.mdatotal + +state.selectedTaxe.aottotal),
                    l30: toNumber(+state.selectedTaxe.l24 + +state.selectedTaxe.l25),
                    l31: toNumber(+state.selectedTaxe.l26 + +state.selectedTaxe.l27),
                    l32: toNumber(+state.selectedTaxe.l28 - +state.selectedTaxe.l29 - +state.selectedTaxe.l30 + +state.selectedTaxe.l31),
                    l35: toNumber(+state.selectedTaxe.l33 - +state.selectedTaxe.l34),
                    // l39 (l36 + l37 + l38)
                    l39principal: toNumber(+state.selectedTaxe.l36principal + +state.selectedTaxe.l37principal + +state.selectedTaxe.l38principal),
                    l39cac: toNumber(+state.selectedTaxe.l36cac + +state.selectedTaxe.l37cac + +state.selectedTaxe.l38cac),
                    l39Penalties: toNumber(+state.selectedTaxe.l36Penalties + +state.selectedTaxe.l37Penalties + +state.selectedTaxe.l38Penalties),
                    l39total: toNumber(+state.selectedTaxe.l36total + +state.selectedTaxe.l37total + +state.selectedTaxe.l38total),
                    // l41 (total)
                    l41total: toNumber(
                        (+state.selectedTaxe.l41base + +state.selectedTaxe.l41principal + +state.selectedTaxe.l41cac + +state.selectedTaxe.l41penalties) +
                        (+state.selectedTaxe.l41base + +state.selectedTaxe.l41principal + +state.selectedTaxe.l41cac + +state.selectedTaxe.l41penalties) * (+state.selectedTaxe.l41rate / 100)
                    ),
                    //l44 (l40 + l41 + l42 + l43)
                    l44base: toNumber(+state.selectedTaxe.l40base + +state.selectedTaxe.l41base + +state.selectedTaxe.l42base + +state.selectedTaxe.l43base),
                    l44principal: toNumber(+state.selectedTaxe.l40principal + +state.selectedTaxe.l41principal + +state.selectedTaxe.l42principal + +state.selectedTaxe.l43principal),
                    l44cac: toNumber(+state.selectedTaxe.l40cac + +state.selectedTaxe.l41cac + +state.selectedTaxe.l42cac + +state.selectedTaxe.l43cac),
                    l44penalties: toNumber(+state.selectedTaxe.l40penalties + +state.selectedTaxe.l41penalties + +state.selectedTaxe.l42penalties + +state.selectedTaxe.l43penalties),
                    l44total: toNumber(+state.selectedTaxe.l40total + +state.selectedTaxe.l41total + +state.selectedTaxe.l42total + +state.selectedTaxe.l43total),
                }
            }
        }
        case ACTION_FORM_ONE: {
            return {
                ...state,
                selectedTaxe: {
                    ...state.selectedTaxe,
                    // l0
                    tsrbasetotal: toNumber(+state.selectedTaxe.tsrbase + (+state.selectedTaxe.tsrbase * +state.selectedTaxe.tsrrate / 100)),
                    tsrprincipaltotal: toNumber(+state.selectedTaxe.tsrprincipal + (+state.selectedTaxe.tsrprincipal * +state.selectedTaxe.tsrrate / 100)),
                    tsrpenalitytotal: toNumber(+state.selectedTaxe.tsrpenality  + (+state.selectedTaxe.tsrpenality * +state.selectedTaxe.tsrrate / 100)),
                    tsrtotals: toNumber(+state.selectedTaxe.tsrtotal  + (+state.selectedTaxe.tsrtotal * +state.selectedTaxe.tsrrate / 100)),
                    // l3 (l1 + l2)
                    daabase:  toNumber(+state.selectedTaxe.cagbase + +state.selectedTaxe.carbase),
                    daarate:  toNumber(+state.selectedTaxe.cagrate + +state.selectedTaxe.carrate),
                    daaprincipal:  toNumber(+state.selectedTaxe.cagprincipal + +state.selectedTaxe.carprincipal),
                    daapenality:  toNumber(+state.selectedTaxe.cagpenality + +state.selectedTaxe.carpenality),
                    daatotal:  toNumber(+state.selectedTaxe.cagtotal + +state.selectedTaxe.cartotal),
                    // l5 (l3 + l4)
                    darbase:  toNumber(+state.selectedTaxe.daabase + +state.selectedTaxe.dasbase),
                    darrate:  toNumber(+state.selectedTaxe.daarate + +state.selectedTaxe.dasrate),
                    darprincipal:  toNumber(+state.selectedTaxe.daaprincipal + +state.selectedTaxe.dasprincipal),
                    darpenality:  toNumber(+state.selectedTaxe.daapenality + +state.selectedTaxe.daspenality),
                    dartotal:  toNumber(+state.selectedTaxe.daatotal + +state.selectedTaxe.dastotal),
                    // l7 (l5 - l6)
                    cdapbase:  toNumber(+state.selectedTaxe.darbase),
                    cdaprate:  toNumber(+state.selectedTaxe.darrate),
                    cdapprincipale:  toNumber(+state.selectedTaxe.darprincipal - +state.selectedTaxe.dapiprincipal),
                    cdappenality:  toNumber(+state.selectedTaxe.darpenality),
                    cdaptotal:  toNumber(+state.selectedTaxe.dartotal),
                    // l9 (l5 + l7)
                    l5l7base:  toNumber(+state.selectedTaxe.darbase + +state.selectedTaxe.cdapbase),
                    l5l7rate:  toNumber(+state.selectedTaxe.darrate + +state.selectedTaxe.cdaprate),
                    l5l7principal:  toNumber(+state.selectedTaxe.darprincipal + +state.selectedTaxe.cdapprincipale),
                    l5l7penality:  toNumber(+state.selectedTaxe.darpenality + +state.selectedTaxe.cdappenality),
                    l5l7total:  toNumber(+state.selectedTaxe.dartotal + +state.selectedTaxe.cdaptotal),
                    // l15 (l10 + l11 + l12 + l13)
                    caghtca: toNumber(+state.selectedTaxe.ottnca + +state.selectedTaxe.mdaca + +state.selectedTaxe.aotca + +state.selectedTaxe.tvaetca),
                    caghttotal: toNumber(+state.selectedTaxe.ottntotal + +state.selectedTaxe.mdatotal + +state.selectedTaxe.aottotal + +state.selectedTaxe.tvaettotal),

                    // propagate changes to table 15
                    l81Principal: toNumber(+state.selectedTaxe.tsrprincipal + (+state.selectedTaxe.tsrprincipal * +state.selectedTaxe.tsrrate / 100)),
                    l81Penalties: toNumber(+state.selectedTaxe.tsrpenality  + (+state.selectedTaxe.tsrpenality * +state.selectedTaxe.tsrrate / 100)),
                }
            }
        }
        case SUM_TABS: {
            let sum = null;
            action.payload.tabs.forEach(elm => {
                if (elm){
                    sum += +state.selectedTaxe[elm];
                }
            });
            return {
                ...state,
                selectedTaxe: {
                    ...state.selectedTaxe,
                    [action.payload.destTab]: toNumber(sum),
                },
            };
        }
        case ONCHANGE_TABLE_8: {
        
            var valRateL46 = +state.selectedTaxe.l46Rate;
        
            let varTotalL45 = toNumber(+state.selectedTaxe.l45Base + +state.selectedTaxe.l45Principal + +state.selectedTaxe.l45Cac + +state.selectedTaxe.l45Penalties);
            let varTotalL46 = toNumber(+state.selectedTaxe.l46Base + +state.selectedTaxe.l46Principal + +state.selectedTaxe.l46Penalties) * (valRateL46/100);
            let varTotalL47 = toNumber(+state.selectedTaxe.l47Base + +state.selectedTaxe.l47Principal + +state.selectedTaxe.l47Penalties);
            let varTotalL48 = toNumber(+state.selectedTaxe.l48Base + +state.selectedTaxe.l48Principal + +state.selectedTaxe.l48Cac + +state.selectedTaxe.l48Penalties);
        
            let valL49Base = toNumber(+state.selectedTaxe.l45Base + +state.selectedTaxe.l46Base + +state.selectedTaxe.l47Base + +state.selectedTaxe.l48Base);
            let valL49Principal = toNumber(+state.selectedTaxe.l45Principal + +state.selectedTaxe.l46Principal + +state.selectedTaxe.l47Principal + +state.selectedTaxe.l48Principal);
            let valL49Cac = toNumber(+state.selectedTaxe.l45Cac + +state.selectedTaxe.l48Cac);
            let valL49Penalties = toNumber(+state.selectedTaxe.l45Penalties + +state.selectedTaxe.l46Penalties + +state.selectedTaxe.l47Penalties + +state.selectedTaxe.l48Penalties);
            let valL49Total =  toNumber(+varTotalL45 + +varTotalL46 + +varTotalL47 + +varTotalL48);
            return {
                ...state,
                selectedTaxe: {
                    ...state.selectedTaxe,
                    l45Total: varTotalL45,
                    l46Total: varTotalL46,
                    l47Total: varTotalL47,
                    l48Total: varTotalL48,
                
                    l49Base: valL49Base,
                    l49Principal: valL49Principal,
                    l49Cac: valL49Cac,
                    l49Penalties: valL49Penalties,
                    l49Total: valL49Total,
                }
            }
        }
        case ONCHANGE_TABLE_9: {
            var valRateL50 = toNumber(+state.selectedTaxe.l50Rate);
            let varTotalL50 = toNumber(+state.selectedTaxe.l50Base + +state.selectedTaxe.l50Principal + +state.selectedTaxe.l50Cac + +state.selectedTaxe.l50Penalties) * (valRateL50/100);
            let varTotalL51 = toNumber(+state.selectedTaxe.l51Base + +state.selectedTaxe.l51Principal + +state.selectedTaxe.l51Cac + +state.selectedTaxe.l51Penalties);
            let varTotalL52 = toNumber(+state.selectedTaxe.l52Base + +state.selectedTaxe.l52Principal + +state.selectedTaxe.l52Cac + +state.selectedTaxe.l52Penalties);
            let varTotalL53 = toNumber(+state.selectedTaxe.l53Base + +state.selectedTaxe.l53Principal + +state.selectedTaxe.l53Cac + +state.selectedTaxe.l53Penalties);
            let varTotalL54 = toNumber(+state.selectedTaxe.l54Base + +state.selectedTaxe.l54Principal + +state.selectedTaxe.l54Cac + +state.selectedTaxe.l54Penalties);
        
            let valBaseL54 = toNumber(+state.selectedTaxe.l50Base + +state.selectedTaxe.l51Base) - (+state.selectedTaxe.l52Base + +state.selectedTaxe.l53Base);
            let valPrincipalL54 = toNumber(+state.selectedTaxe.l50Principal + +state.selectedTaxe.l51Principal) - (+state.selectedTaxe.l52Principal + +state.selectedTaxe.l53Principal);
            let valCacL54 = toNumber(+state.selectedTaxe.l50Cac + +state.selectedTaxe.l51Cac) - (+state.selectedTaxe.l52Cac + +state.selectedTaxe.l53Cac);
            let valPenaltiesL54 = toNumber(+state.selectedTaxe.l50Penalties + +state.selectedTaxe.l51Penalties) - (+state.selectedTaxe.l52Penalties + +state.selectedTaxe.l53Penalties);
            let valTotalL54 = toNumber(+state.selectedTaxe.l50Total + +state.selectedTaxe.l51Total) - (+state.selectedTaxe.l52Total + +state.selectedTaxe.l53Total);
        
            let valL55Base = valBaseL54 < 0 ? toNumber(valBaseL54) : 0;
            let valL55Principal = valPrincipalL54 < 0 ? toNumber(valPrincipalL54) : 0;
            let valL55Cac = valCacL54 < 0 ? toNumber(valCacL54) : 0;
            let valL55Penalties = valPenaltiesL54 < 0 ? toNumber(valPenaltiesL54) : 0;
            let valL55Total =  valTotalL54 < 0 ? toNumber(valTotalL54) : 0;
            return {
                ...state,
                selectedTaxe: {
                    ...state.selectedTaxe,
                    l50Total: varTotalL50,
                    l51Total: varTotalL51,
                    l52Total: varTotalL52,
                    l53Total: varTotalL53,
                    l54Total: varTotalL54,
                
                    l54Base: valBaseL54,
                    l54Principal: valPrincipalL54,
                    l54Cac: valCacL54,
                    l54Penalties: valPenaltiesL54,
                
                    l55Base: valL55Base,
                    l55Principal: valL55Principal,
                    l55Cac: valL55Cac,
                    l55Penalties: valL55Penalties,
                    l55Total: valL55Total,

                    l85Principal: valPrincipalL54,
                    l85Cac: valCacL54,
                    l85Penalties: valPenaltiesL54,
                }
            }
        }
        case ONCHANGE_TABLE_10: {
            let varTotalL56 = toNumber(+state.selectedTaxe.l56Base + +state.selectedTaxe.l56Principal + +state.selectedTaxe.l56Cac + +state.selectedTaxe.l56Penalties);
            let varTotalL57 = toNumber(+state.selectedTaxe.l57Base + +state.selectedTaxe.l57Principal + +state.selectedTaxe.l57Cac + +state.selectedTaxe.l57Penalties);
            let varTotalL58 = toNumber(+state.selectedTaxe.l58Base + +state.selectedTaxe.l58Principal + +state.selectedTaxe.l58Cac + +state.selectedTaxe.l58Penalties);
            let varTotalL59 = toNumber(+state.selectedTaxe.l59Base + +state.selectedTaxe.l59Principal + +state.selectedTaxe.l59Cac + +state.selectedTaxe.l59Penalties);
            let varTotalL60 = toNumber(+state.selectedTaxe.l60Base + +state.selectedTaxe.l60Principal + +state.selectedTaxe.l60Cac + +state.selectedTaxe.l60Penalties);
            let varTotalL61 = toNumber(+state.selectedTaxe.l61Base + +state.selectedTaxe.l61Principal + +state.selectedTaxe.l61Cac + +state.selectedTaxe.l61Penalties);
        
        
            let valL62Base = toNumber(+state.selectedTaxe.l56Base + +state.selectedTaxe.l57Base + +state.selectedTaxe.l58Base + +state.selectedTaxe.l59Base + +state.selectedTaxe.l60Base + +state.selectedTaxe.l61Base);
            let valL62Principal = toNumber(+state.selectedTaxe.l56Principal + +state.selectedTaxe.l57Principal + +state.selectedTaxe.l58Principal + +state.selectedTaxe.l59Principal + +state.selectedTaxe.l60Principal + +state.selectedTaxe.l61Principal);
            let valL62Cac = toNumber(+state.selectedTaxe.l56Cac + +state.selectedTaxe.l57Cac + +state.selectedTaxe.l58Cac + +state.selectedTaxe.l59Cac + +state.selectedTaxe.l60Cac + +state.selectedTaxe.l61Cac);
            let valL62Penalties = toNumber(+state.selectedTaxe.l56Penalties + +state.selectedTaxe.l57Penalties + +state.selectedTaxe.l58Penalties + +state.selectedTaxe.l59Penalties + +state.selectedTaxe.l60Penalties + +state.selectedTaxe.l61Penalties);
            let valL62Total =  toNumber(+varTotalL56 + +varTotalL57 + +varTotalL58 + +varTotalL59 + +varTotalL60 + +varTotalL61);
        
            return {
                ...state,
                selectedTaxe: {
                    ...state.selectedTaxe,
                    l56Total: varTotalL56,
                    l57Total: varTotalL57,
                    l58Total: varTotalL58,
                    l59Total: varTotalL59,
                    l60Total: varTotalL60,
                    l61Total: varTotalL61,

                    l62Base: valL62Base,
                    l62Principal: valL62Principal,
                    l62Cac: valL62Cac,
                    l62Penalties: valL62Penalties,
                    l62Total: valL62Total,

                    // update table 15
                    l86Principal: valL62Principal,
                    l86Cac: valL62Cac,
                    l86Penalties: valL62Penalties,
                },
            };
        }
        
        case ONCHANGE_TABLE_11: {
            let varTotalL63 = toNumber(+state.selectedTaxe.l63Base + +state.selectedTaxe.l63Principal + +state.selectedTaxe.l63Cac + +state.selectedTaxe.l63Penalties);
            let varTotalL64 = toNumber(+state.selectedTaxe.l64Base + +state.selectedTaxe.l64Principal + +state.selectedTaxe.l64Cac + +state.selectedTaxe.l64Penalties);
            let varTotalL65 = toNumber(+state.selectedTaxe.l65Base + +state.selectedTaxe.l65Principal + +state.selectedTaxe.l65Cac + +state.selectedTaxe.l65Penalties);
            varTotalL63 = toNumber(varTotalL63 + (varTotalL63 * (+state.selectedTaxe.l63Rate/100)));
            varTotalL64 = toNumber(varTotalL64 + (varTotalL64 * (+state.selectedTaxe.l64Rate/100)));
            varTotalL65 = toNumber(varTotalL65 + (varTotalL65 * (+state.selectedTaxe.l65Rate/100)));
        
            let valL66Base = toNumber(+state.selectedTaxe.l63Base + +state.selectedTaxe.l64Base + +state.selectedTaxe.l65Base);
            let valL66Rate = toNumber(+state.selectedTaxe.l63Rate + +state.selectedTaxe.l64Rate + +state.selectedTaxe.l65Rate);
            let valL66Principal = toNumber(+state.selectedTaxe.l63Principal + +state.selectedTaxe.l64Principal + +state.selectedTaxe.l65Principal);
            let valL66Cac = toNumber(+state.selectedTaxe.l63Cac + +state.selectedTaxe.l64Cac + +state.selectedTaxe.l65Cac);
            let valL66Penalties = toNumber(+state.selectedTaxe.l63Penalties + +state.selectedTaxe.l64Penalties + +state.selectedTaxe.l65Penalties);
            let valL66Total = toNumber(+varTotalL63 + +varTotalL64 + +varTotalL65);
        
            return {
                ...state,
                selectedTaxe: {
                    ...state.selectedTaxe,
                    l63Total: varTotalL63,
                    l64Total: varTotalL64,
                    l65Total: varTotalL65,
        
                    l66Base: valL66Base,
                    l66Rate: valL66Rate + '%',
                    l66Principal: valL66Principal,
                    l66Cac: valL66Cac,
                    l66Penalties: valL66Penalties,
                    l66Total: valL66Total,

                    l87Principal: valL66Principal,
                    l87Cac: valL66Cac,
                    l87Penalties: valL66Penalties,
                },
            };
                
        }
        
        case ONCHANGE_TABLE_12: {
        
            let varTotalL67 = toNumber(+state.selectedTaxe.l67Base + +state.selectedTaxe.l67Principal + +state.selectedTaxe.l67Cac + +state.selectedTaxe.l67Penalties);
            let varTotalL68 = toNumber(+state.selectedTaxe.l68Base + +state.selectedTaxe.l68Principal + +state.selectedTaxe.l68Penalties);
            let varTotalL69 = toNumber(+state.selectedTaxe.l69Base + +state.selectedTaxe.l69Principal + +state.selectedTaxe.l69Penalties);
            let varTotalL70 = toNumber(+state.selectedTaxe.l70Base + +state.selectedTaxe.l70Principal + +state.selectedTaxe.l70Penalties);
            let varTotalL71 = toNumber(+state.selectedTaxe.l71Base + +state.selectedTaxe.l71Principal + +state.selectedTaxe.l71Penalties);
            let varTotalL72 = toNumber(+state.selectedTaxe.l72Base + +state.selectedTaxe.l72Principal + +state.selectedTaxe.l72Penalties);
        
            let valL73Base = toNumber(+state.selectedTaxe.l67Base + +state.selectedTaxe.l68Base + +state.selectedTaxe.l69Base + +state.selectedTaxe.l70Base + +state.selectedTaxe.l71Base + +state.selectedTaxe.l72Base);
            let valL73Principal = toNumber(+state.selectedTaxe.l67Principal + +state.selectedTaxe.l68Principal + +state.selectedTaxe.l69Principal + +state.selectedTaxe.l70Principal + +state.selectedTaxe.l71Principal + +state.selectedTaxe.l72Principal);
            let valL73Cac = toNumber(+state.selectedTaxe.l67Cac);
            let valL73Penalties = toNumber(+state.selectedTaxe.l67Penalties + +state.selectedTaxe.l68Penalties + +state.selectedTaxe.l69Penalties + +state.selectedTaxe.l70Penalties + +state.selectedTaxe.l71Penalties + +state.selectedTaxe.l72Penalties);
            let valL73Total = toNumber(+varTotalL67 + +varTotalL68 + +varTotalL69 + +varTotalL70 + +varTotalL71 + +varTotalL72);
        
            return {
                ...state,
                selectedTaxe: {
                    ...state.selectedTaxe,
                    l67Total: varTotalL67,
                    l68Total: varTotalL68,
                    l69Total: varTotalL69,
                    l70Total: varTotalL70,
                    l71Total: varTotalL71,
                    l72Total: varTotalL72,
        
                    l73Base: valL73Base,
                    l73Principal: valL73Principal,
                    l73Cac: valL73Cac,
                    l73Penalties: valL73Penalties,
                    l73Total: valL73Total,

                    l88Principal: valL73Principal,
                    l88Cac: valL73Cac,
                    l88Penalties: valL73Penalties,
                },
            };    
        }
        case ONCHANGE_TABLE_13: {

            let varPaymentTaxesL74 = toNumber(+state.selectedTaxe.l74TransferPrice + +state.selectedTaxe.l74NetBookValue + +state.selectedTaxe.l74CapitalGainsOrLosses) * (+state.selectedTaxe.l74Rate/100);
            let varPaymentTaxesL75 = toNumber(+state.selectedTaxe.l75TransferPrice + +state.selectedTaxe.l75NetBookValue + +state.selectedTaxe.l75CapitalGainsOrLosses) * (+state.selectedTaxe.l75Rate/100);;
            let varPaymentTaxesL76 = toNumber(+state.selectedTaxe.l76TransferPrice + +state.selectedTaxe.l76DeductibleExpense + +state.selectedTaxe.l76CapitalGainsOrLosses) * (+state.selectedTaxe.l76Rate/100);;

            let valL77TransferPrice = toNumber(+state.selectedTaxe.l74TransferPrice + +state.selectedTaxe.l75TransferPrice + +state.selectedTaxe.l76TransferPrice);
            let valL77DeductibleExpense = toNumber(+state.selectedTaxe.l74NetBookValue + +state.selectedTaxe.l75NetBookValue + +state.selectedTaxe.l76DeductibleExpense);
            let valL77CapitalGainsOrLosses = toNumber(+state.selectedTaxe.l74CapitalGainsOrLosses + +state.selectedTaxe.l75CapitalGainsOrLosses + +state.selectedTaxe.l76CapitalGainsOrLosses);
            let valL77Rate = toNumber(+state.selectedTaxe.l74Rate + +state.selectedTaxe.l75Rate + +state.selectedTaxe.l76Rate);
            
            let valL77PaymentTaxes = toNumber(+varPaymentTaxesL74 + +varPaymentTaxesL75 + +varPaymentTaxesL76);

            return {
                ...state,
                selectedTaxe: {
                    ...state.selectedTaxe,
                    l74PaymentTaxes: varPaymentTaxesL74,
                    l75PaymentTaxes: varPaymentTaxesL75,
                    l76PaymentTaxes: varPaymentTaxesL76,

                    l77TransferPrice: valL77TransferPrice,
                    l77DeductibleExpense: valL77DeductibleExpense,
                    l77CapitalGainsOrLosses: valL77CapitalGainsOrLosses,
                    l77Rate: valL77Rate,
                    l77PaymentTaxes: valL77PaymentTaxes,

                    l89Principal: valL77TransferPrice,
                    l89Cac: valL77DeductibleExpense,
                    l89Penalties: valL77CapitalGainsOrLosses,
                },
            };
                
        }

        case ONCHANGE_TABLE_14: {

            let varTotalL78 = toNumber(+state.selectedTaxe.l78NumberOfPassengers + +state.selectedTaxe.l78Rate + +state.selectedTaxe.l78Principal + +state.selectedTaxe.l78Penalties);
            let varTotalL79 = toNumber(+state.selectedTaxe.l79NumberOfPassengers + +state.selectedTaxe.l79Rate + +state.selectedTaxe.l79Principal + +state.selectedTaxe.l79Penalties);

            let valL80NumberOfPassengers = toNumber(+state.selectedTaxe.l78NumberOfPassengers + +state.selectedTaxe.l79NumberOfPassengers);
            let valL80Rate = toNumber(+state.selectedTaxe.l78Rate + +state.selectedTaxe.l79Rate);
            let valL80Principal = toNumber(+state.selectedTaxe.l78Principal + +state.selectedTaxe.l79Principal);
            let valL80Penalties = toNumber(+state.selectedTaxe.l78Penalties + +state.selectedTaxe.l79Penalties);
            let valL80Total = toNumber(+varTotalL78 + +varTotalL79);

            return {
                ...state,
                selectedTaxe: {
                    ...state.selectedTaxe,
                    l78Total: varTotalL78,
                    l79Total: varTotalL79,

                    l80NumberOfPassengers: valL80NumberOfPassengers,
                    l80Rate: valL80Rate,
                    l80Principal: valL80Principal,
                    l80Penalties: valL80Penalties,
                    l80Total: valL80Total,

                    l90Principal: valL80Principal,
                    l90Penalties: valL80Penalties,
                },
            };
                
        }

        case ONCHANGE_TABLE_15: {
            let varL82Principal = null;
            let varL82Penalties = null;

            let varTotalL81 = toNumber(+state.selectedTaxe.l81Principal + +state.selectedTaxe.l81Cac + +state.selectedTaxe.l81Penalties);
            let varTotalL82 = toNumber(+state.selectedTaxe.l82Principal + +state.selectedTaxe.l82Cac + +state.selectedTaxe.l82Penalties);

            if (+state.selectedTaxe.l82Rate === 9) {
                varL82Principal = toNumber(+state.selectedTaxe.l5l7principal);
                varL82Penalties = toNumber(+state.l5l7penality.l5orl7penality);
                varTotalL82 =  toNumber(+varL82Principal + +state.selectedTaxe.l82Cac + +varL82Penalties);
            } else {
                varL82Principal = toNumber(+state.selectedTaxe.l5orl7principal);
                varL82Penalties = toNumber(+state.selectedTaxe.l5orl7penality);
                varTotalL82 = toNumber(+varL82Principal + +state.selectedTaxe.l82Cac + +varL82Penalties);
            }

            let varTotalL83 = toNumber(+state.selectedTaxe.l83Principal + +state.selectedTaxe.l83Cac + +state.selectedTaxe.l83Penalties);
            let varTotalL84 = toNumber(+state.selectedTaxe.l84Principal + +state.selectedTaxe.l84Cac + +state.selectedTaxe.l84Penalties);
            let varTotalL85 = toNumber(+state.selectedTaxe.l85Principal + +state.selectedTaxe.l85Cac + +state.selectedTaxe.l85Penalties);
            let varTotalL86 = toNumber(+state.selectedTaxe.l86Principal + +state.selectedTaxe.l86Cac + +state.selectedTaxe.l86Penalties);
            let varTotalL87 = toNumber(+state.selectedTaxe.l87Principal + +state.selectedTaxe.l87Cac + +state.selectedTaxe.l87Penalties);
            let varTotalL88 = toNumber(+state.selectedTaxe.l88Principal + +state.selectedTaxe.l88Cac + +state.selectedTaxe.l88Penalties);
            let varTotalL89 = toNumber(+state.selectedTaxe.l89Principal + +state.selectedTaxe.l89Cac + +state.selectedTaxe.l89Penalties);
            let varTotalL90 = toNumber(+state.selectedTaxe.l90Principal + +state.selectedTaxe.l90Cac + +state.selectedTaxe.l90Penalties);

            let valL91Principal = toNumber(+state.selectedTaxe.l81Principal + +state.selectedTaxe.l82Principal + +state.selectedTaxe.l83Principal + +state.selectedTaxe.l84Principal + +state.selectedTaxe.l85Principal 
                + +state.selectedTaxe.l86Principal + +state.selectedTaxe.l87Principal + +state.selectedTaxe.l88Principal + +state.selectedTaxe.l89Principal + +state.selectedTaxe.l90Principal);
            let valL91Cac = toNumber(+state.selectedTaxe.l81Cac + +state.selectedTaxe.l82Cac + +state.selectedTaxe.l83Cac + +state.selectedTaxe.l84Cac + +state.selectedTaxe.l85Cac 
                + +state.selectedTaxe.l86Cac + +state.selectedTaxe.l87Cac + +state.selectedTaxe.l88Cac + +state.selectedTaxe.l89Cac + +state.selectedTaxe.l90Cac);
            let valL91Penalties = toNumber(+state.selectedTaxe.l81Penalties + +state.selectedTaxe.l82Penalties + +state.selectedTaxe.l83Penalties + +state.selectedTaxe.l84Penalties + +state.selectedTaxe.l85Penalties 
                + +state.selectedTaxe.l86Penalties + +state.selectedTaxe.l87Penalties + +state.selectedTaxe.l88Penalties + +state.selectedTaxe.l89Penalties + +state.selectedTaxe.l90Penalties);

            let valL91Total = toNumber(+varTotalL81 + +varTotalL82 + +varTotalL83 + +varTotalL84 + +varTotalL85 + +varTotalL86 + +varTotalL87 + +varTotalL88 + +varTotalL89 + +varTotalL90);

            return {
                ...state,
                selectedTaxe: {
                    ...state.selectedTaxe,
                    l82Principal: varL82Principal,
                    l82Penalties: varL82Penalties,

                    l81Total: varTotalL81,
                    l82Total: varTotalL82,
                    l83Total: varTotalL83,
                    l84Total: varTotalL84,
                    l85Total: varTotalL85,
                    l86Total: varTotalL86,
                    l87Total: varTotalL87,
                    l88Total: varTotalL88,
                    l89Total: varTotalL89,
                    l90Total: varTotalL90,
                    l91Total: valL91Total,

                    l91Principal: valL91Principal,
                    l91Cac: valL91Cac,
                    l91Penalties: valL91Penalties
                },
            };
                
        }

        case ONCHANGE_FOOTER: {
            return {
                ...state,
                selectedTaxe: {
                    ...state.selectedTaxe,
                    declarationDate: action.payload.date,
                },
            };
        }

        case SET_CNPS_DECLARATION_PERIODS: {
            return {
                ...state,
                cnpsDeclarations: action.payload,
            }
        }

        case ADD_CNPS_DECLARATION_PERIODS: {
            return {
                ...state,
                cnpsDeclarations: [
                    action.payload,
                    ...state.cnpsDeclarations,
                ]
            }
        }

        case ADD_MORE_CNPS_DECLARATION_PERIODS: {
            let {cnpsDeclarations} = state;
            cnpsDeclarations.concat(action.payload);
            return {
                ...state,
                cnpsDeclarations,
            }
        }

        case UPDATE_CNPS_DECLARATION_PERIODS: {
            const i = state.cnpsDeclarations.findIndex(elm => +elm.id === +action.payload.id );
            state.cnpsDeclarations[i] = action.payload;
            return state;
        }

        case UPDATE_CNPS_DECLARATION_PERIODS_DETAILS: {
            const i = state.cnpsDeclarations.details.findIndex(elm => +elm.id === +action.payload.id );
            state.cnpsDeclarations[i] = action.payload;
            return state;
        }

        case DELETE_CNPS_DECLARATION_PERIODS: {
            const i = state.cnpsDeclarations.findIndex(elm => +elm.id === +action.payload );
            state.cnpsDeclarations.splice(i, 1);
            return state;
        }

        case SELECT_CNPS_DECLARATION_PERIODS: {
            return { ...state, selectedCnpsDeclaration: action.payload, }
        }

        default: { return state; }
    }
}

export default TaxesReducer;