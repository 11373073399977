import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setPage } from "../../redux/action/TaxesAction";
import "./steps-button.css";

// #region component
const propTypes = {
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            position: PropTypes.number,
            name: PropTypes.string
        }),
    ),
    activeStep: PropTypes.number,
    defaultBtnColor: PropTypes.string,
    activeBtnColor: PropTypes.string,
    visitedBtnColor: PropTypes.string,
    defaultBtnBg: PropTypes.string,
    activeBtnBg: PropTypes.string,
    visitedBtnBg: PropTypes.string,
    className: PropTypes.string,
    defaultBtnClassName: PropTypes.string,
    activeBtnClassName: PropTypes.string,
    visitedBtnClassName: PropTypes.string,
};

const defaultProps = {
    steps: [
        {
            position: 1,
            name: '',
        },
        {
            position: 2,
            name: '',
        },
        {
            position: 3,
            name: '',
        },
        {
            position: 4,
            name: '',
        }
    ],
    activeStep: 1,
    defaultBtnColor: '#B5B5B5',
    activeBtnColor: '#FFFFFF',
    visitedBtnColor: '#09B6CC',
    defaultBtnBg: '#FFFFFF',
    activeBtnBg: '#09B6CC',
    visitedBtnBg: '#FFFFFF',
    className: '',
    defaultBtnClassName: '',
    activeBtnClassName: '',
    visitedBtnClassName: '',
};

/**
 * 
 */
class StepsButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeStep: 1,
        };
    }

    componentDidMount(){
        this.init();
    }

    init = () => {
        this.props.setPage(this.props.activeStep || 1)
    }

    renderClassName = (currentStep) => {
        const activeStep = this.props.page || 1;
        if(+currentStep < +activeStep){
            return 'visited';
        }else if(+currentStep === +activeStep){
            return 'active';
        }else{
            return '';
        }
    }

    setActiveStep = (step) => {
        this.props.setPage(+step);
        if(this.props.onSelect){
            this.props.onSelect(+step);
        }
    }

    render() {
        return (
            <div className="steps-content">
                {this.props.steps.map((item, idx) => {
                    return (
                        <React.Fragment>
                            <button 
                                key={idx} 
                                type="button" 
                                className={`btn-step ${this.renderClassName(item.position)}`} 
                                style={{
                                    "--default-btncolor": this.props.defaultBtnColor, 
                                    "--default-btnbg": this.props.defaultBtnBg,
                                    "--active-btncolor": this.props.activeBtnColor,
                                    "--active-btnbg": this.props.activeBtnBg,
                                    "--visited-btncolor": this.props.visitedBtnColor,
                                    "--visited-btnbg": this.props.visitedBtnBg,
                                }} 
                                onClick={() => this.setActiveStep(item.position)}
                            >
                                <span className="step-position">
                                    {item.position}
                                </span>
                            </button>
                            {item.name ? <div className="step-name" style={{ "--txt-color": this.props.defaultBtnColor, }}>{item.name}</div> : <></>}
                        </React.Fragment>
                    )
                })}
            </div>
        );
    }
}

StepsButton.propTypes = propTypes;
StepsButton.defaultProps = defaultProps;
// #endregion

const mapStateToProps = (state) => {
    return {
        page: state.taxes.page,
    }
}

export default connect(mapStateToProps, { setPage })(StepsButton);