import React from "react";

const IoniconsSvgIosArrowDownComponent = (props) => (
  <svg width={9.434} height={5.394} viewBox="0 0 9.434 5.394" {...props}>
    <path
      fill="#b5b5b5"
      d="M92.719,163.718l3.567-3.57a.671.671,0,0,1,.952,0,.68.68,0,0,1,0,.955L93.2,165.148a.673.673,0,0,1-.93.02l-4.07-4.062a.674.674,0,0,1,.952-.955Z"
      transform="translate(-88 -159.95)"
    />
  </svg>
);

export default IoniconsSvgIosArrowDownComponent;
