import React, { Component } from 'react'
import DashboardLayout from '../Dashboard/DashboardLayout'
import BillsContent from './BillsContent'

export default class Bills extends Component {
    render() {
        return(
            <DashboardLayout title={'Facturation'}>
                <BillsContent {...this.props}/>
            </DashboardLayout>
        )
    }
}