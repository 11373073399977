import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import EditableInput from '../EditableInput';
import IconDate from '../Icons/DateIcon';
import IconChild from '../Icons/ChildIcon';
import './CardPreview.css';

class CardPreview extends Component {

    constructor(props){
        super(props);
        this.state = {};

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick = (event) => {
        const staffChild = {
            index: this.props.index,
            name: this.props.name,
            surname: this.props.surname,
            birthDate: this.props.birthDate,
        }
        event = {
            ...event,
            staffChild
        }
        if(this.props.onClick){
            this.props.onClick(event);
        }
    }

    render(){
        
        return(
            <div className="row card-preview" onClick={this.handleClick}>
                <div className="col-md-6 col-sm-12">
                    <div className="label-input">{`Nom & prénom`}</div>
                    <EditableInput type="text" name="chilename" value={`${this.props.name} ${this.props.surname}`} className="ml-2 small bold" state={'preview'} withIcon={<IconChild active />} />
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="label-input">{`Né le`}</div>
                    <EditableInput type="text" name="birthclild" value={this.props.birthDate} className="ml-2 small bold" state={'preview'} withIcon={<IconDate active />} />
                </div>
            </div>
        )
    }
}

CardPreview.propTypes = {
    fullName: PropTypes.string,
    birthDate: PropTypes.string,
    onClick: PropTypes.func
}

export default CardPreview;