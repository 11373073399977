import { SET_USER, ADD_USER, UPDATE_USER ,DELETE_USER, SELECT_USER } from '../action-type/userType';

const INITIAL_STATE = {
    users: [],
    selectedUser: {},
};

const UserReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SET_USER: {
            return { ...state, users: action.payload };
        }
        case ADD_USER: {
            const {users} = state;
            users.unshift(action.payload);
            return { ...state, users };
        }
        case UPDATE_USER: {
            const {users} = state;
            const selectedIndex = users.findIndex((element) => { return element.id === action.payload.id});
            if(selectedIndex >= 0){
                users[selectedIndex] = action.payload;
            }
            return { ...state, users };
        }
        case DELETE_USER: {
            const {users} = state;
            const index = users.findIndex((element) => { return element.id === action.payload});
            users.splice(index, 1);
            return { ...state, users };
        }
        case SELECT_USER: {
            return { ...state, selectedUser: action.payload };
        }
        default: { return state; }
    }
}

export default UserReducer;