/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const PathComponent = (props) => (
  <svg width={props.width ? props.width : 8.96} height={props.height ? props.height : 8.96} className={props.className ? props.className : "icon-plus-comp" } style={props.style ? props.style : {position: "relative"}} viewBox="0 0 8.96 8.96">
    <path
      fill={ props.color ? props.color : "#ffffff" }
      d="M10.56-6.62H6.58v3.98h-1V-6.62H1.6v-1H5.58V-11.6h1v3.98h3.98Z"
      transform="translate(-1.6 11.6)"
    />
  </svg>
);

export default PathComponent;
