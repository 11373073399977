import React, { Component } from 'react'
import { connect } from "react-redux";
import Search from '../Search'
import CustomInputFile from '../CustomInputFile';
import './SearchBankRecord.css';
import { setSelectedBankAccount, setLoaderImportBankStatement } from "../../redux/action/bankAction";
import Logger from '../../helpers/Logger';
import { _uploadBankStatement } from '../../services/Statement';
import { _getOrganizationAccounts } from '../../services/Organization';
import ListBankAccounts from "../ListBankAccounts";
import IconLink from '../Icons/IconLink';
import IconUnlink from '../Icons/IconUnlink';
import { errorNotifcation, infoNotification } from "../../helpers/errorHandle";
import { SESSION_STORAGE_KEYS } from "../../constants/constants";
import Loader from '../Loader';

class SearchBankRecord extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errorMsg: "",
            error: false,
            accounts: [],
        }
        this.cif = React.createRef();
    }
    componentDidMount() {
        this.props.onRef(this);
        if (this.props.displayAccounts) this.getAccounts();
    }
    componentWillUnmount = () => {
        this.props.onRef(undefined);
    }

    handleSearch = (term) => {
        this.props.handleSearch(term);
    }

    setRefCustomInputFile = (ref) => {
        this.customInputFile = ref;
    }

    uploadBanStatement = async (formData, options) => {
        if (this.props.closeModal) this.props.closeModal();
        try {
            const defaultOrg = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
            await _uploadBankStatement(defaultOrg.id, formData, options);
        } catch (err) {
            const error = { ...err };
            Logger.error("UploadBanStatement", "Error occured when uploading bank statement", error);
            infoNotification(error?.response?.status, "Nous traitons toujours votre requête, veuillez patientez.");
        }
    }

    reloadBankStatements = (account) => {
        if (this.props.reloadBankStatements) this.props.reloadBankStatements(account?.id);
    }

    getAccounts = () => {
        let defaultOrg = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));

        if (defaultOrg.id){
            _getOrganizationAccounts(defaultOrg.id).then(result => {
                this.setState({
                    accounts : result.data
                });
            }).catch(error => {
                Logger.error("BANK ACCOUNTS", `Error during get bank accounts of ${defaultOrg.id}`, error);
                errorNotifcation(error?.response?.status, "Impossible de récupérer la liste des comptes bancaires, veuillez reessayer ultérieurement.");
            })
        }
    }

    getReconciledBankRecords = () => {
        if (this.props.getReconciled) {
            this.props.getReconciled();
        }
    };

    getUnreconciledBankRecords = () => {
        if (this.props.getUnreconciled) {
            this.props.getUnreconciled();
        }
    };

    render() {
        const MyCustomInputFile = React.forwardRef((props, ref) => <CustomInputFile innerRef={ref} {...props} />);
        return (
            <div className={'search-bankRecord'}>
                <div className="bank-import">
                    {
                        this.props.import ?
                        <MyCustomInputFile onRef={this.setRefCustomInputFile} className="sm-hidden bg-gray" ref={this.cif} afterUpload={this.uploadBanStatement} reset={this.state.error} maxWidth={this.props.displayAccounts ? "100px" : "" }/> : null
                    }
                    {this.props.isLoading ? <Loader style={{width: "25px", height: "25px"}} enable /> : <></>}
                    {
                        this.props.reconcilitaionFilter ?
                        <div className="d-flex align-items-center reconciliation-filter">
                            <button className={`btn rounded-circle bg-white border size-30 p-1 d-flex align-items-center justify-content-center mr-2 ${this.props.isGetReconciled ? 'bg-light-gray' : ''}`} onClick={this.getReconciledBankRecords}>
                                <IconLink width={15} height={40} />
                            </button>
                            <button className={`btn rounded-circle bg-white border size-30 p-1 d-flex align-items-center justify-content-center mr-2 ${this.props.isGetUnreconciled ? 'bg-light-gray' : ''}`} onClick={this.getUnreconciledBankRecords}>
                                <IconUnlink width={19} height={19} />
                            </button>
                        </div> : null
                    }
                    {
                        this.props.displayAccounts ? 
                        <div className="btn-bank-account">
                            <ListBankAccounts data={this.state.accounts} onClick={this.reloadBankStatements} />
                        </div> : null
                    }
                </div>
                <Search handleSearch={this.handleSearch} iconClass="search-bankRecords" />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        selectedAccount: state.accounts.selectedAccount,
        loaderImportBankStatement: state.bank.loaderImportBankStatement,
    }
}

export default connect(mapStateToProps, { setSelectedBankAccount, setLoaderImportBankStatement })(SearchBankRecord)