import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import CnpsList from "./CnpsList";
import CnpsPageViews from './CnpsPageViews';

export default class CnpsViews extends Component {
    constructor(props){
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <>
                <Route exact path={this.props.match.url} render={() => {
                    return <CnpsList {...this.props} />
                }} />
                <Route path={this.props.match.url+'/:view'} component={CnpsPageViews} />
            </>
        );
    }
}