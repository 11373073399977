/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const GroupComponent = (props) => (
  <svg width={props.width ? props.width : 18.292} height={props.height ? props.height : 16.629} viewBox="0 0 18.292 16.629">
    <defs>
      <style>
        {
          `.sea,.seb{fill:${props.active ? '#177bd1;' : '#b5b5b5;'} stroke:#fff;}.sea{stroke-width:0.2px;}.seb{stroke-width:0.1px;}.sec,.see{fill:none;}.sec{stroke:${props.active ? '#177bd1;' : '#b5b5b5;'} stroke-linecap:round;stroke-linejoin:round;stroke-width:0.4px;}.sed{stroke:none;}`
        }
      </style>
    </defs>
    <g transform="translate(-8.322 -7.222)">
      <g className="sea" transform="translate(8.322 7.222)">
        <rect className="sed" width={14.134} height={4.157} rx={1} />
        <rect
          className="see"
          x={0.1}
          y={0.1}
          width={13.934}
          height={3.957}
          rx={0.9}
        />
      </g>
      <g className="sea" transform="translate(8.322 15.536)">
        <rect className="sed" width={14.134} height={4.157} rx={1} />
        <rect
          className="see"
          x={0.1}
          y={0.1}
          width={13.934}
          height={3.957}
          rx={0.9}
        />
      </g>
      <g className="seb" transform="translate(9.154 19.693)">
        <rect className="sed" width={14.134} height={4.157} rx={1} />
        <rect
          className="see"
          x={0.05}
          y={0.05}
          width={14.034}
          height={4.057}
          rx={0.95}
        />
      </g>
      <g className="sec" transform="translate(12.48 11.379)">
        <rect className="sed" width={14.134} height={4.157} rx={1} />
        <rect
          className="see"
          x={0.2}
          y={0.2}
          width={13.734}
          height={3.757}
          rx={0.8}
        />
      </g>
    </g>
  </svg>
);

export default GroupComponent;
