import { SET_AUTH_DATA, SET_CURRENT_AUTH, SET_AUTH_TOKEN, GET_ROLE, SET_SOCKET_SERVICE } from "../action-type/authType";
import { _extractPayloadFromToken } from '../../helpers/jwt';

const INITIAL_STATE = {
    authData: null,
    currentAuth: null,
    authToken: null,
    roles: [],
    socket: null,
};

const AuthReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_AUTH_DATA : 
            return { ...state, authData: action.payload }; 
        case SET_CURRENT_AUTH : 
            return { ...state, currentAuth: action.payload };
        case SET_AUTH_TOKEN : 
            return { ...state, authToken: action.payload };
        case GET_ROLE : 
            ;
            const access_token = _extractPayloadFromToken(action.payload.token);
            if(access_token.resource_access.hasOwnProperty('dizen-customer-client')){
                const roles = access_token.resource_access['dizen-customer-client'].roles ;
                if(roles.find((element) => { return element === action.payload.role })){
                    return { ...state, roles: [ ...roles ] };
                } 
            }
            return { ...state };
        case SET_SOCKET_SERVICE:
            const res = { ...state, socket: action.payload }
            return res;
        default: 
            return state;
    };
};

export default AuthReducer;