import React, { Component } from 'react'
import IconJob from '../Icons/JobComponent';
import IconContrat from '../Icons/AgreementComponent';
import IconDate from '../Icons/DateIcon';
import EditableInput from '../EditableInput';
import IconDepart from '../Icons/DepartIcon';
import IconService from '../Icons/ServiceIcon';
import {updateStaff, selectStaff , __updateContract} from '../../redux/action/StaffAction';
import { _updateContrat } from '../../services/Contrat';
import moment from 'moment';
import {connect} from 'react-redux';
import {CONTRACT_TYPES} from '../../constants/ContractTypes'
import {Toggle} from "react-toggle-component";
import Logger from '../../helpers/Logger';
import { validationNotification } from "../../helpers/errorHandle";

class Contrat extends Component {
    constructor(props){
        super(props)
        this.state = {
            contratOptions: [{value: CONTRACT_TYPES.CDI}, {value: CONTRACT_TYPES.CDD}, {value: CONTRACT_TYPES.STAGE}],
            departureOptions:[{value: ''}, {value:'Abandon de poste'}, {value: 'Démission avec préavis'}, {value: 'Démission sans préavis'} ]
        }
        this.toggleRef = React.createRef();
    }

    updateContract = async (id, contractData) => {
        let defaultOrganization = JSON.parse(sessionStorage.getItem('defaultOrg'));
        if(contractData.contratType === CONTRACT_TYPES.CDI){
            contractData = {...contractData , endDate : null}
        }
        try {
            const contract = (await _updateContrat(defaultOrganization.id, this.props.data.staffId, id, contractData)).data;
            if(contract) {
                this.props.__updateContract(contract)
            }
        } catch (err) {
            const error = { ...err };
            Logger.error("UpdateContract", "Error occured when updating contract", error);
            validationNotification(error?.response?.status, error?.response?.data?.errors);
        }
    }

    render() {
        const { data } = this.props;
        data.nonContributingManager = data.nonContributingManager ? data.nonContributingManager : false;
        if(this.toggleRef && this.toggleRef.current) this.toggleRef.current.checked = data.nonContributingManager;
        return(
            <div className='px-0'>
                <div className="header-preview">
                    <div className="d-flex align-items-center">
                        <span className="ico-content pr-1"><IconJob width={20} height={20} active /></span>
                            <EditableInput type='text' name='poste' value={data.poste} className="header-preview-title pl-30-10 "
                                onBlur={event => this.updateContract(data.id, {poste: event.target.value})} 
                                state={'preview'} editable
                            /> 
                    </div>
                    <div className="d-flex align-items-center mt-1">
                        <span className="ico-content pr-1"></span>
                        <div className="label-input d-flex align-items-center">
                            <span className="ico-content"> <IconContrat active /> </span>
                            <EditableInput withClass='mr-2' type="select" name="contratType" value={data.contratType} options={this.state.contratOptions}
                                optionValue='value' className="pl-30-10" onBlur={event => this.updateContract(data.id, {contratType: event.target.value})} 
                                state={'preview'} editable />
                            <span className="labeled ml-2">{`Depuis ${data.seniorityInYears ? 
                                data.seniorityInYears + ' an(s) ' + data.seniorityInMonth +' mois': 
                                data.seniorityInMonth +' mois'}`}
                            </span> 
                        </div>
                    </div>
                </div>
                <div className="content-preview">
                    <div className="row pt-1 pl-3">
                        <div className="col-md-6 col-sm-12">
                            <div className="label-input">du </div>
                            <EditableInput type="date" name="StartDate" value={data.beginDate ? moment(data.beginDate).format('YYYY/MM/DD') : ''} className="pl-30-10" 
                                onBlur={event => this.updateContract(data.id, {beginDate: event.target.value, endDate: data.endDate})} 
                                state={'preview'} editable withIcon={<IconDate width={15} height={15} active />} />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="label-input">au </div>
                            <EditableInput type="date" name="departureDate" value={data.endDate ? moment(data.endDate).format('YYYY/MM/DD') : ''} className="pl-30-10" 
                                onBlur={event => this.updateContract(data.id, {endDate: event.target.value})} 
                                state={'preview'}  editable = {(data.contratType === CONTRACT_TYPES.CDI ? false : true)}  withIcon={<IconDate width={15} height={10.38} active />} />
                        </div>
                    </div>
                    <div className="row pt-1 pl-3 item-separator">
                        <div className="col-md-6 col-sm-12">
                            <div className="label-input">Service </div>
                            <EditableInput type="text" name="service" value={data.service} className="pl-30-10" 
                                onBlur={event => this.updateContract(data.id, {service: event.target.value})} 
                                state={'preview'} editable withIcon={<IconService width={15} height={15} active />} />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="label-input">Motif de depart </div>
                            <EditableInput withClass='mr-2' type="select" name="reasonOfDeparture" value={data.reasonOfDeparture} options={this.state.departureOptions}
                                optionValue='value' className="pl-30-10" onBlur={event => this.updateContract(data.id, {reasonOfDeparture: event.target.value})} 
                                state={'preview'} editable withIcon={<IconDepart width={20} height={20} active />} />
                            {/* <EditableInput type="text" name="departureOrigin" value={data.reasonOfDeparture} className="pl-30-10" 
                                onBlur={event => this.updateContract(data.id, {reasonOfDeparture: event.target.value})} 
                                state={'preview'} editable withIcon={<IconDepart width={20} height={20} active />} /> */}
                        </div>
                    </div>
                    <div className="row pt-1 pl-3 item-separator mb-1">
                        <div className="col-md-6 col-sm-12 d-flex align-items-center">
                            <div className="label-input">Non Contributing Manager</div>
                        </div>
                        <div>
                        <div className="col-md-6 col-sm-12 d-flex align-items-center">
                            <Toggle ref={this.toggleRef} className="m-0" name="toggle-contributing" radius="3px" radiusBackground="2px" knobRadius="2px"
                                borderWidth="1px" checked={data.nonContributingManager} value={data.nonContributingManager}
                                onToggle={event => this.updateContract(data.id, {nonContributingManager: event.target.checked})} />
                            </div>
                        </div>
                    </div>
                    <div className="item-separator"></div>
                    
                </div>
            </div>
        )
    }
}

export default connect(null, {updateStaff, __updateContract, selectStaff})(Contrat)