import React, { Component } from 'react'
import DashboardLayout from './DashboardLayout'

export default class Dashboard extends Component {
    constructor(props){
        super(props)

        this.state = { }
    }

    render() {
        return(
            <DashboardLayout title={'Tableau de bord'}>
                
            </DashboardLayout>
        )
    }
}
