/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const IoniconsSvgIosCloseComponent = (props) => (
  <svg width={props.width ? props.width : 10} height={props.height ? props.height : 9.997} onClick={props.onClick} viewBox="0 0 10 9.997">
    <defs>
      <style>{ ".closes{fill:#b5b5b5;}"}</style>
    </defs>
    <path
      fill={props.color ? props.color : '#707070'}
      className="close"
      d="M166.685,165.549l3.572-3.572a.837.837,0,1,0-1.184-1.184l-3.572,3.572-3.572-3.572a.837.837,0,1,0-1.184,1.184l3.572,3.572-3.572,3.572a.837.837,0,0,0,1.184,1.184l3.572-3.572,3.572,3.572a.837.837,0,1,0,1.184-1.184Z"
      transform="translate(-160.5 -160.55)"
    />
  </svg>
);

export default IoniconsSvgIosCloseComponent;
