import React, { Component } from 'react'
import './DateItemList.css'
import {formatDate} from "../../helpers/date"

class DateItemList extends Component {
    constructor(props){
        super(props)

        this.state = {
            date: this.props.date,
            active: this.props.active,
        }
    }

    datePart = (date) => {
        const day = formatDate(date, 'DD');
        const month = formatDate(date, 'MMM').replace(".", "");
        const year = formatDate(date, 'YY');
        return {year, day, month};
    }

    render() {
        const { date, active, type } = this.props
        const part = this.datePart(date)
        const day = part.day
        const year = part.year
        const month = part.month

        return(
            <div className={ (active) ? 'DateItemList active' : 'DateItemList'} data-cy="dateItem">
                <div className={ (active) ? `boxDayactive active-${type}` : 'boxDay'}>
                    <span className='txtDay'>
                        {day}
                    </span>
                </div>
                <div className={(active) ? `boxMonthactive month-${type}` : 'boxMonth'}>
                    <span className='txtMonth'>
                        {month} {year}
                    </span>
                </div>
            </div>
        )
    }

} 



export default DateItemList

