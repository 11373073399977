import React from "react";

const IconLock = (props) => (
  <svg width={props.width ? props.width : 20} height={ props.height ? props.height : 26.001} viewBox="0 0 20 26.001">
    <path id="_ionicons_svg_ios-lock" d="M113.5,56.992H112V54.073a6,6,0,1,0-12-.081v3H98.5a2.507,2.507,0,0,0-2.5,2.5v12a2.507,2.507,0,0,0,2.5,2.5h15a2.507,2.507,0,0,0,2.5-2.5v-12A2.507,2.507,0,0,0,113.5,56.992Zm-6.625,7.8v4.3a.9.9,0,0,1-.831.9.876.876,0,0,1-.919-.875V64.792a2,2,0,1,1,1.75,0Zm3.375-7.8h-8.5v-3a4.25,4.25,0,0,1,8.5,0Z" 
      transform="translate(-96 -47.992)" 
      fill={`${props.color ? props.color : '#b5b5b5;'}`}/>
  </svg>
);

export default IconLock;
