import { ADD_STAFF, SET_STAFF, ADD_MULTIPLE_STAFF, UPDATE_STAFF, SELECT_STAFF ,UPDATE_CONTRACT, ADD_ALLOWANCES_STAFF, UPDATE_ALLOWANCES_STAFF, DELETE_ALLOWANCES_STAFF, SET_ALLOWANCES_STAFF } from '../action-type/staffType'

export const addStaff = (staff) => ({
    type: ADD_STAFF,
    payload: staff
})

export const setStaffs = (staffs) => ({
    type: SET_STAFF,
    payload: staffs
})

export const addMultipleOrganizationStaff = (staffs) => ({
    type: ADD_MULTIPLE_STAFF,
    payload: staffs
})

export const updateStaff = (data) => ({
    type: UPDATE_STAFF,
    payload: data
})

export const setAllowancesStaff = (data) => ({
    type: SET_ALLOWANCES_STAFF,
    payload: data
})

export const addAllowancesStaff = (data) => ({
    type: ADD_ALLOWANCES_STAFF,
    payload: data
})

export const updateAllowancesStaff = (data) => ({
    type: UPDATE_ALLOWANCES_STAFF,
    payload: data
})

export const deleteAllowancesStaff = (id) => ({
    type: DELETE_ALLOWANCES_STAFF,
    payload: id
})

export const selectStaff = (data) => ({
    type: SELECT_STAFF,
    payload: data
})
export const __updateContract = (data) => ({
    type: UPDATE_CONTRACT,
    payload: data
})