/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const IoniconsSvgIosCallComponent = (props) => (
  <svg width={props.width ? props.width : 17} height={props.height ? props.height : 16.999} viewBox="0 0 17 16.999">
    <defs>
      {
        <style>{`.${props.className}{fill:${props.active ? '#177BD1' : '#b5b5b5;'}}`}</style>
      }
    </defs>
    <path
      className={props.className}
      d="M80.366,77.346a14.28,14.28,0,0,0-2.983-2c-.894-.429-1.221-.42-1.854.035-.527.381-.867.735-1.474.6A8.791,8.791,0,0,1,71.095,73.8,8.728,8.728,0,0,1,68.9,70.836c-.128-.611.226-.947.6-1.474.456-.633.469-.96.035-1.854a14,14,0,0,0-2-2.983c-.651-.651-.8-.509-1.155-.381a6.573,6.573,0,0,0-1.058.562,3.191,3.191,0,0,0-1.27,1.341c-.252.544-.544,1.558.943,4.2a23.46,23.46,0,0,0,4.125,5.5l0,0,0,0a23.551,23.551,0,0,0,5.5,4.125c2.646,1.487,3.66,1.195,4.2.943a3.136,3.136,0,0,0,1.341-1.27,6.573,6.573,0,0,0,.562-1.058C80.875,78.142,81.021,78,80.366,77.346Z"
      transform="translate(-63.857 -64.035)"
    />
  </svg>
);

export default IoniconsSvgIosCallComponent;
