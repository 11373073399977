import React, {Component} from 'react';
import { connect } from 'react-redux';
import { setPayslips, updatePayslip } from '../../../redux/action/PayslipAction';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import { _deletePayslipAllowance, _getAllowancesByName, _createPayslipAllowance, _updatePayslipAllowance } from '../../../services/Allowance';
import { patchMonthlyInfoPayslip, _updatePayslip } from '../../../services/PaySlip';
import IconBack from '../../Icons/BackIcon';
import IconNext from '../../Icons/NextIcon';
import Tag from '../../Tag';
import EditableInput from '../../EditableInput';
import IconMan from '../../Icons/IconMan';
import IconChild from '../../Icons/ChildIcon';
import IconUnion from '../../Icons/UnionIcon';
import IconMap from '../../Icons/MapIcon';
import Countries from 'country-data';
import './PayslipTab.css';
import JobComponent from '../../Icons/JobComponent';
import CategoryIcon from '../../Icons/CategoryIcon';
import EchelonIcon from '../../Icons/EchelonIcon';
import IconEuro from '../../Icons/IconEuro';
import IconPayment from '../../Icons/EuroPaymentComponent';
import IconDepart from '../../Icons/DepartIcon';
import IconService from '../../Icons/ServiceIcon';
import IconContract from '../../Icons/AgreementComponent';
import TimeIcon from '../../Icons/TimeIcon';
import IconDate from '../../Icons/DateIcon';
import IconBankTransfert from '../../Icons/BankTransferIcon';
import IconBank from '../../Icons/BankComponent';
import { numberFormat } from "../../../helpers/Number";
import moment from 'moment';
import AllowancesManager from "../../AllowancesManager";
import Logger from '../../../helpers/Logger';
import { errorNotifcation } from "../../../helpers/errorHandle";
import "simple-react-validator/dist/locale/fr";
import SimpleReactValidator from "simple-react-validator";
import { toastr } from 'react-redux-toastr';

class PayslipTab extends Component {
	defaultOrganization = JSON.parse(sessionStorage.getItem('defaultOrg'));
	updateAllowanceStatus = false;

    constructor(props){
        super(props);
		this.toggleRef = React.createRef();
		this.autocomplete = React.createRef();
		this.state = {...this.initData(this.props.payslip), step: this.props.initStep >= 0 ? this.props.initStep : 0,
			initStep: this.props.initStep || 0,};
		this.validator = new SimpleReactValidator({autoForceUpdate: this, locale: 'fr'});
	}

	initData = (payslip) => {
		return {
			sickLeaveTime: payslip?.sickLeaveTime || 0,
			exceptionalLeaveTime: payslip?.exceptionalLeaveTime || 0,
			paidLeaveTaken: payslip?.paidLeaveTaken || 0,
			leaveWithoutPay: payslip?.leaveWithoutPay || 0,
			unjustifiedAbsenceTime: payslip?.unjustifiedAbsenceTime || 0,
			overTime: payslip?.overTime || 0,
			holidaysTime: payslip?.holidaysTime || 0,
			proratization: false,
			nameAllowance: '',
			amountAllowance: null,
			proratizationAllowance: false,
			allowanceId: null,
			payslipAllowanceId: null,
			payslipAllowances: payslip?.payslipAllowances || [],
			nightAllowance: payslip?.nightAllowance || 0,
			staff: payslip?.staff || [],
			primeList: [],
			allowanceIdTab: [],
			editPrime: false,
			oldAllowanceName: '',
			cumulSalary: payslip?.cumulSalary || 0,
			cumulAvantages: payslip?.cumulAvantages || 0,
			cumulWageCosts: payslip?.cumulWageCosts || 0,
			cumulNetTaxable: payslip?.cumulNetTaxable || 0,
			cumulEmployerCharges: payslip?.cumulEmployerCharges || 0,
			cumulPaidLeaveTaken: payslip?.cumulPaidLeaveTaken || 0,
			cumulPaidLeaveGained: payslip?.cumulPaidLeaveGained || 0,
			cumulPaidLeaveAvailableTaken: payslip?.cumulPaidLeaveAvailableTaken || 0,
			cumulPaidLeaveGainedPreviousYear: payslip?.cumulPaidLeaveGainedPreviousYear || 0,
			cumulPaidLeaveAvailablePreviousYear: payslip?.cumulPaidLeaveAvailablePreviousYear || 0,
			cumulPaidLeaveTakenPreviousYear: payslip?.cumulPaidLeaveTakenPreviousYear || 0,
			allowances: [],
			editMode: true,
			contentTabStyles: {
				padding: "20px"
			}
		};
	}

	shouldComponentUpdate(nextProps, nextState){
		if((nextState.step === this.state.step && nextProps.initStep !== this.state.step && !this.updateAllowanceStatus 
			&& (nextProps.changePayslipTab))){
			this.setState({step: nextProps.initStep});
		}
		if ((nextProps.payslip !== this.props.payslip)) {
			this.setState({...this.initData(nextProps.payslip)})
		}
		this.updateAllowanceStatus = false;
		return true;
	}

	closeTab = (val = false) => {
		if(val === true){
			document.querySelector('.btnCustomClose').click();
		}
	}

    gotoStep = (event, step) => {
		if(+step >= 0 && +step <= 2 && this.state.step !== step){
            this.setState({
                step
            });
		}
	}
	toNumeric = (value) => {
		return parseFloat(numberFormat(value, 1, "."));
	}
	handleInputChange = (event) => {
		const {value} = event.target;
		this.setState({
			[event.target.name]: Number(+value) ? Number(+value) : null
		});
	}
	handleChange = (event) => {
		const {value} = event.target;
		this.setState({
			[event.target.name]: value
		});
	}
	handleChangeProrat = () => {
		const {payslipAllowances, payslipAllowanceId} = this.state;
		let itemPrime = payslipAllowances.findIndex((elt) => { return elt.id === payslipAllowanceId });
		if(itemPrime >= 0){
			payslipAllowances[itemPrime].proratization = !payslipAllowances[itemPrime].proratization;
			this.setState({
				payslipAllowances,
				proratizationAllowance: payslipAllowances[itemPrime].proratization
			})
		}else{
			this.setState({
				proratizationAllowance: !this.state.proratizationAllowance
			})
		}
	}
	updatePayslipHours = async (e) => {
        try{
			const name = e.target.name;
			const isFieldValid = this.validator.fieldValid(name);

			if( !isFieldValid ){
				return this.validator.showMessageFor(name);
			}
			const data = {[name]: +e.target.value}
			this.toggleLoader();
            const resData = (await patchMonthlyInfoPayslip(this.defaultOrganization.id, this.props.payslip.payPeriodId, this.props.payslip.id, data)).data;
			this.updateAllowanceStatus = true;
            this.props.updatePayslip(resData);
            this.toggleLoader();
        }catch(error){
			this.setState({
				[e.target.name]: this.props.payslip[e.target.name] || 0,
			});
			const err = { ...error };
			Logger.error("updatePayslipHours", "Error occured during update payslip hours", error);
			if(err?.response?.status === 400 && err?.response?.data?.title?.trim()) {
				toastr.warning("", err?.response?.data?.title?.trim());
			} else {
				errorNotifcation(err?.response?.status, "Impossible de mettre à jour les horaires, veuillez reessayer utérieurement.");
			}
        }
	}
		
	onToggleProratisation = (event) => {
		this.setState({
			proratization: event.target.checked
		});
	}

	handleChangePrime = async (e) => {
		try {
			const { value } = e.target;
			this.setState({
				nameAllowance: value,
			}, async () => { 
				const allowances = (await _getAllowancesByName(value)).data;
				this.setState({
					allowances,
				})
			})
		}
		catch(error)
		{
			Logger.error("GetAllowancesByName", "Could not get allowance by name", error);
			errorNotifcation(error?.response?.status, "Impossible de récupérer la prime, veuillez reessayer ultérieurement.");
			return [];
		}
	}

	selectPrimeItem = (value, allowanceId) => {
		this.setState({
			nameAllowance: value,
			allowanceId,
		})
	}

	errorMsg = (err) => {
		return err;
	}

	updateFieldState = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	renderHours = () => {
		return(
			<div id="step-1 ctn-tab" style={this.state.contentTabStyles}>
				<div className="sub-title-tab"> Absences payés </div>
				<div className="row">
					<div className="col-md-6 col-sm-12">
						<div className="input-label">Congé maladie (<b>jours</b>)</div>
						<EditableInput type='text' name='sickLeaveTime' value={this.state.sickLeaveTime} placeholder={"0"} className="pl-30-10 " 
							error={{ msg: this.errorMsg(this.validator.message('sickLeaveTime', this.state.sickLeaveTime, 'numeric')), placement: "bottom"}}
							onBlur={this.updatePayslipHours}
							onChange={this.updateFieldState}
							state={'preview'} editable
						/> 
					</div>	
					<div className="col-md-6 col-sm-12">
						<div className="input-label">Congé exceptionnel (<b>jours</b>)</div>
						<EditableInput type='text' name='exceptionalLeaveTime' value={this.state.exceptionalLeaveTime} placeholder={"0"} className="pl-30-10 "
							error={{ msg: this.errorMsg(this.validator.message('exceptionalLeaveTime', this.state.exceptionalLeaveTime, 'numeric')), placement: "bottom"}}
							onBlur={this.updatePayslipHours}
							onChange={this.updateFieldState}
							state={'preview'} editable
						/> 
					</div>	
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="input-label mt-1">Congés payés (<b>jours</b>)</div>
						<EditableInput type='text' name='paidLeaveTaken' value={this.state.paidLeaveTaken} placeholder={"0"} className="pl-30-10 "
							error={{ msg: this.errorMsg(this.validator.message('paidLeaveTaken', this.state.paidLeaveTaken, 'numeric')), placement: "bottom"}}
							onBlur={this.updatePayslipHours}
							onChange={this.updateFieldState}
							state={'preview'} editable
						/> 
					</div>	
				</div>
				<div className="sub-title-tab"> Absences justifiées non rémunérées </div>
				<div className="row">
					<div className="col-md-12">
						<div className="input-label">Congés sans solde (<b>jours</b>)</div>
						<EditableInput type='text' name='leaveWithoutPay' value={this.state.leaveWithoutPay} placeholder={"0"} className="pl-30-10 "
							error={{ msg: this.errorMsg(this.validator.message('leaveWithoutPay', this.state.leaveWithoutPay, 'numeric')), placement: "bottom"}}
							onBlur={this.updatePayslipHours}
							onChange={this.updateFieldState}
							state={'preview'} editable
						/> 
					</div>	
				</div>
				<div className="sub-title-tab"> Absences injustifiées </div>
				<div className="row">
					<div className="col-md-12">
						<div className="input-label">Nombre d’heures d’absences injustifiées</div>
						<EditableInput type='text' name='unjustifiedAbsenceTime' value={this.state.unjustifiedAbsenceTime} placeholder={"0"} className="pl-30-10 "
							error={{ msg: this.errorMsg(this.validator.message('unjustifiedAbsenceTime', this.state.unjustifiedAbsenceTime, 'numeric')), placement: "bottom"}}
							onBlur={this.updatePayslipHours}
							onChange={this.updateFieldState} 
							state={'preview'} editable
						/> 
					</div>	
				</div>
				<div className="sub-title-tab"> Heures supplémentaires </div>
				<div className="row">
					<div className="col-md-6 col-sm-12">
						<div className="input-label">Nombre d’heures supp...</div>
						<EditableInput type='text' name='overTime' value={this.state.overTime} placeholder={"0"} className="pl-30-10 "
							error={{ msg: this.errorMsg(this.validator.message('overTime', this.state.overTime, 'numeric')), placement: "bottom"}}
							onBlur={this.updatePayslipHours}
							onChange={this.updateFieldState} 
							state={'preview'} editable
						/> 
					</div>	
					<div className="col-md-6 col-sm-12">
						<div className="input-label">Nombre d’heures (<b>jours  fériés</b>)</div>
						<EditableInput type='text' name='holidaysTime' value={this.state.holidaysTime} placeholder={"0"} className="pl-30-10 "
							error={{ msg: this.errorMsg(this.validator.message('holidaysTime', this.state.holidaysTime, 'numeric')), placement: "bottom"}}
							onBlur={this.updatePayslipHours}
							onChange={this.updateFieldState}  
							state={'preview'} editable
						/> 
					</div>	
				</div>
				<div className="row">
					<div className="col-md-12 col-sm-12 mt-1">
						<div className="input-label">Nombre d’heures supplementaires (<strong>nuit</strong>)</div>
						<EditableInput type='text' name='nightAllowance' value={this.state.nightAllowance} placeholder={"0"} className="pl-30-10 "
							error={{ msg: this.errorMsg(this.validator.message('nightAllowance', this.state.nightAllowance, 'numeric')), placement: "bottom"}}
							onBlur={this.updatePayslipHours}
							onChange={this.updateFieldState}   
							state={'preview'} editable
						/> 
					</div>
				</div>
			</div>
		)
	}

	onChangeCumuls = async (cumul, manual) => {
		try{
			const payslipId = this.props.payslip.id;
			const data = {
				id: payslipId,
				staffId: this.props.payslip.staffId,
				payPeriodId: this.props.payslip.payPeriodId,
				...cumul
			}
			let resData = (await _updatePayslip(this.defaultOrganization.id, this.props.payslip.payPeriodId, payslipId, data, manual)).data;
			this.props.updatePayslip(resData);
			this.setState(this.initData({...resData}));
		}catch(error){
			Logger.error("updatePayslip", "Unable to update payslip", error);
			errorNotifcation(error?.response?.status, "Impossible de mettre à jour la fiche de paie, veuillez reessayer ultérieurement.");
		}

	}

	

    renderManualCumuls = () => {
        return(
            <div id="step-1 ctn-tab" style={this.state.contentTabStyles}>
                <table className="table-cumuls">
                    <thead>
                        <tr>
                            <th width="25%">&nbsp;</th>
                            <th width="25%">Congés acquis</th>
                            <th width="25%">Congés pris</th>
                            <th width="25%">Congés disponibles</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>Année prec</td>
                            <td>
                                <EditableInput type="number" boxSizing="inherit" editable name="Conges acquis ann prec" onBlur={(e) => this.onChangeCumuls({cumulPaidLeaveGainedPreviousYear: +e.target.value}, true)} value={this.props.payslip?.cumulPaidLeaveGainedPreviousYear || 0} className="small bold h-inherit-strict no-input-border" state={'preview'} />
                            </td>
                            <td>
                                <EditableInput type="number" boxSizing="inherit" editable name="Conges pris ann prec" onBlur={(e) => this.onChangeCumuls({cumulPaidLeaveTakenPreviousYear: +e.target.value}, true)} value={this.props.payslip?.cumulPaidLeaveTakenPreviousYear || 0} className=" small bold h-inherit-strict no-input-border" state={'preview'} />
                            </td>
                            <td>
                                <EditableInput type="number" boxSizing="inherit" editable name="Conges dispo ann prec" onBlur={(e) => this.onChangeCumuls({cumulPaidLeaveAvailablePreviousYear: +e.target.value}, true)} value={this.props.payslip?.cumulPaidLeaveAvailablePreviousYear || 0} className="small bold h-inherit-strict no-input-border" state={'preview'} />
                            </td>
                        </tr>
                        <tr>
                        <td>Année en cours</td>
                            <td>
                                <EditableInput type="number" editable name="Conges acquis" onBlur={(e) => this.onChangeCumuls({cumulPaidLeaveGained: +e.target.value}, true)} value={this.props.payslip?.cumulPaidLeaveGained || 0} className="small bold h-inherit-strict no-input-border" state={'preview'} />
                            </td>
                            <td>
                                <EditableInput type="number" editable name="Conges pris" onBlur={(e) => this.onChangeCumuls({cumulPaidLeaveTaken: +e.target.value}, true)} value={this.props.payslip?.cumulPaidLeaveTaken || 0} className="small bold h-inherit-strict no-input-border" state={'preview'} />
                            </td>
                            <td>
                                <EditableInput type="number" editable name="Conges dispo" onBlur={(e) => this.onChangeCumuls({cumulPaidLeaveAvailableTaken: +e.target.value}, true)} value={this.props.payslip?.cumulPaidLeaveAvailableTaken || 0} className="small bold h-inherit-strict no-input-border" state={'preview'} />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-cumuls">
                    <thead>
                        <tr>
                        <th width="10%"></th>
                        <th width="15%">Salaire brut</th>
                        <th width="15%">Charges sal.</th>
                        <th width="15%">Charges pat.</th>
                        <th width="15%">Avantages nat.</th>
                        <th width="15%">Net imposable</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr >
                            <td>Année</td>
                            <td>
                                <EditableInput type="number" editable boxSizing="inherit" name="Salaire Brut Mois" onBlur={(e) => this.onChangeCumuls({cumulSalary: +e.target.value}, true)} value={this.props.payslip?.cumulSalary || 0} className="small bold h-inherit-strict no-input-border" state={'preview'} />
                            </td>
                            <td>
                                <EditableInput type="number" editable boxSizing="inherit" name="Salaire Brut Mois" onBlur={(e) => this.onChangeCumuls({cumulAvantages: +e.target.value}, true)} value={this.props.payslip?.cumulAvantages || 0} className="small bold h-inherit-strict no-input-border" state={'preview'} />
                            </td>
                            <td>
                                <EditableInput type="number" editable boxSizing="inherit" name="Salaire Brut Mois" onBlur={(e) => this.onChangeCumuls({cumulWageCosts: +e.target.value}, true)} value={this.props.payslip?.cumulWageCosts || 0} className="small bold h-inherit-strict no-input-border" state={'preview'} />
                            </td>
                            <td>
                                <EditableInput type="number" editable boxSizing="inherit" name="Salaire Brut Mois" onBlur={(e) => this.onChangeCumuls({cumulEmployerCharges: +e.target.value}, true)} value={this.props.payslip?.cumulEmployerCharges || 0} className="small bold h-inherit-strict no-input-border" state={'preview'} />
                            </td>
                            <td>
                                <EditableInput type="number" editable name="Salaire Brut Mois" onBlur={(e) => this.onChangeCumuls({cumulNetTaxable: +e.target.value}, true)} value={this.props.payslip?.cumulNetTaxable || 0} className="small bold h-inherit-strict no-input-border" state={'preview'} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

	addOrUpdatePrime = async () => {
		const payslipId = this.props.payslip.id;
		let {editPrime, payslipAllowanceId, amountAllowance, proratization, allowanceId, nameAllowance} = this.state;
		const data = {
			amount: amountAllowance,
			proratization,
			allowanceId,
			payslipId,
		}
		if(editPrime && payslipAllowanceId) {
			this.updatePayslipAllowance(payslipAllowanceId, data, nameAllowance);
		} else {
			this.createPayslipAllowance(payslipId, data, nameAllowance);
		}
		this.cancelPrime();
	}

	cancelPrime = async () => {
		this.setState({
			payslipAllowanceId: '',
			nameAllowance: '',
			amountAllowance: '',
			proratization: false,
			editPrime: false,
			oldAllowanceName: '',
			allowanceId: '',
		}, () => {
			this.toggleRef.current.checked = this.state.proratization
		});
	}

	createPayslipAllowance =  async (payslipId, data, nameAllowance) => {
		const ifExists = this.props.payslip?.payslipAllowances?.findIndex(payslipAllowance => {
			return data.allowanceId === payslipAllowance.allowance.id;
		});
		if(ifExists < 0){
			try {
				const createdPayslipAllowance =  (await _createPayslipAllowance(
				this.defaultOrganization.id, this.props.payslip.payPeriodId, payslipId, data)).data;
				const newPayslipAllowance = { ...createdPayslipAllowance, allowance: {name: nameAllowance}};
				const newPayslipAllowanceList = [...this.props.payslip?.payslipAllowances, newPayslipAllowance ];
				this.updatePayslipAllowances(newPayslipAllowanceList);
				return true;
			} catch (error) {
				Logger.error("PayslipAllowance", "could not create payslipAllowance",error);
				errorNotifcation(error?.response?.status, "Impossible d'enregistrer la prime, veuillez rafraichir la page.");
				return false;
			}
		}
	}

    updatePayslipAllowance =  async (payslipId, data, nameAllowance) => {
		try {
			const updatedPayslipAllowance = (await _updatePayslipAllowance(this.defaultOrganization.id, this.props.payslip.payPeriodId, payslipId, data.allowanceId, data)).data;
			let newPayslipAllowanceList = [ ...this.props.payslip.payslipAllowances ];
			let oldPayslipAllowanceIndex = newPayslipAllowanceList?.findIndex(payslipAllowance => {
				return updatedPayslipAllowance.id === payslipAllowance.id;
			});
			const newPayslipAllowance = { ...updatedPayslipAllowance, allowance: {name: nameAllowance}};
			if(oldPayslipAllowanceIndex >= 0)
				newPayslipAllowanceList[oldPayslipAllowanceIndex] = newPayslipAllowance;
			this.updatePayslipAllowances(newPayslipAllowanceList);
			return true;
		} catch (error) {
			Logger.error("PayslipAllowance", "Could not update Payslip Allowance", error);
			errorNotifcation(error?.response?.status, "Impossible de mettre à jour la prime, veuillez rafraichir la page.");
			return false;
		}
    }
    
    updatePayslipAllowances = (newPayslipAllowances) => {
		if(newPayslipAllowances){
			const payslip = {...this.props.payslip};
			payslip.payslipAllowances = newPayslipAllowances;
			this.updateAllowanceStatus = true;
			this.props.updatePayslip(payslip);
			this.setState({
				payslipAllowances: newPayslipAllowances
			});
		}
    }

    onClickPrime = (item) => {
        this.setState({
            payslipAllowanceId: item.id,
            amountAllowance: item.amount,
            nameAllowance: item.allowance.name,
            oldAllowanceName: item.allowance.name,
            proratization: item.proratization,
            allowanceId: item.allowanceId,
            editPrime: true
        }, () => {
            this.toggleRef.current.checked = this.state.proratization
        })
    }

    renderContentSelected = () => {
		let payslipAllowances = this.state.payslipAllowances;
		if(payslipAllowances.length){
			return(
				payslipAllowances.map((item, index) => {
					return <Tag onTagClick={() => this.onClickPrime(item)} editable={true} title={item.allowance.name} key={index} id={index} onRemove={() => this.deletePayslipAllowance(item)} />
				})
			)	
		}
	}

    deletePayslipAllowance = async (item, payslipId) => {
		try {
			await _deletePayslipAllowance(this.defaultOrganization.id, this.props.payslip.payPeriodId, payslipId, item);
			const oldPayslipAllowanceIndex = this.props.payslip?.payslipAllowances?.findIndex((payslipAllowance) => {
				return item === payslipAllowance.id;
			});
			if(oldPayslipAllowanceIndex >= 0){
				let newPayslipAllowanceList = [ ...this.props.payslip.payslipAllowances ];
				newPayslipAllowanceList.splice(oldPayslipAllowanceIndex, 1); 
				this.updateAllowanceStatus = true;
				this.updatePayslipAllowances(newPayslipAllowanceList);
			}
			return true;
		} catch (error) {
			Logger.error("DeletePayslipAllowance", "Could not delete Payslip Allowance", error);
			errorNotifcation(error?.response?.status, "Impossible de supprimer la prime, veuillez rafraichir la page.");
			return false;
		}
	}

    renderFlag = () => {
        const { staff } = this.state
        const country = Countries.countries.all.find(country => country.name === staff.nationality)
        if(country)
            return country.emoji
        else
            return ''
    }

    calculateStaffAge = (birthDate) => {
        const startDate = moment(new Date(birthDate));
        const currentDate = moment();
        return currentDate.diff(startDate, 'years');
    }

    renderInfo = () => {
        let { staff } = this.state;
        const contract = staff?.contracts  && staff?.contracts[0];
        return (
            <div  style={this.state.contentTabStyles}>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="input-label">Age</div>
                        <EditableInput type='text' name='age' value={`${this.calculateStaffAge(staff.birthdate)} an(s)`} className="pl-30-10" state='preview' withIcon={<IconMan active="true" />} />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="input-label">Nombre d’enfants</div>
                        <EditableInput type='text' name='nbChild' value={staff?.childNumber ? staff?.childNumber : '0'} className="pl-30-10" state='preview' withIcon={<IconChild active />} />
                    </div>    
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="input-label">Situation familiale</div>
                        <EditableInput type='text' name='situation' value={staff?.familySituation} className="pl-30-10" state='preview' withIcon={<IconUnion active />} />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="input-label">Ville de résidence</div>
                        <EditableInput type='text' name='city' value={staff?.city} className="pl-30-10" state='preview' withIcon={<IconMap active />} />
                    </div>    
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="input-label">Pays de résidence</div>
                        <EditableInput type='text' name='city' value={staff?.country} className="pl-30-10" state='preview' withIcon={this.renderFlag()} />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="input-label">Poste</div>
                        <EditableInput type='text' name='poste' value={contract?.poste} className="pl-30-10" state='preview' withIcon={<JobComponent active />} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="input-label">Categorie</div>
                        <EditableInput type='text' name='categorie' value={contract?.category} className="pl-30-10" state='preview' withIcon={<CategoryIcon active />} />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="input-label">Echelon</div>
                        <EditableInput type='text' name='' value={contract?.echelon} className="pl-30-10" state='preview' withIcon={<EchelonIcon width={20} height={20} active />} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="input-label">Salaire de base</div>
                        <EditableInput type='number' name='salary' value={contract?.baseSalary} className="pl-30-10" state='preview' withIcon={<IconEuro active/>} />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="input-label">Sur salaire</div>
                        <EditableInput type='number' name='sur-salary' value={contract?.surSalary} className="pl-30-10" state='preview' withIcon={<IconPayment active />} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="input-label">Nature du contract</div>
                        <EditableInput type='text' name='contract' value={contract?.contratType} className="pl-30-10" state='preview' withIcon={<IconContract active/>} />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="input-label">Service</div>
                        <EditableInput type='text' name='service' value={contract?.service} className="pl-30-10" state='preview' withIcon={<IconService active />} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="input-label">Date de debut</div>
                        <EditableInput type='date' name='beginDate' value={contract?.beginDate} className="pl-30-10" state='preview' withIcon={<IconDate active />} />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="input-label">Date de fin</div>
                        <EditableInput type='date' name='endDate' value={contract?.endDate} className="pl-30-10" state='preview' withIcon={<IconDate active />} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="input-label">Mode de paiement</div>
                        <EditableInput type='text' name='payment' value={staff?.payementMode} className="pl-30-10" state='preview' withIcon={<IconBankTransfert active/>} />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="input-label">{staff.bank ? 
                            <EditableInput type='text' name='bankName' value={staff?.bank?.name} className="pl-30-10" state='preview' withIcon={<IconBank active/>} /> : 'Coordonnée bancaire'}</div>
                        <EditableInput type='text' name='bankInfo' value={staff?.bank ? `${staff?.bank?.bankCode} ${staff?.bank?.guichetCode} ${staff?.bank?.compteNum} ${staff?.bank?.ribKey}` : ''} className="pl-30-10" state='preview' />
                    </div>
                </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="input-label">Horaire hebdomadaire</div>
                            <EditableInput type='text' name='time' value={contract?.weeklyTime} className="pl-30-10" state='preview' withIcon={<TimeIcon active />} />
                        </div>
                        {
                            contract?.reasonOfDeparture ? 
                            <div className="col-md-6 col-sm-12">
                                <div className="input-label">Motif de depart</div>
                                <EditableInput type='text' name='depart' value={contract?.reasonOfDeparture} className="pl-30-10" state='preview' withIcon={<IconDepart active />} />
                            </div> : <></>
                        }
                        
                    </div>
            </div>
            
        )
    }

    addInfoPayslip = async () => {

        try{
            const { sickLeave, exceptionalLeave, payLeave, noPayLeave, unjustifiedAbsence, overTime, holidaysTime, payslipAllowances, nightAllowance } = this.state;
            const payslipId = this.props.payslip.id;
            const data = {
                staffId: this.props.payslip.staffId,
                payPeriodId: this.props.payslip.payPeriodId,
                overTime: overTime,
                unjustifiedAbsenceTime: unjustifiedAbsence,
                sickLeaveTime: sickLeave,
                exceptionalLeaveTime: exceptionalLeave,
                paidLeaveTaken: payLeave,
                leaveWithoutPay: noPayLeave,
                holidaysTime: holidaysTime,
                payslipAllowances: payslipAllowances,
                nightAllowance: nightAllowance
            }
			this.toggleLoader();
            let resData = (await patchMonthlyInfoPayslip(this.defaultOrganization.id, this.props.payslip.payPeriodId, payslipId, data)).data;
            this.props.updatePayslip(resData);
            this.toggleLoader();
        }catch(error){
			Logger.error("patchMonthlyInfoPayslip", "Unable to patch monthly info of payslip", error);
			errorNotifcation(error?.response?.status, "Impossible de corriger les informations mensuelles de la fiche de paie, veuillez reessayer plus tard");
        }
        
    }

    clearInfos = () => {
        this.setState({
            sickLeave: 0,
            exceptionalLeave: 0,
            payLeave: 0,
            noPayLeave: 0,
            unjustifiedAbsence: 0,
            overTime: 0,
            holidaysTime: 0,
            allowances: [],
            payslipAllowances: [],
            primeList: [],
            allowanceIdTab: []
        })
    }

    toggleLoader = () => {
        if(this.props.toggleLoader){
            this.props.toggleLoader();
        }
    }

    renderTab(step, styles, manual) {
        if (!manual) return (
            <Tabs value={step} onChange={this.gotoStep} TabIndicatorProps={{style: {backgroundColor: '#177BD1', borderRadius: '15px'}}} style={styles.tabs}>
                <Tab label="Heures" />
                <Tab label="Primes/Retenues" />
                <Tab label="Staff info" />
            </Tabs>
            )
        else return (
            <Tabs value={step} TabIndicatorProps={{style: {backgroundColor: '#177BD1', borderRadius: '15px'}}} style={styles.tabs}>
                <Tab label="Cumuls" />
            </Tabs>);
    }

    renderTabContent = (step, manual) => {
        const id = this.props.payslip?.id;
        if (!manual) return (
            <SwipeableViews index={step} onChangeIndex={this.gotoStep} className="swipeable-payslip">
                <div> { this.renderHours() } </div>
				<div> 
					<AllowancesManager 
						allowances={this.props.payslip?.payslipAllowances} 
						objectID={{ payslipId: id }} 
						saveAllowances={this.createPayslipAllowance}
						updateAllowances={this.updatePayslipAllowance}
						deleteAllowance={this.deletePayslipAllowance}
					/>
				</div>
                <div>{ this.renderInfo() }</div>
            </SwipeableViews>)
        else return (
            <SwipeableViews index={step} onChangeIndex={this.gotoStep} className="swipeable-payslip">
                <div>{ this.renderManualCumuls() }</div>
            </SwipeableViews>);
    }

    render(){
        const {step} = this.state;
        const styles = {
            tabs: {
                background: '#fff',
                width: '33,33%'
            },
            indicator: {
                backgroundColor: '#177BD1',
            }
		};
        return(
            <div className="payslip-tab">
                {
                    this.renderTab(step, styles, this.props.period.manual)
                }
                {
                    this.renderTabContent(step, this.props.period.manual)
                }
                <div className='container-btn px-3 py-2'>
                    <div className='d-flex justify-content-between align-items-center'>
                        {
							this.state.step === 2 || this.state.step === 1 ? 
							<button className='btn-back' onClick={(e) => this.gotoStep(e, this.state.step - 1)}>
								<IconBack/>
							</button> : <div></div>
                        }
                        {
							this.state.step === 0 || this.state.step === 1 ? 
							<button className='btn-next' onClick={(e) => this.gotoStep(e, this.state.step + 1)}>
                                <IconNext />
							</button> : <div></div> 
                        }
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
	const { payslips } = state
	const props = {
		payslips: payslips.payslips,
		payslip: payslips.selectedPayslip
	}
	return props;
}
export default connect(mapStateToProps, { setPayslips, updatePayslip })(PayslipTab);