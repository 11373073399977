import React from "react";

const ArrorupredComponent = props => (
  <svg viewBox="0 0 6.372 9.554"  width={props.width ? props.width : 6.372}  height={props.height ? props.height : 9.554} className={props.className ? props.className : ""}>
    <defs>
    </defs>
    <path
      fill={`${props.fill ? props.fill : '#af2216'}`}
      d="M166.212,115.461a.434.434,0,0,1-.611,0l-2.021-2.014v7.675a.431.431,0,0,1-.863,0V113.45l-2.021,2.018a.437.437,0,0,1-.611,0,.43.43,0,0,1,0-.607l2.754-2.734a.484.484,0,0,1,.136-.09.412.412,0,0,1,.166-.033.432.432,0,0,1,.3.123l2.754,2.734A.423.423,0,0,1,166.212,115.461Z"
      transform="translate(-159.962 -112)"
    />
  </svg>
);

export default ArrorupredComponent;
