import React, { Component } from "react";
import PropTypes from "prop-types";
import Loader from "../Loader";

import "./DeconectionButtton.css";

class DeconectionButtton extends Component {

    constructor(props){
        super(props);
        this.state = {
            label: this.props.label || "Deconnexion",
            processing: false,
        };
        this.loader = React.createRef();
    }

    handleClick = (e) => {
        e.stopPropagation();
        if(this.props.onClick && !this.state.processing){
            this.props.onClick(e);
            this.setState({
                processing: true
            }, () => { this.loader.current.toggleLoad(); });
        }
    }

    renderLabel = () => {
        return(
            <span className={`DeconectionButtton__label ${(this.state.processing) ? " processing" : ""}`}> { this.state.label } </span>
        );
    }

    renderLoader = () => {
        if(this.state.processing){
            return(
                <span className="DeconectionButtton__loader"> <Loader ref={ this.loader } style={{width: "20px", height: "20px"}} /> </span>
            )
        }
    }

    desableLoad = () => {
        this.loader.current.disableLoad();
    }

    init = () => {
        this.setState({
            label: this.props.label || "Deconnexion",
            processing: false,
        })
    }

    render(){

        return(
            <div className={`DeconectionButtton ${this.props.className ? this.props.className : ''}`} onClick={this.handleClick}>
                { this.renderLabel() }
                { this.renderLoader() }
            </div>
        )
    }
}

DeconectionButtton.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func
}

export default DeconectionButtton;