import React, { Component } from 'react'
import './SwitchOrgsLoader.css'
import Loader from '../../Loader';
import { SESSION_STORAGE_KEYS } from '../../../constants/constants';

class SwitchOrgsLoader extends Component {

    isLoadOrganizations = () => {
        const orgId = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG))?.id;
        return !orgId ? false: true;
    }

    render(){
        return(
            <>
                <div className="item-list-loader">
                    <div className="logo-org-loader"/>
                    <div className="descript-org-loader"/>
                </div>
                { 
                    this.isLoadOrganizations() ? 
                        <div className="item-list-loader d-flex align-items-center justify-content-center" style={{"height": "100vh"}}>
                            <div className="org-loading-text"> Chargement du contenu...</div>
                            <Loader style={{width: '70px', height: '70px', "marginTop": "-55px", "marginLeft": "-79px"}} loadData={true} />
                        </div> 
                    : <></>
                }
            </>
        )
    }
}

export default SwitchOrgsLoader