export const SET_TAXES = "SET_TAXES";
export const SELECT_TAXE = "SELECT_TAXE";
export const SET_VALUE = "SET_VALUE";
export const HANDLE_CHANGE_TAB = "HANDLE_CHANGE_TAB";
export const HANDLE_CHANGE_RATE = "HANDLE_CHANGE_RATE";
export const HANDLE_CHECKBOX = "HANDLE_CHECKBOX";
export const GET_SUM = "GET_SUM";
export const GET_SUM_L1_L2 = "GET_SUM_L1_L2";
export const GET_SUM_L3_L4 = "GET_SUM_L3_L4";
export const GET_SUBSTACT_L5_L6 = "GET_SUBSTACT_L5_L6";
export const GET_SUM_L5_L7 = "GET_SUM_L5_L7";
export const GET_L16XL22 = "GET_L16XL22";
export const SWITCH_L5_OR_L7 = "SWITCH_L5_OR_L7";
export const SUM_TABS = "SUM_TABS";
export const CALCULATE_L54 = "CALCULATE_L54";
export const ONCHANGE_TABLE_8 = "ONCHANGE_TABLE_8";
export const ONCHANGE_TABLE_9 = "ONCHANGE_TABLE_9";
export const ONCHANGE_TABLE_10 = "ONCHANGE_TABLE_10";
export const ONCHANGE_TABLE_11 = "ONCHANGE_TABLE_11";
export const ONCHANGE_TABLE_12 = "ONCHANGE_TABLE_12";
export const ONCHANGE_TABLE_13 = "ONCHANGE_TABLE_13";
export const ONCHANGE_TABLE_14 = "ONCHANGE_TABLE_14";
export const ONCHANGE_TABLE_15 = "ONCHANGE_TABLE_15";
export const ONCHANGE_FOOTER = "ONCHANGE_FOOTER";
export const SET_PAGE = "SET_PAGE";
export const SWITCH_L22_OR_L23 = "SWITCH_L22_OR_L23";
export const ACTION_FORM_ONE = "ACTION_FORM_ONE";
export const ACTION_TAB_FIVE = "ACTION_TAB_FIVE";
export const SET_CNPS_DECLARATION_PERIODS = "SET_CNPS_DECLARATION_PERIODS"; 
export const ADD_CNPS_DECLARATION_PERIODS = "ADD_CNPS_DECLARATION_PERIODS"; 
export const ADD_MORE_CNPS_DECLARATION_PERIODS = "ADD_MORE_CNPS_DECLARATION_PERIODS"; 
export const UPDATE_CNPS_DECLARATION_PERIODS = "UPDATE_CNPS_DECLARATION_PERIODS";
export const UPDATE_CNPS_DECLARATION_PERIODS_DETAILS = "UPDATE_CNPS_DECLARATION_PERIODS_DETAILS";
export const DELETE_CNPS_DECLARATION_PERIODS = "DELETE_CNPS_DECLARATION_PERIODS"; 
export const SELECT_CNPS_DECLARATION_PERIODS = "SELECT_CNPS_DECLARATION_PERIODS";
