import React, { Component } from 'react';
import {
    Transition
} from "react-transition-group";
import './Transition.css';

export default class BottomToTop extends Component {
    render() {
        let { inverse, className } = this.props
        return (
            <Transition
                in={this.props.in}
                timeout={500}
                unmountOnExit
                onEnter={() => {}}
                onEntering={() => {}}
                onEntered={() => {}}
                onExit={() => {}}
                onExiting={() => {}}
                onExited={() => {}}
            >
                {(statusName) => {
                    return(
                        <div className={ className ? `${className} ${className}-${statusName}` :
                            inverse ? ` top-to-bottom top-to-bottom-${statusName}` : `bottom-to-top bottom-to-top-${statusName}`} >
                            {this.props.children}
                        </div>
                    )
                }}
            </Transition>
        )
    }
}