/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const AgreementComponent = (props) => (
  <svg width={props.width ? props.width : 23.624} height={props.height ? props.height : 14.965} viewBox="0 0 23.624 14.965">
    <defs>
      <style>{`.cta{fill:${props.active ? '#177bd1;' : '#b5b5b5'}}`}</style>
    </defs>
    <g transform="translate(0 -33.597)">
      <g transform="translate(0 33.597)">
        <path
          className="cta"
          d="M44.126,56.028l-2.318-4.647a.484.484,0,0,0-.105-.139.439.439,0,0,0-.146-.088l-2.718-.977a1.725,1.725,0,0,0-1.2.012l-2.062.787a4.4,4.4,0,0,0-.431.192c-.047.025-.094.051-.141.077a3.833,3.833,0,0,0-.488-.222,3.732,3.732,0,0,0-1.631-.225l-1.387.119a.423.423,0,0,0-.178.057.457.457,0,0,0-.141.131l-3.124,4.447a.5.5,0,0,0-.088.33.49.49,0,0,0,.141.308l1.359,1.347a1.1,1.1,0,0,0,.9,1.629l.048,0a1.1,1.1,0,0,0,.979,1.431,1.1,1.1,0,0,0,1.022,1.521,1.094,1.094,0,0,0,.78-.324,1.106,1.106,0,0,0,1.019.934l.073,0a1.094,1.094,0,0,0,.383-.07,1.132,1.132,0,0,0,.677.192,1.157,1.157,0,0,0,.783-.358l-.747-.746c0-.017,0-.034.006-.052a1.1,1.1,0,0,0-.054-.42l1.53,1.526a1.484,1.484,0,0,0,.622.109,1.539,1.539,0,0,0,1.1-.544l.153-.178a.246.246,0,0,0,.034-.056l-2.355-2.348a.167.167,0,0,1-.012-.223.14.14,0,0,1,.208,0l2.8,2.793a1.3,1.3,0,0,0,.486.073,1.353,1.353,0,0,0,.971-.479l.37-.428a.55.55,0,0,0,.131-.377l-2.458-2.45a.167.167,0,0,1-.012-.223.14.14,0,0,1,.208,0l2.469,2.462a1.063,1.063,0,0,0,.317.036,1.106,1.106,0,0,0,.795-.392l.1-.115a1.294,1.294,0,0,0-.06-1.735l-.659-.657.167.166,1.782-1.609a.483.483,0,0,0,.151-.275A.509.509,0,0,0,44.126,56.028ZM30.392,58.69a.627.627,0,0,1,.04-1.253h.042a.627.627,0,1,1-.082,1.252Zm1.069,1.435A.627.627,0,0,1,31,59.074l.374-.395.062-.066a.627.627,0,1,1,.911.862l-.175.185-.118.125-.02.021-.078.082-.036.038-.006.006A.621.621,0,0,1,31.461,60.125Zm1.4,1.326a.627.627,0,0,1-.883.02.629.629,0,0,1-.032-.879l.312-.33h0l.121-.128.3-.322.008-.008h0l.033-.035a.627.627,0,1,1,.911.863Zm1.379.8A.627.627,0,0,1,34.28,61h.042a.627.627,0,0,1-.082,1.252ZM41.909,57.9l-4.882-4.868h0l.055-.139a.267.267,0,0,0-.017-.23.23.23,0,0,0-.185-.118l-.363-.022h0l-.4-.024-.03.47a5.461,5.461,0,0,1-.624,2.044,2.891,2.891,0,0,1-1.412,1.354,2.072,2.072,0,0,1-.368.048.43.43,0,0,1-.183-.033l-.185-.086a.327.327,0,0,1-.168-.187.359.359,0,0,1,0-.251l1.857-4.075.316-.208.127-.048h0l2.334-.89a1.313,1.313,0,0,1,.456-.087h.013a1.311,1.311,0,0,1,.445.078l2.718.977,2.318,4.647Z"
          transform="translate(-24.358 -47.951)"
        />
        <path
          className="cta"
          d="M139.985,37.179l-1.835.915a1.154,1.154,0,0,0-.517,1.547l2.4,4.816a1.154,1.154,0,0,0,1.547.517l1.835-.915Zm1.592,6.883a.442.442,0,1,1,.442-.442A.442.442,0,0,1,141.577,44.062Z"
          transform="translate(-119.793 -36.717)"
        />
        <path
          className="cta"
          d="M0,39.889l1.678,1.178a1.153,1.153,0,0,0,1.606-.281l3.094-4.4a1.153,1.153,0,0,0-.28-1.607L4.42,33.6Zm1.447-.226a.442.442,0,1,1,.371.5A.442.442,0,0,1,1.447,39.662Z"
          transform="translate(0 -33.597)"
        />
      </g>
    </g>
  </svg>
);

export default AgreementComponent;
