import { ADD_ORG, SET_ORGS, SET_DEFAULT_ORG, ADD_ORG_BANK_RECORDS, ADD_ORG_BANK_RECORDS_MULTIPLE, SET_ORG_INVOICES, ADD_ORG_INVOICE_MULTIPLE,
    ADD_ORG_INVOICE, SET_ORG_BANK_REPORTS, UPDATE_ORG_INVOICE, UPDATE_SELECTED_INVOICE, UPDATE_UNRECONCILED_INVOICES,
    SET_RECONCILED_INVOICES, SET_SELECTED_INVOICE, SET_SELECTED_ORG_USER, SET_ORG_USERS, TOGGLE_ORG_ROLE_CHANGE,
    SET_RECONCICLED_BANK_RECORD_ITEM, ADD_ORG_BILL, ADD_MULTIPLE_ORG_BILLS, UPDATE_ORG_BILL, DELETE_ORG_BILL, SET_ORG_BILLS,
    SET_ORG_TAXES, SELECTED_DEFAULT_ORG_TAXES, ADD_ORG_TAXES, ADD_ORG_TAXES_MULTIPLE, UPDATE_ORG_TAXES, DELETE_ORG_TAXES,
} from '../action-type/organizationType';
import {ORGANIZATION_USER_STATUS, SESSION_STORAGE_KEYS, BILL} from "../../constants/constants";
import {SET_ORG_CUSTOMERS, ADD_MULTIPLE_ORG_CUSTOMERS} from '../action-type/organizationType';
// import Countries from 'country-data';

const INITIAL_STATE = {
    organizations: [],
    organizationBankRecords: { data: [], pager: ""},
    organizationInvoices: [],
    organizationBills: [],
    unreconciledInvoices: [],
    reconciledInvoices: [],
    selectedInvoice: {},
    defaultOrganization: {},
    defaultCurrency: null,
    default: null,
    triInvoices: [],
    habilitationsLoaded: false,
    selectedOrgUser: null,
    organizationUsers: null,
    vat: BILL.VAT,
    customers: [],
    organizationTaxes: [],
    selectedOrgTaxes: null,
}

const OrganizationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADD_ORG : 
            return { ...state, organizations: [ action.payload, ...state.organizations ] }

        case SET_ORGS:
            return { ...state, organizations: action.payload };

        case UPDATE_SELECTED_INVOICE:{
            const {selectedInvoice} = state;
            const {payload} = action;
            return { ...state, selectedInvoice: {...selectedInvoice, ...payload} };}
            
        case UPDATE_ORG_INVOICE:{
            const {organizationInvoices} = state;
            const {payload} = action;
            let i = organizationInvoices.findIndex((invoice) => {
                return invoice.id === payload.id;
            });
            organizationInvoices[i] = payload;
            return { ...state, organizationInvoices };}

        case ADD_ORG_INVOICE : 
            return { ...state, organizationInvoices: [ action.payload, ...state.organizationInvoices ] };

        case ADD_ORG_INVOICE_MULTIPLE : {
            return { ...state, organizationInvoices: [...state.organizationInvoices, ...action.payload] };
        }

        case SET_ORG_INVOICES:
            return { ...state, organizationInvoices: action.payload};
        
        case SET_ORG_BILLS:{
            return { ...state, organizationBills: action.payload};
        }

        case ADD_ORG_BILL : {
            return { ...state, organizationBills: [ action.payload, ...state.organizationBills ] };
        }

        case ADD_MULTIPLE_ORG_BILLS : {
            const data = state.organizationBills.concat(action.payload);
            return { ...state, organizationBills: data };
        }
        
        case UPDATE_ORG_BILL:{
            const {organizationBills} = state;
            const {payload} = action;
            let i = organizationBills.findIndex((bill) => {
                return bill.id === payload.id;
            });
            organizationBills[i] = payload;
            return { ...state, organizationBills };}
        case DELETE_ORG_BILL:{
            const {organizationBills} = state;
            const {payload} = action;
            let i = organizationBills.findIndex((bill) => {
                return bill.id === payload.id;
            });
            organizationBills.splice(i,1);
            return { ...state, organizationBills };}
        
        case SET_ORG_CUSTOMERS:{
            return { ...state, customers: action.payload};
        }

        case ADD_MULTIPLE_ORG_CUSTOMERS : {
            const data = state.customers.concat(action.payload);
            return { ...state, customers: data };
        }

        case UPDATE_UNRECONCILED_INVOICES:
            return {...state, unreconciledInvoices: action.payload};

        case SET_RECONCILED_INVOICES:
            return {...state, reconciledInvoices: action.payload};
        
        case SET_SELECTED_INVOICE:{
            return {...state, selectedInvoice: action.payload};}

        case SET_SELECTED_ORG_USER:
            return {...state, selectedOrgUser: action.payload};

        case SET_ORG_USERS:
            let pendingUsers = action.payload.filter((elem) => elem.status === ORGANIZATION_USER_STATUS.PENDING) 
            pendingUsers.sort((a, b) => {
                if(a.createdAt > b.createdAt) { return -1; }
                if(a.createdAt < b.createdAt) { return 1; }
                return 0;
            })
            let activeUsers = action.payload.filter((elem) => elem.status === ORGANIZATION_USER_STATUS.ACTIVE) 
            activeUsers.sort((a, b) => {
                if((a.updatedAt || a.createdAt) > (b.updatedAt || b.createdAt)) { return -1; }
                if((a.updatedAt || a.createdAt) < (b.updatedAt || b.createdAt)) { return 1; }
                return 0;
            })
            return {...state, organizationUsers: pendingUsers.concat(activeUsers)};
        
        case SET_ORG_BANK_REPORTS:
        case ADD_ORG_BANK_RECORDS: { 
                const data = action.payload;
                const pager = action.payload.pager;
                return { ...state, organizationBankRecords: {data, pager} };
        }
        case ADD_ORG_BANK_RECORDS_MULTIPLE: { 
            const data = state.organizationBankRecords.data.concat(action.payload);
            return { ...state, organizationBankRecords: { ...state.organizationBankRecords, data}};
        }
        case SET_RECONCICLED_BANK_RECORD_ITEM : {
            if(action.payload && action.payload?.id) {
                const data = state.organizationBankRecords.data.map((item) => {
                    if (item.id === action.payload.id) return action.payload;
                    return item;
                });
                return { ...state, organizationBankRecords: { ...state.organizationBankRecords, data}};
            }
            return { ...state };
        }
        case SET_DEFAULT_ORG:
            const id = action.payload;
            const {organizations} = state ;
            organizations.map((item) => (
                item.active = false
            ))
            const index = organizations.findIndex((element) => { return element.id === id });
            organizations[index].active = true;
            sessionStorage.setItem(SESSION_STORAGE_KEYS.DEFAULT_ORG,  JSON.stringify(organizations[index]));
            
            return { ...state, defaultOrganization: organizations[index] }
        case TOGGLE_ORG_ROLE_CHANGE: {
            const value = action.payload;
            sessionStorage.setItem(SESSION_STORAGE_KEYS.HABILITATIONS_LOADED,value);
            return { ...state, habilitationsLoaded: value};
        }

        case SET_ORG_TAXES:{
            return { ...state, organizationTaxes: action.payload};
        }
        case SELECTED_DEFAULT_ORG_TAXES:{
            return { ...state, selectedOrgTaxes: action.payload};
        }
        case ADD_ORG_TAXES: { 
            return { ...state, organizationTaxes: [action.payload, ...state.organizationTaxes] };
        }
        case ADD_ORG_TAXES_MULTIPLE: { 
            return { ...state, organizationTaxes: [ ...state.organizationTaxes, ...action.payload]};
        }
        case UPDATE_ORG_TAXES:{
            let i = state.organizationTaxes.findIndex((item) => {
                return item.id === action.payload.id;
            });
            state.organizationTaxes[i] = action.payload;
            return { ...state, organizationTaxes: {...state.organizationTaxes} };
        }

        case DELETE_ORG_TAXES:{
            let i = state.organizationTaxes.findIndex((item) => {
                return item.id === action.payload;
            });
            state.organizationTaxes.splice(i,1);
            return { ...state, organizationTaxes: {...state.organizationTaxes} };}

        default: 
            return state;
    }
}

export default OrganizationReducer;