import axios from '../config/axios';

export const createStaff = async (orgId, staff) => {
    return await axios.post(`/organizations/${orgId}/staff`, staff);
}

export const _updateStaff = async (orgId, staffId, staffData) => {
    return await axios.put(`/organizations/${orgId}/staff/${staffId}`, staffData);
}

export const _getContractByStaffId = async (orgId, staffId) => {
    return await axios.get(`organizations/${orgId}/staff/${staffId}/contract`);
}
