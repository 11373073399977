import { ADD_STAFF, SET_STAFF, UPDATE_STAFF, SELECT_STAFF } from '../action-type/contractType'

const INITIAL_STATE = {
    contracts: [],
    selectedContract: null
}

const ContractReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADD_STAFF : 
            if(action.payload) {
                return { ...state, contracts: [ action.payload, ...state.contracts ] }
            }
            return state;

        case SET_STAFF:
            if(action.payload.length > 0){
                let sortContracts = action.payload.sort((a, b) => {
                    return new Date(b.createdAt) - new Date(a.createdAt)
                })
                return { ...state, contracts: sortContracts }
            } else {
                return { ...state }
            }
        case UPDATE_STAFF: 
            let contracts = state.contracts;  
            let index = contracts.findIndex(elt => elt.id === action.payload.id);
            if(index >= 0) {
                contracts[index] = action.payload;
                return {
                    ...state,
                    contracts: [...contracts]
                }
            }

            return {
                ...state
            }
        case SELECT_STAFF:
            if(action.payload) {
                action.payload.selected = true
                return {
                    ...state,
                    selectedContract: action.payload
                }
            }

            return {
                ...state
            }
        
        default: 
            return state
        
    }
}

export default ContractReducer