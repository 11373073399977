import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import TaxeList from './TaxeList';
import TaxePageViews from './TaxePageViews';

export default class TaxeViews extends Component {
    constructor(props){
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <>
                <Route exact path={this.props.match.url} render={() => {
                    return <TaxeList {...this.props} />
                }} />
                <Route path={this.props.match.url+'/:view'} component={TaxePageViews} />
            </>
        );
    }
}
