import { ADD_STAFF, SET_STAFF, ADD_MULTIPLE_STAFF, UPDATE_STAFF,UPDATE_CONTRACT, SELECT_STAFF, ADD_ALLOWANCES_STAFF, UPDATE_ALLOWANCES_STAFF, DELETE_ALLOWANCES_STAFF, SET_ALLOWANCES_STAFF } from '../action-type/staffType'


const INITIAL_STATE = {
    staffMembers: [],
    selectedStaff: null,
    allowancesStaffSelected: [],
}

const StaffReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADD_STAFF : 
            if(action.payload) {
                return { ...state, staffMembers: [ action.payload, ...state.staffMembers ] }
            }
            return state
        case ADD_MULTIPLE_STAFF : 
            if(action.payload) {
                const data = state.staffMembers.concat(action.payload);
                return { ...state, staffMembers: data }
            }
            return state
        case SET_STAFF:
            if(action.payload.length > 0){
                return { ...state, staffMembers: action.payload }
            } else {
                return { ...state }
            }

        case UPDATE_STAFF: 
            let staffMembers = state.staffMembers;  
            let index = staffMembers.findIndex(elt => elt.id === action.payload.id);
            if(index >= 0) {
                staffMembers[index] = action.payload;
                return {
                    ...state,
                    staffMembers: [...staffMembers],
                    selectedStaff: action.payload
                }
            }

            return {
                ...state
            }
        case UPDATE_ALLOWANCES_STAFF: { 
                let {allowancesStaffSelected, selectedStaff} = state;  
                const index = allowancesStaffSelected.findIndex(item => { return item.id === action.payload.contract.id });
                allowancesStaffSelected[index] = action.payload.contractAllowance;
                selectedStaff.contracts[0] = Object.assign(selectedStaff.contracts[0], {...action.payload.contract})

                return { ...state, allowancesStaffSelected , selectedStaff};
        }
        case ADD_ALLOWANCES_STAFF: { 
            let {selectedStaff, allowancesStaffSelected} = state;
            allowancesStaffSelected.push(action.payload.contractAllowance);
            selectedStaff.contracts[0] = Object.assign(selectedStaff.contracts[0], {...action.payload.contract})

            return { 
                ...state,
                allowancesStaffSelected,
                selectedStaff
            };
        }
        case SET_ALLOWANCES_STAFF: { 
            return { ...state, allowancesStaffSelected: action.payload };
        }
        case DELETE_ALLOWANCES_STAFF: { 
            let {selectedStaff, allowancesStaffSelected} = state; 
            const index = allowancesStaffSelected.findIndex(item => { return +item.id === +action.payload.contractAllowance });
            allowancesStaffSelected.splice(index, 1);
            selectedStaff.contracts[0] = Object.assign(selectedStaff.contracts[0], {...action.payload.contract})
            return { ...state, allowancesStaffSelected, selectedStaff };
        }
        case UPDATE_CONTRACT: 
            let staff = state.staffMembers;  
            let i = staff.findIndex(elt => elt.id === action.payload.staffId);
            if(i >= 0) {
                let staffContractList = staff[i].contracts
                let j = staffContractList.findIndex(contract => contract.id === action.payload.id)
                if(j >= 0) 
                {
                    staffContractList[j] = action.payload
                    staff[i].contracts = staffContractList
                }
                
                return {
                    ...state,
                    staffMembers: [...staff],
                    selectedStaff: staff[i]
                }
            }

            return {
                ...state
            }
        case SELECT_STAFF:
            if(action.payload) {
                action.payload.selected = true
                return {
                    ...state,
                    selectedStaff: action.payload
                }
            }

            return {
                ...state
            }
                
        default: 
            return state
    }
}

export default StaffReducer