import React, { Component } from 'react';
import TaxeViews from "./TaxeViews";
import CnpsViews from "./CnpsViews";

class TaxePage extends Component {
    renderPage = () => {
        switch(this.props.match.params.page){ 
            case 'declaration':{
                return <TaxeViews match={this.props.match} {...this.props} />}
            case 'cnps':{
                return <CnpsViews match={this.props.match} {...this.props} />}
            case 'patente':{
                return <><h3>Patente</h3></>}
            default:{
                return <TaxeViews match={this.props.match} {...this.props} />}
        }
    }
    render() {
        return(
            <div className="flex-part pb-50">
                {this.renderPage()}
            </div>
        )
    }
}

export default TaxePage;