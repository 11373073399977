import { SET_PAYPERIOD, ADD_PAYPERIOD, ADD_PAYPERIOD_MULTIPLE, SELECTED_PAYPERIOD, SET_CLOSE_PAYPERIOD_LOADER } from '../action-type/payperiodType';

export const setPayPeriod = (periods) => ({
    type: SET_PAYPERIOD,
    payload: periods
})

export const addPayPeriod = (period) => ({
    type: ADD_PAYPERIOD,
    payload: period
})

export const addPayPeriodMultiple = (periods) => ({
    type: ADD_PAYPERIOD_MULTIPLE,
    payload: periods
})

export const selectPayPeriod = (period) => ({
    type: SELECTED_PAYPERIOD,
    payload: period
})

export const setClosePayPeriodLoader = (value) => ({
    type: SET_CLOSE_PAYPERIOD_LOADER,
    payload: value,
})