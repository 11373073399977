import React from "react";

const IconFeeDefaultComponent = props => (
  <svg width={props.width ? props.width : 30.072} height={props.height ? props.height : 29.5} viewBox="0 0 30.072 29.5" 
    className={`${props.className ? props.className : ''}`}
  >
    <g className="coin1">
      <path
        fill="#ffffff"
        className="theme-color-one"
        d="M2008.49,5905.381h17.824V5909H2008.49Z"
        transform="translate(-1998.145 -5905.381)"
      />
      <path
        fill="rgba(96,153,242,0.2)"
        className="theme-color-two"
        d="M2008.49,5987.885h1.686v2.61h-1.686Z"
        transform="translate(-1998.145 -5987.46)"
      />
      <path
        fill="#ffffff"
        className="theme-color-one"
        d="M2008.49,5905.381h17.824v.426H2008.49Z"
        transform="translate(-1998.145 -5905.381)"
      />
      <path
        fill="rgba(96,153,242,0.1)"
        className="theme-color-three"
        d="M5032.684,6039.22a8.852,8.852,0,0,1,.074,1.186,8.55,8.55,0,0,1-.074,1.161h2.246v-2.347Z"
        transform="translate(-5006.762 -6038.53)"
      />
      <path
        fill="rgba(96,153,242,0.1)"
        className="theme-color-three"
        d="M2008.49,5988.149h17.824v-.265H2008.49Z"
        transform="translate(-1998.145 -5987.46)"
      />
      <path
        fill="rgba(96,153,242,0.1)"
        className="theme-color-three"
        d="M2008.49,6494.819h17.824v.58H2008.49Z"
        transform="translate(-1998.145 -6491.783)"
      />
      <path
        fill="rgba(96,153,242,0.1)"
        className="theme-color-three"
        d="M2008.49,5987.885h1.686v.265h-1.686Z"
        transform="translate(-1998.145 -5987.46)"
      />
    </g>
    <g className="coin2">
      <path
        fill="#ffffff"
        className="theme-color-one"
        d="M2377,6607.573h17.829v3.612H2377Z"
        transform="translate(-2364.76 -6603.957)"
      />
      <path
        fill="rgba(96,153,242,0.2)"
        className="theme-color-two"
        d="M2377,6689.161h1.69v2.614H2377Z"
        transform="translate(-2364.76 -6685.124)"
      />
      <path
        fill="#ffffff"
        className="theme-color-one"
        d="M2377,6607.573h17.829v.421H2377Z"
        transform="translate(-2364.76 -6603.957)"
      />
      <path
        fill="rgba(96,153,242,0.1)"
        className="theme-color-three"
        d="M5401.2,6740.5a8.994,8.994,0,0,1,.078,1.185,8.628,8.628,0,0,1-.074,1.165h2.247v-2.35Z"
        transform="translate(-5373.378 -6736.194)"
      />
      <path
        fill="rgba(96,153,242,0.1)"
        className="theme-color-three"
        d="M2377,6689.425h17.829v-.265H2377Z"
        transform="translate(-2364.76 -6685.124)"
      />
      <path
        fill="rgba(96,153,242,0.1)"
        className="theme-color-three"
        d="M2377,7197.012h17.829v.575H2377Z"
        transform="translate(-2364.76 -7190.359)"
      />
      <path
        fill="rgba(96,153,242,0.1)"
        className="theme-color-three"
        d="M2377,6689.161h1.69v.265H2377Z"
        transform="translate(-2364.76 -6685.124)"
      />
    </g>
    <g className="coin3">
      <path
        fill="#ffffff"
        className="theme-color-one"
        d="M2008.49,7308.85h17.824v3.611H2008.49Z"
        transform="translate(-1998.145 -7301.622)"
      />
      <path
        fill="rgba(96,153,242,0.2)"
        className="theme-color-two"
        d="M2008.49,7390.437h1.686v2.613h-1.686Z"
        transform="translate(-1998.145 -7382.788)"
      />
      <path
        fill="#ffffff"
        className="theme-color-one"
        d="M2008.49,7308.85h17.824v.42H2008.49Z"
        transform="translate(-1998.145 -7301.622)"
      />
      <path
        fill="rgba(96,153,242,0.1)"
        className="theme-color-three"
        d="M5032.684,7441.771a8.848,8.848,0,0,1,.074,1.185,8.667,8.667,0,0,1-.074,1.167h2.246v-2.352Z"
        transform="translate(-5006.762 -7433.858)"
      />
      <path
        fill="rgba(96,153,242,0.1)"
        className="theme-color-three"
        d="M2008.49,7390.7h17.824v-.265H2008.49Z"
        transform="translate(-1998.145 -7382.788)"
      />
      <path
        fill="rgba(96,153,242,0.1)"
        className="theme-color-three"
        d="M2008.49,7898.287h17.824v.576H2008.49Z"
        transform="translate(-1998.145 -7888.023)"
      />
      <path
        fill="rgba(96,153,242,0.1)"
        className="theme-color-three"
        d="M2008.49,7390.437h1.686v.265h-1.686Z"
        transform="translate(-1998.145 -7382.788)"
      />
    </g>
    <g className="coin4">
      <path
        fill="#ffffff"
        className="theme-color-one"
        d="M2008.49,8010.125h17.824v3.611H2008.49Z"
        transform="translate(-1998.145 -7999.285)"
      />
      <path
        fill="rgba(96,153,242,0.2)"
        className="theme-color-two"
        d="M2008.49,8091.711h1.686v2.616h-1.686Z"
        transform="translate(-1998.145 -8080.451)"
      />
      <path
        fill="#ffffff"
        className="theme-color-one"
        d="M2008.49,8010.125h17.824v.422H2008.49Z"
        transform="translate(-1998.145 -7999.285)"
      />
      <path
        fill="rgba(96,153,242,0.1)"
        className="theme-color-three"
        d="M5032.684,8143.048a8.869,8.869,0,0,1,.074,1.185,8.583,8.583,0,0,1-.074,1.164h2.246v-2.349Z"
        transform="translate(-5006.762 -8131.523)"
      />
      <path
        fill="rgba(96,153,242,0.1)"
        className="theme-color-three"
        d="M2008.49,8091.976h17.824v-.265H2008.49Z"
        transform="translate(-1998.145 -8080.451)"
      />
      <path
        fill="rgba(96,153,242,0.1)"
        className="theme-color-three"
        d="M2008.49,8599.563h17.824v.576H2008.49Z"
        transform="translate(-1998.145 -8585.686)"
      />
      <path
        fill="rgba(96,153,242,0.1)"
        className="theme-color-three"
        d="M2008.49,8091.711h1.686v.265h-1.686Z"
        transform="translate(-1998.145 -8080.451)"
      />
    </g>
    <g className="coin5">
      <path
        fill="#ffffff"
        className="theme-color-one"
        d="M1734.4,8711.4h17.829v3.61H1734.4Z"
        transform="translate(-1725.463 -8696.949)"
      />
      <path
        fill="rgba(96,153,242,0.2)"
        className="theme-color-two"
        d="M1734.4,8792.986h1.686v2.614H1734.4Z"
        transform="translate(-1725.463 -8778.114)"
      />
      <path
        fill="#ffffff"
        className="theme-color-one"
        d="M1734.4,8711.4h17.829v.42H1734.4Z"
        transform="translate(-1725.463 -8696.949)"
      />
      <path
        fill="rgba(96,153,242,0.1)"
        className="theme-color-three"
        d="M4758.589,8844.321a8.915,8.915,0,0,1,.08,1.185,9.709,9.709,0,0,1-.074,1.167h2.247v-2.352Z"
        transform="translate(-4734.079 -8829.184)"
      />
      <path
        fill="rgba(96,153,242,0.1)"
        className="theme-color-three"
        d="M1734.4,8793.25h17.829v-.264H1734.4Z"
        transform="translate(-1725.463 -8778.114)"
      />
      <path
        fill="rgba(96,153,242,0.2)"
        className="theme-color-two"
        d="M1734.4,8792.986h1.686v.264H1734.4Z"
        transform="translate(-1725.463 -8778.114)"
      />
      <path
        fill="rgba(96,153,242,0.1)"
        className="theme-color-three"
        d="M1734.4,9300.84h17.829v.574H1734.4Z"
        transform="translate(-1725.463 -9283.352)"
      />
    </g>
    <g className="coin6">
      <path
        fill="#ffffff"
        className="theme-color-one"
        d="M2008.49,9412.676h17.824v3.613H2008.49Z"
        transform="translate(-1998.145 -9394.613)"
      />
      <path
        fill="rgba(96,153,242,0.2)"
        className="theme-color-two"
        d="M2008.49,9494.262h1.686v2.618h-1.686Z"
        transform="translate(-1998.145 -9475.776)"
      />
      <path
        fill="#ffffff"
        className="theme-color-one"
        d="M2008.49,9412.676h17.824v.418H2008.49Z"
        transform="translate(-1998.145 -9394.613)"
      />
      <path
        fill="rgba(96,153,242,0.1)"
        className="theme-color-three"
        d="M5032.684,9545.6a8.946,8.946,0,0,1,.074,1.186,8.557,8.557,0,0,1-.074,1.165h2.246V9545.6Z"
        transform="translate(-5006.762 -9526.848)"
      />
      <path
        fill="rgba(96,153,242,0.1)"
        className="theme-color-three"
        d="M2008.49,9494.528h17.824v-.267H2008.49Z"
        transform="translate(-1998.145 -9475.776)"
      />
      <path
        fill="rgba(96,153,242,0.2)"
        className="theme-color-two"
        d="M2008.49,9494.262h1.686v.267h-1.686Z"
        transform="translate(-1998.145 -9475.776)"
      />
      <path
        fill="rgba(96,153,242,0.1)"
        className="theme-color-three"
        d="M2008.49,10002.112h17.824v.576H2008.49Z"
        transform="translate(-1998.145 -9981.012)"
      />
    </g>
    <g className="coin7">
      <path
        fill="#ffffff"
        className="theme-color-one"
        d="M1704.146,10113.951h17.823v3.613h-17.823Z"
        transform="translate(-1695.368 -10092.276)"
      />
      <path
        fill="rgba(96,153,242,0.2)"
        className="theme-color-two"
        d="M1704.146,10196.455h1.685v2.611h-1.685Z"
        transform="translate(-1695.368 -10174.354)"
      />
      <path
        fill="#ffffff"
        className="theme-color-one"
        d="M1704.146,10113.951h17.823v.424h-17.823Z"
        transform="translate(-1695.368 -10092.276)"
      />
      <path
        fill="rgba(96,153,242,0.1)"
        className="theme-color-three"
        d="M4728.339,10247.789a9.654,9.654,0,0,1,.075,1.185,8.51,8.51,0,0,1-.075,1.163h2.247v-2.348Z"
        transform="translate(-4703.986 -10225.424)"
      />
      <path
        fill="rgba(96,153,242,0.1)"
        className="theme-color-three"
        d="M1704.146,10196.721h17.823v-.266h-17.823Z"
        transform="translate(-1695.368 -10174.354)"
      />
      <path
        fill="rgba(96,153,242,0.2)"
        className="theme-color-two"
        d="M1704.146,10196.455h1.685v.266h-1.685Z"
        transform="translate(-1695.368 -10174.354)"
      />
      <path
        fill="rgba(96,153,242,0.1)"
        className="theme-color-three"
        d="M1704.146,10703.39h17.823v.576h-17.823Z"
        transform="translate(-1695.368 -10678.679)"
      />
    </g>
    <g className="coin8">
      <path
        fill="#ffffff"
        className="theme-color-one"
        d="M2008.49,10815.228h17.824v3.615H2008.49Z"
        transform="translate(-1998.145 -10789.941)"
      />
      <path
        fill="rgba(96,153,242,0.2)"
        className="theme-color-two"
        d="M2008.49,10897.73h1.686v2.615h-1.686Z"
        transform="translate(-1998.145 -10872.019)"
      />
      <path
        fill="#ffffff"
        className="theme-color-one"
        d="M2008.49,10815.228h17.824v.426H2008.49Z"
        transform="translate(-1998.145 -10789.941)"
      />
      <path
        fill="rgba(96,153,242,0.1)"
        className="theme-color-three"
        d="M5032.684,10949.066a9.35,9.35,0,0,1,0,2.352h2.246v-2.352Z"
        transform="translate(-5006.762 -10923.09)"
      />
      <path
        fill="rgba(96,153,242,0.1)"
        className="theme-color-three"
        d="M2008.49,10898h17.824v-.266H2008.49Z"
        transform="translate(-1998.145 -10872.019)"
      />
      <path
        fill="rgba(96,153,242,0.2)"
        className="theme-color-two"
        d="M2008.49,10897.73h1.686v.266h-1.686Z"
        transform="translate(-1998.145 -10872.019)"
      />
      <path
        fill="rgba(96,153,242,0.1)"
        className="theme-color-three"
        d="M2008.49,11405.582h17.824v.574H2008.49Z"
        transform="translate(-1998.145 -11377.254)"
      />
    </g>
    <g transform="translate(0 12.512)" className="face-coin">
      <path
        fill="rgba(96,153,242,0.7)"
        className="theme-color-four"
        d="M1737.4,8711.4a8.509,8.509,0,0,1,5.236,3.61H1734.4v-3.61Z"
        transform="translate(-1725.463 -8709.461)"
      />
      <path
        fill="rgba(96,153,242,0.7)"
        className="theme-color-four"
        d="M1742.22,9300.84c.146.182.283.377.416.574H1734.4v-.574Z"
        transform="translate(-1725.463 -9295.864)"
      />
      <path
        fill="rgba(96,153,242,0.7)"
        className="theme-color-four"
        d="M2015.317,9412.676a8.414,8.414,0,0,1,1.335,3.613h-8.163v-3.613Z"
        transform="translate(-1998.145 -9407.123)"
      />
      <path
        fill="rgba(96,153,242,0.7)"
        className="theme-color-four"
        d="M2015.317,9412.676c.089.137.179.279.26.418h-7.087v-.418Z"
        transform="translate(-1998.145 -9407.123)"
      />
      <path
        fill="rgba(96,153,242,0.7)"
        className="theme-color-four"
        d="M1712.8,9494.262c.052.089.1.179.151.267h-7.238v-.267Zm.987,2.618c.033.187.066.38.09.573h-8.163v-.573Zm.09.573q.029.212.042.425h-9.773v-.425Z"
        transform="translate(-1695.368 -9488.29)"
      />
      <path
        fill="rgba(96,153,242,0.7)"
        className="theme-color-four"
        d="M1713.876,10113.951a8.815,8.815,0,0,1,.066,1.068,8.559,8.559,0,0,1-.387,2.545h-9.41v-3.613Z"
        transform="translate(-1695.368 -10104.788)"
      />
      <path
        fill="rgba(96,153,242,0.7)"
        className="theme-color-four"
        d="M1713.919,10196.455a2.4,2.4,0,0,1,.014.266h-9.787v-.266Zm-.208,2.611c-.042.2-.1.387-.156.576h-9.41v-.576Zm-.156.576c-.047.146-.094.281-.147.424h-7.7v-.424Z"
        transform="translate(-1695.368 -10186.868)"
      />
      <path
        fill="rgba(96,153,242,0.7)"
        className="theme-color-four"
        d="M2016.332,10815.228a8.489,8.489,0,0,1-2.261,3.615h-5.581v-3.615Z"
        transform="translate(-1998.145 -10802.452)"
      />
      <path
        fill="rgba(96,153,242,0.7)"
        className="theme-color-four"
        d="M2016.186,10897.73c-.033.09-.066.172-.1.266h-7.593v-.266Zm-1.563,2.615c-.175.2-.359.391-.552.578h-5.581v-.578Z"
        transform="translate(-1998.145 -10884.531)"
      />
      <path
        fill="#eff5ff"
        className="theme-color-five"
        d="M8.494,8334.637A8.494,8.494,0,1,1,0,8343.129,8.492,8.492,0,0,1,8.494,8334.637Z"
        transform="translate(0 -8334.637)"
      />
      <path
        fill="#ffffff"
        className="theme-color-one"
        d="M397,8725.151a6.482,6.482,0,1,1-6.483,6.481A6.479,6.479,0,0,1,397,8725.151Z"
        transform="translate(-388.503 -8723.141)"
      />
      <path
        fill="rgba(96,153,242,0.2)"
        className="theme-color-two"
        d="M397,8725.151a6.45,6.45,0,0,1,3.725,1.18,6.482,6.482,0,0,0-6.993,10.9A6.482,6.482,0,0,1,397,8725.151Z"
        transform="translate(-388.503 -8723.141)"
      />
      <path
        fill="#335284"
        className="theme-color-six"
        d="M1233.921,9056.011a2.284,2.284,0,0,1,.378-1.379,1.751,1.751,0,0,1,1.133-.681v-.623h1.171v.623a1.781,1.781,0,0,1,1.152.671,2.307,2.307,0,0,1,.383,1.389v.265h-1.355v-.351a.875.875,0,0,0-.189-.641.715.715,0,0,0-.524-.185.727.727,0,0,0-.529.185.876.876,0,0,0-.189.641,1.33,1.33,0,0,0,.208.747,2.913,2.913,0,0,0,.52.584c.213.181.434.359.675.528a5.823,5.823,0,0,1,.67.588,3.026,3.026,0,0,1,.52.75,2.352,2.352,0,0,1,.208,1.029,2.311,2.311,0,0,1-.392,1.4,1.787,1.787,0,0,1-1.157.671v.613h-1.171v-.613a1.763,1.763,0,0,1-1.167-.671,2.309,2.309,0,0,1-.387-1.4v-.577h1.355v.662a.83.83,0,0,0,.2.638.792.792,0,0,0,.538.179.8.8,0,0,0,.543-.179.85.85,0,0,0,.2-.638,1.3,1.3,0,0,0-.212-.747,2.9,2.9,0,0,0-.52-.585c-.207-.179-.43-.354-.67-.528a4.978,4.978,0,0,1-.671-.584,2.9,2.9,0,0,1-.519-.753A2.281,2.281,0,0,1,1233.921,9056.011Z"
        transform="translate(-1227.523 -9049.626)"
      />
    </g>
  </svg>
);

export default IconFeeDefaultComponent;
