import React, {Component} from 'react'
import { Redirect } from 'react-router-dom'
import './RegisterAccount.css'
import { _register } from '../../services/Auth';
import { _linkUserToOrganization } from "../../services/Organization"
import FormCardInput from '../../components/FormCardInput'
import IconEmail from '../../components/Icons/IconEmail';
import IconUser from '../../components/Icons/IconUser';
import IconLock from '../../components/Icons/IconLock';
import { ReactComponent as IconGoogle } from '../../assets/svg/IconGoogle.svg';
import Loader from '../../components/Loader';
import Logger from '../../helpers/Logger';
import { SESSION_STORAGE_KEYS } from '../../constants/constants';
import {toastr} from 'react-redux-toastr';
import { errorNotifcation } from "../../helpers/errorHandle";
import { getQueryString } from "../../helpers/dataFormat";

class RegisterAccount extends Component {

    constructor(props){
        super(props);

        this.state = { 
            redirect: false,
            firstname: undefined,
            lastname: undefined,
            email: undefined,
            password: undefined,
            passwordConfirm: undefined,
            errors: {
                firstname: null,
                lastname: null,
                email: null,
                password: null,
                passwordConfirm: null,
            },
            loading: false,
            errorMessages: {}
        }
    }

    setLoading = (loading) => {
        this.setState({
            loading
        });
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleSubmit = () => {
        let { firstname, lastname, email, password, passwordConfirm } = this.state;
        let errors = {
            firstname: !firstname ? true : false,
            lastname: !lastname ? true : false,
            email: !email ? true : false,
            password: !password ? true : false,
            passwordConfirm: !passwordConfirm ? true : false
        };
        this.setState({errors}, () => {
            if(!errors.password && !errors.passwordConfirm)
                if (this.validatePassword()) this.onRegister();
        });
        
        
        return !(errors.firstname || errors.lastname || errors.email || errors.password || errors.passwordConfirm);
    }

    validatePassword = () => {
        let { password, passwordConfirm, errors } = this.state;
        errors = {
            ...this.state.errors,
            password: password !== passwordConfirm,
            passwordConfirm: password !== passwordConfirm 
        }
        this.setState({errors}, () => {});
        return !(errors.password || errors.passwordConfirm);
    }

    onRegister = async () => {
        try {
            this.setLoading(true);
            let { firstname, lastname, email, password } = this.state;
            const payload = {
                username: email,
                firstName: firstname,
                lastName: lastname,
                email,
                password: password,
                invitation: sessionStorage.getItem(SESSION_STORAGE_KEYS.INVITATION_USER)
            }
            const registrationData = await _register(payload);
            const uri =  this.props.history.location.search;
            if(uri !== "") {
                const url = getQueryString(uri);
                const linkUserToOrgPayload = {
                    cognitoID: registrationData.data.id,
                    token: url.get("redirect").split("validate/")[1],
                    confirm: true
                };
                await _linkUserToOrganization(linkUserToOrgPayload);
                window.location.replace(url.get("redirect"));
            }

            toastr.success("", "Votre compte a été créé avec succès. Bien vouloir consulter votre boite mail afin de valider votre addresse.");
            this.setState({
                redirect: true
            });
        } catch (error) {
            this.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    handleError = (err) => {
        let { errors, errorMessages } = this.state;
        const status = err?.response?.status;
        let message = err?.response?.data || "Erreur lors de la création du compte";
        if (status === 409)
            message = "Cette adresse email est déjà utilisée.";
            errorMessages["email"] = "Cette adresse email est déjà utilisée.";
        errors = {
            ...this.state.errors,
        }
        this.setState({errors, errorMessages});
        Logger.error("Register Account", message, err);
        errorNotifcation(err?.response?.status, message, "Création du compte");
    }
    
    componentDidMount = () => {
    }

    render(){
        let { redirect, errors, firstname, lastname, email, password, passwordConfirm, loading} = this.state;
        if(redirect){
            return (
                <Redirect to={"/"} />
            )
        }
        return(
            <div className="registerAcc">
                <div className="co-wrapper">
                    <div className="co-form-wrapper">
                        <span className="co-form-title mt-3">NOUVEAU COMPTE</span>
                        <div className="co-fields-wrapper container">
                            <div className="row mb-2">
                                <FormCardInput type="text" onChange={this.handleChange} value={lastname || ''} 
                                    name="lastname" prependIcon={<IconUser color={ errors.lastname ? 'red' : '#177BD1'}/>} 
                                    onBlur={null} placeholder="Nom" 
                                    error={errors.lastname}/>
                            </div>
                            <div className="row mb-2">
                                <FormCardInput type="text" onChange={this.handleChange} value={firstname || ''} 
                                    name="firstname" prependIcon={<IconUser color={ errors.firstname ? 'red' : '#177BD1'}/>} 
                                    onBlur={null} placeholder="Prénom" 
                                    error={errors.firstname}/>
                            </div>
                            <div className="row mb-2">
                                <FormCardInput type="email" onChange={this.handleChange} value={email || ''} 
                                    name="email" prependIcon={<IconEmail color={ errors.email ? 'red' : '#177BD1'}/>} 
                                    onBlur={null} placeholder="Email" />
                            </div>
                            <div className="row mb-2">
                                <FormCardInput type="password" onChange={this.handleChange} value={password || ''} 
                                    name="password" prependIcon={<IconLock color={ errors.password ? 'red' : '#177BD1'}/>} 
                                    onBlur={null} placeholder="Mot de passe" 
                                    error={errors.password}/>
                            </div>
                            <div className="row mb-4">
                                <FormCardInput type="password" onChange={this.handleChange} value={passwordConfirm || ''} 
                                    name="passwordConfirm" prependIcon={<IconLock color={ errors.passwordConfirm ? 'red' : '#177BD1'}/>} 
                                    onBlur={null} placeholder="Confirmer le mot de passe" 
                                    error={errors.passwordConfirm}/>
                            </div>
                            <div className="row mt-10 pl-2 pr-2">
                            
                                <button className="co-form-button" type="submit" disabled={loading} onClick={this.handleSubmit}>
                                {loading ? (<div className="d-flex">
                                <Loader loadData={loading} />  "En cours..."
                                </div>): "Créer mon compte"}
                                </button>
                            </div>
                            <div className="row mt-3 pl-2 pr-2 mb-4">
                                <button className="ra-form-google" type="submit" onClick={this.handleSubmit}>
                                    <div className="row">
                                        <IconGoogle />
                                        <span className="ml-10"> Créer avec google</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default RegisterAccount;