import React, { Component } from 'react'
import Countries from 'country-data';
import { connect } from "react-redux";
import IconMap from '../Icons/MapIcon'
import IconCall from '../Icons/IconCall'
import IconMail from '../Icons/IconMail'
import IconPlus from '../Icons/IconPlus'
import IconAddress from '../Icons/AddressIcon'
import IconBankTransfert from '../Icons/BankTransferIcon'
import IconBank from '../Icons/BankComponent'
import Tag from '../Tag'
import EditableInput from '../EditableInput';
import "simple-react-validator/dist/locale/fr";
import SimpleReactValidator from "simple-react-validator";
import { setCoordFormValid } from '../../redux/action/ValidationAction';
import { REGEX } from "../../constants/constants";

class Coordonnee extends Component {
    constructor(props) {
        super(props)
        this.state = {
            focusedNationality: false,
            focusedCity: false,
            focusedAddress: false,
            focusedPhone1: false,
            focusedPhone2: false,
            focusedEmail: false,
            modePay: [{value: 'Virement'}, {value: 'Espèce'}, {value: 'Chèque'}],
            nationality: '',
            valid: {
                city: true,
                email: true,
            },
            errors: {
                city: '',
                email: '',
            }
        }

        this.validator = new SimpleReactValidator({autoForceUpdate: this, locale: 'fr'});
    }
    focusNationality = (value) => {
        this.setState({
            focusedNationality: !value
        });
    }
    focusCity = (value) => {
        this.setState({
            focusedCity: !value
        })
    }
    focusAddress = (value) => {
        this.setState({
            focusedAddress: !value
        })
    }
    focusPhone1 = (value) => {
        this.setState({
            focusedPhone1: !value
        })
    }
    focusPhone2 = (value) => {
        this.setState({
            focusedPhone2: !value
        })
    }
    focusEmail = (value) => {
        this.setState({
            focusedEmail: !value
        })
    }
    focusPay = (value) => {
        this.setState({
            focusedPay: !value
        })
    }

    onChangeCity(e) {
        this.props.onChangeCity(e)
    }

    onChangeAddress(e) {
        this.props.onChangeAddress(e);
    }

    onChangePhone1(e) {
        this.props.onChangePhone1(e);
    }

    onChangeEmail(e) {
        this.props.onChangeEmail(e)
    }
    onChangeBank() {
        if(this.props.pay === 'Virement'){
            this.props.onChangeBank()
        }
    }

    validCoordForm = (noShowMessage) => {
        if (this.validator.allValid()) {
            this.props.setCoordFormValid(true);
            this.validator.purgeFields();
            return true;
        } else {
            this.props.setCoordFormValid(false);
            if(!noShowMessage) this.validator.showMessages();
            return false;
        }
    }
    resetCoordForm = () => {
        this.validator.purgeFields();
        this.validator.hideMessages();
        this.props.setCoordFormValid(false);
    }

    componentDidMount = () => {
        this.props.onRef(this);
        this.resetCoordForm();
    }
    componentWillUnmount = () => {
        this.props.onRef(undefined);
    }

    getBankClassname = () => {
        if(this.props.pay === 'Virement' && this.props.bankError === false) {
            return 'd-flex align-items-center box-child'
        }else if(this.props.pay === 'Virement' && this.props.banks.length > 0){
            return 'd-flex align-items-center box-child'
        } else if(this.props.pay === 'Virement' && this.props.bankError === true){
            return ' input-error d-flex align-items-center box-child'
        }  else{
            return ' input-readonly d-flex align-items-center box-child'
        }
    }

    renderFlag = () => {
        const country = Countries.countries.all[0];
        if(country)
            return country.emoji
        else
            return ''
    }
    
    renderDefaultCountry = () => {
        const country = Countries.countries.all.find(country => country.name === this.props.nationality)
        if(country) return {flag: country.emoji, name: country.name}
        else return ''
    }

    renderBankInput = () => {
        let { banks, onRemoveBank } = this.props;
        if(this.props.pay && this.props.pay === 'Virement')
        return(
            <div>
                    <div className="label-input ml-3 flex-column">
                        <div className='d-flex align-items-center'><span className='mr-3'><IconBank width={15} height={15} active /></span>Coordonnées bancaires</div>
                        <div className='d-flex tag-container mt-2 mb-2'>
                            {
                                banks ? banks.map((bank, index) => {
                                    return <Tag key={index} active={bank.active} title={bank.name} onClick={() => this.props.onEditBank(bank)} onRemove={() => onRemoveBank(index)}/>
                                }) : null
                            }
                        </div>
                        <button className='btn-plus ' onClick={() => this.onChangeBank()}>
                            <IconPlus />
                        </button>
                    </div>
                    
                </div>
        )
    }

    errorMsg = (error) => {
        return this.props.formCoordValid ? "" : error;
    }

    renderNewCoord = () => {
        return(
            <div className='px-0'>
            <div className="header-preview">
                <div className="row pt-1 pl-3">
                    <div className="col-md-6">
                        <div className="label-input">Reside au </div>
                        <EditableInput type="select" name="livingCountry" options={this.props.nationalities} optionIcon='emoji'
                        optionValue='name' className="pl-30-10" onBlur={event => {}} 
                        value={this.props.nationality ? this.props.nationality : ""}
                        onChange={this.props.onChangeNationality}
                        placeholder="Residence..."
                        state={'preview'} editable withIcon={this.renderDefaultCountry().flag}
                        />
                    </div>
                    <div className="col-md-6">
                        <div className="label-input">Dans la ville de </div>
                        <EditableInput type="text" name="livingTown" value={this.props.city ? this.props.city : ""}
                            onBlur={event => {}} placeholder={"Entrez une ville"}
                            className="pl-30-10" onChange={this.props.onChangeCity}
                            error={{msg: this.errorMsg(this.validator.message('ville', this.props.city, 'required|string')), placement: "top"}}
                            state={'preview'} editable withIcon={<IconMap active />} 
                            required
                        />
                    </div>
                </div>
                <div className="row pt-1 pl-3">
                    <div className="col-md-6">
                        <div className="label-input">Telephone </div>
                        <EditableInput type="text" name="phone1" value={this.props.phone1 ? this.props.phone1 : ""}
                            className="pl-30-10" onBlur={event => {}} placeholder={"Telephone 1"}
                            onChange={this.props.onChangePhone1}
                            state={'preview'} editable withIcon={<IconCall width={15} height={15} active />}
                            error={{msg: this.errorMsg(this.validator.message('téléphone 1', this.props.phone1, `regex:${REGEX.PHONE}`)), placement: "top"}} />
                        <EditableInput type="text" name="phone2" value={this.props.phone2 ? this.props.phone2 : ""}
                            className="pl-30-10" onBlur={event => {}} placeholder={"Telephone 2"}
                            onChange={this.props.onChangePhone2}
                            state={'preview'} editable withIcon={<IconCall width={15} height={15} active />}
                            error={{msg: this.errorMsg(this.validator.message('téléphone 2', this.props.phone2, `regex:${REGEX.PHONE}`)), placement: "bottom"}} />
                    </div>
                    <div className="col-md-6">
                        <div className="label-input">Email </div>
                        <EditableInput type="email" name="email" value={this.props.email ? this.props.email : ""}
                            className="pl-30-10" onBlur={event => {}} placeholder="Entrez un e-mail"
                            onChange={this.props.onChangeEmail} required
                            error={{msg: this.errorMsg(this.validator.message('email', this.props.email, 'required|email')), placement: "bottom"}}
                            state={'preview'} editable withIcon={<IconMail width={15} height={10.38} active />} />
                    </div>
                </div>
                <div className="row pt-1 pl-3">
                    <div className="col-md-12">
                        <div className="label-input">Adresse </div>
                        <EditableInput type="text" name="staffaddr" value={this.props.address ? this.props.address : ""}
                            className="pl-30-10" onBlur={event => {}} placeholder="Addresse du staff"
                            onChange={this.props.onChangeAddress} multiple
                            state={'preview'} editable withIcon={<IconAddress width={15} height={10.38} active />} />
                    </div>
                </div>
            </div>
            <div className="content-preview">
                <div className="row pt-1 pl-3">
                    <div className="col-md-12">
                        <div className="label-input">Mode de paiement </div>
                        <EditableInput type="select" name="paymentMode" options={this.state.modePay ? this.state.modePay : ""} optionValue={'value'}
                            className="pl-30-10" onBlur={event => {}} placeholder="Selectionner un mode de paiement"
                            onChange={this.props.onChangePay}
                            state={'preview'} editable value={this.props.pay}
                            withIcon={<IconBankTransfert width={18} height={18} active />} />
                    </div>
                </div>
                { this.renderBankInput() }
            </div>

        </div>
        )
    }

    render() {
        
        return(
            this.renderNewCoord()
        )
    } 
}

const mapStateToProps = (state) => {
    return {
        defaultOrganization: state.organizations.defaultOrganization,
        formCoordValid: state.validation.formCoordValid,
    }
}

export default connect(mapStateToProps, { setCoordFormValid })(Coordonnee)