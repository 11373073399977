import { SET_PAYSLIPS, ADD_PAYSLIPS_MULTIPLE, UPDATE_PAYSLIP_ITEM, GENERATE_PAYSLIP_ITEM, SET_PAYSLIPS_SENT_BY_MAIL, SELECTED_PAYSLIP } from '../action-type/payslipType';

export const setPayslips = (payslips) => ({
    type: SET_PAYSLIPS,
    payload: payslips
})

export const addPayslipsMultiple = (payslips) => ({
    type: ADD_PAYSLIPS_MULTIPLE,
    payload: payslips
})

export const updatePayslip = (payslip) => ({
    type: UPDATE_PAYSLIP_ITEM,
    payload: payslip
})

export const generatePayslip = (payslip) => ({
    type: GENERATE_PAYSLIP_ITEM,
    payload: payslip
})

export const setPayslipsSentByMail = (result) => ({
    type: SET_PAYSLIPS_SENT_BY_MAIL,
    payload: result
})

export const selectPayslip = (payslip) => ({
    type: SELECTED_PAYSLIP,
    payload: payslip
})
