import React from "react";

const IoniconsSvgIosTimeComponent = (props) => (
  <svg width={props.width ? props.width : 17.172} height={props.height ? props.height : 17.172} viewBox="0 0 17.172 17.172">
    <defs>
      <style>{`.time{fill:${props.active ? '#177bd1;' : '#b5b5b5;'}}`}</style>
    </defs>
    <path
      className="time"
      d="M56.586,48a8.586,8.586,0,1,0,8.586,8.586A8.585,8.585,0,0,0,56.586,48Zm.578,9.329a.58.58,0,0,1-.578.578H52.623a.578.578,0,0,1,0-1.156h3.385V51.3a.578.578,0,1,1,1.156,0Z"
      transform="translate(-48 -48)"
    />
  </svg>
);

export default IoniconsSvgIosTimeComponent;
