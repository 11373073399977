import React, { Component, useState } from 'react';
import {FormControl, Dropdown} from "react-bootstrap";
import ArrowDown from "../Icons/ArrowDown";

import "./CustomDropdown.css";

const CustomToggle = React.forwardRef(({ children, onClick, disabled }, ref) => (
    <button
        ref={ref}
        className="custom-dropdown-btn"
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        {!disabled ? <ArrowDown color="#707070" width={9} height={5} /> : <></>}
    </button>
));

const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy, placeholderAuther, placeholderFilter, other, handleOther, submit, disabled }, ref) => {
            const [value, setValue] = useState('');
            const [valueOther, setValueOther] = useState('');
            return (
                <div
                    ref={ref}
                    style={style}
                    className={className}
                    aria-labelledby={labeledBy}
                >
                    {!disabled ? <FormControl
                        autoFocus
                        className="custom-dropdown-filter-input"
                        placeholder={ placeholderFilter ? placeholderFilter : "Filter"}
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                    /> :
                    <></>}
                    {!disabled ? 
                    <ul className="list-unstyled">
                        { other ? 
                        <input 
                            type="text" 
                            className="new-elm" 
                            placeholder={placeholderAuther ? placeholderAuther : "Autre..."} 
                            name="valueOther" 
                            value={valueOther} 
                            onChange={(e) => setValueOther(e.target.value)} 
                            onBlur={(e) => {handleOther(e.target.value); setValueOther("")}} 
                            onKeyPress={(e) => submit(e.key, e.target.value)} 
                        /> : <></> }
                        {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().startsWith(value),
                        )}
                    </ul>:
                    <></>}
                </div>
            );
        },
    );



export class CustomDropdown extends Component {

    constructor(props) {
        super(props);
        this.state = { };
    }

    onSelect = (ek, e) => {
        if(this.props.onSelect){
            const evt = {
                target: {
                    name: this.props.name,
                    value: e.target.innerHTML,
                }
            }
            this.props.onSelect(evt);
        }
    }

    handleOther = (value) => {
        if(this.props.onSelect){
            const evt = {
                target: {
                    name: this.props.name,
                    value,
                }
            }
            this.props.onSelect(evt);
        }
    }

    submit = (key, value) => {
        if(key === "Enter"){
            this.handleOther(value);
        }
    }

    renderOptions = () => {
        if(this.props.options && !this.props.disabled){
            return this.props.options.map((elm, i) =>{
                return(
                    <Dropdown.Item key={i} eventKey={i} active={elm[this.props.optionKey] === this.props.value}  onSelect={this.onSelect} >{elm[this.props.optionKey]}</Dropdown.Item>
                );
            })
        }
    }

    render() {
        return (
            <Dropdown>
                <Dropdown.Toggle 
                    as={CustomToggle} 
                    id="dropdown-custom-components" 
                    disabled={this.props.disabled}
                >
                    <span> { this.props.value ? <>{this.props.prefixText ? <span>{this.props.prefixText+": " + this.props.value}</span> : this.props.value}</> : this.props.placeholder ? this.props.placeholder : "Select an item..."}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu 
                    as={CustomMenu} 
                    className="custom-dropdown-menu" 
                    placeholderAuther={this.props.placeholderAuther} 
                    placeholderFilter={this.props.placeholderFilter} 
                    other={this.props.other} 
                    handleOther={this.handleOther} 
                    submit={this.submit} 
                    disabled={this.props.disabled} 
                >
                    {this.renderOptions()}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}

export default CustomDropdown;
