import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Routes from './routes/routes'
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import { createStore,applyMiddleware } from 'redux'
import thunk from 'redux-thunk';
import NetworkService from './config/network-service'
import reducer from './redux/reducer'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import ReduxToastr from 'react-redux-toastr'

const store = createStore(reducer,applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
NetworkService.setupInterceptors(store); // setup network interceptor with the store

ReactDOM.render(
    <Provider store={store}>
        <Routes />
        <ReduxToastr
            timeOut={8000}
            newestOnTop={false}
            preventDuplicates
            position="top-right"
            getState={(state) => state.toastr} // This is the default
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick />
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
