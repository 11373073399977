import React from "react";

const DetailsIconComponent = props => (
  <svg width={`${props.width ? props.width : 11.886}`} height={`${props.height ? props.height : 9.91}`} viewBox="0 0 11.886 9.91" {...props}>
    <defs>
      <style>{".a{fill:#fff;}"}</style>
    </defs>
    <path
      fill={`${props.fill ? props.fill : '#fff'}`}
      stroke={`${props.stroke ? props.stroke : 'transparent'}`}
      strokeWidth={`${props.strokeWidth ? props.strokeWidth : 0}`}
      d="M720.04-1750.09a.843.843,0,0,1-.447-.128l-3.581-2.238a.808.808,0,0,1-.1-.071,4.23,4.23,0,0,1-2.695.972,4.227,4.227,0,0,1-4.221-4.223,4.227,4.227,0,0,1,4.221-4.223,4.227,4.227,0,0,1,4.222,4.223,4.17,4.17,0,0,1-.467,1.932l3.512,2.194a.839.839,0,0,1,.376.526.839.839,0,0,1-.107.637A.839.839,0,0,1,720.04-1750.09Zm-5-8.272v4.912h.819v-4.912Zm-1.637.819v4.093h.819v-4.093Zm-1.637.819v3.274h.819v-3.274Zm-1.637-.819v4.093h.819v-4.093Z"
      transform="translate(-709 1760)"
    />
  </svg>
);

export default DetailsIconComponent;
