import React, { Component } from 'react';
import { connect } from 'react-redux';
import IconBack from '../Icons/BackIcon';
import IconNext from '../Icons/NextIcon';
import IconClose from '../Icons/IconClose';
import IconSave from '../Icons/SaveIcon';
import { createStaff } from '../../services/Staff';
import { addStaff, updateStaff, selectStaff, addAllowancesStaff, deleteAllowancesStaff, updateAllowancesStaff } from '../../redux/action/StaffAction';
import { _computeContratBrutSalaryByAllowances, _addContractAllowances, _addContractAllowance, _updateContractAllowance, _deleteContractAllowance, _getContractAllowances, removeDefaultAllowance } from '../../services/Contrat';
import './ModalDetail.css';
import ModalChild from './ModalChild';
import Countries from 'country-data';
import Coordonnee from './Coordonnee';
import CoordonneePreview from './CoodoneePreview';
import ModalBank from './ModalBank';
import CivilInfo from './CivilInfo';
import CivilInfoPreview from './CivilInfoPreview';
import Contrat from './Contrat';
import ContratPreview from './ContratPreview';
import Pay from './Pay';
import PayPreview from './payPreview';
import Loader from '../Loader/Loader';
import BottomToTop from '../Transition/BottomToTop';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import { setCivilFormValid, setContractFormValid, setCoordFormValid, setPayFormValid } from '../../redux/action/ValidationAction';
import moment from "moment";
import Logger from '../../helpers/Logger';
import { toastr } from "react-redux-toastr";
import { errorNotifcation, validationNotification } from "../../helpers/errorHandle";

class ModalDetail extends Component {

    defaultOrganization = JSON.parse(sessionStorage.getItem('defaultOrg'));
    initStateValues = {
        matricule: '',
        civilite: 'Monsieur',
        name: '',
        surname: '',
        birthday: '',
        birthdayPlace: '',
        genre: 'Homme',
        nationality: 'Cameroon',
        numCni: '',
        niu: '',
        expirationDate: null,
        numCnps: '',
        situation: 'Célibataire',
        email: '',
        phone: null,
        role: '',
        brutSalary: 0,
        baseSalary: 0,
        brutSalaryIndicator: 0,
        salary: 0,
        childName: '',
        childSurname: '',
        childBirthday: '',
        nationality2: 'Cameroon',
        city: '',
        address: '',
        phone1: null,
        phone2: null,
        pay: 'Virement',
        bankName: '',
        bankCode: '',
        guichetCode: '',
        compteNum: '',
        ribKey: '',
        active: false,
        poste: '',
        typeContrat: 'CDI',
        beginDate: '',
        endDate: '',
        departure: '',
        nonContributingManager: false,
        service: '',
        primeTransport: 25000,
        category: null,
        echelon: '',
        surSalary: 0,
        transportAllowance: 0,
        housingAllowance: 0,
        time: '40',
        child: [],
        banks: [],
        loading: false,
        nationalities: [],
        salaryGrid: [],
        step: 0,
        modalChild: false,
        modalBank: false,
        duration: 0,
        seniorityInYears: 0,
        seniorityInMonth: 0,
        seniorityTotalInMonth: 0,
        displayCoord: false,
        bankError: false,
        allowances: [],
        valid: {
            name: true,
            surname: true,
            city: true,
            email: true,
            poste: true,
            salary: true,
            housing: true,
            transport: true,
            time: true,
        },
        reload: null,
    };

    constructor(props) {
        super(props);
        this.state = this.initStateValues;
    }
    componentDidMount() {
        let nationalities = Countries.countries.all.map(country => {
            if(country.emoji) {
                return country
            }
            return undefined
        });
        this.setState({
            nationalities: nationalities.sort((a, b) => a.name.localeCompare(b.name))
        });
        //this.hideRightContent();

    }

    onHandleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value 
        })
    }
    onChangeMatricule = (e) => {
        this.setState({
            matricule: e.target.value
        });
    }
    onChangeCivilite = (value) => {
        this.setState({
            civilite: value
        });
    }
    onChangeName = (e) => {
        this.setState({
            name: e.target.value,
        });
    }
    onChangeBirthday = (e) => {
        this.setState({
            birthday: e.target.value,
        });
    }
    onChangeBirthdayPlace = (e) => {
        this.setState({
            birthdayPlace: e.target.value
        });
    }
    onChangeGenre = (value) => {
        this.setState({
            genre: value
        });
    }
    onChangeSurname = (e) => {
        this.setState({
            surname: e.target.value,
        });
    }
    onChangeNationality = (e) => {
        this.setState({
            nationality: e.target.value
        });
    }
    onChangeNumCni = (e) => {
        this.setState({
            numCni: e.target.value
        });
    }
    onChangeExpirationDate = (e) => {
        this.setState({
            expirationDate: e.target.value
        });
    }
    onChangeNumCnps = (e) => {
        this.setState({
            numCnps: e.target.value
        });
    }
    onChangeNiu = (e) => {
        this.setState({
            niu: e.target.value,
        });
    }
    onChangeSituation = (e) => {
        this.setState({
            situation: e.target.value
        });
    }
    onChangeChild = () => {
        this.setState({
            modalChild: true
        });
    }
    onPreviewChild = (event) => {
        let { childs } = this.props.selectedItem;
        this.setState({
            modalChild: true,
            childName: childs[event.staffChild.index].name,
            childSurname: childs[event.staffChild.index].surname,
            childBirthday: childs[event.staffChild.index].birthDate
        });
    }
    onChangeNationality2 = (e) => {
        this.setState({
            nationality2: e.target.value
        })
    }
    onChangeCity = (e) => {
        this.setState({
            city: e.target.value,
        })
    }
    onChangeAddress = (e) => {
        this.setState({
            address: e.target.value
        })
    }
    onChangePhone1 = (e) => {
        this.setState({
            phone1: e.target.value
        })
    }
    onChangePhone2 = (e) => {
        this.setState({
            phone2: e.target.value
        })
    }
    onChangeEmail = (e) => {
        this.setState({
            email: e.target.value,
        })
    }
    onChangePay = (e) => {
        this.setState({
            pay: e.target.value
        })
    }
    onChangeBank = () => {
        this.setState({
            modalBank: true
        })
    }
    onChangeBankName = (e) => {
        this.setState({
            bankName: e.target.value
        })
    }
    onChangeBankCode = (e) => {
        this.setState({
            bankCode: e.target.value
        })
    }
    onChangeGuichetCode = (e) => {
        this.setState({
            guichetCode: e.target.value
        })
    }
    onChangeCompteNum = (e) => {
        this.setState({
            compteNum: e.target.value
        })
    }
    onChangeRibKey = (e) => {
        this.setState({
            ribKey: e.target.value
        })
    }
    onChangeActive = () => {
        this.setState({
            active: !this.state.active
        })
    }
    onChangePoste = (e) => {
        this.setState({
            poste: e.target.value,
        })
    }
    onChangeContrat = (e) => {
        this.setState({
            typeContrat: e.target.value
        })
    }
    onChangeBeginDate = (e) => {
        this.setState({
            beginDate: e.target.value,
        })
    }
    onChangeEndDate = (e) => {
        
        this.setState({
            endDate:  e.target.value
        })
    }

    clearEndDate = () => {
        this.setState({
            endDate:  null
        })
    }

    onChangeDepartue = (e) => {
        this.setState({
            departure: e.target.value
        })
    }
    onChangeNonContributingManager = (e) => {
        this.setState({
            nonContributingManager: e.target.checked
        })
    }
    onChangeService = (e) => {
        this.setState({
            service: e.target.value
        })
    }

    onChangeSalary = (event) => {
        this.setState({brutSalaryIndicator: event.target.value});
    }

    onBlurSalary = async (salaryParamsData) => {
        this.setState({loading: true});
        if(!salaryParamsData) return false;
        if(!salaryParamsData.brutSalaryIndicator) salaryParamsData.brutSalaryIndicator = this.state.brutSalaryIndicator;
        const brutSalary = +(salaryParamsData.brutSalaryIndicator || this.state.brutSalaryIndicator);
        let contractAllowances = salaryParamsData.contractAllowances;
        if (salaryParamsData.updateDefault) {
            contractAllowances = removeDefaultAllowance(contractAllowances);
        }
        if(brutSalary && brutSalary !== 0){
            try {
                const grid = (await _computeContratBrutSalaryByAllowances(this.defaultOrganization.id, brutSalary,
                    contractAllowances, salaryParamsData.updateDefault)).data;
                this.setState({
                    category: grid.category,
                    echelon: grid.echelon,
                    surSalary: grid.surSalary,
                    baseSalary: grid.baseSalary,
                    brutSalaryIndicator: grid.baseSalary + grid.surSalary,
                    brutSalary: grid.brutSalary,
                    transportAllowance: _getContractAllowances.transportAllowance(grid.contractAllowances),
                    housingAllowance: _getContractAllowances.housingAllowance(grid.contractAllowances),
                    allowances: grid.contractAllowances.length > 0 ? grid.contractAllowances : contractAllowances
                });
                this.props.setPayFormValid(true);
                return true;
                
            } catch (err) {
                Logger.error("Contract", "Une erreur est survenue lors du calcul du salaire.",err);
                errorNotifcation(err?.response?.status, "Erreur pendant le calcul du salaire, veuillez reessayer ultérieurement.");
                return false;
            } finally {
                this.setState({loading: false});
            }
        } else {
            this.props.setPayFormValid(false);
            return true;
        }
    }

    addContractAllowances = async (data, contractId) => {
        let staff = this.props.selectedStaff;
        try{
            const allowance = (await _addContractAllowances(this.defaultOrganization.id, staff.id, data, contractId)).data;
            staff.contracts[0].contractAllowances.push(allowance.data);
            this.props.updateStaff(staff);
            return allowance;
        }catch(error){
            Logger.error("AddContractAllowances", "Error during Add contract allowance", error);
            errorNotifcation(error?.response?.status, "Erreur pendant l'enregistrement des primes, veuillez reessayer ultérieurement.");
            return null;
        }
    }

    addContractAllowance = async (contractId, data, nameAllowance) => {
        try{
            let staff = this.props.selectedStaff;
            const allowance = (await _addContractAllowance(this.defaultOrganization.id, staff.id, data, contractId)).data;
            this.props.addAllowancesStaff(allowance);
            return allowance;
        }catch(error){
            Logger.error("AddContractAllowance", "Error during Add contract allowance", error);
            errorNotifcation(error?.response?.status, "Erreur pendant l'enregistrement de la prime, veuillez reessayer ultérieurement.");
            return null;
        }
    }

    updateContractAllowance = async (contractId, data, nameAllowance) => {
        try{
            const allowance = (await _updateContractAllowance(this.defaultOrganization.id, this.props.selectedStaff.id, data, contractId)).data;
            this.props.updateAllowancesStaff(allowance);
            return allowance;
        }catch(error){
            Logger.error("UpdateContractAllowance","Error during contract allowance update", error);
            errorNotifcation(error?.response?.status, "Erreur pendant la mise à jour de la prime, veuillez reessayer ultérieurement.");
            return null;
        }
    }

    deleteContractAllowance = async (id, contractId) => {
        try{
            const allowance = (await _deleteContractAllowance(this.defaultOrganization.id, this.props.selectedStaff.id, id, contractId)).data;
            this.props.deleteAllowancesStaff(allowance);
            return allowance;
        }catch(error){
            Logger.error("DeleteContractAllowance","Error during deleting contract allowance", error);
            errorNotifcation(error?.response?.status, "Erreur pendant la suppression de la prime, veuillez reessayer ultérieurement.");
            return null;
        }
    }

    onChangeCategory = (e) => {
        this.setState({
            category: e.target.value
        })
    }
    onChangeEchelon = (e) => {
        this.setState({
            echelon: e.target.value
        })
    }
    onChangeTime = (e) => {
        this.setState({
            time: e.target.value,
        })
    }
    blurBeginDate = (duration) => {
        if(duration){
            let time = duration.split('an')
            if(time.length === 2) {
                this.setState({
                    seniorityInYears: +time[0],
                    seniorityInMonth: +time[1],
                    seniorityTotalInMonth: +time[0] * 12 + +time[1]
                })
            } else {
                this.setState({
                    seniorityInYears: 0,
                    seniorityInMonth: +time[0],
                    seniorityTotalInMonth: +time[0]
                })
            }
        }
    }
    onValidate = () => {
        let newChild = this.state.child
        let childData = {
            name: this.state.childName,
            surname: this.state.childSurname,
            birthday: this.state.childBirthday
        }
        newChild.push(childData)
        this.setState({
            child: newChild,
            modalChild: false,
            childName: '',
            childSurname: '',
            childBirthday: ''
        })
    }
    onCancelModalBank = () => {
        this.setState({
            modalBank: false,
            bankName: '',
            bankCode: '',
            compteNum: '',
            guichetCode: '',
            ribKey: '',
            active: false
        })
    }
    onValidateModalBank = () => {
        let newBank = this.state.banks
        let bankData = {
            name: this.state.bankName,
            bankCode: this.state.bankCode,
            compteNum: this.state.compteNum,
            guichetCode: this.state.guichetCode,
            ribKey: this.state.ribKey,
            active: this.state.active
        }
        let index = newBank.findIndex(bank => bank.active === true)
        if(index >= 0 && bankData.active) {
            newBank[index].active = false
        }
        newBank.push(bankData)
        this.setState({
            banks: [ ...newBank ],
            bankName: '',
            bankCode: '',
            compteNum: '',
            guichetCode: '',
            ribKey: '',
            active: false,
            modalBank: false,
        })
    }

    onRemove = (index) => {
        let child = this.state.child
        child.splice(index, 1)
        this.setState({
            child
        })
    }

    onRemoveBank = (index) => {
        let banks = this.state.banks
        banks.splice(index, 1)
        this.setState({
            banks
        })
    }

    onReset = () => {
        this.setState({
            name: '',
            email: '',
            phone: null,
            role: '',
            salary: null,
        });
    }

    setAllowances = (allowances) => {
        this.setState({ allowances });
    }

    resetForms = () => {
        if (this.civilInfoRef) this.civilInfoRef.resetCivilForm();
        if (this.coordonneeRef) this.coordonneeRef.resetCoordForm();
        if (this.contractRef) this.contractRef.resetContractForm();
        if (this.payRef) this.payRef.resetPayForm();
    }

    onClose = () => {
        this.setState({ ...this.initStateValues });
        this.resetForms();
        this.props.onClose();
    }

    onCreateStaff = async () => {
        if(this.payRef.validPayForm()) {
            this.setState({
                loading: true
            });
            const staffData = this.constructData();
            createStaff(this.defaultOrganization.id, staffData).then(result => {
                if(result.data){
                    this.setState({
                        loading: false,
                        ...this.initStateValues,
                    });
                    this.onClose();
                    this.props.getStaffs();
                    toastr.success("", `Staff '${result.data.name?.toUpperCase()}' enregistré avec succès!`);
                }else{
                    this.setState({
                        loading: false
                    });
                }
            }).catch(err => {
                const error = { ...err }
                this.setState({
                    loading: false,
                    errorMsg: ''
                });
                Logger.error("CreateStaff", "Unable to create a new staff.", error);
                validationNotification(error?.response?.status, error?.response?.data?.errors);
            });
        }
    }

    constructData = () => {
        let contractAllowances = this.state.allowances;
        contractAllowances.map(item => {
            return delete item.contractId;
        })
        return {
            organizationId: this.defaultOrganization.id,
            civility: this.state.civilite,
            genre: this.state.genre,
            name:this.state.name,
            surname: this.state.surname,
            birthdate: moment(this.state.birthday).format("YYYY-MM-DD"),
            birthdayPlace: this.state.birthdayPlace,
            nationality: this.state.nationality,
            numberCni: this.state.numCni,
            niu: this.state.niu,
            dateExpirationCni: this.state.expirationDate ? moment(this.state.expirationDate).format("YYYY-MM-DD") : null,
            numberCnps: this.state.numCnps,
            familySituation: this.state.situation,
            // childs: this.state.child,
            country: this.state.nationality2,
            city: this.state.city,
            phone1: this.state.phone1,
            phone2: this.state.phone2,
            payementMode: this.state.pay,
            // bankInfo: this.state.banks,
            email: this.state.email,
            address: this.state.address,
            contracts: [{
                poste: this.state.poste,
                contratType: this.state.typeContrat,
                beginDate: this.state.beginDate,
                endDate: this.state.endDate ? this.state.endDate : null,
                reasonOfDeparture: this.state.departure,
                nonContributingManager: this.state.nonContributingManager,
                seniorityInYears: this.state.seniorityInYears,
                seniorityInMonth: this.state.seniorityInMonth,
                seniorityTotalInMonth: this.state.seniorityTotalInMonth,
                attachment: '',
                service: this.state.service,
                brutSalary: +this.state.brutSalaryIndicator,
                transportAllowance: +this.state.transportAllowance,
                housingAllowance: +this.state.housingAllowance,
                weeklyTime: +this.state.time,
                contractAllowances
            }]
        };
    }

    goToStep = (step) => {
        if(step <= 3){
            this.setState({
                step
            })
        }
    }
    validAndChangeStep = (step) => {
        if (step >= this.state.step) {
            let isCivilFormValid = this.civilInfoRef.validCivilForm(true);
            let isCoordFormValid = this.coordonneeRef.validCoordForm(true);
            let isContractFormValid = this.contractRef.validContractForm(true);
            switch(this.state.step) {
                case 0:
                    if (isCivilFormValid) this.goToStep(this.state.step + 1);
                    else this.civilInfoRef.validCivilForm();
                    break;
                case 1:
                    if (isCoordFormValid) this.goToStep(this.state.step + 1);
                    else this.coordonneeRef.validCoordForm();
                    break;
                case 2:
                    if (isContractFormValid) this.goToStep(this.state.step + 1);
                    else this.contractRef.validContractForm();
                    break;
                default:
                    break;
            }
            
        } else {
            this.goToStep(step);
        }   
    }

    setCivilInfoRef = (ref) => {
        this.civilInfoRef = ref;
    }

    onEdit = (child) => {
        this.setState({
            childName: child.name,
            childSurname: child.surname,
            childBirthday: child.birthday
        },() => {
            this.onChangeChild()
        })
        
    }
    renderCivil = () => {
        return (
            <CivilInfo onRef={this.setCivilInfoRef} key={1} nationalities={this.state.nationalities}
                onChangeCivilite={(e) => this.onChangeCivilite(e)} civilite={this.state.civilite}
                onChangeName={(e) => this.onChangeName(e)} name={this.state.name}
                onChangeBirthday={(e) => this.onChangeBirthday(e)} birthday={this.state.birthday}
                onChangeGenre={(e) => this.onChangeGenre(e)} genre={this.state.genre}
                onChangeSurname={(e) => this.onChangeSurname(e)} surname={this.state.surname}
                onChangeBirthdayPlace={(e) => this.onChangeBirthdayPlace(e)} birthdayPlace={this.state.birthdayPlace}
                onChangeNationality={(e) => this.onChangeNationality(e)} nationality={this.state.nationality}
                onChangeNumCni={(e) => this.onChangeNumCni(e)} numCni={this.state.numCni}
                onChangeExpirationDate={(e) => this.onChangeExpirationDate(e)} expirationDate={this.state.expirationDate}
                onChangeNumCnps={(e) => this.onChangeNumCnps(e)} numCnps={this.state.numCnps}
                onChangeSituation={(e) => this.onChangeSituation(e)} situation={this.state.situation}
                onChangeChild={() => this.onChangeChild()} child={this.state.child}
                onChangeNiu={(e) => this.onChangeNiu(e)} niu={this.state.niu}
                onRemove={(index) => this.onRemove(index)} onEdit={(child) => this.onEdit(child)}
            />
        )
    }
    renderCivilPreview = () => {
        const { childs } = this.props.selectedItem;
        return (
            <CivilInfoPreview 
                valid={this.state.valid} onError={this.onCivilError} 
                data={this.props.selectedItem} 
                onPreviewChild={this.onPreviewChild} 
                childs={ childs }
                nationalities={this.state.nationalities}
                onChangeName={(e) => this.onChangeName(e)}
                onChangeSurname={(e) => this.onChangeSurname(e)}
            />
        )
    }
    setCoordonneeRef = (ref) => {
        this.coordonneeRef = ref;
    }
    onEditBank = (bank) => {
        this.setState({
            bankName: bank.name,
            bankCode: bank.bankCode,
            guichetCode: bank.guichetCode,
            compteNum: bank.compteNum,
            ribKey: bank.ribKey
        },() => {
            this.onChangeBank()
        })
        
    }
    renderCoord = () => {
        return (
            <Coordonnee onRef={this.setCoordonneeRef} bankError={this.state.bankError} nationalities={this.state.nationalities}
                onChangeNationality={(e) => this.onChangeNationality2(e)}
                nationality={this.state.nationality2}
                onChangeCity={(e) => this.onChangeCity(e)}
                city={this.state.city}
                onChangeAddress={(e) => this.onChangeAddress(e)}
                address= {this.state.address}
                onChangePhone1={(e) => this.onChangePhone1(e)}
                phone1={this.state.phone1}
                onChangePhone2={(e) => this.onChangePhone2(e)}
                phone2={this.state.phone2}
                onChangeEmail={(e) => this.onChangeEmail(e)}
                email={this.state.email}
                onChangePay={(e) => this.onChangePay(e)}
                pay={this.state.pay}
                banks={this.state.banks}
                onChangeBank={() => this.onChangeBank()}
                onRemoveBank={(index) => this.onRemoveBank(index)}
                onEditBank={(bank) => this.onEditBank(bank)}
            />
        )
    }
    renderCoordPreview = () => {
        return (
            <CoordonneePreview 
                data={this.props.selectedItem} 
                onHandleBank = {this.onHandleBankClick}
                nationalities={this.state.nationalities}
            />
        )
    }
    onHandleBankClick = (event) => {
        let { bankInfo } = this.props.selectedItem;
        let {index} = event.bankInfos
        
        this.setState({
            modalBank: true,
            bankName: bankInfo[index].name,
            bankCode: bankInfo[index].bankCode,
            guichetCode: bankInfo[index].guichetCode,
            compteNum: bankInfo[index].compteNum,
            ribKey: bankInfo[index].ribKey,
            active: bankInfo[index].active,
        })
    }

    setContractRef = (ref) => {
        this.contractRef = ref;
    }
    renderContract = () => {
        return (
            <Contrat onRef={this.setContractRef}
                onChangePoste={(e) => this.onChangePoste(e)}
                poste={this.state.poste}
                onChangeContrat={(e) => this.onChangeContrat(e)}
                typeContrat={this.state.typeContrat}
                onChangeBeginDate={(e) => this.onChangeBeginDate(e)}
                onBlurBeginDate={(duration) => this.blurBeginDate(duration)}
                duration={this.state.duration}
                seniorityInYears={this.state.seniorityInYears}
                seniorityInMonth={this.state.seniorityInMonth}
                beginDate={this.state.beginDate}
                onChangeEndDate={(e) => this.onChangeEndDate(e)}
                clearEndDate={this.clearEndDate}
                endDate={this.state.endDate}
                onChangeDepartue={(e) => this.onChangeDepartue(e)}
                departure={this.state.departure}
                nonContributingManager={this.state.nonContributingManager ? this.state.nonContributingManager : false}
                onChangeService={(e) => this.onChangeService(e)}
                onChangeNonContributingManager={(e) => this.onChangeNonContributingManager(e)}
                service={this.state.service}
            />
        )
    }

    ContratPreview = () => {
        return (
            <ContratPreview 
                data={this.props.selectedItem.contracts[0]} 
                onHandleContrat = {this.onHandleContrat}
            />
        )
    }
    

    setPayRef = (ref) => {
        this.payRef = ref;
    }
    renderPay = () => {
        return (
            <Pay onRef={this.setPayRef}
                onBlurSalary={this.onBlurSalary}
                onChangeSalary={this.onChangeSalary}
                salaire={this.state.salary}
                brutSalary={this.state.brutSalary}
                baseSalary={this.state.baseSalary}
                surSalary={this.state.surSalary}
                brutSalaryIndicator={this.state.brutSalaryIndicator}
                onChangeCategory={this.onChangeCategory}
                category={this.state.category}
                onChangeEchelon={this.onChangeEchelon}
                echelon={this.state.echelon}
                onChangeTime={this.onChangeTime}
                time={this.state.time}
                transportAllowance={this.state.transportAllowance}
                housingAllowance={this.state.housingAllowance}
                setAllowances={this.setAllowances}
                allowances={this.state.allowances}
            />
        )
    }
    renderPayPreview = () => {
        return (
            <PayPreview
                ref={this.payPreview}
                data={this.props.selectedItem.contracts[0]}
                onHandleContrat = {this.onHandleContrat}
                onAddAllowance={this.addContractAllowance}
                onUpdateAllowance={this.updateContractAllowance}
                onDeleteAllowance={this.deleteContractAllowance}
            />
        )
    }
    modalChild = () => {
        return (
            <ModalChild name={this.state.childName} surname={this.state.childSurname} birthday={this.state.childBirthday}
                onChangeChildName={(e) => {this.setState({childName: e.target.value})}} 
                onChangeChildSurname={(e) => {this.setState({childSurname: e.target.value})}} onChangeChildBirthday={(e) => this.setState({childBirthday: e.target.value})} 
                onCancel={(e) => this.setState({modalChild: false})} onValidate={() => this.onValidate()} 
            />
        )
    }
    modalBank = () => {
        return (
            <ModalBank onChangeBankName={(e) => this.onChangeBankName(e)} BankName={this.state.bankName} 
                onChangeBankCode={(e) => this.onChangeBankCode(e)} BankCode={this.state.bankCode}
                onChangeGuichetCode={(e) => this.onChangeGuichetCode(e)} GuichetCode={this.state.guichetCode}
                onChangeCompteNum={(e) => this.onChangeCompteNum(e)} CompteNum={this.state.compteNum}
                onChangeRibKey={(e) => this.onChangeRibKey(e)} RibKey={this.state.ribKey}
                onChangeActive={(e) => this.onChangeActive(e)} active={this.state.active}
                onCancel={() => this.onCancelModalBank()} onValidate={() => this.onValidateModalBank()} 
            />
        )
    }
    renderCreate = () => {
        let { step } = this.state;
        const styles = {
            tabs: {
                background: '#fff',
            },
            indicator: {
                backgroundColor: '#177BD1',
            }
        };
        return(
            <div className='modal-detail-preview'>
                <Tabs value={step} TabIndicatorProps={{style: {backgroundColor: '#177BD1', borderRadius: '15px'}}} style={styles.tabs}>
                    <Tab onClick={() => this.validAndChangeStep(0)} label="Etat civil" />
                    <Tab onClick={() => this.validAndChangeStep(1)} label="Coordonnées" />
                    <Tab onClick={() => this.validAndChangeStep(2)} label="Contrat" />
                    <Tab onClick={() => this.validAndChangeStep(3)} label="Paie" />
                </Tabs>
                <SwipeableViews index={step} onChangeIndex={this.goToStep}>
                    <div>
                        <BottomToTop in={!this.state.modalChild}>{this.renderCivil()}</BottomToTop>
                        <BottomToTop in={this.state.modalChild}>{this.modalChild()}</BottomToTop>
                    </div>
                    <div>
                        <BottomToTop in={!this.state.modalBank}>{this.renderCoord()}</BottomToTop>
                        <BottomToTop in={this.state.modalBank}>{this.modalBank()}</BottomToTop>
                    </div>
                    <div>{this.renderContract()}</div>
                    <div>{this.renderPay()}</div>
                </SwipeableViews>
            </div>
        )
        
    }
    renderDetail = () => {
        let {  step } = this.state;
        const styles = {
            tabs: {
              background: '#fff',
            },
            indicator: {
                backgroundColor: '#177BD1',
            }
        };
        return(
            <div className='modal-detail-preview mt-4'>

            <Tabs value={step} TabIndicatorProps={{style: {backgroundColor: '#177BD1', borderRadius: '15px'}}} style={styles.tabs}>
                <Tab onClick={() => this.goToStep(0)} label="Etat civil" />
                <Tab onClick={() => this.goToStep(1)} label="Coordonnées" />
                <Tab onClick={() => this.goToStep(2)} label="Contrat" />
                <Tab onClick={() => this.goToStep(3)} label="Paie" />
            </Tabs>
            <SwipeableViews index={step} onChangeIndex={this.goToStep}>
                <div>
                    <BottomToTop in={!this.state.modalChild}>{this.renderCivilPreview()}</BottomToTop>
                    <BottomToTop in={this.state.modalChild}>{this.modalChild()}</BottomToTop>
                </div>
                <div>
                    <BottomToTop in={!this.state.modalBank}>{this.renderCoordPreview()}</BottomToTop>
                    <BottomToTop in={this.state.modalBank}>{this.modalBank()}</BottomToTop>
                </div>
                <div>{this.ContratPreview()}</div>
                <div>{this.renderPayPreview()}</div>
            </SwipeableViews>
            </div>
        )
    }
    render() {
        let { type, display } = this.props;
        return(
            <div className={ display ? 'modal-active modal-detail': 'd-none' }>
                <div className={'d-flex row m-0 align-items-center justify-content-between p-3'}>
                    <div className='d-flex align-items-center justify-content-start'><span className='mr-3 details-header text-color-social'>
                    {
                        type === 'create' ? 'Nouveau staff' : `${this.props.selectedItem.surname} ${this.props.selectedItem.name}`
                    }</span> <Loader className='loader-create-staff' style={{width: '20px', height: '20px'}}  loadData={this.state.loading} /></div>
                    <div>
                        <button className='btn-close' onClick={this.onClose}>
                            <IconClose />
                        </button>
                    </div>
                </div>
                {
                    type === 'create' ? this.renderCreate() : this.renderDetail()
                }
                {
                    
                    <div className='container-btn px-3 py-2'>
                        <div className='d-flex justify-content-between align-items-center'>
                            {
                                this.state.step === 0 ? <div></div> :
                                <button className='btn-back' onClick={() => this.goToStep(this.state.step - 1)}>
                                    <IconBack/>
                                </button>
                            }
                            {
                                (type === 'create' && this.state.step === 3) ? <button  
                                    className='btn-next d-flex align-items-center' onClick={() => this.onCreateStaff()} disabled={this.state.loading}>
                                    <IconSave color="#FFFFFF" />
                                    <span className='ml-2'>Soumettre</span>
                                </button> :
                                <button className='btn-next' onClick={() => type === 'create' ? this.validAndChangeStep(this.state.step + 1) : this.goToStep(this.state.step + 1)}>
                                    <IconNext />
                                </button>
                            }
                            
                        </div>
                    </div> 
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        selectedStaff: state.staffs.selectedStaff,
        defaultOrganization: state.defaultOrganization,
        formCivilValid: state.validation.formCivilValid,
        formCoordValid: state.validation.formCoordValid,
        formContractValid: state.validation.formContractValid,
        formPayValid: state.validation.formPayValid
    }
}

export default connect(mapStateToProps, {addStaff, updateStaff, selectStaff, setCivilFormValid, setContractFormValid, setCoordFormValid, setPayFormValid, updateAllowancesStaff, deleteAllowancesStaff, addAllowancesStaff})(ModalDetail)