import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import './OrgsTopModal.css'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addClass, removeClass } from "../../../helpers/DOM";
import IconClose from '../../Icons/IconClose';
import IconPlus from '../../Icons/IconPlus';
import OrgLoader from '../../loaders/OrgLoader';
import { setOrganizations, setDefaultOrganizationWithHabilitations } from '../../../redux/action/OrganisationAction';
import { _getOrganizationsByUserId } from '../../../services/Organization';
import { _extractPayloadFromToken } from '../../../helpers/jwt';
import { SESSION_STORAGE_KEYS } from '../../../constants/constants';
import Logger from '../../../helpers/Logger';
import { errorNotifcation } from "../../../helpers/errorHandle";

class OrgsTopModal extends Component {

    constructor(props){
        super(props);

        this.state = { 
            loading: false,
            organizations: [],
            redirectToOrg: false,
        }
    }

    UNSAFE_componentWillMount = () => {
        let {organizations} = this.props
        if (organizations && organizations.length > 0) {
            this.setState({
                organizations
            });
        }
    }

    componentDidMount = () => {
        this.getOrgs();
    }

    handleSelect = (org) => {
        this.props.setDefaultOrganizationWithHabilitations(org.id);
        window.location.reload();
    }

    setLoading = (loading) => {
        this.setState({
            loading
        });
    }

    setOrganizations = (organizations) => {
        this.setState({
            organizations
        });
        if(organizations && organizations.length > 0) {
            this.props.setOrganizations(organizations)
        }
    }

    getOrgs = async () => {
        if(!this.props.organizations.length){
            let authTokenJson = sessionStorage.getItem(SESSION_STORAGE_KEYS.TOKEN);
            let authToken = JSON.parse(authTokenJson);
            if(authToken) {
                let payload = _extractPayloadFromToken(authToken.access_token);
                let id = payload.sub
                if(id) {
                    await this.getUserOrganizations(id);
                }
            } else {
                Logger.error('User is not authenticated');
            }
        }
    };

    getUserOrganizations = async (userId) => {
        this.setLoading(true);
        try{
            const res = await _getOrganizationsByUserId(userId);
            this.props.setOrganizations(res.data);
            return res.data;
        }catch(error){
            Logger.error("getOrganizationByUser", "Error occured when getting organizations", error);
            errorNotifcation(error?.response?.status, "Erreur pendant la récupération des organisations, veuillez reessayer ultérieurement.");
        }finally{
            this.setLoading(false);
        }
    }

    modalDisplayState = () => {
        let { opened } = this.props;
        return opened ? 'block' : 'none';
    }

    showOrgsModal = (callback = null) => {
       // const org = document.querySelector(".switch-org-modal");
        const org = document.querySelector(".switch-org-modal");
        removeClass(org, "hidden");
        if(callback) callback();
    }

    hideOrgsModal = (callback = null) => {
        const org = document.querySelector(".switch-org-modal");
        const modalOrg = document.querySelector(".switch-org-modal__content");
        addClass(org, "fadeOut");
        addClass(modalOrg, "slideOut");
        setTimeout(() => {
            removeClass(org, "fadeOut");
            removeClass(modalOrg, "slideOut");
            addClass(org, "hidden");
            if(callback) callback();
        }, 400);
    }

    createOrganization = (e) => {
        e.stopPropagation();
        this.hideOrgsModal(this.setState({ redirectToOrg: true }));
    }

    render(){
        let { loading, redirectToOrg } = this.state;
        if(redirectToOrg) return <Redirect to={"/organization"} />
        return (
            <div className="switch-org-modal hidden" onClick={()=>{this.hideOrgsModal()}}>
                <div className="switch-org-modal__content">
                    <div className="switch-org-modal__content-body">
                        <div className="switch-org-modal__content-body-header">
                            <button className='btn-close' onClick={()=>{this.hideOrgsModal()}}>
                                <IconClose/>
                            </button>
                        </div>
                        <div className="org-items-wraper">
                            {
                                loading ? <div className="d-flex">
                                    <OrgLoader/>
                                    <OrgLoader/>
                                </div> : 
                                this.props.organizations.map((org, index) => {
                                    return(
                                        <div key={index} className="modal-item-list" onClick={() => {this.handleSelect(org)}}>
                                            <div className="modal-org-logo-wrapper" style={{"--logo-url": "url(" + org.logo + ")"}}>
                                            </div>
                                            <div className="modal-org-description">
                                                <div className="modal-orgName" title={org.name}>{org.name}</div>
                                                <div className="modal-socialReason-wrapper" title={org.invoiceEmail}>
                                                    <span className="modal-socialReason">{org.invoiceEmail ? org.invoiceEmail: " "}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className="add-org-btn" style={{width: 150, height: 150 }} onClick={this.createOrganization}>
                                <IconPlus width={25} height={25} color={"#EAEAEA"} />
                                <div className="label">Ajouter une organisation</div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        );
    }
}

OrgsTopModal.propTypes = {
    opened: PropTypes.func,
    onClose: PropTypes.func
}

const mapStateToProps = (state) => {
    const { auth, organizations } = state
    return {
        authData: auth.authData,
        organizations: organizations.organizations
    }
}

export default connect(mapStateToProps, { setOrganizations, setDefaultOrganizationWithHabilitations })(OrgsTopModal)