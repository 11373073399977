import React, { Component } from 'react'
import { connect } from 'react-redux';
import { setDefaultItem } from '../../redux/action/NavAction';
import { setMainColor } from '../../redux/action/themeAction';
import NavItem from '../../components/Nav/NavItem';
import * as auth from '../../services/Auth';
import DizenLogo from "../../components/Icons/DiZenLogo";
import mboa from "../../assets/svg/mboatech.svg";
import './NavLink.css';

class NavLink extends Component {
    constructor(props){
        super(props);
        this.state = {
            items: this.props.items,
            roleNav: [
                {id: 1, roles: ["account"]},
                {id: 2, roles: ["account"]},
                {id: 3, roles: ["account","accountant"]},
                {id: 4, roles: ["account"]},
                {id: 5, roles: ["account"]},
                {id: 6, roles: ["account"]},
                {id: 7, roles: ["account"]},
            ],
            defaultOrg: null
        };
        this.navItem = React.createRef();
    }

    disableAllLinks = (items) => {
        items.map((element) => (
            element.active = false
        ))
        return items
    }

    setActiveLink = () => {
        let { match, items } = this.props;
        const path = Object.prototype.hasOwnProperty.call(match, 'children') ? this.props.match.children.props.match.path : '/dashboard';
        const index = items.findIndex((element) => { return element.route === path });

        this.props.setDefaultItem(index);
    }

    setDefaultThemeColor = () => {
        let { match, items } = this.props;
        const path = Object.prototype.hasOwnProperty.call(match, 'children') ? this.props.match.children.props.match.path : '/dashboard';
        const elem = items.find((element) => { return element.route === path });
        if(elem){
            const color = this.navItem.current.renderColorTheme(elem.id);
            this.props.setMainColor(color);
        }
    }

    handleClick = (key, theme) => {
        this.props.setDefaultItem(key);
    }
    onMouseOver = (id) => {
        let newItems = [...this.state.items]
        newItems[id].mouseOver = true
        this.setState({
            items: newItems
        })
    }
    onMouseOut = (id) => {
        let newItems = [...this.state.items]
        newItems[id].mouseOver = false
        this.setState({
            items: newItems
        })
    }

    UNSAFE_componentWillMount(){
        this.setActiveLink()
        let defaultOrg = JSON.parse(sessionStorage.getItem('defaultOrg'));
        this.setState({
            defaultOrg
        })
    }

    componentDidMount(){
        this.setDefaultThemeColor();
    }

    renderAuthorizedMenuItem = () => {
        let authorizedMenuItem = [];
        this.props.items.map( (item, index) => {
            
            if(auth._canAccess(item.code)){
                authorizedMenuItem.push(item);
            }
            return item;
        })
        return (
            authorizedMenuItem.map((item, index)=>(
                <NavItem 
                    ref={ this.navItem }
                    data={item} 
                    key={index} 
                    index={index} 
                    onClick={(i, color) => this.handleClick(index, color)} 
                    onMouseOver={()=>this.onMouseOver(index)} 
                    onMouseOut={() => this.onMouseOut(index)}
                />
            ))
        )
        
    }

    render(){
        const logoUrl = this.state.defaultOrg && this.state.defaultOrg.logo ? this.state.defaultOrg.logo : mboa;
        return (
            <div className={`NavLink ${this.props.className}`}>
                <div className="logo-container">
                    <div className="logo"  style={{"--logo-url": "url(" + logoUrl + ")"}}></div>
                </div>
                <div className="nav-element">
                    {   
                        this.renderAuthorizedMenuItem()
                    }
                </div>
                <div className='d-flex align-items-center justify-content-center dizen-logo'>
                    <DizenLogo />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const i = state.nav.items.findIndex(elm => elm.id === 8); // This is for remove the "user" Navlink...
    if(i >= 0) state.nav.items.splice(i, 1); // that create the overflow in the Navlink bar.
    return {
        items: state.nav.items,
        themeColor: state.theme.mainColor,
    }
}

export default connect(mapStateToProps, { setDefaultItem, setMainColor })(NavLink)
