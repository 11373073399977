import React, {Component} from 'react';
import imageCompression from 'browser-image-compression';
import TagContainer from '../TagContainer/TagContainer';
import iconUpload from '../../assets/svg/icon-cloud-upload.svg';
import IconClose from '../Icons/IconClose';
import IconSave from '../Icons/SaveIcon';
import { ROLES } from '../../constants/AuthConstants';
import { _presingAndUploadToS3 } from '../../services/Invoice';
import CONSTANTS, { SESSION_STORAGE_KEYS, IMAGE_FILE_OPTIONS, IMAGES_TYPES } from '../../constants/constants';
import Logger from "../../helpers/Logger";

import './InputFee.css';
import { toastr } from 'react-redux-toastr';

class InputFee extends Component {
    constructor(props){
        super(props);
        this.state = {
            files: [],
            allowType: ['application/pdf', 'image/jpeg', 'image/png', 'image/jpg'],
            tags: [],
            tagSeparatorString: [';', ','],
            tagValue: '',
            selectedTagPosition: null,
            label: "Choisir un / plusieurs fichier(s)",
            defaultLabel : "Choisir un / plusieurs fichier(s)",
            disabled: false,
            maxFiles: 5,
        };
        this.fileInputRef = React.createRef();
        this.openFileDialog = this.openFileDialog.bind(this);
    }

    componentDidMount = () => {
        this.props.onRef(this);
    }

    componentWillUnmount = () => {
        this.props.onRef(undefined);
    }

    openFileDialog = () => {
        if (this.state.disabled) return;
        this.fileInputRef.current.click();
    }

    onFilesAdded = (e) => {
        if (this.state.disabled) return;
        const files = [ ...e.target.files ];
        if(!files.length) return;
        if(!this.checkMultipleFileType(files)){
            toastr.warning("Seuls les images et les PDFs peuvent être télécharger, veuillez vérifier les types de fichiers selectionnés");
            return;
        }
        this.setState({
            files: files,
            label: files.length === 1 ? files[0].name : `${files.length} fichiers sélectionnés`,
        });
    }

    checkMultipleFileType = (files) => {
        return files.reduce((totalCheck, file) => totalCheck && this.checkOneFileType(file.type));
    }
    checkOneFileType = (fileType) => {
        const { allowType } = this.state;
        return allowType.find((type) => { return type ===  fileType });
    }

    saveForm = async () => {
        this.setState({
            disabled: true,
            label: "Traitement en cours ...",
        });
        this.props.setLoading(true);
        if(this.state.files.length) {
            let organization = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
            const uploadedFiles = await this.uploadedFilestoS3(organization.id);

            const payload = this.constructPayload(uploadedFiles);
            await this.props.onSave(payload);
        }
    }
    uploadedFilestoS3 = async (orgId) => {
        return await Promise.all(this.state.files.map(async (file) => {
            const formData = new FormData();
            let finalFile = file;
            if(IMAGES_TYPES.find((type) => { return type ===  file.type })) {
                const options = {
                    maxSizeMB: IMAGE_FILE_OPTIONS.MAX_SIZE_MB,
                    maxWidthOrHeight: IMAGE_FILE_OPTIONS.MAX_WIDTH_OR_HEIGHT,
                    useWebWorker: IMAGE_FILE_OPTIONS.USE_WEB_WORKER
                };
                try{
                    finalFile = await imageCompression(file, options);
                }catch(error){
                    const err = {...error};
                    Logger.error("COMPRESSION_IMAGE_ERROR", `Can't compress the image: ${file.name}`, err);
                    toastr.error("ERROR", `Erreur lors de l'envoie du fichier ${file.name}`);
                }
            }
            formData.append("scanFile", finalFile, file.name);
            file = await _presingAndUploadToS3(orgId, formData);
            return {
                key: file.key,
                contentType: file["Content-Type"],
            };
        }));
    }
    constructPayload = (files) => {
        if (this.state.tags.length) return { files: JSON.stringify(files), observations: JSON.stringify(this.state.tags), source: CONSTANTS.INVOICE_SOURCE };
        return { files: JSON.stringify(files), source: CONSTANTS.INVOICE_SOURCE };
    }

    resetValues = () => {
        this.fileInputRef.current.value = "";
        this.setState({
            files: null,
            tagValue: '',
            tags: [],
            selectedTagPosition: null,
            label: this.state.defaultLabel,
            disabled: false,
        })
    }

    checkSeparator = (value) => {
        const { tagSeparatorString } = this.state;
        return tagSeparatorString.find((item) => { return  value.indexOf(item) > - 1 });
    }
    handleChangeTag = (e) => {
        this.setState({
            tagValue: e.target.value
        })
        const tagValue = e.target.value;
        if(this.checkSeparator(tagValue)) {
            let tags = this.constructTags(tagValue.substring(0, tagValue.length -1));
            this.setState({
                tags,
                tagValue: '',
                selectedTagPosition: null,
            })
        }
    }

    constructTags = (value) => {
        const { tags, selectedTagPosition } = this.state;

        if(!value) return tags
        const v = tags.find((tag) => tag.value === value.trim());
        if (!!v) return tags
        if(selectedTagPosition !==  null && tags[selectedTagPosition])
            tags[selectedTagPosition].value = value.trim();
        else
            tags.push({value: value.trim(), type: ROLES.customer});
        this.setState({
            tags,
        })
        return tags;
    }

    onTagClick = (tag) => {
        const { tags } = this.state;
        const selectedTagPosition = tags.findIndex((elem) => elem.value === tag.value);
        if(selectedTagPosition >= 0) {
            this.setState({
                tagValue: tag.value,
                selectedTagPosition,
            })
        }
    }

    deleteTag = (tag) => {
        let {tags} = this.state;
        const selectedTagIndex = tags.findIndex((elem) => elem.value === tag.value);
        if(selectedTagIndex >= 0) {
            tags.splice(selectedTagIndex,1);
            this.setState({
                tags
            })
        }
    }

    render(){
        return(
            <div className={`InputFee ${this.props.className ? this.props.className : ""}`}>
                <div className="upload-process">
                    <input
                        ref={this.fileInputRef}
                        className="hidden"
                        type="file"
                        onChange={this.onFilesAdded}
                        accept=".png, .jpeg, .jpg, .pdf"
                        multiple
                        disabled={this.state.disabled}
                    />
                    <button className="btn-upload" onClick={this.openFileDialog} disabled={this.state.disabled}>
                        <img src={iconUpload} alt="Attach" width={19} height={'auto'} />
                        <span>{ (this.state.label) ? this.state.label : this.state.defaultLabel }</span>
                    </button> 
                </div>
                <div className="observation"> 
                    <div className="tags">
                        <TagContainer tags={this.state.tags} onTagClick={this.onTagClick} onRemove={this.deleteTag} accountRole={ROLES.customer} editable/>
                    </div> 
                    <input type="text" name="observation" id="observation" placeholder="Ajouter une observation" value={this.state.tagValue} onChange={this.handleChangeTag}/>
                </div>
                <div className="validation">
                    <button className="validation"  onClick={this.resetValues} disabled={this.state.disabled}> <IconClose color="#B5B5B5" /> </button> 
                    <button className="validation" onClick={this.saveForm} disabled={this.state.disabled}> <IconSave color="#B5B5B5" width={12} height={12} /> </button>
                </div>
            </div>
        )
    }
}
 
export default InputFee;