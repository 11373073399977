import React, { Component } from 'react';
import { Row } from "react-bootstrap";
import { connect } from 'react-redux';
import Countries from 'country-data';
import IconMap from '../Icons/MapIcon';
import IconNumber from '../Icons/NumberIcon';
import IconNumSecuIcon from '../Icons/NumSecuIcon';
import IconUnion from '../Icons/UnionIcon';
import IconDate from '../Icons/DateIcon';
import IconPlus from '../Icons/IconPlus';
import IconPerson from '../Icons/IconPerson';
import IconMan from '../Icons/IconMan';
import IconManMaleSign from '../Icons/IconManMaleSign';
import IconDateExp from '../Icons/DateExpirationIcon';
import IconFemale from '../Icons/IconFemale';
import IconWoman from '../Icons/IconWoman';
import CardPreview from '../CardPreview';
import EditableInput from '../EditableInput';
import "simple-react-validator/dist/locale/fr";
import SimpleReactValidator from "simple-react-validator";
import { setCivilFormValid } from '../../redux/action/ValidationAction';

class CivilInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            civilityOptions: [{value: 'Monsieur'}, {value: 'Madame'}, {value: 'Mademoiselle'}],
            civilityManOptions: [{value: 'Monsieur'}],
            civilityWomanOptions: [{value: 'Madame'}, {value: 'Mademoiselle'}],
            genreOptions: [{value: 'Homme'}, {value: 'Femme'}],
            situationOptions: [{value: 'Célibataire'}, {value: 'Marié(e)'}],
            childs: [],
        }

        this.validator = new SimpleReactValidator({autoForceUpdate: this, locale: 'fr'});
    }

    setDefaultCivility(genre) {
        switch (genre) {
            case this.state.genreOptions[0].value:{
                return this.state.civilityManOptions[0].value;
            }
            case this.state.genreOptions[1].value:{
                return this.state.civilityWomanOptions[1].value;
            }
            default:
                return '';
        }
    }
    onChangeGender(gender){
        switch (gender) {
            case this.state.genreOptions[0].value:{
                this.setState({civilityOptions: [...this.state.civilityManOptions]});
                this.props.onChangeGenre(gender);
                this.props.onChangeCivilite(this.state.genreOptions[0].value);
                break;
            }
            case this.state.genreOptions[1].value:{
                this.setState({civilityOptions: [...this.state.civilityWomanOptions]});
                this.props.onChangeGenre(gender);
                break;
            }
            default:
                return '';
        }
    }
    

    onChangeName(e) {
        this.props.onChangeName(e)
    }
    onChangeSurname = (e) => {
        this.props.onChangeSurname(e);
    }
    onChangeBirthday = (e) => {
        if(this.props.onChangeBirthday){
            this.props.onChangeBirthday(e);
        }
    }
    onChangeNationality(e) {
        this.props.onChangeNationality(e)
    }
    onChangeNumCni(e) {
        this.props.onChangeNumCni(e)
    }
    onChangeNumCnps(e) {
        this.props.onChangeNumCnps(e);
    }
    onChangeNiu(e) {
        this.props.onChangeNiu(e);
    }

    validCivilForm = (noShowMessage) => {
        if (this.validator.allValid()) {
            this.props.setCivilFormValid(true);
            this.validator.purgeFields();
            return true;
        } else {
            this.props.setCivilFormValid(false);
            if(!noShowMessage) this.validator.showMessages();
            return false;
        }
    }
    resetCivilForm() {
        this.validator.purgeFields();
        this.validator.hideMessages();
        this.props.setCivilFormValid(false);
    }

    componentDidMount = () => {
        this.props.onRef(this);
        this.resetCivilForm();
    }
    componentWillUnmount = () => {
        this.props.onRef(undefined);
    }

    renderChildren = () => {
        if(this.props.child.length){
            return (
                this.props.child.map((item, index) => {
                    return(
                        <div className="row ml-3 mr-1 mt-2 mb-2" key={index}>
                            <div className="col-md-12 ml-0">
                                <CardPreview name={item.name} surname={item.surname} birthDate={item.birthday} onClick={this.onChangeChild} index={index} />
                            </div>
                        </div>
                    )
                })
            )
        }else{
            return(
                <div className="row ml-3 mr-1 mt-2">
                    <div className="col-md-12 ml-0 d-flex align-items-center justify-content-center bg-light">
                        AUCUN ENFANT
                    </div>
                </div>
            )
        }
    }

    renderFlag = () => {
        const country = Countries.countries.all.find(country => country.name === this.props.nationality)
        if(country){
            return country.emoji
        }else
            return ''
    }
    
    renderDefaultCountry = () => {
        const country = Countries.countries.all.find(country => country.name === this.props.nationality)
        if(country) return {flag: country.emoji, name: country.name}
        else return ''
    }

    errorMsg = (error) => {
        return this.props.formCivilValid ? "" : error;
    }

    renderContent = () => {
        return(
            <div className='px-0'>
                <div className="header-preview">
                    <div className="d-flex align-items-center">
                        <Row className="p-1">
                            <span className="ico-content pr-1"><IconPerson color={"previewIco"} width={18} height={18} active /></span>
                            <EditableInput type='text' name='surname' className="header-preview-title pl-30-10 w-100" globalClassName="min-width150"
                                value={this.props.surname ? this.props.surname : ""} required
                                state={'preview'} editable placeholder="Prenom" onChange={this.onChangeSurname}
                                error={{ msg: this.errorMsg(this.validator.message('prénom', this.props.surname, 'required|string')), placement: "top"}}
                            />
                            <EditableInput type='text' name='name' className="header-preview-title pl-30-10 w-90"
                                value={this.props.name ? this.props.name : ""}  globalClassName="min-width150" required
                                state={'preview'} editable placeholder="Nom" onChange={(e) => this.onChangeName(e)}
                                error={{ msg: this.errorMsg(this.validator.message('nom', this.props.name, 'required|string')), placement: "bottom"}}
                            />
                        </Row>
                    </div>
                    <div className="d-flex align-items-center">
                        <EditableInput withClass='w-50 mr-2' type="select" name="nationality" options={this.props.nationalities} optionIcon='emoji'
                            optionValue='name' className="pl-30-10" placeholder="Choisir un pays" value={this.renderDefaultCountry().name}
                            state={'preview'} editable  onChange={this.props.onChangeNationality} withIcon={this.renderDefaultCountry().flag} />
                    </div>
                    <div className="d-flex align-items-center mt-1">
                        <span className="ico-content pr-1"></span>
                        <EditableInput withClass='w-50 mr-2' type="select" name="genre" selectedValue={this.props.genre ? this.props.genre : "Homme"} options={this.state.genreOptions}
                        optionValue='value' className="pl-30-10" onChange={(e) => this.onChangeGender(e.target.value)}
                        state={'preview'} editable withIcon={this.props.genre === "Homme" ? <IconMan active="true" /> : <IconWoman active /> } />

                        <EditableInput withClass='w-50 mr-2' type="select" name="civility" selectedValue={this.props.civilite ? this.props.civilite : "Monsieur"} options={this.state.civilityOptions}
                        optionValue='value' className="pl-30-10" onChange={(e) => this.props.onChangeCivilite(e.target.value)}
                        state={'preview'} editable withIcon={ this.state.civilityOptions[0].value === "Monsieur" ? <IconManMaleSign active="true" /> : <IconFemale active />} />
                    </div>
                </div>
                <div className="content-preview">
                    <div className="row pt-1 pl-3">
                        <div className="col-md-6 col-sm-12">
                            <div className="label-input">Né le</div>
                            <EditableInput type="customDate" name="birthdate" value={this.props.birthday ? this.props.birthday : null } className="pl-30-10" 
                                placeholder='Date de naissance'
                                onChange={this.onChangeBirthday} required
                                state={'preview'} editable withIcon={<IconDate width={15} height={10.38} active/>}
                                error={ {msg: this.errorMsg(this.validator.message('date de naissance', this.props.birthday, 'required')), placement: "bottom"} }
                            />
                        </div>
                        <div className="col-md-6">
                            <div className="label-input">À</div>
                            <EditableInput type="text" name="birthlocation" placeholder="Lieu de naissance"
                                value={this.props.birthdayPlace ? this.props.birthdayPlace : ""} 
                                state={'preview'} editable withIcon={<IconMap active />}
                                onChange={(e) => this.props.onChangeBirthdayPlace(e)}
                                className="pl-30-10"
                            />
                        </div>
                    </div>
                    <hr className="mt-1 mb-1"/>
                    <div className="row pl-3">
                        <div className="col-md-6">
                            <div className="label-input">Numero de CNI</div>
                            <EditableInput type="text" name="cni" value={this.props.numCni ? this.props.numCni : ""} 
                                className="pl-30-10" placeholder="Numero de CNI" 
                                state={'preview'} editable withIcon={<IconNumber active={true}  />} 
                                onChange={(e) => this.props.onChangeNumCni(e)}
                            />
                        </div>
                        <div className="col-md-6">
                            <div className="label-input">Date d'expiration de CNI</div>
                            <EditableInput type="customDate" name="cniexp" value={ this.props.expirationDate ? this.props.expirationDate : null} 
                                className="pl-30-10" placeholder="Selectionnez une date"
                                placement="left"
                                state={'preview'} editable withIcon={<IconDateExp  active={true}  />} 
                                onChange={(e) => this.props.onChangeExpirationDate(e)}
                            />
                        </div>
                    </div>
                    <div className="row pl-3">
                        <div className="col-md-6">
                            <div className="label-input">Numéro de sécurité sociale</div>
                            <EditableInput type="text" name="cnps" value={this.props.numCnps ? this.props.numCnps : ""} 
                                className="pl-30-10" placeholder="Entrez un numero"
                                state={'preview'} editable withIcon={<IconNumSecuIcon active={true} />} 
                                onChange={(e) => this.props.onChangeNumCnps(e)}
                            />
                        </div>
                        <div className="col-md-6">
                            <div className="label-input">Numéro contribuable</div>
                            <EditableInput type="text" name="niu" value={this.props.niu ? this.props.niu : ""} 
                                className="pl-30-10" placeholder="Entrez un numéro"
                                state={'preview'} editable withIcon={<IconNumSecuIcon active={true} />} 
                                onChange={(e) => this.props.onChangeNiu(e)}
                            />
                        </div>
                    </div>
                    <hr className="mt-1 mb-1"/>
                    <div className="row pl-3">
                        <div className="col-md-12">
                            <div className="label-input">Situation familiale</div>
                            <EditableInput type="select" name="maritalstatus" value={this.props.situation ? this.props.situation : ""} options={this.state.situationOptions}
                                optionValue='value' className="pl-30-10" placeholder="Selectionner une situation"
                                state={'preview'} editable withIcon={<IconUnion active={true}
                                />} 
                                onChange={this.props.onChangeSituation} />
                        </div>
                    </div>
                    <div className="label-input ml-3 justify-content-between align-items-center">
                        <div>Nombre d'enfant <span className="puce">{`${this.props.child ? this.props.child.length : 0}`}</span></div>
                        <button className='btn-plus mt-2' onClick={this.props.onChangeChild}>
                            <IconPlus />
                        </button>
                    </div>
                    
                    { this.renderChildren() }
                    
                </div>
            </div>
        
        );
    }

    render() {
        
        return(
            this.renderContent()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        defaultOrganization: state.organizations.defaultOrganization,
        formCivilValid: state.validation.formCivilValid,
    }
}

export default connect(mapStateToProps, { setCivilFormValid })(CivilInfo)