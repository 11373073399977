/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const NumsecuComponent = (props) => (
  <svg width={26.714} height={15} viewBox="0 0 26.714 15">
    <defs>
      <style>
        {
          `.sa{fill:${props.active ? '#177BD1' :'#b5b5b5;'}}.sb,.sd{fill:none;}.sb{stroke:${props.active ? '#177BD1' :'#b5b5b5;'};stroke-linejoin:round;stroke-width:0.5px;}.sc{stroke:none;}`
        }
      </style>
    </defs>
    <g transform="translate(-5.286 -7)">
      <path
        className="sa"
        d="M73.5,73.257c-.178-.786-1.195-1.17-1.546-1.293a11.524,11.524,0,0,0-1.289-.247,1.309,1.309,0,0,1-.6-.275,5.209,5.209,0,0,1-.04-1.219,3.668,3.668,0,0,0,.282-.537,6.922,6.922,0,0,0,.208-.937s.2,0,.275-.356a3.5,3.5,0,0,0,.183-.829c-.015-.284-.171-.277-.171-.277a4.336,4.336,0,0,0,.168-1.269,2.227,2.227,0,0,0-4.434,0,4.523,4.523,0,0,0,.166,1.269s-.156-.007-.171.277a3.5,3.5,0,0,0,.183.829c.069.356.275.356.275.356a6.923,6.923,0,0,0,.208.937,3.668,3.668,0,0,0,.282.537,5.209,5.209,0,0,1-.04,1.219,1.309,1.309,0,0,1-.6.275,11.524,11.524,0,0,0-1.289.247c-.351.124-1.368.507-1.546,1.293a.2.2,0,0,0,.2.24h9.1A.2.2,0,0,0,73.5,73.257Z"
        transform="translate(-58.723 -54)"
      />
      <g transform="translate(7 -1)">
        <g className="sb" transform="translate(3 8)">
          <rect className="sc" width={22} height={15} rx={1} />
          <rect
            className="sd"
            x={0.25}
            y={0.25}
            width={21.5}
            height={14.5}
            rx={0.75}
          />
        </g>
        <path
          className="sa"
          d="M-238.552-1349.821v-.985h1.465a1.092,1.092,0,0,0,.921-.387,1.886,1.886,0,0,0,.3-1.162,2.845,2.845,0,0,1-.606.114q-.336.029-.8.029a1.8,1.8,0,0,1-1.271-.366,1.415,1.415,0,0,1-.379-1.065v-.892a1.4,1.4,0,0,1,.408-1.111,1.676,1.676,0,0,1,1.133-.354h.9a1.869,1.869,0,0,1,1.043.265,1.668,1.668,0,0,1,.615.939,6.539,6.539,0,0,1,.21,1.869,4.059,4.059,0,0,1-.593,2.5,2.124,2.124,0,0,1-1.764.741A7.948,7.948,0,0,1-238.552-1349.821Zm1.019-4.921a.622.622,0,0,0-.143.459v.4a.563.563,0,0,0,.173.48,1.022,1.022,0,0,0,.585.126h1.052a4.2,4.2,0,0,0-.089-.98.853.853,0,0,0-.273-.489.81.81,0,0,0-.5-.139h-.379A.6.6,0,0,0-237.533-1354.742Zm-11.364,5.03v-5.093l-1.1.143v-.8l1.238-.539h1.111v6.288Zm6.44-1.886.236-1.153.614.16-.4,1.136Zm-3.637,0,.236-1.153.614.16-.4,1.136Zm4.462-.446.336-.539.977.74-.278.37Zm-3.637,0,.336-.539.977.74-.278.37Zm4.065-.817.985-.682.27.37-.85.808Zm-3.637,0,.985-.682.27.37-.85.808Zm1.911-.059v-.463l1.178-.127.025.632Zm-3.637,0v-.463l1.178-.127.025.632Zm4.739-1.254.446-.143.522,1.052-.6.236Zm-3.637,0,.446-.143.522,1.052-.6.236Z"
          transform="translate(257.446 1368.594)"
        />
      </g>
    </g>
  </svg>
);

export default NumsecuComponent;
