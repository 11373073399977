/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const UnionComponent = (props) => (
  <svg width={props.width ? props.width : 17.535} height={props.height ? props.height : 15.087} viewBox="0 0 17.535 15.087">
    <defs>
      <style>{`.dpa{fill:${props.active ? '#177bd1;': '#b5b5b5;'}}`}</style>
    </defs>
    <path
      className="dpa"
      d="M-51.252,1488.524a1.023,1.023,0,0,1-.426-.858v-10.724A.873.873,0,0,1-50.9,1476h6.962a.873.873,0,0,1,.774.942v6.6h-.774v-6.6h-6.189l3.1,1.885v8.481h3.094v-3.769h.774v3.769a.873.873,0,0,1-.774.942h-3.094v2.837Zm11.641-.523a.5.5,0,0,1-.423-.553l.4-3.106a.49.49,0,0,1,.111-.251l1.508-1.82v-3.164c0-.3.167-.421.484-.421h.978a.439.439,0,0,1,.2.061l1.8.839a.8.8,0,0,1,.405.76v1.727a.49.49,0,0,1-.484.495.49.49,0,0,1-.484-.495v-1.54a.208.208,0,0,0-.116-.187l-.852-.435v1.993a.417.417,0,0,1-.121.3l-2.444,2.5a.2.2,0,0,0-.059.119l-.375,2.77a.479.479,0,0,1-.479.416Zm4.561-1.212-1.844-1.365a.5.5,0,0,1-.15-.356l-.241-1.39,1.209-1.235v2.412l1.794,1.294a.533.533,0,0,1,.14.368.436.436,0,0,1-.124.33.506.506,0,0,1-.36.164A.825.825,0,0,1-35.05,1486.788Zm-5.72-4.643a.449.449,0,0,1-.146-.322.568.568,0,0,1,.149-.376l2.513-2.544v1.391l-1.831,1.854a.474.474,0,0,1-.34.144A.483.483,0,0,1-40.77,1482.145Zm1.773-4.855a.987.987,0,0,1,.816-1.132.987.987,0,0,1,1.132.815.987.987,0,0,1-.816,1.132.96.96,0,0,1-.159.013A.987.987,0,0,1-39,1477.291Z"
      transform="translate(51.678 -1476)"
    />
  </svg>
);

export default UnionComponent;
