import { SET_BANK_STATEMENTS, ADD_MULTIPLE_BANK_STATEMENTS, SET_BANK_ACCOUNT, SET_SELECTED_BANK_STATEMENT, SET_SELECTED_BANK_RECORD, SET_SELECTED_STATEMENT_RECORD, SET_LOAD_IMPORT_BANK_STATEMENT, SET_SELECTED_BANK_ACCOUNT } from '../action-type/bankType';

export const setBankStatements = (bankStatements) => ({
    type: SET_BANK_STATEMENTS,
    payload: bankStatements,
});

export const addMultipleBankStatements = (bankStatements) => ({
    type: ADD_MULTIPLE_BANK_STATEMENTS,
    payload: bankStatements,
});

export const setBankAccounts = (bankAccounts) => ({
    type: SET_BANK_ACCOUNT,
    payload: bankAccounts,
});

export const setSelectedBankStatement = (bankStatement) => ({
    type: SET_SELECTED_BANK_STATEMENT,
    payload: bankStatement,
});

export const setSelectedBankRecord = (bankRecord) => ({
    type: SET_SELECTED_BANK_RECORD,
    payload: bankRecord,
});

export const setSelectedStatementRecord = (bankRecord) => ({
    type: SET_SELECTED_STATEMENT_RECORD,
    payload: bankRecord,
});

export const setLoaderImportBankStatement = (value) => ({
    type: SET_LOAD_IMPORT_BANK_STATEMENT,
    payload: value,
});

export const setSelectedBankAccount = (bankAccount) => ({
    type: SET_SELECTED_BANK_ACCOUNT,
    payload: bankAccount,
});
