import React from 'react';
// import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from "moment";
import IconArrowUpAndDown from "../Icons/IconArrowUpDown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./PeriodDropdown.css";

// #region component
const propTypes = {
    name: PropTypes.string,
    id: PropTypes.any,
    className: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
    optionKey: PropTypes.string,
    defaultValue: PropTypes.any,
    withIcon: PropTypes.element,
    placeholder: PropTypes.string,
    onClick: PropTypes.func,
    onSelect: PropTypes.func,
    onCancel: PropTypes.func,
};

const defaultProps = {
    name: "",
    id: Math.floor(Math.random() * 1000 / Math.random()),
    className: "",
    options: [],
    optionKey: "",
    defaultValue: "",
    withIcon: null,
    placeholder: "Select an item",
    onClick: (e) => this.props.onClick(e),
    onSelect: (e) => this.props.onSelect(e),
    onCancel: (e) => this.props.onCancel(e),
};

/**
 * Period Dropdown Component
 */
class PeriodDropdown extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpened: false,
            isCustomBoxOpened: false,
            startDate: null,
            endDate: null,
        };
    }

    handleChangeDate = (key, date) => {
        this.setState({
            [key]: date,
        });
    }

    toggleItems = () => {
        if(this.props.options.length){
            this.setState({ 
                isOpened: !this.state.isOpened,
            });
        }
    }

    openCustomBox = () => {
        this.setState({ isCustomBoxOpened: true });
    }

    closeCustomBox = () => {
        this.setState({ isCustomBoxOpened: false });
    }

    cancelForm = () => {
        this.setState({ 
            isCustomBoxOpened: false,
            isOpened: !this.state.isOpened,
            startDate: null,
            endDate: null,
        }, () => {
            this.props.onCancel(null);
        });
    }

    onSelect = (e, item) => {
        e.preventDefault();
        this.props.onSelect(item);
        this.setState({
            isCustomBoxOpened: false,
            startDate: null,
            endDate: null
        }, () => {
            this.toggleItems();
        });
    }

    setTimeNow = () => {
        this.setState({
            startDate: new Date(),
            endDate: new Date(),
        })
    }

    submitForm = (e) => {
        e.preventDefault();
        this.setState({
            isOpened: !this.state.isOpened,
            isCustomBoxOpened: true,
        }, () => {
            const payload = { 
                id: 1, 
                value: `${moment(this.state.startDate).format("DD/MM/YYYY")} - ${moment(this.state.endDate).format("DD/MM/YYYY")}`, 
                startDate: this.state.startDate, 
                endDate: this.state.endDate
            }
            this.props.onSelect(payload);
        });
    }

    render() {
        return (
            <div className={`period-dropdown ${this.props.className}`}>
                <div className="btn-selector-item" onClick={this.toggleItems}>
                    {this.props.withIcon ? <div className="period-dropdown-icon">{this.props.withIcon}</div> : <></>} 
                    <div className="period-dropdown-value">{this.props.defaultValue || this.props.placeholder}</div>
                    <IconArrowUpAndDown />
                </div>
                { this.state.isOpened ? 
                    <div className="list-box">
                        <div className="list-box-content">
                            {   this.props.options.map((item, i) => {
                                return (
                                    <div 
                                        className={`list-box-item ${item[this.props.optionKey] === this.props.defaultValue ? "active" : ""}`}
                                        key={`item-${i}`}
                                        onClick={(e) => this.onSelect(e, item)}
                                    >{item[this.props.optionKey]}</div>
                                    )
                                })
                            }
                            <div className={`list-box-item bold ${this.state.isCustomBoxOpened ? "active-custom" : ""}`} onClick={this.openCustomBox}>Personnaliser</div>
                        </div>
                        { this.state.isCustomBoxOpened ?
                            <div className="custom-box-range">
                                <div className="row-justify-between">
                                    <div className="title-custom-box"> Personnaliser </div>
                                    <button className="btn btn-default color-bank-feature" type="button" onClick={this.setTimeNow}>Maintenant</button>
                                </div>
                                <div className="row-justify-between mt-1">
                                    <div className="d-flex flex-1-auto pr-1">
                                        <DatePicker
                                            selected={ this.state.startDate }
                                            onChange={(value) => this.handleChangeDate("startDate", value)}
                                            timeInputLabel="Time:"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="Debut"
                                            popperPlacement="center"
                                            popperClassName="zi-9999"
                                        />
                                    </div>
                                    <div className="d-flex flex-1-auto pl-1">
                                        <DatePicker
                                            selected={ this.state.endDate }
                                            onChange={(value) => this.handleChangeDate("endDate", value)}
                                            timeInputLabel="Time:"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="fin"
                                            popperPlacement="left-start"
                                            popperClassName="zi-9999"
                                        />
                                    </div>
                                </div>
                                <div className="row-justify-between justify-content-end mt-2">
                                    <button type="button" className="btn btn-default color-bank-feature mr-1" onClick={this.cancelForm}>Annuler</button>
                                    <button type="button" className="btn ft-white bg-color-bank border-0 mr-1" onClick={this.submitForm} disabled={!(this.state.startDate && this.state.endDate)}>Appliquer</button>
                                </div>
                            </div> :
                            <></> 
                        }
                    </div> : <></>
                }
            </div>
        );
    }
}

PeriodDropdown.propTypes = propTypes;
PeriodDropdown.defaultProps = defaultProps;
// #endregion

export default PeriodDropdown;