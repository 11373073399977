import React from 'react';
import { connect } from 'react-redux';
import StepFormOne from "./StepFormOne";
import StepTwoForm from "./StepTwoForm"
import StepThreeForm from "./StepThreeForm";
import StepFourForm from "./StepFourForm";
import PropTypes from 'prop-types';
import { selectTaxe } from "../../redux/action/TaxesAction";
import './taxes.css';

// #region component
const propTypes = {
    currentStep: PropTypes.number,
    data: PropTypes.object,
};

const defaultProps = {
    currentStep: 1,
    data: {},
};

class StepsView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    sumTabs = (tabs = [], destTab = null) => {
        let sum = 0;
        tabs.forEach(elm => {
            if(elm && this.state[elm]){
                sum += +this.state[elm];
            }
        });

        if (destTab) {
            this.setState({
                [destTab]: sum ? sum : null,
            });
        }
    }


    renderView = () => {
        switch(+this.props.currentStep){
            case 1: {
                return <StepFormOne onRef={this.props.refPageOne} data={this.props.data} history={this.props.history} />
            }
            case 2: {
                return <StepTwoForm onRef={this.props.refPageTwo} data={this.props.data} history={this.props.history} />
            }
            case 3: {
                return <StepThreeForm onRef={this.props.refPageThree} data={this.props.data} history={this.props.history} />
            }
            case 4: {
                return <StepFourForm onRef={this.props.refPageFour} data={this.props.data} history={this.props.history} />
            }
            default: {
                return <StepFormOne onRef={this.props.refPageOne} data={this.props.data} history={this.props.history} />;
            }
        }
    }

    render() {
        
        return (
            <div className="d-flex flex-1-auto flex-column taxe-step-content">
                {this.renderView()}
            </div>
        );
    }
}

StepsView.propTypes = propTypes;
StepsView.defaultProps = defaultProps;
// #endregion

const mapStateToProps = (state) => {
    return {
        selectedPeriod: state.organizations.selectedOrgTaxes,
        selectedTaxes: state.taxes.selectedTaxe,
    }
}

export default connect(mapStateToProps, { selectTaxe })(StepsView);
