import React, { Component, lazy, Suspense } from 'react';
import Search from '../../../components/Search';
import IconPlus from '../../../components/Icons/IconPlus';
import ModalDetail from '../../../components/ModalDetailStaff/ModalDetail';
import DoubleArrow from '../../../components/Icons/DoubleArrow';
import { setStaffs, selectStaff, addMultipleOrganizationStaff } from '../../../redux/action/StaffAction';
import { connect } from 'react-redux'
import { _getOrganizationStaff } from "../../../services/Organization";
import Loader from '../../../components/Loader';
import Logger from '../../../helpers/Logger';
import { errorNotifcation } from "../../../helpers/errorHandle";
import {PAGINATION} from "../../../constants/constants";
const GenericTable = lazy(() => import("../../../components/Table/GenericTable") );

class Staff extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loadData: false,
            selectedItem: null,
            pageSize: PAGINATION.DEFAULT_PAGE_SIZE,
            startPage: PAGINATION.DEFAULT_PAGE,
            currentPage: PAGINATION.DEFAULT_PAGE,
            endIndex: null,
            endPage: null,
            pages: [],
            startIndex: null,
            totalItems: null,
            totalPages: null,
            count: null,
            hasMore: true,
            scrollLoading: false,
            tableContainerWidth: "100%"
        }
    }

    onCreate = (e) => {
        if (this.props.displayCreate) {
            this.setState({
                tableContainerWidth: "calc(100% - 450px)"
            });
            this.props.displayCreate(e);
        }
    }

    onSelectItem = (item) => {
        if(this.props.onSelectItem) {
            this.setState({
                tableContainerWidth: "calc(100% - 450px)"
            });
            this.props.onSelectItem(item.originalData);
        }
    }

    getStaffs = () => {
        let defaultOrganization = JSON.parse(sessionStorage.getItem('defaultOrg'));
        this.setState({
            loadData: true
        })
        _getOrganizationStaff(defaultOrganization.id, +this.state.pageSize, +this.state.currentPage,).then(res => {
            this.setState({ 
                loadData: false,
                currentPage: res.data.pager.currentPage,
                endIndex: res.data.pager.endIndex,
                endPage: res.data.pager.endPage,
                pageSize: res.data.pager.pageSize,
                pages: res.data.pager.pages,
                startIndex: res.data.pager.startIndex,
                startPage: res.data.pager.startPage,
                totalItems: res.data.pager.totalItems,
                totalPages: res.data.pager.totalPages,
                count: res.data.count,
            });
            this.props.setStaffs(res.data.rows);
        }).catch(error => {
            this.setState({
                loadData: false
            }); 
            Logger.error("getOrganizationStaff", "Unable to get staff of organization", error);
            errorNotifcation(error?.response?.status, "Erreur lors de la récupération du staff, veuillez réessayer ultérieurement");
        })
    }

    getMoreData = async () => {
        try{
            let defaultOrg = JSON.parse(sessionStorage.getItem('defaultOrg'));
            this.setState({ scrollLoading: true });
            const res = await _getOrganizationStaff(defaultOrg.id, this.state.pageSize, this.state.currentPage + 1);
            if(res?.status === 200){
                this.setState({ 
                    scrollLoading: false,
                    currentPage: res.data.pager.currentPage,
                    endIndex: res.data.pager.endIndex,
                    endPage: res.data.pager.endPage,
                    pageSize: res.data.pager.pageSize,
                    pages: res.data.pager.pages,
                    startIndex: res.data.pager.startIndex,
                    startPage: res.data.pager.startPage,
                    totalItems: res.data.pager.totalItems,
                    totalPages: res.data.pager.totalPages,
                    count: res.data.count,
                });
                this.props.addMultipleOrganizationStaff(res.data.rows);
            }else{
                console.error("Erreur lors de la recuperation de donnees");
            }
        }catch(e){
            console.error("error: ", e);
        }
    }

    setPage = (page) => {
        this.setState({
            modalCreate: false
        });
        this.props.selectStaff(null);
        this.getStaffs(this.state.pageSize, page);
        
    }

    componentDidMount() {
        this.getStaffs(this.state.pageSize, 1)
    }

    headerData = [
        { title: <span> Nom Prénom  <DoubleArrow /> </span> , align: "left", className: 'pl-15', width: "45%", name: "name"}, 
        { title: <span> Téléphone  <DoubleArrow /> </span> , align: "left", className: 'hide-xs-responsive pl-15', width: "15%", name: "phone"}, 
        { title: <span> Email  <DoubleArrow /> </span> , align: "left", className: 'pl-15', width: "25%", name: "email"}, 
        { title: <span> Rôle  <DoubleArrow /> </span> , align: "left", className: 'pl-15', width: "15%", name: "role"}, 
    ];

    formatBodyData = (data) => {
        return data.map((item) => {
            let resData = {
                originalData: item,
                name: <span>{item.name + " " + item.surname}</span>,
                phone: <span>{item.phone1}</span>,
                email: <span>{item.email}</span>,
                role: <span className="role"> {`${item.contracts[0].poste}`} </span>,
            }
            return resData;
        })
    }


    closeTab = () => {
        if(this.props.onClose) this.props.onClose();
        this.refGenericTable && this.refGenericTable.unSelectAll();
    }

    setRefGenericTable = (ref) => {
        if(ref){
            this.refGenericTable = ref;
        }
    }

    render() {
        return(
            <div className='d-flex pl-3' style={{ flex: '1 auto' }}>
                <div className='fluid-content-data' style={{width: this.state.tableContainerWidth}}>
                    <div className="options-control-header pt-20 pr-3">
                        <div className="head-option-left">
                            <button className='btn-plus' onClick={this.onCreate}>
                                <IconPlus width={9} height={9} />
                            </button>
                        </div>
                        <Search handleSearch={() => {}} iconClass='search-social'/>
                    </div>
                    <div className='mt-4 mr-3'>
                        <Suspense fallback={<div className="text-center">Chargement... </div>} >
                            <GenericTable
                                data={this.props.staffMembers}
                                onRef={this.setRefGenericTable}
                                header={this.headerData}
                                body={this.formatBodyData(this.props.staffMembers)}
                                className='flex-1-auto'
                                isLoading={this.state.loadData}
                                asLoader={<Loader style={{width: "25px", height: "25px"}} enable />}
                                onSelect={this.onSelectItem}
                                infiniteScroll
                                getData={this.getMoreData}
                                hasMore={(this.state.currentPage < this.state.endPage)}
                                scrollLoading={this.state.scrollLoading}
                            />
                        </Suspense>
                    </div>
                </div>
                <div className={ this.props.modalCreate ? ' social-detail' : 'd-none'}>
                    <ModalDetail type={this.props.modalType} display={this.props.modalCreate} selectedItem={this.props.selectedStaff} 
                        onClose={this.closeTab} getStaffs={() => this.getStaffs(this.state.pageSize, 1)} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { staffs } = state
    return {
        staffMembers: staffs.staffMembers,
        selectedStaff: staffs.selectedStaff
    }
}

export default connect(mapStateToProps, { setStaffs, selectStaff, addMultipleOrganizationStaff })(Staff)