import React, { Component, lazy, Suspense } from 'react'
import { connect } from 'react-redux';
import SearchBankRecord from '../../../components/SearchBankRecord';
import { setStatements, addStatementsMultiple, selectStatement } from '../../../redux/action/AccountAction';
import '../Bank.css';
import { _getOrganizationStatements } from '../../../services/Organization';
import DisplayContainer from '../../../components/DisplayContainer';
import StatementDetails from "../../../views/Bank/StatementDetails";
import Logger from "../../../helpers/Logger";
import { numberFormat } from "../../../helpers/Number";
import Loader from '../../../components/Loader';
import imgPdfFile from '../../../assets/svg/file-icon-orange.svg';
import imgPdfFileNone from '../../../assets/svg/file-pdf-cog-none.svg';
import reverseOrderIcon from '../../../assets/svg/icon-reverseOrder.svg';
import DateItemList from '../../../components/DateItemList';
import moment from "moment";
import { errorNotifcation } from "../../../helpers/errorHandle";
import {PAGINATION} from "../../../constants/constants";
const GenericTable = lazy(() => import("../../../components/Table/GenericTable") );

class BankStatement extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            activePage: 1,
            statements: [],
            selectedItem: null,
            displayModal: false,
            redirect: false,
            pageSize: PAGINATION.DEFAULT_PAGE_SIZE,
            startPage: PAGINATION.DEFAULT_PAGE,
            currentPage: PAGINATION.DEFAULT_PAGE,
            endIndex: null,
            endPage: null,
            pages: [],
            startIndex: null,
            totalItems: null,
            totalPages: null,
            count: null,
            hasMore: true,
            scrollLoading: false,
        };
    }

    componentDidMount() {
        this.getData({pageSize: this.state.pageSize, page: 1});
        this.onCloseModal();
    }

    setRefStatementDetails = (ref) => {
        this.statementDetails = ref;
    }

    setRefGenericTable = (ref) => {
        this.genericTable = ref;
    }

    setRefSearchBank = (ref) => {
        this.searchBankRef = ref;
    }

    constructParam = (pageSize, page, filter) => {
        const param = {};
        param.pageSize = pageSize || this.state.pageSize;
        param.page = page || 1;
        param.filter = filter;
        if (this.props.selectedAccount) param.accountId = this.props.selectedAccount.id;
        return param;
    }

    reloadBankStatements = (accountId) => {
        const param = this.constructParam();
        this.getData({...param, accountId});
    }

    getData(param){
        this.setState({
            loading: true,
        });
        let defaultOrg = JSON.parse(sessionStorage.getItem('defaultOrg'));
        if (defaultOrg.id){
            _getOrganizationStatements(defaultOrg.id, param).then(res => {
                this.props.setStatements(res.data.rows);
                this.setPaginationData(res.data);
            }).catch(error => {
                Logger.error("BANK STATEMENTS", `Error during get statements of ${defaultOrg.id}`, error);
                errorNotifcation(error?.response?.status, "Impossible de récupérer la liste des relevés, veuillez reessayer plus tard");
            }).finally(() =>{
                this.setState({ 
                    loading: false,
                });
            })
        }
    }

    getMoreData = async () => {
        let defaultOrg = JSON.parse(sessionStorage.getItem('defaultOrg'));
        try{
            this.setState({ scrollLoading: true });
            const res = await _getOrganizationStatements(defaultOrg.id, { pageSize: this.state.pageSize, page: (this.state.currentPage + 1) });
            this.props.addStatementsMultiple(res.data.rows);
            this.setPaginationData(res.data);
        } catch(err){
            const error = { ...err };
            Logger.error("STATEMENTS", `Infinite scroll : can not more statements ${defaultOrg.id}`, error);
            errorNotifcation(error?.response?.status, "Impossible de charger plus de relevés, veuillez reessayer plus tard");
        } finally {
            this.setState({ scrollLoading: true });
        }
    }

    setPaginationData = (data) => {
        this.setState({ 
            scrollLoading: false,
            currentPage: data.pager.currentPage,
            endIndex: data.pager.endIndex,
            endPage: data.pager.endPage,
            pageSize: data.pager.pageSize,
            pages: data.pager.pages,
            startIndex: data.pager.startIndex,
            startPage: data.pager.startPage,
            totalItems: data.pager.totalItems,
            totalPages: data.pager.totalPages,
            count: data.count,
        });
    }

    onClick = (item) => {
        this.props.selectStatement(item.originalData);
        this.setState({
            displayModal: true,
            selectedItem: item.originalData,
        }, () => {
            if(this.statementDetails) {
                this.statementDetails.resetFilter();
                this.statementDetails.getRecords();
            };
        })
    }

    onCloseModal = () => {
        if (this.genericTable) this.genericTable.unSelect();
        this.props.selectStatement({});
        this.setState({
            displayModal: false,
            selectedItem: null,
        });
    }

    headerData = [
        { title: <span> Date <img src={reverseOrderIcon} alt="date" width={10} height={10} /></span> , align: "left", className: 'pl-15 stmt-date-width', name: "date"}, 
        { title: <span> Période <img src={reverseOrderIcon} alt="period" width={10} height={10} /></span> , align: "left", className: 'pl-15 stmt-period-width sm-hidden', name: "period"}, 
        { title: <span> Banque <img src={reverseOrderIcon} alt="bank" width={10} height={10} /></span> , align: "left", className: 'text-align-left stmt-name-width sm-hidden', name: "bankName"}, 
        { title: <span> Numéro de compte <img src={reverseOrderIcon} alt="account number" width={10} height={10} /></span> , align: "left", className: 'stmt-account-width', name: "accountNumber"}, 
        { title: <span> Solde de clôture <img src={reverseOrderIcon} alt="closing balance" width={10} height={10} /></span> , align: "right", className: 'stmt-balance-width justify-content-end bold', name: "closingBalance"}, 
        { title: <span> Télécharger <img src={reverseOrderIcon} alt="download" width={10} height={10} /></span> , align: "center", className: 'stmt-attachment-width text-align-center sm-hidden justify-content-center', name: "attachment"}, 
    ];

    formatBodyData = (data) => {
        if (!data?.length) return null;
        return data.map((item, i) => {
            let resData = {
                originalData: item,
                date: <DateItemList date={item.date ? moment(item.date).format('YYYY/MM/DD') : moment(item.endPeriodDate).format('YYYY/MM/DD')} active={ true } type={'bank'} />,
                period: <span>
                            <span>Du </span>
                            <span className="bold"> {moment(item.startPeriodDate).format('DD-MM-YY')} </span>
                            <span> au </span>
                            <span className="bold"> {moment(item.endPeriodDate).format('DD-MM-YY')} </span>
                        </span>,
                bankName: <span>{(item.account.bank.logoSrc ?
                        <img width={17} height={17} alt=" " src={item?.account?.bank.logoSrc} />
                    :
                        "") + " " + item?.account?.bank.bankName}</span>,
                accountNumber: <span>{item?.account.accountNumber}</span>,
                closingBalance: <span><span className="devise"> {`${item?.account.currency} `} </span> {`${numberFormat(item.closingBalance)}`} </span>,
                attachment: (item.attachment ?
                    <a href={item.attachment} onClick={(e) => { e.stopPropagation() }} target='_blank' rel="noopener noreferrer" download={`relevé bancaire  ${item.account.bank.bankName} ${item.startDate}`} alt={`relevé bancaire ${item?.account?.bank.bankName} ${item.startDate}`} title={`relevé bancaire ${item.account.bank.bankName} ${item.startDate}`} >
                        <img src={imgPdfFile} width={15} height={'auto'} alt={'BankStatement file'} />
                    </a>  
                    : <img src={imgPdfFileNone} width={15} height={'auto'} alt={'Relevé bancaire'} />),
            }
            return resData;
        });
    }

    render() {
        return(
            <div className="bank">
                <div className='fluid-content-data '>
                    <div className="d-flex justify-content-between mt-20 pl-3 pr-3">
                        <SearchBankRecord
                            onRef={this.setRefSearchBank}
                            handleSearch={this.handleSearch}
                            displayAccounts
                            reloadBankStatements={this.reloadBankStatements}
                            closeModal={this.onCloseModal}
                            import
                        />
                    </div>
                    <div className='mt-4 pl-lg-3 pr-lg-3'>
                        <Suspense fallback={<div className="text-center">Chargement... </div>} >
                            <GenericTable
                                data={this.props.statements}
                                onRef={this.setRefGenericTable}
                                header={this.headerData}
                                body={this.formatBodyData(this.props.statements)}
                                className='mlr-1'
                                hoverColor="rgba(255, 219, 219, 0.2)"
                                isLoading={this.state.loading}
                                asLoader={<Loader style={{width: "25px", height: "25px"}} enable />}
                                onSelect={this.onClick}
                                infiniteScroll
                                getData={this.getMoreData}
                                hasMore={(this.state.currentPage < this.state.endPage)}
                                scrollLoading={this.state.scrollLoading}
                            />
                        </Suspense>
                    </div>
                </div>
                { this.state.displayModal ?
                    <div className={'bank-detail'}>
                        <DisplayContainer headerTile={this.state.selectedItem ? this.state.selectedItem?.account?.bank.bankName : ''} onClose={this.onCloseModal} loading={this.state.loadModalBank} >
                            <StatementDetails
                                onRef={this.setRefStatementDetails}
                                item={this.state.selectedItem}
                            />
                        </DisplayContainer>
                    </div> : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        statements: state.accounts.statements,
        selectedStatement: state.accounts.selectedStatement,
        selectedAccount: state.accounts.selectedAccount,
    }
}

export default connect(mapStateToProps, { setStatements, addStatementsMultiple, selectStatement })(BankStatement)
