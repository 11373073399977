
import React, { PureComponent } from 'react';
import PropTypes from "prop-types";
import { _getAllowancesByName } from '../../services/Allowance';
import Autocomplete from "react-autocomplete";
import {Toggle} from "react-toggle-component";
import Tag from '../Tag';
import IconClose from '../Icons/IconClose';
import CheckIcon from '../Icons/CheckIcon';
import Logger from '../../helpers/Logger';
import { errorNotifcation } from "../../helpers/errorHandle";

import "./AllowancesManager.css";

export default class AllowancesManager extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            allowances: [],
            parentAllowances: this.props.allowances || [],
            nameAllowance: '',
            amountAllowance: '',
            proratization: false,
            editMode: true,
            id: null,
            editPrime: false,
            oldAllowanceName: '',
            allowanceId: '',
            contentTabStyles: {
                padding: "20px 0 20px 20px",
			},
			...this.props.objectID
        };
        this.autocomplete = React.createRef();
        this.toggleRef = React.createRef();
    }

    componentDidMount() {
        this.autocompleteInputStyle();
    }

    autocompleteInputStyle = () => {
        if (this.autocomplete.current) {
            this.autocomplete.current.refs.input.style.border = 'none';
            this.autocomplete.current.refs.input.style.margin = 0;
            this.autocomplete.current.refs.input.placeholder = 'Entrez une prime ou ...';
            this.autocomplete.current.refs.input.style.fontSize = '0.75rem';
            this.autocomplete.current.refs.input.style.backgroundColor = 'transparent';
        }
    }

    handleChangePrime = async (e) => {
        const { value } = e.target;
        this.setState({
            nameAllowance: value,
        }, async () => { 
            try{
                let allowances = (await _getAllowancesByName(value)).data;
                allowances = await this.excludeExistingAllowances(allowances);
                if(allowances){
                    this.setState({
                        allowances,
                    });
                }
            } catch (error) {
                Logger.error("GetAllowancesByName", "Could not get allowance by name", error);
                errorNotifcation(error?.response?.status, "Erreur pendant la récupération des primes, veuillez reessayer ultérieurement.");
                this.setState({
                    allowances: [],
                });
            }
        })
    }

    excludeExistingAllowances = async (allowances) => {
        const allowanceIds = this.props.allowances?.map(allowance => {return allowance?.allowance.name}) || [];
        const allowancesTab =  allowances.filter(allowance => !allowanceIds?.includes(allowance.name));
        return allowancesTab;
    }

    selectPrimeItem = (value, allowanceId) => {
        this.setState({
            nameAllowance: value,
            allowanceId,
        })
    }

    handleChange = (event) => {
        const {value} = event.target;
        this.setState({
            [event.target.name]: value
        });
    }

    onToggleProratisation = (event) => {
        this.setState({
            proratization: event.target.checked
        });
    }

    addOrUpdatePrime = async () => {
        if(this.props.objectID && this.state.allowanceId){
            let result = false;
			const {objectID} = this.props;
			const parentValue = Object.values(objectID)[0];
            let {editPrime, amountAllowance, proratization, allowanceId, nameAllowance, id} = this.state;
            const data = {
                id,
                amount: +amountAllowance,
                proratization,
                allowanceId,
                ...objectID,
            }

            if(editPrime && parentValue) {
                result = await this.updateAllowance(+parentValue, data, nameAllowance);
            } else {
                result = await this.createAllowance(+parentValue, data, nameAllowance, editPrime);
            }
            if(result)
                this.cancelPrime();
        }
    }


    cancelPrime = () => {
        this.setState({
            id: '',
            nameAllowance: '',
            amountAllowance: '',
            proratization: false,
            editPrime: false,
            oldAllowanceName: '',
            allowanceId: '',
            allowances: [],
        }, () => {
            this.toggleRef.current.checked = this.state.proratization;
        });
    }

    createAllowance =  async (parentId, data, nameAllowance) => {
		if(this.props.saveAllowances){
			return this.props.saveAllowances(parentId, data, nameAllowance, false);
		}
    }

	updateAllowance =  async (id, data, nameAllowance) => {
		if(this.props.updateAllowances){
			return this.props.updateAllowances(id, data, nameAllowance, false);
		}
    }
    
    updateAllowances = async (newPayslipAllowances) => {
		if(this.props.handleAllowances){
			const result = await this.props.handleAllowances(newPayslipAllowances);
            this.setState({
                payslipAllowances: newPayslipAllowances
            });
            return result;
		}
    }

    onClickPrime = (item) => {
        this.setState({
            id: item.id,
            amountAllowance: item.amount,
            nameAllowance: item.allowance.name,
            oldAllowanceName: item.allowance.name,
            proratization: item.proratization,
            allowanceId: item.allowanceId,
            editPrime: true
        }, () => {
            this.toggleRef.current.checked = this.state.proratization
        })
    }


    deleteAllowance = async (item) => {
        const {objectID} = this.props;
		const parentValue = Object.values(objectID)[0];
		if(this.props.deleteAllowance){
            const id = item.id ? item.id : item.allowanceId;
            const result = await this.props.deleteAllowance(+id, +parentValue, false);
            if(result)
                this.cancelPrime();
		}
    }

    renderContentSelected = () => {
        if(this.props.allowances?.length){
            return(
                this.props.allowances.map((item, index) => {
                    return <Tag onTagClick={() => this.onClickPrime(item)} editable={true} title={item.allowance?.name} key={index} id={index} onRemove={() => this.deleteAllowance(item)} />
                })
            )    
        }
    }

    render(){
        return (
            <div id="step-2 ctn-tab" className="allowances" style={this.state.contentTabStyles}>
                <div className="row mt-2 d-flex align-items-end">
                    <div className="col-md-4 col-sm-12 pr-0">
                        <div className="input-label">Prime / Retenue</div>
                        <Autocomplete
                            ref={ this.autocomplete }
                            key="primes"
                            getItemValue={(item) => item.name}
                            items={this.state.allowances}
                            renderItem={(item, isHighlighted) =>
                                <div key={item.id} style={{ 
                                    background: isHighlighted ? '#177BD1' : 'white',
                                    color: isHighlighted ? 'white' : '#333333',
                                    cursor: 'pointer',
                                    padding: 5,
                                    fontSize: "0.75rem",
                                }}>
                                {item.name}
                                </div>
                            }
                            value={this.state.nameAllowance}
                            onChange={(e) => this.handleChangePrime(e)}
                            onSelect={(val, item) => this.selectPrimeItem(val, item.id)}
                            menuStyle = {{
                                borderRadius: '3px',
                                boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                                background: 'rgba(255, 255, 255, 0.9)',
                                padding: '2px 0',
                                fontSize: '90%',
                                position: 'absolute',
                                top: '65px',
                                left: 15,
                                overflow: 'auto',
                                maxHeight: '200px',
                                zIndex: 99999,
                            }}
                            wrapperStyle = {{
                                borderRadius: '3px',
                                padding: '5px',
                                border: 'thin solid rgba(0,0,0,0.2)',
                            }}
                        />
                    </div>    
                    <div className="col-md-4 col-sm-12 pr-0">
                        <div className="input-label">Montant</div>
                        <input name="amountAllowance" onChange={this.handleChange} type="number" 
                            className="form-control" value={this.state.amountAllowance} placeholder="Entrez le montant de la prime" />
                    </div>
                    <div className="col-md-2 col-sm-12 pr-1">
                        <div className="input-label">Proratisation</div>
                        <div className="mt-2">
                            <Toggle ref={this.toggleRef} name="toggle-proratization" radius="3px" radiusBackground="2px" knobRadius="2px"
                                borderWidth="1px" checked={this.state.proratization} value={this.state.proratization}
                                onToggle={this.onToggleProratisation} />
                        </div>
                    </div>
                    <div className="col-md-2 pl-1">
                        <div className={this.state.editMode === true ? 'inputarea-actions-wrapper' : 'hidden'}>
                            <div className="inputarea-action" onClick={() => this.addOrUpdatePrime()}>
                                <CheckIcon color={!this.state.editPrime ? "#707070" : "#177bd1" }/>
                            </div>
                            <div className="inputarea-action ml-1" onClick={() => this.cancelPrime()}>
                                <IconClose color="#707070"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-12">
                        <div className="content-prime">
                            {this.renderContentSelected()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

AllowancesManager.propTypes = {
    allowances: PropTypes.array,
    editMode: PropTypes.bool,
    editPrime: PropTypes.bool,
    objectID: PropTypes.object, // { parentId: value }
    handleAllowances: PropTypes.func,
    saveAllowances: PropTypes.func,
    updateAllowances: PropTypes.func,
    deleteAllowance: PropTypes.func,
}

