/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const IconArrowUpDown = (props) => (
  <svg width={props.width || 7.819} height={props.height || 11.47} viewBox="0 0 7.819 11.47">
    <defs>
      <style>{".icon-arrow-up-down{fill:#707070;font-weight: bold;}"}</style>
    </defs>
    <path
      className="icon-arrow-up-down"
      d="M91.911,163.073l2.956-2.959a.556.556,0,0,1,.789,0,.563.563,0,0,1,0,.791l-3.35,3.352a.558.558,0,0,1-.771.016l-3.373-3.366a.559.559,0,1,1,.789-.791Z"
      transform="translate(-88 -152.95)"
    />
    <path
      className="icon-arrow-up-down"
      d="M3.911,3.123,6.867.164a.556.556,0,0,1,.789,0,.563.563,0,0,1,0,.791L4.306,4.308a.558.558,0,0,1-.771.016L.163.958A.559.559,0,1,1,.952.166Z"
      transform="translate(7.819 4.47) rotate(-180)"
    />
  </svg>
);

export default IconArrowUpDown;
