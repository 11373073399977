/**
 * Format Array allowance list of payslip 
 * @param arrayList : Array
 * @return Array
 */
export const formatPayslipList = (arrayList) => {
    let result = [];
    arrayList.map((elem) => {
        if(elem.hasOwnProperty('primes')){
            let primes = elem.primes;
            return result.push({...elem,primes});
        }else{
            return result.push({...elem,primes:[]});
        }
    });
    return result;
}

export const getQueryString = (url) => {
    // url | format: param1=x&param2=y...
    return new URLSearchParams(url); // *var*.get("query_name") --> to get the query value.
}

export const toNumber = (value) => {
    var numb = parseFloat(value);
    return isNaN(numb) ? 0 : numb;
}