import {_initFunctionalities} from "../../services/Auth";
import { ADD_ORG, SET_ORGS, SET_DEFAULT_ORG, ADD_ORG_BANK_RECORDS, ADD_ORG_BANK_RECORDS_MULTIPLE, ADD_ORG_INVOICE, ADD_ORG_INVOICE_MULTIPLE, SET_ORG_INVOICES,
    SET_ORG_BANK_REPORTS, UPDATE_ORG_INVOICE, UPDATE_SELECTED_INVOICE, UPDATE_UNRECONCILED_INVOICES, SET_RECONCILED_INVOICES,
    SET_SELECTED_INVOICE, SET_SELECTED_ORG_USER, TOGGLE_ORG_ROLE_CHANGE, SET_ORG_USERS, SET_RECONCICLED_BANK_RECORD_ITEM,
    ADD_ORG_BILL, ADD_MULTIPLE_ORG_BILLS, UPDATE_ORG_BILL, DELETE_ORG_BILL, SET_ORG_BILLS, SET_ORG_CUSTOMERS,
    ADD_MULTIPLE_ORG_CUSTOMERS, SET_ORG_TAXES, SELECTED_DEFAULT_ORG_TAXES, ADD_ORG_TAXES, ADD_ORG_TAXES_MULTIPLE, UPDATE_ORG_TAXES, DELETE_ORG_TAXES } from '../action-type/organizationType'

export const addOrganization = (org) => ({
    type: ADD_ORG,
    payload: org
})

export const setOrganizations = (orgs) => ({
    type: SET_ORGS,
    payload: orgs
})

export const setDefaultOrganization = (orgId) => ({
    type: SET_DEFAULT_ORG,
    payload: orgId
})

export const toggleOrgWithRolesChanging = (loaded) => ({
    type: TOGGLE_ORG_ROLE_CHANGE,
    payload: loaded,
})

export const setDefaultOrganizationWithHabilitations =(orgId) =>{
    return  (dispatch) => {
        dispatch(toggleOrgWithRolesChanging(false))
        dispatch(setDefaultOrganization(orgId))
        _initFunctionalities().then(()=>{
            dispatch(toggleOrgWithRolesChanging(true))
        })
    }
}

export const addOrganizationInvoice = (invoice) => ({
    type: ADD_ORG_INVOICE,
    payload: invoice
})

export const addMultipleOrganizationInvoice = (invoices) => ({
    type: ADD_ORG_INVOICE_MULTIPLE,
    payload: invoices
})

export const setOrganizationInvoices = (invoices) => ({
    type: SET_ORG_INVOICES,
    payload: invoices
})

export const addOrganizationBankRecords = (bankRecords) => ({
    type: ADD_ORG_BANK_RECORDS,
    payload: bankRecords
})

export const addMultipleOrganizationBankRecords = (bankRecords) => ({
    type: ADD_ORG_BANK_RECORDS_MULTIPLE,
    payload: bankRecords
})

export const setReconciledBankRecordItem = (bankRecord) => ({
    type: SET_RECONCICLED_BANK_RECORD_ITEM,
    payload: bankRecord,
})

export const setOrganizationBankRecords = (bankRecords) => ({
    type: SET_ORG_BANK_REPORTS,
    payload: bankRecords
})

export const updateOrganizationInvoice = (invoice) => ({
    type: UPDATE_ORG_INVOICE,
    payload: invoice
})

export const updateSelectedInvoice = (invoice) => ({
    type: UPDATE_SELECTED_INVOICE,
    payload: invoice
})

export const updateUnreconciledInvoices = (unmatched_invoices) => ({
    type: UPDATE_UNRECONCILED_INVOICES,
    payload: unmatched_invoices,
})

export const setReconciledInvoices = (matched_invoices) => ({
    type: SET_RECONCILED_INVOICES,
    payload: matched_invoices,
})

export const setSelectedInvoice = (invoice) => ({
    type: SET_SELECTED_INVOICE,
    payload: invoice,
})
export const setSelectedOrgUser = (user) => ({
    type: SET_SELECTED_ORG_USER,
    payload: user,
})

export const setOrgUsers = (users) => ({
    type: SET_ORG_USERS,
    payload: users,
})

export const setOrganizationBills = (bills) => ({
    type: SET_ORG_BILLS,
    payload: bills
})

export const addOrganizationBill = (bill) => ({
    type: ADD_ORG_BILL,
    payload: bill
})

export const addMultipleOrganizationBills = (bills) => ({
    type: ADD_MULTIPLE_ORG_BILLS,
    payload: bills
})

export const updateOrganizationBill = (bill) => ({
    type: UPDATE_ORG_BILL,
    payload: bill
})

export const deleteOrganizationBills = (id) => ({
    type: DELETE_ORG_BILL,
    payload: id
})

export const setOrganizationCustomers = (customers) => ({
    type: SET_ORG_CUSTOMERS,
    payload: customers,
});

export const addMultipleOrganizationCutomers = (customers) => ({
    type: ADD_MULTIPLE_ORG_CUSTOMERS,
    payload: customers
});

export const setOrganizationTaxes = (taxes) => ({
    type: SET_ORG_TAXES,
    payload: taxes
})

export const selectedDefaultOrgTaxes = (taxe) => ({
    type: SELECTED_DEFAULT_ORG_TAXES,
    payload: taxe
})

export const addOrganizationTaxes = (item) => ({
    type: ADD_ORG_TAXES,
    payload: item
})

export const addMultipleOrganizationTaxes = (taxes) => ({
    type: ADD_ORG_TAXES_MULTIPLE,
    payload: taxes
})

export const updateOrganizationTaxes = (item) => ({
    type: UPDATE_ORG_TAXES,
    payload: item
})

export const deleteOrganizationTaxes = (id) => ({
    type: DELETE_ORG_TAXES,
    payload: id
})