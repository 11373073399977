import React from "react";

const ArrowDownCircle = (props) => (
  <svg viewBox="0 0 26.604 26.604" width={props.size ? props.size : 26.604} height={props.size ? props.size : 26.604}>
    <defs>
      <style>
        {
          ".a,.b{fill:none;stroke:#0c9f23;stroke-width:1.5px;}.a{stroke-linecap:round;stroke-linejoin:round;}.b{stroke-miterlimit:10;}"
        }
      </style>
    </defs>
    <g transform="translate(-63.25 -63.25)">
      <path
        fill="none"
        stroke={props.color ? props.color : "#0c9f23"}
        strokeWidth={props.strokeWidth ? props.strokeWidth : 1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M176,176.055l5.23,5.189,5.23-5.189m-5.23,4.468V170"
        transform="translate(-104.678 -99.07)"
      />
      <path
        fill="none"
        stroke={props.color ? props.color : "#0c9f23"}
        strokeWidth={props.strokeWidth ? props.strokeWidth : 1.5}
        strokeMiterlimit={10}
        d="M76.552,64A12.552,12.552,0,1,0,89.1,76.552,12.555,12.555,0,0,0,76.552,64Z"
      />
    </g>
  </svg>
);

export default ArrowDownCircle;
