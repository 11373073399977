import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from "react-redux-toastr";
import {Dropdown} from "react-bootstrap";
import moment from "moment";
import { errorNotifcation } from '../../helpers/errorHandle';
import { _updateOrganizationCnpsDeclaration, _teledeclareOrganizationCnps, _confirmCnpsDeclaration } from "../../services/Organization";
import { 
    setCnpsDeclaration, addCnpsDeclaration, addMoreCnpsDeclaration, updateCnpsDeclaration, deleteCnpsDeclaration, selectCnpsDeclaration 
} from "../../redux/action/TaxesAction";
import { SESSION_STORAGE_KEYS} from '../../constants/constants';
import Logger from '../../helpers/Logger';
import Loader from '../../components/Loader';
import DownloadIcon from "../../components/Icons/IconCloudDownloadComponent";
import IconBack from '../../components/Icons/IconBack';
import IconSave from '../../components/Icons/SaveIcon';
import { getQueryString } from '../../helpers/dataFormat';
import { numberFormat } from '../../helpers/Number';
import {staticNode} from "../../helpers/DOM";

import "./taxes.css";


class CnpsEdit extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            payload: null,
        }
    }

    componentDidMount(){
        staticNode(".hoverlay-controls", 90, "offset");
        this.initData();
    }

    initData = () => {
        this.props.selectCnpsDeclaration(this.props.history.location.state);
        this.setState({payload: this.props.history.location.state});
    }

    handleChange = (e) => {
        e.preventDefault();
        this.setState({ payload:  {...this.state.payload, [e.target.name]: e.target.value} });
    }

    handleChangeDetail = (e, i) => {
        e.preventDefault();
        const { details } = this.state.payload;
        details[i][e.target.name] = e.target.value;
        this.setState({ payload: { ...this.state.payload, details } });
    }

    handleSelect = (ek, e) => {
        this.setState({payload: { ...this.state.payload, regime: ek }})
    }

    updateDeclaration = async () => {
        this.setState({isLoading: true});
        const defaultOrg = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
        const { payload } = this.state;
        try {
            const res = await _updateOrganizationCnpsDeclaration(defaultOrg.id, this.state.payload?.id, payload);
            this.props.selectCnpsDeclaration(res.data);
            this.props.updateCnpsDeclaration(res.data);
            toastr.success("", "Déclaration CNPS mise a jour avec succeès!");
        } catch(e) {
            Logger.error("Can't Update CNPS Declaration", e);
            if(e?.response?.status === 400){ toastr.info("", e?.response?.data?.title?.trim()); } else {
                errorNotifcation(e?.response?.status, "Echec lors de la modification de la période de déclaration CNPS', veuillez reessayer ultérieurement");
            }
        } finally {
            this.setState({isLoading: false});   
        }
    }

    teledeclare = async () => {
        this.setState({isLoading: true});
        const defaultOrg = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
        try {
            await _teledeclareOrganizationCnps(defaultOrg.id, this.state.payload?.id);
            toastr.info("", "Télédéclaration initiée !");
        } catch(err) {
            Logger.error("Can't set teledeclaration", err);
            errorNotifcation(err?.response?.status, "Echec de la télédéclaration', veuillez reessayer ultérieurement!");
        } finally {
            this.setState({isLoading: false});   
        }
    }

    confirmDeclartion = async () => {
        this.setState({isLoading: true});
        const defaultOrg = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
        try {
            await _confirmCnpsDeclaration(defaultOrg.id, this.state.payload?.id);
            toastr.info("", "Télédéclaration initiée !");
        } catch(err) {
            Logger.error("Can't set teledeclaration", err);
            errorNotifcation(err?.response?.status, "Echec de la télédéclaration', veuillez reessayer ultérieurement!");
        } finally {
            this.setState({isLoading: false});   
        }
    }

    queryParam = () => {
        const qs = getQueryString(this.props.history.location.search);
        return qs.get("period");
    }

    gotoPeriodList = () => {
        this.props.history.push(this.props.history.push('/taxes/cnps'));
    }

    renderTableRows = () => {
        return this.state.payload?.details.map((item, i) => {
            return (
                <tr key={i}>
                    <td align="left" className="bordered"><input type="text" name="name" value={item.name} onChange={(e) => this.handleChangeDetail(e, i)} placeholder="" className="input-text pr-2 bold" disabled/></td> 
                    <td className="v-align-middle bordered"><input type="text" name="numEnCnps" value={item.numEnCnps} onChange={(e) => this.handleChangeDetail(e, i)} placeholder="" className="input-text pr-2" disabled/></td>
                    <td align="right" className="bordered-bottom"><input type="text" name="days" value={numberFormat(item.days)} onChange={(e) => this.handleChangeDetail(e, i)} placeholder="" className="input-text text-right-h pr-2" /></td>
                    <td className="border-dashed" align="right"><input type="text" name="brutSalary" value={numberFormat(item.brutSalary)} onChange={(e) => this.handleChangeDetail(e, i)} placeholder="" className="input-text text-right-h pr-2"/></td>
                    <td align="right" className="border-dashed"><input type="text" name="exceptionalSalary" value={numberFormat(item.exceptionalSalary)} onChange={(e) => this.handleChangeDetail(e, i)} placeholder="" className="input-text text-right-h pr-2" /></td>
                    <td align="right" className="border-dashed"><input type="text" name="taxableSalary" value={numberFormat(item.taxableSalary)} onChange={(e) => this.handleChangeDetail(e, i)} placeholder="" className="input-text text-right-h pr-2"/></td>
                    <td align="right" className="border-dashed"><input type="text" name="contributeSalary" value={numberFormat(item.contributeSalary)} onChange={(e) => this.handleChangeDetail(e, i)} placeholder="" className="input-text text-right-h pr-2"/></td>
                    <td align="right" className="border-dashed"><input type="text" name="cappedContributeSalary" value={numberFormat(item.cappedContributeSalary)} onChange={(e) => this.handleChangeDetail(e, i)} placeholder="" className="input-text text-right-h pr-2" /></td>
                    <td align="right" className="border-dashed"><input type="text" name="irpp" value={numberFormat(item.irpp)} onChange={(e) => this.handleChangeDetail(e, i)} placeholder="" className="input-text text-right-h pr-2"/></td>
                    <td align="right" className="border-dashed"><input type="text" name="matricule" value={item.matricule} onChange={(e) => this.handleChangeDetail(e, i)} placeholder="" className="input-text text-right-h pr-2" disabled/></td>
                </tr>
            );
        })
    }

    render() {
        return (
            <div className='bill-list col-display'>
                <div className="d-flex flex-column flex-1-auto pl-3">
                    <div className='d-flex flex-column flex-1-auto mt-4 pr-2'>
                        <div className="options-control-header mb-3">
                            <div className="head-option-left">
                                <button className='btn-plus-taxe mr-2' onClick={this.gotoPeriodList}>
                                    <IconBack  color="#FFFFFF" />
                                </button>
                                <button onClick={this.updateDeclaration} className="btn-update-taxe d-flex align-items-center mr-2 p2 pl-2 pr-2">
                                    <IconSave color="#09B6CC" width={10} height={10} /> 
                                    <span className="ml-2">Mettre a jour</span>
                                </button>
                                {
                                    this.state.payload?.dipeAttachement ?
                                    <>
                                        <a 
                                            className="btn btn-update-taxe p2 mr-2" 
                                            rel="noopener noreferrer"
                                            target="_blank" 
                                            href={this.state.payload.dipeAttachement}
                                            download
                                        >
                                            <DownloadIcon style={{transform: "scale(1.2)"}} fill="#09B6CC"/>
                                            <span className="ml-2">Télécharger le DIPE mensuel</span>
                                        </a>
                                        {
                                            this.props.selectedCnpsDeclaration.proof1 ?
                                            <a 
                                                className="btn btn-update-taxe p2 mr-2"
                                                rel="noopener noreferrer"
                                                target="_blank" 
                                                href={this.props.selectedCnpsDeclaration.proof1}
                                                download
                                            >
                                                <DownloadIcon style={{transform: "scale(1.2)"}} fill="#09B6CC"/>
                                                <span className="ml-2">Télécharger la preuve 1e</span>
                                            </a> : null
                                        }
                                        {
                                            this.props.selectedCnpsDeclaration.proof2 ?
                                            <a 
                                                className="btn btn-update-taxe p2 mr-2"
                                                rel="noopener noreferrer"
                                                target="_blank" 
                                                href={this.props.selectedCnpsDeclaration.proof2}
                                                download
                                            >
                                                <DownloadIcon style={{transform: "scale(1.2)"}} fill="#09B6CC"/>
                                                <span className="ml-2">Télécharger la preuve 2e</span>
                                            </a> : null
                                        }
                                        {
                                            !this.props.selectedCnpsDeclaration.proof1 || !this.props.selectedCnpsDeclaration.proof2 ?
                                                <button onClick={!this.props.selectedCnpsDeclaration.proof1 ? this.teledeclare : this.confirmDeclartion} className="btn-update-taxe d-flex align-items-center mr-2 p2">
                                                    <span className="pl-2 mr-2"> {!this.props.selectedCnpsDeclaration.proof1 ? "Télédéclarer" : "Confirmer"}</span> 
                                                </button>
                                            : null
                                        }
                                    </> : <></>
                                }
                                <Loader enable={this.state.isLoading} className="ml-3" style={{width: 28, height: 28}} />
                            </div>
                            <></>
                        </div>

                        <div className="hoverlay-controls">
                            <div className="d-flex align-items-center justify-content-between m-2 flex-1-auto">
                                <div className="head-option-left">
                                    <button className='btn-plus-taxe mr-2' onClick={this.gotoPeriodList}>
                                        <IconBack  color="#FFFFFF" />
                                    </button>
                                    <button onClick={this.updateDeclaration} className="btn-update-taxe d-flex align-items-center mr-2 p2 pl-2 pr-2">
                                        <IconSave color="#09B6CC" width={10} height={10} /> 
                                        <span className="ml-2">Mettre a jour</span>
                                    </button>
                                    {
                                        this.state.payload?.dipeAttachement ?
                                        <>
                                            <a 
                                                className="btn btn-update-taxe p2 mr-2" 
                                                rel="noopener noreferrer"
                                                target="_blank" 
                                                href={this.state.payload.dipeAttachement}
                                                download
                                            >
                                                <DownloadIcon style={{transform: "scale(1.2)"}} fill="#09B6CC"/>
                                                <span className="ml-2">Télécharger le DIPE mensuel</span>
                                            </a>
                                            {
                                                this.props.selectedCnpsDeclaration.proof1 ?
                                                <a 
                                                    className="btn btn-update-taxe p2 mr-2"
                                                    rel="noopener noreferrer"
                                                    target="_blank" 
                                                    href={this.props.selectedCnpsDeclaration.proof1}
                                                    download
                                                >
                                                    <DownloadIcon style={{transform: "scale(1.2)"}} fill="#09B6CC"/>
                                                    <span className="ml-2">Télécharger la preuve 1e</span>
                                                </a> : null
                                            }
                                            {
                                                this.props.selectedCnpsDeclaration.proof2 ?
                                                <a 
                                                    className="btn btn-update-taxe p2 mr-2"
                                                    rel="noopener noreferrer"
                                                    target="_blank" 
                                                    href={this.props.selectedCnpsDeclaration.proof2}
                                                    download
                                                >
                                                    <DownloadIcon style={{transform: "scale(1.2)"}} fill="#09B6CC"/>
                                                    <span className="ml-2">Télécharger la preuve 2e</span>
                                                </a> : null
                                            }
                                            {
                                                !this.props.selectedCnpsDeclaration.proof1 || !this.props.selectedCnpsDeclaration.proof2 ?
                                                    <button onClick={this.teledeclare} className="btn-update-taxe d-flex align-items-center mr-2 p2">
                                                        <span className="pl-2 mr-2"> {this.props.selectedCnpsDeclaration.proof1 ? "Confirmer" : "Télédéclarer"}</span> 
                                                    </button>
                                                : null
                                            }
                                        </> : <></>
                                    }
                                    <Loader enable={this.state.isLoading} className="ml-3" style={{width: 28, height: 28}} />
                                </div>
                                <></>
                            </div>
                        </div>

                        <div className='d-flex flex-column flex-1-auto'>
                            <div className="taxe-declaration bg-white flex-1-auto overflow-y-auto pt-2 pb-2">
                                <div className="d-flex align-items-center justify-content-between pl-2 pr-2">
                                    <div className="d-flex align-items-center mt-3">
                                        <div className="d-flex align-items-center">
                                            <span className=" normal-txt-uppercase bold">N<sup>o</sup> DIPE:</span> 
                                            <span className="d-flex align-items-center bottom-bordered mw-150 sm-txt ml-2 h-20">
                                                <input type="text" name="numDipe" onChange={this.handleChange} value={this.state.payload?.numDipe} placeholder="" className="input-text bold" disabled/>
                                            </span>
                                        </div>
                                        <div className="d-flex align-items-center ml-2">
                                            {/* <span class=" normal-txt-uppercase bold">Régime:</span> */} 
                                            <span className="d-flex align-items-center sm-txt ml-2 h-20">
                                                {/* <input type="text" name="regime" value={this.state.payload?.regime} onChange={this.handleChange} placeholder="" className="input-text bold" /> */}
                                                <Dropdown className="payslip-actions" onSelect={this.handleSelect}>
                                                    <Dropdown.Toggle className="drop-down-btn" variant="" id="dropdown-payslip">
                                                    { this.state.payload?.regime ? this.state.payload?.regime :  "Régime"}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="drop-down-txt">
                                                        <Dropdown.Item eventKey="Général"> Général </Dropdown.Item>
                                                        <Dropdown.Item eventKey="Agricole"> Agricole </Dropdown.Item>
                                                        <Dropdown.Item eventKey="Enseignement privé"> Enseignement privé </Dropdown.Item>
                                                        <Dropdown.Item eventKey="Domestique"> Domestique </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </span>
                                        </div>
                                    </div>
                                    
                                    <div className="d-flex align-items-center mt-3">
                                        <div className="d-flex align-items-center normal-txt-uppercase bold">
                                            <span>PERIODE</span>
                                            <span className="d-flex align-items-center bottom-bordered mw-100 h-20 ml-2">
                                                <input type="text" name="periodDate" value={moment(this.state.payload?.periodDate).format("MMMM YYYY") } placeholder="" className="input-text bold" disabled />
                                            </span>
                                        </div>
                                        <div className="d-flex align-items-center normal-txt-uppercase bold ml-2">
                                            <span>Effectif des salariés </span>
                                            <span className="d-flex align-items-center bottom-bordered mw-100 h-20 ml-2">
                                                <input type="text" name="numberOfStaff" value={this.state.payload?.numberOfStaff} placeholder="" className="input-text bold" disabled />
                                            </span>
                                        </div>
                                    </div>

                                </div>

                                {/* EFFECTIF SALARIES */}
                                <div className="d-flex align-items-center justify-content-between mt-4 pl-2 pr-2">
                                    <h3 className="title-table normal-txt-uppercase bold"> LISTE DES SALARIES </h3>
                                </div>

                                <div className="d-flex align-items-center justify-content-between mt-3 pl-2 pr-2">
                                    <table className="table-taxes">
                                        <thead>
                                            <tr align="center" className="bg-light-gray">
                                                <td align="left" className="pl-2"><span className="sm-txt bold">Noms et Prenoms</span></td>
                                                <td width="100px" align="left"><span className="sm-txt bold pl-2">Matricule CNPS</span></td>
                                                <td width="50px"><span className="sm-txt bold">Nb jours</span></td>
                                                <td width="100px"><span className="sm-txt bold">Sal. brut</span></td>
                                                <td width="100px"><span className="sm-txt bold">Sal. exceptionnels </span></td>
                                                <td width="100px"><span className="sm-txt bold">Sal. taxable</span></td>
                                                <td width="100px"><span className="sm-txt bold">Sal. cotisable</span></td>
                                                <td width="100px"><span className="sm-txt bold">Sal. plafoné</span></td>
                                                <td width="100px"><span className="sm-txt bold">IRPP</span></td>
                                                <td width="100px"><span className="sm-txt bold">Matr. interne</span></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { this.renderTableRows() }
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth.authData,
        selectedCnpsDeclaration: state.taxes.selectedCnpsDeclaration,
    }
}

export default connect(mapStateToProps, { setCnpsDeclaration, addCnpsDeclaration, addMoreCnpsDeclaration, updateCnpsDeclaration, deleteCnpsDeclaration, selectCnpsDeclaration })(CnpsEdit);
