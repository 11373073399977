export const downloadFile = (url, downloadName) => {
    if(url) {
        fetch(url)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = downloadName || 'dizen_download';
                    a.click();
                });
        }).catch(err => {
            console.error(`Can not download file ${downloadName} from url ${url}`, err);
        });
    } else {
        console.error(`Can not download file from empty url ${url}`);
    }
}