
export const buildAccronym = (fullname, maxlength) => {
    if (fullname.length <= maxlength) {
        return fullname.toUpperCase();
    }
    let result = "";
    fullname.split(" ").forEach(part => {
        result += part.trim()[0];
    })
    return result;

}

export const isSameStringIgnoreCase = (string1, string2) => {
    return string1?.toUpperCase() === string2?.toUpperCase();
}
