import React, { Component } from 'react'
import './Search.css'

class Search extends Component {

    typeTimer = null;

    constructor(props) {
        super(props)
        this.state = {
            term: ''
        }
        this.searchHandler = this.searchHandler.bind(this)
    }

    searchHandler(event){
        this.props.handleSearch(event.target.value)
      }

    render(){
        const { term } = this.props
        return (
            <div className="ct-search">
                <input onChange={this.searchHandler} value={term} className={this.props.iconClass ? `search ${this.props.iconClass}`: `search search-fees`} type="text" placeholder={this.props.placeholder ? this.props.placeholder : "Recherche"} />
            </div>
        )
    }
}

export default Search