/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const IoniconsSvgIosCheckmarkComponent = (props) => (
  <svg width={9.164} height={7.017} viewBox="0 0 9.164 7.017">
    <defs>
    </defs>
    <path
      fill={props.color ? props.color : '#707070'}
      className="check"
      d="M157.515,174.421l-.749-.77a.161.161,0,0,0-.119-.051.154.154,0,0,0-.119.051l-5.192,5.23-1.889-1.889a.165.165,0,0,0-.238,0l-.757.757a.17.17,0,0,0,0,.243l2.383,2.383a.754.754,0,0,0,.5.243.79.79,0,0,0,.494-.234h0l5.69-5.719A.182.182,0,0,0,157.515,174.421Z"
      transform="translate(-148.4 -173.6)"
    />
  </svg>
);

export default IoniconsSvgIosCheckmarkComponent;
