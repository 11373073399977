import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import SubNavLink from "../../views/SubNavLink";
import {PAGINATION} from "../../constants/constants";
import BillPage from "./BillPage";
import BillList from "./BillList";

import "./Bills.css";

class BillsContent extends Component {
    constructor(props){
        super(props);
        this.state = {
            menu: [
                { label: 'Facture', route: '', active: true, notifications: 0 },
                { label: 'Devis', route: '/devis', active: false, notifications: 0 },
                { label: 'Avoirs', route: '/avoirs', active: false, notifications: 0 },
                { label: 'Proforma', route: '/proforma', active: false, notifications: 0 },
                { label: 'Clients', route: '/clients', active: false, notifications: 0 },
            ],
            loading: false,
            activePage: PAGINATION.DEFAULT_PAGE,
            pageSize: PAGINATION.DEFAULT_PAGE_SIZE,
            bankRecords: [],
            pager: null,
            selectedItem: {},
            displayModal: false,
            loadModalBank: false,
            loadingUnreconciledInvoices: false,
            loadingReconciledInvoices: false,
            scanAttachment: '',
            showScanPreview: false,
            showFilePreviewResponsive: false,
            unselectedInvoice: false,
            colorTheme:  {
                bgColor: "#FFFFFF",
                color: "#B5B5B5",
                activeColor: "#0C9F23",
                hoverColor: "transparent",
            }
        };
    }

    setDefaultActiveSubMenu = () => {
        const {pathname} = window.location
        const param = this.getCurrentSubRouteParam(pathname)
        let {menu} = this.state
        

        menu.forEach((item) => (
            item.active = false
        ))
        let activeItem = menu.find((item) => { return item.route ===  '/'+param }) 
        
        activeItem ? activeItem.active = true : menu[0].active = true;
    }

    UNSAFE_componentWillMount(){
        this.setDefaultActiveSubMenu()
    }
    UNSAFE_componentWillUpdate = () => {
        this.setDefaultActiveSubMenu()
    }

    getCurrentSubRouteParam = (pathname) => {
        const part = pathname.split('/')
        return part[ part.length - 1 ]
    }

    render() {
        return(
            <div className='bill-content'>
                <SubNavLink match={this.props.match} menu={this.state.menu} colorTheme={this.state.colorTheme} />
                <Route exact path={this.props.match.url} render={() => {
                    return <BillList {...this.props} />
                }} />
                <Route path={this.props.match.url+'/:page'} component={BillPage} />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { auth, organizations } = state
    return {
        authData: auth.authData,
        organizations: organizations.organizations,
        defaultOrg: organizations.defaultOrganization,
        pager: organizations.organizationBankRecords.pager
    }
}

export default connect(mapStateToProps, { })(BillsContent)
