import { 
    SET_TAXES, SELECT_TAXE, SET_VALUE ,HANDLE_CHANGE_TAB, HANDLE_CHANGE_RATE, HANDLE_CHECKBOX, 
    GET_SUM, GET_SUM_L1_L2, GET_SUM_L3_L4, GET_SUBSTACT_L5_L6, GET_SUM_L5_L7, SWITCH_L5_OR_L7,
    SET_PAGE, GET_L16XL22, SWITCH_L22_OR_L23, ACTION_TAB_FIVE, ACTION_FORM_ONE,
    SUM_TABS, ONCHANGE_TABLE_8, ONCHANGE_TABLE_9, ONCHANGE_TABLE_10, ONCHANGE_TABLE_11, ONCHANGE_TABLE_12,
    ONCHANGE_TABLE_13, ONCHANGE_TABLE_14, ONCHANGE_TABLE_15, ONCHANGE_FOOTER,
    SET_CNPS_DECLARATION_PERIODS, ADD_CNPS_DECLARATION_PERIODS, ADD_MORE_CNPS_DECLARATION_PERIODS, UPDATE_CNPS_DECLARATION_PERIODS, 
    UPDATE_CNPS_DECLARATION_PERIODS_DETAILS, DELETE_CNPS_DECLARATION_PERIODS, SELECT_CNPS_DECLARATION_PERIODS,
} from '../action-type/taxesType';

export const setTaxes = (data) => ({
    type: SET_TAXES,
    payload: data,
});

export const selectTaxe = (data) => ({
    type: SELECT_TAXE,
    payload: data,
});

export const setPage = (page) => ({
    type: SET_PAGE,
    payload: page,
});

export const handleChange = (e) => ({
    type: SET_VALUE,
    payload: e,
});

export const handleChangeTab = (e, tabCol = [], total = "", taux = null, callback = null) => {
    return {
        type: HANDLE_CHANGE_TAB,
        payload: {event: e, tabCol, total, taux},
    }
};

export const handleChangeRate = (e, tabCol = [], total = "", callback = null) => ({
    type: HANDLE_CHANGE_RATE,
    payload: {event: e, tabCol, total},
});

export const handleCheckbox = (e, target, tabCol, callback = null) => ({
    type: HANDLE_CHECKBOX,
    payload: {event: e, target, tabCol},
});

export const getSum = (target, tab = [], taux = null) => ({
    type: GET_SUM,
    payload: {target, tab, taux},
});

export const getSumL1L2 = (callback) => ({
    type: GET_SUM_L1_L2,
    payload: callback,
});

export const getSumL3L4 = (callback) => ({
    type: GET_SUM_L3_L4,
    payload: callback,
});

export const getSubstactL5L6 = (callback) => ({
    type: GET_SUBSTACT_L5_L6,
    payload: callback,
});

export const getSumL5L7 = (callback) => ({
    type: GET_SUM_L5_L7,
    payload: callback,
});

export const switchL5OrL7 = (value) => ({
    type: SWITCH_L5_OR_L7,
    payload: value,
});

export const getL16XL22 = () => ({
    type: GET_L16XL22,
    payload: null,
});

export const switchL22OrL23 = (value) => ({
    type: SWITCH_L22_OR_L23,
    payload: value,
});

export const actionTabFive = () => ({
    type: ACTION_TAB_FIVE,
    payload: null,
});

export const actionFormOne = () => ({
    type: ACTION_FORM_ONE,
    payload: null,
});
// actions by Tobi
export const sumTabs = (tabs = [], destTab = null) => ({
    type: SUM_TABS,
    payload: {tabs, destTab},
});


export const onChangeTable8 = () => ({
    type: ONCHANGE_TABLE_8,
    payload: null,
});

export const onChangeTable9 = () => ({
    type: ONCHANGE_TABLE_9,
    payload: null,
});

export const onChangeTable10 = () => ({
    type: ONCHANGE_TABLE_10,
    payload: null,
});

export const onChangeTable11 = () => ({
    type: ONCHANGE_TABLE_11,
    payload: null,
});

export const onChangeTable12 = () => ({
    type: ONCHANGE_TABLE_12,
    payload: null,
});

export const onChangeTable13 = () => ({
    type: ONCHANGE_TABLE_13,
    payload: null,
});

export const onChangeTable14 = () => ({
    type: ONCHANGE_TABLE_14,
    payload: null,
});

export const onChangeTable15 = () => ({
    type: ONCHANGE_TABLE_15,
    payload: null,
});

export const onChangeFooter = (date=null) => ({
    type: ONCHANGE_FOOTER,
    payload: {date},
});

export const setCnpsDeclaration = (payload) => ({
    type: SET_CNPS_DECLARATION_PERIODS,
    payload,
});

export const addCnpsDeclaration = (payload) => ({
    type: ADD_CNPS_DECLARATION_PERIODS,
    payload,
});

export const addMoreCnpsDeclaration = (payload) => ({
    type: ADD_MORE_CNPS_DECLARATION_PERIODS,
    payload,
});

export const updateCnpsDeclaration = (payload) => ({
    type: UPDATE_CNPS_DECLARATION_PERIODS,
    payload,
});

export const updateDetailsCnpsDeclaration = (payload) => ({
    type: UPDATE_CNPS_DECLARATION_PERIODS_DETAILS,
    payload,
});

export const deleteCnpsDeclaration = (id) => ({
    type: DELETE_CNPS_DECLARATION_PERIODS,
    payload: id,
});

export const selectCnpsDeclaration = (payload) => ({
    type: SELECT_CNPS_DECLARATION_PERIODS,
    payload,
});
