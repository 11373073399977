export const periodDatas = [
    {
        name: "John DOE",
        days: 20,
        absences: 0,
        sicknessVaccation: 0,
        bonus: 1,
        payslipId: 1,
        status: "Pending"
    },
    {
        name: "Bruce WAYNE",
        days: 20,
        absences: 0,
        sicknessVaccation: 0,
        bonus: 3,
        payslipId: 2,
        status: "Pending"
    },
    {
        name: "Clark KENT",
        days: 20,
        absences: 0,
        sicknessVaccation: 0,
        bonus: 0,
        payslipId: 3,
        status: "Pending"
    },
    {
        name: "Oliver QUEEN",
        days: 20,
        absences: 0,
        sicknessVaccation: 0,
        bonus: 1,
        payslipId: 4,
        status: "Pending"
    },
    {
        name: "Amanda WELLER",
        days: 18,
        absences: 0,
        sicknessVaccation: 0,
        bonus: 1,
        payslipId: 1,
        status: "Pending"
    },
]