import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { getPayslipsPerPayPeriodId, _updatePayPeriod } from '../../services/PayPeriod'; 
import { generatePayslip, sendPayslipsByMail } from '../../services/PaySlip';
import Search from '../../components/Search';
import { setPayslips, addPayslipsMultiple, setPayslipsSentByMail, selectPayslip } from '../../redux/action/PayslipAction';
import { setClosePayPeriodLoader } from '../../redux/action/PayPeriodAction';
import { periodDatas } from "../../constants/payslipsPeriodData";
import { PAGINATION } from "../../constants/constants";
import CalendarComponent from '../../components/Icons/CalendarComponent';
import IconBack from '../../components/Icons/IconBack';
import DoubleArrow from '../../components/Icons/DoubleArrow';
import moment from "moment";
import {Dropdown} from "react-bootstrap";
import Loader from '../../components/Loader';
import DetailContainer from "../../components/DetailContainer";
import CustomBtn from "../../components/Button";
import EditIcon from "../../components/Icons/EditIconComponent";
import DownloadIcon from "../../components/Icons/IconCloudDownloadComponent";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import EditableInput from "../../components/EditableInput";
import RowGroup from "../../components/RowGroup";
import { numberFormat } from "../../helpers/Number";
import PayslipTab from "../../components/Tabs/PayslipTab";
import Logger from '../../helpers/Logger';
import { toastr } from "react-redux-toastr";
import imgPdfFile from '../../assets/svg/file-pdf-cog.svg';
import imgPdfFileNone from '../../assets/svg/file-pdf-cog-none.svg';
import Adder from '../../components/Adder';
import LabelStatus from '../../components/LabelStatus';
import { errorNotifcation, infoNotification } from "../../helpers/errorHandle";
const GenericTable = lazy(() => import("../../components/Table/GenericTable") );

class PayslipViewDetailList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            staffName: null,
            period: this.props.period,
            payslip: null,
            datas: periodDatas,
            list: [],
            loader: false,
            dipeLoader: false,
            loadData: false,
            selectedItem: null,
            pageOfItems: [],
            DetailList: false,
            showDetail: false,
            isPreview: true,
            step: 0,
            changePayslipTab: true,
            pageSize: PAGINATION.DEFAULT_PAGE_SIZE,
            startPage: PAGINATION.DEFAULT_PAGE,
            currentPage: PAGINATION.DEFAULT_PAGE,
            endIndex: null,
            endPage: null,
            pages: [],
            startIndex: null,
            totalItems: null,
            totalPages: null,
            count: null,
            hasMore: true,
            scrollLoading: false,
            tableContainerWidth: "100%"
		}
    }

    selectItem = (data) => {
        this.props.selectPayslip(data.originalData);
        this.setState({
            showDetail: true,
            staffName: data.source[1].value,
            changePayslipTab: false,
            tableContainerWidth: "calc(100% - 450px)"
        });
    }

    gotoMainList = () => {
        if(this.props.goBack){
            this.props.selectPayslip();
            this.props.goBack(null)
        }
    }

    /**
	 * Get list of payslips's payperiod 
	 */
    getList = async () => {
        this.setState({
            loadData: true
        });
        try {
            let defaultOrganization = await JSON.parse(sessionStorage.getItem('defaultOrg'));
            const {period} = this.props;
            const res = (await getPayslipsPerPayPeriodId(defaultOrganization.id, period.id, this.state.pageSize, this.state.currentPage)).data;
            this.setState({
                currentPage: res.pager.currentPage,
                endIndex: res.pager.endIndex,
                endPage: res.pager.endPage,
                pageSize: res.pager.pageSize,
                pages: res.pager.pages,
                startIndex: res.pager.startIndex,
                startPage: res.pager.startPage,
                totalItems: res.pager.totalItems,
                totalPages: res.pager.totalPages,
                count: res.count,
                list: res.rows,
            });
            this.props.setPayslips(res.rows);
        }catch(err) {
            Logger.error("getPayslipsPayPeriodId", "Unable to get pay slip of specific period", err);
            errorNotifcation(err?.response?.status, "Impossible de récupérer les fiches de paies pour cette période, veuillez rafraichir la page");
        } finally {
            this.setState({loadData: false});
        }
    }

    getMoreData = async () => {
        try{
            let defaultOrganization = await JSON.parse(sessionStorage.getItem('defaultOrg'));
            const {period} = this.props;
            this.setState({ scrollLoading: true });
            const res = await getPayslipsPerPayPeriodId(defaultOrganization.id, period.id, this.state.pageSize, this.state.currentPage + 1);
            if(res?.status === 200){
                this.setState({ 
                    scrollLoading: false,
                    currentPage: res.data.pager.currentPage,
                    endIndex: res.data.pager.endIndex,
                    endPage: res.data.pager.endPage,
                    pageSize: res.data.pager.pageSize,
                    pages: res.data.pager.pages,
                    startIndex: res.data.pager.startIndex,
                    startPage: res.data.pager.startPage,
                    totalItems: res.data.pager.totalItems,
                    totalPages: res.data.pager.totalPages,
                    count: res.data.count,
                    list: res.data.rows,
                });
                this.props.addPayslipsMultiple(res.data.rows);
            }else{
                console.error("Erreur lors de la recuperation de donnees");
            }
        }catch(e){
            console.error("error: ", e);
        }
    }

    toggleLoader = (callback = null) => {
        this.setState({
            loader: !this.state.loader
        }, (state = this.state) => {
            if (callback){
                callback(state);
            }
        })
    }

    goToStep = (event, step) => {
		if(step <= 1 && step >= 0){
			this.setState({
				step
			})
		}
    }

    /**
     * Generation's payslips 
     */
    generatePayslips = async (printOrGenerate = 'generate') => {
        const defaultOrganization = await JSON.parse(sessionStorage.getItem('defaultOrg'));
        this.toggleLoader();
        let payslips = this.state.list;
        try{
            payslips.forEach(async (p, i) => {
                payslips[i].loader = true;
            });
            this.setState({
                list: payslips,
            });
            await generatePayslip(defaultOrganization.id, this.props.period.id,printOrGenerate);
        }catch(error){
            payslips.map(p => p.loader = false);
            this.setState({
                loader: false,
                list: payslips
            });
            Logger.error("generatePayslip", "Unable to generate pay slip", error);
            infoNotification(error?.response?.status, "Nous traitons toujours votre requète, veuillez patienter.");
        }
    }

    sendPayslipsByMail = async () => {
        const defaultOrganization = await JSON.parse(sessionStorage.getItem('defaultOrg'));
        this.props.setPayslipsSentByMail(true);
        try{
            const result = (await sendPayslipsByMail(defaultOrganization.id, this.props.period.id)).data;
            if(result) {
                toastr.success("", "Fiches de paie envoyées par mail avec succès!");
            }
        }catch(error){
            Logger.error("sendPayslipByMail", "Unable to send Pay slip by mail.", error);
            errorNotifcation(error?.response?.status, "Erreur à l'envoi des fiches de paies par mail, veuillez reessayer ultérieurement.");
        }finally {
            this.props.setPayslipsSentByMail(false);
        }
    }

    generateDipeButonText(){
        return this.state.dipeLoader ? 'En cours...' : 'Générer la dipe'
    }

    componentDidMount() {
        this.getList();
    }

    updatePayPeriod = async () => {
        let defaultOrganization = await JSON.parse(sessionStorage.getItem('defaultOrg'));
        this.props.setClosePayPeriodLoader(true);
        try {
            const payPeriod = (await _updatePayPeriod(defaultOrganization.id, this.props.period.id, {close: true})).data;
            this.setState({
                period: payPeriod.payPeriodUpdated,
            })
            toastr.success("", "Période de paie fermée avec succès !");
        }catch(error){
            const err = { ...error };
            Logger.error("updatedPayPeriod","Unbale to close pay period", err);
            errorNotifcation(err?.response?.status, "Echec de la fermeture de la période de paie, veuillez reessayer plus tard");
        }finally {
            this.props.setClosePayPeriodLoader(false);
        }
    }

    renderImpots = () => {
        const {selectedPayslip} = this.props;
        return(
            <div className="impots-content">

                <div className="title-content-section"> Impôts salariaux </div>
                <RowGroup className="mt-1 ml-2 mr-2">
                    <div className="row-detail-item txt-grey"> 
                        <span className="txt-thin txt-small pl-10 mr-20">IRPP</span>
                        <span className="txt-bold txt-medium pr-10">{`XAF ${numberFormat(+selectedPayslip?.irpp, 2, ",", " ")}`}</span>
                    </div>
                    <div className="row-detail-item txt-grey"> 
                        <span className="txt-thin txt-small pl-10 mr-20">IRPP CAC</span>
                        <span className="txt-bold txt-medium pr-10">{`XAF ${numberFormat(+selectedPayslip?.irppCac, 2, ",", " ")}`}</span>
                    </div>
                    <div className="row-detail-item txt-grey"> 
                        <span className="txt-thin txt-small pl-10 mr-20">CFC Salarial</span>
                        <span className="txt-bold txt-medium pr-10">{`XAF ${numberFormat(+selectedPayslip?.cfc, 2, ",", " ")}`}</span>
                    </div>
                    <div className="row-detail-item txt-grey"> 
                        <span className="txt-thin txt-small pl-10 mr-20">Redevance Audiovisuelle</span>
                        <span className="txt-bold txt-medium pr-10">{`XAF ${numberFormat(+selectedPayslip?.audioVisualFee, 2, ",", " ")}`}</span>
                    </div>
                    <div className="row-detail-item txt-grey"> 
                        <span className="txt-thin txt-small pl-10 mr-20">Taxe Communale</span>
                        <span className="txt-bold txt-medium pr-10">{`XAF ${numberFormat(+selectedPayslip?.localDvpTax, 2, ",", " ")}`}</span>
                    </div>
                </RowGroup>

                <div className="title-content-section"> Impôts patronaux </div>
                <RowGroup className="mt-1 ml-2 mr-2 mb-2">
                    <div className="row-detail-item txt-grey"> 
                        <span className="txt-thin txt-small pl-10 mr-20">CFC Patronal</span>
                        <span className="txt-bold txt-medium pr-10">{`XAF ${numberFormat(+selectedPayslip?.employeCfc, 2, ",", " ")}`}</span>
                    </div>
                    <div className="row-detail-item txt-grey"> 
                        <span className="txt-thin txt-small pl-10 mr-20">FNE</span>
                        <span className="txt-bold txt-medium pr-10">{`XAF ${numberFormat(+selectedPayslip?.fne, 2, ",", " ")}`}</span>
                    </div>
                </RowGroup>
            </div>
        )
    }

    renderCnps = () => {
        const {selectedPayslip} = this.props;
        return(
            <div className="cnps-content">
                <div className="title-content-section"> CNPS salariaux </div>
                <RowGroup className="mt-1 ml-2 mr-2">
                    <div className="row-detail-item txt-grey"> 
                        <span className="txt-thin txt-small pl-10 mr-20">Pension Vieillesse Salariale</span>
                        <span className="txt-bold txt-medium pr-10">{`XAF ${numberFormat(+selectedPayslip?.oldAgeBranch, 2, ",", " ")}`}</span>
                    </div>
                </RowGroup>

                <div className="title-content-section"> CNPS patronaux </div>
                <RowGroup className="mt-1 ml-2 mr-2">
                    <div className="row-detail-item txt-grey"> 
                        <span className="txt-thin txt-small pl-10 mr-20">Pensions Vieillesse Patronalles</span>
                        <span className="txt-bold txt-medium pr-10">{`XAF ${numberFormat(+selectedPayslip?.oldAgeBranch, 2, ",", " ")}`}</span>
                    </div>
                    <div className="row-detail-item txt-grey"> 
                        <span className="txt-thin txt-small pl-10 mr-20">Allocations Familiales</span>
                        <span className="txt-bold txt-medium pr-10">{`XAF ${numberFormat(+selectedPayslip?.familyAllowances, 2, ",", " ")}`}</span>
                    </div>
                    <div className="row-detail-item txt-grey"> 
                        <span className="txt-thin txt-small pl-10 mr-20">Accident du Travail</span>
                        <span className="txt-bold txt-medium pr-10">{`XAF ${numberFormat(+selectedPayslip?.workAccident, 2, ",", " ")}`}</span>
                    </div>
                </RowGroup>
            </div>
        )
    }

    downloadPayslip = () => {
        const { selectedPayslip } = this.props;
        if(selectedPayslip && selectedPayslip.attachment){
            setTimeout(() => {
                window.open(selectedPayslip.attachment);
            }, 100); 
        }
    }

    enableDownloadPayslip = () => {
        const { selectedPayslip } = this.props;
        if(selectedPayslip && selectedPayslip.attachment) return "";
        return " btn-disabled";
    }

    renderPayslipHours = () => {
        const {step, staffName, changePayslipTab} = this.state;
        const {period, selectedPayslip} = this.props;
        return (
            <DetailContainer title={`Detail de la fiche de paie ${moment(period.dateDebut).format("MMMM YYYY")}`} onClose={this.closeTab}>
                <div className="d-flex align-items-center txt-grey">
                    <span className="txt-bold txt-medium ml-2">{`${staffName}`}</span>
                </div>
                <div className="d-flex align-items-center mt-2">
                    <button className='btn-plus ml-2 mr-2' onClick={this.goBackToPreview}>
                        <IconBack  color="#FFFFFF" />
                    </button>
                    <span className="txt-thin txt-medium txt-grey pointer" onClick={this.goBackToPreview}>Retour</span>
                </div>
                
                <PayslipTab changePayslipTab={changePayslipTab} initStep={step} payslip={selectedPayslip ? selectedPayslip: null} period={this.props.period}  toggleLoader={false} />
            </DetailContainer>
        )
    }

    renderPreview = () => {

        const {step, staffName} = this.state;
        const {period, selectedPayslip} = this.props;
        const statusOptions = [
            {value: "Declaration en attente"},
            {value: "Declaration reçus"},
            {value: "Paiement effectué"},
            {value: "Reçus"}
        ];
        const styles = {
			tabs: {
                background: '#fff',
                fontWeight: "bold",
			},
			indicator: {
				backgroundColor: '#177BD1',
			}
        };
        return(
            <DetailContainer title={`Detail de la fiche de paie ${moment(period.dateDebut).format("MMMM YYYY")}`} onClose={this.closeTab}>
                <div className="d-flex align-items-center txt-grey">
                    <span className="txt-bold txt-medium ml-2">{`${staffName}`}</span>
                </div>
                <div className="d-flex align-items-center txt-grey">
                    <span className="txt-thin txt-small pl-10 mr-20">Salaire brut:</span>
                    <span className="txt-bold txt-medium">{`XAF ${numberFormat(+selectedPayslip?.totalBrut, 2, ",", " ")}`}</span>
                </div>
                <div className="d-flex align-items-center txt-grey">
                    <span className="txt-thin txt-small pl-10 mr-20">Salaire brut imposable:</span>
                    <span className="txt-bold txt-medium">{`XAF ${numberFormat(+selectedPayslip?.netTaxable, 2, ",", " ")}`}</span>
                </div>
                <div className="d-flex align-items-center txt-grey pl-10 mt-10">
                    <CustomBtn className="btn bg-dark-grey round-3 btn-center round-3 w-140 h-30" onClick={this.gotoPayslips} disabled={this.state.period.close}>
                        <EditIcon size={12} color={"#fff"} />
                        <span className="txt-bold txt-smaller text-white text-nowrap ml-2">Editer</span> 
                    </CustomBtn>
                    <CustomBtn className={`btn bg-dark-grey round-3 btn-center round-3 w-150 h-30 ml-2 ${this.enableDownloadPayslip()}`}>
                        <DownloadIcon style={{transform: "scale(1.1)"}} />
                        <span className={`txt-bold txt-smaller text-white text-nowrap ml-2`} onClick={this.downloadPayslip}>Télécharger la fiche de paie</span> 
                    </CustomBtn>
                </div>
                <div className="d-flex align-items-center txt-grey pl-10 mt-10">
                    <div className="d-flex align-items-center justify-content-between w-140 h-30 ">
                        <span className="txt-thin txt-small mr-20">Statut</span>
                        <EditableInput type="select" options={statusOptions} optionValue={'value'} name={"impots"} className="txt-thin txt-small text-nowrap txt-grey" onChange={() => {}} />
                    </div>
                </div>
                <div className="modal-detail-preview mt-2">
                    <Tabs value={step} onChange={this.goToStep} TabIndicatorProps={{style: {backgroundColor: '#177BD1', borderRadius: '15px'}}} style={styles.tabs}>
                        <Tab label="Impôts" />
                        <Tab label="CNPS" />
                    </Tabs>
                    <SwipeableViews index={step} onChangeIndex={this.goToStep}>
                        <div id="tab-preview-ps-1">{this.renderImpots()}</div>
                        <div id="tab-preview-ps-2">{this.renderCnps()}</div>
                    </SwipeableViews>
                </div>

            </DetailContainer>
        );
    }

    switchRenderPayslip = () => {
        const {isPreview} = this.state;
        if(isPreview){
            return this.renderPreview();
        }
        return this.renderPayslipHours();
    }
    
    gotoPayslips = () => {
        this.setState({ isPreview: false });
    }

    goBackToPreview = () => {
        this.setState({ isPreview: true });
    }

    closeTab = () => {
        this.setState({
            showDetail: false,
        })
        this.props.selectPayslip();
        this.refGenericTable && this.refGenericTable.unSelectAll();
    }

    setRefGenericTable = (ref) => {
        if(ref){
            this.refGenericTable = ref;
        }
    }

    editPayslip = (val, step, isPreview) => {
        this.props.selectPayslip(val?.length >= 3 ? val[2] : null);
        this.setState({
            showDetail: true,
            staffName: val?.length >= 2 ? val[1].value : "",
            isPreview,
            step,
            changePayslipTab: true,
            tableContainerWidth: "calc(100% - 450px)"
        });
    }

    constructSource(item){
        return [{name: 'staffId',value: item.staff.id}, {name: 'fullName', value: item.staff.surname +' '+ item.staff.name}, {...item}];
    }

    /**
	 * Count 
	 * @param val : Array
	 * @return number
	 */
	countAllowance = (payslipAllowances) => {
		if(payslipAllowances){
			return payslipAllowances.length
		}
		return 0
	}

    /**
	 * Open payslip's modal's tab
	 * @param val : object
	 * @param val : number
	 */
	addItem = (val,step, isPreview = false) => {
        if(val?.length >= 3) this.editPayslip(val,step, isPreview);
    }
    

    headerData = () => {
        return [
            { title: <> Staff <DoubleArrow /> </> , align: "left", className: 'pl-15 payslip-staff-width', name: "staff"}, 
            { title: <> Heures d'absences  <DoubleArrow /> </> , align: "center", className: 'pl-15 large-vw-hidden payslip-absence-width justify-content-center', name: "absence"}, 
            { title: <> Heures supplémentaires  <DoubleArrow /> </> , align: "center", className: 'medium-vw-hidden payslip-hsupp-width justify-content-center pl-15', name: "hsupp"},
            { title: <> Primes{this.state.showDetail ? '' : '/Retenues'}  <DoubleArrow /> </> , align: "center", className: 'medium-vw-hidden payslip-allowance-width justify-content-center pl-15', name: "allowance"},
            { title: <> Fiche de paie  <DoubleArrow /> </> , align: "center", className: 'pl-15 payslip-attachment-width justify-content-center', name: "payslip"}, 
            { title: <> Statut  <DoubleArrow /> </> , align: "center", className: 'pl-15 payslip-status-width justify-content-center', name: "status"}, 
        ];
    }

    formatBodyData = (data) => {
        return data.map((item) => {
            const source = this.constructSource(item);
            const leaveHours = (+item?.sickLeaveTime) + (+item?.exceptionalLeaveTime) + (+item?.paidLeaveTaken);
            let resData = {
                originalData: item,
                source: source,
                staff: item.staff.surname +' '+ item.staff.name,
                absence: <> <center><Adder source={source} onAdd={(val, step) => this.addItem(val, 0, false)} color={'#177BD1'} items={[{name: 'absp',value: (leaveHours), bgColor: '#0C9F23'},{name: 'absjnr',value: item?.leaveWithoutPay, bgColor: '#F08913'},{name: 'absi',value: item?.unjustifiedAbsenceTime, bgColor: '#FC2F14'}]} openStep={0} /></center> </>,
                hsupp: <> <center><Adder source={source} onAdd={(val, step) => this.addItem(val, 0, false)}  color={'#177BD1'} items={[{name: 'hsupp',value: item?.overTime, bgColor: '#177BD1'},{name: 'hsuppjf',value: item?.holidaysTime, bgColor: '#0C9F23'},{name: 'nuit',value: item?.nightAllowance, bgColor: '#999999'}]} openStep={0} /></center> </>,
                allowance: <> <center><Adder source={source} onAdd={(val, step) => this.addItem(val, 1, false)}  color={'#177BD1'} items={[{name: 'payslipAllowances',value: ( item.hasOwnProperty("payslipAllowances") ? this.countAllowance(item.payslipAllowances) : 0 ), bgColor: '#177BD1'}]} openStep={1} /></center> </>,
                payslip: <> {item.loader ? <Loader ref='generate' loadData={item.loader} style={{width: '20px', height: '20px', marginLeft: '20px'}} /> : (item.hasOwnProperty('attachment') && item.attachment) ? <a href={item.attachment} onClick={this.downloadAttachment} target='_blank' rel="noopener noreferrer" download={`fiche de paie ${item.staff.name}`} alt={`fiche de paie ${item.staff.name}`} title={`fiche de paie ${item.staff.name} `} > <img src={imgPdfFile} width={15} height={'auto'} alt={'Payslip file'} /> </a>  : <img src={imgPdfFileNone} width={15} height={'auto'} alt={'Payslip file'} /> } </>,
                status: <> <span className="label-status"> <LabelStatus statement={'pending'} variant={'default'} /> </span> </>
            }
            return resData;
        })
    }


    render(){
        const {period} = this.props;
        let part = moment(period.dateDebut).format("MMMM YYYY").split(" ")
        const currentMonth = part[0];
        const currentYear = part[1];

        return (
            <div className='payslip col-display'>
                            
                <div className="d-flex flex-column flex-1-auto pl-3 pr-3" style={{width: this.state.tableContainerWidth}}>
                    <div className="options-control-header pt-20">
                        <div className="head-option-left">
                            <button className='btn-plus' onClick={this.gotoMainList}>
                                <IconBack  color="#FFFFFF" />
                            </button>

                            <button className='btn-period-title ml-2' style={{cursor: 'text'}}>
                                <CalendarComponent width={20} height={20}/>
                                <span className="btn-txt ml-3" style={{ color: "#707070", fontSize: "12px" }}>{`${currentMonth}`} <strong>{`${currentYear}`}</strong> </span>
                            </button>
                            {
                                period.manual ? "" :
                                <div className="flex-nowrap drop-down-div">
                                    <Dropdown className="payslip-actions">
                                        <Dropdown.Toggle className="drop-down-btn" variant="" id="dropdown-payslip">
                                            Action
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="drop-down-txt">
                                            <Dropdown.Item onClick={(e) => this.generatePayslips('generate')} disabled={this.state.period.close}>
                                                Générer les fiches de paie
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => this.sendPayslipsByMail()}>
                                                Envoyer les fiches par mail
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => this.updatePayPeriod()}  disabled={this.state.period.close}>
                                                Fermer la période
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            }
                            {
                                this.props.payslipsSentByMail || this.props.closePayPeriodLoader ? <div className='d-flex justify-content-center'>
                                    <Loader ref='loader' loadData={this.props.payslipsSentByMail || this.props.closePayPeriodLoader} style={{width: '25px', height: '25px', marginLeft: '20px'}} />
                                </div> : null
                            }
                        </div>
                            
                        <Search handleSearch={() => {}} iconClass='search-social' />
                    </div>

                    <div className='mt-4'>
                        <Suspense fallback={<div className="text-center">Chargement... </div>} >
                            <GenericTable
                                data={this.props.list}
                                onRef={this.setRefGenericTable}
                                header={this.headerData()}
                                body={this.formatBodyData(this.props.list)}
                                className=''
                                isLoading={this.state.loadData}
                                asLoader={<Loader style={{width: "25px", height: "25px"}} enable />}
                                onSelect={this.selectItem}
                                infiniteScroll
                                getData={this.getMoreData}
                                hasMore={(this.state.currentPage < this.state.endPage)}
                                scrollLoading={this.state.scrollLoading}
                            />
                        </Suspense>
                    </div>
                </div>
                
                <div className={`social-detail  ${(this.state.showDetail ? "w-450" : "d-none")}`}>
                    { this.switchRenderPayslip() }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        list: state.payslips.payslips,
        payslipsSentByMail: state.payslips.payslipsSentByMail,
        closePayPeriodLoader: state.payPeriod.closePayPeriodLoader,
        selectedPayslip: state.payslips.selectedPayslip
    }
}

export default connect(mapStateToProps, { setPayslips, addPayslipsMultiple, setPayslipsSentByMail, selectPayslip, setClosePayPeriodLoader })(PayslipViewDetailList)
