import React, { Component } from 'react';
import List from './List';
import EditBill from './EditBill';

class BillViews extends Component {
    renderPage = () => {
        let { match } = this.props;
        switch(match.params.view){
            case 'edit':{
                return <EditBill {...this.props} />}
            case 'preview':{
                return <EditBill {...this.props} preview />}
            case 'update':{
                return <EditBill {...this.props} update />}
            default:{
                return <List match={match} {...this.props} />}
        }
    }
    render() {
        return this.renderPage();
    }
}

export default BillViews;