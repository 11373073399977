import React, { Component } from "react";
import PropTypes from 'prop-types';
import IconClose from "../Icons/IconClose";

import "./DetailContainer.css";

class DetailContainer extends Component {

    constructor (props){
        super(props);
        this.state = {};
    }

    render() {

        return(
            <div className="detail-container">
                <div className="header-detail-container"> 
                    <span className="detail-container-title">{ this.props.title }</span> 
                    <div className="close-container" onClick={this.props.onClose}><IconClose /></div> 
                </div>
                { this.props.children }
            </div>
        )
    }
}

DetailContainer.propTypes = {
    title: PropTypes.string,
    onClose: PropTypes.func,
}

export default DetailContainer;