import React from "react";

const EuroPaymentComponent = (props) => (
  <svg width={props.width ? props.width : 16.649} height={props.height ? props.height : 16.4}  viewBox="0 0 16.649 16.4">
    <defs>
      <style>{`.awrt{fill:${props.active ? '#177bd1;' : '#b5b5b5;'}}`}</style>
    </defs>
    <path
      className="awrt"
      d="M31.668,32Zm-1.156,1.137v.577a1.152,1.152,0,0,0,2.3,0v-.577a1.152,1.152,0,0,0-2.3,0Zm3.423,2.957a1.214,1.214,0,0,0-.121,0h-.644l-1.24,1.156s0,0-.007,0l-.011.009-.012.009-.012.008-.013.008-.012.007-.013.006-.013.005-.014.005-.013,0-.014,0-.013,0-.015,0-.013,0H31.7l-.014,0-.014,0-.014,0-.013,0-.014,0-.013,0-.014-.006-.012-.006-.014-.008-.011-.007-.013-.009-.01-.007-.013-.009s0,0-.005,0L30.332,36.1h-.687a1.2,1.2,0,0,0-1.2,1.2,1.054,1.054,0,0,0,.01.114c0,.01.01.02.01.03V41.08a.488.488,0,0,0,.486.5h.005a.512.512,0,0,0,.5-.523V38.9a.293.293,0,1,1,.585,0v1c0,.013,0,.023.005.037l.016.473h3.35l-.007-.32V38.9a.293.293,0,0,1,.585,0v1c.037.02.005.041,0,.062l0,.139v.979a.512.512,0,1,0,1.024,0V37.437a.294.294,0,0,1,0-.03,1.2,1.2,0,0,0-1.078-1.314Z"
      transform="translate(-28.443 -31.999)"
    />
    <path
      className="awrt"
      d="M73.336,284.848a.564.564,0,1,0,1.129-.018V279.1a.293.293,0,0,1,.585,0v5.726a.575.575,0,0,0,1.15.017L76.428,278H73.116Z"
      transform="translate(-71.482 -269.003)"
    />
    <path
      className="awrt"
      d="M261.714,142.953a4.134,4.134,0,1,0,4.134,4.134A4.134,4.134,0,0,0,261.714,142.953Zm.891,3.44a.293.293,0,0,1,0,.585h-2.4c0,.037,0,.073,0,.11a2.009,2.009,0,0,0,.028.329h2.155a.293.293,0,0,1,0,.585h-1.96a1.986,1.986,0,0,0,2.475.936.293.293,0,1,1,.21.546A2.571,2.571,0,0,1,259.786,148h-.545a.293.293,0,0,1,0-.585h.4a2.6,2.6,0,0,1-.021-.329c0-.037,0-.073,0-.11h-.38a.293.293,0,0,1,0-.585h.473a2.57,2.57,0,0,1,3.4-1.7.293.293,0,1,1-.21.546,1.986,1.986,0,0,0-2.572,1.156Z"
      transform="translate(-249.2 -135.895)"
    />
    <path
      className="awrt"
      d="M132.515,129.841h-2.008v-2.008a.333.333,0,0,0-.666,0v2.008h-2.008a.333.333,0,0,0,0,.666h2.008v2.008a.333.333,0,0,0,.666,0v-2.008h2.008a.333.333,0,0,0,0-.666Z"
      transform="translate(-118.716 -126.674)"
    />
  </svg>
);

export default EuroPaymentComponent;
