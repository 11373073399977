import axios from "../config/axios";
import { ALLOWANCES_TYPES } from "../constants/ContractTypes";

export const createContrat = (contratData) => {
  contratData.endDate = contratData.endDate || null;
  return axios.post("/contrat", contratData);
};

export const _addContractAllowances = async (
  orgId,
  staffId,
  allowanceData,
  contractId
) => {
  allowanceData.map((item) => {
    return (item = Object.assign(item, { contractId }));
  });
  return await axios.post(
    `/organizations/${orgId}/staff/${staffId}/contract/${contractId}/allowances`,
    allowanceData
  );
};

export const _addContractAllowance = async (
  orgId,
  staffId,
  allowanceData,
  contractId
) => {
  allowanceData = Object.assign(allowanceData, { contractId });
  return await axios.post(
    `/organizations/${orgId}/staff/${staffId}/contract/${contractId}/allowance`,
    allowanceData
  );
};

export const _updateContractAllowance = async (
  orgId,
  staffId,
  allowanceData,
  contractId
) => {
  allowanceData = Object.assign(allowanceData, { contractId });
  return await axios.put(
    `/organizations/${orgId}/staff/${staffId}/contract/${contractId}/allowance/${allowanceData.id}`,
    allowanceData
  );
};

export const _deleteContractAllowance = async (
  orgId,
  staffId,
  id,
  contractId
) => {
  return await axios.delete(
    `/organizations/${orgId}/staff/${staffId}/contract/${contractId}/allowance/${id}`
  );
};

export const _updateContrat = async (
  orgId,
  staffId,
  contractId,
  contractData
) => {
  return await axios.put(
    `/organizations/${orgId}/staff/${staffId}/contract/${contractId}`,
    contractData
  );
};

export const _computeContratBrutSalaryByAllowances = (orgId, brutSalary, contractAllowances, setDefaultAllowance) => {
  const data = {
    contractAllowances
  }
  const url = `/organizations/${orgId}/contract/brutsalary/${brutSalary}${setDefaultAllowance ? "?setDefaultAllowance=" + setDefaultAllowance : ""}`;
  return axios.put(url, data);
}

const housingAllowance = (contractAllowances) => {
  if (!contractAllowances) return 0;
  const housing = contractAllowances.find(
    (x) => x.allowance.name === ALLOWANCES_TYPES.HOUSING_ALLOWANCE_NAME
  );
  return housing ? housing.amount : 0;
};

const transportAllowance = (contractAllowances) => {
  if (!contractAllowances) return 0;
  const transport = contractAllowances.find(
    (x) => x.allowance.name === ALLOWANCES_TYPES.TRANSPORT_ALLOWANCE_NAME
  );
  return transport ? transport.amount : 0;
};

export const removeDefaultAllowance = (contractAllowances) => {
  const housingIndex = contractAllowances.findIndex(c => c.allowance.name === ALLOWANCES_TYPES.HOUSING_ALLOWANCE_NAME);
  const transportIndex = contractAllowances.findIndex(c => c.allowance.name === ALLOWANCES_TYPES.TRANSPORT_ALLOWANCE_NAME);
  if (housingIndex >= 0) {
    contractAllowances.splice(housingIndex, 1);
  }
  if (transportIndex >= 0) {
    contractAllowances.splice(transportIndex, 1);
  }
  return contractAllowances;
}

export const _getContractAllowances = {
  housingAllowance,
  transportAllowance,
};
