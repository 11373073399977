import axios from '../config/axios';
import { SESSION_STORAGE_KEYS } from '../constants/constants';

export const __updateObservation = async (invoiceId, observation) => {
    let org = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
    return await axios.put(`organizations/${org.id}/invoices/${invoiceId}/observations/${observation.id}` , observation);
}

export const __createObservation = async (invoiceId, observation) => {
    let org = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
    return await axios.post(`organizations/${org.id}/invoices/${invoiceId}/observations`, observation); 
}

export const __deleteObservation = async (invoiceId, observation) => {
    let org = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.DEFAULT_ORG));
    return await axios.delete(`organizations/${org.id}/invoices/${invoiceId}/observations/${observation.id}`);
}
