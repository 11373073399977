import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setSelectedBankRecord } from "../../../redux/action/bankAction";
import CustomBtn from "../../../components/Button";
import IconCalendar from "../../../components/Icons/IconCalendar";
import DownloadIcon from "../../../components/Icons/IconCloudDownloadComponent";
import ListBankRecords from "../../../components/ListBankRecords";
import moment from "moment";
import { numberFormat } from "../../../helpers/Number";
import { downloadFile } from "../../../helpers/download";
import { _getBankRecordsByStatementId } from "../../../services/Statement";
import Logger from "../../../helpers/Logger";
import { errorNotifcation } from "../../../helpers/errorHandle";

import "./StatementDetails.css";

class BankStatementDetails extends Component {
    constructor(props){
        super(props);
        this.state = {
            displayFilter: true,
            loading: true,
            filter: '',
            records: [],
        };
    }

    componentDidMount = () => {
        this.props.onRef(this);
        this.getRecords();
    }

    componentWillUnmount = () => {
        this.props.onRef(undefined);
    }

    getRecords = async () => {
        this.setState({
            loading: true,
        });
        const organization = JSON.parse(sessionStorage.getItem('defaultOrg'));
        if(organization) {
            try{
                const records = (await _getBankRecordsByStatementId(organization.id, this.constructParam())).data;
                this.setState({
                    records: records.rows,
                });
            } catch(error) {
                Logger.error("GetBankRecordsByStatementId", `Error occured during get bank records by bank statements of org ${organization.id}`, error);
                errorNotifcation(error?.response?.status, "Impossible de charger la liste des relevés bancaires, veuillez reessayer plus tard.");
            } finally {
                this.setState({ 
                    loading: false
                });
            }
        }
    }

    resetFilter = () => {
        this.setState({
            filter: '',
        });
    }

    constructParam = () => {
        return {
            filter: this.state.filter,
            statementId: this.props.selectedStatement.id,
        };
    }

    handleSearch = (e) => {
        this.setState({
            filter: e.target.value,
        }, () => {
            this.getRecords(this.constructParam());
        });
    }

    hideFilterZone = () => {
        this.setState({
            displayFilter: false,
            filter: '',
        });
    }

    downloadAttachment = () => {
        const startPeriodDate = moment(this.props.selectedStatement.startPeriodDate).format("DD-MM-YYYY");
        const endPeriodDate = moment(this.props.endPeriodDate).format("DD-MM-YYYY");
        const downloadName = `Relevé bancaire du ${startPeriodDate} au ${endPeriodDate}`;
        downloadFile(this.props.selectedStatement.attachment, downloadName);
    }

    renderFilterZone = () => {
        if(this.state.displayFilter) {
            return (
                <div className="zone-filter mt-3">
                    <div className="filter-form pl-10 pr-10">
                        <input type="search" onChange={this.handleSearch} className="invoice-filter" placeholder={"Filtrer"} value={this.state.filter} />
                    </div>
                    <div className="p-10 unreconciled-invoice-rows">
                        <ListBankRecords data={this.state.records} onClick={() => {}} loading={this.state.loading} />
                    </div>
                </div>
            )
        }
        
    }

    render() {
        return (
            <div className="bank-statement-details">
                <div className="item-row">
                    <span className="text-modal-bank bold"> Numéro de compte </span>
                    <span className="txt-bold text-modal-bank txt-medium"> { this.props.selectedStatement?.account.accountNumber } </span>
                </div>
                <div className="item-row">
                    <span className="text-modal-bank bold bank-color"> Solde de clôture </span>
                    <span className="txt-bold text-modal-bank txt-medium"> {`${numberFormat(this.props.selectedStatement.closingBalance)}  ${this.props.selectedStatement?.account.currency}`} </span>
                </div>
                <div className="item-row">
                    <div>
                        <div className="text-modal-bank txt-bold txt-smallest"> Du </div>
                        <div className="txt-bold  text-modal-bank">
                            <span className='pr-2'> <IconCalendar fill="#E29300" size={15} /> </span>
                            <span className='pr-2 txt-bold'> {moment(this.props.selectedStatement.startPeriodDate).format('DD MMM YYYY')} </span>
                        </div>
                    </div>
                    <div>
                        <div className="text-modal-bank txt-bold txt-smallest "> Au </div>
                        <div className="txt-bold  text-modal-bank">
                            <span className='pr-2'> <IconCalendar fill="#E29300" size={15} /> </span>
                            <span className='pr-2 txt-bold'> {moment(this.props.selectedStatement.endPeriodDate).format('DD MMM YYYY')} </span>
                        </div>
                    </div>
                </div>
                <CustomBtn className={`btn bg-dark-grey round-3 btn-center round-3 w-150 h-30 ml-2 mt-2 mb-3`}>
                    <DownloadIcon style={{transform: "scale(1.1)"}} />
                    <span className={`txt-bold txt-smaller text-white text-nowrap ml-2`} onClick={this.downloadAttachment}>Télécharger le relevé</span> 
                </CustomBtn>
                <span className="h-separator" ></span>

                {
                    this.renderFilterZone()
                }
                
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selectedStatement: state.accounts.selectedStatement,
    }
}

export default connect(mapStateToProps, { setSelectedBankRecord })(BankStatementDetails);
