import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import "./SubNavLink.css";

// #region component
const propTypes = {
    menu: PropTypes.arrayOf(
        PropTypes.exact({
            label: PropTypes.string.isRequired,
            route: PropTypes.string.isRequired,
            active: PropTypes.bool.isRequired,
            notifications: PropTypes.number.isRequired,
        })
    ),
    match: PropTypes.shape({ url: PropTypes.string }),
    colorTheme:  PropTypes.shape({
        bgColor: PropTypes.string,
        color: PropTypes.string,
        activeColor: PropTypes.string,
        hoverColor: PropTypes.string,
    }),
    onSelect: PropTypes.func,
};

const defaultProps = {
    menu: [
        {label: "Parent", route: "/", active: true, notifications: 0},
        {label: "First Chil", route: "/first-child", active: false, notifications: 0},
        {label: "Last Chil", route: "/last-child", active: false, notifications: 0},
    ],
    match: { url: "/" },
    colorTheme:  {
        bgColor: "transparent",
        color: "#666666",
        activeColor: "#333333",
        hoverColor: "transparent",
    },
    onSelect: (e) => {this.props.onSelect(e)},
};

class SubNavLink extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: [],
        };
    }

    componentDidMount = () => {
        this.setState({
            menu: this.props.menu,
        })
    }

    setActive(key) {
        let newMenu = this.state.menu.map(item => {
            item.active = false;
            return item;
        })
        newMenu[key].active = true;
        this.setState({
            menu: [...newMenu],
        })
    }

    render() {
        let { match} = this.props;
        let { menu } = this.state;
        return (
            <div className={'sub-navlink'} style={{"--primary-color": this.props.colorTheme.activeColor, "--secondary-color": this.props.colorTheme.color, "--bgnav-color": this.props.colorTheme.bgColor, "--hovernav-color": this.props.colorTheme.hoverColor}}>
                <ul className = {'sub-navlink-container'}>
                    {menu.map((item, key) => {
                        return (<li key={key} onClick={() => this.setActive(key)} className={`${item.active ? "active" : ''}`}>
                                <NavLink className='d-flex sub-navlink-item-item' activeClassName="sub-navlink-active" exact to={match.url+item.route}>
                                    {item.label} {item.notifications > 0 ? <span className={'item-notification'}>{item.notifications}</span> : <></>}
                                </NavLink>
                            </li>)
                    })}
                </ul>
            </div>);
    }
}

SubNavLink.propTypes = propTypes;
SubNavLink.defaultProps = defaultProps;
// #endregion

export default SubNavLink;