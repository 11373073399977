import React, { Component } from 'react';
import Loader from "../Loader";
import ItemBankRecord from "./ItemBankRecord";
import "./ListBankRecords.css";

class ListBankRecords extends Component {
    constructor(props){
        super(props);
        this.state = {

        };
    }

    render() {
        const { data, loading } = this.props;
        if (loading) {
            return(
                <div className={'d-flex justify-content-center'}>
                    <Loader className='loader-create-staff' style={{width: '30px', height: '30px'}}  loadData={loading} />
                </div>
            )
        }
        else {
            return(
                <div className="card">
                    {
                        (data && data.length > 0) ?
                            data.map((item, index) => {
                                return <ItemBankRecord
                                            data={item}
                                            key={index}
                                            onClick={this.props.onClick}
                                        />
                            })
                        :
                        <span className='text-header-bank'> <center> Aucune opérations bancaires ne repond à cette recherche </center> </span>
                    }
                </div>
            );
        }
    }
}

export default ListBankRecords;