export default {
    PAYSLIP_TEMPLATE_BUCKET: "dizen.io.payslips.test",
    PAYSLIP_TEMPLATE_KEY: "Template/payslip.html",
    PAYSLIP_OUTPUT_BUCKET: "dizen.io.payslips.test",
    BANK_CONTEXT: "BANK_STATEMENT",
    INVOICE_CONTEXT: "INVOICE",
    INVOICE_SOURCE: "Customer App",
}

export const SESSION_STORAGE_KEYS = {
    DEFAULT_ORG : "defaultOrg",
    TOKEN: "token",
    ROLE: "role",
    HABILITATIONS: "habilitations",
    HABILITATIONS_LOADED: "habilitationsLoaded",
    INVITATION_USER: "invitation",
}

export const PATHS = {
    UNAUTHORIZED_PATH: "/fees",
    AUTH_FAILED_URL: "/",
}

export const ORGANIZATION_USER_STATUS = {
    PENDING: "En attente",
    ACTIVE: "Actif",
    CANCELED: "Annulé",
}

export const TAG_TYPES = {
    ROW: "row",
}

export const mapStatusToVariantLabel = (status) => {
    switch (status) {
        case ORGANIZATION_USER_STATUS.ACTIVE:
            return "success"
        case ORGANIZATION_USER_STATUS.PENDING:
            return "warning"
        case ORGANIZATION_USER_STATUS.CANCELED:
            return "danger"
        default:
            return "success"
    }
}

export const ContentTypes = {
    pdf: "application/pdf",
    jpg: "image/jpg",
    png: "image/png",
    jpeg: "image/jpeg",
}

export const IMAGES_TYPES = [
    "image/jpg",
    "image/png",
    "image/jpeg",
]

export const PAGINATION = {
    DEFAULT_PAGE_SIZE: 40,
    DEFAULT_PAGE: 1
}

export const REGEX = {
    PHONE: '^[623][0-9]{8}$',
}

export const BILL = {
    VAT: 19.25,
}

export const MESURE_UNIT_OPTIONS = [
    {name: "Piece"},
    {name: "Dizaine"},
    {name: "Paquet"},
    {name: "Carton"},
    {name: "Sac"},
    {name: "Tas"},
    {name: "Jour"},
];

export const ACCOUNT_TYPE = {
    BANQUE: "Banque",
    CAISSE: "Caisse",
};

export const IMAGE_FILE_OPTIONS = {
    MAX_SIZE_MB: 25,
    MAX_WIDTH_OR_HEIGHT: 1024,
    USE_WEB_WORKER: true,
    FILE_TYPE: "image/jpeg"
};